// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qEiQLiiEAhHFDT39cEQB{width:calc(100vw - 64px);margin-top:-1px;aspect-ratio:311/208}@media only screen and (min-width: 880px){.qEiQLiiEAhHFDT39cEQB{width:648px;height:552px}}
`, "",{"version":3,"sources":["webpack://./src/tss/views/pages/Summary/components/Map/map.module.scss"],"names":[],"mappings":"AAAA,sBACI,wBAAyB,CACzB,eAAgB,CAChB,oBAAqB,CACrB,0CAJJ,sBAKQ,WAAY,CACZ,YAAa,CAEpB","sourcesContent":[".container {\n    width: calc(100vw - 64px);\n    margin-top: -1px;\n    aspect-ratio: 311/208; // Figma :)\n    @media only screen and (min-width: 880px) {\n        width: 648px;\n        height: 552px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `qEiQLiiEAhHFDT39cEQB`
};
export default ___CSS_LOADER_EXPORT___;
