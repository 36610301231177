import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import StepsBox from '../../components/StepsBox';
import FormBox from '../../components/FormBox';
import InfoForm from './form/InfoForm';
import customerService from '../../../../motor/_services/customer.service';
import { convertBirthDatetoDashed } from '../../../../shared/_helpers/convertBirthDate';
import informationActions from '../../../../motor/actions/informationAction';
import stepActions from '../../../../motor/actions/stepAction';
import { tssStepDecider } from '../../../../shared/_helpers/stepDecider';
import { relations, selfRelation } from '../../../data/relations';
import getQuotes, { stepsEnum } from '../../../apiOperations/getQuotes';
import loadingKlActions from '../../../../motor/actions/loadingActions';
import toastrActions from '../../../../motor/actions/toastrAction';
import useUserState from '../../../../shared/_hooks/userStateHook';
import { UserStates } from '../../../../motor/data/userStates';
import { removeCustomEventsFromDataLayer } from '../../../../shared/_helpers/helperFunctions';
import { Helmet } from 'react-helmet';

export const Info = (props) => {
  const { setUserState } = useUserState();
  const { isFamilyFlow, insureds } = useSelector((state) => state.info);
	const [currentInsured, setCurrentInsured] = useState(null);
  const { step } = useSelector((state) => state.step);
	const {consumer} = useSelector((state) => state.consumer);
	const consumerObj = JSON.parse(consumer);
  const [isFamilyMember, setIsFamilyMember] = useState(null);
  const [relation, setRelation] = useState(null);
  const [userConsentChecked, setUserConsentChecked] = useState(null);

  const dispatch = useDispatch();
  const URL_PARAMS = new URLSearchParams(document.location.search);
  const extRefHash = URL_PARAMS.get('extrefhash') || URL_PARAMS.get('extRefHash') || URL_PARAMS.get('extrefHash')|| null;

  const isHK = extRefHash === 'D41C57E6F286E329' || 
		extRefHash === 'A88FAA8014F28B30' || 
		extRefHash === '0F416D0AA440E391';

  const campaignSource = URL_PARAMS.get('CampSrc') || null;
  const {
    handleSubmit,
    reset,
    formState: { errors, isValid, touchedFields },
    control,
    setValue,
  } = useForm({
    mode: 'onChange',
  });

  const isFormValid = isValid && (isFamilyFlow ? relation : true);

  const formHookObject = {
    handleSubmit,
    reset,
    control,
    setValue,
    errors,
    isValid: isFormValid,
    touchedFields,
    isFamilyMember,
  };

  const relationObject = {
    value: relation,
    onChange: setRelation,
    options: relations,
  };

  //* Setting 'movingForward' on page open to false, to detect browserback
  //* Pageview datalayer event
  useEffect(() => {
	dispatch(loadingKlActions.hideKlLoading())
    if (currentInsured && !currentInsured.isFull) {
      currentInsured.movingForward = false;
      dispatch(
        informationActions.updateInsured(currentInsured),
      );
    }
    dispatch(stepActions.setStep(1));

	if (!Object.values(window.dataLayer).map((element) => element.event).includes('insurant_id')) {
		removeCustomEventsFromDataLayer(window.dataLayer);
		window.dataLayer.push({
		  event: 'insurant_id',
		});
	}
  }, []);

	useEffect(() => {
		let currentIns = insureds[insureds.length - 1]
		setCurrentInsured(currentIns);
		if(currentIns?.isFull && currentIns?.movingForward){ //TODO: fix here
			currentIns = []
		}
		const relationValue = currentIns?.relationObject?.enumValue || currentIns?.relation || null
		setRelation(
			isFamilyFlow === false ?
			selfRelation : 
			relations.find((rel) => rel.enumValue === relationValue)
		)
  }, [insureds,isFamilyMember]);
	
	useEffect(() => {
  	setIsFamilyMember(isFamilyFlow);
  }, [isFamilyFlow]);

  const submitForm = (formValues) => {
    const {
      name, surname, birthDate, tckn,
    } = formValues;
    const customerObject = {
      TcKimlikVergiNo: tckn,
      Name: name,
      Surname: surname,
      BirthDate: convertBirthDatetoDashed(birthDate),
      Email: '',
      PhoneNumber: '',
	  FunctionalBranchId: 3,
      CampSrc: campaignSource || '',
		ExtRefId: consumerObj.dealerCode,
	  	HasExpressConsent: null
    };
		let tempInsureds = null;
    if (!currentInsured) {
			const newInsurer = {
				name: name,
				lastName: surname,
				identityNo:tckn,
				birthDate: birthDate,
				isFull: false,
				self: !isFamilyMember,
				movingForward: true,
				relationObject: relationObject.value,
				relation: relationObject.value.Value,
				relationText: relationObject.value.displayName,
			}

			tempInsureds = [...insureds, newInsurer];
    } else if (currentInsured.isFull) { // Adding new family member
			const newInsurer = {
				name: name,
				lastName: surname,
				identityNo:tckn,
				birthDate: birthDate,
				isFull: false,
				self: !isFamilyMember,
				movingForward: true,
				relationObject: relationObject.value,
				relation: relationObject.value.Value,
				relationText: relationObject.value.displayName,
			}

			tempInsureds = [...insureds, newInsurer];
    } else {
      currentInsured.movingForward = true;
      currentInsured.name = name;
      currentInsured.lastName = surname;
      currentInsured.identityNo = tckn;
      currentInsured.birthDate = birthDate;
			currentInsured.relationObject = relationObject.value,
      currentInsured.relation = relationObject.value.Value;
      currentInsured.relationText = relationObject.displayName || relationObject.value.displayName;
			insureds.pop();

			tempInsureds = [...insureds, currentInsured];
    }
    // stepDecider(false, step + 1, props.history, true, isFamilylow);
    if (!isFamilyFlow) {
      dispatch(loadingKlActions.showKlLoadingEmpty());
      customerService.customer(customerObject,
        (customerResult, _, __) => {
					const newSearchParams = { jt: customerResult.userUID };
          dispatch(informationActions.setCustomerInfo(customerResult));
					dispatch(loadingKlActions.showKlLoadingEmpty());
					const userSateData = {
						UserUID: customerResult.userUID,
						UserStateDefinition: UserStates.TSS_Landing_Page_Devama_Tiklayan,
					};
					setUserState(userSateData);

					getQuotes(dispatch, { step: stepsEnum.None, insureds: tempInsureds, jt:customerResult.userUID }, (response,hasError) => {
						if (hasError) {
							dispatch(loadingKlActions.hideKlLoading());
							dispatch(
								toastrActions.setToastrMessage({
									variant: 'error',
									message: response.Message || 'Bir hata oluştu',
								}),
							);
						} else{
							if(response.apsNotFound){
								console.log(response)
								dispatch(stepActions.setShouldRedirectCityDistrict(true))
							}
							dispatch(
								informationActions.setInsureds(tempInsureds),
							);
							newSearchParams.qGuid = response.quoteId
							dispatch(loadingKlActions.hideKlLoading());
							tssStepDecider(step + 1, props.history, isFamilyFlow, newSearchParams);
							dispatch(stepActions.incrementStep());
						}
					});
        });
    }else{
			dispatch(loadingKlActions.showKlLoadingEmpty());
			getQuotes(dispatch, { step: stepsEnum.None, insureds: tempInsureds }, (response,hasError) => {
				if (hasError) {
					dispatch(loadingKlActions.hideKlLoading());
					dispatch(
						toastrActions.setToastrMessage({
							variant: 'error',
							message: response.Message || 'Bir hata oluştu',
						}),
					);
				} else{
					if(response.apsNotFound){
						console.log(response)
						dispatch(stepActions.setShouldRedirectCityDistrict(true))
					}
					dispatch(
					informationActions.setInsureds(tempInsureds),
					);
					
					dispatch(loadingKlActions.hideKlLoading());
					tssStepDecider(step + 1, props.history, isFamilyFlow);
					dispatch(stepActions.setStep(step + 1));
				}
			});
		}
	window.dataLayer.push({ funnel_variables: null });
	window.dataLayer.push({
		event: 'insurant_id_continue',
		funnel_variables: {
			birthdate: birthDate
		},
	});
  };

  return (
    ((currentInsured && isFamilyMember !== null) || isFamilyMember === false) ? (<>
		{isHK && (
			<Helmet>
			<meta name="robots" content="noindex, nofollow" />
		</Helmet>
		)}
	  <StepsBox hasFunnelName={!isFamilyMember}>
        <FormBox formHeader="Kimlik Bilgileri">
          <InfoForm formHookObject={formHookObject} submitForm={submitForm} relationObject={relationObject} userConsentChecked={setUserConsentChecked} />
        </FormBox>
      </StepsBox>
    </>) : (
			''
		)
  );
};

// to be deleted

export default withRouter(Info);

