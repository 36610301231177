// App.js
import React, { useEffect } from 'react';
import {
  BrowserRouter as Router, Route, Switch, useLocation,
} from 'react-router-dom';
import { ErrorBoundary, Provider } from '@rollbar/react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ReactGA from 'react-ga';
import MotorApp from './motor.app';
import TSSApp from './tss.app';
import Toastr from './shared/Toastr';
import CookieModal from './motor/views/components/shared/Cookie';
import { clientRollBarConfig } from '../config/rollbar';
import resolveConsumerFnc from './shared/apiOperations/resolveConsumer';
import getTheme from './shared/apiOperations/getTheme';
import { theme } from './shared/themes/koalay';
import KLHeader from './motor/views/layout/KLHeader';
import KoalayLoading from './motor/views/components/KoalayLoading';
import { NODE_ENV } from './shared/_helpers/env';

function App() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { theme: consumerTheme } = useSelector((state) => state.theme);
  const { isKoalaySS } = useSelector((state) => state.shell);
  const { useHeader } = useSelector((state) => state.header);

  const renderAppRouter = () => {
    const { pathname } = location;
    switch (true) {
      case pathname.toLowerCase().includes('/quotation'):
        return <MotorApp />;
      case (pathname.includes('/tamamlayici-saglik-sigortasi')):
        return <TSSApp />;
      default:
        return <div>Route Bulunamadı</div>;
    }
  };

  useEffect(() => {
    resolveConsumerFnc(dispatch, () => {
      getTheme(dispatch, () => {
      });
    });

    ReactGA.pageview(window.location.pathname);
  }, [dispatch]);

  // TODO: move KL loading, kl Header
  return (
    (consumerTheme) ? (
      <Provider config={clientRollBarConfig}>
        <ErrorBoundary>
          <ThemeProvider theme={isKoalaySS ? theme : consumerTheme}>
            <CssBaseline />
            <Router>
              <KoalayLoading />
              <Toastr />
              {useHeader && <KLHeader />}
              <Switch>
                <Route path="/quotation" render={renderAppRouter} />
                <Route path="/tamamlayici-saglik-sigortasi" render={renderAppRouter} />
              </Switch>
            </Router>
            <CookieModal />
          </ThemeProvider>
        </ErrorBoundary>
      </Provider>
    ) : (
      <Toastr />
    )
  );
}

export default App;
