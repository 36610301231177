import React, { useEffect } from 'react';
import { Grid, Dialog, Paper } from '@mui/material';
import useWindowSize from '../../../../shared/_hooks/windowResizeHook';
import DeleteSVG from '../../../../shared/assets/img/svg/delete.svg';

export const CenteredModal = ({
  title, isOpen, setOpen, children, hasClose = true, ...props
}) => {
  const size = useWindowSize();
  const isMobile = size.width <= 880;
  const { className } = props;
  const isKidsModal = className === 'kid-discount-modal';

  const handleClose = (e) => {
    const isOuther = e.target.className.includes('MuiDialog-container');
    const isEscape = e.code === 'Escape';
    if (!hasClose && (isOuther || isEscape)) return;
    setOpen(false);
  };
  return (
    <>
      <Dialog
        open={isOpen}
        aria-labelledby="centered-modal-kl"
        onClose={handleClose}
        aria-describedby="centered-modal-with-child"
        PaperProps={{
          style: {
            margin: isMobile ? '20px' : '',
          },
        }}
        className={`${className} centered-modal-container`}
      >
        <Paper
          style={{
            color: '#373B3F',
            padding: isMobile ? !isKidsModal ? '16px 8px' : '16px' : !isKidsModal ? '24px' : '16px',
            width: isMobile || isKidsModal ? 'auto' : '440px',
            height: isKidsModal ? (isMobile ? '366px' : '415px') : 'auto',
            position: 'relative',
          }}
          square
        >
          {hasClose && (
          <img
            width={isMobile ? '16' : '20'}
            src={DeleteSVG}
						className='centered-modal-close'
            style={{
              position: 'absolute',
              filter: 'invert(88%) sepia(21%) saturate(935%) hue-rotate(123deg) brightness(85%) contrast(97%)',
              right: isMobile ? '0' : '8px',
              top: isMobile ? '15px' : '20px',
              cursor: 'pointer',
            }}
            onClick={() => setOpen(false)}
          />
          )}
          <Grid xs={12} sm={12} lg={12} xl={12} item>
            <h3>{title}</h3>
          </Grid>
          {children}
        </Paper>
      </Dialog>
    </>
  );
};

export default CenteredModal;
