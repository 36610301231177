import { themeTypes } from '../types/theme.types';

function setTheme(theme, additionalInfo) {
  return {
    type: themeTypes.SET_THEME,
    payload: {
      theme,
      additionalInfo,
    },
  };
}

const themeActions = {
  setTheme,
};
export default themeActions;
