import React from 'react';
import {
  Autocomplete, Button,
	MenuItem,
} from '@mui/material';

import InfoSVG from '../../../../../../shared/assets/img/svg/info3.svg';

export const CascoCodeInput = (props) => {
  const {
    autoCompleteProps,
    getModelByCode,
    setCascoModalOpen,
		cascoFieldref,
  } = props;

  return (
    <div className="casco-code-container">
      <Autocomplete
        freeSolo
        openOnFocus
        id="casco-code-input"
        noOptionsText="Kasko koduna ait araç bulunamadı"
        getOptionDisabled={(option) => option.Value === '-1'}
        getOptionLabel={(option) => option.Text}
        sx={{ width: 'auto' }}
        renderOption={(prop, option, { selected }) => (
          <MenuItem style={{ whiteSpace: 'normal' }} {...prop} value={option.Value} key={option.Text} data-text={option.Text}>
            {option.Text}
          </MenuItem>
        )}
        {...autoCompleteProps}
      />
      <Button
        variant="contained"
        style={{
          width: '70px',
          position: 'absolute',
          top: '0',
          right: '0',
          height: '45px',
          boxSizing: 'border-box',
          boxShadow: 'none',
    			borderRadius: "0 5px 5px 0",
          textTransform: 'none',
        }}
        onClick={getModelByCode}
      >
        Sorgula
      </Button>
      <div className="modal-popup-text-container" onClick={() => setCascoModalOpen(true)}>
        <img src={InfoSVG} alt="" width={20} className="svg-icon" />
        <p>Kasko kodumu nereden bulabilirim?</p>
      </div>
    </div>
  );
};

export default CascoCodeInput;
