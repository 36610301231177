import React, { useEffect, useState } from 'react';
import { Button, Tooltip, tooltipClasses } from '@mui/material';
import Switch from '@mui/material/Switch';
import '../../../../shared/assets/styles/offer.scss';
import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import BabyBottlekasko from '../../../../shared/assets/img/svg/bottle.svg';
import CheckmarkCircle from '../../../../shared/assets/img/svg/CheckmarkCircle.svg';
import BabyBottletrafik from '../../../../shared/assets/img/svg/bottle-green.svg';
import Gift from '../../../../shared/assets/img/svg/gift.svg';
import InfoSVG from '../../../../shared/assets/img/svg/info.svg';
import TickSVG from '../../../../shared/assets/img/svg/tick-green.svg';
import TickESVG from '../../../../shared/assets/img/svg/tick.svg';
import useWindowSize from '../../../../shared/_hooks/windowResizeHook';
import { getUsableLogo, priceFormatter } from '../../../../shared/_helpers/helperFunctions';
import EventBus from '../../../../shared/_helpers/EventBus';
import coverageService from '../../../_services/coverage.service';
import crossSVG from '../../../../shared/assets/img/svg/cross.svg';
import CenteredModal from '../../components/widget/CenteredModal';
import PopularCoverages from './PopularCoverages/PopularCoverages';
import popularCoveragesDecider from '../../../../shared/_helpers/popularCoveragesDecider';

const SwitchButton = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  marginLeft: 5,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#0055A5',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));
const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 13,
    },
  }),
);
const allPopular = [
  { displayText: 'Asistans' },
  { displayText: 'Çarpma, Çarpışma' },
  { displayText: 'Cam Değişimi (Muafiyetli)' },
  { displayText: 'Orjinal Parça / Eşdeğer Parça' },
  { displayText: 'Artan Mali Mesuliyet (Maddi Bedeni Ayrımsız) / Artan Mali Mesuliyet (Manevi Tazminat)' },
  { displayText: 'Anlaşmalı Yetkili Servis / Anlaşmalı Özel Servis / Özel Servis' },
];
export const Offer = (props) => {
  const size = useWindowSize();
  const isMobile = size.width <= 880;
  const { offer, addToComparissionList, campaignPromotionGift } = props;
  const [isTeminatVisible, setIsTeminatVisible] = useState(offer.isTeminatOpen);
  const [popularCoverages, setPopularCoverages] = useState(null);
  const [totalCoverage, setTotalCoverage] = useState(null);
  const [commercialName, setCommercialName] = useState('');
  const [subtitleOpen, setSubtitleOpen] = useState(false);
  const [pertDescOpen, setPertDescOpen] = useState(false);
  const [installmentHintOpen, setInstallmentHintOpen] = useState(false);

  useEffect(() => {
    const tempName = getUsableLogo(offer);
    setCommercialName(tempName);
  }, [offer]);

  const openKidDiscount = () => {
    EventBus.emit('openKidDiscount', offer);
  };

  // const openCoverageModal = () => {
  //   EventBus.emit('openCoverageModal', offer);
  // };

  const handlePopularCoverages = (value) => {
    setPopularCoverages(value);
    offer.popularCoverages = value;
  };

  useEffect(() => {
    if (popularCoverages === null && offer.popularCoverages && isTeminatVisible) {
      offer.isTeminatOpen = true;
      handlePopularCoverages(offer.popularCoverages);
    } else if (popularCoverages === null && isTeminatVisible) {
      offer.isTeminatOpen = true;
      popularCoveragesDecider(offer, handlePopularCoverages);
    } else {
      offer.isTeminatOpen = false;
    }
  }, [isTeminatVisible]);

  useEffect(() => {
    if (popularCoverages !== null) {
      setTotalCoverage(allPopular.filter((cov) => !popularCoverages.some((pop) => cov.displayText == pop.displayText)));
    }
  }, [popularCoverages]);

  const hasPromotion = ((offer.SwitchableKidProductId && !offer.HasKidIndentity)
      || offer.SwitchableParentKidProductId
      || offer.GiftCardPrice !== null)
    && offer.Occupation !== null
    && offer.OldPremium !== null
    && offer.OldPremium > offer.Premium;
  const hasBanner = (offer.SwitchableKidProductId && !offer.HasKidIndentity)
    || offer.SwitchableParentKidProductId
    || offer.GiftCardPrice !== null;

  const MiddleSide = () => (
    <div className="middle-side">
      <div className="offer-title-teminat">
        <div className="offer-title-container">
          <div className="offer-title" dangerouslySetInnerHTML={{ __html: offer.Subtitle }} />
          {offer.SubtitleDescription !== null && (
            <>
              <LightTooltip title={offer.SubtitleDescription} placement="top">
                <img
                  style={{
                    width: isMobile ? '16px' : '20px',
                    height: isMobile ? '16px' : '20px',
                  }}
                  src={InfoSVG}
                  onClick={() => (isMobile ? setSubtitleOpen(true) : '')}
                />
              </LightTooltip>
              <CenteredModal title={offer.Subtitle} isOpen={subtitleOpen} setOpen={setSubtitleOpen}>
                <p className="disclaimer" style={{ marginTop: '10px' }}>
                  {offer.SubtitleDescription}
                </p>
              </CenteredModal>
            </>
          )}
        </div>
        {offer.Subtitle.toLowerCase().indexOf('pert') >= 0 || offer.Subtitle.toLowerCase().indexOf('mini') >= 0 ? (
          <div className="offer-subtitle">
            Dar Kapsamlı Ürün
            <LightTooltip
              title={
                offer.Subtitle.toLowerCase().indexOf('pert') >= 0
                  ? "Seçtiğiniz sigorta 'Pert Kasko' ürünüdür. Teminat kapsamına alınan bir olay nedeniyle aracın tam hasara uğraması ve eksperin onaylaması durumunda tazminat ödenen poliçe tipidir. Pert hasarı haricinde oluşan kısmi onarım masrafları için, bu poliçe kapsamında herhangi bir tazminat ödenmez."
                  : "Seçtiğiniz sigorta 'Mini Kasko' ürünüdür. Allianz Mini Kasko aracınızı hırsızlık, yanma, terör, doğal afetler(sel/su baskını,dolu yıldırım, fırtına, toprak kayması) gibi temel risklere karşı güvence altına alır. Bu üründe çarpma/çarpışma teminatı bulunmamaktadır"
              }
              placement="top"
            >
              <img
                src={InfoSVG}
                style={{
                  width: isMobile ? '16px' : '20px',
                  height: isMobile ? '16px' : '20px',
                }}
                onClick={() => (isMobile ? setPertDescOpen(true) : '')}
              />
            </LightTooltip>
            <CenteredModal title="Dar Kapsamlı Ürün" isOpen={pertDescOpen} setOpen={setPertDescOpen}>
              <p className="disclaimer" style={{ marginTop: '10px' }}>
                {offer.Subtitle.toLowerCase().indexOf('pert') >= 0
                  ? "Seçtiğiniz sigorta 'Pert Kasko' ürünüdür. Teminat kapsamına alınan bir olay nedeniyle aracın tam hasara uğraması ve eksperin onaylaması durumunda tazminat ödenen poliçe tipidir. Pert hasarı haricinde oluşan kısmi onarım masrafları için, bu poliçe kapsamında herhangi bir tazminat ödenmez."
                  : "Seçtiğiniz sigorta 'Mini Kasko' ürünüdür. Allianz Mini Kasko aracınızı hırsızlık, yanma, terör, doğal afetler(sel/su baskını,dolu yıldırım, fırtına, toprak kayması) gibi temel risklere karşı güvence altına alır. Bu üründe çarpma/çarpışma teminatı bulunmamaktadır"}
              </p>
            </CenteredModal>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
  return (
    offer && (
      <div className={`offer ${offer.Brans.toLowerCase()}`}>
        <div className={`offer-header ${offer.Brans.toLowerCase()}`}>
          <div className="offer-header-left">
            {((offer.SwitchableKidProductId && !offer.HasKidIndentity) || offer.SwitchableParentKidProductId) && (
              <span className={`promotion-baby ${offer.Brans.toLowerCase()}`}>
                <img src={offer.Brans.toLowerCase() === 'trafik' ? BabyBottletrafik : BabyBottlekasko} />
                Bebeği Olana %
                {offer.Brans === 'Trafik' ? '15' : '25'}
                ’e Varan İndirim!
              </span>
            )}
            {offer.GiftCardPrice !== null && (
              <span className="promotion-gift">
                <img src={Gift} />
                50 TL Hediye Çeki
              </span>
            )}
            {campaignPromotionGift && offer.DealerCampaignPrice ? (
              <span className="campaign-promotion-gift">
                <img src={Gift} />
                {offer.DealerCampaignPrice}
                {' '}
                {campaignPromotionGift}
              </span>
            ) : null}
            {/* {offer.Occupation !== null && offer.OldPremium !== null && offer.OldPremium > offer.Premium && (
              <span className="promotion-occupation">
                <img src={TickESVG} />
                <p className="assistive">Meslek indirimi uygulandı</p>
              </span>
            )} */}
          </div>
          <span className="compare" style={{ visibility: 'hidden' }}>
            <>
              Karşılaştır
              {' '}
              <SwitchButton onChange={() => addToComparissionList(offer)} checked={offer.compare} />
            </>
          </span>
        </div>
        <div className={`offer-container ${offer.Brans.toLowerCase()}`}>
          <div className="offer-type" style={{ marginBottom: hasBanner ? '10px' : '30px' }}>
            {offer.Brans}
          </div>
          <div className="offer-detail">
            <div className="left-side">
              <div className="left-container">
                <div className="insurer-image" style={{ marginTop: hasPromotion ? '25px' : '' }}>
                  <img src={commercialName.src} alt={commercialName.alt} height="auto" width={120} />
                </div>
                {isMobile && <MiddleSide />}
                <div
                  className="teminat"
                  onClick={() => setIsTeminatVisible(!isTeminatVisible)}
                  role="button"
                  tabIndex={0}
                >
                  Teminatlar
                  <KeyboardArrowDownIcon style={{ transform: isTeminatVisible ? 'rotate(180deg)' : '', zoom: '0.8' }} />
                </div>
                {offer.Occupation !== null && offer.OldPremium !== null && offer.OldPremium > offer.Premium && (
                <div className="occupation-discount">
                  <img
                    style={{
											  width: isMobile ? '14px' : '16px',
											  height: isMobile ? '14px' : '16px',
                    }}
                    src={CheckmarkCircle}
                  />
                  <span>
                    <b>Meslek</b>
                    {' '}
                    indirimi uygulandı
                  </span>
                </div>
                )}
              </div>
            </div>
            {!isMobile && <MiddleSide />}
            <div className="right-side">
              <div className="offer-price">
                <div className="price-title">
                  <span>Peşin Fiyat</span>
                </div>
                <div className="price-box">
                  {offer.OldPremium !== null && (
                  <div className="old-price">
                    {priceFormatter.format(offer.OldPremium).split(',')[0].replace('₺', '')}
                    {' '}
                    TL
                  </div>
                  )}
                  <div className="price">
                    {priceFormatter.format(offer.Premium).split(',')[0].replace('₺', '')}
                    {' '}
                    TL
                  </div>
                </div>
                <div className="installment">
                  {offer.PossibleInstallment.toLowerCase().indexOf('taksit') >= 0 ? (
                    <>
                      <span>
                        Taksit
                        {' '}
                        <span style={{ whiteSpace: 'nowrap' }}>
                          İmkanı:
                          {' '}
                          {offer.PossibleInstallment.toLowerCase().replace('taksit', '')}
                        </span>
                      </span>
                      <>
                        <LightTooltip title="Kredi kartına bağlı olarak sigorta şirketlerinin sunduğu taksit sayısı değişebilir ve vade farkı oluşabilir." placement="top">
                          <img
                            style={{
														  width: isMobile ? '16px' : '20px',
														  height: isMobile ? '16px' : '20px',
														  margin: '0 0 0 3px',
                            }}
                            src={InfoSVG}
                            onClick={() => (isMobile ? setInstallmentHintOpen(true) : '')}
                          />
                        </LightTooltip>
                        <CenteredModal title="" isOpen={installmentHintOpen} setOpen={setInstallmentHintOpen}>
                          <p className="disclaimer" style={{ marginTop: '10px' }}>
                            Kredi kartına bağlı olarak sigorta şirketlerinin sunduğu taksit sayısı değişebilir ve vade farkı oluşabilir.
                          </p>
                        </CenteredModal>
                      </>
                    </>
                  ) : (
                    <>
                      Taksit İmkanı: Yok
                      <>
                        <LightTooltip title="Sigorta şirketi bu poliçe için taksit imkanı sunmuyor." placement="top">
                          <img
                            style={{
													  width: isMobile ? '16px' : '20px',
													  height: isMobile ? '16px' : '20px',
													  margin: '0 0 0 3px',
                            }}
                            src={InfoSVG}
                            onClick={() => (isMobile ? setInstallmentHintOpen(true) : '')}
                          />
                        </LightTooltip>
                        <CenteredModal title="" isOpen={installmentHintOpen} setOpen={setInstallmentHintOpen}>
                          <p className="disclaimer" style={{ marginTop: '10px' }}>
                            Sigorta şirketi bu poliçe için taksit imkanı sunmuyor.
                          </p>
                        </CenteredModal>
                      </>
                    </>
                  )}
                </div>
              </div>
              <div className="offer-buy">
                {((offer.SwitchableKidProductId && !offer.HasKidIndentity) || offer.SwitchableParentKidProductId) && (
                  <Button variant="outlined" className="button-discount" onClick={openKidDiscount}>
                    Bebek İndirimi Al
                  </Button>
                )}
                <Button variant="contained" className="button" onClick={() => props.buyButtonClicked(offer, true)}>
                  Devam Et
                </Button>
              </div>
            </div>
          </div>
        </div>
        {isTeminatVisible && popularCoverages !== null && (
          <PopularCoverages popularCoverages={popularCoverages} totalCoverage={totalCoverage} offer={offer} />
        )}

      </div>
    )
  );
};

export default Offer;
