import { consumerTypes } from '../types/consumer.types';

function setConsumer(consumer) {
  return {
    type: consumerTypes.SET_CONSUMER,
    payload: {
      consumer,
    },
  };
}

const consumerActions = {
  setConsumer,
};
export default consumerActions;
