export const UserStates = {
  VehicleApi_Hatasi: 63, // ok api hatası geldiğinde setlendi
  // Change_Phone_Summary: 80,
  // Change_Email_Summary: 81,
  // Change_Address_Summary: 82,
  // Update_Vehicle_Summary: 83,
  Update_Vehicle_Breakout: 84, // ok breakout isteği gönderildiğinde setlendi
  Change_Phone_OTPCheck: 90, // ok Farklı bir telefon gir sonrası sms gönder butonuna tıklandığında setlendi
  Change_Phone_Confirmation: 91, // ok confirmation sayfası değiştir butonuna tıklandığında setlendi
  Change_Email_Confirmation: 92, // ok confirmation sayfası değiştir butonuna tıklandığında setlendi
  Change_Address_Confirmation: 93, // ok confirmation sayfası değiştir butonuna tıklandığında setlendi
  Update_Vehicle_Confirmation: 94, // ok confirmation sayfası değiştir butonuna tıklandığında setlendi
  // Change_Email_Summary_CC: 110,
  // Change_Email_Confirmation_CC: 111,
  // Change_Phone_Summary_CC: 112,
  // Change_Phone_Confirmation_CC: 113,
  // Scheduled_Call_Near_Exit_Popup: 115,
  // Change_Email_ThankYou_CC: 116,
  Change_Email_ThankYou: 117, // ok evet, istiyorum butonu tıklandığında
  // Potansiyel_Musteri_Yenileme_Trafik_Policesi_CapPrice_En_Dusuk_Teklif: 131,
  Potansiyel_Musteri_Trafik_Odeme_Sayfasinda_Siteden_Ayrilan: 138, // ok ödeme sayfası açıldığında setlendi
  Potansiyel_Musteri_Kasko_Odeme_Sayfasinda_Siteden_Ayrilan: 139, // ok ödeme sayfası açıldığında setlendi
  Potansiyel_Musteri_Trafik_Odeme_Butonunu_Tiklayan: 140, // ok butona basıldığında paratika öncesi
  Potansiyel_Musteri_Kasko_Odeme_Butonunu_Tiklayan: 141, // ok butona basıldığında paratika öncesi
  Potansiyel_Musteri_Teklif_Sayfasina_Gelen: 142, // ok teklif sayfası açıldığında setlendi
  Potansiyel_Musteri_Paratika_Uyari_Metni_Goren: 153, // test için kullanılmış
  Potansiyel_Musteri_Paratika_Uyari_Popup_Goren: 154, // test için kullanılmış
  Potansiyel_Musteri_Paratika_Uyari_Popup_Onaylayan: 155, // patatika message varsa setlendi
  Potansiyel_Musteri_Paratika_Uyari_Popup_Vazgecen: 156, // patatika.js effect setlendi
  MeslekSayfasiniGordu: 162, // ok meslek sayfgası açıldığında setlendi
  Potansiyel_Musteri_Iletisim_Bilgisi_Yazmadan_Ayrılan: 165, // ok iletişim sayfası açıldığında setlendi
  Potansiyel_Musteri_Ruhsat_Bilgisi_Yazmadan_Ayrılan: 166, // ok ruhsat sayfası açıldığında setlendi
  No_Continue_Process: 169, // ok
  Continue_Process_New_Offer_Button_Clicked: 170, // ok Yeni Teklif Al butonuna tıklandığında setlendi
  Continue_Process_Continue_With_This_Car_Button_Clicked: 171, // ok Bu Araçla Devam Et butonuna tıklandığında setlendi
  OTP_Screen_Has_Shown: 172, // ok otp screen açıldığında setlendi
  OTP_SMS_Sent: 173, // ok is_otp_required true olduğunda setlendi
  OTP_SMS_Verified: 174, // ok verify olduğunda setlendi
  OTP_SMS_Invalid_Code_Entered: 175, // ok verify olmadığında setlendi
  Thankyou_Casco_Seen: 177, // ok thankyou page açıldığında setlendi
  Thankyou_Traffic_Seen: 178, // ok thankyou page açıldığında setlendi
  Koalay_Teklif_Sayfasinda_Kredi_Kartina_Basvuran: 191,
  Koalay_Teklif_Sayfasinda_Kredi_Kartina_Basvuran_Basarili_Sonuc: 192,
  Koalay_Teklif_Sayfasinda_Kredi_Kartina_Basvuran_Basarisiz_Sonuc: 193,
  TSS_Landing_Page_Devama_Tiklayan: 179, //
  TSS_Iletisim_Bilgileri_Sayfasina_Gelen: 180, //
  TSS_Iletisim_Bilgileri_Sayfasinda_Devama_Tiklayan: 181, //
  TSS_Teklif_Sayfasina_Gelen: 182, //
  TSS_Teklif_Sayfasinda_Satin_Al_Tiklayan: 183, //
  TSS_Confirmation_Page_Gelen: 184, //
  TSS_Confirmation_Page_Devama_Tiklayan: 185, //
  TSS_Odeme_Sayfasina_Gelen: 186, //
  TSS_Odeme_Sayfasinda_Siteden_Ayrilan: 187, // atılmadı
  TSS_Odeme_Sayfasinda_Satin_Al_Tiklayan: 188,
  TSS_Tesekkur_Sayfasi_Goren: 189,
  TSS_Call_Center_Sayfasina_Yönlendirilen: 194,
  Hasarsizlik_Bilgileri_Tam_Olarak_Devam_Etti: 195,
};

export const EventSubType = {
  QuotationUserStateCreated: 1,
  IssueUserStateCreated: 2,
};
