import React, { useEffect, useState, useMemo, useRef } from "react";

import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Drawer, Modal } from "@mui/material";
import quotationService from "../../../_services/quotation.service";
import { partajToQuotes } from "../../../../shared/_helpers/partajHelper";
import productService from "../../../_services/product.service";
import { MAIN_URL, WS } from "../../../../shared/_helpers/env";
import Offer from "./Offer";
import SkeletonOffer from "./SkeletonOffer";
import OfferFilters from "./OfferFilters";
import Steps from "../../components/Steps";
import stepActions from "../../../actions/stepAction";
import useWindowSize from "../../../../shared/_hooks/windowResizeHook";
import EventBus from "../../../../shared/_helpers/EventBus";
import MegaphoneSVG from "../../../../shared/assets/img/svg/megaphone.svg";
import securedLetter from "../../../../shared/assets/img/svg/securedLetter.svg";
import discountSVG from "../../../../shared/assets/img/svg/discount.svg";
import editSVG from "../../../../shared/assets/img/svg/edit.svg";
import { loadingLimit } from "../../../data/shared";
import ProgressBar from "../../components/ProgressBar";
import NotOfferContainer from "../../components/insurerNotOffer/NotOfferContainer";
import RenewalCard from "../../components/RenewalCard";
import NoOffer from "../../components/widget/NoOffer";
import loadingKlActions from "../../../actions/loadingActions";
import commonService from "../../../_services/common.service";
import OccupationModal from "../../components/widget/OccupationModal";
import SwipeCard, { CarouselItem } from "../../components/SwipeCard";
import { checkRenevalPeriod, getRenevalDateString } from "../../../../shared/_helpers/vehicle/policy";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Comparission } from "../../components/widget/Comparission";
import toastrActions from "../../../actions/toastrAction";
import CloseIcon from "@mui/icons-material/Close";
import Coverage from "../../components/Coverage";
import CenteredModal from "../../components/widget/CenteredModal";

import onlineSupport from "../../../../shared/assets/img/png/Online Support.png";
import { displayPhoneNumber, getUsableLogo, parseOffersAndPushToDatalayer, pushSelectItemEvent, removeCustomEventsFromDataLayer } from "../../../../shared/_helpers/helperFunctions";
import SendMailModal from "../../components/widget/SendMailModal";
import mailService from "../../../_services/mail.service";
import KidDiscount from "../../components/widget/KidDiscount";
import customerService from "../../../_services/customer.service";
import mainFilters from "../../../data/mainFilters";
import RedirectPage from "../Steps/OptionalSteps/RedirectPage";
import vehicleService from "../../../_services/vehicle.services";
import CarUsageTypesModal from "../../components/widget/CarUsageTypesModal";
import HasFailAtInsurer from "../../components/widget/HasFailAtInsurer";
import KPSWarningCard from "../../components/KPSWarningCard";
import { useRollbar } from "@rollbar/react";
import { formatDateToString } from "../../../../shared/_helpers/dateFormat";
import { setSearchString } from "../../../../shared/_helpers/redirect";
import shellActions from "../../../actions/shellAction";
import headerActions from "../../../actions/headerAction";
import CCCrossSell from "../../components/cc-crossell/CCCrossSell";

const StompJs = require("@stomp/stompjs");

export const OfferPage = (props) => {
  const size = useWindowSize();
  const Rollbar = useRollbar();
  const isMobile = size.width <= 880;
  const [offers, setOffers] = useState([]);
	const { isAgency, isMerchant, isCallCenter } = useSelector((state) => state.shell);
  const [startLoading, setStartLoading] = useState(true); // Global Loading state may be false on initial, to owerride it this state used. Must be control by global state
  const comparePlaceholder = [1, 2, 3];
  // base filters
  const [filterInsurers, setFilterInsurers] = useState([]);
  const [filterProductGroups, setFilterProductGroups] = useState([]);
  const [filterProductSubTypeModels, setFilterProductSubTypeModels] = useState([]);
  // modified filters
  const [insurerFilters, setInsurerFilters] = useState({});
  const [productFilters, setProductFilters] = useState({});
  const [subTypeFilter, setSubTypeFilter] = useState({});

  const [filteredOffers, setFilteredOffers] = useState([]);
  const [carInfo, setCarInfo] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [policyInfoList, setPolicyInfoList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [offerEnded, setOfferEnded] = useState(false);

  const [typeFilterList, setTypeFilterList] = useState(["all"]);
  const [brandFilterList, setBrandFilterList] = useState(["all"]);
  const [subTypeFilterList, setSubTypeFilterList] = useState(["all"]);

  const [shouldApplyFilter, setShouldApplyFilter] = useState(false);

  const [occupationList, setOccupationList] = useState([]);
  const [occupationModal, setOccupationModal] = useState(false);
  const [selectedOccupationId, setSelectedOccupationId] = useState(null);
  const [selectedOccupation, setSelectedOccupation] = useState(null);

  const [comparissionList, setComparissionList] = useState([]);
  const [isComparissionModalOpen, setIsComparissionModalOpen] = useState(false);
  const [minimizeCompare, setMinimizeCompare] = useState(false);

  const [isCarTypeModalOpended, setIsCarTypeModalOpended] = useState(false);
  const [usageTypes, setUsageTypes] = useState([]);
  const [selectedUsageType, setSelectedUsageType] = useState(null);

  const [isMailModalOpened, setIsMailModalOpened] = useState(false);
  const [isCoverageModalOpen, setIsCoverageModalOpen] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [isCoverageChanged, setIsCoverageChanged] = useState(null);
  const [isCoverageUpdated, setIsCoverageUpdated] = useState(false);

  const [isKidModelOpen, setIsKidModalOpen] = useState(false);
  const [selectedKidOffer, setSelectedKidOffer] = useState(null);

  const [shouldOffersRerender, setShouldOffersRerender] = useState(null);

  const [loadingCounter, setCounter] = useState(1);
  const [newTempCoverage, setNewTempCoverage] = useState({});

  const [hasFailAtInsurer, setHasFailAtInsurer] = useState(false);
  const [insurerFailType, setInsurerFailType] = useState("");
  const [failedInsurerTId, setFailedInsurerTId] = useState(null);
  const [showPertDescription, setShowPertDescription] = useState(false);
  const [pertOffer, setPertOffer] = useState(null);
  const [mailAddress, setMailAddress] = useState("");
  const [mainFilter, setMainFilter] = useState(mainFilters.all);
  const [dataLayerEventCounter, setDataLayerEventCounter] = useState(0);
  const offerRef = useRef(filteredOffers);
  offerRef.current = offers;
  const dataLayerEventCounterRef = useRef(dataLayerEventCounter);
  dataLayerEventCounterRef.current = dataLayerEventCounter;

  const [notOfferTrafik, setNotOfferTrafik] = useState([]);
  const [notOfferKasko, setNotOfferKasko] = useState([]);

  const [isCascoRenewal, setIsCascoRenewal] = useState(null);
  const [isTrafficRenewal, setIsTrafficRenewal] = useState(null);

  const [hasKpsError, setHasKpsError] = useState(null);
  const [callcenterInfo, setCallcenterInfo] = useState(null);
  const [policyEndDates, setPolicyEndDates] = useState(null)

  const [showCCCrossSell, setShowCCCrossSell] = useState(false)

  const { show, isProgressBarActive } = useSelector((state) => state.loading);
  const { quoteCardCampaignBanner } = useSelector((state) => state.campaign);
  const { isVkn } = useSelector((state) => state.info);

  const isKoalay = document.location.href.indexOf("koalay") >= 0;
  const dispatch = useDispatch();
  const URL_PARAMS = new URLSearchParams(document.location.search);
  const jt = URL_PARAMS.get("jt");

  const insurer = {};
  // const product = {};
  const subType = {};
  const GenerateQueueGuid = () =>
    "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0;
      const v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  const qGuid = URL_PARAMS.get("qGuid") !== null ? URL_PARAMS.get("qGuid") : props.location.state.qGuid;
  const forcePrepare = URL_PARAMS.get("forcePrepare") !== null ? URL_PARAMS.get("forcePrepare") : false;
  const [queueNameState, setQueueName] = useState();
  var queueName;
  const client = new StompJs.Client({
    brokerURL: WS,
    connectHeaders: {
      login: "koalay",
      passcode: "koalay",
    },
    heartbeatIncoming: 5000,
    heartbeatOutgoing: 5000,
    reconnectDelay: 10,
  });
  const headers = {
    "auto-delete": true,
    "x-expires": 3600000,
  };

  const sendMail = (mailAddress) => {
    const postData = {
      QuoteModel: {
        quoteGuid: qGuid,
        customerEmail: mailAddress,
      },
    };

    dispatch(loadingKlActions.showKlLoadingEmpty());
    setIsMailModalOpened(false);
    mailService.sendMailTeminatlar(postData, (response) => {
      dispatch(loadingKlActions.hideKlLoading());
      const { success } = response;
      dispatch(
        toastrActions.setToastrMessage({
          variant: success ? "success" : "error",
          message: success
            ? "Poliçe girdiğiniz e-posta adresine gönderildi. Sistemin yoğunluğuna göre azami 10 dakika içerisinde ulaşacaktır."
            : "Poliçe girdiğiniz e-posta adresine gönderilemedi.",
        })
      );
    });
  };

  const onSubmit = (data) => {
    sendMail(data.email);
  };

  const handleClearFilter = () => {
    setSubTypeFilterList(["all"]);
    setTypeFilterList(["all"]);
    setBrandFilterList(["all"]);
    setMainFilter(mainFilters.all);
  };

	const handleChangeCarType = () => {
		setIsCarTypeModalOpended(true)
  };

  const handleClose = () => {
    setIsCoverageModalOpen(false);
    setSelectedOffer(null);
    setIsCoverageChanged(false);
  };

  const replaceOfferWithNew = (b) => {
    if (b.TeklifTID && b.TeklifTID !== 0) {
      if (isCoverageUpdated && filteredOffers.some((filtered) => filtered.ProductId === b.ProductId)) {
        b.compare = false;
        const updatedOfferList = filteredOffers.map((fo) => {
          if (fo.ProductId === b.ProductId) {
            fo = b;
            setSelectedOffer(b);
          }
          return fo;
        });
        setOffers(updatedOfferList);
        dispatch(loadingKlActions.hideKlLoading());
      } else {
        b.compare = false;
        if (offers.find((o) => o.TeklifTID === b.TeklifTID) === undefined) {
          setOffers((old) => [...old, b]);
          adjustFilters(b);
        }
      }
    } else if (b.TeklifTID === 0) {
      if (isCoverageUpdated && filteredOffers.some((filtered) => filtered.ProductId === b.ProductId) && b.MessageId) {
        errorOnOffer(b);
      } else {
        if (b.Brans === "Trafik") {
          setNotOfferTrafik((prev) => [...prev, b]);
        } else if (b.Brans === "Kasko") {
          setNotOfferKasko((prev) => [...prev, b]);
        }
      }
    }
  };

  useEffect(() => {
    replaceOfferWithNew(newTempCoverage);
  }, [newTempCoverage]);

  useEffect(() => {
    setFilteredOffers(applyFilterToOffers());
  }, [offers]);

  const adjustFilters = (offer, shouldTypesRender = true) => {
    const product = productFilters;
    if (shouldTypesRender) {
      // Product Type
      if (product[offer.Brans] !== undefined) {
        product[offer.Brans] += 1;
      } else {
        product[offer.Brans] = 1;
      }
    }
    // Insurer
    if (insurer[offer.InsurerCommercialName] !== undefined) {
      insurer[offer.InsurerCommercialName] += 1;
    } else {
      insurer[offer.InsurerCommercialName] = 1;
    }

    // Product Category
    if (subType[offer.SubTypeTag[0]] !== undefined) {
      subType[offer.SubTypeTag[0]] += 1;
    } else {
      subType[offer.SubTypeTag[0]] = 1;
    }
    if (mainFilter !== mainFilters.insurer) {
      setInsurerFilters({ ...insurer });
    }

    if (mainFilter !== mainFilters.subType) {
      setSubTypeFilter({ ...subType });
    }
    if (shouldTypesRender) {
      setProductFilters({ ...product });
    }
  };

  const filterByType = ({ type, value }) => {
    if (typeFilterList.length === 2) {
      setBrandFilterList(["all"]);
      setSubTypeFilterList(["all"]);
    }
    if (type !== "all") {
      if (value) {
        setTypeFilterList((old) => [...old.filter((o) => o !== "all"), type]);
      } else {
        setTypeFilterList((old) => {
          const temp = old.filter((o) => o !== type);
          if (temp.length === 0) {
            temp.push("all");
          }
          return [...temp];
        });
      }
    } else {
      setTypeFilterList(["all"]);
    }
  };

  const buyButtonClicked = (offer, fromChild) => {
    if (fromChild) {
      if (offer.Subtitle.toLowerCase().indexOf("pert") >= 0 || offer.Subtitle.toLowerCase().indexOf("mini") >= 0) {
        setShowPertDescription(true);
        setPertOffer(offer);
      } else {
        if (offer.Brans === "Trafik" && offer.Insurer === "GUNES") {
          setHasFailAtInsurer(true);
          setInsurerFailType("Türkiye Sigorta Trafik");
					setFailedInsurerTId(offer.TeklifTID)
        }
        else {
          window.dataLayer.push({ ecommerce: null });
          pushSelectItemEvent(offer, localStorage.getItem("latestEvent"), window.dataLayer);
          props.history.push({
            pathname: "/quotation/confirmation",
            search: `${props.history.location.search}&teklifTID=${offer.TeklifTID}&bransType=${offer.Brans}`,
            state: { teklifTID: offer.TeklifTID, bransType: offer.Brans, quoteGuid: qGuid },
          });
        }
      }
    } else {
      if (offer.Brans === "Trafik" && offer.Insurer === "GUNES") {
        setHasFailAtInsurer(true);
        setInsurerFailType("Türkiye Sigorta Trafik");
				setFailedInsurerTId(offer.TeklifTID)
      } else {
        window.dataLayer.push({ ecommerce: null });
        pushSelectItemEvent(offer, localStorage.getItem("latestEvent"), window.dataLayer);
        props.history.push({
          pathname: "/quotation/confirmation",
          search: `${props.history.location.search}&teklifTID=${offer.TeklifTID}&bransType=${offer.Brans}`,
          state: { teklifTID: offer.TeklifTID, bransType: offer.Brans, quoteGuid: qGuid },
        });
      }
    }
  };

  const filterByInsurer = ({ type, value }) => {
    let mailFilterTemp = mainFilter;
    if (mailFilterTemp === mainFilters.all && mainFilter !== mainFilters.insurer) {
      mailFilterTemp = mainFilters.insurer;
    }

    if (type !== "all") {
      if (value) {
        if (mailFilterTemp === mainFilters.insurer) setBrandFilterList((old) => [...old, type]);
        else setBrandFilterList((old) => [...old.filter((o) => o !== "all"), type]);
      } else {
        setBrandFilterList((old) => {
          const temp = old.filter((o) => o !== type);
          if (temp.length === 0) {
            temp.push("all");
          }
          if (temp.length === 1 && temp[0] === "all" && mailFilterTemp === mainFilters.insurer) {
            mailFilterTemp = mainFilters.all;
          }
          return [...temp];
        });
      }
    } else {
      setBrandFilterList(["all"]);
    }
    setMainFilter(mailFilterTemp);
  };

  const filterBySubType = ({ type, value }) => {
    let mailFilterTemp = mainFilter;
    if (mailFilterTemp === mainFilters.all && mainFilter !== mainFilters.subType) {
      mailFilterTemp = mainFilters.subType;
    }
    if (type !== "all") {
      if (value) {
        if (mailFilterTemp === mainFilters.subType) setSubTypeFilterList((old) => [...old, type]);
        else setSubTypeFilterList((old) => [...old.filter((o) => o !== "all"), type]);
      } else {
        setSubTypeFilterList((old) => {
          const temp = old.filter((o) => o !== type);
          if (temp.length === 0) {
            temp.push("all");
          }
          if (temp.length === 1 && temp[0] === "all" && mailFilterTemp === mainFilters.subType) {
            mailFilterTemp = mainFilters.all;
          }
          return [...temp];
        });
      }
    } else {
      setSubTypeFilterList(["all"]);
    }
    setMainFilter(mailFilterTemp);
  };

  const applyFilterToOffers = () =>
    offers.filter((offer) => {
      const shouldPertShown =
        typeFilterList.some((type) => offer.Brans === type || (type === "all" && typeFilterList.length === 1)) &&
        brandFilterList.some(
          (brand) => offer.InsurerCommercialName === brand || (brand === "all" && brandFilterList.length === 1)
        ) &&
        subTypeFilterList.some((offerSubType) =>
          offerSubType === "all" && subTypeFilterList.length === 1
            ? offer.SubTypeTag[0] === "PertKasko" || offer.SubTypeTag[0] === "Limited"
            : false
        );
      const isOfferListed =
        typeFilterList.some((type) => offer.Brans === type || (type === "all" && typeFilterList.length === 1)) &&
        brandFilterList.some(
          (brand) => offer.InsurerCommercialName === brand || (brand === "all" && brandFilterList.length === 1)
        ) &&
        subTypeFilterList.some((offerSubType) =>
          offerSubType === "all" && subTypeFilterList.length === 1
            ? offer.SubTypeTag[0] !== "PertKasko" && offer.SubTypeTag[0] !== "Limited"
            : false || offer.SubTypeTag[0] === offerSubType
        );
      if (isOfferListed) adjustFilters(offer, false);
      else if (shouldPertShown) adjustFilters(offer, false);
      return isOfferListed;
    });

  useEffect(() => {
    if (carInfo?.brandCode) {
      vehicleService.getVehicleUsageTypes({ vehicleBrandId: carInfo.brandCode }, (result) => {
        setUsageTypes(result.vehicleUsageTypeNameList);
        const stringUsageType = carInfo.usageType.toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        });
        setSelectedUsageType(result.vehicleUsageTypeNameList.find((type) => type.Value === stringUsageType));
      });
    }
  }, [carInfo]);

  useEffect(() => {
    dispatch(shellActions.setThemeByPage(true));
    quotationService.getQuotationFilters((resp) => {
      setFilterInsurers(resp.insurers);
      setFilterProductGroups(resp.productGroups);
      setFilterProductSubTypeModels(resp.productSubTypeModels);
    });
    dispatch(stepActions.setStep(3));

    subscribeToSocket();

    EventBus.on("openDrawer", () => {
      setIsOpen(true);
    });
    EventBus.on("closeDrawer", () => {
      setIsOpen(false);
    });
		dispatch(headerActions.setUseHeader(true));
  }, []);

  /* #region dataLayer operations */

  //* Sending for 10 seconds.
  useEffect(() => {
    setTimeout(() => {
      if (dataLayerEventCounterRef.current === 0) {
        parseOffersAndPushToDatalayer(offerRef.current, 10, window.dataLayer);
        localStorage.setItem("latestEvent", 10);
        setDataLayerEventCounter((dataLayerEventCounter) => dataLayerEventCounter + 1);
      }
    }, 10000);
  }, []);

  //* Sending for 30 seconds.
  useEffect(() => {
    setTimeout(() => {
      if (dataLayerEventCounterRef.current === 1) {
        parseOffersAndPushToDatalayer(offerRef.current, 30, window.dataLayer);
        localStorage.setItem("latestEvent", 30);
        setDataLayerEventCounter((dataLayerEventCounter) => dataLayerEventCounter + 1);
      }
    }, 30000);
  }, []);

  //* Sending for 50 seconds.
  useEffect(() => {
    setTimeout(() => {
      if (dataLayerEventCounterRef.current === 2) {
        parseOffersAndPushToDatalayer(offerRef.current, 50, window.dataLayer);
        localStorage.setItem("latestEvent", 50);
        setDataLayerEventCounter((dataLayerEventCounter) => dataLayerEventCounter + 1);
      }
    }, 50000);
  }, []);

  //* Sending after progress bar is hidden.
  useEffect(() => {
    if (dataLayerEventCounterRef.current > 0 && !isProgressBarActive) {
      parseOffersAndPushToDatalayer(offerRef.current, "end", window.dataLayer);
      localStorage.setItem("latestEvent", "end");
      setDataLayerEventCounter((dataLayerEventCounter) => dataLayerEventCounter + 1);
    }
  }, [isProgressBarActive]);

  /* #endregion */

  const subscribeToSocket = () => {
    queueName = `KoalayWebQuotation-${qGuid}-${GenerateQueueGuid()}`;
    setQueueName(queueName);
    client.onConnect = function (frame) {
      // Do something, all subscribes must be done is this callback
      // This is needed because this will be executed after a (re)connect
      client.subscribe(
        queueName,
        (message) => {
          if (message && message.body !== "") {
            const body = JSON.parse(message.body);
            body.map((b) => {
              if (!isVkn && b.TeklifTID === 0) {
                const { MessageId } = b;
                const kpsErrorKeyWords = [
                  "kps",
                  "isim ",
                  "soyisim",
                  " ad ",
                  "doğum tarihi",
                  "doğum",
                  "dogum",
                  "name'",
                  "birthdate",
                  "birth",
                ];
                const hasError = kpsErrorKeyWords.some((word) => MessageId.toLocaleLowerCase("tr").includes(word));
                if (hasError) {
                  // Rollbar.info(`User has KPS error jt:${jt} qGuid:${qGuid}`);
                  setHasKpsError(true);
                }
              }
              if (b.TeklifTID !== undefined && b.TeklifTID !== null && b.Brans !== "Imm" && b.BranchType !== 15) {
                b.isTeminatOpen = false;
                setNewTempCoverage(b);
              }
            });
          }
        },
        headers
      );
    };
  };

  const errorOnOffer = (b) => {
    if (isCoverageModalOpen) {
      setIsCoverageChanged(true);
      setIsCoverageUpdated(false);
    }
    dispatch(toastrActions.setToastrMessage({ variant: "error", message: b.MessageId }));
    dispatch(loadingKlActions.hideKlLoading());
  };

  const subscribeToRaySocket = (qname) => {
    client.activate();
    client.subscribe(
      qname,
      (message) => {
        if (message && message.body !== "") {
          const body = JSON.parse(message.body);
          body.map((b) => {
            if (b.TeklifTID) {
              setNewTempCoverage(b);
              dispatch(loadingKlActions.hideKlLoading());
            } else {
              errorOnOffer(b);
            }
          });
        }
      },
      headers
    );
  };

  useEffect(() => {
    if (!showPertDescription) {
      setPertOffer(null);
    }
  }, [showPertDescription]);

  useEffect(() => {
    client.forceDisconnect();
    setOffers([]);
    setFilteredOffers([]);
    setInsurerFilters({});
    setSubTypeFilter({});
    setProductFilters({});
    setSubTypeFilterList(["all"]);
    setTypeFilterList(["all"]);
    setBrandFilterList(["all"]);
    setCounter(1);
    getOffers();
    setTimeout(() => {
      setOfferEnded(true);
    }, 30000);
  }, [shouldOffersRerender]);

	const sendToInfo = () => {
		const searchString = setSearchString(``);
		window.location.href = `${location.origin}/quotation/info${searchString ? `?${searchString}` : ''}`;
	}

	const generateAndRedirect = () => {
	const SbnCode = URL_PARAMS.get("campaignSbn");
		quotationService.getGenerateRedirectedQuote(jt, qGuid, SbnCode, 1, (response, hasError = false) => {
			if (hasError) {
				sendToInfo();
			} else {
				const params = new URLSearchParams(window.location.search);
				params.set('jt', response.userId);
				params.set('qGuid', response.redirectedQuoteId);
				params.delete('forcePrepare');
				const newSearchParams = setSearchString(params.toString());
				const { location } = window;
				const redirectUrl = `${location.origin + location.pathname}?${newSearchParams}`;
				window.location.href = redirectUrl
			}
		});
	}

	const checkisOldAndRedirect = (prequoteResponse, callback) => {
		const {isOldQuote, ruhsatNo, processStep} = prequoteResponse;
		if(isOldQuote && !forcePrepare){
			const params = new URLSearchParams(window.location.search);
			params.set('forcePrepare', 'true');
			const newSearchParams = setSearchString(params.toString());
			const { location } = window;
			const redirectUrl = `${location.origin + location.pathname}?${newSearchParams}`;
			window.location.href = redirectUrl
			return;
		}

		if(isOldQuote){
			if(!ruhsatNo){
				sendToInfo();
			}else if(processStep === 10){
				sendToInfo();
			}else{
				generateAndRedirect()
			}
		}else if(!isOldQuote && forcePrepare == 'true'){
			generateAndRedirect()
		}else{
			callback()
		}
	}

  const getOffers = () => {
    subscribeToSocket();
    // //CRM link regenerate forcePrepare
    // if (forcePrepare !== null && forcePrepare == "true") {
    //   regenerateCRMLink();
    // } 
      quotationService.preQuote(qGuid, forcePrepare, (prequoteResponse) => {
				checkisOldAndRedirect(prequoteResponse, () => {
					getRenewalPeriod(prequoteResponse);
					setShowCCCrossSell(prequoteResponse.creditCardCrossSellVisibility && !prequoteResponse.hasAkbankApply)
					setMailAddress(prequoteResponse.customerEMail);
					removeCustomEventsFromDataLayer(window.dataLayer);
					window.dataLayer.push({
						event: "result_page",
						funnel_variables: {
							jt,
							qGuid: qGuid,
						},
						ecommerce: null,
						customer_data: { email: prequoteResponse.customerEMail, phone: "+90" + prequoteResponse.telNo },
					});
					setCarInfo({
						year: prequoteResponse.vehicleModelYear,
						model: prequoteResponse.vehicleModelText,
						brand: prequoteResponse.vehicleBrandText,
						fuel: prequoteResponse.vehicleFuelType,
						brandCode: prequoteResponse.vehicleBrandCode,
						modelCode: prequoteResponse.modelKodBirlesik,
						usageType: prequoteResponse.vehicleTarifeCode,
						fuelType: prequoteResponse.vehicleFuelType,
						processStep: prequoteResponse.processStep,
					});
					setUserInfo({
						name: prequoteResponse.customerName,
						surname: prequoteResponse.customerSurname,
						customerTCNo: prequoteResponse.customerRegId,
						birthDate: formatDateToString(new Date(prequoteResponse.customerBirthDate), "/"),
					});
					setCallcenterInfo({
						appliedLevel: prequoteResponse.appliedLevel,
						customerIl: prequoteResponse.customerIl,
						customerIlce: prequoteResponse.customerIlce
					})

					if (prequoteResponse.masterOccupationId) {
						setSelectedOccupationId(prequoteResponse.masterOccupationId);
					}
					const blacklistObject = {
						Email: prequoteResponse.customerEMail,
						PhoneNumber: prequoteResponse.telNo,
						TcKimlikVergiNo: prequoteResponse.customerTCNo,
						UserUid: prequoteResponse.customerUID,
						IpNumber: prequoteResponse.customerIpNumber,
						Sbnkod: null,
						BlackListPage: 0,
					};
					commonService.blacklist(blacklistObject, (blResponse) => {
						if (blResponse.code === "0") {
							const { kaskoPoliceBitisTarihi, trafikPoliceBitisTarihi, policyStatus, isTrafficService, isCascoService } =
								prequoteResponse;
							let shouldCascoHidden = !!(kaskoPoliceBitisTarihi && checkRenevalPeriod(kaskoPoliceBitisTarihi))
								? false
								: policyStatus.hasKaskoPolicy;
							let shouldTrafikHidden = !!(trafikPoliceBitisTarihi && checkRenevalPeriod(trafikPoliceBitisTarihi, 15))
								? false
								: policyStatus.hasTrafficPolicy;
							const userPolicyStatus = JSON.parse(localStorage.getItem("userPolicyStatus"));
							if (userPolicyStatus && userPolicyStatus?.jt === jt) {
								shouldCascoHidden = userPolicyStatus.policyStatus.hasKaskoPolicy;
								shouldTrafikHidden = userPolicyStatus.policyStatus.hasTrafficPolicy;
							}

							const partaj = {
								isTraffic: isAgency ? isTrafficService : !shouldTrafikHidden,
								isCasco: isAgency ? isCascoService : !shouldCascoHidden,
								quoteId: qGuid,
								functionalBranchId: 1,
							};

							productService.getOnlinePartaj(partaj, (partajResponse) => {
								let quoteModel;
								if (prequoteResponse.masterOccupationId !== null) {
									quoteModel = partajToQuotes(partajResponse, queueName, qGuid, prequoteResponse.masterOccupationId);
								} else {
									quoteModel = partajToQuotes(partajResponse, queueName, qGuid);
								}
								client.activate();
								quotationService.getQuotes(quoteModel, (response) => {
									console.log(response);
								});
							});
						} else {
							dispatch(toastrActions.setToastrMessage({ variant: "error", message: blResponse.msg }));
							dispatch(loadingKlActions.showKlLoadingEmpty());
						}
					});
				})
      });
    
  };

  const getRenewalPeriod = (quote) => {
    const { trafikPoliceBitisTarihi, kaskoPoliceBitisTarihi } = quote;

    if (trafikPoliceBitisTarihi && !checkRenevalPeriod(trafikPoliceBitisTarihi, 15)) {
      setIsTrafficRenewal(trafikPoliceBitisTarihi);
    }

    if (kaskoPoliceBitisTarihi && !checkRenevalPeriod(kaskoPoliceBitisTarihi)) {
      setIsCascoRenewal(kaskoPoliceBitisTarihi);
    }

    if (kaskoPoliceBitisTarihi || trafikPoliceBitisTarihi) {
      setPolicyEndDates({
        kaskoEndDate: kaskoPoliceBitisTarihi?.replaceAll('-', '/'),
        trafikEndDate: trafikPoliceBitisTarihi?.replaceAll('-', '/')
      })
    }
  };

  const showNoOfferContainer =
    !isProgressBarActive &&
    !startLoading &&
    ((!isCascoRenewal && offers.filter((offer) => offer.Brans === "Kasko")?.length <= 0) ||
      (!isTrafficRenewal && offers.filter((offer) => offer.Brans === "Trafik")?.length <= 0));

  const updateQuote = (coverageGroups, productId) => {
    const quoteModel = {
      AdjustmentFactor: 1,
      BranchType: 1,
      IsAlternate: false,
      IsDefaultCoverage: false,
      ProductId: productId,
      QueueName: queueNameState,
      QuotationCoverageItem: {
        CoverageGroups: [...coverageGroups],
      },
      QuotationUId: qGuid,
    };
    quotationService.quote(quoteModel, (response) => {
      setIsCoverageChanged(false);
      setIsCoverageUpdated(true);
      dispatch(loadingKlActions.showKlLoadingEmpty());
    });
  };

  useEffect(() => {
    setFilteredOffers([]);
    if (mainFilter !== mainFilters.insurer) {
      setInsurerFilters({});
    }
    if (mainFilter !== mainFilters.subType) {
      setSubTypeFilter({});
    }

    setShouldApplyFilter(!shouldApplyFilter);
  }, [typeFilterList, brandFilterList, subTypeFilterList]);

  useEffect(() => {
    setFilteredOffers(applyFilterToOffers());
  }, [shouldApplyFilter]);

  useEffect(() => {
    if (filteredOffers && filteredOffers.length > 1 && show) {
      dispatch(loadingKlActions.hideKlLoading());
      setStartLoading(false);
      dispatch(loadingKlActions.setProgressBar(true));
    } else if (loadingCounter > 20 && filteredOffers && filteredOffers.length === 1 && show) {
      dispatch(loadingKlActions.hideKlLoading());
      setStartLoading(false);
      dispatch(loadingKlActions.setProgressBar(true));
    }
  }, [filteredOffers]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (loadingLimit < loadingCounter + 1) {
        dispatch(loadingKlActions.hideKlLoading());
        setStartLoading(false);
        clearInterval(interval);
      } else {
        setCounter(loadingCounter + 1);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [loadingCounter]);

  useEffect(() => {
    dispatch(loadingKlActions.showKlLoadingDefault());
    if (selectedOccupationId !== null) {
      const occ = occupationList.find((o) => o.value === parseInt(selectedOccupationId, 10));
      setSelectedOccupation(occ);
    } else {
      setSelectedOccupation(null);
    }
  }, [selectedOccupationId]);

  useEffect(() => {
    EventBus.on("openCoverageModal", (coverageOffer) => {
      setSelectedOffer(coverageOffer);
      setIsCoverageModalOpen(true);
    });
    EventBus.on("openKidDiscount", (kidOffer) => {
      setSelectedKidOffer(kidOffer);
      setIsKidModalOpen(true);
    });
    EventBus.on("updateKidOffer", (data) => {
      const [day, month, year] = data.birthdate !== undefined ? data.birthdate.split("/") : [null, null, null];
      const qName = `KoalayWebQuotation-${qGuid}-${GenerateQueueGuid()}`;
      subscribeToRaySocket(qName);
      const quoteModel = {
        AdjustmentFactor: 1,
        KidBirthDate: year !== null ? [year, month, day].join("-") : null,
        KidIdentityNumber: data.tckn,
        ProductId: data.productId,
        QueueName: qName,
        QuotationUId: qGuid,
      };
      quotationService.quote(quoteModel, (response) => {
        dispatch(loadingKlActions.showKlLoadingEmpty());
      });
    });
    commonService.getOccupations((occupations) => {
      setOccupationList([...occupations.occupationViewModels]);
    });
  }, []);

  const regenerateCRMLink = () => {
    quotationService.getGenerateRedirectedQuote(jt, qGuid, 1, (response, hasError = false) => {
      dispatch(loadingKlActions.hideKlLoading());
      if (hasError) {
        window.location.href = MAIN_URL + "/quotation/info";
      } else {
        // like: http://testteklif.koalay.com/quotation/summary?jt=...&qGuid=...
        const params = new URLSearchParams(window.location.search);
        params.set("qGuid", response.redirectedQuoteId);
        params.set("jt", response.userId);
        params.delete("forcePrepare");
        const newSearchParams = params.toString();
        const { location } = window;
        const redirectUrl = `${location.origin + location.pathname}?${newSearchParams}`;
        window.location.href = redirectUrl;
      }
    });
  };

  const addToComparissionList = (compOffer) => {
    const tempComparission = [...comparissionList];
    if (tempComparission.find((offer) => offer.TeklifTID === compOffer.TeklifTID)) {
      setComparissionList(tempComparission.filter((offer) => offer.TeklifTID !== compOffer.TeklifTID));
      if (tempComparission.filter((offer) => offer.TeklifTID !== compOffer.TeklifTID).length <= 1) {
        closeComparission();
      }
      filteredOffers.find((offer) => {
        if (offer.TeklifTID === compOffer.TeklifTID) {
          offer.compare = false;
        }
      });
    } else if (
      tempComparission.length === 0 ||
      (tempComparission.length > 0 && tempComparission[0].Brans === compOffer.Brans && tempComparission.length < 3)
    ) {
      filteredOffers.find((offer) => {
        if (offer.TeklifTID === compOffer.TeklifTID) {
          offer.compare = true;
        }
      });
      setComparissionList((old) => [...tempComparission, compOffer]);
      return true;
    } else {
      if (tempComparission.length === 3) {
        dispatch(
          toastrActions.setToastrMessage({ variant: "error", message: "En fazla 3 adet teklif karşılaştırılabilir." })
        );
      } else if (tempComparission[0].Brans !== compOffer.Brans) {
        dispatch(
          toastrActions.setToastrMessage({ variant: "error", message: "Hem trafik hem kasko teklifi ekleyemezsiniz." })
        );
      }
      return false;
    }
  };

  const closeComparission = () => {
    setIsComparissionModalOpen(false);
  };
  return useMemo(
    () =>
      offers.length > 0 || show || startLoading ? (
        <div className="">
          <span className="offer-page">
            {!show && <ProgressBar initialCounter={loadingCounter} />}
            {isMobile && <Steps className="steps" isHidden={isProgressBarActive} />}
            {hasKpsError && (
							<KPSWarningCard
								startLoading={startLoading}
								isProgressBarActive={isProgressBarActive}
							/>
						)}
						<RenewalCard
              startLoading={startLoading}
              isProgressBarActive={isProgressBarActive}
              isCascoRenewal={isCascoRenewal}
              isTrafficRenewal={isTrafficRenewal}
            />
            {isMobile && (
              <NoOffer
                startLoading={startLoading}
                isProgressBarActive={isProgressBarActive}
                offers={offers}
                isCascoRenewal={isCascoRenewal}
                isTrafficRenewal={isTrafficRenewal}
              />
            )}
            <div
              className={
                !showNoOfferContainer ? "offer-page-container" : "offer-page-container no-offer-offer-page-container"
              }
            >
              {!isMobile && (
                <OfferFilters
                  filterInsurers={filterInsurers}
                  filterProductGroups={filterProductGroups}
                  filterProductSubTypeModels={filterProductSubTypeModels}
                  subTypeFilter={subTypeFilter}
                  insurerFilter={insurerFilters}
                  productFilter={productFilters}
                  filterByType={filterByType}
                  filterByInsurer={filterByInsurer}
                  filterBySubType={filterBySubType}
                  carInfo={carInfo}
                  userInfo={userInfo}
                  setUserInfo={setUserInfo}
                  typeFilterList={typeFilterList}
                  brandFilterList={brandFilterList}
                  subTypeFilterList={subTypeFilterList}
                  filteredOffers={filteredOffers}
                  isLoading={!offerEnded || show}
                  kaskoCount={productFilters.Kasko}
                  handleClearFilter={handleClearFilter}
                  setIsCarTypeModalOpended={setIsCarTypeModalOpended}
                  selectedUsageType={selectedUsageType}
                  usageTypes={usageTypes}
                  noOffershown={showNoOfferContainer}
                  setShouldOffersRerender={setShouldOffersRerender}
                  history={props.history}
                  setSelectedUsageType={setSelectedUsageType}
                  CCinfo={callcenterInfo}
                  policyEndDates={policyEndDates}
                />
              )}
              <div
                className="offer-list"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  marginLeft: !isMobile ? "24px" : "",
                }}
              >
                {!isMobile && (
                  <NoOffer
                    startLoading={startLoading}
                    isProgressBarActive={isProgressBarActive}
                    offers={offers}
                    isCascoRenewal={isCascoRenewal}
                    isTrafficRenewal={isTrafficRenewal}
                  />
                )}
                <span className="offer-page-description">
                  <span
                    className="teklif-count"
                    style={{ marginBottom: productFilters.Kasko === undefined ? "10px" : "0" }}
                  >
                    <img src={MegaphoneSVG} width={isMobile ? 20 : 24} height={isMobile ? 20 : 24} />
                    <p className="disclaimer">
                      Sizin için
                      {productFilters.Trafik > 0 && (
                        <b style={{ color: "#599409" }}>
                          &nbsp;
                          {productFilters.Trafik}
                          &nbsp;trafik
                          {productFilters.Kasko === undefined && <>&nbsp;</>}
                        </b>
                      )}
                      {productFilters.Kasko > 0 && productFilters.Trafik > 0 && (
                        <b style={{ color: "#599409" }}>,&nbsp;</b>
                      )}
                      {productFilters.Kasko > 0 && (
                        <>
                          {productFilters.Trafik === undefined && <>&nbsp;</>}
                          <b style={{ color: "#1B7EDC" }}>
                            {productFilters.Kasko}
                            &nbsp;kasko&nbsp;
                          </b>
                        </>
                      )}
                      teklifi bulduk ve fiyata göre sıraladık.
                    </p>
                  </span>
                </span>
                <div className="teklif-action-container">
                  {productFilters.Kasko !== undefined && (
                    <>
                      {selectedOccupation !== null ? (
                        <span className="occupation-selection" onClick={() => setOccupationModal(true)}>
                          <img src={editSVG} width={isMobile ? 20 : 24} height={isMobile ? 20 : 24} />
                          <p>
                            Mesleğiniz:&nbsp;
                            {selectedOccupation.text}
                          </p>
                        </span>
                      ) : (
                        <span className="occupation-selection" onClick={() => setOccupationModal(true)}>
                          <img src={discountSVG} width={isMobile ? 20 : 24} height={isMobile ? 20 : 24} />
                          <p>Meslek indirimi al</p>
                        </span>
                      )}
                    </>
                  )}
                  {filteredOffers.length > 0 && !isMobile && (
                    <span className="send-teklif-action" onClick={() => setIsMailModalOpened(true)}>
                      <img src={securedLetter} width={isMobile ? 20 : 24} height={isMobile ? 20 : 24} />
                      <p style={{ color: "#003b73", fontWeight: "700", fontSize: "16px" }}>
                        Teklifleri e-posta ile gönder
                      </p>
                    </span>
                  )}
                </div>

                {filteredOffers.length > 0
                  ? filteredOffers
                      .sort((a, b) => a.Premium - b.Premium)
                      .map((offer, i) => {
                        if (offer.TeklifTID !== 0) {
                          return (
                            <>
                              <Offer
                                offer={offer}
                                key={offer.TeklifTID}
                                addToComparissionList={addToComparissionList}
                                buyButtonClicked={buyButtonClicked}
                                campaignPromotionGift={quoteCardCampaignBanner}
                              />
															{((filteredOffers.length === 1 && i === 0) || (filteredOffers.length > 1 && i === 1)) && 
															offers.length >= 2 && (
																<CCCrossSell
																	key='CCCrossSell'
																	showCCCrossSell={showCCCrossSell}
																	jt={jt}
																	qGuid={qGuid}
																/>
															)}
                              {isMobile && i === 2 && filteredOffers.length > 0 && (
                                <span className="send-teklif-action" onClick={() => setIsMailModalOpened(true)}>
                                  <img src={securedLetter} width={isMobile ? 20 : 24} height={isMobile ? 20 : 24} />
                                  <p>Teklifleri e-posta ile gönder</p>
                                </span>
                              )}
                            </>
                          );
                        }
                      })
                  : new Array(3).fill().map(() => <SkeletonOffer key={`no-offer-${Math.random()}`} />)}
                <NotOfferContainer
                  isCascoRenewal={isCascoRenewal}
                  isTrafficRenewal={isTrafficRenewal}
                  offers={offers}
                  cascoOffers={notOfferKasko}
                  trafficOffers={notOfferTrafik}
                  isAgency={isAgency}
                  isMerchant={isMerchant}
                />
              </div>
            </div>

            {isMobile && (
              <Drawer anchor="left" open={isOpen} onClose={() => setIsOpen(false)}>
                <OfferFilters
                  filterInsurers={filterInsurers}
                  filterProductGroups={filterProductGroups}
                  filterProductSubTypeModels={filterProductSubTypeModels}
                  subTypeFilter={subTypeFilter}
                  insurerFilter={insurerFilters}
                  productFilter={productFilters}
                  filterByType={filterByType}
                  setUserInfo={setUserInfo}
                  filterByInsurer={filterByInsurer}
                  filterBySubType={filterBySubType}
                  carInfo={carInfo}
                  userInfo={userInfo}
                  typeFilterList={typeFilterList}
                  brandFilterList={brandFilterList}
                  subTypeFilterList={subTypeFilterList}
                  filteredOffers={filteredOffers}
                  handleClearFilter={handleClearFilter}
									selectedUsageType={selectedUsageType}
									usageTypes={usageTypes}
									setShouldOffersRerender={setShouldOffersRerender}
                  CCinfo={callcenterInfo}
                  policyEndDates={policyEndDates}
                />
              </Drawer>
            )}
            {occupationModal && occupationList.length !== 0 && (
              <Modal
                open={occupationModal}
                aria-labelledby="modal-modal-title"
                onClose={() => setOccupationModal(false)}
                aria-describedby="modal-modal-description"
              >
                <OccupationModal
                  occupationList={occupationList}
                  selectedOccupation={selectedOccupation}
                  setOccupationModal={setOccupationModal}
                  setShouldOffersRerender={setShouldOffersRerender}
                  setSelectedOccupationId={setSelectedOccupationId}
                />
              </Modal>
            )}
            {isCarTypeModalOpended && usageTypes.length !== 0 && (
              <Modal
                open={isCarTypeModalOpended}
                aria-labelledby="modal-modal-title"
                onClose={() => setIsCarTypeModalOpended(false)}
                aria-describedby="modal-modal-description"
              >
                <CarUsageTypesModal
                  history={props.history}
                  carInfo={carInfo}
                  usageTypes={usageTypes}
                  selectedUsageType={selectedUsageType}
                  setIsCarTypeModalOpended={setIsCarTypeModalOpended}
                  setShouldOffersRerender={setShouldOffersRerender}
                  setSelectedUsageType={setSelectedUsageType}
                />
              </Modal>
            )}
            {comparissionList.length > 0 && (
              <>
                {!minimizeCompare && (
                  <div className="compare-container">
                    <div className="inner-container">
                      <div className="compare-container-header">
                        <p className="disclaimer">Karşılaştırmak istediğin teklifleri ekle.</p>
                        <KeyboardArrowDownIcon
                          style={{
                            position: "absolute",
                            right: isMobile ? "25px" : "0px",
                            top: isMobile ? "2px" : "0px",
                            zoom: "0.8",
                          }}
                          onClick={() => setMinimizeCompare(true)}
                        />
                      </div>
                      {comparissionList.map((offer) => (
                        <div className="insurer-info">
                          <CloseIcon
                            onClick={() => addToComparissionList(offer)}
                            style={{
                              width: isMobile ? "22px" : "26px",
                              height: isMobile ? "22px" : "26px",
                              position: "absolute",
                              top: "5px",
                              right: "5px",
                              fill: "#373B3F",
                            }}
                          />
                          <img
                            src={getUsableLogo(offer).src}
                            alt={getUsableLogo(offer).alt}
                            height={isMobile ? 39 : 64}
                            width={isMobile ? 89 : 120}
                          />{" "}
                          <div className="offer-title" dangerouslySetInnerHTML={{ __html: offer.Subtitle }} />
                        </div>
                      ))}
                      {comparePlaceholder
                        .splice(comparissionList.length, comparePlaceholder.length - 1)
                        .map((placeholder) => (
                          <div className="insurer-info placeholder-info">
                            <div className="placeholder">{placeholder}</div>
                          </div>
                        ))}
                      {!isMobile && (
                        <Button
                          className="compare-button-real"
                          variant="contained"
                          size="medium"
                          aria-label="compare"
                          disabled={comparissionList.length <= 1}
                          onClick={() => setIsComparissionModalOpen(true)}
                        >
                          <SyncAltIcon sx={{ mr: 1, fontSize: 20 }} />
                          <p>Karşılaştır</p>
                        </Button>
                      )}
                    </div>
                    {isMobile && (
                      <Button
                        className="compare-button-real"
                        variant="contained"
                        size="medium"
                        aria-label="compare"
                        disabled={comparissionList.length <= 1}
                        onClick={() => setIsComparissionModalOpen(true)}
                      >
                        <SyncAltIcon sx={{ mr: 1, fontSize: 20 }} />
                        <p>Karşılaştır</p>
                      </Button>
                    )}
                  </div>
                )}
                {minimizeCompare && (
                  <div className="compare-button">
                    <Button
                      variant="extended"
                      size="medium"
                      aria-label="compare"
                      sx={{ backgroundColor: "#EBF3FB" }}
                      onClick={() => setMinimizeCompare(false)}
                    >
                      <SyncAltIcon sx={{ mr: 1, fontSize: 20 }} />
                      <p>Karşılaştır</p>
                      <div className="compare-count">{comparissionList.length}</div>
                    </Button>
                  </div>
                )}
              </>
            )}
            {isComparissionModalOpen && (
              <Modal open={isComparissionModalOpen} onClose={closeComparission}>
                <Comparission
                  comparissionList={comparissionList}
                  handleClose={closeComparission}
                  addToComparissionList={addToComparissionList}
                  buyButtonClicked={buyButtonClicked}
									isComparissionModalOpen={isComparissionModalOpen}
                />
              </Modal>
            )}
            <CenteredModal className="fail-at-insurer-modal" isOpen={hasFailAtInsurer} setOpen={setHasFailAtInsurer}>
              <HasFailAtInsurer
                insurerFailType={insurerFailType}
                setHasFailAtInsurer={setHasFailAtInsurer}
                failedInsurerTId={failedInsurerTId}
              />
            </CenteredModal>
            {pertOffer !== null && (
              <CenteredModal isOpen={showPertDescription} setOpen={setShowPertDescription}>
                <div className="fail-at-insurer">
                  <h3>
                    {pertOffer.Subtitle.toLowerCase().indexOf("pert") >= 0
                      ? "Pert Kasko Hakkında Bilgilendirme"
                      : "Mini Kasko Hakkında Bilgilendirme"}
                  </h3>
                  <p className="text">
                    {pertOffer.Subtitle.toLowerCase().indexOf("pert") >= 0
                      ? "Seçtiğiniz sigorta 'Pert Kasko' ürünüdür. Teminat kapsamına alınan bir olay nedeniyle aracın tam hasara uğraması ve eksperin onaylaması durumunda tazminat ödenen poliçe tipidir. Pert hasarı haricinde oluşan kısmi onarım masrafları için, bu poliçe kapsamında herhangi bir tazminat ödenmez."
                      : "Seçtiğiniz sigorta 'Mini Kasko' ürünüdür. Allianz Mini Kasko aracınızı hırsızlık, yanma, terör, doğal afetler(sel/su baskını,dolu yıldırım, fırtına, toprak kayması) gibi temel risklere karşı güvence altına alır. Bu üründe çarpma/çarpışma teminatı bulunmamaktadır"}
                  </p>
                  <Button
                    variant="contained"
                    className="button"
                    onClick={() => buyButtonClicked(pertOffer, false)}
                    style={{ marginTop: "20px", textTransform: "none" }}
                  >
                    Satın Al
                  </Button>
                </div>
              </CenteredModal>
            )}
            <Modal open={isCoverageModalOpen} onClose={handleClose}>
              <Coverage
                qGuid={qGuid}
                offer={selectedOffer}
                handleClose={handleClose}
                updateQuote={updateQuote}
                isCoverageChanged={isCoverageChanged}
                setIsCoverageChanged={setIsCoverageChanged}
                buyButtonClicked={buyButtonClicked}
								mailAddress={mailAddress}
              />
            </Modal>
          </span>
          {isMailModalOpened && (
            <Modal
              open={isMailModalOpened}
              aria-labelledby="modal-modal-title"
              onClose={() => setIsMailModalOpened(false)}
              aria-describedby="modal-modal-description"
            >
              <SendMailModal setSendMailModal={setIsMailModalOpened} onSubmit={onSubmit} mailAddress={mailAddress} />
            </Modal>
          )}
          {selectedKidOffer !== null && (
            <CenteredModal className="kid-discount-modal" isOpen={isKidModelOpen} setOpen={setIsKidModalOpen}>
              <KidDiscount
                offer={selectedKidOffer}
                setIsKidModalOpen={setIsKidModalOpen}
                queueNameState={queueNameState}
              ></KidDiscount>
            </CenteredModal>
          )}
        </div>
      ) : (
        <>
          <RenewalCard
            startLoading={startLoading}
            isProgressBarActive={isProgressBarActive}
            isCascoRenewal={isCascoRenewal}
            isTrafficRenewal={isTrafficRenewal}
            size={!isMobile && `large`}
          />
          <NoOffer
            startLoading={startLoading}
            offers={offers}
            size="large"
            isCascoRenewal={isCascoRenewal}
            isTrafficRenewal={isTrafficRenewal}
          />
          <NotOfferContainer
            size="large"
            isCascoRenewal={isCascoRenewal}
            isTrafficRenewal={isTrafficRenewal}
            offers={offers}
            cascoOffers={notOfferKasko}
            trafficOffers={notOfferTrafik}
            isAgency={isAgency}
            isMerchant={isMerchant}
          />
        </>
      ),
    [
      show,
      offers,
      filteredOffers,
      isMobile,
      isOpen,
      occupationModal,
      isMailModalOpened,
      occupationList,
      selectedOccupation,
      isCarTypeModalOpended,
      usageTypes,
      shouldOffersRerender,
      isProgressBarActive,
      offerEnded,
      selectedOffer,
      isCoverageChanged,
      startLoading,
      comparissionList,
      isComparissionModalOpen,
      minimizeCompare,
      hasFailAtInsurer,
      pertOffer,
      showPertDescription,
      isKidModelOpen,
      selectedKidOffer,
      notOfferKasko,
      notOfferTrafik,
      isCascoRenewal,
      isTrafficRenewal,
			showCCCrossSell,
    ]
  );
};

export default withRouter(OfferPage);
