import { showRequestError } from './errorToastr';

export const setSearchString = (string) => {
  let searchString = string;
  const URL_PARAMS = new URLSearchParams(document.location.search);
  const extrefhash = URL_PARAMS.get('extrefhash') || URL_PARAMS.get('extRefHash') || URL_PARAMS.get('extrefHash');
  const ReferenceId = URL_PARAMS.get('ReferenceId') || URL_PARAMS.get('referenceid');
  if (extrefhash && !searchString.includes('extrefhash')) {
    searchString = `${searchString}&extrefhash=${extrefhash}`;
  }
  if (ReferenceId && !searchString.includes('ReferenceId')) {
    searchString = `${searchString}&ReferenceId=${ReferenceId}`;
  }
  return searchString;
};

export const checkAPSAndGoSummary = (uid, quoteId, code, props, msg = '', dispatch, redirectUrl) => {
  if (code === '102') {
    // EventBus.emit('apsError');
    props.history.push({
      pathname: '/quotation/CityDistrict',
      search: props.history.location.search,
    });
  } else if (code === '99') {
    showRequestError('InvalidInformation', dispatch);
  } else {
    let url = '';
    if (redirectUrl !== '') {
      // // HK url example => https://stagingkasko.hesapkurdu.com/quotation/#/Summary/Quote?qGuid=E2A6D2E3-9962-429B-B8BE-B336E4CC4491&sbn=HSPKDLK&jt=95ec1fae-0a4b-4874-bbb6-8b4e908ae3c2
      // const URL_PARAMS = new URLSearchParams(document.location.search);
      // const jt = URL_PARAMS.get('jt');
      // // ? &r means the user is being redirected to a business partner
      // url = `${redirectUrl.split('#')[0].replace('?', '')}/summary?${redirectUrl.split('?')[2]}&jt=${jt}&r`;
      // window.location.href = url;
      window.location.href = redirectUrl;
    } else {
      url = '/quotation/summary';
      const searchString = setSearchString(`?jt=${uid}&qGuid=${quoteId}`);
      props.history.push({
        pathname: url,
        search: searchString,
        state: { qGuid: quoteId },
      });
    }
  }
};
