import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { withRouter } from 'react-router-dom';
import { Box } from '@mui/system';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import useWindowSize from '../../../../../shared/_hooks/windowResizeHook';

import './product-card.scss';
import { emailValidation } from '../../../../../shared/_helpers/validations';
import { getUsableLogo, openPDF, priceFormatter } from '../../../../../shared/_helpers/helperFunctions';
import EventBus from '../../../../../shared/_helpers/EventBus';
import { KLInput } from '../../../../../motor/views/components/form/KLInput';
import { returnCDNlink } from '../../../../helpers/stringHelpers';

export const ProductCard = (props) => {
  const size = useWindowSize();
  const isMobile = size.width <= 880;
  const { policy, customerInitialMail } = props;
  const dispatch = useDispatch();
  const [lastDate, setLastDate] = useState();
  const [showMail, setShowMail] = useState(false);
  const [commercialName, setCommercialName] = useState('');

  useEffect(() => {
    setCommercialName(returnCDNlink(policy.insurerCommercialName));
  }, [policy]);

  const getMonth = () => {
    const today = new Date();
    const newDate = new Date(today.setMonth(today.getMonth() + 1));
    const yyyy = newDate.getFullYear();
    let mm = newDate.getMonth() + 1; // Months start at 0!
    let dd = newDate.getDate();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;

    const formattedToday = `${dd}/${mm}/${yyyy}`;

    setLastDate(formattedToday);
  };
  useEffect(() => {
    getMonth();
  }, []);
  const {
    handleSubmit,
    formState: { errors, isValid, touchedFields },
    control,
  } = useForm({
    mode: 'onChange',
  });

  const onSubmit = (data) => {
    props.handleMailClicked({ mail: data.email, policy });
  };
  const showPolicy = () => {
    openPDF(policy.teklifTId, 'policy');
  };

  const showMakbuz = () => {
    // const pdfWindow = window.open();
    // documentService.getMakbuzPdf(policy.teklifTId, (response) => {
    //   pdfWindow.location.href = openPDF(response);
    // });
    openPDF(policy.teklifTId, 'receipt');
  };

  return (
    <>
      <Box className="product-card-tss">
        {!isMobile ? (
          <div className="product-card-container">
            <div className="product-left">
              <div className="insurer">
                <img
                  src={commercialName}
                  height={isMobile ? 45 : 64}
                  width={isMobile ? 84 : 120}
                />
                <p className="text" dangerouslySetInnerHTML={{ __html: policy.productDisplayName }} />
              </div>
              <div className="pdf-section">
                <Button variant="outlined" onClick={() => showPolicy()}>
                  Poliçeyi Göster
                </Button>
                <Button variant="outlined" onClick={() => showMakbuz()}>
                  Makbuzu Göster
                </Button>
              </div>
            </div>
            <div className="product-right">
							<h4>
								{priceFormatter.format(policy.grossPremium).replace('₺', '')}
								{' '}
								TL
							</h4>
              <div className="mail-section">
                <p className="assistive">
                  Poliçeniz
                  {' '}
                  {customerInitialMail ? (<b>{customerInitialMail}</b>) : 'girdiğiniz'}
                  {' '}
                  e-posta adresine gönderilmiştir, dilerseniz farklı bir adrese de gönderebilirsiniz.
                </p>
                <div className="send-mail">
                  <form>
                    <KLInput
                      name="email"
                      control={control}
                      label="E-Posta"
                      error={errors}
                      rules={emailValidation()}
                      touchedFields={touchedFields}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          width: '100%',
                          marginBottom: '0',
                          borderRadius: '4px 0 0 4px',
                          '&.Mui-error': {
                            width: '100%',
                            marginBottm: '0',
                          },
                        },
                        '.MuiInputLabel-formControl': {
                          fontSize: '15px',
                          marginBottom: '0',
                        },
                      }}
                    />
                    <Button
                      variant="contained"
                      disabled={!isValid}
                      style={{
                        width: '87px',
                        height: '45px',
                        boxSizing: 'border-box',
                        textTransform: 'none',
                      }}
                      onClick={handleSubmit(onSubmit)}
                    >
                      Gönder
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="product-card-container">
            <div className="insurer">
              <img
                src={commercialName}
                height={isMobile ? 45 : 48}
                width={isMobile ? 84 : 153}
              />
              <p className="disclaimer">
                {' '}
                {priceFormatter.format(policy.grossPremium).replace('₺', '')}
                {' '}
                TL
              </p>
            </div>
						<p className="disclaimer" dangerouslySetInnerHTML={{ __html: policy.productDisplayName }} />
            <div className="pdf-section" style={{ gridTemplateColumns: policy.isCrossSell === undefined ? '1fr 1fr' : '1fr' }}>
              <Button variant="outlined" onClick={() => showPolicy()}>
                Poliçeyi Göster
              </Button>
              {policy.isCrossSell === undefined && (
              <Button variant="outlined" onClick={() => showMakbuz()}>
                Makbuzu Göster
              </Button>
              )}
            </div>
            <div className="mail-section">
              <p className="assistive">
                Poliçeniz
                {' '}
                {customerInitialMail ? (<b>{customerInitialMail}</b>) : 'girdiğiniz'}
                {' '}
                e-posta adresine gönderilmiştir. Farklı bir adrese göndermek için
                {' '}
                <a style={{ color: '#0055a5', fontWeight: '700' }} onClick={() => { setShowMail(!showMail); }}>tıklayın.</a>
                {' '}

              </p>
              {showMail && (
              <div className="send-mail">
                <form>
                  <KLInput
                    name="email"
                    control={control}
                    label="E-Posta"
                    error={errors}
                    rules={emailValidation()}
                    touchedFields={touchedFields}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        width: '100%',
                        marginBottom: '0',
                        borderRadius: '4px 0 0 4px',
                        '&.Mui-error': {
                          width: '100%',
                          marginBottm: '0',
                        },
                      },
                      '.MuiInputLabel-formControl': {
                        fontSize: '15px',
                        marginBottom: '0',
                      },
                    }}
                  />
                  <Button
                    variant="contained"
                    disabled={!isValid}
                    style={{
                      width: '87px',
                      height: '45px',
                      boxSizing: 'border-box',
                      textTransform: 'none',
                    }}
                    onClick={handleSubmit(onSubmit)}
                  >
                    Gönder
                  </Button>
                </form>
              </div>
              )}
            </div>
          </div>
        )}
      </Box>

    </>
  );
};

export default withRouter(ProductCard);
