import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import '../../../../shared/assets/styles/pages/info.scss';
import '../../../../shared/assets/styles/components/user-aggrement-modal.scss';

import useWindowSize from '../../../../shared/_hooks/windowResizeHook';
import { KLInput } from './KLInput';
import { emailValidation} from '../../../../shared/_helpers/validations';
import { userAgreement } from '../../../data/shared';
import customerService from '../../../_services/customer.service';
import { useDispatch } from 'react-redux';
import loadingKlActions from '../../../actions/loadingActions';
import toastrActions from '../../../actions/toastrAction';

import store from '../../../../shared/store/store'

export const UserAggrementModal = (props) => {
  const size = useWindowSize();
	const dispatch = useDispatch();
  const isMobile = size.width <= 880;
  const URL_PARAMS = new URLSearchParams(document.location.search);
  const jt = URL_PARAMS.get('jt');
	const [aggrement, setAggrement] = useState(userAgreement);

  const {
    handleSubmit,
    reset,
    formState: { errors, isValid, touchedFields },
    control,
    setValue,
  } = useForm({
    mode: 'onChange',
  });

	useEffect(() => {
		dispatch(loadingKlActions.showKlLoadingEmpty());
		customerService.getUserAgreementHtml((response)=>{
			if(response?.userAgreement?.content){
				setAggrement(response.userAgreement.content)
			}
			dispatch(loadingKlActions.hideKlLoading());
		})
	}, [])

	const openPdfInTab = () => {
		window.open(`/quotation/downloadPdf?pdfType=user-aggrement&consumer=${btoa(store.getState().consumer.consumer)}`);
	};

	const openAggrement = () => {
			openPdfInTab()
	}

  const submitForm = (data) => {
		const {email} = data;
		customerService.sendUserAgreementEmail({ Email: email }, (response) => {
			dispatch(
        toastrActions.setToastrMessage({
          variant: 'success',
          message: 'Poliçeniz girdiğiniz e-posta adresine gönderildi. Sistemin yoğunluğuna göre azami 10 dakika içerisinde ulaşacaktır.',
        }),
      );
		})
  };

  return (
    <>
			{aggrement && typeof aggrement === 'string' && (
				<>
					<div className='user-aggrement' dangerouslySetInnerHTML={{__html: aggrement}}></div>
					<div className="aggrement-action-container">
						<Button variant="outlined" onClick={openAggrement}>
							İndir
						</Button>
						<div className="send-mail">
							<form>
								<KLInput
									name="email"
									control={control}
									label="E-Posta"
									error={errors}
									rules={emailValidation()}
									touchedFields={touchedFields}
									sx={{
										'&': {
											marginBottom: '0',
										},
												'& .MuiOutlinedInput-root': {
													width: '100%',
													marginBottom: '0',
													borderRadius: '4px 0 0 4px',
													'&.Mui-error': {
														width: '100%',
														marginBottm: '0',
													},
												},
												'.MuiInputLabel-formControl': {
													fontSize: '15px',
													marginBottom: '0',
												},
									}}
								/>
								<Button
									variant="contained"
									disabled={!isValid}
									style={{
												width: '87px',
												height: '45px',
												boxSizing: 'border-box',
												boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.4)',
												textTransform: 'none',
										borderRadius: '0 4px 4px 0',
									}}
									onClick={handleSubmit(submitForm)}
								>
									Gönder
								</Button>
							</form>
						</div>
					</div>
				</>
			)}
    </>
  );
};

export default withRouter(UserAggrementModal);

