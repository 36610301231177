import * as React from 'react';

export const useIsOverflow = (ref, callback, useWindow = false) => {
  const [isOverflow, setIsOverflow] = React.useState(undefined);

  React.useLayoutEffect(() => {
    const { current } = ref;

    const trigger = () => {
			const clientHeight = useWindow ? window.innerHeight : current.clientHeight
      const hasOverflow = current.scrollHeight > clientHeight;

      setIsOverflow(hasOverflow);

      if (callback) callback(hasOverflow);
    };

    if (current) {
      if ('ResizeObserver' in window) {
        new ResizeObserver(trigger).observe(current);
      }

      trigger();
    }
  }, [callback, ref]);

  return isOverflow;
};