import { createTheme } from '@mui/material/styles';
import { API } from '../../shared/_helpers/env';
import { interceptors } from '../../shared/_helpers/interceptors';
import axiosInstance from '../../shared/_helpers/axiosHelper';

async function checkOTPUser(OTP, callback) {
  interceptors.setXConsumer();
  await axiosInstance.post(`${API}/api/common/otp-check-user`, OTP).then((response) => {
    try {
      callback(response.data.Result);
    } catch (error) {
    }
  });
}
async function isInBlackList(blackList, callback) {
  await axiosInstance.post(`${API}/api/common/check-phone-number-in-black-list`, blackList).then((response) => {
    try {
      callback(response.data.Result.result);
    } catch (error) {
    }
  });
}
async function sendSMS(sms, callback) {
  await axiosInstance.post(`${API}/api/common/send-sms`, sms).then((response) => {
    try {
      callback(response.data.Result);
    } catch (error) {
    }
  });
}
async function checkOTP(otp, callback) {
  await axiosInstance.post(`${API}/api/common/otp-check`, otp).then((response) => {
    try {
      callback(response.data.Result);
    } catch (error) {
    }
  });
}
async function getOccupations(callback) {
  await axiosInstance.get(`${API}/api/common/masteroccupation`).then((response) => {
    try {
      callback(response.data.Result);
    } catch (error) {
    }
  });
}
async function getLossPayee(productId, parentId, callback) {
  await axiosInstance.get(`${API}/api/common/loss-payee?ProductId=${productId}${parentId ? `&parentId=${parentId}` : ''}`).then((response) => {
    try {
      callback(response.data.Result);
    } catch (error) {
    }
  });
}
async function getDitrictsByCityId(id, callback) {
  await axiosInstance.get(`${API}/api/common/districts?cityCode=${id}`).then((response) => {
    try {
      callback(response.data.Result);
    } catch (error) {
    }
  });
}
async function getCityList(callback) {
  await axiosInstance.get(`${API}/api/common/cities`).then((response) => {
    try {
      callback(response.data.Result);
    } catch (error) {
    }
  });
}
async function blacklist(blackList, callback) {
  await axiosInstance.post(`${API}/api/common/blacklist`, blackList).then((response) => {
    try {
      callback(response.data);
    } catch (error) {
    }
  });
}

async function getCampaign(callback) {
  try {
    const { data } = await axiosInstance.get(`${API}/api/common/customization/campaign`);
    callback(data);
  } catch (error) {
  }
}

async function getTheme(callback) {
  interceptors.setXConsumer();
  try {
    await axiosInstance.get(`${API}/api/common/customization/theme`).then((response) => {
      const theme = createTheme(JSON.parse(response.data.Result.dealerTheme.themeJson));
      const additionalInfo = response.data.Result.dealerTheme;
      try {
        callback(theme, additionalInfo);
      } catch (error) {

      }
    });
  } catch (error) {
    callback(null, true);
  }
}

async function getTssOccupationList(callback) {
  interceptors.setXConsumer();
  try {
    await axiosInstance.get(`${API}/api/common/tss-occupation`).then((response) => {
      try {
        callback(response.data);
      } catch (error) {

      }
    });
  } catch (error) {
  }
}

const commonService = {
  checkOTPUser,
  isInBlackList,
  sendSMS,
  checkOTP,
  getOccupations,
  getCityList,
  getDitrictsByCityId,
  getLossPayee,
  blacklist,
  getTheme,
  getCampaign,
  getTssOccupationList,
};
export default commonService;
