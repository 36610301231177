import React, { useState } from 'react';
import { Tooltip, styled, tooltipClasses } from '@mui/material';
import crossSVG from '../../../../../../shared/assets/img/svg/cross.svg';
import TickSVG from '../../../../../../shared/assets/img/svg/tick-green.svg';
import '../../../../../../shared/assets/styles/components/popular-coverages-v2.scss';
import infoSVG from '../../../../../../shared/assets/img/svg/info.svg';
import CenteredModal from '../../../../components/widget/CenteredModal';
import useWindowSize from '../../../../../../shared/_hooks/windowResizeHook';

const TrafficPopularv2 = (props) => {
  const size = useWindowSize();
  const [hintPopup, showHintPopup] = useState(null);
  const [hintText, setHintText] = useState(null);

  const isMobile = size.width <= 880;
  const { popularCoverages } = props;
  const hasExtraCoverage = popularCoverages.some((popular) => popular.isExtraCoverage);

  const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 13,
      },
    }),
  );

  const setHintDesc = (coverage) => {
    setHintText(coverage.coverageText);
    showHintPopup(coverage.hintText);
  };
  const closeHintPopup = () => {
    setHintText(null);
    showHintPopup(null);
  };

  return (
    <>
      <div className="teminat-container-v2" key="teminat-container">
        <div className="teminat-inner traffic-v2-teminat-inner">
          {popularCoverages.filter((popular) => !popular.isExtraCoverage).map((popular) => (
            <span className="teminat-detail" key={popular.id}>
              {popular.hasValue
                ? (<img className="coverage-icon" src={TickSVG} alt="Tick" />)
                : (<img className="coverage-icon" src={crossSVG} alt="Cross" />)}
              <span className="teminat-desc">
                <span className="coverage-text">
                  {popular.coverageText}
                  {popular.hintText && (
                  <LightTooltip
                    title={(
                      <span dangerouslySetInnerHTML={{ __html: popular.hintText }} />
                    )}
                  >
                    <img
                      src={infoSVG}
                      style={{ marginLeft: '8px' }}
                      alt="Info"
                      onClick={() => (isMobile ? setHintDesc(popular) : '')}
                    />
                  </LightTooltip>
                  )}
                </span>
                <span className="coverage-value-text">
                  {popular.coverageValueText
                    ? (popular.coverageValueText)
                    : ('')}
                </span>
              </span>
            </span>
          ))}
          {hasExtraCoverage && (
          <>
            <h4 className="coverage-ek-teminatlar">Ek Teminatlar</h4>
            {popularCoverages.filter((popular) => popular.isExtraCoverage).map((popular) => (
              <span className="teminat-detail" key={popular.id}>
                {popular.hasValue
                  ? (<img className="coverage-icon" src={TickSVG} alt="Tick" />)
                  : (<img className="coverage-icon" src={crossSVG} alt="Cross" />)}
                <span className="teminat-desc">
                  <span className="coverage-text">
                    {popular.coverageText}
                    {popular.hintText && (
                    <LightTooltip
                      title={(
                        <span dangerouslySetInnerHTML={{ __html: `<p className="disclaimer">${popular.hintText}</p>` }} />
                  )}
                    >
                      <img
                        src={infoSVG}
                        style={{ marginLeft: '8px' }}
                        alt="Info"
                        onClick={() => (isMobile ? setHintDesc(popular) : '')}
                      />
                    </LightTooltip>
                    )}
                  </span>
                  <span className="coverage-value-text">
                    {popular.coverageValueText
                      ? (popular.coverageValueText)
                      : ('')}
                  </span>
                </span>
              </span>
            ))}
          </>
          )}
        </div>
      </div>
      {hintPopup !== null && (
      <CenteredModal title={hintText} isOpen={hintPopup !== null} setOpen={closeHintPopup}>
        <br />
        <>
          <p className="disclaimer">{hintPopup}</p>
        </>
      </CenteredModal>
      )}
    </>
  );
};

export default TrafficPopularv2;
