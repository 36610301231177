import { stepTypes } from '../types/step.types';

const initialState = {
  step: 1,
  quotationUrl: '',
  fromInsureds: false,
  shouldRedirectCityDistrict: false,
};

export default function STEP_REDUCER(state = initialState, { type, payload }) {
  switch (type) {
    case stepTypes.INCREMENT_STEP:
      return {
        ...state,
        step: state.step + 1,
      };
    case stepTypes.DECREMENT_STEP:
      return {
        ...state,
        step: state.step - 1,
      };
    case stepTypes.SET_STEP:
      return {
        ...state,
        step: payload.step,
      };
    case stepTypes.SET_REDIRECT_URL:
      return {
        ...state,
        quotationUrl: payload.url,
      };
    case stepTypes.SET_FROM_INSUREDS:
      return {
        ...state,
        fromInsureds: payload.fromInsureds,
      };
    case stepTypes.SET_SHOULD_REDIRECT_CITY_DISTRICT:
      return {
        ...state,
        shouldRedirectCityDistrict: payload.shouldRedirectCityDistrict,
      };
    default:
      return state;
  }
}
