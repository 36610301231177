import { ROLLBAR_ENV, ROLLBAR_TOKEN } from '../src/shared/_helpers/env';

export const clientRollBarConfig = {
  accessToken: ROLLBAR_TOKEN,
  environment: ROLLBAR_ENV,
  enabled: ROLLBAR_ENV === 'production' || ROLLBAR_ENV === 'qa',
  autoInstrument: true,
  captureIp: 'anonymize',
  captureUncaught: false,
  captureUnhandledRejections: false,
  ignoreDuplicateErrors: true,
  itemsPerMinute: 5, // TODO check and maybe update
  ignoredMessages: ['Script error.', 'console.log', 'console.info', 'console.warn', 'console.error'],
  scrubFields: ['expiry', 'number', 'name', 'surname', 'lastName', 'lastname', 'cvc', 'mail', 'birthDate', 'tckn', 'phone', 'seri', 'no'],
};
