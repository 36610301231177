// import { birthDateValidation, wordValidation } from '../../../shared/_helpers/validations';
import React from 'react';
import { Button } from '@mui/material';
import BackButton from '../../views/components/BackButton/BackButton';
import store from '../../../shared/store/store';

import tssButtonStyle from '../tssButtonStyle';
import PlusSvg from '../../../shared/assets/img/svg/plus';
import { realationsEnum } from '../relations';
import Insured from '../../views/components/Insured/Insured';

const createInsuredsForm = (props) => {
  const {
    submitForm,
    advanceToFamily,
    deleteAndSetInsureds,
		isMobile,
    formHookObject: {
      handleSubmit,
      isValid,
      isFamilyMember,
    },
  } = props;

  const { insureds } = store.getState().info;

  const generateUniqKey = (insured) => insured.name.substr(0, 2) + insured.identityNo.substr(0, 4) + insured.identityNo.substr(-2) + Math.floor(Math.random() * 10);

  const formInsureds = () => {
    const mappedInsureds = [];
    insureds.forEach((insured) => {
      const uniqKey = generateUniqKey(insured);
      const tempInsured = {
        type: 'component',
        component: <Insured />,
        props: {
          insured,
          hasDelete: insured.relation !== realationsEnum.Kendisi,
          key: uniqKey,
          deleteAndSetInsureds,
        },
      };
      mappedInsureds[uniqKey] = tempInsured;
    });

    return mappedInsureds;
  };

  const formObject = {
    form: {
      ...formInsureds(),
      addNew: {
        type: 'button',
        component: <Button />,
        lg: 6,
        md: 6,
        xs: 12,
        props: {
          style: {
            ...tssButtonStyle,
            marginTop: isMobile ? '4px' : '12px',
            fontSize: '14px',
            fontWeight: '600',
          },
          disabled: !isValid,
          onClick: handleSubmit(advanceToFamily),
          variant: 'outlined',
        },
        children: (
          <>
            Sigortalanacak Aile Bireyi Ekle
            <PlusSvg className='insureds-plus' color={isValid ? '#0055A5' : '#00000042'} />
          </>
        ),
      },
      button: {
        type: 'button',
        component: <Button />,
        lg: 6,
        md: 6,
        xs: 12,
        props: {
          style: {
            ...tssButtonStyle,
            marginTop: '16px',
            fontSize: '14px',
            fontWeight: '600',
          },
          disabled: !isValid,
          onClick: handleSubmit(submitForm),
          variant: 'contained',
        },
        children: 'Teklif Al',
        hideMobile: true,
      },
      backbutton: {
        type: 'component',
        component: <BackButton />,
        hideMobile: true,
        show: isFamilyMember,
      },
    },
    bottom: {
      button: {
        type: 'button',
        component: <Button />,
        props: {
          style: {
            ...tssButtonStyle,
          },
          disabled: !isValid,
          onClick: handleSubmit(submitForm),
          variant: 'contained',
        },
        children: 'Teklif Al',
        mobile: true,
        show: isFamilyMember,
      },
    },
  };

  return formObject;
};

export default createInsuredsForm;
