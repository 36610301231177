import axiosInstance from '../../shared/_helpers/axiosHelper';
import { API } from '../../shared/_helpers/env';

async function cancelPolicy(data, callback) {
  await axiosInstance.post(`${API}/api/policy/online-policy-cancellation`, data).then((response) => {
    try {
      callback(response.data.Result);
    } catch (error) {
      console.log(error);
    }
  });
}
async function hasPolicy(data, callback) {
  const { identityNumber, phoneNumber } = data;

  await axiosInstance.get(`${API}/api/policy/has-customer-policy`, { params: { ...data } }).then((response) => {
    try {
      callback(response.data.Result);
    } catch (error) {
      console.log(error);
    }
  });
}
async function customerPolicies(data, callback) {
  await axiosInstance.get(`${API}/api/policy/customer-policies`, { params: { ...data } }).then((response) => {
    try {
      callback(response.data.Result);
    } catch (error) {
      console.log(error);
    }
  });
}
async function editEmail(data, callback) {
  await axiosInstance.post(`${API}/api/policy/online-policy-cancelation-contact`, data).then((response) => {
    try {
      callback(response.data);
    } catch (error) {
      console.log(error);
    }
  });
}

const policyService = {
  cancelPolicy,
  hasPolicy,
  customerPolicies,
  editEmail,
};
export default policyService;
