import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import loadingKlActions from '../../../actions/loadingActions';
import documentService from '../../../_services/document.service';
import headerActions from '../../../actions/headerAction';
import { useRollbar } from '@rollbar/react';

export const PdfView = (props) => {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const URL_PARAMS = new URLSearchParams(document.location.search);
  const quoteGuid = URL_PARAMS.get('qGuid');
  const teklifTID = URL_PARAMS.get('teklifTID');
  const pdfType = URL_PARAMS.get('pdfType');

  const Rollbar = useRollbar();

  const isIOS = () => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  };

  useEffect(() => {
    dispatch(headerActions.setUseHeader(false));
  }, [dispatch]);

  useEffect(() => {
    const startTime = Date.now();

    const callback = (response) => {
      const responseGot = Date.now();
      const midElapsedTime = (responseGot - startTime) / 1000;
      dispatch(loadingKlActions.hideKlLoading());
      const file = new Blob([response?.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      setData(fileURL);
      const endTime = Date.now();
      const elapsedTime = (endTime - responseGot) / 1000;
      Rollbar.info(`TeklifTID : ${teklifTID} response came in ${midElapsedTime} seconds, PDF opened in ${elapsedTime} seconds`);
    };

    dispatch(loadingKlActions.showKlLoadingEmpty());

    switch (pdfType) {
      case 'disclosure':
        documentService.getDisclosurePdf(teklifTID, quoteGuid, callback);
        break;
      case 'policy':
        documentService.getPolicyPdf(teklifTID, callback);
        break;
      case 'quotation':
        documentService.getQuotationPdf(teklifTID, quoteGuid, callback);
        break;
      case 'receipt':
        documentService.getMakbuzPdf(teklifTID, callback);
        break;
      default:
    }
  }, [dispatch, pdfType, teklifTID, quoteGuid]);

  return (
    <>
      {data && (
        isIOS() ? (
          <iframe
            src={data}
            style={{
              width: '100%',
              height: '100vh',
              border: 'none',
            }}
            title="PDF Viewer"
          />
        ) : (
          <embed
            src={data}
            type="application/pdf"
            style={{
              width: '100%',
              height: '100vh',
            }}
          />
        )
      )}
    </>
  );
};

export default withRouter(PdfView);
