import React, { lazy, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import '../../../../shared/assets/styles/radio-button.scss';
import '../../../../shared/assets/styles/pages/car-info.scss';

import useWindowSize from '../../../../shared/_hooks/windowResizeHook';

export const CarInfo = (props) => {
  const [DynamicComponent, setDynamicComponent] = useState(null);
  const size = useWindowSize();
  const URL_PARAMS = new URLSearchParams(document.location.search);
	
  const {
    isRenewal, quotation, isAgency, isMerchant, isCallCenter
  } = useSelector((state) => state.shell);

	useEffect(() => {
    if (isRenewal) {
      setDynamicComponent(lazy(() => import(`./CarInfoComponents/CarInfoRenewal`)));
    } else {
      setDynamicComponent(lazy(() => import(`./CarInfoComponents/CarInfoZero`)));
    }
  }, [isRenewal]);

  if (DynamicComponent !== null) {
    return (
      <>
				<DynamicComponent />
      </>
    );
  } else {
    return "";
  }
};

export default withRouter(CarInfo);

