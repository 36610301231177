import React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import fileSVG from '../../../../../../shared/assets/img/svg/file.svg';
import crossSVG from '../../../../../../shared/assets/img/svg/cross.svg';
import TickSVG from '../../../../../../shared/assets/img/svg/tick-green.svg';
import EventBus from '../../../../../../shared/_helpers/EventBus';
import { openPDF } from '../../../../../../shared/_helpers/helperFunctions';

const CascoPopularv1 = (props) => {
  const {
    offer, TeklifTID, popularCoverages, totalCoverage,
  } = props;

  const openCoverageModal = () => {
    EventBus.emit('openCoverageModal', offer);
  };

  const handleGetPdf = (e) => {
    e.preventDefault();
    const URL_PARAMS = new URLSearchParams(document.location.search);
    const qGuid = URL_PARAMS.get('qGuid');
    openPDF(TeklifTID, 'quotation', qGuid);
  };

  return (
    <>
      <div className="teminat-container" key="teminat-container">
        <div className="teminat-inner">
          {popularCoverages.map((popular) => (
            <span className="teminat-detail">
              <img src={TickSVG} />
              <span className="teminat-desc">
                {popular.displayText !== 'Var' && popular.displayText !== 'Yok' ? popular.displayText : ''}
                {popular.displayText.indexOf('Mesuliyet') >= 0 && (
                <span className="teminat-detail-price">
                  {`${
                    popular.quotationCoverages.find(
                      (qc) => qc.enumValueDisplayName !== 'Var' && qc.enumValueDisplayName !== 'Yok',
                    ).enumValueDisplayName
                  } TL`}
                </span>
                )}
              </span>
            </span>
          ))}
          {totalCoverage !== null && totalCoverage.slice(0, 6 - popularCoverages.length).map((popular) => (
            <span className="teminat-detail">
              <img src={crossSVG} alt="Yok" />
              <span className="teminat-desc">
                {popular.displayText !== 'Var' && popular.displayText !== 'Yok' ? popular.displayText : ''}
              </span>
            </span>
          ))}
        </div>
        {/* <a className="all-teminat" onClick={openCoverageModal} role="button" tabIndex={0}>
          Tüm Teminatları Gör ve Düzenle
          {' '}
          <KeyboardArrowDownIcon
            style={{
              transform: 'rotate(270deg)',
              zoom: '0.8',
            }}
          />
        </a> */}
        <div className="offer-show-pdf" onClick={(e) => handleGetPdf(e)}>
          <img src={fileSVG} alt="file" className="svg-icon" />
          <span>
            Teklif Dosyasını Görüntüle
          </span>
        </div>
      </div>
    </>
  );
};

export default CascoPopularv1;
