import React, { useEffect, useState } from "react";

import { withRouter } from "react-router-dom";
import { Box } from "@mui/system";
import { Button, Modal, Tab, Tabs, Tooltip, tooltipClasses } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import useWindowSize from "../../../shared/_hooks/windowResizeHook";
import fileSVG from "../../../shared/assets/img/svg/file.svg";
import securedLetter from "../../../shared/assets/img/svg/securedLetter.svg";
import coverageService from "../../_services/coverage.service";
import "../../../shared/assets/styles/components/coverage.scss";
import { getUsableLogo, openPDF, priceFormatter } from "../../../shared/_helpers/helperFunctions";
import tickGreenSVG from "../../../shared/assets/img/svg/tick-green.svg";
import infoSVG from "../../../shared/assets/img/svg/info.svg";
import crossSVG from "../../../shared/assets/img/svg/cross.svg";
import editSVG from "../../../shared/assets/img/svg/edit.svg";
import EditCoverage from "./widget/EditCoverage";
import CenteredModal from "./widget/CenteredModal";
import mailService from "../../_services/mail.service";
import loadingKlActions from "../../actions/loadingActions";
import toastrActions from "../../actions/toastrAction";
import { emailValidation } from "../../../shared/_helpers/validations";
import { KLInput } from "./form/KLInput";
import documentService from "../../_services/document.service";

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 13,
    },
  })
);

export const Coverage = (props) => {
  const size = useWindowSize();
  const isMobile = size.width <= 880;
  const { offer, isCoverageChanged, qGuid } = props;

  const [selectedTab, setSelectedTab] = useState(0);
  const [coverageGroups, setCoverageGroups] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editModalProps, setEditModalProps] = useState({});
  const [didCoverageUpdated, setDidCoverageUpdated] = useState(false);
  const [hintPopup, showHintPopup] = useState(null);
  const [hintTitle, setHintTitle] = useState(null);
  const [commercialName, setCommercialName] = useState("");
  const [hasMailClicked, setHasMailClicked] = useState(0);
  const [isSecondQuestionNeeded, setIsSecondQuestionNeeded] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const tempName = getUsableLogo(offer);
    setCommercialName(tempName);
  }, [offer]);

  const {
    handleSubmit,
    formState: { errors, isValid, touchedFields },
    control,
  } = useForm({
    mode: "onChange",
  });

  const {
    quotation: {
      customer: { eMail: customerEmail },
    },
  } = useSelector((state) => state.shell);

  const handleChange = (event, newTab) => {
    event.preventDefault();
    setSelectedTab(newTab);
  };

  useEffect(() => {
    coverageService.getQuotationCoverages(offer.TeklifTID, (response) => {
      setCoverageGroups(response.coverageGroups);
    });
  }, []);

  const sendMail = (mailAddress) => {
    const postData = {
      teklifUId: qGuid,
      teklifTId: offer.TeklifTID,
      productId: offer.ProductId,
      customerEmail: mailAddress,
    };
    dispatch(loadingKlActions.showKlLoadingEmpty());
    setHasMailClicked(false);
    mailService.sendMailKaskoTeminatlari(postData, (response) => {
      dispatch(loadingKlActions.hideKlLoading());
      const { success } = response;
      dispatch(
        toastrActions.setToastrMessage({
          variant: success ? "success" : "error",
          message: success
            ? "Poliçe girdiğiniz e-posta adresine gönderildi. Sistemin yoğunluğuna göre azami 10 dakika içerisinde ulaşacaktır."
            : "Poliçe girdiğiniz e-posta adresine gönderilemedi.",
        })
      );
    });
  };

  const handleGetPdf = (e) => {
    e.preventDefault();
    // const pdfWindow = window.open();
    // documentService.getQuotationPdf(offer.TeklifTID, (response) => {
    //   pdfWindow.location.href = openPDF(offer.TeklifTID,'quotation');
    // });
    openPDF(offer.TeklifTID, "quotation", qGuid);
  };

  const handleSendPdfEmail = () => {
    setHasMailClicked(true);
  };

  const onSubmit = (data) => {
    sendMail(data.email);
  };

  const buttonClicked = () => {
    setDidCoverageUpdated(false);
    props.updateQuote(coverageGroups, offer.ProductId);
    if (!isCoverageChanged) {
      props.buyButtonClicked(offer, true);
    }
  };

  const getRiskObjectValues = (coverage) => {
    setIsEditModalOpen(true);
    let parentId = null;
    coverageGroups[selectedTab].coverages.map((c) => {
      c.riskObjectAttributes.map((ro) => {
        if (ro.id === coverage.riskObjectAttributes[0].parentRiskObjectAttributeId) {
          parentId = c.enumValue;
        }
      });
    });
    if (coverage.riskObjectAttributes[0].typeName === "Decimal") {
      const modalProps = {
        title: coverage.riskObjectAttributes[0].description,
        inputTitle: coverage.riskObjectAttributes[0].name,
        selectedValue:
          coverage.displayText === "Yok"
            ? ""
            : coverage.displayText !== coverage.riskObjectAttributes[0].displayText
            ? coverage.displayText
            : coverage.riskObjectAttributes[0].displayText,
        options: [],
        hasUpdated: coverage.displayText !== coverage.riskObjectAttributes[0]?.displayText ? true : false,
        attrId: coverage.riskObjectAttributes[0].id,
        coverageId: coverage.coverageId,
        type: coverage.riskObjectAttributes[0].typeName,
      };
      setEditModalProps(modalProps);
    }
		if (coverage.riskObjectAttributes[0].typeName === "List") {
      coverageService.GetChildRiskObjectAttr(
				offer.ProductId,
				coverage.riskObjectAttributes[0].id,
				'',
				(response) => {
					coverageService.GetRiskObjectEnumValues(
						offer.TeklifTID,
						response.riskObjectAttributeItems[0].id,
						offer.ProductId,
						parentId,
						(response) => {
							const modalProps = {
								title: coverage.riskObjectAttributes[0].description,
								inputTitle: coverage.riskObjectAttributes[0].name,
								selectedValue: coverage.displayText,
								options: response,
								attrId: coverage.riskObjectAttributes[0].id,
								coverageId: coverage.coverageId,
								type: coverage.riskObjectAttributes[0].typeName,
							};
							setEditModalProps(modalProps);
						}
					);
				}
			);
    }else{
			coverageService.GetRiskObjectEnumValues(
				offer.TeklifTID,
				coverage.riskObjectAttributes[0].id,
				offer.ProductId,
				parentId,
				(response) => {
					const modalProps = {
						title: coverage.riskObjectAttributes[0].description,
						inputTitle: coverage.riskObjectAttributes[0].name,
						selectedValue: coverage.displayText,
						options: response,
						attrId: coverage.riskObjectAttributes[0].id,
						coverageId: coverage.coverageId,
						type: coverage.riskObjectAttributes[0].typeName,
					};
					setEditModalProps(modalProps);
				}
			);
		}
  };


  const updateCoverage = (updateObject) => {
    if (
      updateObject.selectedEnumValue === "" &&
      updateObject.selectedOption === "" &&
      updateObject.selectedVal === ""
    ) {
      updateObject.selectedOption = "Yok";
    }
    setEditModalProps((old) => ({
      ...old,
      selectedValue: updateObject.selectedOption,
    }));
    coverageService.GetChildRiskObjectAttr(
      offer.ProductId,
      editModalProps.attrId,
      updateObject.selectedEnumValue,
      (response) => {
        if (response.riskObjectAttributeItems.length > 0) {
          if (response.riskObjectAttributeItems[0].typeName !== "String") {
            response.riskObjectAttributeItems.map((riskObject) => {
              coverageService.GetRiskObjectEnumValues(
                offer.TeklifTID,
                riskObject.id,
                offer.ProductId,
                updateObject.selectedEnumValue,
                (riskResponse) => {
                  coverageGroups.map((group) => {
                    if (group.coverageGroups.length !== 0) {
                      group.coverageGroups.map((single) => {
                        single.coverages.map((coverage) => {
                          if (
                            riskResponse.getRiskObjectEnumValues !== undefined &&
                            riskResponse.getRiskObjectEnumValues.length !== 0 &&
                            coverage.riskObjectAttributes.length !== 0
                          ) {
                            if (coverage.riskObjectAttributes[0].id === riskObject.id) {
                              coverage.displayText = riskResponse.getRiskObjectEnumValues[0].displayName;
                              coverage.riskObjectAttributes[0].displayText =
                                riskResponse.getRiskObjectEnumValues[0].displayName;
                              coverage.riskObjectAttributes[0].value =
                                riskResponse.getRiskObjectEnumValues[0].enumValue.toString();
                            }
                          }
                          if (editModalProps.coverageId === coverage.coverageId) {
                            coverage.riskObjectAttributes[0].displayText = updateObject.selectedOption;
                            coverage.displayText = updateObject.selectedOption;
                            coverage.hasUpdated = true;
                            if (coverage.riskObjectAttributes[0].typeName === "Decimal") {
                              coverage.riskObjectAttributes[0].value = updateObject.selectedOption.toString();
                            }
                            if (updateObject.selectedEnumValue) {
                              coverage.riskObjectAttributes[0].value = updateObject.selectedEnumValue.toString();
                              coverage.enumValue = updateObject.selectedEnumValue;
                            }
                            setDidCoverageUpdated(true);
                            setIsEditModalOpen(false);
                          }
                        });
                      });
                    } else {
                      group.coverages.map((coverage) => {
                        if (
                          riskResponse.getRiskObjectEnumValues !== undefined &&
                          riskResponse.getRiskObjectEnumValues.length !== 0 &&
                          coverage.riskObjectAttributes.length !== 0
                        ) {
                          if (coverage.riskObjectAttributes[0].id === riskObject.id) {
                            coverage.displayText = riskResponse.getRiskObjectEnumValues[0].displayName;
                            coverage.riskObjectAttributes[0].displayText =
                              riskResponse.getRiskObjectEnumValues[0].displayName;
                            coverage.riskObjectAttributes[0].value =
                              riskResponse.getRiskObjectEnumValues[0].enumValue.toString();
                          }
                        }
                        if (editModalProps.coverageId === coverage.coverageId) {
                          coverage.riskObjectAttributes[0].displayText = updateObject.selectedOption;
                          coverage.displayText = updateObject.selectedOption;
                          coverage.hasUpdated = true;
                          if (coverage.riskObjectAttributes[0].typeName === "Decimal") {
                            coverage.riskObjectAttributes[0].value = updateObject.selectedOption.toString();
                          }
                          if (updateObject.selectedEnumValue) {
                            coverage.riskObjectAttributes[0].value = updateObject.selectedEnumValue.toString();
                            coverage.enumValue = updateObject.selectedEnumValue;
                          }
                          setDidCoverageUpdated(true);
                          setIsEditModalOpen(false);
                        }
                      });
                    }
                  });
                }
              );
            });
          } else {
            //TODO:set values
            if (coverageGroups[selectedTab].coverageGroups.length === 0) {
              coverageGroups[selectedTab].coverages.map((coverage) => {
                if (coverage.coverageId === editModalProps.coverageId) {
                  coverage.displayText = updateObject.selectedOption;
                  coverage.comparissonValue[0].displayText = updateObject.selectedOption;
                  coverage.riskObjectAttributes[0].displayText = updateObject.selectedOption;
                  coverage.riskObjectAttributes[0].value = updateObject.selectedOption.toString();
                  coverage.riskObjectAttributes[0].riskObjectAttributes = [];
                  coverage.riskObjectAttributes[0].riskObjectAttributes.push(response.riskObjectAttributeItems[0]);
                }
              });
            } else {
              coverageGroups[selectedTab].coverageGroups.map((cGroupCoverage) => {
                cGroupCoverage.coverages.map((coverage) => {
                  if (coverage.coverageId === editModalProps.coverageId) {
                    coverage.displayText = updateObject.selectedOption;
                    coverage.comparissonValue[0].displayText = updateObject.selectedOption;
                    coverage.riskObjectAttributes[0].displayText = updateObject.selectedOption;
                    coverage.riskObjectAttributes[0].value = updateObject.selectedOption.toString();
                    coverage.riskObjectAttributes[0].riskObjectAttributes = [];
                    coverage.riskObjectAttributes[0].riskObjectAttributes.push(response.riskObjectAttributeItems[0]);
                  }
                });
              });
            }
            const tempAttr = response.riskObjectAttributeItems[0];
            tempAttr.coverageId = editModalProps.coverageId;
            setEditModalProps({});
            setIsEditModalOpen(false);
            setIsSecondQuestionNeeded(tempAttr);
          }
        }

        if (response.riskObjectEnumValues?.length > 0) {
          response.riskObjectEnumValues.map((riskEnumVal, i) => {
            coverageGroups.map((group) => {
              if (group.coverageGroups.length !== 0) {
                group.coverageGroups.map((single) => {
                  single.coverages.map((coverage) => {
                    if (editModalProps.coverageId === coverage.coverageId) {
                      coverage.hasUpdated = true;
                      coverage.riskObjectAttributes[0].displayText = updateObject.selectedOption;
                      coverage.displayText = updateObject.selectedOption;
                      if (coverage.riskObjectAttributes[0].typeName === "Decimal") {
                        coverage.riskObjectAttributes[0].value = updateObject.selectedOption.toString();
                      }
                      if (updateObject.selectedEnumValue) {
                        coverage.riskObjectAttributes[0].value = updateObject.selectedEnumValue.toString();
                        coverage.enumValue = updateObject.selectedEnumValue;
                      }
                      setDidCoverageUpdated(true);
                      setIsEditModalOpen(false);
                    }
                    if (
                      riskEnumVal.id ===
                      (coverage.riskObjectAttributes.length !== 0 ? coverage.riskObjectAttributes[i].id : false)
                    ) {
                      setDidCoverageUpdated(false);
                      coverage.comparissonValue[0].displayText = riskEnumVal.displayName;
                      coverage.displayText = riskEnumVal.displayName;
                      coverage.riskObjectAttributes[i].displayText = riskEnumVal.displayName;
                      setDidCoverageUpdated(true);
                    }
                  });
                });
              } else {
                group.coverages.map((coverage) => {
                  if (editModalProps.coverageId === coverage.coverageId) {
                    coverage.riskObjectAttributes[0].displayText = updateObject.selectedOption;
                    coverage.displayText = updateObject.selectedOption;
                    coverage.hasUpdated = true;
                    if (coverage.riskObjectAttributes[0].typeName === "Decimal") {
                      coverage.riskObjectAttributes[0].value = updateObject.selectedOption.toString();
                    }
                    if (updateObject.selectedEnumValue) {
                      coverage.riskObjectAttributes[0].value = updateObject.selectedEnumValue.toString();
                      coverage.enumValue = updateObject.selectedEnumValue;
                    }
                    setDidCoverageUpdated(true);
                    setIsEditModalOpen(false);
                  }
                  if (
                    riskEnumVal.id ===
                    (coverage.riskObjectAttributes.length !== 0 ? coverage.riskObjectAttributes[i].id : false)
                  ) {
                    setDidCoverageUpdated(false);
                    coverage.comparissonValue[0].displayText = riskEnumVal.displayName;
                    coverage.displayText = riskEnumVal.displayName;
                    coverage.riskObjectAttributes[i].displayText = riskEnumVal.displayName;
                    setDidCoverageUpdated(true);
                  }
                });
              }
            });
          });
        }
        if (response.riskObjectAttributeItems.length === 0 && response.riskObjectEnumValues?.length === 0) {
          coverageGroups.map((group) => {
            if (group.coverageGroups.length !== 0) {
              group.coverageGroups.map((single) => {
                single.coverages.map((coverage) => {
                  if (editModalProps.coverageId === coverage.coverageId) {
                    if (
                      coverage.riskObjectAttributes.length !== 0 &&
                      coverage.riskObjectAttributes[0].riskObjectAttributes.length !== 0
                    ) {
                      coverage.riskObjectAttributes[0].riskObjectAttributes[0].value =
                        updateObject.selectedOption.toString();
                      coverage.riskObjectAttributes[0].riskObjectAttributes[0].displayText =
                        updateObject.selectedOption;
                    } else {
                      coverage.riskObjectAttributes[0].displayText = updateObject.selectedOption;
                      coverage.comparissonValue[0].displayText = updateObject.selectedOption;
                      coverage.displayText = updateObject.selectedOption;
                      coverage.hasUpdated = true;
                      if (coverage.riskObjectAttributes[0].typeName === "Decimal") {
                        coverage.riskObjectAttributes[0].value = updateObject.selectedOption.toString();
                      }
                      if (updateObject.selectedEnumValue) {
                        coverage.comparissonValue[0].value = updateObject.selectedEnumValue.toString();
                        coverage.riskObjectAttributes[0].value = updateObject.selectedEnumValue.toString();
                        coverage.enumValue = updateObject.selectedEnumValue;
                      }
                    }
                    setDidCoverageUpdated(true);
                    setIsEditModalOpen(false);
                  }
                });
              });
            } else {
              group.coverages.map((coverage) => {
                if (editModalProps.coverageId === coverage.coverageId) {
                  coverage.comparissonValue[0].displayText = updateObject.selectedOption;
                  coverage.displayText = updateObject.selectedOption;
                  coverage.hasUpdated = true;
                  if (coverage.riskObjectAttributes[0].typeName === "Decimal") {
                    coverage.riskObjectAttributes[0].value = updateObject.selectedOption.toString();
                  }
                  if (updateObject.selectedEnumValue) {
                    coverage.comparissonValue[0].value = updateObject.selectedEnumValue.toString();
                    coverage.riskObjectAttributes[0].value = updateObject.selectedEnumValue.toString();
                    coverage.riskObjectAttributes[0].displayText = updateObject.selectedOption;
                    coverage.enumValue = updateObject.selectedEnumValue;
                  }
                  setDidCoverageUpdated(true);
                  setIsEditModalOpen(false);
                }
              });
            }
          });
        }
      }
    );
  };
  useEffect(() => {
    if (didCoverageUpdated) {
      props.setIsCoverageChanged(true);
      setEditModalProps({});
    }
  }, [didCoverageUpdated]);
  useEffect(() => {
    if (isSecondQuestionNeeded !== false) {
      setIsEditModalOpen(true);
      setEditModalProps((old) => ({
        type: "Decimal",
        selectedValue: "",
        title: isSecondQuestionNeeded.description,
        inputTitle: isSecondQuestionNeeded.name,
        attrId: isSecondQuestionNeeded.id,
        coverageId: isSecondQuestionNeeded.coverageId,
      }));
    }
  }, [isSecondQuestionNeeded]);
  useEffect(() => {
    if (!isEditModalOpen) {
      setEditModalProps({});
    }
  }, [isEditModalOpen]);

  const setHintDesc = (coverage) => {
    setHintTitle(coverage.coverageName);
    showHintPopup(coverage.hintText);
  };
  const closeHintPopup = () => {
    setHintTitle(null);
    showHintPopup(null);
  };

  return (
    <>
      {offer && (
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            backgroundColor: "white",
            height: isMobile ? "100%" : "559px",
            width: isMobile ? "100%" : "995px",
            margin: isMobile ? "0" : "100px auto",
            borderRadius: isMobile ? "unset" : "5px",
          }}
          className="coverage-modal"
        >
          <div className="coverage-header">
            <div className="coverage-detail-header">
              <div className="coverage-detail-left">
                <div className="insurer-image">
                  <img src={commercialName.src} alt={commercialName.alt} height={64} width={120} />
                </div>
                <div className="offer-detail-container">
                  <div className="offer-detail-inner">
                    <div className="offer-title" dangerouslySetInnerHTML={{ __html: offer.Subtitle }} />
                    {offer.Subtitle.toLowerCase().indexOf("pert") >= 0 ||
                    offer.Subtitle.toLowerCase().indexOf("mini") >= 0 ? (
                      <div className="offer-subtitle">Dar Kapsamlı Ürün</div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="offer-pdf-container">
                    <div className="show-offer-pdf" onClick={(e) => handleGetPdf(e)}>
                      <img src={fileSVG} alt="file" className="svg-icon" />
                      <p className="assistive">Teklif Dosyasını Görüntüle</p>
                    </div>
                    {!isMobile && (
                      <div className="send-offer-pdf" onClick={() => handleSendPdfEmail()}>
                        <img src={securedLetter} alt="file" className="svg-icon"/>
                        <p className="assistive">E-posta Gonder</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="coverage-detail-right">
                <div className="offer-price">
                  {offer.OldPremium !== null && (
                    <div className="old-price">{priceFormatter.format(offer.OldPremium).replace("₺", "")} TL</div>
                  )}
                  <div className="price">{priceFormatter.format(offer.Premium).replace("₺", "")} TL</div>
                  <div className="installment">
                    {offer.PossibleInstallment}{" "}
                    {offer.PossibleInstallment.toLowerCase().indexOf("taksit") >= 0 ? "İmkanı" : ""}
                  </div>
                  {isMobile && (
                    <div className="offer-pdf-container">
                      <div className="send-offer-pdf" onClick={() => handleSendPdfEmail()}>
                        <img src={securedLetter} alt="file" className="svg-icon"/>
                        <p className="assistive">E-posta Gonder</p>
                      </div>
                    </div>
                  )}
                </div>
                <div className="offer-buy">
                  {!isMobile && (
                    <Button variant="contained" className="button" onClick={buttonClicked}>
                      {isCoverageChanged ? "Teklifi Güncelle" : "Satın Al"}
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <CloseIcon
              onClick={props.handleClose}
              style={{
                width: isMobile ? "22px" : "26px",
                height: isMobile ? "22px" : "26px",
                position: isMobile ? "absolute" : "relative",
                top: "5px",
                right: "5px",
              }}
            />
          </div>
          {coverageGroups !== null && coverageGroups.length !== 0 && (
            <>
              <Tabs
                value={selectedTab}
                onChange={handleChange}
                className="coverage-tabs-container"
                variant="scrollable"
                scrollButtons="none"
              >
                {coverageGroups.map((coverageGroup) => (
                  <Tab label={coverageGroup.coveregeGroupName.toLocaleLowerCase()} className="tab-label" />
                ))}
              </Tabs>
              {coverageGroups.map(
                (coverageGroup, i) =>
                  selectedTab === i && (
                    <div className="coverages">
                      {coverageGroup.coverages.length !== 0
                        ? coverageGroup.coverages.map((coverage) => (
                            <div className="coverage">
                              <div className="coverage-item">
                                {coverage.displayText !== "Yok" ? (
                                  <img src={tickGreenSVG} alt="Var" />
                                ) : (
                                  <img src={crossSVG} alt="Yok" />
                                )}
                                <p className="disclaimer">{coverage.coverageName}</p>
                                {coverage.hintText !== "" && (
                                  <LightTooltip
                                    title={<span dangerouslySetInnerHTML={{ __html: coverage.hintText }} />}
                                  >
                                    <img
                                      src={infoSVG}
                                      style={{ marginLeft: "8px" }}
                                      alt="Var"
                                      onClick={() => (isMobile ? setHintDesc(coverage) : "")}
                                    />
                                  </LightTooltip>
                                )}
                              </div>
                              <div className="coverage-description">
                                {((!coverage.readOnly && coverage.riskObjectAttributes.length !== 0) ||
                                  (coverage.displayText !== "Var" && coverage.displayText !== "Yok")) && (
                                  <p
                                    className="desc"
                                    style={{
                                      color: coverage.hasUpdated !== undefined ? "#0055A5" : "#53585E",
                                      fontWeight: "300",
                                      marginLeft: "28px",
                                    }}
                                  >
                                    { coverage.displayText !== null ? coverage.displayText?.split(",")[0] : coverage.defaultText.split(",")[0] }
                                  </p>
                                )}
                                {!coverage.readOnly && coverage.riskObjectAttributes.length !== 0 && (
                                  <span className="edit-button" onClick={() => getRiskObjectValues(coverage)}>
                                    <img src={editSVG} alt="Değiştir" />
                                    <p className="disclaimer" style={{ color: "#0055A5" }}>
                                      Değiştir
                                    </p>
                                  </span>
                                )}
                              </div>
                            </div>
                          ))
                        : coverageGroup.coverageGroups.map((cGroup) => (
                            <div className="coverage-sub-group">
                              <p className="text" style={{ fontWeight: "bold" }}>
                                {cGroup.coveregeGroupName}
                              </p>
                              {cGroup.coverages.map((cGroupCoverage) => (
                                <div className="coverage">
                                  <div className="coverage-item">
                                    {cGroupCoverage.displayText === "Yok" ? (
                                      <img src={crossSVG} alt="Yok" />
                                    ) : (
                                      <img src={tickGreenSVG} alt="Var" />
                                    )}
                                    <p className="disclaimer">{cGroupCoverage.coverageName}</p>
                                    {cGroupCoverage.hintText !== "" && (
                                      <LightTooltip
                                        title={<span dangerouslySetInnerHTML={{ __html: cGroupCoverage.hintText }} />}
                                      >
                                        <img
                                          src={infoSVG}
                                          style={{ marginLeft: "8px" }}
                                          alt="Var"
                                          onClick={() => (isMobile ? setHintDesc(cGroupCoverage) : "")}
                                        />
                                      </LightTooltip>
                                    )}
                                  </div>
                                  <div className="coverage-description">
                                    {!cGroupCoverage.readOnly && cGroupCoverage.riskObjectAttributes.length !== 0 && (
                                      <p
                                        className="desc"
                                        style={{
                                          color: cGroupCoverage.hasUpdated !== undefined ? "#0055A5" : "#53585E",
                                          fontWeight: cGroupCoverage.hasUpdated !== undefined ? "400" : "300",
                                          marginLeft: "28px",
                                        }}
                                      >
                                        { cGroupCoverage.displayText !== null ? cGroupCoverage.displayText?.split(",")[0] : cGroupCoverage.defaultText.split(",")[0] }
                                      </p>
                                    )}
                                    {!cGroupCoverage.readOnly && cGroupCoverage.riskObjectAttributes.length !== 0 && (
                                      <span className="edit-button" onClick={() => getRiskObjectValues(cGroupCoverage)}>
                                        <img src={editSVG} alt="Değiştir" />
                                        <p className="disclaimer" style={{ color: "#0055A5" }}>
                                          Değiştir
                                        </p>
                                      </span>
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          ))}
                    </div>
                  )
              )}
            </>
          )}
        </Box>
      )}
      {isEditModalOpen && Object.keys(editModalProps).length !== 0 && (
        <Modal open={isEditModalOpen} onClose={() => setIsEditModalOpen(false)}>
          <EditCoverage
            {...editModalProps}
            handleClose={() => setIsEditModalOpen(false)}
            updateCoverage={updateCoverage}
          />
        </Modal>
      )}
      {hintPopup !== null && (
        <CenteredModal title={hintTitle} isOpen={hintPopup !== null} setOpen={closeHintPopup}>
          <br />
          <p className="disclaimer">{hintPopup}</p>
        </CenteredModal>
      )}
      {isMobile ? (
        <Button
          variant="contained"
          onClick={buttonClicked}
          style={{
            width: !isMobile ? "254px" : "100%",
            height: "55px",
            boxSizing: "border-box",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.4)",
            borderRadius: "5px",
            textTransform: "none",
            display: "block",
            position: "fixed",
            bottom: 0,
            left: 0,
            margin: "0 0 0 -20%",
          }}
        >
          {isCoverageChanged ? "Teklifi Güncelle" : "Satın Al"}
        </Button>
      ) : (
        ""
      )}
      <CenteredModal title="Teklifi E-posta ile gönder" isOpen={hasMailClicked} setOpen={setHasMailClicked}>
        <p
          className="text"
          style={{
            marginBottom: "30px",
          }}
        >
          Teklif detayları aşağıdaki e-posta adresine gönderilecektir.
        </p>

        <div className="mail-section">
          <div className="send-mail">
            <form>
              <KLInput
                name="email"
                control={control}
                label="E-Posta"
                error={errors}
                defaultValue={customerEmail || props.mailAddress}
                rules={emailValidation()}
                touchedFields={touchedFields}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    width: isMobile ? "100%" : "310px",
                    marginBottom: "0",
                    "&.Mui-error": {
                      width: isMobile ? "100%" : "310px",
                      marginBottm: "0",
                    },
                  },
                  ".MuiInputLabel-formControl": {
                    fontSize: "15px",
                    marginBottom: "0",
                  },
                  "&": {
                    width: "auto",
                  },
                }}
              />
              <Button
                variant="contained"
                disabled={!isValid}
                style={{
                  width: "87px",
                  height: "45px",
                  boxSizing: "border-box",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.4)",
                  borderRadius: "0 5px 5px 0",
                  textTransform: "none",
                }}
                onClick={handleSubmit(onSubmit)}
              >
                Gönder
              </Button>
            </form>
          </div>
        </div>
      </CenteredModal>
    </>
  );
};

export default withRouter(Coverage);
