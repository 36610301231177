import React, { useEffect, useState } from 'react';
import { Autocomplete, Button, MenuItem, Paper, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { withRouter } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import useWindowSize from '../../../../shared/_hooks/windowResizeHook';
import { KLInput } from '../../components/form/KLInput';
import {
  emailValidation, minLength, phoneValidation, requiredArea,
} from '../../../../shared/_helpers/validations';
import { UserStates } from '../../../data/userStates';
import useUserState from '../../../../shared/_hooks/userStateHook';
import commonService from '../../../_services/common.service';

export const EditConfirmationField = (props) => {
  const size = useWindowSize();
  const isMobile = size.width <= 880;
  const { title, value, exactKey } = props;
  const [isOptionChanged, setIsOptionChanged] = useState(false);
  const [val, setValue] = React.useState(value);
  const { setUserState } = useUserState();
  const URL_PARAMS = new URLSearchParams(document.location.search);
  const uid = URL_PARAMS.get('jt');

  const {
    handleSubmit,
    reset,
    formState: { errors, isValid, touchedFields },
    control,
  } = useForm({
    mode: 'onChange',
  });
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -40%)',
    width: 321,
    height: 'fit-content',
    bgcolor: 'background.paper',
    textAlign: 'center',
    background: '#FFFFFF',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    paddingBottom: '30px',
  };

  const CustomPaper = (props) => (
    <Paper
      style={{
        fontSize: '13px',
      }}
      elevation={8}
      {...props}
    />
  );

  const [cities, setCityList] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [selectedDistrictRow, setSelectedDistrictRow] = useState(null);
  const [selectedCityRow, setSelectedCityRow] = useState(null);
  const [districtSelectedForTheFirstTime, setDistrictSelectedForTheFirstTime] = useState(false);
  const [cityDistrictVisible, setCityDistrictVisible] = useState(false);

  useEffect(() => {
    if ((props.confirmationInfo.customerInfo.adresIlceAck
      || props.confirmationInfo.customerInfo.adresIlAck) && (props.confirmationInfo.customerInfo.adresIlceCode || props.confirmationInfo.customerInfo.adresIlCode)) {
      setCityDistrictVisible(true);
      commonService.getCityList((data) => {
        if (data.provinces) {
          const uniqueProvinces = [];
          const map = new Map();
          for (const province of data.provinces) {
            if (!map.has(province.code)) {
              map.set(province.code, true);
              uniqueProvinces.push({
                name: province.name,
                code: province.code,
              });
            }
          }
          setCityList(uniqueProvinces);
          setSelectedCityRow(uniqueProvinces.find((city) => city.code === props.confirmationInfo.customerInfo.adresIlCode));
        }
      });
    }
  }, []);

  const getDistricts = (val) => {
    setSelectedCityRow(val);
    setSelectedDistrictRow(null);
    if (val && val.code) {
      commonService.getDitrictsByCityId(val.code, (data) => {
        setDistricts(data.districts);
        if (!districtSelectedForTheFirstTime) {
          setSelectedDistrictRow(data.districts.find((district) => district.code === props.confirmationInfo.customerInfo.adresIlceCode));
          setDistrictSelectedForTheFirstTime(true);
        }
      });
    }
  };

  useEffect(() => {
    if (selectedCityRow?.code) {
      getDistricts(selectedCityRow);
    }
  }, [selectedCityRow]);

  const districtSelect = (val) => {
    setSelectedDistrictRow(val);
  };

  const onSubmit = (data) => {
    const userSateData = {
      UserUID: uid,
    };
    if (title === 'Telefon') {
      userSateData.UserStateDefinition = UserStates.Change_Phone_Confirmation;
      setUserState(userSateData);
    }
    if (title === 'E-posta') {
      userSateData.UserStateDefinition = UserStates.Change_Email_Confirmation;
      setUserState(userSateData);
    }
    if (title === 'multiple') {
      userSateData.UserStateDefinition = UserStates.Change_Address_Confirmation;
      setUserState(userSateData);
    }
    if (data[title] === 'Şasi No' || data[title] === 'Motor No') {
      userSateData.UserStateDefinition = UserStates.Update_Vehicle_Confirmation;
      setUserState(userSateData);
    }

    if (cityDistrictVisible) {
      data.city = selectedCityRow;
      data.district = selectedDistrictRow;
    }

    if (props.title === 'multiple') {
      props.updateMultiple(data);
    } else {
      props.updateField(data[title] !== undefined ? data[title] : val, title, exactKey);
    }
  };

  const { updateableFlag } = props;

  return (
    <>
      <Paper style={style} square>
        <CloseIcon
          onClick={props.handleClose}
          style={{
            width: isMobile ? '22px' : '26px',
            height: isMobile ? '22px' : '26px',
            position: 'absolute',
            right: '10px',
            top: '10px',
            cursor: 'pointer',
          }}
        />
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '33px',
              width: isMobile ? '100%' : 'unset',
            }}
          >
            {exactKey === 'policyStartDate' ? (
              <div style={{
							  width: isMobile ? '254px' : 'unset',
              }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label={title}
                    value={val}
                    disablePast
                    inputFormat="DD/MM/YYYY"
                    onChange={(newValue) => {
                      setValue(newValue.$d.toISOString());
                      setIsOptionChanged(true);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
            ) : exactKey !== null ? (
              <form 									style={{
                width: isMobile ? '254px' : 'unset',
              }}
              >
                <KLInput
                  label={title}

                  name={title}
                  mask={exactKey.indexOf('telefon') >= 0 ? '(599) 999 99 99' : ''}
                  rules={
                    exactKey.indexOf('telefon') >= 0
                      ? phoneValidation()
                      : exactKey.indexOf('email') >= 0
                        ? emailValidation()
                        : minLength(5)
                  }
                  defaultValue={value}
                  value={value}
                  control={control}
                  error={errors}
                  touchedFields={touchedFields}
                  inputProps={{
                    type: 'text',
                    maxLength: exactKey.indexOf('sasi') >= 0 ? '17' : exactKey.indexOf('motor') >= 0 ? '25' : 'none',
                    minLength: 5,
                  }}
                />
              </form>
            ) : (
              <div style={{
                display: 'flex', alignItems: 'center', justifyContent: 'center', width: 'unset', flexDirection: 'column',
              }}
              >
                <div>
                  {!cityDistrictVisible && (
                    <p className="text">
                      Adres:
                      {' '}
                      {props.confirmationInfo.customerInfo.adresIlceAck
                        && `${props.confirmationInfo.customerInfo.adresIlceAck},`}
                      {' '}
                      {props.confirmationInfo.customerInfo.adresIlAck
                        && `${props.confirmationInfo.customerInfo.adresIlAck}`}
                    </p>
                  )}
                </div>
                <form>
                  {cityDistrictVisible && (
                    <Autocomplete
                      openOnFocus
                      id="model-type-select"
                      options={cities}
                      PaperComponent={CustomPaper}
                      getOptionLabel={(option) => option.name}
                      noOptionsText="Sonuç bulunamadı"
                      value={selectedCityRow}
                      rules={requiredArea()}
                      filterOptions={(x, e) => {
                        if (e.inputValue) {
                          return x.filter((elem) => elem.name.toLocaleLowerCase().includes(e.inputValue.toLocaleLowerCase()));
                        }
                        return x;
                      }}
                      renderOption={(props, option, { selected }) => (
                        <MenuItem {...props} value={option.code} key={option.name} data-text={option.name}>
                          {option.name}
                        </MenuItem>
                      )}
                      onChange={(event, value) => {
                        getDistricts(value);
                      }}
                      style={{
                        gridColumn: 'span 2'
                      }}
                      renderInput={(params) => <TextField {...params} label="İl" />}
                    />
                  )}
                  {cityDistrictVisible && (
                    <Autocomplete
                      openOnFocus
                      disabled={!selectedCityRow}
                      id="model-type-select"
                      options={districts}
                      PaperComponent={CustomPaper}
                      getOptionLabel={(option) => option.name}
                      noOptionsText="Sonuç bulunamadı"
                      value={selectedDistrictRow}
                      rules={requiredArea()}
                      filterOptions={(x, e) => {
                        if (e.inputValue) {
                          return x.filter((elem) => elem.name.toLocaleLowerCase().includes(e.inputValue.toLocaleLowerCase()));
                        }
                        return x;
                      }}
                      renderOption={(props, option, { selected }) => (
                        <MenuItem {...props} value={option.code} key={option.name} data-text={option.name}>
                          {option.name}
                        </MenuItem>
                      )}
                      onChange={(event, value) => {
                        districtSelect(value);
                      }}
                      style={{
                        gridColumn: 'span 2'
                      }}
                      renderInput={(params) => <TextField {...params} label="İlçe" />}
                    />
                  )}
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 1fr',
                      gap: '10px',
                      marginTop: '15px',
                    }}
                  >
                    {updateableFlag.includes('mahalle') && (
                    <KLInput
                      name="mahalle"
                      control={control}
                      label="Mahalle"
                      rules={requiredArea()}
                      defaultValue={props.confirmationInfo.customerInfo.mahalle}
                      error={errors}
                      touchedFields={touchedFields}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          width: '120px',
                          '&.Mui-error': {
                            width: '120px',
                          },
                        },
                      }}
                    />
                    )}
                    {updateableFlag.includes('caddeSokak') && (
                    <KLInput
                      name="caddeSokak"
                      control={control}
                      label="Cadde/Sokak"
                      defaultValue={props.confirmationInfo.customerInfo.caddeSokak}
                      rules={requiredArea()}
                      error={errors}
                      touchedFields={touchedFields}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          width: '120px',
                          '&.Mui-error': {
                            width: '120px',
                          },
                        },
                      }}
                    />
                    )}

                    <KLInput
                      name="icKapiNo"
                      disabled={!updateableFlag.includes('icKapiNo')}
                      control={control}
                      label="İç Kapı No"
                      defaultValue={props.confirmationInfo.customerInfo.icKapiNo}
                      rules={requiredArea()}
                      error={errors}
                      touchedFields={touchedFields}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          width: '120px',
                          '&.Mui-error': {
                            width: '120px',
                          },
                        },
                      }}
                    />
                    <KLInput
                      name="disKapiNo"
                      disabled={!updateableFlag.includes('disKapiNo')}
                      control={control}
                      label="Dış Kapı No"
                      defaultValue={props.confirmationInfo.customerInfo.disKapiNo}
                      rules={requiredArea()}
                      error={errors}
                      touchedFields={touchedFields}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          width: '120px',
                          '&.Mui-error': {
                            width: '120px',
                          },
                        },
                      }}
                    />
                  </div>
                </form>
              </div>
            )}
          </div>
          <Button
            variant="contained"
            disabled={exactKey === 'policyStartDate' ? !isOptionChanged : !isValid || (cityDistrictVisible && !selectedDistrictRow)}
            style={{
              width: !isMobile ? '254px' : '254px',
              height: '45px',
              boxSizing: 'border-box',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
              borderRadius: '5px',
              textTransform: 'none',
            }}
            onClick={handleSubmit(onSubmit)}
          >
            Değiştir
          </Button>
        </div>
      </Paper>
    </>
  );
};

export default withRouter(EditConfirmationField);
