import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import StepsBox from '../../components/StepsBox';
import FormBox from '../../components/FormBox';
import InsuredsForm from './form/InsuredsForm';
import informationActions from '../../../../motor/actions/informationAction';
import stepActions from '../../../../motor/actions/stepAction';
import { tssStepDecider } from '../../../../shared/_helpers/stepDecider';
import getQuotes, { stepsEnum } from '../../../apiOperations/getQuotes';
import loadingKlActions from '../../../../motor/actions/loadingActions';
import TSS_SEQUENCE from '../../../constants/sequence';
import { removeCustomEventsFromDataLayer } from '../../../../shared/_helpers/helperFunctions';

export const Insureds = (props) => {
  const { insureds, isFamilyFlow } = useSelector((state) => state.info);
  const { step } = useSelector((state) => state.step);
  const currentInsured = insureds[insureds.length - 1];

  const dispatch = useDispatch();

  useEffect(() => {
    const { location: { pathname } } = window;
    const stepType = isFamilyFlow ? 'FAMILY' : 'SELF';
    const currentStep = TSS_SEQUENCE[stepType].find((elm) => elm.stepUrl.toLowerCase() === pathname.toLowerCase()).index;
    dispatch(
      stepActions.setStep(currentStep),
    );

    if (!Object.values(window.dataLayer).map((element) => element.event).includes('family_insurant_list')) {
      removeCustomEventsFromDataLayer(window.dataLayer);
      window.dataLayer.push({
        event: 'family_insurant_list',
      });
    }
  }, []);

  const {
    handleSubmit,
    reset,
    formState: { errors, touchedFields },
    control,
    setValue,
  } = useForm({
    mode: 'onChange',
  });

  const formHookObject = {
    handleSubmit,
    reset,
    control,
    setValue,
    errors,
    isValid: true,
    touchedFields,
  };

  useEffect(() => {
    dispatch(loadingKlActions.hideKlLoading());
  }, []);

  const advanceToFamily = () => {
    if (currentInsured) {
      currentInsured.movingForward = true;
      currentInsured.isFull = true;
      dispatch(
        informationActions.updateInsured(currentInsured),
      );
    }
    dispatch(
      informationActions.setIsFamilyFlow(true),
    );
    dispatch(loadingKlActions.hideKlLoading());
    dispatch(stepActions.setfromInsureds(true));
    tssStepDecider(1, props.history, true);
    dispatch(stepActions.setStep(1));
  };

  const deleteAndSetInsureds = (removedInsuranceTCKN) => {
    const filteredInsureds = insureds.filter((insured) => {
      if (insured.identityNo !== removedInsuranceTCKN) {
        return true;
      }
      return false;
    });

    getQuotes(dispatch, {
      step: stepsEnum.TSSPersonalInformation,
      insureds: filteredInsureds,
    }, () => {
      dispatch(informationActions.setInsureds(filteredInsureds));
    });
  };

  const submitForm = () => {
    tssStepDecider(step + 1, props.history, true);
    dispatch(stepActions.incrementStep());
    window.dataLayer.push({ funnel_variables: null });
    window.dataLayer.push({
      event: 'family_insurant_list_continue',
    });
  };

  return (
    insureds.length > 0 && (
    <>
      <StepsBox>
        <FormBox formHeader="Sigortalanacak Bireyler">
          <InsuredsForm
            formHookObject={formHookObject}
            submitForm={submitForm}
            advanceToFamily={advanceToFamily}
            deleteAndSetInsureds={deleteAndSetInsureds}
          />
        </FormBox>
      </StepsBox>
    </>
    )
  );
};

export default withRouter(Insureds);
