import React, { useState, useEffect } from "react";
import { Grid, Checkbox, Button, FormControlLabel, Modal } from "@mui/material";
import { useForm } from "react-hook-form";

import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import SafetySVG from "../../../../shared/assets/img/svg/safety.svg";
import InfoSVG from "../../../../shared/assets/img/svg/info.svg";
import useWindowSize from "../../../../shared/_hooks/windowResizeHook";
import CengizOnCar from "../../../../shared/assets/img/png/cengiz-on-car.png";
import CengizPhoto from "../../../../shared/assets/img/png/cengiz-photo.png";
import NonKoalay from "../../../../shared/assets/img/png/non_koalay.png";
import informationAction from "../../../actions/informationAction";
import customerService from "../../../_services/customer.service";
import commonService from "../../../_services/common.service";
import vehicleService from "../../../_services/vehicle.services";
import { birthDateValidation, companyNameValidation, emailValidation, phoneValidation, requiredArea, wordValidation } from "../../../../shared/_helpers/validations";
import { KLInput } from "../../components/form/KLInput";
import OTP from "./OptionalSteps/OTP";
import EventBus from "../../../../shared/_helpers/EventBus";

import informationActions from "../../../actions/informationAction";
import toastrActions from "../../../actions/toastrAction";
import stepActions from "../../../actions/stepAction";
import InfoModal from "../../components/widget/InfoModal";
import PersonalDataInfo from "../../components/shared/PersonalDataProtection";
import loadingKlActions from "../../../actions/loadingActions";

import useUserState from "../../../../shared/_hooks/userStateHook";
import { UserStates } from "../../../data/userStates";
import { checkAPSAndGoSummary } from "../../../../shared/_helpers/redirect";
import { stepDecider } from "../../../../shared/_helpers/stepDecider";
import shellActions from "../../../actions/shellAction";
import styled from "@emotion/styled";
import { removeCustomEventsFromDataLayer } from "../../../../shared/_helpers/helperFunctions";
import CenteredModal from "../../components/widget/CenteredModal";
import UserConsentModal from "../../components/widget/UserConsentModal";

export const ContactInfo = (props) => {
  const size = useWindowSize();
  const isMobile = size.width <= 880;
  const URL_PARAMS = new URLSearchParams(document.location.search);
  const uid = URL_PARAMS.get("jt");
  const [isOTPRequired, setIsOTPRequired] = useState(false);
  const [isPersonalDataModalOpen, setIsPersonalDataModalOpen] = useState(false);
  const [quoteId, setQuoteId] = useState("");
  const [isPermissonCollectionAllowed, setIsPermissonCollectionAllowed] = useState(false);

  const { vehicle, customer, isKPSActive, isVkn, noClaimDiscount } = useSelector((state) => state.info);
  const { isRenewal, quotation, isAgency, isMerchant, isCallCenter } = useSelector((state) => state.shell);
  const { step } = useSelector((state) => state.step);
  const { phoneInfoMessage } = useSelector((state) => state.campaign);
  const {additionalInfo} = useSelector((state) => state.theme);
  const {consumer} = useSelector((state) => state.consumer);

  const [eBulletin, setEBulletin] = useState(customer?.eBulletin);
  const [userConsentModal, setUserConsentModal] = useState(false);

  const { setUserState } = useUserState();
  const dispatch = useDispatch();
  const isKoalaySS = document.location.href.indexOf("koalay") >= 0;
  var consumerObj = JSON.parse(consumer);

  const jt = URL_PARAMS.get("jt") !== null ? URL_PARAMS.get("jt") : props.location.state.jt;
  const StyledSpan = styled("span")(({ theme, ownerState }) => ({
    color: theme.palette.primary.main,
  }));
  const {
    handleSubmit,
    reset,
    formState: { errors, isValid, touchedFields },
    control,
    setValue,
  } = useForm({
    mode: "onChange",
  });
  const handleNewsCheck = (e) => {
    setEBulletin(e.target.checked);
  };

  useEffect(() => {
    if (
      !Object.values(dataLayer)
        .map((element) => element.event)
        .includes("contact_info_page")
    ) {
      removeCustomEventsFromDataLayer(window.dataLayer);
      window.dataLayer.push({
        event: 'contact_info_page',
        'funnel_variables': {
          jt
        },
      });
    }
    if (isMerchant) {
      const {
        referenceParams: { phone },
      } = JSON.parse(consumer);
      const newCustomerObj = customer;
      newCustomerObj.phoneNumber = phone;
      dispatch(informationAction.setCustomerInfo(newCustomerObj));
      setValue("phone", phone);
			const sbn = JSON.parse(consumer).partnerSbn || 'KCDUTC'
			customerService.getPermissonCollectionAllowed(sbn, (response) => {
				setIsPermissonCollectionAllowed(response.isAllowed)
			})
    }
  }, []);

  useEffect(() => {
    if (isOTPRequired) {
      const userSateData = {
        UserUID: uid,
        UserStateDefinition: UserStates.OTP_SMS_Sent,
      };
      setUserState(userSateData);
    }
  }, [isOTPRequired]);

  const getOffers = (data) => {
    try {
			const modelYearValue =  vehicle.modelYear.yil !== undefined ? 
			vehicle.modelYear.yil : 
			vehicle.modelYear?.Value ? 
			vehicle.modelYear?.Value : 
			vehicle.modelYear

			
      const vehicleObject = {
        BrandCode: vehicle.carBrand.kod !== undefined ? vehicle.carBrand.kod : vehicle.carBrand,
        IsAgency: 0,
        ModelCode: vehicle.carModel.kod !== undefined ? vehicle.carModel.kod : vehicle.carModel,
        UserGuid: uid,
        VehicleBuyingStatus: 0,
        VehicleProcessStatus: 0,
        ModelYear: modelYearValue,
        tarifeCode: vehicle.vehicleType,
        ExistsAsbisNumber: 0,
        kimlikNo: quotation.customer.tcKimlikVergiNo,
        FunctionalBranchId: 1,
      };
			if(noClaimDiscount){
				vehicleObject.NoClaimDiscount = {
					...noClaimDiscount
				}
			}

      const customerObject = {
        Email: data.mail,
        PhoneNumber: data.phone.replace(/\D/g, ""),
        UserGuid: uid,
        EBulletin: eBulletin,
        Name: data.name || "",
        Surname: data.lastName || "",
        Sbn: consumerObj.partnerSbn,
        GCLID: "",
        I3Identify: "",
        ExtRefId: consumerObj.dealerCode,
        GclientId: "1383055116.1619005077",
        ChannelId: 1,
        CampSrc: "",
        FunctionalBranchId: 1,
        FromStep1: true,
				BirthDate: data.birthDate || ""
      };

			customerService.customer(customerObject, (customerResult, isbulking,
				redirectionUrl) => {
				if(isbulking){
					dispatch(loadingKlActions.hideKlLoading());
					location.replace(redirectionUrl);
				}else {
          dispatch(loadingKlActions.showKlLoadingEmpty());
          vehicleService.vehicle(vehicleObject, (response) => {
            dispatch(informationAction.setQuoteId(response.Result.qtGuid));
            var url = "";
            if (response.Result.ResultPageUrl) {
              url = response.Result.ResultPageUrl;
              dispatch(stepActions.setRedirectUrl(url));
            }

            setQuoteId(response.Result.qtGuid);
            commonService.checkOTPUser(
              {
                phoneNumber: data.phone.replace(/\D/g, ""),
                tcIdentityNumber: quotation.customer.tcKimlikVergiNo,
                isPolicyFlow: false,
              },
              (resp) => {
                if (!resp.otpUserResult && !isMerchant && !isCallCenter) {
                  setIsOTPRequired(true);
                  commonService.isInBlackList(
                    {
                      PhoneNumber: customer.phoneNumber ? customer.phoneNumber : data.phone.replace(/\D/g, ""),
                      TCKN: quotation.customer.tcKimlikVergiNo,
                      Email: customer.mail,
                      FirstPhoneNumber: customer.phoneNumber,
                    },
                    (blresp) => {
                      if (!blresp) {
                        if (!isAgency) {
                          commonService.sendSMS(
                            {
                              OTP: null,
                              OTPType: 0,
                              PhoneNumber: customer.phoneNumber ? customer.phoneNumber : data.phone.replace(/\D/g, ""),
                              TCKN: quotation.customer.tcKimlikVergiNo,
                              UserUId: uid,
                            },
                            (sms) => {
                              // customerService.verify(
                              //   {
                              //     QuoteGUID: response.Result.qtGuid,
                              //     ReferenceCustomerUID: quotation.customer.referenceCustomerUID,
                              //     UserGUID: uid,
                              //   },
                              //   (vresp) => {
                              //     props.history.push({
                              //       pathname: url,
                              //       search: `?jt=${uid}&qGuid=${response.Result.qtGuid}`,
                              //       state: { qGuid: response.Result.qtGuid },
                              //     });
                              //   },
                              // );
                            }
                          );
                        }
                      }
                    }
                  );
                } else if (!quotation.customer.referenceCustomerUID && quotation.customer.tcKimlikVergiNo.length > 10) {
                  // EventBus.emit('birthDateInfo');
									dispatch(informationActions.setBirthDateInfo(!isKPSActive));
                  dispatch(shellActions.setIsBack(true));
                  props.history.push({
                    pathname: "/quotation/BirthdateInfo",
                    search: props.history.location.search,
                  });
                } else if (
                  response.activeStep === "OKRespOccupationQuery" &&
                  quotation.customer.tcKimlikVergiNo.length > 10
                ) {
                  dispatch(informationAction.setBirthDateInfo(true));
                  // EventBus.emit('birthDateInfo');
                  dispatch(shellActions.setIsBack(true));
                  props.history.push({
                    pathname: "/quotation/BirthdateInfo",
                    search: props.history.location.search,
                  });
                } else {
                  dispatch(loadingKlActions.showKlLoadingEmpty());
                  customerService.verify(
                    {
                      QuoteGUID: response.Result.qtGuid,
                      ReferenceCustomerUID: uid,
                      UserGUID: uid,
											BirthDate: data.birthDate || null,
                    },
                    (vresp, code) => {
                      if (quotation.customer.tcKimlikVergiNo.length === 10) {
                        code = "102";
                      }
                      checkAPSAndGoSummary(uid, response.Result.qtGuid, code, props, null, null, url);
                    }
                  );
                }
              }
            );
            dispatch(loadingKlActions.hideKlLoading());
          });
        }
      });
    } catch (error) {
      dispatch(toastrActions.setToastrMessage({ variant: "error", message: error.toString() }));
    }
  };
  const nextStep = (data) => {
    try {
      const crmModel = {
        IdentityNumber: quotation.customer.tcKimlikVergiNo,
        PlateNo: quotation.vehicle.plakaBirlesik,
        SBNCode: quotation.customer.sbn,
      };
      vehicleService.vehicleCrm(crmModel);
			const customerObject = {
				Email: data.mail,
				PhoneNumber: data.phone.replace(/\D/g, ''),
				EBulletin: eBulletin,
				UserGuid: uid,
				Sbn: consumerObj.partnerSbn,
				ExtRefId: consumerObj.dealerCode,
				ChannelId: 1,
				FromStep1: true,
				Name: data.name || "",
        Surname: data.lastName || "",
				BirthDate: data.birthDate || "",
				GCLID: '',
				I3Identify: '',
				GclientId: '1383055116.1619005077',
				CampSrc: '',
			};
			customerService.customer(customerObject, (customerResult, isbulking,
				redirectionUrl) => {
				if(isbulking){
					dispatch(loadingKlActions.hideKlLoading());
					location.replace(redirectionUrl);
				}else{
					dispatch(stepActions.incrementStep());
					stepDecider(isRenewal, step+1, props.history);
				}
			})
    } catch (error) {
      dispatch(toastrActions.setToastrMessage({ variant: "error", message: error.toString() }));
    }
  };
  const onSubmit = (data) => {
		const [day, month, year] = data.birthDate !== undefined ? data.birthDate.split('/') : [null, null, null];
		data.birthDate =  year !== null ? [year, month, day].join('-') : null,

    dispatch(
      informationAction.setCustomerInfo({
        phoneNumber: data.phone.replace(/\D/g, ""),
        eMail: data.mail,
        uid,
        eBulletin,
        name: data.name || "",
        surname: data.lastName || "",
				birthDate:data.birthDate|| ""
      })
    );
    window.dataLayer.push({ funnel_variables: null });
    window.dataLayer.push({
      event: 'contact_permission',
      'funnel_variables': {
        'marketing_permission': eBulletin,
        jt,
      }
    });
		if (isRenewal) {
			nextStep(data);
		} else {
			getOffers(data);
		}
  };

  const userConsentHook = (e) => {
    setUserConsentModal(true);
    if (e.target.href === '') {
      e.preventDefault();
    }
    return false;
  };

  useEffect(() => {
    dispatch(loadingKlActions.hideKlLoading());
    const userSateData = {
      UserUID: jt,
      UserStateDefinition: UserStates.Potansiyel_Musteri_Iletisim_Bilgisi_Yazmadan_Ayrılan,
    };
    setUserState(userSateData);

    //Reset Feature step data
    let stateVehicle = vehicle;
    stateVehicle.seri = "";
    stateVehicle.no = "";
    dispatch(informationActions.setVehicleInfo(stateVehicle));
  }, []);

	const vehiclePhoto = consumerObj.partnerSbn === "ATMDLK" ? additionalInfo.vehiclePhoto : (isKoalaySS ? CengizOnCar : NonKoalay);
  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12} sm={12} lg={6} xl={6} item>
          <div>
            {isVkn ? (<h1>Firma Bilgileri</h1>) : (<h1>Kişisel Bilgiler</h1>)}
          </div>
          {!isAgency && (
            <div style={{ alignItems: "center", display: "flex", margin: "10px 0 8px 0" }}>
              <img src={SafetySVG} alt="" width={20} className="svg-icon" />
              <p className="text">Kaydettiğiniz bilgiler Koalay ile her zaman güvende.</p>
            </div>
          )}
          {isAgency && (
            <div style={{ alignItems: "center", display: "flex", margin: "10px 0 8px 0" }}>
              {isVkn ? (
								<p className="disclaimer" style={{ margin: "12px 0" }}>
									Lütfen <b>firmaya</b> ait bilgileri girin
								</p>
								) : (
								<p className="disclaimer" style={{ margin: "12px 0" }}>
                	Lütfen <b>araç sahibine</b> ait bilgileri girin.
              	</p>
							)}
            </div>
          )}
          <form>
						{!isKPSActive && !isVkn && (
							<>
            <div>
              <KLInput
                name="name"
                label="Ad"
                control={control}
								rules={wordValidation()}
                defaultValue={customer?.name}
                error={errors}
                touchedFields={touchedFields}
                reset={reset}
                value={customer?.name}
              />
              <KLInput
                name="lastName"
                label="Soyad"
                control={control}
								rules={wordValidation()}
                defaultValue={customer?.surname}
                error={errors}
                touchedFields={touchedFields}
                reset={reset}
                value={customer?.surname}
              />
            </div>
            <div>
              <KLInput
                name="birthDate"
                mask="99/99/9999"
                label="Doğum Tarihi"
                placeholder="GG/AA/YYYY"
                rules={birthDateValidation()}
                control={control}
                error={errors}
                touchedFields={touchedFields}
								value={customer?.birthDate}
              />
            </div>
						</>
						)}
						{(!isKPSActive && isVkn) && (
							<>
            <div>
              <KLInput
                name="name"
                label="Firma adı"
                control={control}
								rules={companyNameValidation()}
                defaultValue={customer?.name}
                error={errors}
                touchedFields={touchedFields}
                reset={reset}
                value={customer?.name}
              />
            </div>
						</>
						)}
            <div>
              <KLInput
                name="phone"
                mask="(599) 999 99 99"
                label="Telefon Numarası"
                control={control}
                rules={phoneValidation()}
                defaultValue={customer?.phoneNumber}
                error={errors}
                touchedFields={touchedFields}
                reset={reset}
                info={phoneInfoMessage}
                disabled={isMerchant}
                value={customer?.phoneNumber}
              />
            </div>
            <div>
              <KLInput
                name="mail"
                control={control}
                label="E-posta Adresi"
                rules={emailValidation()}
                error={errors}
                defaultValue={customer?.eMail}
                touchedFields={touchedFields}
              />
            </div>
						{isMerchant ? (
						<>
							{isPermissonCollectionAllowed ? (
							<>
							<FormControlLabel
                style={{ margin: "0", display: "flex", alignItems: "flex-start" }}
                control={<Checkbox onChange={handleNewsCheck} checked={eBulletin} />}
                label={
                  <p className="desc" style={{ marginTop: "8px" }}>
                    Kampanyalar hakkında Koalay ve Hesapkurdu’ndan e-posta, sms ve telefonla bilgi almak istiyorum. Yukarıda girdiğim ve
										{" "}
										<a onClick={(e) => userConsentHook(e)}>
										açık rıza metnindeki
										</a>{" "}
										verilerin işlenmesine izin veriyorum.
                  </p>
                }
              />
							</>) : (
							<>
								<FormControlLabel
									style={{ margin: "0", display: "flex", alignItems: "flex-start" }}
									control={<Checkbox onChange={handleNewsCheck} checked={eBulletin} />}
									label={
										<p className="desc" style={{ marginTop: "8px" }}>
											Kampanyalar hakkında Koalay’dan e-posta, sms ve telefonla bilgi almak istiyorum. Yukarıda girdiğim ve
											{" "}
											<a onClick={(e) => userConsentHook(e)}>
											açık rıza metnindeki
											</a>{" "}
											verilerin işlenmesine izin veriyorum.
										</p>
									}
								/>
							</>
							)}
						</>) : (
						<>
							{consumerObj.type === "SelfServiceKoalay" || consumerObj.partnerSbn === "HSPKDLK" ? (
							  <FormControlLabel
                style={{ margin: "0", display: "flex", alignItems: "flex-start" }}
                control={<Checkbox onChange={handleNewsCheck} checked={eBulletin} />}
                label={
                  <p className="desc" style={{ marginTop: "8px" }}>
                    Kampanyalar hakkında Koalay ve Hesapkurdu’ndan e-posta, sms ve telefonla bilgi almak istiyorum. Yukarıda girdiğim ve
										{" "}
										<a onClick={(e) => userConsentHook(e)}>
										açık rıza metnindeki
										</a>{" "}
										verilerin işlenmesine izin veriyorum.
                  </p>
                }
              />
						) :
              !isAgency && (
                <div>
                  <FormControlLabel
                    style={{ margin: "0", display: "flex", alignItems: "flex-start" }}
                    control={<Checkbox onChange={handleNewsCheck} checked={eBulletin} />}
                    label={
                      <p className="desc" style={{ marginTop: "8px" }}>
                        Ürünler, yenilikler ve kampanyalar hakkında e-bülten ve sms almak istiyorum.
                      </p>
                    }
                  />
                </div>
              )
            }
						</>
						)}
						
          </form>
          <div>
            {!isMobile ? (
              <Button
                variant="contained"
                disabled={!isValid}
                style={{
                  width: !isMobile ? "254px" : "100%",
                  height: "45px",
                  margin: "24px 7px",
                  boxSizing: "border-box",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.4)",
                  borderRadius: "5px",
                  textTransform: "none",
                }}
                onClick={handleSubmit(onSubmit)}
              >
                {isRenewal ? "Devam Et" : "Teklifleri Göster"}
              </Button>
            ) : (
              ""
            )}
          </div>
          <div>
            <p className="desc">
              Kişisel Verilerin Korunması Hakkında Aydınlatma Metni&apos;ne{" "}
              <StyledSpan style={{ cursor: "pointer" }} onClick={() => setIsPersonalDataModalOpen(true)}>
                buradan
              </StyledSpan>{" "}
              ulaşabilirsiniz.
            </p>
          </div>
        </Grid>
        {!isAgency && (
          <Grid xs={12} sm={12} lg={6} xl={6} item>
            <div style={{ marginTop: isMobile ? "" : "55px", justifyContent: "center" }}>
              <div
                className="step-description-box"
                style={{ background: "#F0FFDC", padding: "15px", borderRadius: "5px" }}
              >
                <div
                  className="step-description-item"
                  style={{ display: "flex", alignItems: "flex-start", marginBottom: "12px" }}
                >
                  <img src={InfoSVG} alt="" className="svg-icon" />
									{!isMerchant && (
                    <p className="disclaimer">
                      İletişim bilgilerinizi, size en uygun teklifleri en doğru zamanda ulaştırabilmek için istiyoruz
                    </p>
                  )}
                  {isMerchant && (
                    <p className="disclaimer">
                      Kişisel bilgileriniz size en uygun poliçe tekliflerini bulabilmek amacıyla kullanılır.
                    </p>
                  )}
                </div>
                <div className="step-description-item" style={{ display: "flex", alignItems: "flex-start", marginBottom: "12px" }}>
                  {!isMerchant && (
                    <p className="disclaimer" style={{ marginLeft: "25px" }}>
                      Ayrıca güvenliğiniz için verdiğiniz cep telefonu numarasını doğruluyoruz.
                    </p>
                  )}
                  {isMerchant && (
                    <p className="disclaimer" style={{ marginLeft: "25px" }}>
                      Böylece her zamankinden daha güvenilir ve doğru fiyatlanmış tekliflere ulaşırsınız.
                    </p>
                  )}
                </div>
								{!isKPSActive && !isVkn && (<div className="step-description-item" style={{ display: "flex", alignItems: "flex-start" }}>
                    <p className="disclaimer" style={{ marginLeft: "25px" }}>
										Ad, soyad ve doğum tarihini kimlikte yazdığı gibi girmeniz gerekmektedir.
                    </p>
                </div>)}
              </div>
            </div>
            <div style={{ justifyContent: "center", display: isMobile ? "none" : "flex", marginTop:'20px' }}>
              {!isMerchant && <img src={vehiclePhoto} alt="" />}
              {isMerchant && <img src={CengizPhoto} alt="" />}
            </div>
          </Grid>
        )}
        {isAgency && (
          <Grid xs={12} sm={12} lg={6} xl={6} item style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginTop: isMobile ? "" : "55px", justifyContent: "center" }}>
              <div
                className="step-description-box"
                style={{ background: "#F0FFDC", padding: "15px", borderRadius: "5px" }}
              >
                <div
                  className="step-description-item"
                  style={{ display: "flex", alignItems: "flex-start", marginBottom: "12px" }}
                >
                  <img src={InfoSVG} alt="" className="svg-icon" />
                  <p className="disclaimer">
                    Bu bilgiler, kişiye özel en uygun poliçe tekliflerini bulabilmek için kullanılır.
                  </p>
                </div>
                <div className="step-description-item" style={{ display: "flex", alignItems: "flex-start", marginBottom: "12px"  }}>
                  <p className="disclaimer" style={{ marginLeft: "25px" }}>
                    Poliçe yenileme döneminde, müşterinizin bilgileri sizinle paylaşılacaktır.
                  </p>
                </div>
								{!isKPSActive && !isVkn && (<div className="step-description-item" style={{ display: "flex", alignItems: "flex-start" }}>
                    <p className="disclaimer" style={{ marginLeft: "25px" }}>
										Ad, soyad ve doğum tarihini kimlikte yazdığı gibi girmeniz gerekmektedir.
                    </p>
                </div>)}
              </div>
            </div>
          </Grid>
        )}
      </Grid>
      {isMobile ? (
        <Button
          variant="contained"
          disabled={!isValid}
          style={{
            width: !isMobile ? "254px" : "100%",
            height: "55px",
            boxSizing: "border-box",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.4)",
            borderRadius: "5px",
            textTransform: "none",
            display: "block",
            position: "fixed",
            bottom: 0,
            left: 0,
            margin: "0 0 0 -20%",
          }}
          onClick={handleSubmit(onSubmit)}
        >
          {isRenewal ? "Devam Et" : "Teklifleri Göster"}
        </Button>
      ) : (
        ""
      )}
      <Modal
        open={isOTPRequired}
        onClose={() => setIsOTPRequired(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <OTP quoteId={quoteId} />
      </Modal>
      <InfoModal
        isOpen={isPersonalDataModalOpen}
        closeModal={setIsPersonalDataModalOpen}
        title={"6698 sayılı Kişisel Verilerin Korunması Kanunu Kapsamında Bilgilendirme"}
      >
        <PersonalDataInfo />
      </InfoModal>
			<CenteredModal className="user-consent-modal" isOpen={userConsentModal} setOpen={() => setUserConsentModal(false)}>
        <UserConsentModal />
      </CenteredModal>
    </>
  );
};

export default withRouter(ContactInfo);

