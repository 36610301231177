import React, { useState } from 'react';
import Switch from '@mui/material/Switch';
import '../../../../shared/assets/styles/offer.scss';
import { styled } from '@mui/material/styles';
import useWindowSize from '../../../../shared/_hooks/windowResizeHook';

import Skeleton from '@mui/material/Skeleton';

export const SkeletonOffer = (props) => {
    const size = useWindowSize();
    const isMobile = size.width <= 880;

    return (
        <div className={`offer offer-loading`}>
            <div className={`offer-container`}>
                <div className="offer-detail">
                    <div className="left-side">
                        <div className="insurer-image">
                            <Skeleton variant="rectangular" width={isMobile ? 90 : 120} height={isMobile ? 40 : 64} />
                        </div>
                        <div className="offer-title-teminat">
                            <div className="offer-title" >
                                <Skeleton variant="rectangular" width={120} height={20} style={{marginLeft: isMobile ? '': '8px', marginTop: isMobile ? '8px' : ''}}/>
                            </div>
                        </div>
                    </div>
                    <div className="right-side">
                        <div className="offer-price">
                            <div className="price">
                                <Skeleton variant="rectangular" width={120} height={30} />
                            </div>
                            <div className="installment">
                                <Skeleton variant="rectangular" width={80} height={20} style={{marginTop: '5px'}} />
                            </div>
                        </div>
                        <div className="offer-buy">
                            <Skeleton variant="rectangular" width={isMobile ? 70 : 168} height={45} style={{marginRight: isMobile ? '16px': '24px', marginBottom: isMobile ? '16px': ''}}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SkeletonOffer;
