import { combineReducers } from 'redux';
import informationReducer from './informationReducer';
import stepReducer from './stepReducer';
import campaignReducer from './campaignReducer';

import productReducer from './productReducer';
import shellReducer from './shellReducer';
import toastrReducer from './toastrReducer';
import loadingReducer from './loadingReducer';
import themeReducer from './themeReducer';
import headerReducer from './headerReducer';
import consumerReducer from './consumerReducer';
import ccCrossSellReducer from './ccCrossSellReducer';

const rootReducer = combineReducers({
  info: informationReducer,
  step: stepReducer,
  product: productReducer,
  shell: shellReducer,
  toastr: toastrReducer,
  loading: loadingReducer,
  campaign: campaignReducer,
  theme: themeReducer,
  header: headerReducer,
  consumer: consumerReducer,
  ccCrossSell: ccCrossSellReducer,
});

export default rootReducer;
