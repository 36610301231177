export const genders = [
  {
    Text: 'Erkek',
    Value: 1,
  },
  {
    Text: 'Kadın',
    Value: 2,
  },
];

export const genderEnum = {
  Erkek: 1,
  Kadın: 2,
};
