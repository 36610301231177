import axiosInstance from '../../shared/_helpers/axiosHelper';
import { API } from '../../shared/_helpers/env';

async function getQuotationCoverages(teklifTid, callback) {
  await axiosInstance
    .get(`${API}/api/coverage/quotation-coverages?TeklifTId=${teklifTid}&DefaultCoverage=false`)
    .then((response) => {
      try {
        callback(response.data.Result);
      } catch (error) {
        console.log(error);
      }
    });
}
async function GetRiskObjectEnumValues(teklifTid, attrId, pid, parentId, callback) {
  if (parentId !== null) {
    await axiosInstance
      .get(
        `${API}/api/coverage/risk-object-enum-values?TeklifTId=${teklifTid}&RiskObjectAttributeId=${attrId}&ProductId=${pid}&parentEnumValue=${parentId}`,
      )
      .then((response) => {
        try {
          callback(response.data.Result);
        } catch (error) {
          console.log(error);
        }
      });
  } else {
    await axiosInstance
      .get(
        `${API}/api/coverage/risk-object-enum-values?TeklifTId=${teklifTid}&RiskObjectAttributeId=${attrId}&ProductId=${pid}`,
      )
      .then((response) => {
        try {
          callback(response.data.Result);
        } catch (error) {
          console.log(error);
        }
      });
  }
}
async function GetChildRiskObjectAttr(pid, attrId, enumValue, callback) {
  if (enumValue === '') {
    await axiosInstance
      .get(
        `${API}/api/coverage/child-risk-object-attributes?ProductId=${pid}&ParentRiskObjectAttributeId=${attrId}`,
      )
      .then((response) => {
        try {
          callback(response.data.Result);
        } catch (error) {
          console.log(error);
        }
      });
  } else {
    await axiosInstance
      .get(
        `${API}/api/coverage/child-risk-object-attributes?ProductId=${pid}&ParentRiskObjectAttributeId=${attrId}&paramEnumValue=${enumValue}`,
      )
      .then((response) => {
        try {
          callback(response.data.Result);
        } catch (error) {
          console.log(error);
        }
      });
  }
}
async function GetPopularCoverages(pid, defaultCoverage, callback) {
  await axiosInstance
    .get(
      `${API}/api/coverage/popular-coverages?TeklifTID=${pid}&defaultCoverage=${defaultCoverage}`,
    )
    .then((response) => {
      try {
        callback(response.data.Result);
      } catch (error) {
        console.log(error);
      }
    });
}

async function GetPopularCoveragesv2(TeklifTID, callback) {
  await axiosInstance
    .get(
      `${API}/api/v2/coverage/casco-coverages?TeklifTID=${TeklifTID}`,
    )
    .then((response) => {
      try {
        callback(response.data.Result);
      } catch (error) {
        console.log(error);
      }
    });
}

async function GetTrafficCoveragesv2(TeklifTID, callback) {
  await axiosInstance
    .get(
      `${API}/api/v2/coverage/traffic-coverages?TeklifTID=${TeklifTID}`,
    )
    .then((response) => {
      try {
        callback(response.data.Result);
      } catch (error) {
        console.log(error);
      }
    });
}

async function CompareCoverages(teklifTIds, callback) {
  await axiosInstance
    .post(
      `${API}/api/coverage/comparison`, { teklifTIds },
    )
    .then((response) => {
      try {
        callback(response.data.Result);
      } catch (error) {
        console.log(error);
      }
    });
}

const coverageService = {
  getQuotationCoverages,
  GetRiskObjectEnumValues,
  GetChildRiskObjectAttr,
  GetPopularCoverages,
  GetPopularCoveragesv2,
  GetTrafficCoveragesv2,
  CompareCoverages,
};
export default coverageService;
