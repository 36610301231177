import React from "react";

const CoverageCard = (props) => {
  const { coveregeGroupName, coverages } = props;

  return (
    <>
      <div className="coverage-card">
        <p className="coverage-card-title">{coveregeGroupName}</p>
        <div className="coverage-list-row">
          {coverages.map((coverage) => (
            <div className="coverage-row">
              <p className="coverage-name">{coverage.coverageName}</p>
              <p className="coverage-value">{coverage.displayText}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export const CoverageList = (props) => {
  const { coverageGroups } = props;

  return (
    <>
      <div className="coverage-card-list">
        {coverageGroups.map((coverageGroup) => (
          <CoverageCard {...coverageGroup} />
        ))}
      </div>
    </>
  );
};

export default CoverageList;
