import React from 'react';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import IllnnesDeclarationText from './illnesDeclarationText';
import './illness-declataion-list.scss'

export const IllnnesDeclarationList = (props) => {
  const { location: { pathname } } = window;

  return (
    <div className="illnnes-declaration-list-box">
      <IllnnesDeclarationText />
    </div>

  );
};

export default withRouter(IllnnesDeclarationList);
