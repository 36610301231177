import axiosInstance from '../../shared/_helpers/axiosHelper';
import { DOCUMENT_API } from '../../shared/_helpers/env';
import { interceptors } from '../../shared/_helpers/interceptors';

async function getDisclosurePdf(teklifTid, qGuid, callback) {
  interceptors.setXConsumer();
  await axiosInstance.get(`${DOCUMENT_API}/disclosurepdf?teklifTId=${teklifTid}&qGuid=${qGuid}`, {
    responseType: 'arraybuffer',
    headers: {
      'X-Response-Content-Type': 'application/pdf',
    },
  }).catch((error) => {
    if (error.response) {
      console.log(error.response.data);
    }
  }).then((response) => {
    try {
      callback(response);
    } catch (error) {
      console.log(error);
    }
  });
}

async function getPolicyPdf(teklifTid, callback, xConsumer = null) {
  if (xConsumer) {
    axiosInstance.defaults.headers.common['X-Consumer'] = xConsumer;
  } else {
    interceptors.setXConsumer();
  }
  await axiosInstance.get(`${DOCUMENT_API}/policyPdf?teklifTId=${teklifTid}`, {
    responseType: 'arraybuffer',
    headers: {
      'X-Response-Content-Type': 'application/pdf',
    },
  }).catch((error) => {
    if (error.response) {
      console.log(error.response.data);
    }
  }).then((response) => {
    try {
      callback(response);
    } catch (error) {
      console.log(error);
    }
  });
}

async function getQuotationPdf(teklifTid, qGuid, callback) {
  interceptors.setXConsumer();
  await axiosInstance.get(`${DOCUMENT_API}/quotationpdf?teklifTId=${teklifTid}&qGuid=${qGuid}`, {
    responseType: 'arraybuffer',
    headers: {
      'X-Response-Content-Type': 'application/pdf',
    },
  }).catch((error) => {
    if (error.response) {
      console.log(error.response.data);
    }
  }).then((response) => {
    try {
      callback(response);
    } catch (error) {
      console.log(error);
    }
  });
}

async function getMakbuzPdf(teklifTid, callback) {
  interceptors.setXConsumer();
  await axiosInstance.get(`${DOCUMENT_API}/receiptdocument?teklifTId=${teklifTid}`, {
    responseType: 'arraybuffer',
    headers: {
      'X-Response-Content-Type': 'application/pdf',
    },
  }).catch((error) => {
    if (error.response) {
      console.log(error.response.data);
    }
  }).then((response) => {
    try {
      callback(response);
    } catch (error) {
      console.log(error);
    }
  });
}

const documentService = {
  getDisclosurePdf,
  getPolicyPdf,
  getMakbuzPdf,
  getQuotationPdf,
};
export default documentService;
