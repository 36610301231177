import React, { useState } from 'react';
import '../../../../shared/assets/styles/components/not-offer-box.scss';
import styled from '@emotion/styled';
import { Tooltip, tooltipClasses } from '@mui/material';
import infoBlueSVG from '../../../../shared/assets/img/svg/info-blue.svg';

import useWindowSize from '../../../../shared/_hooks/windowResizeHook';
import NotOfferInsurer from './NotOfferInsurer';
import CenteredModal from '../widget/CenteredModal';

export const NotOfferContainer = (props) => {
  const screenSize = useWindowSize();
  const [hintPopup, setHintPopup] = useState(false);
  const isMobile = screenSize.width <= 880;
  const {
    offers, cascoOffers, trafficOffers, isCascoRenewal, isTrafficRenewal, size, isAgency, isMerchant,
  } = props;
  const uniqueTrafficNames = [];
  const uniqueCascoNames = [];

  const filteredTrafik = trafficOffers.filter((to) => {
    const hasOffer = offers.some((offer) => offer.InsurerCommercialName === to.InsurerCommercialName && offer.Brans === to.Brans);
    return !hasOffer;
  }).filter((element) => {
    const isDuplicate = uniqueTrafficNames.includes(element.InsurerCommercialName);
    if (!isDuplicate && element.IsDisplayLogoNotQuoting) {
      uniqueTrafficNames.push(element.InsurerCommercialName);
      return true;
    }
    return false;
  });

  const filteredKasko = cascoOffers.filter((co) => {
    const hasOffer = offers.some((offer) => offer.InsurerCommercialName === co.InsurerCommercialName && offer.Brans === co.Brans);
    return !hasOffer;
  }).filter((element) => {
    const isDuplicate = uniqueCascoNames.includes(element.InsurerCommercialName);
    if (!isDuplicate && element.IsDisplayLogoNotQuoting) {
      uniqueCascoNames.push(element.InsurerCommercialName);
      return true;
    }
    return false;
  });

  const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 13,
      },
    }),
  );

  return (
    <>
      {!isMobile ? (
        <>
          {filteredTrafik.length > 0 && !isTrafficRenewal && (
          <div
            className={`not-offer-container ${size === 'large' ? 'not-offer-container-large' : ''}`}
            style={{ marginBottom: filteredKasko.length <= 0 ? '40px' : '0' }}
          >
            {(isAgency || isMerchant) && (
            <div className="title-with-info">
              <h3 className="not-offer-title">
                Online trafik sigortası teklifi verilemeyen sigorta şirketleri:
              </h3>
              <LightTooltip
                title={<span>Teklif alamadığınız firmalar için Koalay Beni Arasın üzerinden talep açınız.</span>}
              >
                <img
                  src={infoBlueSVG}
                  style={{ marginLeft: '8px' }}
                  alt="Var"
                />
              </LightTooltip>
            </div>
            )}
            {!isAgency && !isMerchant && (
            <h3 className="not-offer-title">
              Trafik sigortası teklifi verilemeyen sigorta şirketleri:
            </h3>
            )}
            <div className="insurers">
              {filteredTrafik.map((offer) => (
                <NotOfferInsurer offer={offer} />
              ))}
            </div>
          </div>
          )}
          {filteredKasko.length > 0 && !isCascoRenewal && (
          <div className={`not-offer-container ${size === 'large' ? 'not-offer-container-large' : ''}`} style={{ marginBottom: '40px' }}>
            {(isAgency || isMerchant) && (
            <div className="title-with-info">
              <h3 className="not-offer-title">
                Online kasko sigortası teklifi verilemeyen sigorta şirketleri:
              </h3>
              <LightTooltip
                title={<span>Teklif alamadığınız firmalar için Koalay Beni Arasın üzerinden talep açınız.</span>}
              >
                <img
                  src={infoBlueSVG}
                  style={{ marginLeft: '8px' }}
                  alt="Var"
                />
              </LightTooltip>
            </div>
            )}
            {!isAgency && !isMerchant && (
            <h3 className="not-offer-title">
              Kasko sigortası teklifi verilemeyen sigorta şirketleri:
            </h3>
            )}
            <div className="insurers">
              {filteredKasko.map((offer) => (
                <NotOfferInsurer offer={offer} />
              ))}
            </div>
          </div>
          )}
        </>
      ) : (
        <>
          {filteredTrafik.length > 0 && !isTrafficRenewal && (
          <div
            className={`not-offer-container ${size === 'large' ? 'not-offer-container-large' : ''}`}
            style={{ marginBottom: filteredKasko.length <= 0 ? '40px' : '12px' }}
          >
            {(isAgency || isMerchant) && (
            <div className="title-with-info">
              <h3 className="not-offer-title">
                Online trafik sigortası teklifi verilemeyen
                {' '}
                <br />
                {' '}
                sigorta şirketleri:
              </h3>
              <LightTooltip
                title={<span>Teklif alamadığınız firmalar için Koalay Beni Arasın üzerinden talep açınız.</span>}
              >
                <img
                  src={infoBlueSVG}
                  style={{ marginLeft: '8px' }}
                  alt="Var"
                  onClick={() => (setHintPopup(true))}
                />
              </LightTooltip>
            </div>
            )}
            {!isAgency && !isMerchant && (
            <h3 className="not-offer-title">
              Trafik sigortası teklifi verilemeyen sigorta şirketleri:
            </h3>
            )}
            <div className="insurers">
              {filteredTrafik.map((offer) => (
                <NotOfferInsurer offer={offer} />
              ))}
            </div>
          </div>
          )}
          {filteredKasko.length > 0 && !isCascoRenewal && (
          <div className={`not-offer-container ${size === 'large' ? 'not-offer-container-large' : ''}`} style={{ marginBottom: '40px' }}>
            {(isAgency || isMerchant) && (
            <div className="title-with-info">
              <h3 className="not-offer-title">
                Online kasko sigortası teklifi verilemeyen sigorta şirketleri:
              </h3>
              <LightTooltip
                title={<span>Teklif alamadığınız firmalar için Koalay Beni Arasın üzerinden talep açınız.</span>}
              >
                <img
                  src={infoBlueSVG}
                  style={{ marginLeft: '8px' }}
                  alt="Var"
                  onClick={() => (setHintPopup(true))}
                />
              </LightTooltip>
            </div>
            )}
            {!isAgency && !isMerchant && (
            <h3 className="not-offer-title">
              Kasko sigortası teklifi verilemeyen sigorta şirketleri:
            </h3>
            )}
            <div className="insurers">
              {filteredKasko.map((offer) => (
                <NotOfferInsurer offer={offer} />
              ))}
            </div>
          </div>
          )}
          {hintPopup !== null && (
          <CenteredModal isOpen={hintPopup} setOpen={setHintPopup}>
            <br />
            <p className="disclaimer">Teklif alamadığınız firmalar için Koalay Beni Arasın üzerinden talep açınız.</p>
          </CenteredModal>
          )}
        </>
      )}
    </>
  );
};

export default NotOfferContainer;
