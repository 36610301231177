import { shellTypes } from '../types/shell.types';

function setFunnelInfo(funnel) {
  return {
    type: shellTypes.SET_FUNNEL_INFO,
    payload: {
      funnel,
    },
  };
}
function setIsRenewal(isRenewal) {
  return {
    type: shellTypes.SET_RENEWAL_INFO,
    payload: {
      isRenewal,
    },
  };
}
function setIsAgency(isAgency) {
  return {
    type: shellTypes.SET_DEALER_INFO,
    payload: {
      isAgency,
    },
  };
}
function setIsMerchant(isMerchant) {
  return {
    type: shellTypes.SET_MERCHANT_INFO,
    payload: {
      isMerchant,
    },
  };
}
function setisCallCenter(isCallCenter) {
  return {
    type: shellTypes.SET_CALL_CENTER_INFO,
    payload: {
      isCallCenter,
    },
  };
}
function setIsBack(isBack) {
  return {
    type: shellTypes.SET_IS_BACK,
    payload: {
      isBack,
    },
  };
}
function setQuotationInfo(quotation) {
  return {
    type: shellTypes.SET_QUOTATION_INFO,
    payload: {
      quotation,
    },
  };
}
function setSbn(sbn) {
  return {
    type: shellTypes.SET_SBN,
    payload: {
      sbn,
    },
  };
}

function setThemeByPage(value) {
  return {
    type: shellTypes.SET_THEME_BY_PAGE,
    payload: {
      value,
    },
  };
}
function setIsKoalaySS(value) {
  return {
    type: shellTypes.SET_IS_KOALAY_SS,
    payload: {
      value,
    },
  };
}

const shellActions = {
  setFunnelInfo,
  setIsRenewal,
  setIsAgency,
  setIsMerchant,
  setisCallCenter,
  setIsBack,
  setQuotationInfo,
  setSbn,
  setThemeByPage,
  setIsKoalaySS,
};
export default shellActions;
