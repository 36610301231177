import { headerTypes } from '../types/header.types';

const initialState = {
  useHeader: false,
  useStepper: true,
  mainNav: false,
};

export default function HEADER_REDUCER(state = initialState, { type, payload }) {
  switch (type) {
    case headerTypes.SET_USE_HEADER:
      return {
        ...state,
        useHeader: payload.useHeader,
      };
    case headerTypes.SET_USE_STEPPER:
      return {
        ...state,
        useStepper: payload.useStepper,
      };
    case headerTypes.SET_MAIN_NAV:
      return {
        ...state,
        mainNav: payload.mainNav,
      };
    default:
      return state;
  }
}
