// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zAl6T3jc2fgmEWdCLIHM{margin:0 !important;display:flex;align-items:center;margin-bottom:-8px}.zAl6T3jc2fgmEWdCLIHM p{font-size:14px !important;color:#787878}@media only screen and (max-width: 880px){.zAl6T3jc2fgmEWdCLIHM{margin-top:6px !important;margin-bottom:-3px !important}}
`, "",{"version":3,"sources":["webpack://./src/tss/views/components/UserConsent/userConsent.module.scss"],"names":[],"mappings":"AAAA,sBACI,mBAAoB,CACpB,YAAa,CACb,kBAAmB,CACnB,kBAAmB,CAJvB,wBAMQ,yBAA0B,CAC1B,aAAc,CACjB,0CARL,sBAUQ,yBAA0B,CAC1B,6BAA8B,CAErC","sourcesContent":[".label {\n    margin: 0 !important;\n    display: flex;\n    align-items: center;\n    margin-bottom: -8px;\n    p {\n        font-size: 14px !important;\n        color: #787878;\n    }\n    @media only screen and (max-width: 880px) {\n        margin-top: 6px !important;\n        margin-bottom: -3px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `zAl6T3jc2fgmEWdCLIHM`
};
export default ___CSS_LOADER_EXPORT___;
