import React, { useState, useEffect } from 'react';
import {
  Button, Paper, Select, InputLabel, FormControl, MenuItem, TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { withRouter } from 'react-router-dom';

import useWindowSize from '../../../../shared/_hooks/windowResizeHook';

export const EditCoverage = (props) => {
  const size = useWindowSize();
  const isMobile = size.width <= 880;
  const {
    title, inputTitle, options, selectedValue, handleClose, type,
  } = props;
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedEnumValue, setSelectedEnumValue] = useState('');
  const [selectedVal, setSelectedVal] = useState('');
  const [isOptionChanged, setIsOptionChanged] = useState(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -40%)',
    width: isMobile ? 343 : 449,
    height: 228,
    bgcolor: 'background.paper',
    padding: '30px 10px 0px 10px',
    textAlign: 'center',
    background: '#FFFFFF',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
  };

  const selectOption = (e) => {
    setSelectedOption(e.target.value);
    setSelectedEnumValue(e.view.event.target.getAttribute('enumValue'));
    setSelectedVal(e.view.event.target.getAttribute('val'));
    if (e.target.value !== selectedValue) {
      setIsOptionChanged(true);
    }
  };
  const changeDecimalValue = (e) => {
    setSelectedOption(e.target.value);
    setIsOptionChanged(true);
  };
  useEffect(() => {
    setSelectedOption(selectedValue);
  }, []);
  return (
    <>
      <Paper style={style} square>
        <CloseIcon
          onClick={handleClose}
          style={{
            width: isMobile ? '22px' : '26px',
            height: isMobile ? '22px' : '26px',
            position: 'absolute',
            right: '10px',
            top: '10px',
            pointer: 'cursor',
          }}
        />
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <h4>{title}</h4>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '33px',
              width: isMobile ? '100%' : 'unset',
            }}
          >
            {type !== 'Decimal' && (
              <FormControl>
                <InputLabel id="meslek-label">{inputTitle}</InputLabel>
                <Select
                  className="MuiOutlinedInput-root"
                  labelId="meslek-label"
                  id="meslek-label"
                  label={inputTitle}
                  defaultValue={selectedValue}
                  onChange={selectOption}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center',
                    },
                    style: {
                      width: !isMobile ? '254px' : '100%',
                      maxWidth: '150px',
                      maxHeight: '450px',
                    },
                  }}
                >
                  {options.getRiskObjectEnumValues.map((opt) => (
                    <MenuItem key={opt.displayName} value={opt.displayName} enumValue={opt.enumValue} val={opt.value}>
                      {opt.displayName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {type === 'Decimal' && (
              <TextField
                inputProps={{ type: 'text', pattern: 'd*', maxLength: '10' }}
                label={inputTitle}
                onChange={changeDecimalValue}
                defaultValue={selectedValue}
              />
            )}
          </div>
          <Button
            variant="contained"
            disabled={type === 'Decimal' ? false : !isOptionChanged}
            style={{
              width: !isMobile ? '254px' : '100%',
              height: '45px',
              boxSizing: 'border-box',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
              borderRadius: '5px',
              textTransform: 'none',
            }}
            onClick={() => props.updateCoverage({ selectedOption, selectedEnumValue, selectedVal })}
          >
            {type === 'Decimal' && selectedOption === '' ? 'Varsayılana Geri Dön' : 'Değiştir'}
          </Button>
        </div>
      </Paper>
    </>
  );
};

export default withRouter(EditCoverage);
