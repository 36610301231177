import React, { useEffect, useState } from 'react';

import {
  Autocomplete, Button, MenuItem, Modal, Paper, TextField,
} from '@mui/material';

import { useDispatch } from 'react-redux';
import tickSVG from '../../../../../../shared/assets/img/svg/tss-checkmark.svg';
import closeSVG from '../../../../../../shared/assets/img/svg/tss-xmark.svg';
import hospitalSVG from '../../../../../../shared/assets/img/svg/hospital-building.svg';
import noSearchResult from '../../../../../../shared/assets/img/svg/noSearchResult.svg';

import classes from './productCard.module.scss';
import tssButtonStyle from '../../../../../data/tssButtonStyle';
import { constructTreatmentTypeText, countStandingTreatment, returnCDNlink } from '../../../../../helpers/stringHelpers';
import commonService from '../../../../../../motor/_services/common.service';
import loadingKlActions from '../../../../../../motor/actions/loadingActions';
import productService from '../../../../../_services/product.service';
import ContractedProviderListItem from '../ContractedProviderListItem/contractedProviderListItem.component';
import MapComponent from '../Map/map.component';
import { priceFormatter } from '../../../../../../shared/_helpers/helperFunctions';
import { historyPushWithSearch } from "../../../../../../shared/_helpers/historyPushWithSearch";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { UserStates } from '../../../../../../motor/data/userStates';
import useUserState from '../../../../../../shared/_hooks/userStateHook';
import userStateService from '../../../../../../motor/_services/userState.service';
import LoadingSpinner from '../../../../../../shared/components/loading-spinner/loading-spinner.component';

const ProductCard = (props) => {
  const {
    offer,
    popularCoverages,
    customerCity,
    handleOfferDetailModal
  } = props;
	const history = useHistory();
  const dispatch = useDispatch();
  const { setUserState } = useUserState();

  const [hospitalNetworkModal, setHospitalNetworkModal] = useState(false);
  const [cities, setCityList] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [institutionTypes, setInstitutionTypes] = useState([]);
  const [selectedCity, setSelectedCity] = useState(customerCity);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedDistrictRow, setSelectedDistrictRow] = useState(null);
  const [selectedInstitutionType, setSelectedInstitutionType] = useState(null);
  const [selectedCityRow, setSelectedCityRow] = useState(customerCity);
  const [activeTab, setActiveTab] = useState(1);
  const [iframeSrc, setIframeSrc] = useState(null);
  const [mapVisible, setMapVisible] = useState(true);
  const [listVisible, setListVisible] = useState(true);
  const [contractedProvidersList, setContractedProvidersList] = useState(null);
  const [modalDataLoading, setModalDataLoading] = useState(false);

  const getInstitutionType = async () => {
    productService.getInstitutionType(offer.productId, (response) => {
      setInstitutionTypes(response);
    });
  };

  const getContractedProviders = async (productId, city, district, insType) => {
    dispatch(loadingKlActions.showKlLoadingEmpty());
    setModalDataLoading(true);
    productService.getContractedProviders({
      ProductId: productId, CityCode: city, TownCode: 0, InstutitionType: '-1',
    }, (response) => {
      setIframeSrc(response.iframeSource);
      // setIframeSrc('https://acente.quicksigorta.com/tamamlayici-saglik-sigortasi/networkler');
      setMapVisible(response.isMapTabVisible);
      setListVisible(response.isListTabVisible);
      setContractedProvidersList(response.items);
      setModalDataLoading(false);
    });
  };

  const searchContractedProviders = () => {
    productService.getContractedProviders({
      ProductId: offer.productId, CityCode: selectedCityRow?.code, TownCode: selectedDistrict?.toString() || 0, InstutitionType: selectedInstitutionType?.enumValue ?? '-1',
    }, (response) => {
      setContractedProvidersList(response.items);
    });
  };

  const getDistricts = (e, val) => {
    setSelectedDistrict('');
    setSelectedDistrictRow(null);
    if (e.target.value) {
      const formattedValue = e.target.value.toLocaleString('tr-TR', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
      setSelectedCity(formattedValue);
      setSelectedCityRow(val);
      commonService.getDitrictsByCityId(formattedValue, (data) => {
        const sortedDistricts = data.districts.sort((a, b) => a.name.localeCompare(b.name, 'tr'));

        setDistricts(sortedDistricts);
      });
    } else {
      setSelectedCity(null);
      setSelectedCityRow(null);
    }
  };

  const districtSelect = (e, val) => {
    setSelectedDistrict(e.target.value);
    setSelectedDistrictRow(val);
  };

  //* Getting districts on page open from customerCity
  useEffect(() => {
    if (customerCity) {
      if (customerCity.code.toString().length === 1) {
        customerCity.code = '0' + customerCity.code.toString();
      }
      setSelectedCityRow(customerCity);
      commonService.getDitrictsByCityId(customerCity.code, (data) => {
        const sortedDistricts = data.districts.sort((a, b) => a.name.localeCompare(b.name, 'tr'));

        setDistricts(sortedDistricts);
      });
    }
  }, [customerCity]);

  const openHospitalNetwork = () => {
    getInstitutionType();
    getContractedProviders(offer.productId, selectedCityRow?.code ?? customerCity?.code, 0, -1);
    setHospitalNetworkModal(true);
    dispatch(loadingKlActions.showKlLoadingEmpty());
    commonService.getCityList((data) => {
      if (data.provinces) {
        const uniqueProvinces = [];
        const map = new Map();
        for (const province of data.provinces) {
          if (!map.has(province.code)) {
            map.set(province.code, true);
            uniqueProvinces.push({
              name: province.name,
              code: province.code,
            });
          }
        }
        setCityList(uniqueProvinces);
      }
    });
    dispatch(loadingKlActions.hideKlLoading());
  };

  const closeHospitalNetwork = () => {
    setHospitalNetworkModal(false);
  };

  // Preventing body scroll when modal is open
  useEffect(() => {
    if (hospitalNetworkModal) {
      document.body.style.overflowY = 'hidden'
    } else {
      document.body.style.overflowY = 'initial';
    }
  }, [hospitalNetworkModal]);

  const treatmentCountElement = countStandingTreatment(offer.quotationCoverageItem.coverageGroups) === '-' ? (
    <p className={`${classes.treatmentCount} ${classes.treatmentCountZero}`}>
      -
    </p>
  ) : (
    <p className={classes.treatmentCount}>
      {countStandingTreatment(offer.quotationCoverageItem.coverageGroups)}
    </p>
  );

  const CustomPaper = (props) => (
    <Paper
      style={{
        fontSize: '13px',
      }}
      elevation={8}
      {...props}
    />
  );

  useEffect(() => {
    if (!mapVisible) {
      setActiveTab(2);
    }
  }, [mapVisible]);
  
	
  const redirectToConfirmation = () => {
		const URL_PARAMS = new URLSearchParams(document.location.search);
		const jt = URL_PARAMS.get('jt') || null;
		const userSateData = {
			UserUID: jt,
			UserStateDefinition: UserStates.TSS_Teklif_Sayfasinda_Satin_Al_Tiklayan,
		};
		userStateService.setUserState(userSateData);
    window.dataLayer.push({ funnel_variables: null });
    window.dataLayer.push({
      event: 'insurance_coverage',
      funnel_variables: {
        network_type: offer.network.networkGroup,
        coverage_type: (offer.coverageGroup && offer.coverageGroup[0]) ? offer.coverageGroup[0].coverageGroupName : null
      }
    });
    historyPushWithSearch("/tamamlayici-saglik-sigortasi/confirmation", history, { teklifTId: offer.teklifTId });
  };

  return (
    <div className={classes.container}>
      <div className={classes.logoRow}>
        <div className={classes.imageContainer}>
          <img src={returnCDNlink(offer.insurerName)} />
        </div>
        <div className={classes.premiumAndInstallment}>
          <p className={classes.premium}>{priceFormatter.format(offer.grossPremium).split(',')[0].replace('₺', '')} TL</p>
          <p className={classes.installment}>{offer.paymentOptions[offer.paymentOptions.length - 1].text} İmkanı</p>
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.features}>
          <div className={classes.feature}>
            <p className={classes.subtitle}>Hastane Ağı:</p>
            <p className={classes.network}>
              {offer.network.networkGroup}
              {" - "}
              {offer.network.networkName}
            </p>
          </div>
          <div className={classes.feature}>
            <p className={classes.subtitle}>Tedavi Türü:</p>
            <p className={classes.network}>{constructTreatmentTypeText(offer.quotationCoverageItem.coverageGroups)}</p>
          </div>
          <div className={classes.feature}>
            <p className={classes.subtitle}>Ayakta Tedavi Adeti:</p>
            <p className={`${classes.subtitle} ${classes.subtitleDesktop}`}>Ayakta Tedavi </p>
            {treatmentCountElement}
          </div>
        </div>
        <div className={classes.importantFeature}>
          {popularCoverages.map((pop) => (
            <div className={classes.importantFeatureRow}>
              {offer.quotationCoverageItem.coverageGroups.some((coverageGroup) =>
                coverageGroup.coverages.some((coverage) => {
                  if (coverage.coverageId === pop.id) {
                    return <img key={pop.id} src={tickSVG} />;
                  }
                  return null;
                })
              ) ? (
                <img src={tickSVG} />
              ) : (
                <img src={closeSVG} />
              )}
              <p>{pop.name}</p>
            </div>
          ))}
        </div>
      </div>
      <div className={classes.bottomRow}>
        <div onClick={() => openHospitalNetwork()} className={classes.hospitalNetworkButton}>
          <img src={hospitalSVG} />
          <a>Hastane Ağını Gör</a>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            style={tssButtonStyle}
            variant="outlined"
            onClick={() => {
              handleOfferDetailModal(offer, true);
            }}
          >
            Poliçe Detayı
          </Button>
          <Button style={tssButtonStyle} variant="contained" onClick={redirectToConfirmation}>
            Satın Al
          </Button>
        </div>
      </div>
      <Modal
        open={hospitalNetworkModal}
        onClose={closeHospitalNetwork}
        className={classes.muiModal}
      >
        {iframeSrc ? (
          <div className={`${classes.modal} ${classes.iframeModalContainer} ${iframeSrc.includes('turkiyesigorta') && classes.turkiyeIframeModalContainer}`}>
            <div className={`${classes.iframeModalTopRow}  ${iframeSrc.includes('turkiyesigorta') && classes.turkiyeIframeModalTopRow}`}>
              {iframeSrc.includes('turkiyesigorta') ? <p>Network adını “Altın Network” seçtiğinizden emin olunuz.</p> : <p> </p>}
              <img className={`${iframeSrc.includes('quicksigorta') && classes.quickCloseButton}`} onClick={closeHospitalNetwork} src={closeSVG} />
            </div>
            <iframe
              src={iframeSrc}
              frameBorder="0"
              className={`${classes.iframeModal} ${iframeSrc.includes('turkiyesigorta') && classes.turkiyeIframe}  ${iframeSrc.includes('quicksigorta') && classes.quickIframe}`}
            ></iframe>
          </div>
        ) : (
          <div className={classes.modal}>
            <div className={classes.mobileTopRow}>
                <p>Hastane Ağı</p>
                <div className={classes.closeButtonMobile}>
                  <img onClick={closeHospitalNetwork} src={closeSVG} />
                </div>
            </div>
            <div className={classes.form}>
              <Autocomplete
                className={classes.autoComplete}
                openOnFocus
                id="model-type-select"
                options={cities}
                PaperComponent={CustomPaper}
                getOptionLabel={(option) => option.name}
                noOptionsText="Sonuç bulunamadı"
                value={selectedCityRow}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    maxWidth: "100%"
                  },
                }}
                filterOptions={(x, e) => {
                  if (e.inputValue) {
                    return x.filter((elem) => elem.name.toLocaleLowerCase().includes(e.inputValue.toLocaleLowerCase()));
                  }
                  return x;
                }}
                renderOption={(props, option, { selected }) => (
                  <MenuItem {...props} value={option.code} key={option.name} data-text={option.name}>
                    {option.name}
                  </MenuItem>
                )}
                onChange={(event, value) => {
                  getDistricts(event, value);
                }}
                
                renderInput={(params) => <TextField {...params} label="İl" />}
              />
              <Autocomplete
                className={classes.autoComplete}
                openOnFocus
                disabled={!districts || districts.length === 0}
                id="model-type-select"
                options={districts}
                PaperComponent={CustomPaper}
                getOptionLabel={(option) => option.name}
                noOptionsText="Sonuç bulunamadı"
                value={selectedDistrictRow}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    maxWidth: "100%"
                  },
                }}
                filterOptions={(x, e) => {
                  if (e.inputValue) {
                    return x.filter((elem) => elem.name.toLocaleLowerCase().includes(e.inputValue.toLocaleLowerCase()));
                  }
                  return x;
                }}
                renderOption={(props, option, { selected }) => (
                  <MenuItem {...props} value={option.code} key={option.name} data-text={option.name}>
                    {option.name}
                  </MenuItem>
                )}
                onChange={(event, value) => {
                  districtSelect(event, value);
                }}
                
                renderInput={(params) => <TextField {...params} label="İlçe" />}
              />
              <Autocomplete
                className={classes.autoComplete}
                openOnFocus
                id="model-type-select"
                options={institutionTypes}
                PaperComponent={CustomPaper}
                getOptionLabel={(option) => option.displayName}
                noOptionsText="Sonuç bulunamadı"
                value={selectedInstitutionType}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    maxWidth: "100%"
                  },
                }}
                filterOptions={(x, e) => {
                  if (e.inputValue) {
                    return x.filter((elem) => elem.displayName.toLocaleLowerCase().includes(e.inputValue.toLocaleLowerCase()));
                  }
                  return x;
                }}
                renderOption={(props, option, { selected }) => (
                  <MenuItem {...props} value={option.enumValue} key={option.displayName} data-text={option.displayName}>
                    {option.displayName}
                  </MenuItem>
                )}
                onChange={(event, value) => {
                  setSelectedInstitutionType(value);
                }}
                
                renderInput={(params) => <TextField {...params} label="Kurum Tipi" />}
              />
              <Button
                variant="contained"
                onClick={searchContractedProviders}
                className={classes.searchButton} 
              >
                Ara
              </Button>
            </div>
            <div className={classes.view}>
              {!modalDataLoading ? (
                <>
                  <div className={classes.viewSelector}>
                    {mapVisible && <p onClick={() => setActiveTab(1)} className={activeTab === 1 && classes.active}>Harita Görünümü</p>}
                    {listVisible && <p onClick={() => setActiveTab(2)} className={activeTab === 2 && classes.active}>Liste Görünümü</p>}
                  </div>
                  {activeTab === 1 ? (
                    <div className={classes.mapView}>
                      {contractedProvidersList && <MapComponent list={contractedProvidersList} />}
                    </div>
                  ) : (
                    <div className={classes.listView}>
                      {(contractedProvidersList && contractedProvidersList.length > 0) ? contractedProvidersList.map((item) => (
                        <ContractedProviderListItem key={item.name} item={item} />
                      )) : (
                        <>
                          <img className={classes.notFoundImage} src={noSearchResult} />
                          <p className={classes.notFoundText}>Seçilen lokasyon için anlaşmalı kurum bulunamadı</p>
                        </>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <LoadingSpinner />
              )}
            </div>
            <div className={classes.closeButtonDesktop}>
              <img onClick={closeHospitalNetwork} src={closeSVG} />
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};




export default ProductCard;
