import React from 'react';
import './insured.scss';
import { TextField } from '@mui/material';
import fullWidthInputSx from '../../../data/fullWidthInputSx';
import Trash from '../../../../shared/assets/img/svg/Trash.svg';

export const Insured = (props) => {
  const {
    key,
    insured,
    hasDelete,
    deleteAndSetInsureds,
  } = props;
	console.log(insured)
  return (
    <div className="insured-container" key={key}>
      <div className="insured-row">
        <div className="insured-column">
          <p>{insured.relationText}</p>
        </div>
        {hasDelete && (
        <div className="insured-column">
          <img src={Trash} alt="file" className="svg-icon" onClick={() => deleteAndSetInsureds(insured.identityNo)} />
        </div>
        )}
      </div>
      <div className="insured-row">
        <div className="insured-column">
					{insured.identityNo}
        </div>
        <div className="insured-column">
					{insured.name}{' '}{insured.lastName}
        </div>
      </div>
    </div>
  );
};

export default (Insured);
