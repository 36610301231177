import React, { useEffect, useState } from 'react';

import '../../../../../shared/assets/styles/components/card-owner-identity.scss';
import styled from '@emotion/styled';
import {
  FormControlLabel, Radio, RadioGroup, Tooltip, tooltipClasses,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import useWindowSize from '../../../../../shared/_hooks/windowResizeHook';
import CenteredModal from '../../../components/widget/CenteredModal';
import infoSVG from '../../../../../shared/assets/img/svg/infoBlue.svg';
import { tcknValidation } from '../../../../../shared/_helpers/validations';
import { KLInput } from '../../../components/form/KLInput';

const CardOwnerIdentity = (props) => {
  const size = useWindowSize();
  const [isOwner, setIsOwner] = useState('1');
  const [hintPopup, setHintPopup] = useState(false);
  const [tckn, setTckn] = useState('');

  const isMobile = size.width <= 880;

  const {
    reset,
    clearErrors,
    formState: { errors, touchedFields },
    control,
  } = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
  });
  /* #endregion */

  const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 13,
      },
    }),
  );

  const StyledParagraph = styled('p')(({ theme, ownerState }) => ({
    color: theme.palette.secondary.main,
  }));

  const hintText = 'SEDDK kararı gereği, poliçe satın almak için ödeme yapılan kredi kartı sahibinin TCKN/Vergi Kimlik No bilgileri sigorta şirketine iletilmelidir.';

  const handleChange = (event) => {
    setIsOwner(event.target.value);
  };

  useEffect(() => {
    const isTcknValid = tcknValidation().validate.validation(tckn) === true;
    if (isOwner === '1') {
      props.setCardOwnerIdentity(true);
    } else if (isTcknValid) {
      props.setCardOwnerIdentity(tckn);
    } else {
      props.setCardOwnerIdentity(false);
    }
  }, [isOwner, tckn]);

  return (
    <>
      <div className="card-owner-container">
        <StyledParagraph style={{
          fontStyle: 'normal', fontWeight: '700', fontSize: '15px', lineHeight: '20.46px',
        }}
        >
          Poliçe sahibi ile kredi kartı sahibi aynı kişi mi?
        </StyledParagraph>
        {' '}
        <LightTooltip
          title={<span dangerouslySetInnerHTML={{ __html: hintText }} />}
        >
          <img
            src={infoSVG}
            alt="Var"
            onClick={() => (isMobile ? setHintPopup(true) : '')}
          />
        </LightTooltip>
      </div>
      <div className="radio-container">
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          onChange={handleChange}
          value={isOwner}
        >
          <FormControlLabel value={1} control={<Radio />} label="Evet" id={1} key={1} />
          <FormControlLabel value={0} control={<Radio />} label="Hayır" id={0} key={0} />
        </RadioGroup>
      </div>
      {isOwner === '0' && (
      <div className="identity-row">
        {' '}
        <form>
          <KLInput
            name="tckn"
            control={control}
            label="Kart Sahibi TCKN/VKN"
            value={tckn}
            error={errors}
            mask="99999999999"
            rules={tcknValidation()}
            sx={{
              '& .MuiOutlinedInput-root': {
                width: '100%',
                '&.Mui-error': {
                  width: '100%',
                },
              },
            }}
            onChanged={(val) => { clearErrors('tckn'); setTckn(val); }}
            touchedFields={touchedFields}
            reset={reset}
          />
        </form>
      </div>
      )}
      {hintPopup !== null && (
      <CenteredModal isOpen={hintPopup} setOpen={setHintPopup}>
        <br />
        <p className="disclaimer">{hintText}</p>
      </CenteredModal>
      )}
    </>
  );
};

export default CardOwnerIdentity;
