import React, { useState, useEffect } from 'react';
import { Grid, Button, Paper } from '@mui/material';
import OtpInput from 'react-otp-input';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import OTPSVG from '../../../../shared/assets/img/svg/otp.svg';
import tickSVG from '../../../../shared/assets/img/svg/tick.svg';
import useWindowSize from '../../../../shared/_hooks/windowResizeHook';
import { phoneValidation } from '../../../../shared/_helpers/validations';
import OTPConfirmation from './OTPConfirmation';
import loadingKlActions from '../../../../motor/actions/loadingActions';
import commonService from '../../../../motor/_services/common.service';
import informationActions from '../../../../motor/actions/informationAction';
import { KLInput } from '../../../../motor/views/components/form/KLInput';

export const OTP = (props) => {
  const size = useWindowSize();
  const isMobile = size.width <= 880;
  const URL_PARAMS = new URLSearchParams(document.location.search);
  const uid = URL_PARAMS.get('jt');
  const [otp, setOtp] = useState('');
  const [isTimeEnd, setIsTimeEnd] = useState(false);
  const [isOtpFail, setIsOtpFail] = useState(false);
  const [isOtpSuccess, setIsOtpSuccess] = useState(false);
  const { quotation } = useSelector((state) => state.shell);
  const [stepName, setStepName] = useState('otp');
  const [resetTimer, setResetTimer] = useState(0);
  const { customer } = useSelector((state) => state.info);
  const dispatch = useDispatch();

  const {
    handleSubmit,
    formState: { errors, isValid, touchedFields },
    control,
    reset,
  } = useForm({
    mode: 'onChange',
  });

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -40%)',
    width: isMobile ? 343 : 468,
    bgcolor: 'background.paper',
    padding: 30,
    textAlign: 'center',
    background: '#FFFFFF',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
  };

  const checkOTP = () => {
    if (Math.ceil(Math.log10(parseInt(otp) + 1)) === 5) {
      const otpObj = {
        PhoneNumber: props.phone !== undefined ? props.phone : customer.telefon,
        OTP: otp,
        UserUId: uid,
				TCKN: props.TCKN !== undefined ? props.TCKN : customer.kimlikNo,
        OTPType: 0,
      };
      dispatch(loadingKlActions.showKlLoadingEmpty());
      commonService.checkOTP(otpObj, (data) => {
        if (data.otpResult) {
          setIsOtpSuccess(true);
          setTimeout(() => {
						props.nextPage();
            dispatch(loadingKlActions.hideKlLoading());
          }, 2000);
        } else {
          setIsOtpFail(true);
					dispatch(loadingKlActions.hideKlLoading());
        }
      });
    }
  };
  const sendSms = () => {
		const {
			HasAgencyHealthDeclarationText = false,
		} = props;
    commonService.sendSMS(
      {
        OTP: null,
        OTPType: 0,
        PhoneNumber: customer.telefon || props.phone,
        TCKN: props.TCKN !== undefined ? props.TCKN : customer.kimlikNo,
        UserUId: uid,
				HasAgencyHealthDeclarationText,
      },
      (resp) => {
        setResetTimer(resetTimer + 1);
        setOtp('');
        setIsOtpFail(false);
        setIsTimeEnd(false);
      },
    );
  };
  const onSubmit = (data) => {
    setStepName('otp');
    customer.telefon = data.phone;
		const {setCustomerObject = null} = props;
		if(setCustomerObject){
			setCustomerObject({
				...customer,
				phoneNumber:data.phone,
			})
		}
		dispatch(informationActions.setCustomerInfo(customer));
    reset();
    sendSms();
  };

  useEffect(() => {
    if (Math.ceil(Math.log10(parseInt(otp) + 1)) === 5) {
      document.querySelectorAll('.MuiPaper-root input')[4].addEventListener('keypress', (e) => {
        if (e.key === 'Enter') {
          checkOTP();
        }
      });
    }
  }, [otp]);
  return (
    <>
      <Paper style={style} square>
        {stepName === 'confirmation' && (
        <OTPConfirmation differntPhoneSubmit={onSubmit} setStepName={setStepName} />
        )}
        {stepName === 'otp' && (
          <Grid xs={12} sm={12} lg={12} xl={12} item>
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                margin: '10px 0 8px 0',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ width: '64px', position: 'relative' }} />
              <img src={OTPSVG} alt="" width={58} height={64} className="svg-icon" />
              <div>
                <CountdownCircleTimer
                  isPlaying
                  size={64}
                  strokeWidth={2}
                  duration={120}
                  onComplete={() => setIsTimeEnd(true)}
                  remainingTime={120}
                  key={resetTimer}
                  trailColor="#AA2323"
                  colors={['#8DC63F', '#8DC63F', '#8DC63F', '#8DC63F']}
                  colorsTime={[7, 5, 2, 0]}
                >
                  {({ remainingTime }) => {
                    const minutes = Math.floor(remainingTime / 60);
                    let seconds = remainingTime % 60;
                    if (seconds === 0) {
                      seconds = '00';
                    } else if (seconds < 10) {
                      seconds = `0${seconds}`;
                    }
                    return `${minutes}:${seconds}`;
                  }}
                </CountdownCircleTimer>
              </div>
            </div>
            <h3 style={{ margin: '24px 0' }}>Mobil Onay</h3>
            <div>
              <p className="otp" style={{ padding: '0 20px' }}>
                Lütfen 0
                {props.phone !== undefined ? props.phone : customer.telefon}
                {' '}
                nolu telefona gönderilen doğrulama kodunu girin
              </p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <OtpInput
                value={otp}
                onChange={(otp) => {
                  setOtp(otp);
                }}
                numInputs={5}
                inputStyle={{
                  fontSize: '24px',
                  width: '36px',
                  height: '36px',
                  margin: '4px',
                  borderTop: '0px',
                  borderLeft: '0px',
                  borderRight: '0px',
                  outline: 'none',
                  borderColor: '#000a46',
                }}
                containerStyle={{
                  margin: '10px auto',
                  padding: '10px',
                }}
                isInputNum
              />
              {' '}
            </div>
            {isOtpFail && !isOtpSuccess ? (
              <p
                className="disclaimer"
                style={{
                  color: '#c73f3f',
                  marginBottom: '16px',
                  marginTop: '15px',
                }}
              >
                Girdiğiniz kod hatalıdır, lütfen kontrol edin.
              </p>
            ) : (
              ''
            )}
            {isOtpSuccess ? (
              <p
                className="disclaimer"
                style={{
                  color: '#8DC63F',
                  marginBottom: '16px',
                  marginTop: '15px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <img src={tickSVG} />
                Kod onaylandı
              </p>
            ) : (
              ''
            )}
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Button
                variant="contained"
                disabled={isTimeEnd || isOtpSuccess}
                style={{
                  width: !isMobile ? '254px' : '100%',
                  height: '45px',
                  margin: '8px 7px',
                  boxSizing: 'border-box',
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
                  borderRadius: '5px',
                  textTransform: 'none',
                }}
                onClick={checkOTP}
                type="submit"
              >
                Doğrula
              </Button>
              {isTimeEnd ? (
                <p
                  className="disclaimer"
                  style={{
                    color: '#c73f3f',
                    marginBottom: '16px',
                    marginTop: '15px',
                  }}
                >
                  Süre doldu, lütfen tekrar SMS gönderin.
                </p>
              ) : (
                ''
              )}
              <Button
                variant="outlined"
                disabled={!isTimeEnd}
                type="submit"
                style={{
                  width: !isMobile ? '254px' : '100%',
                  height: '45px',
                  margin: '8px 7px',
                  boxSizing: 'border-box',
                  color: '#53585E',
                  borderRadius: '5px',
                  borderColor: '#53585E',
                  textTransform: 'none',
                }}
                onClick={sendSms}
              >
                Tekrar SMS Gönder
              </Button>

              <Button onClick={() => setStepName('newPhone')} style={{ textTransform: 'none' }}>
                Farklı bir telefon numarası gir
              </Button>
            </div>
          </Grid>
        )}
        {stepName === 'newPhone' && (
          <Grid xs={12} sm={12} lg={12} xl={12} item>
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                margin: '10px 0 8px 0',
                justifyContent: 'center',
              }}
            >
              <img src={OTPSVG} alt="" width={58} height={64} className="svg-icon" />
            </div>
            <h3 style={{ margin: '24px 0' }}>Mobil Onay</h3>
            <div>
              <p className="otp">Lütfen doğrulamak istediğiniz telefon numarasını girin.</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <form>
                <KLInput
                  name="phone"
                  mask="(599) 999 99 99"
                  label="Telefon Numaranızı Giriniz"
                  control={control}
                  rules={phoneValidation()}
                  error={errors}
                  touchedFields={touchedFields}
                />
              </form>
            </div>
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Button
                variant="contained"
                disabled={!isValid}
                style={{
                  width: !isMobile ? '254px' : '100%',
                  height: '45px',
                  margin: '8px 7px',
                  boxSizing: 'border-box',
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
                  borderRadius: '5px',
                  textTransform: 'none',
                }}
                onClick={handleSubmit(onSubmit)}
              >
                SMS Gönder
              </Button>
            </div>
          </Grid>
        )}
      </Paper>
    </>
  );
};

export default withRouter(OTP);
