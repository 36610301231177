import React, { useEffect, useRef, useState } from 'react';
import {
  GoogleMap, LoadScript, Marker, InfoWindow,
} from '@react-google-maps/api';

import classes from './map.module.scss';

const MapComponent = ({ list }) => {
  const [markers, setMarkers] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const mapRef = useRef(null);

  useEffect(() => {
    const formattedMarkers = list.map((location, index) => ({
      lat: location.latitude,
      lng: location.longtitude,
      title: location.name,
      otherFields: { ...location },
    }));
    setMarkers(formattedMarkers);
  }, [list]);

  const filteredMarkers = markers.filter((marker) => marker.lat !== null && marker.lng !== null);

  let center = filteredMarkers.reduce(
    (acc, curr) => ({
      lat: acc.lat + curr.lat / filteredMarkers.length,
      lng: acc.lng + curr.lng / filteredMarkers.length,
    }),
    { lat: 0, lng: 0 },
  );

  if (center.lat === 0 && center.lng === 0) {
    center = { lat: 39.9334, lng: 32.8597 }; //* Geographic center of Turkey
  }

  return (
    <>
      <LoadScript
        googleMapsApiKey="AIzaSyAmEWCEQSTTVT0OvFEviq9XylBnZkoNb0Q"
      >
        <GoogleMap
          mapContainerClassName={classes.container}
          zoom={8}
          center={center}
          onLoad={(map) => {
            mapRef.current = map;
          }}
        >
          {(markers && markers.length > 0) && markers
            .filter((marker) => marker.lat !== null && marker.lng !== null) // Filter out markers with null lat/lng
            .map((marker, index) => (
              selectedLocation ? (
                <>
                  <Marker
                    key={index}
                    position={marker}
                    title={marker.title}
                    onClick={() => {
                      setSelectedLocation(marker);
                    }}
                  />
                  <InfoWindow
                    onCloseClick={() => {
                      setSelectedLocation(null);
                    }}
                    position={{
                      lat: selectedLocation.lat,
                      lng: selectedLocation.lng,
                    }}
                    map={mapRef.current}
                  >
                    <div>
                      <h3>{selectedLocation.title}</h3>
                      <p>
                        Tel:
                        {selectedLocation.otherFields.phone}
                      </p>
                      <p>
                        Eposta:
                        {selectedLocation.otherFields.mail}
                      </p>
                      <p>
                        Adres:
                        {selectedLocation.otherFields.address}
                      </p>
                    </div>
                  </InfoWindow>
                </>
              ) : (
                <Marker
                  key={index}
                  position={(marker.lat && marker.lng) && marker}
                  title={marker.title}
                  onClick={() => {
                    setSelectedLocation(marker);
                  }}
                />
              )
            ))}
        </GoogleMap>
      </LoadScript>
    </>
  );
};

export default MapComponent;
