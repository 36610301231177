import axiosInstance from '../../shared/_helpers/axiosHelper';
import { API } from '../../shared/_helpers/env';

async function issue(data, callback) {
  try {
    await axiosInstance
      .post(`${API}/api/issue/side-products/issue`, { PurchaseRequestModel: data })
      .then((response) => {
        try {
          callback(response.data, false);
        } catch (error) {
          console.log(error);
        }
      });
  } catch (error) {
    callback(error.response.data, true);
  }
}

const issueService = {
  issue,
};
export default issueService;
