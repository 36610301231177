const requiredArea = () => ({
  required: 'Bu alanın doldurulması zorunludur.',
  validate: {
    validation: (value) => (value && value != false ? true : 'Bu alanın doldurulması zorunludur'),
  },
});

const minLength = (length = 1) => ({
  required: 'Bu alanın doldurulması zorunludur.',
  validate: {
    validation: (value) => (value && value != false && value.length >= length ? true : ''),
  },
});

const maxLength = (length = 1) => ({
  required: 'Bu alanın doldurulması zorunludur.',
  validate: {
    validation: (value) => (value && value != false && value.length <= length ? true : ''),
  },
});

const exactLength = (length = 1) => ({
  required: 'Bu alanın doldurulması zorunludur.',
  validate: {
    validation: (value) => (value && value != false && value.length === length ? true : ''),
  },
});

const betweenLength = (min = 1, max = 1) => ({
  required: 'Bu alanın doldurulması zorunludur.',
  validate: {
    validation: (value) => (value && (value != false || value === '000') && value.length >= min && value.length <= max ? true : ''),
  },
});

const atLeastTwoWord = (min = 1, max = 1) => ({
  required: 'Bu alanın doldurulması zorunludur.',
  validate: {
    validation: (value) => {
      if (value) {
        const twoWordRegex = /^(.{2,}\s+.{2,})$/;
        if (value.match(twoWordRegex)) {
          return true;
        }
        return false;
      }
    },
  },
});

const userAgreement = () => ({
  required: 'Devam etmek için kullanıcı sözleşmesini okuyup kabul etmeniz gerekiyor.',
  validate: {
    validation: (value) => (value && value == '' ? 'Devam etmek için kullanıcı sözleşmesini okuyup kabul etmeniz gerekiyor.' : true),
  },
});

const plateValidation = () => ({
  required: 'Lütfen plakanızı giriniz.',
  validate: {
    validation: (value) => {
      if (value) {
        let regex;
        let v;
        v = value.replace(/\s+/g, '').toUpperCase();
        regex = /^(0[1-9]|[1-7][0-9]|8[01])(([A-Z])(\d{4,5})|([A-Z]{2})(\d{3,4})|([A-Z]{3})(\d{2,3}))$/;
        if (v.match(regex)) {
          return true;
        }
        if (v.length === 9) {
          v = v.slice(0, -1);
          return true;
        }
        return 'Lütfen geçerli bir plaka giriniz.';
      }
    },
  },
});

const onlyTcknValidation = () => ({
  required: 'Lütfen TC kimlik no giriniz.',
  validate: {
    validation: (value) => {
      if (value) {
        const tcknRegex = /^[1-9]{1}[0-9]{9}[02468]{1}$/;
        if (value.match(tcknRegex)) {
          return true;
        }
        return 'TCKN geçersiz.';
      }
    },
  },
});

const onlyVknValidation = () => ({
  required: 'Lütfen vergi no giriniz.',
  validate: {
    validation: (value) => {
      if (value) {
        const vergiNoRegex = /^[0-9]{10}$/;
        if (value.match(vergiNoRegex)) {
          return true;
        }
        return 'Vergi kimlik no geçersiz.';
      }
    },
  },
});

const tcknValidation = () => ({
  required: 'Lütfen TC kimlik veya vergi no giriniz.',
  validate: {
    validation: (value) => {
      if (value) {
        const tcknRegex = /^[1-9]{1}[0-9]{9}[02468]{1}$/;
        const vergiNoRegex = /^[0-9]{10}$/;
        if (value.match(tcknRegex) || value.match(vergiNoRegex)) {
          return true;
        }
        return 'TCKN veya vergi no geçersiz.';
      }
    },
  },
});

const birthDateValidation = () => ({
  required: 'Lütfen doğum tarihinizi giriniz.',
  validate: {
    validation: (value) => {
      if (value) {
        const birthdateregex = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]|(?:Jan|Mar|May|Jul|Aug|Oct|Dec)))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)(?:0?2|(?:Feb))\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))\4(?:(?:1[9]|[2-9]\d)?\d{2})$/;

				if (!value.match(birthdateregex)) {
          return 'Lütfen geçerli bir doğum tarihi giriniz.';
        }

        const [day, month, year] = value.split(/\/|-|\./).map(Number);
        const selectedDate = new Date(year, month - 1, day);
        const today = new Date();

        if (selectedDate > today) {
          return 'Lütfen geçerli bir doğum tarihi giriniz.';
        }

        return true;
      }
    },
  },
});

const phoneValidation = () => ({
  required: 'Lütfen cep telefonu numaranızı giriniz.',
  validate: {
    validation: (value) => {
      if (value) {
        value = value.replace(/\(|\)/g, '').replace(/\s/g, '');
        const phoneRegex = /^((5)[0-9]{2})\s?([0-9]{3})\s?([0-9]{2})\s?([0-9]{2})$/;
        if (value.match(phoneRegex)) {
          return true;
        }
        return 'Lütfen geçerli bir telefon numarası giriniz.';
      }
    },
  },
});

const emailValidation = () => ({
  required: 'Bu alanın doldurulması zorunludur.',
  validate: {
    validation: (value) => {
      if (value) {
        // const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const emailRegex = /^(?=.{6,265}$)(-|_)*[^\W_](?:[+\w.-]*[^\W_])*(-|_|\.)*@(?:\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.|(?:[\w-]{2,}\.)+)(?:[a-zA-Z]{2,13}|[0-9]{1,2})\]?$/;
        if (value.match(emailRegex)) {
          return true;
        }
        return 'Lütfen geçerli bir e-posta adresi giriniz.';
      }
    },
  },
});

const wordValidation = (min = 1, max = 1) => ({
  required: 'Bu alanın doldurulması zorunludur.',
  validate: {
    validation: (value) => {
      if (value) {
        const twoWordRegex = /^(.[\s*İĞÜŞÇÖığüşçöA-Za-z]{1,})$/;
        if (value.match(twoWordRegex)) {
          return true;
        }
        return false;
      }
    },
  },
});

const companyNameValidation = (min = 1, max = 1) => ({
  required: 'Bu alanın doldurulması zorunludur.',
  validate: {
    validation: (value) => {
      if (value) {
        const twoWordRegex = /^(.[\s*.&'-^+%/()=_?#,\\"$İĞÜŞÇÖığüşçöA-Za-z]{1,})$/;
        if (value.match(twoWordRegex)) {
          return true;
        }
        return false;
      }
    },
  },
});

const heightValidation = () => ({
  required: 'Bu alanın doldurulması zorunludur.',
  validate: {
    validation: (value) => {
      if (value) {
        const heightRegex = /^(10|[1-9][0-9]|[12][0-9]{2}|299)$/;
        if (value.toString().match(heightRegex)) {
          return true;
        }
        return false;
      }
    },
  },
});

const weightValidation = () => ({
  required: 'Bu alanın doldurulması zorunludur.',
  validate: {
    validation: (value) => {
      if (value) {
        const weightRegex = /^(1|[1-9][0-9]|[12][0-9]{2}|299)$/;
        if (value.toString().match(weightRegex)) {
          return true;
        }
        return false;
      }
    },
  },
});

const yearValidation = () => ({
  required: 'Bu alanın doldurulması zorunludur.',
  validate: {
    validation: (value) => {
      if (value) {
        const weightRegex = /^(?:19|20)\d{2}$/;
        if (value.toString().match(weightRegex)) {
          return true;
        }
        return 'Lütfen geçerli bir yıl giriniz.';
      }
    },
  },
});

const dateValidation = () => ({
  required: 'Lütfen doğum tarihinizi giriniz.',
  validate: {
    validation: (value) => {
      if (value) {
        const birthdateregex = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]|(?:Jan|Mar|May|Jul|Aug|Oct|Dec)))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)(?:0?2|(?:Feb))\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))\4(?:(?:1[9]|[2-9]\d)?\d{2})$/;
        if (value.match(birthdateregex)) {
          return true;
        }
        return 'Lütfen geçerli bir tarih giriniz.';
      }
    },
  },
});

const policyEndDateValidation = () => ({
  required: 'Lütfen poliçe bitiş tarihinizi giriniz.',
  validate: {
    validation: (value) => {
      if (value) {
        const dateregex = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]|(?:Jan|Mar|May|Jul|Aug|Oct|Dec)))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)(?:0?2|(?:Feb))\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))\4(?:(?:1[9]|[2-9]\d)?\d{2})$/;

				const [day, month, year] = value ? value.split('/') : [null, null, null];
				if (day === null || month === null || year === null) {
						// Invalid date format
						return false;
				}
				const inputDate = new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
				const currentDate = new Date();
				const oneYearAgo = new Date().setFullYear(currentDate.getFullYear() - 1);
				
				const withinOneYear = inputDate >= oneYearAgo;

        if (value.match(dateregex) && withinOneYear) {
          return true;
        }
        return 'Lütfen geçerli bir tarih giriniz.';
      }
    },
  },
});

export {
  userAgreement, plateValidation, tcknValidation, onlyTcknValidation, onlyVknValidation, phoneValidation, emailValidation, requiredArea, birthDateValidation, minLength, maxLength, exactLength, betweenLength, atLeastTwoWord, wordValidation, companyNameValidation, heightValidation, weightValidation, yearValidation, dateValidation, policyEndDateValidation,
};
