/*
 * Input Helper
 */
import React, { useRef } from 'react';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import InputMask from 'react-input-mask';
import Info from '../info/Info';

function KLInput(props) {
  const {
    defaultValue,
    name,
    id,
    control,
    error,
    touchedFields,
    reset,
    rules,
    type,
    inputProps,
    InputProps,
    disabled,
    label,
    mask,
    hasCustomMaskFunction,
    styles,
    placeholder,
    InputLabelProps,
    onChanged,
    onFocused,
    onBlured,
    sx = {},
    hidden,
    accept,
    customRef,
    info,
    autoComplete = 'on',
  } = props;

  const textRef = useRef();
  const resetForm = reset || (() => {});

  sx['& .MuiOutlinedInput-input:-webkit-autofill'] = {
    padding: '10.5px 14px',
  };

  const commonProps = {
    disabled,
    styles,
    sx,
    name,
    id,
    type,
    placeholder,
    inputProps,
    InputProps,
    autoComplete,
    InputLabelProps,
    onFocus: onFocused,
    label,
    hidden,
  };

  const controlledInputProps = {
    name,
    control,
    defaultValue,
    rules,
    style: styles,
  };

  const onBlurCombined = (onBlur) => {
    onBlur();
    if (onBlured !== undefined) {
      onBlured();
    }
  };

  const onChangeCombined = (onChange, value) => {
    onChange(value);
    if (onChanged !== undefined) {
      if (type !== 'file') {
        onChanged(value.target.value);
      } else {
        onChanged(value.target.files);
      }
    }
  };

  return (
    <>
      {!mask ? (
        <Controller
          {...controlledInputProps}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextField
              {...commonProps}
              inputRef={customRef}
              onBlur={() => { onBlurCombined(onBlur); }}
              onChange={(val) => { onChangeCombined(onChange, val); }}
              value={value}
              multiple="multiple"
              error={error[name] && touchedFields[name]}
              helperText={error[name] && touchedFields[name] && error[name].message}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
              accept={accept}
            />
          )}
        />
      ) : (
        !hasCustomMaskFunction ? (
          <Controller
            {...controlledInputProps}
            render={({
              field: {
                onChange, onBlur, value, ref,
              },
            }) => (
              <InputMask
                {...commonProps}
                mask={mask}
                onBlur={() => { onBlurCombined(onBlur); }}
                onChange={(val) => { onChangeCombined(onChange, val); }}
                value={value}
                error={error[name] && touchedFields[name]}
                helperText={error[name] && touchedFields[name] && error[name].message}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                  }
                }}
                maskChar=""
                formatChars={{
                  9: '[0-9]',
                  5: '[5]',
                  A: '[A-Z]',
                  a: '[a-z]',
                  x: '[A-Za-z]',
                  y: '[İĞÜŞÇÖığüşçöA-Za-z]',
                  '*': '[A-Za-z0-9]',
                }}
              >
                {(maskedProps) => <TextField disabled={disabled} inputRef={textRef} {...maskedProps} />}
              </InputMask>
            )}
          />
        ) : (
          control ? (
            <Controller
              {...controlledInputProps}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  {...commonProps}
                  onBlur={() => { onBlurCombined(onBlur); }}
                  onChange={(val) => { onChangeCombined(onChange, val); }}
                  value={mask(value)}
                  error={error[name]}
                  helperText={error[name] && error[name].message}
                />
              )}
            />
          )
            : ( //* Used as disabled inputs, not included in form validation */
              <TextField
                {...commonProps}
              />
            )
        )
      )}
      {info && <Info>{info}</Info>}
    </>
  );
}

export { KLInput };
