import informationActions from '../actions/informationAction';
import auxlessObject from '../data/auxlessObject';

const checkBreakout = (dispatch, vehicleResponse) => {
  const breakoutObjectKeys = Object.keys(auxlessObject);
  const breakOutFields = Object.entries(vehicleResponse).map(([key, value]) => {
    if (value === null && breakoutObjectKeys.includes(key)) {
      return key;
    }
  }).filter((field) => field);

  if (breakOutFields.includes('ModelKod')) {
    breakOutFields.push('ModelRadio');
    breakOutFields.push('ModelCascoCode');
  }

  if (vehicleResponse.ProcessStep === 30 && !breakOutFields.includes('VehicleType')) {
    breakOutFields.push('VehicleType');
  }

  if (breakOutFields.length > 0) {
    dispatch(informationActions.setBreakOutFields(breakOutFields));
    return true;
  }
  return false;
};

export default checkBreakout;
