import React, { useEffect, useState } from 'react';
import {
  Autocomplete, CircularProgress, MenuItem, Paper, TextField,
} from '@mui/material';

export const KLAutoComplete = (props) => {
  const {
    value,
    onChange,
    options,
    getOptions,
    label,
    sx,
    PaperComponent = null,
    renderInput = null,
    renderParams: {
      name = 'name',
      code = 'code',
    },
    ...otherProps
  } = props;
  const [open, setOpen] = useState(false);
  const loading = open && options.length === 0;

  const CustomPaper = (paperProps) => (
    <Paper
      style={{
        fontSize: '13px',
        minWidth: '100%',
      }}
      elevation={8}
      {...paperProps}
    />
  );

  useEffect(() => {
    if (open && options.length === 0 && getOptions) {
      getOptions();
    }
  }, [open]);

  return (
    <Autocomplete
      openOnFocus
      id="model-type-select"
      options={options}
      PaperComponent={PaperComponent || CustomPaper}
      getOptionLabel={(option) => option[name]}
      noOptionsText="Sonuç bulunamadı"
      value={value}
      sx={sx}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      filterOptions={(x, e) => {
        if (e.inputValue) {
          return x.filter(
            (elem) => elem[name].toLocaleLowerCase('tr').includes(e.inputValue.toLocaleLowerCase('tr')),
          );
        }
        return x;
      }}
      renderOption={(prop, option) => (
        <MenuItem {...prop} value={option[code]} key={option[code]} data-text={option[name]} name={option[name]}>
          {option[name]}
        </MenuItem>
      )}
      onChange={(_, row) => {
        onChange(row);
      }}
      renderInput={
				renderInput || ((params) => (
  <TextField
    {...params}
    label={label}
    InputProps={{
      ...params.InputProps,
      endAdornment: (
        <>
          {loading
            ? (
              <CircularProgress
                color="inherit"
                size={20}
                sx={{
                  position: 'absolute',
                  right: '40px',
                  top: '13px',
                }}
              />
            ) : null }

          {params.InputProps.endAdornment}
        </>
      ),
    }}
  />
				))
}
      {...otherProps}
    />
  );
};

export default KLAutoComplete;
