import React from 'react';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import createInsuredsForm from '../../../../data/createForm/createInsuredsForm';
import FormBuilder from '../../../../../shared/formBuilder/FormBuilder';
import useWindowSize from '../../../../../shared/_hooks/windowResizeHook';

export const InsuredsForm = (props) => {
	const windowSize = useWindowSize();
	const isMobile = windowSize.width <= 880;

  const {
    formHookObject,
    submitForm,
    advanceToFamily,
    deleteAndSetInsureds,
  } = props;


  const infoFormObject = createInsuredsForm({
    formHookObject, submitForm, advanceToFamily, deleteAndSetInsureds, isMobile,
  });

  return (
    <>
      <form>
        <FormBuilder formBuilderObject={infoFormObject} submitForm={submitForm} />
      </form>
    </>
  );
};

export default withRouter(InsuredsForm);
