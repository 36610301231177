import { API } from '../../shared/_helpers/env';
import { interceptors } from '../../shared/_helpers/interceptors';
import axiosInstance from '../../shared/_helpers/axiosHelper';

async function getFunnelInfo(jt, campaignSource = null, funnelId, callback) {
  interceptors.setXConsumer();
  await axiosInstance.get(`${API}/api/shell/funnel?funnelId=${funnelId}&jt=${jt}${campaignSource ? `&campaignSource=${campaignSource}` : ''}`).then((response) => {
    try {
      callback(response.data.Result);
    } catch (error) {
      console.log(error);
    }
  });
}

async function resolveUrl(callback) {
  const encodedUrl = document.location.href.replaceAll('&', '%26');
  await axiosInstance.get(`${API}/api/shell/resolve-consumer-url?Url=${encodedUrl}`).then((response) => {
    try {
      callback(response.data.Result);
    } catch (error) {
      console.log(error);
    }
  });
}

async function funnelPost(data, callback) {
  interceptors.setXConsumer();
  const postData = data;
  postData.channelId = 1;
  postData.funnelId = 1;
  await axiosInstance.post(`${API}/api/shell/funnel?funnelId=1`, postData).then((response) => {
    try {
      callback(response.data.Result);
    } catch (error) {
      console.log(error);
    }
  });
}

const shellService = {
  getFunnelInfo,
  resolveUrl,
  funnelPost,
};
export default shellService;
