/* eslint-disable react/jsx-indent */
/* eslint-disable no-tabs */

import React from 'react';

import '../../../shared/assets//styles/components/kps-warning-card.scss';
import '../../../shared/assets//styles/radio-button.scss';
import { useSelector } from 'react-redux';
import searchingForAnswer from '../../../shared/assets/img/svg/searchingForAnswer.svg';
import useWindowSize from '../../../shared/_hooks/windowResizeHook';

export const KPSWarningCard = ({
  startLoading, isProgressBarActive,
}) => {
  const screenSize = useWindowSize();
  const isMobile = screenSize.width <= 880;
  const { isAgency } = useSelector((state) => state.shell);
  const show = !isProgressBarActive && !startLoading;/*  && !isMobile */
  return (
		<>
			{show && (
				<div className="kps-warning-container">
					<div className="kps-warning-card">
						<img className="warningImg" src={searchingForAnswer} width={isMobile ? 60 : 70} height={isMobile ? 60 : 70} />
						<div>
							<p className="text">
								<b>Ad, soyad</b>
								{' '}
								ve
								{' '}
								<b>doğum tarihi</b>
								{' '}
								bilgilerinizde bir hata var. Bu hatayı düzelterek daha fazla sigorta şirketinden teklif alabilirsiniz.
							</p>
							<p className="text">
								Filtre menüsünün en altından bilgilerinizi kontrol edip, değiştirebilirsiniz.
							</p>
						</div>
					</div>
				</div>
			)}
		</>
  );
};

export default KPSWarningCard;
