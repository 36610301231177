import React, {
  lazy, Suspense, useEffect, useState,
} from 'react';
import {
  Box, Button, CircularProgress, Modal,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { withRouter } from 'react-router-dom';
import SEQUENCE from '../../constants/sequence';
import Steps from '../components/Steps';
import stepActions from '../../actions/stepAction';
import useWindowSize from '../../../shared/_hooks/windowResizeHook';
import { MAIN_URL } from '../../../shared/_helpers/env';
import { stepDecider } from '../../../shared/_helpers/stepDecider';
import shellActions from '../../actions/shellAction';
import headerActions from '../../actions/headerAction';

export const BaseStepContainer = (props) => {
  const { step } = useSelector((state) => state.step);
  const { isRenewal, isBack } = useSelector((state) => state.shell);
  const dispatch = useDispatch();
  // const [isBack, setIsBack] = useState(false);
  const size = useWindowSize();
  const isMobile = size.width <= 880;
  const optionalSteps = ['CarInfo', 'BirthdateInfo', 'CityDistrict'];

  const isFirstVisit = JSON.parse(localStorage.getItem('isFirstVisit'));

  useEffect(() => {
    const path = window.location.pathname.split('/').pop();
    const shouldRedirect = isFirstVisit && path !== 'LicenseInfo';
    if (shouldRedirect) {
      props.history.push({
        pathname: '/quotation',
        search: document.location.search,
      });
    }
		dispatch(shellActions.setThemeByPage(false));
		dispatch(headerActions.setUseHeader(true));
  }, []);

  const decrementStep = () => {
    const path = document.location.pathname.split('/').pop();
    const stepToGo = optionalSteps.includes(path) ? step : step - 1;
    if (step === 1) {
      document.location.href = MAIN_URL;
    } else if (isBack) {
      stepDecider(isRenewal, step, props.history);
      dispatch(shellActions.setIsBack(false));
    } else {
      dispatch(stepActions.decrementStep());
      stepDecider(isRenewal, stepToGo, props.history);
    }
  };

  return (
    <div className="steps-page">
      {isMobile && <Steps goBack={decrementStep} />}
      <Box className="steps-container">
        <Suspense
          fallback={(
            <div className="inner-loading-container">
              <CircularProgress size={20} />
            </div>
            )}
        >
          {props.children}
          {!isMobile ? (
            <Button
              style={{
                color: '#53585E',
                textTransform: 'none',
                fontSize: '17px',
                fontWeight: 600,
              }}
              onClick={decrementStep}
            >
              <ArrowBackIosNewIcon />
              Geri
            </Button>
          ) : (
            ''
          )}
        </Suspense>
      </Box>
    </div>
  );
};

export default withRouter(BaseStepContainer);
