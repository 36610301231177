export const historyPushWithSearch = (funnelUrl, history, newSearchParams = null) => {
  const params = new URLSearchParams(window.location.search);
  if (newSearchParams) {
    Object.entries(newSearchParams).map(([key, value]) => {
      if (value) {
        params.set(key, value);
      }
    });
  }

  const searchParams = params.toString();
  history.push({
    pathname: funnelUrl,
    search: searchParams,
  });
};
