export const ongoingInsurance = [
  {
    Text: 'Evet, var',
    Value: true,
  },
  {
    Text: 'Hayır, yok',
    Value: false,
  },
];

export const ongoingInsuranceEnum = {
  Evet: true,
  Hayır: false,
};
