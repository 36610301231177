import React, { useState, useEffect } from 'react';
import {
  Grid, Button, Select, MenuItem, InputLabel, FormControl, Autocomplete, TextField, Paper,
} from '@mui/material';
import { useForm } from 'react-hook-form';

import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import InfoSVG from '../../../../../shared/assets/img/svg/info.svg';
import useWindowSize from '../../../../../shared/_hooks/windowResizeHook';
import { KLInput } from '../../../components/form/KLInput';
import commonService from '../../../../_services/common.service';
import { birthDateValidation } from '../../../../../shared/_helpers/validations';
import { checkAPSAndGoSummary } from '../../../../../shared/_helpers/redirect';
import toastrActions from '../../../../actions/toastrAction';

import customerService from '../../../../_services/customer.service';
import loadingKlActions from '../../../../actions/loadingActions';
import useUserState from '../../../../../shared/_hooks/userStateHook';
import { UserStates } from '../../../../data/userStates';
import informationActions from '../../../../actions/informationAction';
import { removeCustomEventsFromDataLayer } from '../../../../../shared/_helpers/helperFunctions';

export const BirthdateInfo = (props) => {
  const size = useWindowSize();
  const isMobile = size.width <= 880;
  const URL_PARAMS = new URLSearchParams(document.location.search);
  const uid = URL_PARAMS.get('jt');
  const [occupations, setOccupations] = useState([]);
  const [selectedOccupation, setSelectedOccupation] = useState(null);
  const [occupation, setOccupation] = useState('');
  const [occupationFocus, setOccupationFocus] = useState();
  const { customer, hasBirthDateInfo, quoteId, birthDateValue, isKPSActive } = useSelector((state) => state.info);
  const { isRenewal, quotation, isAgency } = useSelector((state) => state.shell);
	const { quotationUrl } = useSelector((state) => state.step);
  const dispatch = useDispatch();

  const { setUserState } = useUserState();

  const {
    handleSubmit,
    formState: { errors, isValid, touchedFields },
    control,
  } = useForm({
    mode: 'onChange',
  });

  const occuptaionSelect = (event, value, brandRow) => {
    setSelectedOccupation(brandRow);
    setOccupation(value);
  };

  useEffect(() => {
    removeCustomEventsFromDataLayer(window.dataLayer);
    window.dataLayer.push({
      event: 'additional_step',
      'funnel_variables': {
        jt: uid
      }
    });
    dispatch(loadingKlActions.hideKlLoading());
    const userSateData = {
      UserUID: uid,
      UserStateDefinition: UserStates.MeslekSayfasiniGordu,
    };
    setUserState(userSateData);
    commonService.getOccupations((data) => {
      setOccupations(data.occupationViewModels);
    });
  }, []);

  const onSubmit = (data) => {
    window.dataLayer.push({'funnel_variables': null});
    window.dataLayer.push({
      event: 'additional_info',
      'funnel_variables':{
        occupation: selectedOccupation ? selectedOccupation.text : null,
        jt: uid
      }
    });
		dispatch(informationActions.setBirthDateValue(data.birthdate));
    const [day, month, year] = data.birthdate !== undefined ? data.birthdate.split('/') : [null, null, null];
    dispatch(toastrActions.hideToastr());
    dispatch(loadingKlActions.showKlLoadingEmpty());

    customerService.verify(
      {
        MasterOccupationId: occupation,
        QuoteGUID: quoteId,
        ReferenceCustomerUID: uid,
        UserGUID: uid,
        BirthDate: year !== null ? [year, month, day].join('-') : customer.birthDate || null,
      },
      (resp, code, msg) => {
        dispatch(loadingKlActions.hideKlLoading());
				if (quotation.customer.tcKimlikVergiNo.length === 10) {
					code = '102';
				}
        checkAPSAndGoSummary(uid, quoteId, code, props, msg, dispatch,quotationUrl);
      },
    ).catch((err) => {
      dispatch(loadingKlActions.hideKlLoading());
    });
  };

  const CustomPaper = (props) => (
    <Paper
      style={{
        fontSize: '13px',
        minWidth: isMobile ? '100%' : '368px',
      }}
      elevation={8}
      {...props}
    />
  );

  const handleMeslekLabel = () => {
		if(!isAgency){
			if (!occupationFocus && !occupation) {
				return 'Meslek (zorunlu değil)';
			}
			return 'Meslek'
		}else{
			if (!occupationFocus && !occupation) {
				return 'Müşterinizin mesleğini yazın';
			}
			return 'Müşterinizin mesleğini yazın (zorunlu değil)';
		}
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12} sm={12} lg={6} xl={6} item>
          {!hasBirthDateInfo && (
            <>
              <div>
                <h1>Teklifleriniz Neredeyse Hazır!</h1>
              </div>
              <div style={{ alignItems: 'center', display: 'flex', margin: '10px 0 8px 0' }}>
                <p className="text">
                  Lütfen doğum tarihinizi girin ve mesleğinizi seçin.
                  <br />
                  Mesleğinize özel indirimli teklifler
                  alabilirsiniz!
                </p>
              </div>
            </>
          )}
          {hasBirthDateInfo && (
            <>
              <div>
                <h1>Mesleğinizi seçin, %40’a varan indirimler alın!</h1>
              </div>
              <div style={{ alignItems: 'center', display: 'flex', margin: '10px 0 8px 0' }}>
                <p className="text">İndirimli kasko teklifleri almak için mesleğinizi seçin.</p>
              </div>
            </>
          )}
          <form>
            {!hasBirthDateInfo ? (
              <div>
                <KLInput
                  name="birthdate"
                  mask="99/99/9999"
                  label="Doğum Tarihi"
                  placeholder="GG/AA/YYYY"
                  rules={birthDateValidation()}
                  control={control}
                  error={errors}
                  touchedFields={touchedFields}
									defaultValue={birthDateValue}
                />
              </div>
            ) : (
              ''
            )}
            <div>
              <FormControl>
                <Autocomplete
                  openOnFocus
                  id="model-type-select"
                  options={occupations}
                  PaperComponent={CustomPaper}
                  getOptionLabel={(option) => option.text}
                  noOptionsText="Sonuç bulunamadı"
                  value={selectedOccupation}
                  onFocus={() => setOccupationFocus(true)}
                  onBlur={() => setOccupationFocus(false)}
                  filterOptions={(x, e) => {
                    if (e.inputValue) {
                      return x.filter(
                        (elem) => elem?.value.toString().includes(e.inputValue) || elem?.text.toLocaleLowerCase().includes(e.inputValue.toLocaleLowerCase()),
                      );
                    }
                    return x;
                  }}
                  renderOption={(props, option, { selected }) => (
                    <MenuItem {...props} value={option.value} key={option.text} data-text={option.text}>
                      {option.text}
                    </MenuItem>
                  )}
                  onChange={(event, value) => {
                    if (value) {
                      occuptaionSelect(event, value.value, value);
                    } else {
                      occuptaionSelect(event, '', null);
                    }
                  }}
                  sx={{ width: 'auto' }}
                  renderInput={(params) => <TextField {...params} label={handleMeslekLabel()} />}
                />
              </FormControl>
            </div>
          </form>
          <div>
            {!isMobile ? (
              <Button
                variant="contained"
                disabled={!isValid}
                style={{
                  width: !isMobile ? '254px' : '100%',
                  height: '45px',
                  margin: '24px 7px',
                  boxSizing: 'border-box',
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
                  borderRadius: '5px',
                  textTransform: 'none',
                }}
                onClick={handleSubmit(onSubmit)}
              >
                Tekliflerimi Göster
              </Button>
            ) : (
              ''
            )}
          </div>
        </Grid>
        {!hasBirthDateInfo && (
        <Grid xs={12} sm={12} lg={6} xl={6} item>
          <div style={{ marginTop: isMobile ? '' : '55px', justifyContent: 'center' }}>
            <div
              className="step-description-box"
              style={{ background: '#F0FFDC', padding: '15px', borderRadius: '5px' }}
            >
              <div
                className="step-description-item"
                style={{ display: 'flex', alignItems: 'flex-start' }}
              >
                <img src={InfoSVG} alt="" className="svg-icon"/>
                <p className="disclaimer">
                  Sigorta şirketlerinin teklif verebilmesi için doğum tarihi bilgisi zorunlu hale getirilmiştir.
                </p>
              </div>
            </div>
          </div>
        </Grid>
        )}
      </Grid>
      {isMobile ? (
        <Button
          variant="contained"
          disabled={!isValid}
          style={{
            width: !isMobile ? '254px' : '100%',
            height: '55px',
            boxSizing: 'border-box',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
            borderRadius: '5px',
            textTransform: 'none',
            display: 'block',
            position: 'fixed',
            bottom: 0,
            left: 0,
            margin: '0 0 0 -20%',
          }}
          onClick={handleSubmit(onSubmit)}
        >
          Tekliflerimi Göster
        </Button>
      ) : (
        ''
      )}
    </>
  );
};

export default withRouter(BirthdateInfo);
