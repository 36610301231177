
export const getDifferenceInDays = (date1, date2) => {
    const diffInMs = date2 - date1;
    return diffInMs / (1000 * 60 * 60 * 24);
}

/**
 * @description Converts a date string to a date object
 * @param {string} {(dd-mm-yyyy)} dateString 
 * @returns {Date}
 */
export const formatStringToDate = (dateString, parser = '-') => {
    const [day, month, year] = dateString.split(parser);
    return new Date(+year, month - 1, +day);
}

/**
 * @description Converts a date object to a date string
 * @param {Date} date
 * @returns {string} {(dd-mm-yyyy)}
 */
export const formatDateToString = (date, parser = '.') => {
    const newDate = [
        String(date.getDate()).padStart(2, '0'),
        String(date.getMonth() + 1).padStart(2, '0'),
        date.getFullYear()
    ];
    return newDate.join(parser);
}

/**
 * @description Get a date variable x days from now
 */
export const getDateXDaysAgo = (numOfDays, date = new Date()) => {
    const daysAgo = new Date(date.getTime());
    daysAgo.setDate(date.getDate() - numOfDays);
    return daysAgo;
}
