import React, { useState } from 'react';
import FormBuilder from '../../../../../shared/formBuilder/FormBuilder';
import createContactInfoForm from '../../../../data/createForm/createContactInfoForm';

export const ContactInfoForm = (props) => {
  const {
    formHookObject,
    submitForm,
    userConsentObject,
  } = props;

  const infoFormObject = createContactInfoForm({
    formHookObject,
    submitForm,
    userConsentObject,
  });

  return (
    <>
      <form>
        <FormBuilder formBuilderObject={infoFormObject} submitForm={submitForm} />
      </form>
    </>
  );
};

export default ContactInfoForm;
