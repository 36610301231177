import { campaignTypes } from "../types/campaign.type";

const initialState = {
  campaignInfo: "",
  campaignDetail: "",
  phoneInfoMessage: "",
  quoteCardCampaignBanner: "",
  thankYouPageDescription: "",
  thankYouPageDetailTextLink: "",
};

export default function CAMPAIGN_REDUCER(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case campaignTypes.CAMPAIGN_SET_INFO:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
}
