import React from 'react';
import '../../../../shared/assets/styles/send-mail-modal.scss';

import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { Button } from '@mui/material';
import useWindowSize from '../../../../shared/_hooks/windowResizeHook';
import { KLInput } from '../form/KLInput';
import { emailValidation } from '../../../../shared/_helpers/validations';

export const SendMailModal = (props) => {
  const {
    setSendMailModal,
    onSubmit,
    mailAddress,
  } = props;

  const size = useWindowSize();
  const isMobile = size.width <= 880;

  const {
    handleSubmit,
    formState: { errors, isValid, touchedFields },
    control,
  } = useForm({
    mode: 'onChange',
  });

  return (
    <div className="send-mail-modal">
      <div className="send-mail-header">
        <h2>Teklifleri e-posta ile gönder</h2>
        <CloseIcon sx={{ color: '#003B73', fontSize: isMobile ? 22 : 26 }} onClick={() => setSendMailModal(false)} />
      </div>

      <p
        className="text"
        style={{ marginBottom: '30px' }}
      >
        Teklifler aşağıdaki e-posta adresine gönderilecektir.
      </p>

      <div className="mail-section">
        <div className="send-mail">
          <form>
            <KLInput
              name="email"
              control={control}
              label="E-Posta"
              error={errors}
              defaultValue={mailAddress}
              rules={emailValidation()}
              touchedFields={touchedFields}
              sx={{
                '& .MuiOutlinedInput-root': {
                  width: isMobile ? '100%' : '310px',
                  marginBottom: '0',
                  '&.Mui-error': {
                    width: isMobile ? '100%' : '310px',
                    marginBottm: '0',
                  },
                },
                '.MuiInputLabel-formControl': {
                  fontSize: '15px',
                  marginBottom: '0',
                },
                '&': {
                  width: 'auto',
                },
              }}
            />
            <Button
              variant="contained"
              disabled={!isValid}
              style={{
                width: '87px',
                height: '45px',
                boxSizing: 'border-box',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
                borderRadius: '0 5px 5px 0',
                textTransform: 'none',
              }}
              onClick={handleSubmit(onSubmit)}
            >
              Gönder
            </Button>
          </form>
        </div>
      </div>

    </div>
  );
};

export default SendMailModal;
