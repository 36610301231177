import React, { lazy, Suspense, useEffect, useState } from "react";
import { Box, Button, CircularProgress, Modal } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import SEQUENCE from "../../constants/sequence";
import Steps from "../components/Steps";
import stepActions from "../../actions/stepAction";
import useWindowSize from "../../../shared/_hooks/windowResizeHook";
import EventBus from "../../../shared/_helpers/EventBus";
import BirthdateInfo from "../pages/Steps/OptionalSteps/BirthdateInfo";
import ContinueProcess from "../pages/Steps/ContinueProcess";
import { withRouter } from "react-router-dom";
import { MAIN_URL } from "../../../shared/_helpers/env";
import loadingKlActions from "../../actions/loadingActions";
import headerActions from "../../actions/headerAction";
import shellActions from "../../actions/shellAction";

export const StepsContainer = (props) => {
  const { step } = useSelector((state) => state.step);
  const { isRenewal, isAgency, isMerchant } = useSelector((state) => state.shell);
  const { useHeader } = useSelector((state) => state.header);
  const dispatch = useDispatch();
  const [DynamicComponent, setDynamicComponent] = useState(null);
  const [isContinueProcess, setIsContinueProcess] = useState(null);
  const [isBack, setIsBack] = useState(false);
	const URL_PARAMS = new URLSearchParams(document.location.search);
  const referenceId = URL_PARAMS.get('ReferenceId') !== null ? URL_PARAMS.get('ReferenceId') : '';
  const jt = URL_PARAMS.get('jt') !== null ? URL_PARAMS.get('jt') : props.location.state.jt;
  const size = useWindowSize();
  const isMobile = size.width <= 880;


  const decrementStep = () => {
    if (step === 1) {
			if(isAgency){
				document.location.href = window.location.origin+'/quotation/info?Token='+localStorage.getItem('dealerToken')
			}else if(isMerchant){
				document.location.href = `${window.location.origin}+'/quotation/info?jt=${jt}&ReferenceId=${referenceId}`
			}else{
				document.location.href = MAIN_URL;
			}
    } else {
      if (isBack) {
        if (isRenewal) {
          setDynamicComponent(lazy(() => import(`../pages/Steps/${SEQUENCE.YENILEME[step].name}`)));
        } else {
          setDynamicComponent(lazy(() => import(`../pages/Steps/${SEQUENCE.SIFIR[step].name}`)));
        }
        setIsBack(false);
      } else {
        dispatch(stepActions.decrementStep());
      }
    }
  };
  useEffect(() => {
		dispatch(loadingKlActions.hideKlLoading());
    // EventBus.on("999ModelKod", () => {
    //   setDynamicComponent(lazy(() => import(`../pages/Steps/CarInfo`)));
    //   setIsBack(true);
    // });
    // EventBus.on("birthDateInfo", () => {
    //   setDynamicComponent(lazy(() => import(`../pages/Steps/OptionalSteps/BirthdateInfo`)));
    //   setIsBack(true);
    // });
    // EventBus.on("apsError", () => {
    //   setDynamicComponent(lazy(() => import(`../pages/Steps/OptionalSteps/CityDistrict`)));
    //   setIsBack(true);
    // });
    if (isRenewal) {
      setDynamicComponent(lazy(() => import(`../pages/Steps/${SEQUENCE.YENILEME[step].name}`)));
    } else {
      setDynamicComponent(lazy(() => import(`../pages/Steps/${SEQUENCE.SIFIR[step].name}`)));
    }
  }, [isRenewal, step]);

  useEffect(() => {
    if (props.quotes !== null && props.quotes.customerQuotes !== undefined && props.quotes.customerQuotes.length > 0 && props.appContinueProcess !== false) {
      setIsContinueProcess(props.quotes.customerQuotes.length > 0 );
      props.setAppContinueProcess(props.quotes.customerQuotes.length > 0);
			dispatch(headerActions.setUseHeader(false));
    }
  }, [props.quotes]);
  useEffect(() => {
    EventBus.on("closeContinueProcessModal", () => {
      setIsContinueProcess(false);
      props.setAppContinueProcess(false);
			dispatch(headerActions.setUseHeader(true));
    });
		dispatch(shellActions.setThemeByPage(false))
		localStorage.setItem('isFirstVisit', false)
    dispatch(stepActions.setStep(1));
  }, []);

  if (DynamicComponent !== null && useHeader) {
    return (
      <div className="steps-page">
        {isMobile && <Steps goBack={decrementStep} />}
        <Box className="steps-container">
          <Suspense
            fallback={
              <div className="inner-loading-container">
                <CircularProgress size={20} />
              </div>
            }
          >
            <DynamicComponent />
            {!isMobile ? (
              <Button
                style={{
                  color: "#53585E",
                  textTransform: "none",
                  fontSize: "17px",
                  fontWeight: 600,
                }}
                onClick={decrementStep}
              >
                <ArrowBackIosNewIcon />
                Geri
              </Button>
            ) : (
              ""
            )}
          </Suspense>
        </Box>
      </div>
    );
  } else {
    return(
			<>
			{isContinueProcess && props.appContinueProcess &&(
          <Modal
            open={isContinueProcess}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="continue-process"
          >
            <ContinueProcess quotes={props.quotes} setUseHeader={props.setUseHeader} isAgency={props.isAgency}></ContinueProcess>
          </Modal>
        )}
			</>
		)
  }
};

export default withRouter(StepsContainer);
