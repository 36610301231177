import React, { useEffect, useState } from 'react';
import '../../../../shared/assets/styles/occupation-selection.scss';

import {
  Button, FormControl, FormControlLabel, Radio, RadioGroup, TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import useWindowSize from '../../../../shared/_hooks/windowResizeHook';
import customerService from '../../../_services/customer.service';
import thrashSVG from '../../../../shared/assets/img/svg/thrash.svg';

export const OccupationModal = (props) => {
  const {
    occupationList, selectedOccupation, setOccupationModal, setSelectedOccupationId, setShouldOffersRerender,
  } = props;
  const size = useWindowSize();
  const isMobile = size.width <= 880;
  const URL_PARAMS = new URLSearchParams(document.location.search);
  const [filteredOccupations, setFilteredOccupations] = useState(occupationList);
  const { isCallCenter } = useSelector((state) => state.shell);
  const [value, setValue] = useState(selectedOccupation !== null ? selectedOccupation.value : null);
  useEffect(() => {
    const { devicePixelRatio } = window;
  }, []);

  useEffect(() => {
    if (selectedOccupation !== null) {
      document.getElementById(selectedOccupation.value).scrollIntoView();
    }
  }, []);

  const filterOccupations = (e) => {
    const filterValue = e.target.value;

    setFilteredOccupations(
      occupationList.filter((occ) => occ.text.toLowerCase().indexOf(filterValue.toLowerCase()) === 0),
    );
  };
  const selectOccuptation = (type) => {
    let selectedValue = value;
    if (type === 'remove') {
      selectedValue = null;
    }
    customerService.masterOccupation(
      { MasterOccupationId: parseInt(selectedValue, 10), UserGuid: URL_PARAMS.get('jt') },
      () => {
        if (selectedOccupation !== null && type !== 'remove') {
          setOccupationModal(false);
        }
        setSelectedOccupationId(selectedValue);
        setShouldOffersRerender(selectedValue);
      },
    );
    setOccupationModal(false);
  };
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const getOccupationSelectHeight = () => {
    const { devicePixelRatio } = window;
    if (isCallCenter) {
      return '45vh';
    }
    if (isMobile) {
      return '100%';
    }

    return `calc(667px / ${devicePixelRatio})`;
  };
  const getOccupationButtonsHeight = () => {
    const { devicePixelRatio } = window;
    if (isCallCenter) {
      return '25vh';
    }
    if (isMobile) {
      if (value !== null) {
        return 'calc(90vh - 135px)';
      }
      return 'calc(87vh - 45px)';
    }
    if (value !== null) {
      return `calc(500px / ${devicePixelRatio})`;
    }
    if (devicePixelRatio === 1.25) {
      return '428px';
    }
    return '565px';
  };

  return (
    <div className="occupation-select" style={{ height: getOccupationSelectHeight(), top: isMobile ? 'unset' : `calc(120px / ${devicePixelRatio})` }}>
      <div className="occupation-header">
        <h2>Meslek Seçimi</h2>
        <CloseIcon sx={{ color: '#003B73', fontSize: isMobile ? 22 : 26 }} onClick={() => setOccupationModal(false)} />
      </div>
      <div className="occupations">
        <div className="search-input">
          <TextField
            name="occupation"
            label="Meslek ara..."
            variant="outlined"
            onChange={filterOccupations}
            sx={{
              backgroundColor: 'rgba(217, 221, 225, 0.3)',
              borderRadius: '10px',
              width: '302px',
              height: '45px',
              marginTop: '20px',
            }}
          />
        </div>
        {value !== null && (
          <div className="remove-occupation">
            <span
              className="remove-occupation-text"
              onClick={() => {
                setValue(null);
                selectOccuptation('remove');
              }}
            >
              <img src={thrashSVG} width={isMobile ? 20 : 24} height={isMobile ? 20 : 24} />
              <p>Meslek seçimini kaldır</p>
            </span>
          </div>
        )}
        <FormControl
          className="occupation-buttons"
          style={{ height: getOccupationButtonsHeight() }}
        >
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            onChange={handleChange}
            value={value}
          >
            {filteredOccupations.map((occ) => (
              <FormControlLabel value={occ.value} control={<Radio />} label={occ.text} id={occ.value} key={occ.value} />
            ))}
          </RadioGroup>
        </FormControl>
      </div>
      {value !== null && (
        <div className="apply-occupation">
          <Button
            disabled={false}
            variant="contained"
            style={{
              width: '100%',
              height: '45px',
              boxSizing: 'border-box',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
              borderRadius: '5px',
              textTransform: 'none',
            }}
            onClick={selectOccuptation}
          >
            Meslek İndirimi Uygula
          </Button>
        </div>
      )}
    </div>
  );
};

export default OccupationModal;
