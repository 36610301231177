// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UYQ9UE1Dy250CkfTNzu5{position:fixed;cursor:pointer;transition:opacity 0.3s;bottom:90px;right:10px;width:50px;height:50px;opacity:0.7}@media only screen and (min-width: 880px){.UYQ9UE1Dy250CkfTNzu5{bottom:50px;right:50px;width:50px;height:50px}}.wtC3cz1xnCmv1SUA3JhI{opacity:1}.OwPiOW8ndndWze3cZMdr{opacity:0;pointer-events:none}
`, "",{"version":3,"sources":["webpack://./src/shared/components/PageToTopButton/pageToTopButton.module.scss"],"names":[],"mappings":"AAAA,sBACI,cAAe,CACf,cAAe,CACf,uBAAwB,CACxB,WAAY,CACZ,UAAW,CACX,UAAW,CACX,WAAY,CACZ,WAAY,CACZ,0CATJ,sBAUM,WAAY,CACZ,UAAW,CACX,UAAW,CACX,WAAY,CAEf,CAED,sBACE,SAAU,CACX,sBAGC,SAAU,CACV,mBAAoB","sourcesContent":[".icon {\n    position: fixed;\n    cursor: pointer;\n    transition: opacity 0.3s;\n    bottom: 90px;\n    right: 10px;\n    width: 50px;\n    height: 50px;\n    opacity: 0.7;\n    @media only screen and (min-width: 880px) {\n      bottom: 50px;\n      right: 50px;\n      width: 50px;\n      height: 50px;\n    }\n  }\n  \n  .visible {\n    opacity: 1;\n  }\n  \n  .hidden {\n    opacity: 0;\n    pointer-events: none;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `UYQ9UE1Dy250CkfTNzu5`,
	"visible": `wtC3cz1xnCmv1SUA3JhI`,
	"hidden": `OwPiOW8ndndWze3cZMdr`
};
export default ___CSS_LOADER_EXPORT___;
