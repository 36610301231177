import React, { useState } from 'react';
import '../../../../../shared/assets/styles/send-mail-modal.scss';

import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import Support from '../../../../../shared/assets/img/svg/Support.svg';
import Done from '../../../../../shared/assets/img/svg/Done.svg';
import customerService from '../../../../../motor/_services/customer.service';
import tssButtonStyle from '../../../../data/tssButtonStyle';
import { MAIN_URL } from '../../../../../shared/_helpers/env';

export const SelfServiceRedicrectCC = (props) => {
  const [modalStep, setModalStep] = useState(1);
  const { consumer } = useSelector((state) => state.consumer);
  const { customer, insureds } = useSelector((state) => state.info);

  const onSubmit = (data) => {
    const selfInsured = insureds.find((insured) => insured.self === true);
    const customerPhone = data?.phone || customer?.PhoneNumber || customer?.telefon || selfInsured?.mobilePhone;
		const URL_PARAMS = new URLSearchParams(document.location.search);
		const qGuid = URL_PARAMS.get('qGuid');
    const contactData = {
      TeklifUid: qGuid,
      Sbn: JSON.parse(consumer).partnerSbn || 'KCDUTC',
      Phone: customerPhone,
    };
    customerService.contactScheduleCallTss(contactData, (resp) => {
      setModalStep(2);
    });
  };

  return (
    <>
      <div className="redirect-cc-container">
        {modalStep === 1 && (
          <div className="redirect-cc">
            <img src={Support} />
            <h3>
              Üzgünüz!
            </h3>
            <p className="text">
              Seçmiş olduğunuz kriterler için yalnızca çağrı merkezimiz üzerinden poliçe işleminize devam edebiliyoruz. Sizi aramamızı ister misiniz?
            </p>
            <div className="redirect-cc-buttons self-service">
              <Button
                variant="outlined"
                onClick={() => {window.location.href = MAIN_URL}}
              >
                Hayır, aramayın
              </Button>
              <Button
                variant="contained"
                onClick={() => onSubmit()}
              >
                Evet, lütfen arayın
              </Button>
            </div>
          </div>
        )}
        {modalStep === 2 && (
          <div className="redirect-cc">
            <img src={Done} />
            <h3 style={{marginTop: '-16px'}}>
						  Teşekkürler!
            </h3>
            <p className="text">
              Talebiniz alınmıştır. Sigorta uzmanlarımız size en kısa sürede ulaşacak.
            </p>
            <Button
              variant="contained"
              onClick={() => {window.location.href = MAIN_URL}}
              style={{ ...tssButtonStyle, marginTop: '8px' }}
            >
              Ana sayfaya dön
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default SelfServiceRedicrectCC;
