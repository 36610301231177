import React, { useEffect, useState } from 'react';
import {
  Button, Checkbox, FormControlLabel, Modal, Tooltip, tooltipClasses,
} from '@mui/material';
import '../../../../shared/assets/styles/offer.scss';
import { styled } from '@mui/material/styles';
import { withRouter } from 'react-router-dom';
import { Box } from '@mui/system';
import { useDispatch } from 'react-redux';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import useWindowSize from '../../../../shared/_hooks/windowResizeHook';
import quotationService from '../../../_services/quotation.service';
import Steps from '../../components/Steps';
import stepActions from '../../../actions/stepAction';
import '../../../../shared/assets/styles/pages/confirmation.scss';
import schedule from '../../../../shared/assets/img/svg/Schedule.svg';
import edit from '../../../../shared/assets/img/svg/edit.svg';
import EditConfirmationField from './EditConfirmationField';
import MissingConfirmation from './MissingConfirmation';
import vehicleService from '../../../_services/vehicle.services';
import customerService from '../../../_services/customer.service';
import toastrActions from '../../../actions/toastrAction';
import loadingKlActions from '../../../actions/loadingActions';
import commonService from '../../../_services/common.service';
import { removeCustomEventsFromDataLayer } from '../../../../shared/_helpers/helperFunctions';
import headerActions from '../../../actions/headerAction';
import shellActions from '../../../actions/shellAction';

export const Confirmation = (props) => {
  const size = useWindowSize();
  const isMobile = size.width <= 880;
  const URL_PARAMS = new URLSearchParams(document.location.search);
  const quoteGuid = URL_PARAMS.get('qGuid') !== null ? URL_PARAMS.get('qGuid').toLowerCase() : props.location.state.quoteGuid.toLowerCase();
  const jt = URL_PARAMS.get('jt') !== null ? URL_PARAMS.get('jt') : props.location.state.jt;
  const quoteId = URL_PARAMS.get('teklifTID') !== null ? URL_PARAMS.get('teklifTID') : props.location.state.teklifTID;
  const bransType = URL_PARAMS.get('bransType') !== null ? URL_PARAMS.get('bransType') : props.location.state.bransType;

  const [confirmationInfo, setConfirmationInfo] = useState(null);
  const [plainConfirmation, setPlainConfirmation] = useState(null);
  const [confirmationChecked, setConfirmationChecked] = useState(null);
  const [policyStartDate, setPolicyStartDate] = useState(null);

  const [isEditModalOpen, setIsEditModalOpen] = useState(null);
  const [editModalProps, setEditModalProps] = useState({});
  const [didUserInfoEdited, setDidUserInfoEdited] = useState(false);

  const [isMissingModalOpen, setIsMissingModalOpen] = useState(null);
  const [didVehicleInfoEdited, setDidVehicleInfoEdited] = useState(false);
  const [selectedUsageType, setSelectedUsageType] = useState('');

  const [updateableFlag, setUpdateableFlag] = useState([]);
  const [isAddressEditted, setIsAddressEditted] = useState(false);
  const [isZero, setIsZero] = useState(false);

  const dispatch = useDispatch();

  dispatch(stepActions.setStep(3));

  useEffect(() => {
    dispatch(loadingKlActions.hideKlLoading());
  }, [confirmationInfo]);

  const getQuotationDetail = () => {
    quotationService.getQuotationDetail(quoteGuid, quoteId, (response) => {
      const blacklistObject = {
        Email: response.confirmation.customerInfo.email,
        PhoneNumber: response.confirmation.customerInfo.telefon,
        TcKimlikVergiNo: response.confirmation.customerInfo.tcKimlikVergiNo,
        UserUid: response.confirmation.customerInfo.customerUId,
        IpNumber: response.confirmation.customerInfo.ipNumber,
        Sbnkod: null,
        BlackListPage: 0,
      };
      if (response.confirmation.vehicleInfo.markaKod) {
        vehicleService.getVehicleUsageTypes({ vehicleBrandId: response.confirmation.vehicleInfo.markaKod }, (result) => {
          const stringUsageType = response.confirmation.vehicleInfo.tarifeCode.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false,
          });
          setSelectedUsageType(result.vehicleUsageTypeNameList.find((type) => type.Value === stringUsageType).Text.toUpperCase());
        });
      }
      commonService.blacklist(blacklistObject, (blResponse) => {
        if (blResponse.code === '0') {
          setConfirmationInfo(response.confirmation);
          setPlainConfirmation(response.confirmation);
          setPolicyStartDate(new Date(response.confirmation.policyStartDate).toLocaleDateString('tr-TR'));
          if (
            !response.confirmation.customerInfo.icKapiNo
            || !response.confirmation.customerInfo.disKapiNo
            || response.confirmation.vehicleInfo.sasiNo.indexOf('CAHSNM') >= 0
            || response.confirmation.vehicleInfo.motorNo.indexOf('MTRNM') >= 0
            || !response.confirmation.customerInfo.adresIlAck
            || !response.confirmation.customerInfo.adresIlceAck
          ) {
            setIsMissingModalOpen(true);
            setIsZero(true);
          }
        } else {
          dispatch(toastrActions.setToastrMessage({ variant: 'error', message: blResponse.msg }));
          dispatch(loadingKlActions.showKlLoadingEmpty());
        }
      });
    });
  };

  useEffect(() => {
    const addresInputs = ['icKapiNo', 'disKapiNo', 'caddeSokak', 'mahalle'];
    const found = updateableFlag.some((r) => addresInputs.indexOf(r) >= 0);
    setIsAddressEditted(found);
  }, [updateableFlag]);

  const handleUpdateableFlag = (value) => {
    setUpdateableFlag(value);
  };
  const handleConfirmation = (e) => {
    setConfirmationChecked(e.target.checked);
  };

  const goToSummary = () => {
    props.history.push({
      pathname: '/quotation/summary',
      search: `${props.history.location.search.split('&teklifTID')[0]}`,
      state: { qGuid: quoteGuid },
    });
  };

  const handleSubmit = (e) => {
    if (didUserInfoEdited) {
      const confirmObject = {
        CustomerEMail: confirmationInfo.customerInfo.email,
        CustomerUID: URL_PARAMS.get('jt'),
        DisKapiNo: confirmationInfo.customerInfo.disKapiNo.split('/')[0],
        IcKapiNo: confirmationInfo.customerInfo.icKapiNo,
        IsConfirmation: true,
        PolicyStartDate: confirmationInfo.policyStartDate,
        ProcessStep: '10',
        TelNo: confirmationInfo.customerInfo.telefon,
        CaddeSokak: confirmationInfo.customerInfo.caddeSokak,
        Mahalle: confirmationInfo.customerInfo.mahalle,
        quoteGuid,
        CustomerIlKodu: confirmationInfo.customerInfo.adresIlCode || null,
        CustomerIl: confirmationInfo.customerInfo.adresIlAck || null,
        CustomerIlceKodu: confirmationInfo.customerInfo.adresIlceCode || null,
        CustomerIlce: confirmationInfo.customerInfo.adresIlceAck || null,
      };
      quotationService.quotationConfirm(confirmObject, (response) => {

      });
    }
    if (didVehicleInfoEdited) {
      const confirmObject = {
        MotorNo: confirmationInfo.vehicleInfo.motorNo,
        PolicyStartDate: confirmationInfo.policyStartDate,
        QuoteID: quoteGuid,
        SasiNo: confirmationInfo.vehicleInfo.sasiNo,
      };
      vehicleService.vehicleDetail(confirmObject, (response) => {
      });
    }
    // for lowercase qGuid otherwise ws error is received
    const searchString = props.history.location.search;
    const searchArray = searchString.replace('?', '').split('&').map((i) => {
      if (i.indexOf('qGuid') >= 0) {
        return `qGuid=${i.split('=').pop().toLowerCase()}`;
      }
      return i;
    });

    const redirectSearchParams = `?${searchArray.join('&')}`;
    props.history.push({
      pathname: '/quotation/checkout',
      search: `${redirectSearchParams}`,
      state: { teklifTID: quoteId, bransType, quoteGuid },
    });
  };
  const editData = (val, type, exactKey) => {
    setEditModalProps({
      value: val,
      title: type,
      exactKey,
    });
  };

  useEffect(() => {
    setIsEditModalOpen(true);
  }, [editModalProps]);

  useEffect(() => {
    removeCustomEventsFromDataLayer(window.dataLayer);
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'confirmation_page',
      funnel_variables: {
        jt,
        qGuid: quoteGuid,
        selected_item: JSON.parse(localStorage.getItem('selectedOffer')),
      },
    });
    dispatch(shellActions.setThemeByPage(true));
    dispatch(loadingKlActions.showKlLoadingEmpty());
    getQuotationDetail();
    dispatch(headerActions.setUseHeader(true));
  }, []);

  const updateField = (val, title, exactKey) => {
    const tempConf = { ...confirmationInfo };
    if (exactKey.split('.').length > 1) {
      tempConf[exactKey.split('.')[0]][exactKey.split('.')[1]] = val;
    } else {
      tempConf[exactKey] = val;
      setPolicyStartDate(new Date(val).toLocaleDateString('tr-TR'));
    }
    setDidUserInfoEdited(true);
    setIsEditModalOpen(false);
    setConfirmationInfo(tempConf);
  };
  const updateMultiple = (data) => {
    const tempConf = { ...confirmationInfo };
    Object.keys(data).map((key) => {
      tempConf.customerInfo[key] = data[key];
    });
    setDidUserInfoEdited(true);
    setIsEditModalOpen(false);
    if (data.city) {
      tempConf.customerInfo.adresIlAck = data.city.name;
      tempConf.customerInfo.adresIlCode = data.city.code;
      tempConf.customerInfo.adresIlceAck = data.district.name;
      tempConf.customerInfo.adresIlceCode = data.district.code;
    }
    setConfirmationInfo(tempConf);
  };
  const updateConfirmation = (confInfo) => {
    setConfirmationInfo(confInfo);
    setIsMissingModalOpen(false);
    setDidVehicleInfoEdited(true);
    setDidUserInfoEdited(true);
  };

  return (
    confirmationInfo
    && policyStartDate && (
      <div className="confirmation-page">
        {isMobile && <Steps className="steps" isHidden={false} goBack={goToSummary} />}
        <Box className="steps-container" style={{ marginBottom: isMobile ? '70px' : 'unset' }}>
          <h1>Bilgilerinizi Doğrulayın</h1>
          <div className="confirmation">
            <div className="personal-info-container">
              <div className="detail">
                <span className="row">
                  <p className="text gray">Ad Soyad</p>
                  <p className="text">{`${confirmationInfo.customerInfo.ad} ${confirmationInfo.customerInfo.soyad}`}</p>
                </span>
                <span className="row">
                  <p className="text gray">Telefon</p>
                  <p className="text">
                    {`${
                      confirmationInfo.customerInfo.telefon.indexOf('(') >= 0
                        ? confirmationInfo.customerInfo.telefon
                        : confirmationInfo.customerInfo.telefon.replace(
                          /^(\d{3})(\d{3})(\d{2})(\d{2}).*/,
                          '($1) $2 $3 $4',
                        )
                    }`}
                    {' '}
                    <img
                      src={edit}
                      onClick={() => {
                        editData(confirmationInfo.customerInfo.telefon, 'Telefon', 'customerInfo.telefon');
                      }}
                    />
                  </p>
                </span>
                <span className="row">
                  <p className="text gray">E-posta</p>
                  <p className="text">
                    {`${confirmationInfo.customerInfo.email}`}
                    {' '}
                    <img
                      src={edit}
                      width="29px"
                      height="24px"
                      onClick={() => {
                        editData(confirmationInfo.customerInfo.email, 'E-posta', 'customerInfo.email');
                      }}
                    />
                  </p>
                </span>
                <span className="row">
                  <p className="text gray">Adres</p>
                  {(confirmationInfo.customerInfo.mahalle
                    || confirmationInfo.customerInfo.caddeSokak
                    || confirmationInfo.customerInfo.adresIlceAck
                    || confirmationInfo.customerInfo.adresIlAck) && (
                    <p className="text">
                      {`${confirmationInfo.customerInfo.mahalle} Mahallesi, ${confirmationInfo.customerInfo.caddeSokak} Sokak, ${confirmationInfo.customerInfo.disKapiNo} / ${confirmationInfo.customerInfo.icKapiNo},`}
                      {`${confirmationInfo.customerInfo.adresIlceAck}, ${confirmationInfo.customerInfo.adresIlAck}`}
                      {(isAddressEditted
                        || !plainConfirmation.customerInfo.mahalle
                        || !plainConfirmation.customerInfo.caddeSokak
                        || !plainConfirmation.customerInfo.adresIlceAck
                        || !plainConfirmation.customerInfo.adresIlAck) && (
                        <img
                          src={edit}
                          onClick={() => {
                            editData(plainConfirmation.customerInfo, 'multiple', null);
                          }}
                        />
                      )}
                    </p>
                  )}
                </span>
              </div>
            </div>
            <div className="car-info-container">
              <div className="detail">
                <span className="row">
                  <p className="text gray">Araç</p>
                  <p className="text">{`${confirmationInfo.vehicleInfo.modelYil} ${confirmationInfo.vehicleInfo.modelAck} - ${selectedUsageType}`}</p>
                </span>
                <span className="row">
                  <p className="text gray">Şasi No</p>

                  <p className="text">
                    {`${
                      confirmationInfo.vehicleInfo.sasiNo.indexOf('CAHSNM') >= 0
                        ? ''
                        : confirmationInfo.vehicleInfo.sasiNo
                    }`}
                    {isZero && (
                      <img
                        src={edit}
                        onClick={() => {
                          editData(confirmationInfo.vehicleInfo.sasiNo, 'Şasi No', 'vehicleInfo.sasiNo');
                        }}
                      />
                    )}
                  </p>
                </span>
                <span className="row">
                  <p className="text gray">Motor No</p>
                  <p className="text">
                    {`${
                      confirmationInfo.vehicleInfo.motorNo.indexOf('MTRNM') >= 0
                        ? ''
                        : confirmationInfo.vehicleInfo.motorNo
                    }`}
                    {isZero && (
                      <img
                        src={edit}
                        onClick={() => {
                          editData(confirmationInfo.vehicleInfo.motorNo, 'Motor No', 'vehicleInfo.motorNo');
                        }}
                      />
                    )}
                  </p>
                </span>
                {confirmationInfo.vehicleInfo.ruhsatSeriNo !== null
                  && confirmationInfo.vehicleInfo.ruhsatNo !== null && (
                    <span className="row">
                      <p className="text gray">Ruhsat Seri No</p>
                      <p className="text">{`${confirmationInfo.vehicleInfo.ruhsatSeriNo} ${confirmationInfo.vehicleInfo.ruhsatNo}`}</p>
                    </span>
                )}
              </div>
            </div>
            <span className="policy-row">
              <img src={schedule} className="svg-icon" />
              {' '}
              <p className="text">{`Poliçe Başlangıç Tarihi: ${policyStartDate}`}</p>
              {/* {bransType === 'Kasko' && ( // disabled via CER-6987
                <img
                  src={edit}
                  onClick={() => {
                    editData(confirmationInfo.policyStartDate, 'Poliçe Başlangıç Tarihi', 'policyStartDate');
                  }}
                />
              )} */}
            </span>
          </div>
          {bransType === 'Kasko' && (
            <div>
              <FormControlLabel
                style={{ margin: '0', display: 'flex', alignItems: 'flex-start' }}
                control={<Checkbox onChange={handleConfirmation} />}
                label={(
                  <p className="disclaimer" style={{ marginTop: '8px' }}>
                    Yukarıda verdiğim bilgilerin doğru olduğunu, aracımda herhangi bir hasar olmadığını, aracımın ticari
                    taksi, kiralık araç (rent a car), sürücü kursu aracı, hatlı minibüs ya da dolmuş olarak
                    kullanılmadığını beyan ve taahhüt ederim. Bilgilerimin doğruluğunu kabul ediyorum.
                  </p>
                )}
              />
            </div>
          )}
          <p className="disclaimer" style={{ marginTop: '8px' }}>
            Devam ederek yukarıda gördüğünüz ve süreçte sağladığınız bilgilerin doğru olduğunu taahhüt etmektesiniz.
            Gerçeğe aykırı ve eksik beyan, sigorta şirketi tarafından sigorta poliçesinin iptaline ve/veya hasarın
            reddine sebep olabilir.
          </p>
          {!isMobile ? (
            <>
              <div>
                <Button
                  variant="contained"
                  disabled={bransType === 'Kasko' ? !confirmationChecked : false}
                  style={{
                    width: !isMobile ? '254px' : '100%',
                    height: '45px',
                    margin: '24px 7px',
                    boxSizing: 'border-box',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
                    borderRadius: '5px',
                    textTransform: 'none',
                  }}
                  onClick={handleSubmit}
                >
                  Devam Et
                </Button>
              </div>
              <div>
                <Button
                  style={{
                    color: '#53585E',
                    textTransform: 'none',
                    fontSize: '17px',
                    fontWeight: 600,
                  }}
                  onClick={goToSummary}
                >
                  <ArrowBackIosNewIcon />
                  Geri
                </Button>
              </div>
            </>
          ) : (
            ''
          )}
        </Box>
        {isMobile ? (
          <Button
            variant="contained"
            disabled={bransType === 'Kasko' ? !confirmationChecked : false}
            style={{
              width: !isMobile ? '254px' : '100%',
              height: '55px',
              boxSizing: 'border-box',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
              borderRadius: '5px 5px 0 0 ',
              textTransform: 'none',
              display: 'block',
              position: 'fixed',
              bottom: 0,
              left: 0,
              margin: '0 0 0 -20%',
            }}
            onClick={handleSubmit}
          >
            Devam Et
          </Button>
        ) : (
          ''
        )}
        {isEditModalOpen && Object.keys(editModalProps).length !== 0 && (
          <Modal open={isEditModalOpen} onClose={() => setIsEditModalOpen(false)}>
            <EditConfirmationField
              {...editModalProps}
              handleClose={() => setIsEditModalOpen(false)}
              updateField={updateField}
              updateMultiple={updateMultiple}
              confirmationInfo={confirmationInfo}
              updateableFlag={updateableFlag}
            />
          </Modal>
        )}
        {isMissingModalOpen && confirmationInfo !== null && (
          <Modal open={isEditModalOpen}>
            <MissingConfirmation
              confirmationInfo={confirmationInfo}
              updateConfirmation={updateConfirmation}
              handleUpdateableFlag={handleUpdateableFlag}
            />
          </Modal>
        )}
      </div>
    )
  );
};

export default withRouter(Confirmation);
