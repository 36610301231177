// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CgvMLBYTTyXsbutnnsar{border:1px solid #DFE8F6;box-sizing:border-box;border-radius:8px;padding:8px 12px;margin-top:12px}.CgvMLBYTTyXsbutnnsar p{margin-top:0;margin-bottom:8px}.CgvMLBYTTyXsbutnnsar p:last-child{margin-bottom:0}@media only screen and (min-width: 880px){.CgvMLBYTTyXsbutnnsar{width:624px}}.Pfw1hfpB89bcIKouo_sQ{font-size:14px;font-weight:700;line-height:21px;letter-spacing:0px;color:#2A3563}.I1DN4oa1wDXUQOtvTRZF{font-size:12px;font-weight:400;line-height:18px;letter-spacing:0px;color:#787878}
`, "",{"version":3,"sources":["webpack://./src/tss/views/pages/Summary/components/ContractedProviderListItem/contractedProviderListItem.module.scss"],"names":[],"mappings":"AAAA,sBACI,wBAAyB,CACzB,qBAAsB,CACtB,iBAAkB,CAClB,gBAAiB,CACjB,eAAgB,CALpB,wBAOQ,YAAa,CACb,iBAAkB,CAR1B,mCAWQ,eAAgB,CACnB,0CAZL,sBAcQ,WAAY,CAEnB,CAED,sBACI,cAAe,CACf,eAAgB,CAChB,gBAAiB,CACjB,kBAAmB,CACnB,aAAc,CACjB,sBAGG,cAAe,CACf,eAAgB,CAChB,gBAAiB,CACjB,kBAAmB,CACnB,aAAc","sourcesContent":[".container {\n    border: 1px solid #DFE8F6;\n    box-sizing: border-box;\n    border-radius: 8px;\n    padding: 8px 12px;\n    margin-top: 12px;\n    p {\n        margin-top: 0;\n        margin-bottom: 8px;\n    }\n    p:last-child {\n        margin-bottom: 0;\n    }\n    @media only screen and (min-width: 880px) {\n        width: 624px;\n    }\n}\n\n.name {\n    font-size: 14px;\n    font-weight: 700;\n    line-height: 21px;\n    letter-spacing: 0px;\n    color: #2A3563;\n}\n\n.text {\n    font-size: 12px;\n    font-weight: 400;\n    line-height: 18px;\n    letter-spacing: 0px;\n    color: #787878;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CgvMLBYTTyXsbutnnsar`,
	"name": `Pfw1hfpB89bcIKouo_sQ`,
	"text": `I1DN4oa1wDXUQOtvTRZF`
};
export default ___CSS_LOADER_EXPORT___;
