/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-indent */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-expressions */
import SEO from 'react-seo-component';
import {
  Box, Button, Checkbox, Chip, FormControl, FormControlLabel, Grid, InputAdornment, InputLabel, Select,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import '../../../../shared/assets/styles/pages/cancellation.scss';
import { maskPlate } from '../../../../shared/_helpers/masks';
import { plateValidation, tcknValidation } from '../../../../shared/_helpers/validations';
import useWindowSize from '../../../../shared/_hooks/windowResizeHook';
import { KLInput } from '../../components/form/KLInput';

import upload from '../../../../shared/assets/img/svg/upload.svg';
import close from '../../../../shared/assets/img/svg/close.svg';
import { getBase64, removeCustomEventsFromDataLayer } from '../../../../shared/_helpers/helperFunctions';
import policyService from '../../../_services/policy.service';
import toastrActions from '../../../actions/toastrAction';
import headerActions from '../../../actions/headerAction';
import shellActions from '../../../actions/shellAction';

export const Cancellation = (props) => {
  const size = useWindowSize();
  const isMobile = size.width <= 880;
  const dispatch = useDispatch();
  const fileUploadRef = useRef();

  /* #region  State operations */
  const [fileNames, setFileNames] = useState([]);
  const [customerMail, setCustomerMail] = useState(null);
  const [customerPhone, setCustomerPhone] = useState(null);
  const [teklifTId, setTeklifTId] = useState(null);
  const [snackbarErrorMessages, setSnackbarErrorMessages] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [shouldShowCloseIcon, setShouldShowCloseIcon] = useState(false);
  /* #endregion */

  /* #region  Checkbox */
  const [kasko, setKasko] = useState(false);
  const [traffic, setTraffic] = useState(false);
  const [imm, setImm] = useState(false);

  const checkboxArr = [{ Kasko: kasko }, { Trafik: traffic }, { İMM: imm }];
  /* #endregion */

  /* #region Form fields */
  const [tckn, setTckn] = useState('');
  const [plate, setPlate] = useState('');
  const [filesUploaded, setFilesUploaded] = useState(null);

  const {
    handleSubmit,
    reset,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isValid, touchedFields },
    control,
  } = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
  });
  /* #endregion */

  /* #region Checkbox errors */
  const [kaskoError, setKaskoError] = useState(null);
  const [trafficError, setTrafficError] = useState(null);
  const [immError, setImmError] = useState(null);
  /* #endregion */

  /* #region Checkbox change handlers */
  const kaskoChangeHandler = () => {
    if (kasko && kaskoError) {
      setKaskoError(null);
    }
    setKasko(!kasko);
  };

  const trafficChangeHandler = () => {
    if (traffic && trafficError) {
      setTrafficError(null);
    }
    setTraffic(!traffic);
  };

  const immChangeHandler = () => {
    if (imm && immError) {
      setImmError(null);
    }
    setImm(!imm);
  };
  /* #endregion */

  useEffect(() => {
    dispatch(shellActions.setThemeByPage(true))
  }, []);

  useEffect(() => {
		dispatch(headerActions.setUseHeader(true));
    dispatch(headerActions.setMainNav(true));
  }, []);

  //* Redirecting to success page or displaying errors
  useEffect(() => {
    if (isSubmitted && (kaskoError === null || !kaskoError) && (trafficError === null || !trafficError) && (immError === null || !immError)) {
      props.history.push({ pathname: 'police-iptal-talebi-basarili', state: { customerMail, customerPhone, teklifTId } });
    } else {
      const sortedErrorMessages = snackbarErrorMessages.sort((a, b) => a - b);
      dispatch(toastrActions.setToastrMessage({ variant: 'error', message: sortedErrorMessages }));
    }
    setTimeout(() => (setIsSubmitted(false)), 500); //* Does not work properly without the timeout
  }, [isSubmitted]);

  const fileChangedHandler = (event) => {
    clearErrors('filesUploaded');
    const tempArr = [];
    const tempArr2 = [];
    let currentFile;
    Object.values(event).map(async (file) => {
      tempArr.push(file);
      const fileSize = file.size;
      const fileName = file.name;
      const fileType = file.type;
      const base64value = await getBase64(file);
      currentFile = {
        fileName,
        fileSize,
        fileType,
        fileShared: true,
        uploadName: fileName,
        key: 'task',
        file: base64value,
      };
      tempArr2.push(currentFile);
      return 0;
    });
    let errorFound = false;
    if (tempArr.length <= 4) {
      tempArr.map((file) => {
        const acceptedArr = ['PDF', 'DOC', 'DOCX', 'PNG', 'JPG', 'JPEG'];
        const extension = file.name.split('.')[1].toUpperCase();
        if (!acceptedArr.includes(extension) && !errorFound) {
          errorFound = true;
        }
      });
      if (errorFound) {
        setError('filesUploaded', { type: 'error', message: 'Dosya formatı .doc, .docx, .jpg, .jpeg, .png, .pdf formatlarından biri olmalıdır.' });
      }
    } else {
      setError('filesUploaded', { type: 'error', message: 'En fazla 4 adet dosya seçilebilir.' });
    }
    setFileNames(tempArr);
    setFilesUploaded(tempArr2);
    setShouldShowCloseIcon(true);
    if (event.length === 0) {
      setError('filesUploaded', { type: 'error', message: 'Dosya seçili değil.' });
      setShouldShowCloseIcon(false);
    }
  };

  //* Request handling
  const submitForm = () => {
    const productGroupIds = [];
    kasko && productGroupIds.push(1);
    traffic && productGroupIds.push(2);
    imm && productGroupIds.push(15);
    const requestObj = {
      productGroupIds,
      identity: tckn,
      plate: plate.replaceAll(' ', ''),
      ContractDocument: filesUploaded,
    };
    policyService.cancelPolicy(requestObj, (resp) => {
      setCustomerMail(resp.customerMail);
      setCustomerPhone(resp.customerPhone);
      const tempArr = [];
      setKaskoError(null);
      setTrafficError(null);
      setImmError(null);
      const tempArrForTeklifTIds = [];
      for (const policyCancellationResponse in resp.policyCancellationResponses) {
        const response = resp.policyCancellationResponses[policyCancellationResponse];
        tempArrForTeklifTIds.push(response.teklifTId);
        if (!response.isSuccessfull && response.productGroupId === 1) {
          setKaskoError(true);
          if (!tempArr.includes(response.errorDescription)) {
            tempArr.push(response.errorDescription);
          }
        } else if (!response.isSuccessfull && response.productGroupId === 2) {
          setTrafficError(true);
          if (!tempArr.includes(response.errorDescription)) {
            tempArr.push(response.errorDescription);
          }
        } else if (!response.isSuccessfull && response.productGroupId === 15) {
          setImmError(true);
          if (!tempArr.includes(response.errorDescription)) {
            tempArr.push(response.errorDescription);
          }
        }
      }
      setSnackbarErrorMessages(tempArr);
      setTeklifTId(tempArrForTeklifTIds);
      setIsSubmitted(true);
      removeCustomEventsFromDataLayer(window.dataLayer);
      window.dataLayer.push({
        event: 'PolicyCancellationSubmit',
        isSuccessfull: tempArr.length === 0, //! not sure if this is the best way
      });
    });
  };

  const mobileCheckboxLabel = (!kasko && !traffic && !imm) ? 'Poliçe Seçiniz'
    : (
      checkboxArr.map((el) => {
        if (Object.entries(el)[0][1]) {
          return (
            <Chip
              sx={{
                background: '#EFF3FA', borderRadius: '4px', width: '70px', height: '24px', padding: '4px 0', marginRight: '5px',
              }}
              key={Object.keys(el)[0]}
              label={Object.keys(el)[0]}
            />
          );
        }
      })
    );

  return (
    <div>
      <SEO
        title="Sigorta Poliçe İptal Talebi | Koalay.com"
        titleSeparator=" "
        titleTemplate=""
        description="Koalay Sigorta Broker’ı üzerinden satın alınmış trafik, kasko ve İMM (ihtiyari mali mesuliyet) sigortalarına ait poliçe iptal işlemlerini yapabilirsiniz."
        siteLanguage="tr-TR"
        siteLocale="tr-TR"
      />
      <Box className="steps-container cancellation-container">
        <div className="cancellation-header">
          <h1>Poliçe İptal Talebi</h1>
          <p>Koalay Sigorta Broker&rsquo;ı üzerinden satın alınmış trafik, kasko ve İMM (ihtiyari mali mesuliyet) sigortalarına ait poliçe iptal işlemlerini aşağıdaki formu doldurarak yapabilirsiniz.</p>
        </div>
        <div className="form-container">
          <form>
            <Grid container className="checkbox-container-desktop">
              <Grid item xs={4}>
                <FormControlLabel
                  style={{ margin: '0', display: 'flex', alignItems: 'flex-start' }}
                  control={(
                    <Checkbox
                      id="desktop-kasko"
                      onChange={() => kaskoChangeHandler()}
                      checked={kasko}
                      sx={{
                        '& svg': { color: kaskoError && '#CC4036' },
                      }}
                    />
                  )}
                  label={(
                    <p className="disclaimer" style={{ marginTop: '6px', color: kaskoError && '#CC4036' }}>
                      Kasko Poliçesi
                    </p>
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  style={{ margin: '0', display: 'flex', alignItems: 'flex-start' }}
                  control={(
                    <Checkbox
                      id="desktop-traffic"
                      onChange={() => trafficChangeHandler()}
                      checked={traffic}
                      sx={{
                        '& svg': { color: trafficError && '#CC4036' },
                      }}
                    />
                  )}
                  label={(
                    <p className="disclaimer" style={{ marginTop: '6px', color: trafficError && '#CC4036' }}>
                      Trafik Poliçesi
                    </p>
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  style={{ margin: '0', display: 'flex', alignItems: 'flex-start' }}
                  control={(
                    <Checkbox
                      id="desktop-imm"
                      onChange={() => immChangeHandler()}
                      checked={imm}
                      sx={{
                        '& svg': { color: immError && '#CC4036' },
                      }}
                    />
                  )}
                  label={(
                    <p className="disclaimer" style={{ marginTop: '6px', color: immError && '#CC4036' }}>
                      İMM Poliçesi
                    </p>
                  )}
                />
              </Grid>
            </Grid>
            <FormControl className="checkbox-container-mobile">
              <InputLabel id="police-seciniz-input-label">
                {mobileCheckboxLabel}
              </InputLabel>
              <Select
                id="police-seciniz-select-box"
                label-id="police-seciniz"
                label={(kasko || traffic || imm)
                  ? '----------'
                  : 'Poliçe Seçiniz'}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                  },
                }}
                style={{
                  width: '313px',
                }}
              >
                <FormControlLabel
                  style={{ margin: '0', display: 'flex', alignItems: 'flex-start' }}
                  control={(
                    <Checkbox
                      id="mobile-kasko"
                      onChange={() => kaskoChangeHandler()}
                      checked={kasko}
                      sx={{
                        '& svg': { color: kaskoError && '#CC4036' },
                      }}
                    />
                  )}
                  label={(
                    <p className="disclaimer" style={{ marginTop: '6px', color: kaskoError && '#CC4036' }}>
                      Kasko Poliçesi
                    </p>
                  )}
                />
                <FormControlLabel
                  style={{ margin: '0', display: 'flex', alignItems: 'flex-start' }}
                  control={(
                    <Checkbox
                      id="mobile-traffic"
                      onChange={() => trafficChangeHandler()}
                      checked={traffic}
                      sx={{
                        '& svg': { color: trafficError && '#CC4036' },
                      }}
                    />
                  )}
                  label={(
                    <p className="disclaimer" style={{ marginTop: '6px', color: trafficError && '#CC4036' }}>
                      Trafik Poliçesi
                    </p>
                  )}
                />
                <FormControlLabel
                  style={{ margin: '0', display: 'flex', alignItems: 'flex-start' }}
                  control={(
                    <Checkbox
                      id="mobile-imm"
                      onChange={() => immChangeHandler()}
                      checked={imm}
                      sx={{
                        '& svg': { color: immError && '#CC4036' },
                      }}
                    />
                  )}
                  label={(
                    <p className="disclaimer" style={{ marginTop: '6px', color: immError && '#CC4036' }}>
                      İMM Poliçesi
                    </p>
                  )}
                />
              </Select>
            </FormControl>
            <KLInput
              name="tckn"
              control={control}
              label="TCKN veya Vergi Kimlik No"
              value={tckn}
              error={errors}
              mask="99999999999"
              rules={tcknValidation()}
              sx={{
                '& .MuiOutlinedInput-root': {
                  width: isMobile ? '313px' : '100%',
                  '&.Mui-error': {
                    width: isMobile ? '313px' : '100%',
                  },
                },
              }}
              onChanged={(val) => { clearErrors('tckn'); setTckn(val); }}
              touchedFields={touchedFields}
              reset={reset}
            />
            <KLInput
              name="plate"
              control={control}
              label="Plaka"
              value={plate}
              hasCustomMaskFunction
              mask={maskPlate}
              error={errors}
              rules={plateValidation()}
              onChanged={(val) => setPlate(val)}
              touchedFields={touchedFields}
              reset={reset}
              sx={{
                '& .MuiOutlinedInput-root': {
                  width: isMobile ? '313px' : '100%',
                  '&.Mui-error': {
                    width: isMobile ? '313px' : '100%',
                  },
                },
              }}
            />
            <KLInput
              name="filesUploaded"
              control={control}
              label="Noter Satış Sözleşmesi"
              error={errors}
              onChanged={fileChangedHandler}
              touchedFields={touchedFields}
              reset={reset}
              customRef={fileUploadRef}
              type="file"
              multiple="multiple"
              inputProps={{ accept: '.doc,.docx,.jpg,.jpeg,.png,.pdf', multiple: true }}
              InputProps={{
                type: 'file',
                endAdornment:
                  <InputAdornment position="end">
                    {shouldShowCloseIcon
                      ? <img role="button" onClick={() => { setFilesUploaded(null); setFileNames([]); setValue('filesUploaded', ''); clearErrors('filesUploaded'); setError('filesUploaded', { type: 'error', message: 'Dosya seçili değil.' }); setShouldShowCloseIcon(false); }} className="closeIcon" src={close} alt="Var" width={16} height={16} />
                      : <img role="button" onClick={() => { fileUploadRef.current.click(); clearErrors('filesUploaded'); setShouldShowCloseIcon(false); }} className="uploadIcon" src={upload} alt="Var" width={16} height={16} />}
                  </InputAdornment>,
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  width: isMobile ? '313px' : '100%',
                  '& input': {
                    opacity: fileNames.length === 0 ? '0' : '100',
                  },
                  '&.Mui-error': {
                    width: isMobile ? '313px' : '100%',
                  },
                },
              }}
            />
          </form>
          <p className="cancellation-info">
            Form üzerinden
            {' '}
            <b style={{ fontSize: '16px' }}>yalnızca araç satışı sebebiyle</b>
            {' '}
            poliçe işlem talebinde bulunabilirsiniz. Diğer poliçe iptal işlemleriniz için
            {' '}
            <a href="mailto:satis@koalay.com">satis@koalay.com</a>
            {' '}
            adresi üzerinden bizimle iletişime geçebilirsiniz.
          </p>
          <p className="cancellation-info">* Poliçenizin iptali sağlandığında iade tutar, poliçenizi satın aldığınız hesaba/kredi kartına aktarılır.</p>
          <Button
            variant="contained"
            className="submitButton"
            onClick={handleSubmit(submitForm)}
            style={{ textTransform: 'none' }}
            disabled={!isValid || !(kasko || imm || traffic) || !filesUploaded || Object.keys(errors).length !== 0}
          >
            Poliçe İptal Başvurusu Yap
          </Button>
        </div>
      </Box>
    </div>
  );
};

export default withRouter(Cancellation);
