import { Box, Grid } from '@mui/material';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';

import '../../../shared/assets/styles/components/form-box.scss';

export const FormBox = (props) => {
  const { formHeader } = props;

  return (
    <Box className="form-box">
      <h6 className="form-header">{formHeader}</h6>
      {props.children}
    </Box>
  );
};

export default (FormBox);
