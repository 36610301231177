import React from 'react';
import {
  Button,
} from '@mui/material';
import { withRouter } from 'react-router-dom';
import useWindowSize from '../../../../../shared/_hooks/windowResizeHook';
import recoImage from '../../../../../shared/assets/img/svg/Car insurance.svg';

export const PolicyRecommendation = (props) => {
  const size = useWindowSize();
  const isMobile = size.width <= 880;

  const goToSummary = (type) => {
    props.history.push({
      pathname: '/quotation/summary',
      search: `${props.history.location.search.split('&teklifTID')[0]}`,
      state: { qGuid: props.quoteGuid },
    });
  };

  return (
    <>
      <div className="policy-status-reco">
        {!props.policyStatus.hasKaskoPolicy && (
        <>
          <h4>
            Zorunlu trafik sigortanı aldın, şimdi kasko yaptırarak kendini tam korumaya almak ister misin?
          </h4>
          <Button variant="contained" onClick={() => goToSummary('trafik')}>Kasko Teklifleri Al</Button>
        </>
        )}
        {!props.policyStatus.hasTrafficPolicy && (
        <>
          <h4>Kasko poliçeni aldın, şimdi zorunlu trafik sigortanı da yapırmak ister misin?</h4>
          <Button variant="contained" onClick={() => goToSummary('kasko')}>Sigorta Teklifleri Al</Button>
        </>
        )}
        <div className="reco-image">
          <img src={recoImage} className="svg-icon" />
        </div>
      </div>
    </>
  );
};

export default withRouter(PolicyRecommendation);
