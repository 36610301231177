import React, { useState, useEffect } from 'react';
import { UserStates } from '../../../data/userStates';
import { PAYMENT_URL } from '../../../../shared/_helpers/env';
import useUserState from '../../../../shared/_hooks/userStateHook';
import userStateService from '../../../_services/userState.service';

export const Paratika = (props) => {
  const [contentRef, setContentRef] = useState(null);
  const { setUserState } = useUserState();
  const [paratikaObject, setParatikaObject] = useState(null);
  const paymentUrl = PAYMENT_URL + props.token;
  const URL_PARAMS = new URLSearchParams(document.location.search);
  const jt = URL_PARAMS.get('jt') !== null ? URL_PARAMS.get('jt') : props.location.state.jt;

  useEffect((() => {
    userStateService.setUserState({
      UserUID: jt,
      UserStateDefinition: UserStates.Potansiyel_Musteri_Paratika_Uyari_Popup_Goren, // 154
    }, () => {
      userStateService.setUserState({
        UserUID: jt,
        UserStateDefinition: UserStates.Potansiyel_Musteri_Paratika_Uyari_Popup_Vazgecen, // 156
      });
    });

    const paratikatemp = localStorage.getItem('paratika');
    setParatikaObject(JSON.parse(paratikatemp));
  }), []);

  useEffect(() => {
    if (contentRef !== null) {
      contentRef.submit();
    }
  }, [contentRef]);

  return (
    <div id="3d-form-container">
      {paratikaObject !== null && (
      <form target="_self" id="3dForm" action={paymentUrl} method="POST" ref={setContentRef} onSubmit={(e) => e.preventDefault()}>
        <input type="hidden" id="expiryMonth" name="expiryMonth" value={paratikaObject.expiryMonth} />
        <input type="hidden" id="expiryYear" name="expiryYear" value={paratikaObject.expiryYear} />
        <input type="hidden" id="pan" name="pan" value={paratikaObject.pan} />
        <input type="hidden" id="installmentCount" name="installmentCount" value="1" />
        <input type="hidden" id="amount" name="amount" value="1.00" />
        <input type="hidden" id="cvv" name="cvv" value={paratikaObject.cvv} />
        <input type="hidden" id="cardOwner" name="cardOwner" value={paratikaObject.cardOwner} />
      </form>
      )}
    </div>
  );
};
export default Paratika;
