import React, { useState } from 'react';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import StepsBox from '../../components/StepsBox';
import RedirectCCBox from './RedirectCCBox/RedirectCCBox';

export const RedirectCC = (props) => {
  const [eBulletin, setEBulletin] = useState(false);

  return (
    <>
      <StepsBox gap={0}>
        <RedirectCCBox />
      </StepsBox>
    </>
  );
};

export default withRouter(RedirectCC);
