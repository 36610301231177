import React from 'react';

const PlusSvg = (props) => {
	const {className = ''} = props;
	return (
  <svg className={className} width="21" height="20" viewBox="0 0 21 20" fill={props.color} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.5 3.54199C10.8452 3.54199 11.125 3.82181 11.125 4.16699V15.8337C11.125 16.1788 10.8452 16.4587 10.5 16.4587C10.1548 16.4587 9.875 16.1788 9.875 15.8337V4.16699C9.875 3.82181 10.1548 3.54199 10.5 3.54199Z" fill={props.color} />
    <path fillRule="evenodd" clipRule="evenodd" d="M4.04169 10C4.04169 9.65482 4.32151 9.375 4.66669 9.375H16.3334C16.6785 9.375 16.9584 9.65482 16.9584 10C16.9584 10.3452 16.6785 10.625 16.3334 10.625H4.66669C4.32151 10.625 4.04169 10.3452 4.04169 10Z" fill={props.color} />
  </svg>
)};

export default PlusSvg;
