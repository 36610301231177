import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import StepsBox from '../../components/StepsBox';
import FormBox from '../../components/FormBox';
import { CustomerInfoForm } from './form/CustomerInfoForm';
import commonService from '../../../../motor/_services/common.service';
import { genderEnum } from '../../../data/gender';
import informationActions from '../../../../motor/actions/informationAction';
import stepActions from '../../../../motor/actions/stepAction';
import { tssStepDecider } from '../../../../shared/_helpers/stepDecider';
import getQuotes, { stepsEnum } from '../../../apiOperations/getQuotes';
import loadingKlActions from '../../../../motor/actions/loadingActions';
import toastrActions from '../../../../motor/actions/toastrAction';
import { historyPushWithSearch } from '../../../../shared/_helpers/historyPushWithSearch';
import TSS_SEQUENCE from '../../../constants/sequence';
import { removeCustomEventsFromDataLayer } from '../../../../shared/_helpers/helperFunctions';

export const CustomerInfo = (props) => {
  const { insureds, isFamilyFlow } = useSelector((state) => state.info);
  const [occupationList, setOccupationList] = useState([]);
  const [currentInsured, setCurrentInsured] = useState(null);
  const [occupation, setOccupation] = useState(null);
  const [gender, setGender] = useState(1);
  const [pregnancy, setPregnancy] = useState(false);
  const [ongoingInsurance, setOngoingInsurance] = useState(false);
  const [showPregnancy, setShowPregnancy] = useState(false);
  const [userAgreement, setUserAgreement] = useState(currentInsured?.approvedPolicy || false);

  const { step } = useSelector((state) => state.step);

  const dispatch = useDispatch();

  const getTssOccupationList = () => {
    commonService.getTssOccupationList((response) => {
      setOccupationList(response.Result.occupations);
    });
  };

  useEffect(() => {
    if (occupationList.length > 0 && (currentInsured?.occupation || currentInsured?.occupationCode)) {
      const occupationValue = currentInsured.occupation ? currentInsured.occupation.enumValue : currentInsured.occupationCode;
      setOccupation(occupationList.find((occ) => occ.enumValue === occupationValue));
    }
  }, [occupationList]);

  const occupationProps = {
    value: occupation,
    onChange: setOccupation,
    options: occupationList,
    getOptions: getTssOccupationList,
  };

  const userAgreementProps = {
    checked: userAgreement,
    onChange: setUserAgreement,
  };

  const genderProps = {
    value: gender,
    onChange: (value) => setGender(Number(value)),
  };

  const pregnancyProps = {
    value: pregnancy.toString(),
    onChange: (value) => setPregnancy(Boolean(value === 'true')),
  };

  const ongoingInsuranceProps = {
    value: ongoingInsurance.toString(),
    onChange: (value) => setOngoingInsurance(Boolean(value === 'true')),
  };

  useEffect(() => {
    if (gender == genderEnum.Kadın && currentInsured.relation !== 3) {
      setShowPregnancy(true);
    } else {
      setShowPregnancy(false);
    }
  }, [gender]);  
	
	useEffect(() => {
		const currentIns = insureds[insureds.length - 1]
  	setCurrentInsured(currentIns);
		setGender(currentIns?.gender || 1)
		setPregnancy(currentIns?.isPregnant || false)
		setOngoingInsurance(currentIns?.hasExistingPolicy || false)
  }, [insureds]);

  const {
    handleSubmit,
    reset,
    formState: { errors, isValid, touchedFields },
    control,
    setValue,
  } = useForm({
    mode: 'onChange',
  });

  const isFormValid = isValid
  && occupation
  && gender
  && (showPregnancy
    ? (pregnancy === true || pregnancy === false)
    : true)
  && (isFamilyFlow ? true : userAgreement);

  const formHookObject = {
    handleSubmit,
    reset,
    control,
    setValue,
    errors,
    isValid: isFormValid,
    touchedFields,
    showPregnancy,
  };

  //* Redux operations & datalayer pageview
  useEffect(() => {
    getTssOccupationList();
    if (currentInsured && !currentInsured.movingForward && !currentInsured.isFull) {
      dispatch(informationActions.removeInsured(currentInsured));
			getQuotes(dispatch, { step: stepsEnum.TSSPersonalInformation }, () => {
			});
    }

		const { location: { pathname } } = window;
		const stepType = isFamilyFlow ? 'FAMILY' : 'SELF';
		const currentStep = TSS_SEQUENCE[stepType].find((elm) => elm.stepUrl.toLowerCase() === pathname.toLowerCase()).index;
		dispatch(
			stepActions.setStep(currentStep),
		);

    if (!Object.values(window.dataLayer).map((element) => element.event).includes('insurant_info_page')) {
      removeCustomEventsFromDataLayer(window.dataLayer);
      window.dataLayer.push({
        event: 'insurant_info_page',
      });
    }
  }, []);

  const submitForm = (formValues) => {
    if (currentInsured) {
      currentInsured.isFull = true;
      currentInsured.occupation = occupation;
      currentInsured.occupationCode = occupation.enumValue;
      currentInsured.occupationText = occupation.name;
      currentInsured.gender = gender;
      currentInsured.isPregnant = pregnancy;
      currentInsured.hasExistingPolicy = ongoingInsurance;
      currentInsured.movingForward = true;
      currentInsured.height = Number(formValues.height);
      currentInsured.weight = Number(formValues.weight);
      currentInsured.approvedPolicy = true;
      dispatch(
        informationActions.updateInsured(currentInsured),
      );
      // TODO redirect to before-summary page
      dispatch(loadingKlActions.showKlLoadingEmpty());
      getQuotes(dispatch, { step: stepsEnum.TSSPersonalInformation }, (response, hasError) => {
        if (hasError) {
          dispatch(loadingKlActions.hideKlLoading());
          dispatch(
            toastrActions.setToastrMessage({
              variant: 'error',
              message: response.Message || 'Bir hata oluştu',
            }),
          );
        } else {
          console.log(response);
					dispatch(loadingKlActions.hideKlLoading());

          window.dataLayer.push({ funnel_variables: null });
          let insurant_info = [];
          for (let insured of insureds) {
            let obj = {
              gender: insured.gender === 1 ? 'male' : 'female',
              occupation: insured.occupationText
            }
            insurant_info.push(obj);
          }
          window.dataLayer.push({
            event: 'insurant_info_page_continue',
            funnel_variables: {
              insurant_type: insureds.length === 1 ? 'individual ' : 'family',
              insurant_info: insurant_info,
            },
          });

					if(isFamilyFlow){
						tssStepDecider(step +1, props.history, true, { qGuid: response.quoteId });
						dispatch(stepActions.setStep(step +1));
					}else{
						historyPushWithSearch('/tamamlayici-saglik-sigortasi/Summary', props.history,response.quoteId ? { qGuid: response.quoteId } : null)	
					}
        }
      });
    }
    console.log(formValues, occupation, gender, pregnancy);
  };

  const advanceToFamily = (formValues) => {
    if (currentInsured) {
      currentInsured.isFull = true;
      currentInsured.occupation = occupation;
      currentInsured.occupationCode = occupation.enumValue;
      currentInsured.occupationText = occupation.name;
      currentInsured.gender = gender;
      currentInsured.isPregnant = pregnancy;
      currentInsured.hasExistingPolicy = ongoingInsurance;
      currentInsured.movingForward = true;
      currentInsured.height = Number(formValues.height);
      currentInsured.weight = Number(formValues.weight);
      currentInsured.approvedPolicy = true;
      dispatch(
        informationActions.updateInsured(currentInsured),
      );
      dispatch(
        informationActions.setIsFamilyFlow(true),
      );
      dispatch(stepActions.setStep(1));

      dispatch(loadingKlActions.showKlLoadingEmpty());
      getQuotes(dispatch, { step: stepsEnum.TSSPersonalInformation }, (response, hasError) => {
        if (hasError) {
          dispatch(loadingKlActions.hideKlLoading());
          dispatch(
            toastrActions.setToastrMessage({
              variant: 'error',
              message: response.Message || 'Bir hata oluştu',
            }),
          );
        } else {
          console.log(response);
          dispatch(loadingKlActions.hideKlLoading());
          tssStepDecider(1, props.history, true, { qGuid: response.quoteId });
        }
      });
    }
    window.dataLayer.push({ funnel_variables: null });
    window.dataLayer.push({
      event: 'insurant_info_page_continue',
      funnel_variables: {
        insurant_type: 'family',
      },
    });
  };

  return (
    currentInsured ? (
    <>
      <StepsBox>
        <FormBox formHeader="Kişisel Bilgiler">
          <CustomerInfoForm
            formHookObject={formHookObject}
            submitForm={submitForm}
            occupationProps={occupationProps}
            genderProps={genderProps}
            pregnancyProps={pregnancyProps}
            ongoingInsuranceProps={ongoingInsuranceProps}
            userAgreementProps={userAgreementProps}
            advanceToFamily={advanceToFamily}
          />
        </FormBox>
      </StepsBox>
    </>
    ) : (
			''
		)
  );
};

export default withRouter(CustomerInfo);
