import React, { useState, useEffect } from 'react';
import {
  FormControl, FormControlLabel, Radio, RadioGroup,
} from '@mui/material';

import '../../../../../shared/assets/styles/components/installment.scss';
import 'react-credit-cards/es/styles-compiled.css';
import useWindowSize from '../../../../../shared/_hooks/windowResizeHook';
import infoBlue from '../../../../../shared/assets/img/svg/infoBlue.svg';
import { priceFormatter } from '../../../../../shared/_helpers/helperFunctions';

export const Installment = (props) => {
  const size = useWindowSize();
  const isMobile = size.width <= 880;
  const [isCrossSell, setIsCrossSell] = useState(false);
  const handleChange = (event) => {
    props.selectInstallment(event.target.value, props.productId);
  };
  useEffect(() => {
    if (props.cartData.find((i) => i.productId === props.productId).isCrossSell) {
      setIsCrossSell(true);
    }
  }, []);

  return (
    <>
      {props.installments && props.installments.paymentPlan.length > 1 ? (
        <div className="installments">
          <FormControl className="installment-buttons">
            <RadioGroup name="radio-buttons-group" onChange={handleChange} value={isCrossSell ? props.selectedCrossInstallment : props.selectedInstallment}>
              {props.installments.paymentPlan.map(
                (inst) => !inst.disabled && (
                <div className="installment">
                  <FormControlLabel
                    value={inst.paymentPlanId}
                    control={<Radio />}
                    label={inst.description}
                    id={inst.paymentPlanId}
                    key={inst.paymentPlanId}
                  />
                  {inst.paymentPlanId === 1 && (
                  <>
                    <p className={!isMobile ? 'disclaimer' : 'assistive'}>
                      {priceFormatter.format(props.premium).replace('₺', '')}
                      {' '}
                      TL
                    </p>
                    <p className={!isMobile ? 'disclaimer' : 'assistive'}>
                      {priceFormatter.format(props.premium).replace('₺', '')}
                      {' '}
                      TL
                    </p>
                  </>
                  )}
                  {inst.paymentPlanId !== 1 && inst.installmentPlan !== undefined && (
                  <>
                    <p className={!isMobile ? 'disclaimer' : 'assistive'}>
                      {inst.installmentPlan[0].Amount ? priceFormatter.format(inst.installmentPlan[0].Amount).replace('₺', '') : priceFormatter.format(inst.installmentPlan[0].amount).replace('₺', '')}
                      {' '}
                      TL
                    </p>
                    <p className={!isMobile ? 'disclaimer' : 'assistive'}>
                      {priceFormatter.format(inst.Premium).replace('₺', '')}
                      {' '}
                      TL
                    </p>
                  </>
                  )}
                </div>
                ),
              )}
            </RadioGroup>
          </FormControl>
        </div>
      ) : !props.hasCrossSell ? (
        <div className="installment-info">
          <img src={infoBlue} className="svg-icon" />
          <p className="disclaimer">Kartınız için taksit seçeneği bulunmamaktadır </p>
        </div>
      ) : (
        <div className="installment">
          <FormControlLabel control={<Radio checked />} label="Peşin" />
          <p className={!isMobile ? 'disclaimer' : 'assistive'}>
            {priceFormatter.format(props.premium).replace('₺', '')}
            {' '}
            TL
          </p>
          <p className={!isMobile ? 'disclaimer' : 'assistive'}>
            {priceFormatter.format(props.premium).replace('₺', '')}
            TL
          </p>
        </div>
      )}
    </>
  );
};

export default Installment;
