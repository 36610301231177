import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  FormControlLabel,
  Modal,
  Radio,
} from '@mui/material';
import { useForm } from 'react-hook-form';

import { withRouter } from 'react-router-dom';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useDispatch, useSelector } from 'react-redux';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Frame from 'react-frame-component';
import Paratika from '../../components/widget/Paratika';
import useWindowSize from '../../../../shared/_hooks/windowResizeHook';
import { KLInput } from '../../components/form/KLInput';
import '../../../../shared/assets/styles/pages/checkout.scss';
import Steps from '../../components/Steps';
import SafetySVG from '../../../../shared/assets/img/svg/safety.svg';
import issueService from '../../../_services/issue.service';
import { WS } from '../../../../shared/_helpers/env';
import {
  getUsableLogo, openPDF, priceFormatter, removeCustomEventsFromDataLayer,
} from '../../../../shared/_helpers/helperFunctions';
import Installment from './components/Installment';
import infoBlue from '../../../../shared/assets/img/svg/infoBlue.svg';
import closeSVG from '../../../../shared/assets/img/svg/delete.svg';
import editSVG from '../../../../shared/assets/img/svg/edit.svg';
import secureCard from '../../../../shared/assets/img/svg/Card Security.svg';
import DainiMurtehin from './components/DainiMurtehin';
import commonService from '../../../_services/common.service';
import CenteredModal from '../../components/widget/CenteredModal';
import EventBus from '../../../../shared/_helpers/EventBus';
import stepActions from '../../../actions/stepAction';
import CrossSell from './components/CrossSell';
import paymentService from '../../../_services/payment.service';
import quotationService from '../../../_services/quotation.service';
import toastrActions from '../../../actions/toastrAction';
import loadingKlActions from '../../../actions/loadingActions';
import { UserStates } from '../../../data/userStates';
import useUserState from '../../../../shared/_hooks/userStateHook';
import PriceDiffModal from './components/PriceDiffModal';
import {
  atLeastTwoWord, betweenLength, exactLength,
} from '../../../../shared/_helpers/validations';
import SecurePaymentWarning from './components/SecurePaymentWarning';
import CrossSellModal from './components/CrossSellModal';
import HasFailAtIssue from '../../components/widget/HasFailAtIssue';
import { useRollbar } from '@rollbar/react';
import CardOwnerIdentity from './components/CardOwnerIdentity';
import headerActions from '../../../actions/headerAction';
import shellActions from '../../../actions/shellAction';

const StompJs = require('@stomp/stompjs');

export const Checkout = (props) => {
  const size = useWindowSize();
  const Rollbar = useRollbar();
  const isMobile = size.width <= 880;
  const URL_PARAMS = new URLSearchParams(document.location.search);
  const jt = URL_PARAMS.get('jt') !== null ? URL_PARAMS.get('jt') : props.location.state.jt;
  const quoteGuid = URL_PARAMS.get('qGuid') !== null ? URL_PARAMS.get('qGuid') : props.location.state.quoteGuid;
  const teklifTID = URL_PARAMS.get('teklifTID') !== null ? URL_PARAMS.get('teklifTID') : props.location.state.teklifTID;
  const bransType = URL_PARAMS.get('bransType') !== null ? URL_PARAMS.get('bransType') : props.location.state.bransType;
  const agentCode = URL_PARAMS.get('agentCode') !== null ? URL_PARAMS.get('agentCode')
    : localStorage.getItem('agentCode') ? localStorage.getItem('agentCode')
      : '';
  const { isCallCenter } = useSelector((state) => state.shell);

  const [cvc, setCvc] = useState('');
  const [expiry, setExpiry] = useState('');
  const [focus, setFocused] = useState('');
  const [name, setName] = useState('');
  const [number, setNumber] = useState('');

  const [mainInstallmentCollapse, setMainInstallmentCollapse] = useState(true);
  const [securePaymentMore, setSecurePaymentMore] = useState(false);
  const [crossQuotationCollapse, setCrossQuotationCollapse] = useState(true);
  const [dainiMurtehin, setDainiMurtehin] = useState(false);
  const [securePaymentWarning, setSecurePaymentWarning] = useState(false);
  const [priceDiffModal, setPriceDiffModal] = useState(false);
  const [priceDiffData, setPriceDiffData] = useState([]);
  const [bankList, setBankList] = useState(null);
  const [branchList, setBranchList] = useState(null);
  const [paymentData, setPaymentData] = useState(null);
  const [selectedInstallment, setSelectedInstallment] = useState(1);
  const [selectedCrossInstallment, setSelectedCrossInstallment] = useState(1);
  const [selectedKurum, setSelectedKurum] = useState('');
  const [selectedDaini, setSelectedDaini] = useState();
  const [showVknLossPayee, setShowVknLossPayee] = useState(false);

  const [hasCrossSellAdded, setHasCrossSellAdded] = useState(false);
  const [policyRead, setPolicyRead] = useState(false);
  const [isCrossSellModalOpen, setIsCrossSellModalOpen] = useState(false);
  const [isCrossSellDetailModalOpen, setIsCrossSellDetailModalOpen] = useState(false);
  const [cartData, setCartData] = useState([]);
  const [cartTotal, setCartTotal] = useState(0);
  const [newPremium, setNewPremium] = useState(null);

  const [hasFailAtIssue, setHasFailAtIssue] = useState(false);
  const [isResponseCame, setIsResponseCame] = useState(false);
  const [cardOwnerIdentity, setCardOwnerIdentity] = useState(true);
  const [identityNo, setIdentityNo] = useState(null);
  const [cardOwnershipVisible, setCardOwnershipVisible] = useState(false);

  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [paratikaObject, setParatikaObject] = useState(null);
  const [dealerInfo, setDealerInfo] = useState(null);
  const { setUserState } = useUserState();

  const dispatch = useDispatch();
  let queueName;
  let interval;
  const GenerateQueueGuid = () => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

  const client = new StompJs.Client({
    brokerURL: WS,
    connectHeaders: {
      login: 'koalay',
      passcode: 'koalay',
    },
    heartbeatIncoming: 5000,
    heartbeatOutgoing: 5000,
    reconnectDelay: 0,
  });
  const headers = {
    'auto-delete': true,
    'x-expires': 3600000,
  };

  useEffect(() => {
    if (!dainiMurtehin) {
      setBranchList(null);
    }
  }, [dainiMurtehin]);

  const {
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isValid, touchedFields },
    control,
  } = useForm({
    mode: 'onChange',
  });

  const handleBranchList = (parentId) => {
    commonService.getLossPayee(paymentData.productId, parentId, (resp) => {
      setBranchList(resp.lossPayees);
    });
  };

  const goToSummary = () => {
    const state = { ...props.location.state };
    delete state.teklifTID;
    props.history.push({
      pathname: '/quotation/summary',
      search: `${props.history.location.search.split('&teklifTID')[0]}`,
      state: { ...state, qGuid: quoteGuid },
    });
  };
  const priceDiffBuyAction = (newTeklifTID) => {
    const params = new URLSearchParams(props.location.search);
    params.set('teklifTID', newTeklifTID);
    window.location.search = params.toString();
  };

  const goToConfirmation = () => {
    props.history.push({
      pathname: '/quotation/confirmation',
      search: `${props.history.location.search}`,
      state: { quoteGuid, teklifTID },
    });
  };

  const prepareInstallments = (inst) => {
    const tempCart = [...cartData];
    if (inst.Installments && inst.Installments.length > 0) { // without this, this one fails after selecting 'Pesin' again
      tempCart
        .find((i) => i.productId === inst.ProductId)
        .installmentShow.paymentPlan.find((pp) => pp.paymentPlanId === inst.Installments.length).installmentPlan = inst.Installments;
      tempCart
        .find((i) => i.productId === inst.ProductId)
        .installmentShow.paymentPlan.find((pp) => pp.paymentPlanId === inst.Installments.length).Premium = inst.Premium;
    }
    setCartData(tempCart);
  };

  const subscribeToSocket = () => {
    client.activate();
    client.onConnect = function (frame) {
      // Do something, all subscribes must be done is this callback
      // This is needed because this will be executed after a (re)connect
      client.subscribe(
        queueName,
        (message) => {
          if (message && message.body !== '') {
            const body = JSON.parse(message.body);
            body.map((b) => {
              if (b.MessageId === '') {
                if (b.Installments.length == 1 && b.Insurer != 'NEOVA') {
                  setNewPremium(cartData[0].premium); // Cunku taksitten sonra pesin secince, pesinin degeri socketten yanlis donuyor? :DDD
                } else if (b.Insurer != 'NEOVA') {
                  setNewPremium(b.Premium); // replace original premium with value of installment
                }
                prepareInstallments(b);
              } else {
                dispatch(toastrActions.setToastrMessage({ variant: 'error', message: b.MessageId }));
              }
              dispatch(loadingKlActions.hideKlLoading());
            });
          }
        },
        headers,
      );
    };
  };
  const getInstallmentOptions = (cartItem) => {
    const tempCart = [...cartData];
    if (!cartItem.installmentShow && number.length >= 8) {
      const installmentObject = {
        AppliedLevel: paymentData.appliedLevel,
        Bin: number.slice(0, 8),
        IdentityType: '1',
        ProductId: cartItem.productId,
        QuoteId: quoteGuid,
        TotalAmount: cartItem.premium,
        VehicleTypeCode: '01',
      };
      setLoading(true);
      paymentService.getPaymentPlan(installmentObject, (response) => {
        if (cartItem.installmentOptions !== undefined) {
          const pay = cartItem.installmentOptions.map((obj) => response.paymentPlan.filter((s) => s.paymentPlanId === obj.paymentPlanId));
          tempCart.find((i) => i.productId === cartItem.productId).installmentShow = { paymentPlan: [] };
          // setPaymentPlan
          setLoading(false);
          const uniqueIds = [];
          const unique = pay.filter((element) => {
            if (element !== null && element.length > 0) {
              const isDuplicate = uniqueIds.includes(element[0].paymentPlanId);
              if (!isDuplicate) {
                uniqueIds.push(element[0].paymentPlanId);
                return true;
              }
              return false;
            }
          });
          unique.map((p, index) => {
            tempCart.find((i) => i.productId === cartItem.productId).installmentShow.paymentPlan.push(...p);
            if (
              index > 1
              && tempCart.find((i) => i.productId === cartItem.productId).maxPaymentPlanIdForProduct > 1
              && pay[index].length !== 0
            ) {
              tempCart.find((i) => i.productId === cartItem.productId).isCash = false;
            }
          });
          setCartData(tempCart);
        }
      });
    }
  };
  const addCrossSellToBasket = (productId, removedCrossId = null) => {
    const crossItems = [];
    paymentData.crossQuotations.map((item) => {
      if (item.productId === productId) {
        crossItems.push({
          productId: item.productId,
          premium: item.grossPremium,
          installmentOptions: item.installmentOptions,
          insurerCommercialName: item.insurerCommercialName,
          logoName: item.logoName,
          teklifTId: item.teklifTId,
          subTitle: item.productName,
          packageName: item.subtitle,
          installmentShow: null,
          isCrossSell: true,
          isCash: true,
          maxPaymentPlanIdForProduct: item.paymentPlans.length,
        });
      }
    });
    setCartData((old) => [...old.filter((item) => item.productId !== removedCrossId), ...crossItems]);
    setHasCrossSellAdded(productId);
  };
  const removeCrossSellFromBasket = () => {
    const tempCart = cartData;

    setCartData(tempCart.filter((item) => !item.isCrossSell));
  };

  const setCardValue = (Info) => {
    const { CardNo, Cvv, Expiration } = Info.CardInfo;
    setNumber(CardNo);
    setValue('number', CardNo);
    setCvc(Cvv);
    setValue('cvc', Cvv);
    setExpiry(Expiration);
    setValue('expiry', Expiration);
  };

  const subscribeToCCIvrSocket = () => {
    const ccQueueName = `ccagent.ivr.queue_${agentCode}`;
    client.activate();
    client.onConnect = function (frame) {
      // Do something, all subscribes must be done is this callback
      // This is needed because this will be executed after a (re)connect
      client.subscribe(
        ccQueueName,
        (message) => {
          if (message && message.body !== '') {
            const body = JSON.parse(message.body);
            setCardValue(JSON.parse(body.Value));
          }
        },
        {},
      );
    };
  };

  useEffect(() => {
    if (isCallCenter) {
      subscribeToCCIvrSocket();
    }
    removeCustomEventsFromDataLayer(window.dataLayer);
    window.dataLayer.push({
      event: 'checkout_page',
      funnel_variables: {
        jt,
        qGuid: quoteGuid,
        selected_item: JSON.parse(localStorage.getItem('selectedOffer')),
      },
    });
    dispatch(shellActions.setThemeByPage(true))
    dispatch(headerActions.setUseStepper(true));
    const userSateData = {
      UserUID: jt,
      UserStateDefinition:
        bransType === 'Trafik'
          ? UserStates.Potansiyel_Musteri_Trafik_Odeme_Sayfasinda_Siteden_Ayrilan
          : bransType === 'Kasko'
            ? UserStates.Potansiyel_Musteri_Kasko_Odeme_Sayfasinda_Siteden_Ayrilan
            : '',
    };
    setUserState(userSateData);
		dispatch(headerActions.setUseHeader(true));
  }, []);

  useEffect(() => {
    setLoading(false);
    cartData.map((item) => {
      getInstallmentOptions(item);
    });
  }, [cartData]);

  const selectInstallment = (paymentPlanId, productId) => {
    dispatch(loadingKlActions.showKlLoadingEmpty());
    queueName = `KoalayWebQuotation-${quoteGuid}-${GenerateQueueGuid()}`;
    const quoteModel = {
      BranchType: 1,
      PaymentPlanId: parseInt(paymentPlanId),
      ProductId: productId,
      QueueName: queueName,
      QuotationUId: quoteGuid,
    };
    subscribeToSocket();
    if (cartData.find((i) => i.productId === productId).isCrossSell) {
      setSelectedCrossInstallment(Number(paymentPlanId));
    } else {
      setSelectedInstallment(Number(paymentPlanId));
    }
    quotationService.quote(quoteModel, () => {
    });
  };

  const getDisclosurePdf = (tekliftid, e) => {
    e.preventDefault();
    openPDF(tekliftid, 'disclosure', quoteGuid);
  };

  const handleInputFocus = (e) => {
    setFocused(e.target.name);
  };

  const handleCardPaste = () => {
    setSelectedInstallment(1);
    setSelectedCrossInstallment(1);
    cartData.map((cart) => {
      delete cart.installmentShow;
      cart.isCash = true;
    });
  };

  const handleCardNumberChange = (val) => {
    setNumber((prevNumber) => {
      if (prevNumber.slice(0, 6) !== val.replace(/\s/g, '').slice(0, 6)) {
        handleCardPaste();
      }
      return val.replace(/\s/g, '');
    });
  };

  useEffect(() => {
    if (number.length > 7) {
      cartData.map((item) => {
        getInstallmentOptions(item);
      });
    }
    if (number.length < 8) {
      setSelectedInstallment(1);
      setSelectedCrossInstallment(1);
      cartData.map((cart) => {
        delete cart.installmentShow;
        cart.isCash = true;
      });
    }
  }, [number]);
  useEffect(() => {
    if (hasCrossSellAdded) {
      let total = 0;
      cartData.map((item) => {
        if (!item.isCrossSell) {
          if (newPremium) {
            total += newPremium;
          } else {
            total += item.premium;
          }
        } else {
          total += item.premium;
        }
      });
      setCartTotal(total);
    }
  }, [hasCrossSellAdded, newPremium]);

  useEffect(() => {
    dispatch(loadingKlActions.showKlLoadingEmpty());
    EventBus.on('closeDaini', () => {
      setDainiMurtehin(false);
    });
    EventBus.on('closePriceDiff', () => {
      setPriceDiffModal(false);
    });
    dispatch(stepActions.setStep(4));
    issueService.getCheckoutDetail(quoteGuid, teklifTID, (response) => {
      setIdentityNo(response.payment.tcKimlikVergiNo);
      setCardOwnershipVisible(response.payment.creditCardOwnershipVisible);
      const blacklistObject = {
        Email: response.payment.eMail,
        PhoneNumber: response.payment.telNo,
        TcKimlikVergiNo: response.payment.tcKimlikVergiNo,
        UserUid: response.payment.userUid,
        IpNumber: response.payment.ipNumber,
        Sbnkod: null,
        BlackListPage: 0,
      };
      setDealerInfo({
        dealerName: response.payment.dealerName,
        dealerCode: response.payment.subDealerCode,
      });
      commonService.blacklist(blacklistObject, (blResponse) => {
        if (blResponse.code === '0') {
          setPaymentData(response.payment);
          setShowVknLossPayee(response.payment
            .branchIdentityNoVisible || response.payment
            .fcIdentityNoVisible);
          commonService.getLossPayee(response.payment.productId, null, (resp) => {
            setBankList(resp.lossPayees);
          });
          setCartData([
            {
              productId: response.payment.productId,
              premium: response.payment.premium,
              installmentOptions: response.payment.installmentOptions,
              insurerCommercialName: response.payment.insurerCommercialName,
              logoName: response.payment.logoName,
              subTitle: response.payment.subTitle,
              installmentShow: null,
              isCash: true,
              maxPaymentPlanIdForProduct: response.payment.maxPaymentPlanIdForProduct[0],
            },
          ]);
          dispatch(loadingKlActions.hideKlLoading());
        } else {
          dispatch(toastrActions.setToastrMessage({ variant: 'error', message: blResponse.msg }));
          dispatch(loadingKlActions.showKlLoadingEmpty());
        }
      });
    });
    reset();
  }, []);

  const blurCard = () => {
    setFocused('name');
  };

  // #region  purchase

  const issueResponseActions = (result) => {
    dispatch(loadingKlActions.hideKlLoading());
    switch (result.code) {
      case '0':
        setIsResponseCame(true);
        props.history.push({
          pathname: '/quotation/thankyou',
          search: `${props.history.location.search.split('&teklifTID')[0]}&teklifTID=${result.teklifTId}`,
          state: { teklifTID: result.teklifTId, bransType, quoteGuid },
        });
        break;
      case '101':
        setIsResponseCame(true);
        dispatch(toastrActions.setToastrMessage({ variant: 'success', message: result.msg }));
        props.history.push({
          pathname: '/quotation/thankyou',
          search: `${props.history.location.search.split('&teklifTID')[0]}&teklifTID=${result.teklifTId}`,
          state: { teklifTID: result.teklifTId, bransType, quoteGuid },
        });
        break;
      case '104':
        setIsResponseCame(true);
        setPriceDiffData(result);
        setPriceDiffModal(true);
        break;
      default:
        dispatch(toastrActions.setToastrMessage({ variant: 'error', message: result.msg }));
        break;
    }
  };

  const checkIssueStatus = (qName, showModal = true, callback = null) => {
    issueService.getIssueStatus(qName, (resonse, isSuccess) => {
      if (resonse.code === null && isSuccess) {
        if (showModal) {
          setHasFailAtIssue(true);
        }
      } else if (isSuccess) {
        issueResponseActions(resonse);
        if (callback) {
          callback();
        }
      } else {
        // Rollbar.info(`issueStatusCodeError jt:${jt} qGuid:${quoteGuid}`);
      }
    });
  };

  const intervalFunc = async (qName) => {
    let waitedtime = 0;
    interval = setInterval(() => {
      if (isResponseCame) {
        clearInterval(interval);
      }
      if (waitedtime === 60) {
        checkIssueStatus(qName, true, () => clearInterval(interval));
        dispatch(loadingKlActions.hideKlLoading());
        clearInterval(interval);
      } else if (waitedtime === 20) {
        dispatch(toastrActions.setToastrMessage({ variant: 'info', message: 'İşleminiz tahminimizden uzun sürüyor. Lütfen bekleyiniz.' }));
        checkIssueStatus(qName, false, () => clearInterval(interval));
      }
      waitedtime += 1;
    }, 1000);
  };

  const subscribeToIssue = (name) => {
    queueName = name;
    client.activate();
    intervalFunc(queueName);

    client.onConnect = function (frame) {
      // Do something, all subscribes must be done is this callback
      // This is needed because this will be executed after a (re)connect
      client.subscribe(
        queueName,
        (message) => {
          if (message && message.body !== '') {
            setIsResponseCame(true);
            const body = JSON.parse(JSON.parse(message.body));
            const result = body.paymentResponses[0];
            const { code, msg } = result;
            if (code !== '0' || code !== '200') {
              // Rollbar.info(`issueSocketCodeError jt:${jt} qGuid:${quoteGuid} msg:${msg}`);
              checkIssueStatus(queueName);
            }
            issueResponseActions(result);
            dispatch(loadingKlActions.hideKlLoading());
          }
        },
        headers,
      );
    };

    client.onStompError(() => {
      // Rollbar.info(`issueSocketStompError jt:${jt} qGuid:${quoteGuid}`);
    });

    client.onWebSocketError(() => {
      // Rollbar.info(`issueSocketError jt:${jt} qGuid:${quoteGuid}`);
    });

    client.onWebSocketClose(() => {
      // Rollbar.info(`issueSocketClose jt:${jt} qGuid:${quoteGuid}`);
    });
  };

  const issueAsync = async (queueName) => {
    dispatch(loadingKlActions.showKlLoadingEmpty());
    const issueObject = {
      BranchType: paymentData.branchType,
      CardNo: number,
      CardOwner: name,
      CrossTeklifTInstallment:
        cartData.length > 1
          ? [{ TeklifTId: parseInt(cartData[1].teklifTId), Installment: parseInt(selectedCrossInstallment) }]
          : [],
      CurrentQuoteId: quoteGuid,
      Cvc2: cvc,
      DealerName: null,
      ExpMonth: expiry.split('/')[0],
      ExpYear: `20${expiry.split('/')[1]}`,
      IdentityNumber: null,
      PaymentPlanId: parseInt(selectedInstallment),
      Premium:
        newPremium ? parseFloat(newPremium).toFixed(2) : parseFloat(paymentData.premium).toFixed(2),
      ProductId: parseInt(paymentData.productId),
      QueueName: queueName,
      SubDealerCode: null,
      TaksitSayi: selectInstallment,
      TeklifNo: paymentData.teklifNo,
      TeklifTId: parseInt(teklifTID),
      VehicleStatus: parseInt(paymentData.vehicleStatus),
    };
    if (typeof cardOwnerIdentity === 'string') {
      issueObject.CreditCardOwner = {
        IdentityNumber: cardOwnerIdentity,
      };
    } else if (cardOwnershipVisible) {
      issueObject.CreditCardOwner = {
        IdentityNumber: identityNo,
      };
    }
    if (selectedDaini) {
      issueObject.LossPayeeInfo = selectedDaini;
    }
    const blacklistObject = {
      Email: paymentData.eMail,
      PhoneNumber: paymentData.telNo,
      TcKimlikVergiNo: paymentData.tcKimlikVergiNo,
      UserUid: paymentData.userUid,
      IpNumber: paymentData.ipNumber,
      Sbnkod: null,
      BlackListPage: 0,
    };
    commonService.blacklist(blacklistObject, (blResponse) => {
      if (blResponse.code === '0') {
        issueService.issueAsync(issueObject, (resp) => {
          if (resp.Message) {
            setIsResponseCame(true);
            clearInterval(interval);
            dispatch(toastrActions.setToastrMessage({ variant: 'error', message: resp.Message }));
            dispatch(loadingKlActions.hideKlLoading());
          }
        });
      } else {
        dispatch(toastrActions.setToastrMessage({ variant: 'error', message: blResponse.msg }));
        dispatch(loadingKlActions.showKlLoadingEmpty());
      }
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const subscribeToParatika = (prefix) => {
    queueName = `${prefix}${quoteGuid}`;
    client.activate();
    client.onConnect = function (frame) {
      // Do something, all subscribes must be done is this callback
      // This is needed because this will be executed after a (re)connect
      client.subscribe(
        queueName,
        (message) => {
          if (message && message.body !== '') {
            client.forceDisconnect();
            document.querySelectorAll('[role=presentation]')[0].getElementsByTagName('iframe')[0]?.remove();
            const userSateData = {
              UserUID: jt,
              UserStateDefinition: UserStates.Potansiyel_Musteri_Paratika_Uyari_Popup_Onaylayan,
            };
            setUserState(userSateData);
            const body = JSON.parse(message.body);
            setTimeout(() => {
              if (body.ErrorMessage === '') {
                setIsResponseCame(false);
                window.dataLayer.push({
                  event: 'paratika_response',
                  funnel_variables: {
                    paratika_success: true,
                    jt,
                    qGuid: quoteGuid,
                    selected_item: JSON.parse(localStorage.getItem('selectedOffer')),
                  },
                });
                queueName = `KoalayWebIssue-${quoteGuid}-${GenerateQueueGuid()}`;
                subscribeToIssue(queueName);
                issueAsync(queueName);
                setToken(null);
              } else {
                window.dataLayer.push({
                  event: 'paratika_response',
                  funnel_variables: {
                    paratika_success: false,
                    error_message: body.ErrorMessage,
                    jt,
                    qGuid: quoteGuid,
                    selected_item: JSON.parse(localStorage.getItem('selectedOffer')),
                  },
                });
                dispatch(toastrActions.setToastrMessage({ variant: 'error', message: body.ErrorMessage }));
                setToken(null);
              }
            }, 1000);
          }
        },
        headers,
      );
    };
  };

  const handleSecurePayment = () => {
    dispatch(loadingKlActions.showKlLoadingEmpty());
    setSecurePaymentWarning(false);
    const paratika = {
      quoteId: quoteGuid,
      teklifTId: teklifTID,
      paymentPageUrl: `${document.location.origin}/quotation/PaymentPopup/Index?jt=${jt}`,
      branchType: 2,
      paymentContextInfo: {
        expiryMonth: expiry.split('/')[0],
        expiryYear: `20${expiry.split('/')[1]}`,
        pan: number,
        cvv: cvc,
        cardOwner: name,
        domainName: '',
      },
    };

    paymentService.getSessionToken(paratika, (response) => {
      setToken(response.data.Result.token);
      dispatch(loadingKlActions.hideKlLoading());
    });

    setParatikaObject({
      cardOwner: name,
      pan: number,
      expiryMonth: expiry.split('/')[0],
      expiryYear: `20${expiry.split('/')[1]}`,
      cvv: cvc,
      quoteId: quoteGuid,
      teklifTId: teklifTID,
    });
    localStorage.setItem(
      'paratika',
      JSON.stringify({
        cardOwner: name,
        pan: number,
        expiryMonth: expiry.split('/')[0],
        expiryYear: `20${expiry.split('/')[1]}`,
        cvv: cvc,
        quoteId: quoteGuid,
        teklifTId: teklifTID,
      }),
    );
  };

  const purchase = () => {
    window.dataLayer.push({ funnel_variables: null });
    window.dataLayer.push({
      event: 'payment_action',
      funnel_variables: {
        x_sell: hasCrossSellAdded,
        installment_month: selectedInstallment,
        jt,
        qGuid: quoteGuid,
        selected_item: JSON.parse(localStorage.getItem('selectedOffer')),
      },
    });
    const userSateData = {
      UserUID: jt,
      UserStateDefinition:
        bransType === 'Trafik'
          ? UserStates.Potansiyel_Musteri_Trafik_Odeme_Butonunu_Tiklayan
          : bransType === 'Kasko'
            ? UserStates.Potansiyel_Musteri_Kasko_Odeme_Butonunu_Tiklayan
            : '',
    };
    setUserState(userSateData);
    if (paymentData.securePaymentInfo.needsSecurePayment) {
      setSecurePaymentWarning(true);
    } else {
      setIsResponseCame(false);
      queueName = `KoalayWebIssue-${quoteGuid}-${GenerateQueueGuid()}`;
      subscribeToIssue(queueName);
      issueAsync(queueName);
    }
  };

  useEffect(() => {
    if (paratikaObject && token) {
      window.dataLayer.push({
        event: 'paratika_open',
        funnel_variables: {
          jt,
          qGuid: quoteGuid,
          selected_item: JSON.parse(localStorage.getItem('selectedOffer')),
        },
      });
    }
  }, [jt, paratikaObject, quoteGuid, token]);

  useEffect(() => {
    if (token !== null) {
      subscribeToParatika('KoalayParatikaResult_');
    }
  }, [subscribeToParatika, token]);
  // #endregion
  return (
    <>
      {paymentData && cartData.length > 0 && (
        <div className="checkout" style={{ marginTop: hasCrossSellAdded && isMobile ? '125px' : '' }}>
          {isMobile && <Steps className="steps" isHidden={false} goBack={goToConfirmation} />}
          <div className="payment">
            <div className="payment-left">
              <Box className="steps-container card-container">
                <div className="payment-header">
                  <h2>Kart Bilgileri</h2>
                  <div>
                    <div style={{ alignItems: 'center', display: 'flex', margin: '10px 0 8px 0' }}>
                      <img src={SafetySVG} alt="" width={!isMobile ? 32 : 20} />
                      <p className="text">Güvenli Ödeme</p>
                    </div>
                  </div>
                </div>
                <div className="card-info">
                  <form>
                    <KLInput
                      name="name"
                      control={control}
                      label="Kart Üzerinde Yazan İsim"
                      value={name}
                      onFocused={handleInputFocus}
                      error={errors}
                      onBlured={blurCard}
                      onChanged={setName}
                      touchedFields={touchedFields}
                      rules={atLeastTwoWord()}
                      reset={reset}
                      autoComplete="off"
                    />
                    <KLInput
                      name="number"
                      control={control}
                      label="Kart Numarası"
                      value={number}
                      mask="9999 9999 9999 9999"
                      error={errors}
                      onBlured={blurCard}
                      onChanged={(val) => handleCardNumberChange(val)}
                      onFocused={handleInputFocus}
                      touchedFields={touchedFields}
                      rules={exactLength(19)}
                      reset={reset}
                      autoComplete="off"
                      defaultValue={number}
                    />
                    <span
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '3fr 1.5fr',
                        width: isMobile ? '100%' : '254px',
                        gap: isMobile ? '0' : '0 15px',
                      }}
                    >
                      <KLInput
                        name="expiry"
                        control={control}
                        label="Son Kullanma Tarihi"
                        placeholder="AA / YY"
                        mask="99/99"
                        value={expiry}
                        onFocused={handleInputFocus}
                        error={errors}
                        onBlured={blurCard}
                        onChanged={setExpiry}
                        touchedFields={touchedFields}
                        rules={exactLength(5)}
                        reset={reset}
                        autoComplete="off"
                        defaultValue={expiry}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            width: isMobile ? '95%' : '160px',
                            '&.Mui-error': {
                              width: isMobile ? '95%' : '160px',
                            },
                          },
                          '.MuiInputLabel-formControl': {
                            fontSize: '15px',
                          },
                        }}
                      />
                      <KLInput
                        name="cvc"
                        control={control}
                        label="CVV"
                        error={errors}
                        mask="999"
                        value={cvc}
                        onFocused={handleInputFocus}
                        onChanged={setCvc}
                        onBlured={blurCard}
                        touchedFields={touchedFields}
                        rules={betweenLength(3, 4)}
                        reset={reset}
                        autoComplete="off"
                        defaultValue={cvc}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            width: isMobile ? '100%' : '80px',
                            '&.Mui-error': {
                              width: isMobile ? '100%' : '80px',
                            },
                          },
                        }}
                      />
                    </span>
                  </form>
                  {!isMobile && (
                    <Cards
                      cvc={cvc}
                      expiry={expiry}
                      focused={focus}
                      name={name}
                      number={number}
                      locale={{ valid: '' }}
                      placeholders={{ name: 'Ad Soyad' }}
                    />
                  )}
                </div>
                {cardOwnershipVisible && (<CardOwnerIdentity setCardOwnerIdentity={setCardOwnerIdentity} />)}
              </Box>
              <Box className="steps-container installment-options">
                <div className="installment-header">
                  <h2>Taksit Seçenekleri</h2>
                  {number.length > 7 && mainInstallmentCollapse && (!cartData[0].isCash || cartData.length > 1) && (
                    <p
                      className="disclaimer"
                      style={{ color: '#90979D' }}
                      onClick={() => {
                        setMainInstallmentCollapse(false);
                      }}
                    >
                      {' '}
                      Kapat
                      {' '}
                      <KeyboardArrowDownIcon
                        style={{
                          transform: 'rotate(180deg)',
                        }}
                      />
                    </p>
                  )}
                  {number.length > 7 && !mainInstallmentCollapse && (!cartData[0].isCash || cartData.length > 1) && (
                    <>
                      <p
                        className="disclaimer"
                        style={{ color: '#90979D' }}
                        onClick={() => {
                          setMainInstallmentCollapse(true);
                        }}
                      >
                        {' '}
                        Aç
                        {' '}
                        <KeyboardArrowDownIcon />
                        {' '}
                      </p>
                    </>
                  )}
                </div>

                {loading ? (
                  <div className="inner-loading-container">
                    <CircularProgress size={20} />
                  </div>
                ) : number.length <= 5 ? (
                  // cross sell yok
                  cartData.length <= 1 ? (
                    paymentData.maxPaymentPlanIdForProduct[0] <= 1 ? (
                      <div className="installment-info">
                        <img src={infoBlue} className="svg-icon" />
                        <p className="disclaimer">Bu ürün için taksit seçeneği bulunmamaktadır. </p>
                      </div>
                    ) : (
                      <div className="installment-info">
                        <img src={infoBlue} className="svg-icon" />
                        <p className="disclaimer">
                          Taksit seçenekleriniz kart bilgilerinizi girdikten sonra görüntülenecektir.
                          {' '}
                        </p>
                      </div>
                    )
                  ) : (
                    <div className="installment-info">
                      <img src={infoBlue} className="svg-icon" />
                      <p className="disclaimer">
                        Taksit seçenekleriniz kart bilgilerinizi girdikten sonra görüntülenecektir.
                        {' '}
                      </p>
                    </div>
                  )
                ) : paymentData.maxPaymentPlanIdForProduct[0] > 1 || cartData.length > 1 ? (
                  cartData.map((item, i) => {
                    if (
                      number.length > 7
                      && (item.installmentShow !== null || paymentData.crossQuotations.length !== 0)
                    ) {
                      return (
                        <>
                          {cartData.length > 1 && (
                            <div className="installment-insurer-info">
                              <div className="insurer-logo">
                                <img
                                  src={getUsableLogo(item, false).src}
                                  alt={getUsableLogo(paymentData, false).alt}
                                  height={42}
                                  width={79}
                                />
                              </div>
                              <p className="disclaimer" dangerouslySetInnerHTML={{ __html: item.subTitle }} />
                            </div>
                          )}
                          {(!item.isCash || paymentData.crossQuotations.length !== 0) && (
                            <div className="installment" style={{ marginTop: isMobile ? '12px' : '16px' }}>
                              <p className={!isMobile ? 'disclaimer' : 'assistive'} style={{ color: '#90979D' }}>
                                Taksit Sayısı
                              </p>
                              <p
                                className={!isMobile ? 'disclaimer' : 'assistive'}
                                style={{ color: '#90979D', marginLeft: isMobile ? '0' : '-20px' }}
                              >
                                Aylık Taksit
                              </p>
                              <p className={!isMobile ? 'disclaimer' : 'assistive'} style={{ color: '#90979D' }}>
                                Toplam
                              </p>
                            </div>
                          )}
                          {item.installmentShow && !mainInstallmentCollapse && (
                            <div className="installment">
                              <FormControlLabel
                                control={<Radio checked />}
                                label={
                                  item.isCrossSell ? item.installmentShow.paymentPlan.find(
                                    (i) => i.paymentPlanId === parseInt(selectedCrossInstallment),
                                  ).description : item.installmentShow.paymentPlan.find(
                                    (i) => i.paymentPlanId === parseInt(selectedInstallment),
                                  ).description
                                }
                              />
                              <p className={!isMobile ? 'disclaimer' : 'assistive'}>
                                {item.installmentOptions.find(
                                  (i) => i.paymentPlanId === parseInt(selectedInstallment),
                                ).amount === undefined
                                  ? priceFormatter.format(paymentData.premium).replace('₺', '')
                                  : item.isCrossSell ? priceFormatter
                                    .format(
                                      item.installmentOptions.find(
                                        (i) => i.paymentPlanId === parseInt(selectedCrossInstallment),
                                      ).amount,
                                    )
                                    .replace('₺', '') : priceFormatter
                                    .format(
                                      item.installmentOptions.find(
                                        (i) => i.paymentPlanId === parseInt(selectedInstallment),
                                      ).amount,
                                    )
                                    .replace('₺', '')}
                                {' '}
                                TL
                              </p>
                              <p className={!isMobile ? 'disclaimer' : 'assistive'}>
                                {item.installmentOptions.find(
                                  (i) => i.paymentPlanId === parseInt(selectedInstallment),
                                ).amount === undefined
                                  ? priceFormatter.format(paymentData.premium).replace('₺', '')
                                  : priceFormatter.format(Math.round(priceFormatter
                                    .format(
                                      item.installmentOptions.find(
                                        (i) => i.paymentPlanId === parseInt(selectedInstallment),
                                      ).amount * item.installmentOptions.find(
                                        (i) => i.paymentPlanId === parseInt(selectedInstallment),
                                      ).value,
                                    )
                                    .replace('₺', '').replace('.', '').replace(',', '.'))).replace('₺', '')}
                                {' '}
                                TL
                              </p>
                            </div>
                          )}
                          <Collapse in={mainInstallmentCollapse} timeout={20} unmountOnExit>
                            <Installment
                              installments={item.installmentShow}
                              premium={item.premium}
                              selectedInstallment={selectedInstallment}
                              selectedCrossInstallment={selectedCrossInstallment}
                              productId={item.productId}
                              selectInstallment={selectInstallment}
                              hasCrossSell={paymentData.crossQuotations.length !== 0}
                              paymentData={paymentData}
                              cartData={cartData}
                            />
                          </Collapse>
                          {i !== cartData.length - 1 && <hr />}
                        </>
                      );
                    }
                  })
                ) : (
                  <div className="installment-info">
                    <img src={infoBlue} className="svg-icon" />
                    <p className="disclaimer">Bu ürün için taksit seçeneği bulunmamaktadır. </p>
                  </div>
                )}
              </Box>

              {paymentData.crossQuotations.length !== 0 && !hasCrossSellAdded && (
                <Box
                  className="steps-container cross-quotations"
                  style={{
                    paddingBottom: !crossQuotationCollapse ? '0' : '',
                    paddingTop: !crossQuotationCollapse ? '35px' : '',
                  }}
                >
                  <div className="cross-quotation-header">
                    <div className="header-container">
                      <p className="cross-quotation-title">Birlikte Almanızı Öneriyoruz</p>
                    </div>
                    {crossQuotationCollapse && (
                      <p
                        className="disclaimer"
                        style={{ color: '#90979D' }}
                        onClick={() => {
                          setCrossQuotationCollapse(false);
                        }}
                      >
                        {' '}
                        Kapat
                        {' '}
                        <KeyboardArrowDownIcon
                          style={{
                            transform: 'rotate(180deg)',
                          }}
                        />
                      </p>
                    )}
                    {!crossQuotationCollapse && (
                      <p
                        className="disclaimer"
                        style={{ color: '#90979D' }}
                        onClick={() => {
                          setCrossQuotationCollapse(true);
                        }}
                      >
                        {' '}
                        Aç
                        {' '}
                        <KeyboardArrowDownIcon />
                        {' '}
                      </p>
                    )}
                  </div>
                  <Collapse in={crossQuotationCollapse} timeout="auto" unmountOnExit className="collapse">
                    <CrossSell CrossQuotations={paymentData.crossQuotations} />
                    <div className="cross-quotations-buttons">
                      <Button
                        variant="text"
                        onClick={() => {
                          setIsCrossSellDetailModalOpen(true);
                        }}
                      >
                        Detaylar
                      </Button>
                      <Button variant="outlined" onClick={() => setIsCrossSellModalOpen(true)}>
                        Sepete Ekle
                      </Button>
                    </div>
                  </Collapse>
                </Box>
              )}
              {isMobile && (
                <Box className="steps-container policy-description">
                  {!hasCrossSellAdded ? (
                    <div className="policy-info">
                      <FormControlLabel
                        style={{ margin: '0', display: 'flex', alignItems: 'flex-start' }}
                        control={<Checkbox onChange={() => setPolicyRead(!policyRead)} />}
                        label={(
                          <p className="disclaimer" style={{ marginTop: '8px' }}>
                            <a onClick={(e) => getDisclosurePdf(paymentData.teklifTId, e)} style={{ color: '#0055A5' }}>
                              Poliçe Bilgilendirme Formu
                            </a>
                            ’nu okudum ve kabul ediyorum.
                          </p>
                        )}
                      />
                    </div>
                  ) : (
                    <div className="policy-info">
                      <FormControlLabel
                        style={{ margin: '0', display: 'flex', alignItems: 'flex-start' }}
                        control={<Checkbox onChange={() => setPolicyRead(!policyRead)} />}
                        label={(
                          <p className="disclaimer" style={{ marginTop: '8px' }}>
                            <a onClick={(e) => getDisclosurePdf(paymentData.teklifTId, e)} style={{ color: '#0055A5' }}>
                              {cartData[0].insurerCommercialName}
                              {' '}
                              Poliçe Bilgilendirme Formu
                            </a>
                            ’nu ve
                            {' '}
                            <a onClick={(e) => getDisclosurePdf(cartData[1].teklifTId, e)} style={{ color: '#0055A5' }}>
                              {cartData[1].insurerCommercialName}
                              {' '}
                              Sigorta Poliçe Bilgilendirme Formu
                            </a>
                            {' '}
                            okudum ve kabul ediyorum.
                          </p>
                        )}
                      />
                    </div>
                  )}
                </Box>
              )}
              {paymentData.securePaymentInfo.needsSecurePayment && (
                <Box className="steps-container secure-payment-description">
                  <p className="disclaimer">
                    <img src={secureCard} className="svg-icon" />
                    Güvenliğiniz için işleminiz 3D Secure ile yapılacaktır.
                  </p>
                  <p className="disclaimer">
                    Koalay.com bilgilerinizi asla saklamaz ve paylaşmaz.
                    {!securePaymentMore && (
                      <p
                        onClick={() => {
                          setSecurePaymentMore(true);
                        }}
                        className="assistive"
                      >
                        Daha Fazla
                        <KeyboardArrowDownIcon />
                      </p>
                    )}
                    {securePaymentMore && (
                      <p
                        onClick={() => {
                          setSecurePaymentMore(false);
                        }}
                        className="assistive"
                      >
                        Daha Az
                        <KeyboardArrowDownIcon
                          style={{
                            transform: 'rotate(180deg)',
                          }}
                        />
                      </p>
                    )}
                  </p>
                  <Collapse in={securePaymentMore} timeout="auto" unmountOnExit className="collapse">
                    <br />
                    <p className="disclaimer">
                      İçiniz rahat olsun! Koalay SSL (Secure Sockets Layer) güvenlik sertifikası sahibi. Bu sertifikaya
                      sahip olmak isteyen diğer tüm firmalar gibi, Koalay da çok sıkı bir güvenlik prosedüründen geçti.
                      Bu nedenle teklif alırken ya da poliçenizi satın alırken gireceğiniz her türlü kişisel bilginiz ve
                      kredi kartı detaylarınız ilgili sunuculara şifrelenerek güvenli bir şekilde iletilir, kredi kartı
                      bilgileriniz asla kayıt altına alınmaz ve saklanmaz. Tahsilat, seçtiğiniz sigorta şirketi
                      tarafından yapılacaktır.
                    </p>
                  </Collapse>
                </Box>
              )}
              {bransType === 'Kasko'
                && (selectedKurum === '' ? (
                  <Box className="steps-container dainimurtein-description">
                    <Button
                      className="daini-murtehin"
                      variant="outlined"
                      onClick={() => {
                        setDainiMurtehin(true);
                      }}
                    >
                      Dain-i Mürtehin Seçimi
                      {isMobile && <br />}
                      {!isMobile && ' '}
                      (Aracınızı Kredi ile Aldıysanız)
                    </Button>
                  </Box>
                ) : (
                  <Box className="steps-container daini-detail">
                    <p className="disclaimer">
                      {' '}
                      <b>Rehin Alacaklı:</b>
                      {' '}
                      {selectedKurum}
                    </p>
                    <img
                      src={closeSVG}
                      onClick={() => {
                        setSelectedKurum('');
                        setBranchList(null);
                        setSelectedDaini(null);
                      }}
                      width={isMobile ? '16' : '20'}
                      style={{
                        position: 'absolute',
                        filter:
                          'invert(88%) sepia(21%) saturate(935%) hue-rotate(123deg) brightness(85%) contrast(97%)',
                        right: isMobile ? '0' : '8px',
                        top: isMobile ? '15px' : '20px',
                        cursor: 'pointer',
                      }}
                    />
                  </Box>
                ))}
              {isCallCenter && (
                <Box className="steps-container callcenter-info">
                  <h4 style={{ fontWeight: '600' }}>İş Ortağı Bilgileri</h4>
                  <div className="wrapper">
                    <div className="row dealer-info">
                      <p>Firma: </p>
                      <p>{dealerInfo.dealerName}</p>
                    </div>
                    <div className="row dealer-info">
                      <p>Bayi Kodu: </p>
                      <p>{dealerInfo.dealerCode ? dealerInfo.dealerCode : '0'}</p>
                    </div>
                  </div>
                </Box>
              )}
              {!isMobile ? (
                <div style={{ margin: '10px 48px' }}>
                  <Button
                    style={{
                      color: '#53585E',
                      textTransform: 'none',
                      fontSize: '17px',
                      fontWeight: 600,
                    }}
                    onClick={goToConfirmation}
                  >
                    <ArrowBackIosNewIcon />
                    Geri
                  </Button>
                </div>
              ) : (
                ''
              )}
            </div>

            {!isMobile && (
              <div className="cart-summary">
                {cartData.map((item) => (
                  <>
                    <div
                      className="insurer-logo"
                      style={{ justifyContent: hasCrossSellAdded ? 'space-between' : 'center' }}
                    >
                      <img
                        src={getUsableLogo(item, false).src}
                        alt={getUsableLogo(item, false).alt}
                        height={64}
                        width={120}
                      />
                      {item.isCrossSell && (
                        <img
                          src={editSVG}
                          style={{ cursor: 'pointer' }}
                          width={24}
                          onClick={() => setIsCrossSellModalOpen(true)}
                        />
                      )}
                    </div>
                    <div className="offer-detail">
                      <div className="offer-name" dangerouslySetInnerHTML={{ __html: item.subTitle.split('(')[0] }} />
                      <div className="offer-price">
                        {' '}
                        {newPremium && !item.isCrossSell ? priceFormatter.format(newPremium).replace('₺', '') : priceFormatter.format(item.premium).replace('₺', '')}
                        {' '}
                        TL
                      </div>
                    </div>
                    {item.packageName !== undefined && <div className="offer-subtitle">{item.packageName}</div>}
                    {cartData.length > 1 && <hr />}
                    {' '}
                  </>
                ))}
                {hasCrossSellAdded && (
                  <>
                    <div className="cart-total">
                      <h4>Toplam</h4>
                      <h3>
                        {' '}
                        {priceFormatter.format(cartTotal).replace('₺', '')}
                        {' '}
                        TL
                      </h3>
                    </div>
                  </>
                )}
                {!hasCrossSellAdded ? (
                  <div className="policy-info">
                    <FormControlLabel
                      style={{ margin: '0', display: 'flex', alignItems: 'flex-start' }}
                      control={<Checkbox onChange={() => setPolicyRead(!policyRead)} />}
                      label={(
                        <p className="disclaimer" style={{ marginTop: '8px' }}>
                          <a onClick={(e) => getDisclosurePdf(paymentData.teklifTId, e)} style={{ color: '#0055A5' }}>
                            Poliçe Bilgilendirme Formu
                          </a>
                          ’nu okudum ve kabul ediyorum.
                        </p>
                      )}
                    />
                  </div>
                ) : (
                  <div className="policy-info">
                    <FormControlLabel
                      style={{ margin: '0', display: 'flex', alignItems: 'flex-start' }}
                      control={<Checkbox onChange={() => setPolicyRead(!policyRead)} />}
                      label={(
                        <p className="disclaimer" style={{ marginTop: '8px' }}>
                          <a onClick={(e) => getDisclosurePdf(paymentData.teklifTId, e)} style={{ color: '#0055A5' }}>
                            {cartData[0].insurerCommercialName}
                            {' '}
                            Poliçe Bilgilendirme Formu
                          </a>
                          ’nu ve
                          {' '}
                          <a onClick={(e) => getDisclosurePdf(cartData[1].teklifTId, e)} style={{ color: '#0055A5' }}>
                            {cartData[1].insurerCommercialName}
                            {' '}
                            Sigorta Poliçe Bilgilendirme Formu
                          </a>
                          {' '}
                          okudum ve kabul ediyorum.
                        </p>
                      )}
                    />
                  </div>
                )}
                <div className="buy-button">
                  <Button
                    variant="contained"
                    className="button"
                    onClick={handleSubmit(purchase)}
                    style={{ textTransform: 'none' }}
                    disabled={!policyRead || !isValid || (cardOwnerIdentity === false)}
                  >
                    Satın Al
                  </Button>
                </div>
              </div>
            )}
          </div>
          {dainiMurtehin && (
            <CenteredModal isOpen={dainiMurtehin} setOpen={setDainiMurtehin}>
              <DainiMurtehin bankList={bankList} selectedKurum={selectedKurum} setSelectedKurum={setSelectedKurum} branchList={branchList} handleBranchList={handleBranchList} showVknLossPayee={showVknLossPayee} setSelectedDaini={setSelectedDaini} lossPayeeVisible={paymentData.lossPayeeVisible} />
            </CenteredModal>
          )}
          {securePaymentWarning && (
          <CenteredModal className="securePaymentWarning-modal" isOpen={securePaymentWarning} setOpen={setSecurePaymentWarning}>
            <SecurePaymentWarning handleSecurePayment={handleSecurePayment} />
          </CenteredModal>
          )}
          {priceDiffModal && (
            <CenteredModal
              className="price-diff-modal-container"
              isOpen={priceDiffModal}
              setOpen={setPriceDiffModal}
              hasClose={false}
            >
              <PriceDiffModal
                priceDiffData={priceDiffData}
                premium={paymentData.premium}
                goToSummary={goToSummary}
                buyAction={priceDiffBuyAction}
              />
            </CenteredModal>
          )}
          {isCrossSellModalOpen && (
            <CenteredModal
              isOpen={isCrossSellModalOpen}
              setOpen={setIsCrossSellModalOpen}
              title="İhtiyari Mali Mesuliyet(İMM) Sigortası"
              className="crosssell-modal"
            >
              <CrossSellModal
                setSelectedCrossInstallment={setSelectedCrossInstallment}
                hasCrossSellAdded={hasCrossSellAdded}
                crossQuotations={paymentData.crossQuotations}
                getDisclosurePdf={getDisclosurePdf}
                removeCrossSellFromBasket={removeCrossSellFromBasket}
                setIsCrossSellModalOpen={setIsCrossSellModalOpen}
                setHasCrossSellAdded={setHasCrossSellAdded}
                addCrossSellToBasket={addCrossSellToBasket}
              />
            </CenteredModal>
          )}
          {isCrossSellDetailModalOpen && (
            <CenteredModal isOpen={isCrossSellDetailModalOpen} setOpen={setIsCrossSellDetailModalOpen}>
              <>
                <div className="cross-quotation-popup" style={{ border: 'none' }}>
                  <h4>
                    İhtiyari Mali Mesuliyet Sigortası nedir?
                  </h4>
                  <div className="description">
                    <p className="assistive">
                      İhtiyari Mali Mesuliyet(İMM) Sigortası, sigortalanan taşıtın kusurlu bulunduğu trafik kazası sonucunda, “üçüncü kişilere” verdiği zararların trafik sigortasının maddi, sağlık, ölüm ve sakatlanma teminat limitlerini aşması durumunda size güvence sağlayan bir sigorta ürünüdür. Trafik sigortası teminatları içerisinde yer almayan manevi tazminat talepleri de teminat kapsamındadır. Neova İMM sigortasının satın alınması için kasko sigortasının olmasına gerek yoktur. Sadece trafik sigortasının olması yeterlidir. Satın al dediğinizde İMM sigortası paketlerini görebilir, size uygun olan İMM paketini sepete ekleyerek kendinizi güvence altına alabilirsiniz.
                    </p>
                  </div>
                </div>
                <div className="cross-quotations-buttons" style={{ gridTemplateColumns: '1fr' }}>
                  {!hasCrossSellAdded && (
                    <Button
                      variant="contained"
                      className="button"
                      style={{ textTransform: 'none' }}
                      onClick={() => {
                        setIsCrossSellDetailModalOpen(false);
                        setIsCrossSellModalOpen(true);
                      }}
                    >
                      Satın Al
                    </Button>
                  )}
                </div>
              </>
            </CenteredModal>
          )}

          {isMobile && (
            <div
              className="header-cart-summary"
              style={{
                height: hasCrossSellAdded ? `calc(${cartData.length + 1} * 60px)` : `calc(${cartData.length} * 60px)`,
              }}
            >
              {cartData.map((item) => (
                <>
                  <div className="cart-item-container">
                    <div className="insurer-logo">
                      <img src={getUsableLogo(item, false).src} alt={getUsableLogo(item, false).alt} width={76} />
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div className="mobile-offer-info">
                        <p className="disclaimer" dangerouslySetInnerHTML={{ __html: item.subTitle.split('(')[0] }} />
                        {item.packageName !== undefined && <p className="desc">{item.packageName}</p>}
                      </div>
                      {item.isCrossSell && (
                        <img
                          src={editSVG}
                          style={{ cursor: 'pointer' }}
                          width={16}
                          onClick={() => setIsCrossSellModalOpen(true)}
                        />
                      )}
                    </div>

                    <div className="offer-price">
                      {' '}
                      {newPremium ? priceFormatter.format(newPremium).replace('₺', '') : priceFormatter.format(item.premium).replace('₺', '')}
                      {' '}
                      TL
                    </div>
                  </div>
                  {cartData.length > 1 && <hr />}
                </>
              ))}
              {hasCrossSellAdded && (
                <div className="cart-item-container">
                  <p className="disclaimer" style={{ fontWeight: '700' }}>
                    Toplam
                  </p>
                  <p className="disclaimer" style={{ fontWeight: '700' }}>
                    {' '}
                    {priceFormatter.format(cartTotal).replace('₺', '')}
                    {' '}
                    TL
                  </p>
                </div>
              )}
            </div>
          )}
          {isMobile ? (
            <Button
              variant="contained"
              onClick={handleSubmit(purchase)}
              disabled={!policyRead || !isValid || (cardOwnerIdentity === false)}
              style={{
                width: !isMobile ? '254px' : '100%',
                height: '55px',
                boxSizing: 'border-box',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
                borderRadius: '5px',
                textTransform: 'none',
                display: 'block',
                position: 'fixed',
                bottom: 0,
                left: 0,
                margin: '0 0 0 -20%',
              }}
            >
              Satın Al
            </Button>
          ) : (
            ''
          )}
          {paratikaObject !== undefined && (
            <Modal open={token !== null}>
              <Frame
                style={{
                  width: isMobile ? '100%' : '80%',
                  height: isMobile ? '100%' : '80%',
                  left: '50%',
                  top: '50%',
                  position: 'relative',
                  transform: 'translate(-50%, -50%)',
                  backgroundColor: '#fff',
                  border: '1px solid #999',
                  borderRadius: '6px',
                  outline: '0',
                  boxShadow: '0 3px 9px rgb(0 0 0 / 50%)',
                }}
              >
                <Paratika paratikaObject={paratikaObject} token={token} />
              </Frame>
            </Modal>
          )}
          <CenteredModal className="fail-at-insurer-modal" isOpen={hasFailAtIssue} setOpen={setHasFailAtIssue}>
            <HasFailAtIssue setHasFailAtInsurer={setHasFailAtIssue} />
          </CenteredModal>
        </div>
      )}
    </>
  );
};

export default withRouter(Checkout);
