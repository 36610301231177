// import { birthDateValidation, wordValidation } from '../../../shared/_helpers/validations';
import React from 'react';
import { Button } from '@mui/material';
import { KLInput } from '../../../motor/views/components/form/KLInput';
import { wordValidation, birthDateValidation, onlyTcknValidation } from '../../../shared/_helpers/validations';
import fullWidthInputSx from '../fullWidthInputSx';
import KVKKInfo from '../../views/components/KVKKInfo/KVKKInfo';
import BackButton from '../../views/components/BackButton/BackButton';
import store from '../../../shared/store/store';

import tssButtonStyle from '../tssButtonStyle';
import KLAutoComplete from '../../../shared/formComponents/KLAutoComplete';
import UserConsent from '../../views/components/UserConsent/userConsent.component';

const createInfoForm = (props) => {
  const {
    submitForm,
    isMobile,
    formHookObject: {
      handleSubmit,
      reset,
      control,
      errors,
      touchedFields,
      isValid,
      isFamilyMember,
    },
    relationObject,
    userConsentChecked,
  } = props;

  const { insureds } = store.getState().info;
  const { isMerchant } = store.getState().shell;
  const consumer = JSON.parse(store.getState().consumer.consumer);
  const currentInsured = insureds[insureds.length - 1];
  const shouldFillForm = currentInsured && (!currentInsured.isFull || (!currentInsured.isFull && !currentInsured.movingForward));

  const formHookProps = {
    control,
    error: { ...errors },
    touchedFields,
    reset,
  };

  const formObject = {
    form: {
      relation: {
        type: 'input',
        component: <KLAutoComplete />,
        show: isFamilyMember,
        props: {
          name: 'relation',
          label: 'Yakınlık',
          sx: fullWidthInputSx,
          ...relationObject,
          ...formHookProps,
          renderParams: {
            name: 'displayName',
            code: 'enumValue',
          },
        },
      },
      tckn: {
        type: 'input',
        component: <KLInput />,
        props: {
          name: 'tckn',
          label: 'T.C. Kimlik No',
          type: 'tel',
          sx: fullWidthInputSx,
          disabled: isMerchant && !isFamilyMember,
          mask: '99999999999',
          rules: onlyTcknValidation(),
          ...formHookProps,
          defaultValue: (isMerchant && !isFamilyMember) ? consumer?.referenceParams?.identityNo : shouldFillForm ? currentInsured.identityNo : null,
        },
      },
      name: {
        type: 'input',
        component: <KLInput />,
        props: {
          name: 'name',
          label: 'Ad',
          sx: fullWidthInputSx,
          rules: wordValidation(),
          ...formHookProps,
          defaultValue: shouldFillForm ? currentInsured.name : null,
        },
      },
      lastName: {
        type: 'input',
        component: <KLInput />,
        props: {
          name: 'surname',
          label: 'Soyad',
          sx: fullWidthInputSx,
          rules: wordValidation(),
          ...formHookProps,
          defaultValue: shouldFillForm ? currentInsured.lastName : null,
        },
      },
      birthDate: {
        type: 'input',
        component: <KLInput />,
        props: {
          name: 'birthDate',
          label: 'Doğum Tarihi',
          sx: fullWidthInputSx,
          mask: '99/99/9999',
          rules: birthDateValidation(),
          ...formHookProps,
          grid: 12,
          defaultValue: shouldFillForm ? currentInsured.birthDate : null,
        },
      },
      kvkk: {
        type: 'component',
        component: <KVKKInfo />,
        show: !isFamilyMember,
      },
      button: {
        type: 'button',
        show: !isMobile || !isFamilyMember,
        component: <Button />,
        props: {
          style: {
            width: '100%',
            height: '45px',
            boxSizing: 'border-box',
            textTransform: 'none',
            borderRadius: '4px',
          },
          disabled: !isValid,
          onClick: handleSubmit(submitForm),
          variant: 'contained',
        },
        children: isFamilyMember ? 'Devam Et' : 'Sağlık Sigortası Teklifleri Al',
      },
      backbutton: {
        type: 'component',
        component: <BackButton />,
        hideMobile: true,
        show: isFamilyMember,
      },
    },
    bottom: {
      button: {
        type: 'button',
        component: <Button />,
        props: {
          style: {
            ...tssButtonStyle,
          },
          disabled: !isValid,
          onClick: handleSubmit(submitForm),
          variant: 'contained',
        },
        children: 'Devam Et',
        mobile: true,
        show: isFamilyMember,
      },
    },
  };

  return formObject;
};

export default createInfoForm;
