import React from "react";
import "./policy-detail.scss";
import { Button, Tooltip, tooltipClasses } from "@mui/material";
import styled from "@emotion/styled";
import { returnCDNlink } from "../../../helpers/stringHelpers";
import tssButtonStyle from "../../../data/tssButtonStyle";
import ProductFeatures from "./components/ProductFeatures";
import ProductCoverages from "./components/ProductCoverages";
import { openPDF } from "../../../../shared/_helpers/helperFunctions";
import { useIsOverflow } from "../../../../shared/_hooks/useIsOverflow";
import toastrActions from "../../../../motor/actions/toastrAction";
import { useDispatch } from "react-redux";
import { historyPushWithSearch } from "../../../../shared/_helpers/historyPushWithSearch";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useUserState from "../../../../shared/_hooks/userStateHook";
import { UserStates } from "../../../../motor/data/userStates";
import userStateService from "../../../../motor/_services/userState.service";

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 13,
    },
  })
);

export const PolicyDetail = (props) => {
  const ref = React.useRef();
  const dispatch = useDispatch();
  const { setUserState } = useUserState();
	const history = useHistory();
	const URL_PARAMS = new URLSearchParams(document.location.search);
  const jt = URL_PARAMS.get("jt");

  const isOverflow = useIsOverflow(
    ref,
    (isOverflowFromCallback) => {
      console.log(isOverflowFromCallback);
      // true
    },
    true
  );

  const {
    offer,
    offer: { quotationCoverageItem },
  } = props;

  const handleOfferPDF = () => {
    console.log(offer);
    const URL_PARAMS = new URLSearchParams(document.location.search);
    const qGuid = URL_PARAMS.get("qGuid");
    openPDF(offer.teklifTId, "quotation", qGuid);
  };

  const redirectToConfirmation = () => {
		const userSateData = {
			UserUID: jt,
			UserStateDefinition: UserStates.TSS_Teklif_Sayfasinda_Satin_Al_Tiklayan,
		};
		userStateService.setUserState(userSateData)
    historyPushWithSearch("/tamamlayici-saglik-sigortasi/confirmation", history, { teklifTId: offer.teklifTId });
  };

  return (
    <>
      <div className="policy-detail-container" ref={ref}>
        <div className="policy-detail-top-container">
          <div className="policy-detail-logo-row">
            <img src={returnCDNlink(offer.insurerName)} />
            <p className="policy-network-name">
              {offer.network.networkGroup}
              <br />
              {offer.network.networkName}
            </p>
          </div>
          <div className="policy-detail-button-row">
            {offer.isVisibleQuotationDocument ? (
              <Button
                style={tssButtonStyle}
                variant="outlined"
                onClick={() => {
                  handleOfferPDF(offer);
                }}
              >
                Teklif Dosyası Görüntüle
              </Button>
            ) : (
              <LightTooltip
                title={
                  <span dangerouslySetInnerHTML={{ __html: "Bu ürün için teklif dosyası görüntülenememektedir." }} />
                }
              >
                <Button style={tssButtonStyle} variant="outlined">
                  Teklif Dosyası Görüntüle
                </Button>
              </LightTooltip>
            )}

            <Button style={tssButtonStyle} variant="contained" onClick={redirectToConfirmation}>
              Ödeme Adımına Geç
            </Button>
          </div>
        </div>
        {offer.productFeatures && <ProductFeatures productFeatures={offer.productFeatures} />}
        <ProductCoverages quotationCoverageItem={quotationCoverageItem} />
        <div className="mobile-button-row" style={{ width: isOverflow ? "calc(100vw - 40px)" : "calc(100vw - 32px)" }}>
          {offer.isVisibleQuotationDocument ? (
            <Button
              style={tssButtonStyle}
              variant="outlined"
              onClick={() => {
                handleOfferPDF(offer);
              }}
            >
              Teklif Dosyası Görüntüle
            </Button>
          ) : (
            <Button
              style={tssButtonStyle}
              variant="outlined"
              onClick={() => {
                dispatch(
                  toastrActions.setToastrMessage({
                    variant: "info",
                    message: "Bu ürün için teklif dosyası görüntülenememektedir.",
                  })
                );
              }}
            >
              Teklif Dosyası Görüntüle
            </Button>
          )}
          <Button style={tssButtonStyle} variant="contained" onClick={redirectToConfirmation}>
            Ödeme Adımına Geç
          </Button>
        </div>
      </div>
    </>
  );
};

export default PolicyDetail;
