import React, {
  forwardRef, useEffect, useImperativeHandle, useState,
} from 'react';

import { Skeleton, Tooltip, tooltipClasses } from '@mui/material';
import styled from '@emotion/styled';
import infoSvg from '../../../../../../shared/assets/img/svg/info.svg';

import classes from './filterSection.module.scss';
import useWindowSize from '../../../../../../shared/_hooks/windowResizeHook';
import CenteredModal from '../../../../../../motor/views/components/widget/CenteredModal';
import TrashFilterSvg from '../../../../../../shared/assets/img/svg/TrashFilter.jsx';

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 13,
    },
  }),
);

const FilterSection = forwardRef((props, ref) => {
  const size = useWindowSize();
  const isMobile = size.width <= 880;
  const {
    yatarakTedavi,
    yatarakAyaktaTedavi,
    yatarakAyaktaDogum,
    kapsamli,
    limitli,
    oneToFive,
    sixToTen,
    setOffersToBeRendered,
    setSeeAllClicked,
    seeAllClicked,
    showSkeleton,
  } = props;

  //* Active filters
  const [activeTT, setActiveTT] = useState([1]);
  const [activeNT, setActiveNT] = useState([0]);
  const [activeOO, setActiveOO] = useState([1]);
  const [filtersSelected, setFiltersSelected] = useState(null);
  const [allEmpty, setAllEmpty] = useState(false);

  const [hintPopup, showHintPopup] = useState(null);
  const [hintTitle, setHintTitle] = useState(null);

  //* Being able to change state directly from Summary page
  useImperativeHandle(ref, () => ({
    setActiveTT,
    setActiveNT,
    setActiveOO,
  }));

  useEffect(() => {
    setFiltersSelected({
      tt: activeTT,
      nt: activeNT,
      oo: activeOO,
    });
  }, [activeNT, activeOO, activeTT]);

  useEffect(() => {
    setOffersToBeRendered(filtersSelected);
  }, [filtersSelected, setOffersToBeRendered]);

  //* If every filter option is empty, the clear must be disabled
  useEffect(() => {
    if ((!yatarakTedavi || yatarakTedavi.length === 0)
     && (!yatarakAyaktaTedavi || yatarakAyaktaTedavi.length === 0)
     && (!yatarakAyaktaDogum || yatarakAyaktaDogum.length === 0)
     && (!kapsamli || kapsamli.length === 0)
     && (!limitli || limitli.length === 0)
     && (!oneToFive || oneToFive.length === 0)
     && (!sixToTen || sixToTen.length === 0)) {
      setAllEmpty(true);
    } else {
      setAllEmpty(false);
    }
  }, [kapsamli, limitli, oneToFive, sixToTen, yatarakAyaktaDogum, yatarakAyaktaTedavi, yatarakTedavi]);

  const handleClearFilters = () => {
    setActiveNT([]);
    setActiveTT([]);
    setActiveOO([]);
    setSeeAllClicked(true);
  };

  const handleActiveTT = (value) => {
    if (activeTT.includes(value)) {
      const newActiveTT = activeTT.filter((val) => val !== value);
      setActiveTT(newActiveTT);
    } else {
      const newActiveTT = [...activeTT, value];
      setActiveTT(newActiveTT);
    }
  };

  const handleActiveNT = (value) => {
    if (activeNT.includes(value)) {
      const newActiveNT = activeNT.filter((val) => val !== value);
      setActiveNT(newActiveNT);
    } else {
      const newActiveNT = [...activeNT, value];
      setActiveNT(newActiveNT);
    }
  };

  const handleActiveOO = (value) => {
    if (activeOO.includes(value)) {
      const newActiveOO = activeOO.filter((val) => val !== value);
      setActiveOO(newActiveOO);
    } else {
      const newActiveOO = [...activeOO, value];
      setActiveOO(newActiveOO);
    }
  };

  const setHintDesc = (title, text) => {
    setHintTitle(title);
    showHintPopup(text);
  };
  const closeHintPopup = () => {
    setHintTitle(null);
    showHintPopup(null);
  };

  return (
    <>
      <div className={classes.wrapper}>
        <h2 className={`${classes.heading} ${classes.desktopHeading}`}>Sigortanı Kişiselleştir</h2>
        <div className={classes.container}>
          <div className={classes.mobileHeadingRow}>
            <h2 className={`${classes.heading} ${classes.mobileHeading}`}>Sigortanı Kişiselleştir</h2>
            <div onClick={(!seeAllClicked || !allEmpty) ? handleClearFilters : () => {}} className={(seeAllClicked || allEmpty) ? `${classes.clearButton} ${classes.disabledClearButton}` : classes.clearButton}>
              <TrashFilterSvg disabled={seeAllClicked || allEmpty} />
              <p>Temizle</p>
            </div>
          </div>
          <div className={`${classes.filterHeadingContainer} ${classes.filterHeadingContainerFirst}`}>
            <p className={classes.filterHeading}>Tedavi Türü</p>
            <LightTooltip
              title={(
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      'Poliçenizi sadece yatış gerektiren(yatarak) veya hem yatış gerektiren hem de gerektirmeyen tedavileri(yatarak+ayakta) kapsayacak şekilde düzenleyebilirsiniz. İsteğe bağlı olarak doğum paketi teminatını da ekleyebilirsiniz.',
                  }}
                />
              )}
            >
              <img
                src={infoSvg}
                alt="Var"
                onClick={() => (isMobile
                  ? setHintDesc(
                    'Tedavi Türü',
                    'Poliçenizi sadece yatış gerektiren(yatarak) veya hem yatış gerektiren hem de gerektirmeyen tedavileri(yatarak+ayakta) kapsayacak şekilde düzenleyebilirsiniz. İsteğe bağlı olarak doğum paketi teminatını da ekleyebilirsiniz.',
                  )
                  : '')}
              />
            </LightTooltip>
          </div>
          <div className={classes.filterOptionContainer}>
            <p
              onClick={yatarakTedavi.length !== 0 ? () => handleActiveTT(0) : undefined}
              value={0}
              className={`${classes.filterOption} ${activeTT.includes(0) && classes.active} ${
                yatarakTedavi.length === 0 && classes.disabled
              }`}
            >
              Yatarak Tedavi
            </p>
            <p
              onClick={yatarakAyaktaTedavi.length !== 0 ? () => handleActiveTT(1) : undefined}
              value={1}
              className={`${classes.filterOption} ${activeTT.includes(1) && classes.active} ${
                yatarakAyaktaTedavi.length === 0 && classes.disabled
              }`}
            >
              Yatarak ve Ayakta Tedavi
            </p>
            <p
              onClick={yatarakAyaktaDogum.length !== 0 ? () => handleActiveTT(2) : undefined}
              value={2}
              className={`${classes.filterOption} ${activeTT.includes(2) && classes.active} ${
                yatarakAyaktaDogum.length === 0 && classes.disabled
              }`}
            >
              Yatarak, Ayakta Tedavi ve Doğum
            </p>
          </div>
          <div className={classes.filterHeadingContainer}>
            <p className={classes.filterHeading}>Hastane Ağı</p>
            <LightTooltip
              title={(
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      'Kapsamlı hastane ağı sgk anlaşmalı özel hastaneler içerisinde daha geniş  hastaneleri kapsar. Limitli hastane ağında ise daha kısıtlı hastane seçenekleri vardır.',
                  }}
                />
              )}
            >
              <img
                src={infoSvg}
                alt="Var"
                onClick={() => (isMobile
                  ? setHintDesc(
                    'Hastane Ağı',
                    'Kapsamlı hastane ağı sgk anlaşmalı özel hastaneler içerisinde daha geniş  hastaneleri kapsar. Limitli hastane ağında ise daha kısıtlı hastane seçenekleri vardır.',
                  )
                  : '')}
              />
            </LightTooltip>
          </div>
          <div className={classes.filterOptionContainer}>
            <p
              onClick={kapsamli.length !== 0 ? () => handleActiveNT(0) : undefined}
              value={0}
              className={`${classes.filterOption} ${activeNT.includes(0) && classes.active} ${
                kapsamli.length === 0 && classes.disabled
              }`}
            >
              Kapsamlı Hastane Ağı
            </p>
            <p
              onClick={limitli.length !== 0 ? () => handleActiveNT(1) : undefined}
              value={1}
              className={`${classes.filterOption} ${activeNT.includes(1) && classes.active} ${
                limitli.length === 0 && classes.disabled
              }`}
            >
              Limitli Hastane Ağı
            </p>
          </div>
          <div className={classes.filterHeadingContainer}>
            <p className={classes.filterHeading}>Ayakta Tedavi Adeti</p>
            <LightTooltip
              title={(
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      'Kısa süreli tedaviler için alınan muayene adetine dair limit aralığını ifade eder. Sigorta şirketine göre ayakta tedavi adeti değişkenlik gösterir.',
                  }}
                />
              )}
            >
              <img
                src={infoSvg}
                alt="Var"
                onClick={() => (isMobile
                  ? setHintDesc(
                    'Ayakta tedavi adeti',
                    'Kısa süreli tedaviler için alınan muayene adetine dair limit aralığını ifade eder. Sigorta şirketine göre ayakta tedavi adeti değişkenlik gösterir.',
                  )
                  : '')}
              />
            </LightTooltip>
          </div>
          <div className={`${classes.filterOptionContainer} ${classes.ooContainer}`}>
            <p
              onClick={oneToFive.length !== 0 ? () => handleActiveOO(0) : undefined}
              value={0}
              className={`${classes.filterOption} ${activeOO.includes(0) && classes.active} ${
                oneToFive.length === 0 && classes.disabled
              }`}
            >
              0 - 5
            </p>
            <p
              onClick={sixToTen.length !== 0 ? () => handleActiveOO(1) : undefined}
              value={1}
              className={`${classes.filterOption} ${activeOO.includes(1) && classes.active} ${
                sixToTen.length === 0 && classes.disabled
              }`}
            >
              6 - 10
            </p>
          </div>
        </div>
      </div>
      {hintPopup !== null && (
        <CenteredModal title={hintTitle} isOpen={hintPopup !== null} setOpen={closeHintPopup}>
          <p className="disclaimer" style={{ marginTop: '10px' }}>{hintPopup}</p>
        </CenteredModal>
      )}
    </>
  );
});

export default FilterSection;
