import React, { useState } from 'react';
import {
	Autocomplete,
	Button, FormControl, InputLabel, MenuItem, Paper, Select, TextField,
} from '@mui/material';

import '../../../../../shared/assets/styles/components/installment.scss';
import 'react-credit-cards/es/styles-compiled.css';
import { useForm } from 'react-hook-form';
import useWindowSize from '../../../../../shared/_hooks/windowResizeHook';
import EventBus from '../../../../../shared/_helpers/EventBus';
import { KLInput } from '../../../components/form/KLInput';
import { onlyVknValidation, requiredArea } from '../../../../../shared/_helpers/validations';

export const DainiMurtehin = (props) => {
	const [kurum, setKurum] = useState(null);
	const [kurumName, setKurumName] = useState('');
	const [branch, setBranch] = useState(null);
	const [branchName, setBranchName] = useState('');
	const [vkn, setVkn] = useState('');
	const size = useWindowSize();
	const isMobile = size.width <= 880;
  const isKoalaySS = document.location.href.indexOf('koalay') >= 0;

	const {
		handleSubmit,
		formState: { errors, isValid, touchedFields },
		control,
		setValue
	} = useForm({
		mode: 'onChange',
	});

	const selectKurum = (e, val) => {
		setKurum(val);
		setKurumName(e.view.event.target.getAttribute("daininame"));
		setBranch(null)
		setValue('daini-vkn', "", { shouldValidate: true })
		props.handleBranchList(val.id);
	};
	const vknChange = (e) => {
		setVkn(e.target.value)
	}
	const handleVkn = (e, val) => {
		const identityNo = (e.view.event.target.getAttribute("identityNo"))
		setBranch(val)
		setBranchName(e.view.event.target.getAttribute("branchname"))
		setVkn(identityNo);
		setValue('daini-vkn', identityNo || "", { shouldValidate: true })
	};

	const submitDaini = (data) => {
		const selectedDaini = `${kurumName}${branchName ? '/ ' + branchName : ''} `
		props.setSelectedKurum(selectedDaini);
		props.setSelectedDaini({ id: branch?.id || kurum?.id, IdentityNo: data['daini-vkn'] });
		EventBus.emit('closeDaini');
	};

	const onSubmit = (data) => {
		submitDaini(data)
	};

	const CustomPaper = (props) => (
		<Paper
			style={{
				fontSize: '13px',
				minWidth: '100%',
			}}
			elevation={8}
			{...props}
		/>
	);


	return (
		<div>
			{props.lossPayeeVisible ?
				props.bankList && (
					<>
						<h3>Dain-i Mürtehin (Rehinli Alacaklı) Seçimi </h3>
						<p className="desc">
						Dain-i Mürtehin, kredi kullanılarak satın alınan araçlarda, hasarın gerçekleşmesi durumunda ödenecek olan
							tazminat tutarından birinci derecede alacaklı olan banka ya da finansal kuruluşları ifade eder.
							<br />

						</p>
						<FormControl>
							<Autocomplete
								openOnFocus
								id="model-type-select"
								options={props.bankList}
								PaperComponent={CustomPaper}
								getOptionLabel={(option) => option.name}
								noOptionsText="Sonuç bulunamadı"
								value={kurum}
								filterOptions={(x, e) => {
									if (e.inputValue) {
										return x.filter(
											(elem) => elem.name.toLocaleLowerCase('tr').includes(e.inputValue.toLocaleLowerCase('tr')),
										);
									}
									return x;
								}}
								renderOption={(props, option, { selected }) => (
									<MenuItem {...props} value={option.code} key={option.name} data-text={option.name} daininame={option.name} name={option.name}>
										{option.name}
									</MenuItem>
								)}
								onChange={(event, value) => {
									selectKurum(event, value);
								}}
								sx={{
									margin: 'auto',
									width: isMobile ? '100%' : '368px',
									'& .MuiOutlinedInput-root':
										{ width: '100%' },
									'& .MuiTextField-root':
										{ marginBottom: '0' }
								}}
								renderInput={(params) => <TextField {...params} label="Kurum" />}
							/>

						</FormControl>
						{props.branchList?.length > 0 && (
							<Autocomplete
								openOnFocus
								id="model-type-select"
								options={props.branchList}
								PaperComponent={CustomPaper}
								getOptionLabel={(option) => option.name}
								noOptionsText="Sonuç bulunamadı"
								value={branch}
								filterOptions={(x, e) => {
									if (e.inputValue) {
										return x.filter(
											(elem) => elem.name.toLocaleLowerCase('tr').includes(e.inputValue.toLocaleLowerCase('tr')),
										);
									}
									return x;
								}}
								renderOption={(props, option, { selected }) => (
									<MenuItem {...props} value={option.code} key={option.name} data-text={option.name} branchname={option.name} identityNo={option.identityNo}>
										{option.name}
									</MenuItem>
								)}
								onChange={(event, value) => {
									handleVkn(event, value);
								}}
								sx={{ margin: 'auto', width: isMobile ? '100%' : '368px', '& .MuiOutlinedInput-root': { width: '100%' } }}
								renderInput={(params) => <TextField {...params} label="Şube" />}
							/>
						)}
						{props.showVknLossPayee && (
							<KLInput
								name="daini-vkn"
								label="Vergi kimlik numarası"
								mask="9999999999"
								placeholder=""
								rules={onlyVknValidation()}
								control={control}
								error={errors}
								touchedFields={touchedFields}
								defaultValue={vkn}
								onChange={vknChange}
								sx={{
									margin: 'auto',
									'& .MuiOutlinedInput-root': {
										width: isMobile ? '100%' : '368px',
										marginBottom: '0',
										'&.Mui-error': {
											width: '100%',
											marginBottm: '0',
										},
									},
									'.MuiInputLabel-formControl': {
										fontSize: '15px',
										marginBottom: '0',
									},
									'&': {
										width: isMobile ? '100%' : '368px',
										display: 'flex',
										marginLeft: 'auto !important',
										marginRight: 'auto',
									},
								}}
							/>
						)}
						<Button
							variant="contained"
							disabled={kurum === ''}
							style={{
								display: 'flex',
								width: '100%',
								margin: 'auto !important',
								marginTop: '20px',
								height: '45px',
								boxSizing: 'border-box',
								boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
								borderRadius: '5px',
								textTransform: 'none',
							}}
							onClick={handleSubmit(onSubmit)}
						>
							Rehin Alacaklıyı Ekle
						</Button>
					</>
				) : (
					<>
					<h3>Dain-i Mürtehin (Rehinli Alacaklı) Seçimi </h3>
					<p className="desc">
						<span>Dain-i Mürtehin taşıtınız üzerinde alacaklı bir kurum olduğu durumlarda poliçenizde olması gerekmektedir. Bu ürüne Dain-i Mürtehin eklemek için poliçeyi satın aldıktan sonra
							<a
                    href={isKoalaySS ? 'tel:4448883' : 'tel:02165700800'}
                    style={{ textDecoration: 'none', color: 'rgb(0, 74, 153)' }}
                  >
                    {isKoalaySS ? '444 88 83' : '0216 570 08 00'}
                  </a>
							 no’lu telefon numaramızdan çağrı merkezimize ulaşarak kolaylıkla Dain-i Mürtehin ekletebilirsiniz.</span>
					</p>
					</>
					)
			}
		</div>
	);
};

export default DainiMurtehin;
