import React from 'react';
import { useSelector } from 'react-redux';
import FormBuilder from '../../../../../shared/formBuilder/FormBuilder';
import createCustomerInfoForm from '../../../../data/createForm/createCustomerInfoForm';

export const CustomerInfoForm = (props) => {
  const {
    formHookObject,
    submitForm,
    occupationProps,
    genderProps,
    pregnancyProps,
    ongoingInsuranceProps,
    userAgreementProps,
    advanceToFamily,
  } = props;

  const { insureds, isFamilyFlow } = useSelector((state) => state.info);
  const currentInsured = insureds[insureds.length - 1];

  const infoFormObject = createCustomerInfoForm({
    formHookObject, submitForm, occupationProps, genderProps, pregnancyProps, ongoingInsuranceProps, userAgreementProps, currentInsured, advanceToFamily, isFamilyFlow,
  });

  return (
    <>
      <form>
        <FormBuilder formBuilderObject={infoFormObject} submitForm={submitForm} />
      </form>
    </>
  );
};

export default CustomerInfoForm;
