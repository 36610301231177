export const treatmentTypesEnum = {
  Yatarak: 0,
  YatarakAyakta: 1,
  YatarakAyaktaDogum: 2,
};

export const hospitalNetworksEnum = {
  Kapsamli: 0,
  Limitli: 1,
};
export const treatmentCountsEnum = {
  OneToFive: 0,
  SixToTen: 1,
};
