import React from "react";
import "./no-offer.scss";
import WarningSVG from "../../../../../../shared/assets/img/svg/warning.svg";
import useWindowSize from "../../../../../../shared/_hooks/windowResizeHook";

export const NoOffer = () => {
  const screenSize = useWindowSize();
  const isMobile = screenSize.width <= 880;

  return (
    <>
      <div className="no-offer-container no-offer-container-large">
        <img
          className="zero-offer-img"
          src={isMobile ? WarningSVG : "https://static.koalay.com/Images/KoalayApp/Quotation/zeroOffer.svg"}
          alt="noQuote"
          srcSet=""
        />
        <div className="no-offer-description">
          <p className="warning-text" style={{ fontWeight: "700" }}>
            Sizin için şu anda sigorta firmalarından teklif alamadık. Daha sonra tekrar deneyebilirsiniz.
          </p>
          <p className="explanation-header">Teklif alınamama nedenleri başlınca şunlar olabilir:</p>
          <div className="no-offer-content">
            <ul className="zero-offer-details">
              <li>Ulusal sigorta alt yapısından kaynaklanan genel kesintiler</li>
              <li>Sistemsel hatalar</li>
              <li>Sigorta şirketlerinin size uygun teklif çalışamaması</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
export default NoOffer;
