import { Box, Grid } from '@mui/material';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';

import '../../../shared/assets/styles/components/main-box.scss';

export const MainBox = ({ gap, ...props }) => {
  const [state, setState] = useState(false);

  return (
    <Box className="main-box" style={{ gap }}>
      {props.children}
    </Box>
  );
};

export default (MainBox);
