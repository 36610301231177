import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter as Router, Route, Switch,
} from 'react-router-dom';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import './App.scss';
import KLHeader from './motor/views/layout/KLHeader';
import StepsContainer from './motor/views/layout/StepsContainer';
import OfferPage from './motor/views/pages/Offer/OfferPage';
import { GA_ID } from './shared/_helpers/env';
import KoalayLoading from './motor/views/components/KoalayLoading';
import Toastr from './shared/Toastr';
import loadingKlActions from './motor/actions/loadingActions';
import { isQuotation } from './shared/_helpers/stepHelper';
import Confirmation from './motor/views/pages/Confirmation/Confirmation';
import Checkout from './motor/views/pages/Checkout/Checkout';
import Thankyou from './motor/views/pages/Thankyou/Thankyou';
import useUserState from './shared/_hooks/userStateHook';
import PdfView from './motor/views/pages/PdfPages/PdfView';
import PdfDownload from './motor/views/pages/PdfPages/PdfDownload';
import Paratika from './motor/views/components/widget/Paratika';
import Cancellation from './motor/views/pages/Cancellation/Cancellation';
import { CancellationSuccess } from './motor/views/pages/Cancellation/Success';
import MyPolicies from './motor/views/pages/MyPolicies/MyPolicies';
import Info from './motor/views/pages/Steps/Info';
import CarInfo from './motor/views/pages/Steps/CarInfo';
import ContactInfo from './motor/views/pages/Steps/ContactInfo';
import LicenseInfo from './motor/views/pages/Steps/LicenseInfo';
import BirthdateInfo from './motor/views/pages/Steps/OptionalSteps/BirthdateInfo';
import CityDistrict from './motor/views/pages/Steps/OptionalSteps/CityDistrict';
import BaseStepContainer from './motor/views/layout/BaseStepContainer';
import MyPoliciesEmptyPage from './motor/views/pages/MyPoliciesPage/MyPoliciesEmptyPage';
import MyPoliciesPage from './motor/views/pages/MyPoliciesPage/MyPoliciesPage';
import getFunnelInfo from './shared/apiOperations/getFunnelInfo';
import getCustomerQuotes from './shared/apiOperations/getCustomerQuotes';
import headerActions from './motor/actions/headerAction';
import pageList from './motor/data/pageList';

ReactGA.initialize(GA_ID);
export const MotorApp = () => {
  const dispatch = useDispatch();
  const { useHeader } = useSelector((state) => state.header);
  const { additionalInfo } = useSelector((state) => state.theme);
  const [quotes, setQuotes] = useState(null);
  const [appContinueProcess, setAppContinueProcess] = useState(null);
  const [customerQuotesLoaded, setCustomerQuotesLoaded] = useState(false);

  const URL_PARAMS = new URLSearchParams(document.location.search);
  const JT = URL_PARAMS.get('jt') || document.location.href.split('jt=')[1];

  const { quotation } = useSelector((state) => state.shell);
  const { setUserState } = useUserState();

  useEffect(() => {
    if (quotes !== null) {
      setCustomerQuotesLoaded(true);
    }
  }, [quotes]);

  const getPageObject = (pageName) => {
    let pageObject = Object.values(pageList).find((item) => {
      if (item.pageName === pageName) {
        return item;
      }
    });
    if (!pageObject) {
      pageObject = pageList.default;
      pageObject.pageName = pageName;
    }
    return pageObject;
  };

  useEffect(() => {
    let pageName = window.location.pathname.split('/').pop();
    if (pageName === '' || pageName === 'Quotation') {
      pageName = 'quotation';
    }
    const pageParameters = getPageObject(pageName);

    const isFirstVisit = pageName !== '' || pageName !== 'quotation';
    localStorage.setItem('isFirstVisit', isFirstVisit);

    // TODO replace this index case with sbn coming from url resolver.
    if (document.location.href.indexOf('hesapkurdu') >= 0) {
      TagManager.initialize({ gtmId: 'GTM-5B9SDM3' });
    } else {
      TagManager.initialize({ gtmId: 'GTM-N34DLK' });
    }

    // Header operations
    if (pageParameters.hasHeader) {
      if (appContinueProcess) {
        dispatch(headerActions.setUseHeader(false));
      } else {
        dispatch(headerActions.setUseHeader(true));
      }
    }

    if (!pageParameters.hasStepper) {
      dispatch(headerActions.setUseStepper(false));
    }

    const checkQuotation = isQuotation();

    if (checkQuotation) {
      dispatch(loadingKlActions.showKlLoadingEmpty());
    }
    if (
      !pageParameters.hasJt
      || (pageName.toLowerCase() === 'quotation' && !JT)
    ) {
      setQuotes([]);
      if (pageName.toLowerCase() === 'quotation') {
        window.location.href = `/quotation/info${document.location.search}`;
      } else {
        dispatch(loadingKlActions.hideKlLoading());
      }
    } else {
      getFunnelInfo(dispatch, 1, (funnelData) => {
        getCustomerQuotes(dispatch, funnelData, setUserState, (customerQuotes, QuotesState) => {
          setQuotes(QuotesState);
        }, additionalInfo.phone);
      });
    }
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    (customerQuotesLoaded) ? (
      <Router>
        {Object.keys(quotation).length > 0 && (
        <Switch>
          <Route path="/quotation/summary" exact render={() => <OfferPage />} />
          <Route path="/quotation/confirmation" exact render={() => <Confirmation />} />
          <Route path="/quotation/checkout" exact render={() => (<Checkout />)} />
          <Route path="/quotation/thankyou" exact render={() => <Thankyou />} />
          <Route path="/quotation/openPDF" exact render={() => <PdfView />} />
          <Route path="/quotation/PaymentPopup/Index" exact render={Paratika} />
          <Route
            path="/quotation/CarInfo"
            exact
            render={() => (
              <BaseStepContainer>
                <CarInfo />
              </BaseStepContainer>
            )}
          />
          <Route
            path="/quotation/ContactInfo"
            exact
            render={() => (
              <BaseStepContainer>
                <ContactInfo />
              </BaseStepContainer>
            )}
          />
          <Route
            path="/quotation/LicenseInfo"
            exact
            render={() => (
              <BaseStepContainer>
                <LicenseInfo />
              </BaseStepContainer>
            )}
          />
          <Route
            path="/quotation/BirthdateInfo"
            exact
            render={() => (
              <BaseStepContainer>
                <BirthdateInfo />
              </BaseStepContainer>
            )}
          />
          <Route
            path="/quotation/CityDistrict"
            exact
            render={() => (
              <BaseStepContainer>
                <CityDistrict />
              </BaseStepContainer>
            )}
          />
          <Route
            path="/quotation"
            exact
            render={() => (
              <StepsContainer
                quotes={quotes}
                appContinueProcess={appContinueProcess}
                setAppContinueProcess={setAppContinueProcess}
              />
            )}
          />
        </Switch>
        )}
        {Object.keys(quotation).length <= 0 && (
        <Switch>
          <Route path="/quotation/downloadPdf" exact render={PdfDownload} />
          <Route path="/quotation/police-iptal-talebi" exact render={() => (<Cancellation />)} />
          <Route path="/quotation/police-iptal-talebi-basarili" exact render={() => (<CancellationSuccess />)} />
          <Route path="/quotation/info" exact render={() => (<Info />)} />
          <Route path="/quotation/police-giris-yap" exact render={() => (<MyPolicies />)} />
          <Route path="/quotation/policem-yok" exact render={() => (<MyPoliciesEmptyPage />)} />
          <Route path="/quotation/policelerim" exact render={() => (<MyPoliciesPage />)} />
        </Switch>
        )}
      </Router>
    ) : (
      <Toastr />
    )
  );
};

export default MotorApp;
