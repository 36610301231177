import React from 'react';
import { MenuItem, Paper, Popper } from '@mui/material';
import fullWidthInputSx from '../../tss/data/fullWidthInputSx';
import { KLInput } from '../views/components/form/KLInput';
import KLAutoComplete from '../../shared/formComponents/KLAutoComplete';
import KLRadioGroup from '../../shared/formComponents/KLRadioGroup';
import { renewalModelRadioOptions } from './renewalModelRadioOptions';
import CascoCodeInput from '../views/pages/Steps/CarInfoComponents/components/CascoCodeInput';
import { birthDateValidation, dateValidation, minLength, requiredArea, yearValidation } from '../../shared/_helpers/validations';
import { processTypes } from './processTypes';

String.prototype.capitalizeFirstLetterOfEveryWord = function () {
  let newSentence = this.valueOf().toLowerCase().split(' ');
  for (let i = 0; i < newSentence.length; i += 1) {
    //* Ternary is needed because otherwise things like (RENAULT) would become (renault)
    newSentence[i] = newSentence[i].charAt(0) !== '(' ? newSentence[i].charAt(0).toUpperCase() + newSentence[i].slice(1) : newSentence[i][0] + newSentence[i].charAt(1).toUpperCase() + newSentence[i].slice(2);
  }
  newSentence = newSentence.join(' ');
  return newSentence;
};

const CustomPaper = (props) => {
  const { isMobile } = props;
  return (
    <Paper
      style={{
		  fontSize: '13px',
      }}
      elevation={8}
      {...props}
    />
  );
};

const auxlessObject = {
	ProcessStep: {
    form: {
      formBuilderProps: {
        type: 'input',
        component: <KLRadioGroup />,
        props: {
					name: 'ProcessStep',
					label: 'Hangi işlemi yapmak istiyorsunuz?',
          options: processTypes,
        },
      },

      formProps: 'processTypeObject',
    },
  },
	VehicleType: {
    form: {
      formBuilderProps: {
        type: 'input',
        component: <KLAutoComplete />,
        props: {
          name: 'vehicleType',
          label: 'Aracınızın türünü seçiniz',
          // sx: fullWidthInputSx,
          renderParams: {
            name: 'Text',
            code: 'Value',
          },
          openOnFocus: true,
          filterOptions: (x, e) => {
            if (e.inputValue) {
              return x.filter(
                (elem) => elem.Value.includes(e.inputValue)
								|| elem.Text.toLocaleLowerCase('tr').includes(e.inputValue.toLocaleLowerCase('tr')),
              );
            }
            return x;
          },
					PaperComponent: (paperProps) => CustomPaper(paperProps),
          ListboxProps: {
            style: {
              maxHeight: 250,
            },
          },
        },
      },

      formProps: 'vehicleTypeObject',
    },
  },
  MarkaKod: {
    form: {
      formBuilderProps: {
        type: 'input',
        component: <KLAutoComplete />,
        props: {
          name: 'brand',
          label: 'Aracınızın markasını seçiniz',
          // sx: fullWidthInputSx,
          renderParams: {
            name: 'Text',
            code: 'Value',
          },
          openOnFocus: true,

          filterOptions: (x, e) => {
            if (e.inputValue) {
              return x.filter(
                (elem) => elem.Value.includes(e.inputValue) || elem.Text.includes(e.inputValue.toUpperCase()),
              );
            }
            return x;
          },
        },
      },

      formProps: 'brandObject',
    },
		breakoutKey: 'BrandCode',
  },
  ModelYear: {
    form: {
      formBuilderProps: {
        type: 'input',
        component: <KLInput />,
        props: {
          name: 'ModelYear',
          label: 'Aracınızın model yılını yazınız',
					rules: yearValidation(),
          mask: '9999',
        },
      },
      hasFormHook: true,
      formProps: 'yearObject',
			inputType: 'int',
    },
  },
  ModelRadio: {
    form: {
      formBuilderProps: {
        type: 'input',
        component: <KLRadioGroup />,
        props: {
          options: renewalModelRadioOptions,
        },
      },

      formProps: 'modelRadioObject',
    },
  },
	ModelKod: {
    form: {
      formBuilderProps: {
        type: 'input',
        component: <KLAutoComplete />,
        props: {
          name: 'model',
          label: 'Aracınızın modelini seçiniz',
          renderParams: {
            name: 'Text',
            code: 'Value',
          },
          openOnFocus: true,
          filterOptions: (x, e) => {
            if (e.inputValue) {
              return x.filter(
                (elem) => elem.Value.includes(e.inputValue) || elem.Text.includes(e.inputValue.toUpperCase()),
              );
            }
            return x;
          },
        },
      },

      formProps: 'modelObject',
    },
		breakoutKey: 'ModelKod',
  },
  ModelCascoCode: {
    form: {
      formBuilderProps: {
        type: 'component',
        component: <CascoCodeInput />,
        props: {
          name: 'casco-code',
					sx:{ width: 'auto' },
        },
      },

      formProps: 'modelCascoCodeObject',
    },
  },
  fuelType: {
    form: {
      formBuilderProps: {
        type: 'input',
        component: <KLAutoComplete />,
        props: {
          name: 'fuelType',
          label: 'Yakıt Tipi',
          renderParams: {
            name: 'name',
            code: 'value',
          },
        },
      },

      formProps: 'fuelTypeObject',
    },
		breakoutKey: 'FuelType',
  },
  RegistryDate: {
    form: {
      formBuilderProps: {
        type: 'input',
        component: <KLInput />,
        props: {
          name: 'RegistryDate',
          label: 'Tescil Tarihi',
					rules: dateValidation(),
          mask: '99/99/9999',
        },
      },
      hasFormHook: true,
			inputType: 'date',
    },
  },
  MachineNo: {
    form: {
      formBuilderProps: {
        type: 'input',
        component: <KLInput />,
        props: {
          name: 'MachineNo',
          label: 'Motor No',
					rules: minLength(5),
        },
      },
      hasFormHook: true,
    },
		breakoutKey: 'MotorNo',
  },
  ChassisNo: {
    form: {
      formBuilderProps: {
        type: 'input',
        component: <KLInput />,
        props: {
          name: 'ChassisNo',
          label: 'Şasi No',
					rules: minLength(17),
        },
      },
      hasFormHook: true,
    },
		breakoutKey: 'SasiNo',
  },

};

export default auxlessObject;
