const pageList = {
  default: {
    pageName: '',
    hasHeader: true,
    hasStepper: true,
    hasMainNav: false,
    hasJt: true,
  },
  quotation: {
    pageName: 'quotation',
    hasHeader: true,
    hasStepper: true,
    hasMainNav: true,
    hasJt: true,
  },
  thankyou: {
    pageName: 'thankyou',
    hasHeader: true,
    hasStepper: false,
    hasMainNav: true,
    hasJt: true,
  },
  'police-iptal-talebi': {
    pageName: 'police-iptal-talebi',
    hasHeader: true,
    hasStepper: false,
    hasMainNav: true,
    hasJt: false,
  },
  'police-iptal-talebi-basarili': {
    pageName: 'police-iptal-talebi-basarili',
    hasHeader: true,
    hasStepper: false,
    hasMainNav: true,
    hasJt: false,
  },
  'police-giris-yap': {
    pageName: 'police-giris-yap',
    hasHeader: true,
    hasStepper: false,
    hasMainNav: true,
    hasJt: false,
  },
  'policem-yok': {
    pageName: 'policem-yok',
    hasHeader: true,
    hasStepper: false,
    hasMainNav: true,
    hasJt: false,
  },
  policelerim: {
    pageName: 'policelerim',
    hasHeader: true,
    hasStepper: false,
    hasMainNav: true,
    hasJt: false,
  },
  openPDF: {
    pageName: 'openPDF',
    hasHeader: false,
    hasStepper: false,
    hasMainNav: true,
    hasJt: true,
  },
  downloadPdf: {
    pageName: 'downloadPdf',
    hasHeader: false,
    hasStepper: true,
    hasMainNav: true,
    hasJt: false,
  },
  info: {
    pageName: 'info',
    hasHeader: true,
    hasStepper: true,
    hasMainNav: true,
    hasJt: false,
  },
};

export default pageList;
