import React from 'react';
import '../../../../shared/assets/styles/components/user-consent-modal.scss';

import useWindowSize from '../../../../shared/_hooks/windowResizeHook';

export const UserConsentModal = (props) => {
  const size = useWindowSize();
  const isMobile = size.width <= 880;

  return (
    <>
      <h3 className='consent-modal-header'>Kişisel Verilerin Korunması Mevzuatı Uyarınca <br/> Müşteri Açık Rıza Metni</h3>
      <p>Koalay Com Sigorta ve Reasürans Brokerliği Anonim Şirketi (“Koalay” veya “Şirket”) olarak, 6698 sayılı Kişisel Verilerin Korunması Kanunu kapsamında istisnaya giren (örneğin veri sorumlusunun hukuki yükümlülüğü, sözleşmenin ifası için gerekli olması) haller haricinde, kişisel verilerinizin işlenmesi kapsamında aşağıdaki hususlara ilişkin açık rızanızı talep ediyoruz:</p>
      <p>Şirket ile paylaştığım kimlik, iletişim, müşteri işlem, finans ve risk verilerimin Şirket hizmetlerinin geliştirilmesi ve müşteri memnuniyetinin sağlanması için analiz, promosyon, profilleme, segmentasyon, danışmanlık, reklam, kampanya, anket, satış ve pazarlama faaliyetlerinin gerçekleştirilmesi amacıyla işlenmesi, tarafımla iletişime geçilmesi, ticari elektronik ileti gönderilmesi ve aynı amaçlarla Şirketin anlaşmalı iş ortaklarına, Şirket ortaklarına ve Şirket’in bağlı bulunduğu şirketler topluluğuna ve Şirket’in ana hissedarlarına aktarılmasına,</p>
      <p>Kişisel verilerimin Şirket’in ve Şirket’in bağlı bulunduğu şirketler topluluğunun politika ve prosedürlerine uyum sağlanması ve destek hizmeti alınması amaçlarıyla Şirket’in bulunduğu topluluk şirketlerine, ana hissedarlara ve onlarla ortak kullanılan veri tabanlarına, yurt içinde bulunan bilgi teknolojileri hizmeti sunan firmaların sistemlerine aktarılmasına bilerek ve isteyerek rıza gösterdiğimi beyan ediyorum.</p>

    </>
  );
};

export default UserConsentModal;
