import React, { useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import '../../../shared/assets/styles/components/koalay-loading.scss';
import Fade from '@mui/material/Fade';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import KoalayGreen from '../../../shared/assets/img/svg/koala-green.svg';
import KoalayGray from '../../../shared/assets/img/svg/koala-gray.svg';
import KoalayBlue from '../../../shared/assets/img/svg/koala-blue.svg';
import useWindowSize from '../../../shared/_hooks/windowResizeHook';

export const KoalayLoading = () => {
	const size = useWindowSize();
  const isMobile = size.width <= 880;

  const [counter, setCounter] = useState(0);
  const {
    show, footerList, title, stepLoading,
  } = useSelector((state) => state.loading);
  const isKoalaySS = document.location.href.indexOf('koalay') >= 0;
  const { step } = useSelector((state) => state.step);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter(counter + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [counter]);

  return (
    show ? (
      <div className="koalay-loading-container">
        <Backdrop
          sx={{
            flexDirection: 'column', display: 'flex', background: `rgba(0, 0, 0, ${stepLoading ? '0.5' : '0.85'})`, zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open
        >
          <div className="loading-text">
            {title}
          </div>

          <div className="koalay-loading loading-text-bottom">
            {isKoalaySS || step >= 3 || document.location.pathname.toLowerCase().indexOf('pdf') >= 0 ? (
              <>
                <Fade timeout={1000} in={counter % 3 == 0}><img src={KoalayBlue} /></Fade>
                <Fade timeout={1000} in={counter % 3 == 1}><img src={KoalayGray} /></Fade>
                <Fade timeout={1000} in={counter % 3 == 2}><img src={KoalayGreen} /></Fade>
              </>
            ) : (
              <>
                <CircularProgress size={80} />
              </>
            )}
          </div>
          <div className="loading-text loading-text-bottom">
            {footerList.map((item, index) => (
              <Fade timeout={isMobile ? 500 : 1000} in={(Math.ceil(counter / 3)) % footerList.length == index} key={`loading-kl-info${index}`}>
                <p>{item}</p>
              </Fade>
            ))}
          </div>
        </Backdrop>
      </div>

    ) : <></>
  );
};

export default KoalayLoading;
