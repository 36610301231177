/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-indent */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-expressions */
import {
  Box, Button, Modal,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import '../../../../shared/assets/styles/pages/cancellation.scss';
import { useDispatch } from 'react-redux';
import edit from '../../../../shared/assets/img/svg/edit.svg';

import tickGreenSVG from '../../../../shared/assets/img/svg/tick-green.svg';
import { MAIN_URL } from '../../../../shared/_helpers/env';
import EditConfirmationField from '../Confirmation/EditConfirmationField';
import toastrActions from '../../../actions/toastrAction';
import policyService from '../../../_services/policy.service';
import headerActions from '../../../actions/headerAction';

export const CancellationSuccess = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();

  function newPolicy() {
    document.location.href = MAIN_URL;
  }

  function redirectToForm() {
    document.location.href = 'police-iptal-talebi';
  }

  useEffect(() => {
    if (!location.state || (location.state.customerMail === '' && location.state.customerPhone === '')) {
      redirectToForm();
    }
  }, []);

  useEffect(() => {
		dispatch(headerActions.setUseHeader(true));
    dispatch(headerActions.setMainNav(true));
  }, [props]);

  /* #region  State operations */
  const [customerPhone, setCustomerPhone] = useState(null);
  const [customerMail, setCustomerMail] = useState(null);
  const [teklifTId, setTeklifTId] = useState(null);
  const [renderPage, setRenderPage] = useState(true);
  const [isEditModalOpen, setIsEditModalOpen] = useState(null);
  const [editModalProps, setEditModalProps] = useState({});
  /* #endregion */

  /* #region  Page open */
  useEffect(() => {
    if (location.state !== undefined) {
      setCustomerMail(location.state.customerMail);
      setCustomerPhone(location.state.customerPhone);
      setTeklifTId(location.state.teklifTId);
    }
    if ((location.state.customerPhone === undefined && location.state.customerMail === undefined) || (location.state.customerPhone === null && location.state.customerMail === null) || (location.state.customerPhone === '' && location.state.customerMail === '')) {
      setRenderPage(false);
    }
  }, []);
  /* #endregion */

  /* #region  Edit email modal */
  const editData = (val, type, exactKey) => {
    setEditModalProps({
      value: val,
      title: type,
      exactKey,
    });
  };

  useEffect(() => {
    setIsEditModalOpen(true);
  }, [editModalProps]);

  const submitEmailChange = (newEmail) => {
    const requestObj = {
      teklifTList: teklifTId,
      eMail: newEmail,
    };
    policyService.editEmail(requestObj, (resp) => {
      if (resp.StatusCode === 200) {
        dispatch(toastrActions.setToastrMessage({ variant: 'success', message: 'Düzenlemiş olduğunuz email üzerinden sizinle iletişime geçeceğiz.' }));
        setCustomerMail(newEmail);
      } else {
        dispatch(toastrActions.setToastrMessage({ variant: 'error', message: 'Bir hata oluştu.' }));
      }
    });
  };

  const updateField = (val) => {
    setIsEditModalOpen(false);
    submitEmailChange(val);
  };
  /* #endregion */

  return (renderPage
    && (
      <>
        <Box className="steps-container cancellation-container success-container">
          <div className="cancellation-header">
            <img className="tickgreen" src={tickGreenSVG} alt="Var" width={80} height={80} key="tickGreen" />
            <h2>Poliçe iptal başvurunuzu aldık!</h2>
            <p>Poliçe iptal işleminiz gerçekleştiğinde size aşağıdaki iletişim bilgileriniz üzerinden dönüş sağlayacağız.</p>
          </div>
          <div className="user-info-section">
            <p className="user-info">
              <div className="email-row">
              <p>{customerMail || ''}</p>
              <img
                className="edit-email-icon"
                src={edit}
                onClick={() => {
                  editData(customerMail, 'E-posta', 'customerMail.email');
                }}
              />
              </div>
              <p>{customerPhone || '233 233 23 23'}</p>
            </p>

          </div>
          <Button
            variant="contained"
            className="newPolicyButton"
            onClick={() => newPolicy()}
            style={{ textTransform: 'none' }}
          >
            Yeni Sigorta Teklifi Al
          </Button>
        </Box>
        {isEditModalOpen && Object.keys(editModalProps).length !== 0 && (
          <Modal open={isEditModalOpen} onClose={() => setIsEditModalOpen(false)}>
            <EditConfirmationField
              {...editModalProps}
              handleClose={() => setIsEditModalOpen(false)}
              updateField={updateField}
            />
          </Modal>
        )}

      </>
    )
  );
};

export default withRouter(CancellationSuccess);
