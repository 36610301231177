import React, { useState } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import { Drawer, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import cengo from '../../../../shared/assets/img/svg/Cengiz-transparent.svg';
import '../../../../shared/assets/styles/components/navbar/mobile.scss';
import { MAIN_URL } from '../../../../shared/_helpers/env';

const FitContentIconButton = styled(IconButton)(() => ({
  width: 'fit-content',
  minWidth: 'unset',
  padding: '0',
  marginLeft: '-6px',
}));
const FitContentListItemIcon = styled(ListItemIcon)(() => ({
  width: 'fit-content',
  minWidth: 'unset',
  padding: '0',
  svg: {
    fontWeight: '700',
    color: '#373b3f',
  },
}));
const CustomListItemButton = styled(ListItemButton)(() => ({
  paddingRight: '20px',
}));
const CustomListItemText = styled(ListItemText)(() => ({
  span: {
    fontWeight: '700',
    color: '#373b3f',
  },
}));

export const MainNavMobile = (props) => {
  const [mainDrawer, setMainDrawer] = useState(false);
  const [sigortaDrawer, setSigortaDrawer] = useState(false);
  const [koalayDrawer, setKoalayDrawer] = useState(false);
  const [policeDrawer, setPoliceDrawer] = useState(false);

  const handleMainDrawerOpen = () => {
    setMainDrawer(true);
  };
  const handleSigortaDrawerOpen = () => {
    setSigortaDrawer(true);
  };
  const handleKoalayDrawerOpen = () => {
    setKoalayDrawer(true);
  };
  const handlePoliceDrawerOpen = () => {
    setPoliceDrawer(true);
  };

  const handleMainDrawerClose = () => {
    setMainDrawer(false);
  };
  const handleSigortaDrawerClose = () => {
    setSigortaDrawer(false);
  };
  const handleKoalayDrawerClose = () => {
    setKoalayDrawer(false);
  };
  const handlePoliceDrawerClose = () => {
    setPoliceDrawer(false);
  };

  const redirectToPolicyCancellation = () => {
    props.history.push({
      pathname: '/quotation/police-iptal-talebi',
    });
  };

  const redirectToMyPolicies = () => {
    if (document.location.pathname === '/quotation/policem-yok' || document.location.pathname === '/quotation/policelerim') {
      window.location.reload(false);
    } else {
      props.history.push({
        pathname: '/quotation/police-giris-yap',
      });
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleMainDrawerOpen}
        edge="start"
        style={{
          padding: '0',
          marginLeft: '6px',
        }}
      >
        <MenuIcon />
      </IconButton>
      {/* Main Drawer */}
      <Drawer
        variant="persistent"
        anchor="left"
        open={mainDrawer}
      >
        <div className="drawerHeader mainDrawerHeader">
          <FitContentIconButton
            onClick={handleMainDrawerClose}
          >
            <CloseIcon
              className="closeIcon"
              style={{
                fontSize: '1.9rem',
              }}
            />
          </FitContentIconButton>
          <img className="cengo" src={cengo} />
          <div />
        </div>
        <List>
          <ListItem disablePadding>
            <CustomListItemButton onClick={handleSigortaDrawerOpen}>
              <CustomListItemText>Sigorta</CustomListItemText>
              <FitContentListItemIcon>
                <ChevronRightIcon />
              </FitContentListItemIcon>
            </CustomListItemButton>
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <CustomListItemButton onClick={handleKoalayDrawerOpen}>
              <CustomListItemText>Koalay</CustomListItemText>
              <FitContentListItemIcon>
                <ChevronRightIcon />
              </FitContentListItemIcon>
            </CustomListItemButton>
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <CustomListItemButton onClick={handlePoliceDrawerOpen}>
              <CustomListItemText>Poliçe İşlemleri</CustomListItemText>
              <FitContentListItemIcon>
                <ChevronRightIcon />
              </FitContentListItemIcon>
            </CustomListItemButton>
          </ListItem>
          <Divider />
        </List>
      </Drawer>
      {/* Sigorta Drawer */}
      <Drawer
        variant="persistent"
        anchor="left"
        open={sigortaDrawer}
      >
        <div className="drawerHeader">
          <FitContentIconButton
            onClick={handleSigortaDrawerClose}
          >
            <ChevronLeftIcon
              className="closeIcon"
              style={{
                fontSize: '2.4rem',
              }}
            />
          </FitContentIconButton>
          <img className="cengo" src={cengo} />
          <div />
        </div>
        <h3 className="subdrawer-title">Sigorta</h3>
        <List>
          <p className="subdrawer-subtitle">Araç</p>
          <ListItem disablePadding>
            <CustomListItemButton>
              <ListItemText><a className="subdrawer-link" href={`${MAIN_URL}zorunlu-trafik-sigortasi`}>Zorunlu Trafik Sigortası</a></ListItemText>
            </CustomListItemButton>
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <CustomListItemButton>
              <ListItemText><a className="subdrawer-link" href={`${MAIN_URL}kasko-sigortasi`}>Kasko Sigortası</a></ListItemText>
            </CustomListItemButton>
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <CustomListItemButton>
              <ListItemText><a className="subdrawer-link" href={`${MAIN_URL}motosiklet-sigortasi`}>Motosiklet Kasko Sigortası</a></ListItemText>
            </CustomListItemButton>
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <CustomListItemButton>
              <ListItemText><a className="subdrawer-link" href={`${MAIN_URL}ihtiyari-mali-mesuliyet-sigortasi`}>Limitsiz İhtiyari Mali Mesuliyet Sigortası</a></ListItemText>
            </CustomListItemButton>
          </ListItem>
          <Divider />
          <p className="subdrawer-subtitle">Diğer Sigortalar</p>
          <ListItem disablePadding>
            <CustomListItemButton>
              <ListItemText><a className="subdrawer-link" href={`${MAIN_URL}online-tamamlayici-saglik-sigortasi`}>Tamamlayıcı Sağlık Sigortası</a></ListItemText>
            </CustomListItemButton>
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <CustomListItemButton>
              <ListItemText><a className="subdrawer-link" href={`${MAIN_URL}online-seyahat-saglik-sigortasi`}>Seyahat Sağlık Sigortası</a></ListItemText>
            </CustomListItemButton>
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <CustomListItemButton>
              <ListItemText><a className="subdrawer-link" href={`${MAIN_URL}online-dask-teklifi-al`}>DASK (Zorunlu Deprem Sigortası)</a></ListItemText>
            </CustomListItemButton>
          </ListItem>
          <Divider />
        </List>
      </Drawer>
      {/* Koalay Drawer */}
      <Drawer
        variant="persistent"
        anchor="left"
        open={koalayDrawer}
      >
        <div className="drawerHeader">
          <FitContentIconButton
            onClick={handleKoalayDrawerClose}
          >
            <ChevronLeftIcon
              className="closeIcon"
              style={{
                fontSize: '2.4rem',
              }}
            />
          </FitContentIconButton>
          <img className="cengo" src={cengo} />
          <div />
        </div>
        <h3 className="subdrawer-title">Koalay</h3>
        <List>
          <p className="subdrawer-subtitle">Koalay ile Tanışın</p>
          <ListItem disablePadding>
            <CustomListItemButton>
              <ListItemText><a className="subdrawer-link" href={`${MAIN_URL}koalay-hakkinda/koalay-nedir`}>Koalay Nedir?</a></ListItemText>
            </CustomListItemButton>
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <CustomListItemButton>
              <ListItemText><a className="subdrawer-link" href={`${MAIN_URL}nasil-calisir/5-adimda-sigorta-teklifi-alin`}>Koalay Nasıl Çalışır?</a></ListItemText>
            </CustomListItemButton>
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <CustomListItemButton>
              <ListItemText><a className="subdrawer-link" href={`${MAIN_URL}anlasmali-sigorta-sirketleri`}>Anlaşmalı Sigorta Şirketleri</a></ListItemText>
            </CustomListItemButton>
          </ListItem>
          <Divider />
          <p className="subdrawer-subtitle">Hakkımızda</p>
          <ListItem disablePadding>
            <CustomListItemButton>
              <ListItemText><a className="subdrawer-link" href={`${MAIN_URL}koalay-hakkinda/ekibimiz`}>Ekibimiz</a></ListItemText>
            </CustomListItemButton>
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <CustomListItemButton>
              <ListItemText><a className="subdrawer-link" href={`${MAIN_URL}koalay-hakkinda/oduller`}>Ödüllerimiz</a></ListItemText>
            </CustomListItemButton>
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <CustomListItemButton>
              <ListItemText><a className="subdrawer-link" href={`${MAIN_URL}koalay-hakkinda/reklamlar`}>Reklamlarımız</a></ListItemText>
            </CustomListItemButton>
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <CustomListItemButton>
              <ListItemText><a className="subdrawer-link subdrawer-subtitle" href="https://insurer.koalay.com/login" style={{ paddingLeft: '0' }}>Sigorta Şirketi Portalı</a></ListItemText>
            </CustomListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <CustomListItemButton>
              <ListItemText><a className="subdrawer-link subdrawer-subtitle" href="https://www.koalay.com/blog" style={{ paddingLeft: '0' }}>Koalay Blog</a></ListItemText>
            </CustomListItemButton>
          </ListItem>
        </List>
      </Drawer>
      {/* Police Drawer */}
      <Drawer
        variant="persistent"
        anchor="left"
        open={policeDrawer}
      >
        <div className="drawerHeader">
          <FitContentIconButton
            onClick={handlePoliceDrawerClose}
          >
            <ChevronLeftIcon
              className="closeIcon"
              style={{
                fontSize: '2.4rem',
              }}
            />
          </FitContentIconButton>
          <img className="cengo" src={cengo} />
          <div />
        </div>
        <h3 className="subdrawer-title">Poliçe</h3>
        <List>
          <ListItem disablePadding>
            <CustomListItemButton>
              <ListItemText>
                <a className="subdrawer-link" href="" onClick={() => redirectToMyPolicies()}>Poliçelerim</a>
              </ListItemText>
            </CustomListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <CustomListItemButton>
              <ListItemText>
                <a className="subdrawer-link" href="" onClick={() => redirectToPolicyCancellation()}>Poliçe İptal Talebi</a>
              </ListItemText>
            </CustomListItemButton>
          </ListItem>
          <Divider />
        </List>
      </Drawer>
    </Box>
  );
};

export default MainNavMobile;
