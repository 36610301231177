import { headerTypes } from '../types/header.types';

function setUseHeader(useHeader) {
  return {
    type: headerTypes.SET_USE_HEADER,
    payload: {
      useHeader,
    },
  };
}
function setUseStepper(useStepper) {
  return {
    type: headerTypes.SET_USE_STEPPER,
    payload: {
      useStepper,
    },
  };
}
function setMainNav(mainNav) {
  return {
    type: headerTypes.SET_MAIN_NAV,
    payload: {
      mainNav,
    },
  };
}

const headerActions = {
  setUseHeader,
  setUseStepper,
  setMainNav,
};
export default headerActions;
