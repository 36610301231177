/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState } from 'react';
import Toolbar from '@mui/material/Toolbar';

import '../../../../shared/assets/styles/components/navbar/desktop.scss';
import { MAIN_URL } from '../../../../shared/_helpers/env';

export const MainNavDesktop = (props) => {
  const [sigortaMenu, setSigortaMenu] = useState(false);
  const [koalayMenu, setKoalayMenu] = useState(false);
  const [policeMenu, setPoliceMenu] = useState(false);

  const openSigorta = () => {
    setSigortaMenu(true);
    setKoalayMenu(false);
    setPoliceMenu(false);
  };
  const openKoalay = () => {
    setKoalayMenu(true);
    setSigortaMenu(false);
    setPoliceMenu(false);
  };
  const openPolice = () => {
    setPoliceMenu(true);
    setSigortaMenu(false);
    setKoalayMenu(false);
  };
  const closeSigorta = () => {
    setSigortaMenu(false);
  };
  const closeKoalay = () => {
    setKoalayMenu(false);
  };
  const closePolice = () => {
    setPoliceMenu(false);
  };
  const closeAll = () => {
    closeSigorta();
    closeKoalay();
    closePolice();
  };

  const redirectToPolicyCancellation = () => {
    props.history.push({
      pathname: '/quotation/police-iptal-talebi',
    });
  };

  const redirectToMyPolicies = () => {
    if (document.location.pathname === '/quotation/policem-yok' || document.location.pathname === '/quotation/policelerim') {
      window.location.reload(false);
    } else {
      props.history.push({
        pathname: '/quotation/police-giris-yap',
      });
    }
  };

  useEffect(() => {
    const { devicePixelRatio } = window;
  }, []);

  return (
    <div className="maindiv" onMouseLeave={closeAll}>
      <Toolbar className="toolbar">
        <div className="title-div">
          <h2
            className={sigortaMenu ? 'menu-title active' : 'menu-title'}
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onMouseEnter={openSigorta}
            id="sigorta"
          >
            Sigorta
          </h2>
          {sigortaMenu && <div className="underline">{}</div>}
        </div>
        <div className="title-div">
          <h2
            className={koalayMenu ? 'menu-title active' : 'menu-title'}
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onMouseEnter={openKoalay}
            id="koalay"
          >
            Koalay
          </h2>
          {koalayMenu && <div className="underline">{}</div>}
        </div>
        <div className="title-div">
          <h2
            className={policeMenu ? 'menu-title active' : 'menu-title'}
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onMouseEnter={openPolice}
            id="police"
          >
            Poliçe İşlemleri
          </h2>
          {policeMenu && <div className="underline">{}</div>}
        </div>
      </Toolbar>
      <div className="menu-row">
        <div className="menu" style={{ alignItems: 'flex-start', position: 'relative', right: devicePixelRatio === 1.25 && '40px' }}>
          <div
            className="submenu"
            style={{
              display: sigortaMenu ? 'flex' : 'none', width: '456px',
            }}
            onMouseLeave={closeSigorta}
          >
            <div className="link-list">
              <p className="submenu-title">Araç</p>
              <a href={`${MAIN_URL}zorunlu-trafik-sigortasi`}>Zorunlu Trafik Sigortası</a>
              <a href={`${MAIN_URL}kasko-sigortasi`}>Kasko Sigortası</a>
              <a href={`${MAIN_URL}motosiklet-sigortasi`}>Motosiklet Kasko Sigortası</a>
              <a href={`${MAIN_URL}ihtiyari-mali-mesuliyet-sigortasi`}>Limitsiz İhtiyari Mali Mesuliyet Sigortası</a>
            </div>
            <div className="link-list">
              <p className="submenu-title">Diğer Sigortalar</p>
              <a href={`${MAIN_URL}online-tamamlayici-saglik-sigortasi`}>Tamamlayıcı Sağlık Sigortası</a>
              <a href={`${MAIN_URL}online-seyahat-saglik-sigortasi`}>Seyahat Sağlık Sigortası</a>
              <a href={`${MAIN_URL}online-dask-teklifi-al`}>DASK (Zorunlu Deprem Sigortası)</a>
            </div>
          </div>
        </div>
        <div className="menu" style={{ alignItems: 'center' }}>
          <div className="submenu" style={{ display: koalayMenu ? 'flex' : 'none' }} onMouseLeave={closeKoalay}>
            <div className="link-list" style={{ maxWidth: '180px', marginRight: '20px' }}>
              <p className="submenu-title">Koalay ile Tanışın</p>
              <a href={`${MAIN_URL}koalay-hakkinda/koalay-nedir`}>Koalay Nedir?</a>
              <a href={`${MAIN_URL}nasil-calisir/5-adimda-sigorta-teklifi-alin`}>Koalay Nasıl Çalışır?</a>
              <a href={`${MAIN_URL}anlasmali-sigorta-sirketleri`}>Anlaşmalı Sigorta Şirketleri</a>
            </div>
            <div className="link-list" style={{ marginRight: '30px' }}>
              <p className="submenu-title">Hakkımızda</p>
              <a href={`${MAIN_URL}koalay-hakkinda/ekibimiz`}>Ekibimiz</a>
              <a href={`${MAIN_URL}koalay-hakkinda/oduller`}>Ödüllerimiz</a>
              <a href={`${MAIN_URL}koalay-hakkinda/reklamlar`}>Reklamlarımız</a>
            </div>
            <div className="link-list">
              <a className="submenu-title" href="https://insurer.koalay.com/login">Sigorta Şirketi Portalı</a>
              <a className="submenu-title" href="https://www.koalay.com/blog">Koalay Blog</a>
            </div>
          </div>
        </div>
        <div className="menu" style={{ alignItems: 'flex-end', position: 'relative', right: devicePixelRatio === 1.25 ? '0' : '45px' }}>
          <div className="submenu" style={{ display: policeMenu ? 'flex' : 'none' }} onMouseLeave={closePolice}>
            <div className="link-list">
              <a href="" onClick={() => redirectToMyPolicies()}>Poliçelerim</a>
              <a href="" onClick={() => redirectToPolicyCancellation()}>Poliçe İptal Talebi</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNavDesktop;
