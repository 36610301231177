import axiosInstance from '../../shared/_helpers/axiosHelper';
import { API } from '../../shared/_helpers/env';

async function getQuotes(data, callback) {
  try {
    await axiosInstance
      .post(`${API}/api/quotation/side-products/quotes`, data)
      .then((response) => {
        try {
          callback(response.data.Result, false);
        } catch (error) {
          console.log(error);
        }
      });
  } catch (error) {
    callback(error.response.data.Result, true);
  }
}

async function getQuotationDetail(data, callback) {
  await axiosInstance
    .post(`${API}/api/quotation/side-products/quotation-detail`, data)
    .then((response) => {
      try {
        callback(response.data.Result);
      } catch (error) {
        console.log(error);
      }
    });
}

async function getFilterOptions(data, callback) {
  await axiosInstance
    .get(`${API}/api/quotation/filter-option`, data)
    .then((response) => {
      try {
        callback(response.data.Result);
      } catch (error) {
        console.log(error);
      }
    });
}
async function updateQuotationPlan(data, callback) {
  await axiosInstance
    .post(`${API}/api/quotation/update-quotation-plan`, data)
    .then((response) => {
      try {
        callback(response.data.Result);
      } catch (error) {
        console.log(error);
      }
    });
}
async function sendPolicy(data, callback) {
  await axiosInstance
    .post(`${API}/api/quotation/side-products/send-policy-mail`, data)
    .then((response) => {
      try {
        callback(response.data.Result);
      } catch (error) {
        console.log(error);
      }
    });
}

const quotationService = {
  getQuotes,
  getQuotationDetail,
  getFilterOptions,
  updateQuotationPlan,
  sendPolicy,
};
export default quotationService;
