import React, { useState, useEffect } from 'react';
import '../../../shared/assets/styles/components/progress-bar.scss';
import { useDispatch } from 'react-redux';
import useWindowSize from '../../../shared/_hooks/windowResizeHook';
import { loadingLimit, tssLoadingLimit } from '../../data/shared';
import loadingKlActions from '../../actions/loadingActions';

export const ProgressBar = ({ initialCounter, type='motor' }) => {
  const size = useWindowSize();
  const dispatch = useDispatch();
  const isMobile = size.width <= 880;
  const [loadingCounter, setCounter] = useState(initialCounter);
	const limit = type === 'tss' ? tssLoadingLimit : loadingLimit;
  const noRenderOnMobile = () => isMobile && getProgress() < 55;

  useEffect(() => {
    const interval = setInterval(() => {
      if (limit < loadingCounter + 1) {
        dispatch(loadingKlActions.hideKlLoading());
        dispatch(loadingKlActions.setProgressBar(false));
        clearInterval(interval);
      } else {
        setCounter(loadingCounter + 1);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [loadingCounter]);

  const getProgress = () => {
    // Increasing progress bar every 2 seconds
    const renderPercentage = 40 + Math.round(Math.ceil(loadingCounter / 2) * 1.62);
    return renderPercentage >= 100 ? 100 : renderPercentage;
  };

  const getProgressText = () => {
    // TODO: Text may be dynamic
    if (noRenderOnMobile()) {
      // Hide text on mobile if less than 55% to prevent text overlapping
      return '';
    }
    return getProgress() == 100 ? 'Teklifleriniz tamamlandı.' : 'Size özel teklifler hazırlıyoruz';
  };

  return (
    <div className={`progress-bar ${getProgress() == 100 && 'progress-bar-closed'}`}>
      <span
        className="loading-area"
        data-text={getProgressText()}
        style={{
          paddingLeft: `calc(${getProgress()}% - ${isMobile ? '45' : '60'}px)`,
        }}
      >
        {getProgress()}
        %
      </span>
      {getProgress() !== 100 && <span className={noRenderOnMobile() ? '' : 'loading-dots'} />}
    </div>
  );
};

export default ProgressBar;
