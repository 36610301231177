import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import { Skeleton } from "@mui/material";
import trashSvg from "../../../../../../shared/assets/img/svg/TrashFilter.jsx";
import infoSvg from "../../../../../../shared/assets/img/svg/info.svg";

import classes from "./SkeletonFilterSection.module.scss";
import useWindowSize from "../../../../../../shared/_hooks/windowResizeHook";

const SkeletonFilterSection = forwardRef((props, ref) => {
  const size = useWindowSize();
  const isMobile = size.width <= 880;

  return (
    <div className={classes.wrapper}>
      <h2 className={`${classes.heading} ${classes.desktopHeading}`}>Sigortanı Kişiselleştir</h2>
      <div className={classes.container}>
        <div className={classes.mobileHeadingRow}>
          <h2 className={`${classes.heading} ${classes.mobileHeading}`}>Sigortanı Kişiselleştir</h2>
          <div className={classes.clearButton}>
            <img src={trashSvg} alt="trash-icon" />
            <p>Temizle</p>
          </div>
        </div>
        <div className={classes.filterHeadingContainer}>
          <p className={classes.filterHeading}>Tedavi Türü</p>
          <img src={infoSvg} alt="info-icon" />
        </div>
        <div
          className={classes.filterOptionContainer}
          style={{ display: "flex", flexDirection: isMobile ? "row" : "column" }}
        >
          <Skeleton
            variant="rectangular"
            width={isMobile ? 90 : 280}
            height={isMobile ? 40 : 42}
            style={{
              marginBottom: "8px",
              marginRight: isMobile ? "8px" : 0,
            }}
          />
          <Skeleton
            variant="rectangular"
            width={isMobile ? 90 : 280}
            height={isMobile ? 40 : 42}
            style={{
              marginBottom: "8px",
              marginRight: isMobile ? "8px" : 0,
            }}
          />
          <Skeleton
            variant="rectangular"
            width={isMobile ? 90 : 280}
            height={isMobile ? 40 : 42}
            style={{
              marginBottom: "8px",
              marginRight: isMobile ? "8px" : 0,
            }}
          />
        </div>
        <div className={classes.filterHeadingContainer}>
          <p className={classes.filterHeading}>Hastane Ağı</p>
          <img src={infoSvg} alt="info-icon" />
        </div>
        <div
          className={classes.filterOptionContainer}
          style={{ display: "flex", flexDirection: isMobile ? "row" : "column" }}
        >
          <Skeleton
            variant="rectangular"
            width={isMobile ? 90 : 280}
            height={isMobile ? 40 : 42}
            style={{
              marginBottom: "8px",
              marginRight: isMobile ? "8px" : 0,
            }}
          />
          <Skeleton
            variant="rectangular"
            width={isMobile ? 90 : 280}
            height={isMobile ? 40 : 42}
            style={{
              marginBottom: "8px",
              marginRight: isMobile ? "8px" : 0,
            }}
          />
        </div>
        <div className={classes.filterHeadingContainer}>
          <p className={classes.filterHeading}>Ayakta Tedavi Adeti</p>
          <img src={infoSvg} alt="info-icon" />
        </div>
        <div
          className={classes.filterOptionContainer}
          style={{ display: "flex", flexDirection: isMobile ? "row" : "column" }}
        >
          <Skeleton
            variant="rectangular"
            width={isMobile ? 90 : 280}
            height={isMobile ? 40 : 42}
            style={{
              marginBottom: "8px",
              marginRight: isMobile ? "8px" : 0,
            }}
          />
          <Skeleton
            variant="rectangular"
            width={isMobile ? 90 : 280}
            height={isMobile ? 40 : 42}
            style={{
              marginBottom: "8px",
              marginRight: isMobile ? "8px" : 0,
            }}
          />
        </div>
      </div>
    </div>
  );
});

export default SkeletonFilterSection;
