import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import KoalayLogo from '../../../../../shared/assets/img/png/koalay.png';
import HKLogo from '../../../../../shared/assets/img/png/Hesapkurdu.png';
import '../../../../../shared/assets/styles/pages/redirect.scss';
import { removeCustomEventsFromDataLayer } from '../../../../../shared/_helpers/helperFunctions';

export const RedirectPage = (props) => {
  const URL_PARAMS = new URLSearchParams(document.location.search);
  const jt = URL_PARAMS.get('jt') !== null ? URL_PARAMS.get('jt') : 'info_page_no_jt';
  const quoteGuid = URL_PARAMS.get('qGuid') !== null ? URL_PARAMS.get('qGuid') : 'info_page_no_qGuid';
  const { additionalInfo } = useSelector((state) => state.theme);
  const { consumer } = useSelector((state) => state.consumer);
  useEffect(() => {
    removeCustomEventsFromDataLayer(window.dataLayer);
    window.dataLayer.push({
      event: 'hk_kl_redirection_page',
      funnel_variables: {
        jt,
        qGuid: quoteGuid,
      },
    });
  }, []);

  return (
    <>
      <div className="dealer-information-notification">
        <div className="inner">
          <b className="redirection-text">
            Sigorta teklifleri için iş ortağımız ve sigorta brokeri Koalay.com’a yönlendiriliyorsunuz...
          </b>
          <p className="redirection-text-secondary">Mevzuat gereği, sigorta teklifleri sadece yetkili sigorta aracıları tarafından verilebilir.</p>
          <div className="logo-container">
            <img
              className="referrer-logo"
              src={JSON.parse(consumer).partnerSbn === 'HSPKDLK'
                ? HKLogo
                : additionalInfo.dealerLogo}
            />
            <div className="mobile-rotate">
              <div className="lds-ellipsis">
                <div />
                <div />
                <div />
              </div>
              {' '}
            </div>
            <img className="koalay-logo" src={KoalayLogo} alt="Koalay.com" />
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(RedirectPage);
