import React, { useEffect, useState } from "react";
import "../../../../shared/assets/styles/offer-filters.scss";

import { Button, Checkbox, Collapse, FormControlLabel, Radio, RadioGroup,Modal } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import useWindowSize from "../../../../shared/_hooks/windowResizeHook";
import EventBus from "../../../../shared/_helpers/EventBus";
import thrash from "../../../../shared/assets/img/svg/thrash.svg";
import editSVG from "../../../../shared/assets/img/svg/edit.svg";
import { CustomCheckbox, CustomCheckboxChecked } from "../../components/form/CustomCheckBox";
import vehicleService from "../../../_services/vehicle.services";
import loadingKlActions from "../../../actions/loadingActions";
import edit from "../../../../shared/assets/img/svg/edit.svg";
import EditPersonalInfo from "./EditPersonalInfo";
import customerService from "../../../_services/customer.service";
import { formatDateToString } from "../../../../shared/_helpers/dateFormat";
import { useRollbar } from "@rollbar/react";

export const OfferFilters = (props) => {
  const {
    filterProductSubTypeModels,
    productFilter,
    subTypeFilter,
    insurerFilter,
    typeFilterList,
    brandFilterList,
    subTypeFilterList,
    filterByType,
    filterByInsurer,
    filterBySubType,
    carInfo,
    filteredOffers,
    isLoading,
    kaskoCount,
    handleClearFilter,
    setIsCarTypeModalOpended,
    selectedUsageType,
    usageTypes,
    noOffershown,
    userInfo,
		setUserInfo,
		setShouldOffersRerender,
		history,
		setSelectedUsageType,
    CCinfo,
    policyEndDates
  } = props;
  const size = useWindowSize();
	const Rollbar = useRollbar();
  const isMobile = size.width <= 880;
  const dispatch = useDispatch();
  const URL_PARAMS = new URLSearchParams(document.location.search);
  const quoteGuid = URL_PARAMS.get("qGuid") !== null ? URL_PARAMS.get("qGuid") : props.location.state?.quoteGuid;
  const jt = URL_PARAMS.get("jt") !== null ? URL_PARAMS.get("jt") : props.location.state.jt;
  const [subTypeFilters, setSubTypeFilters] = useState([]);
  const [productCollapse, setProductCollapse] = useState(true);
  const [subTypeCollapse, setSubTypeCollapse] = useState(true);
  const [insurerCollapse, setInsurerCollapse] = useState(true);
  const [carTypeCollapse, setCarTypeCollapse] = useState(true);
  const [carTypeValue, setCarTypeValue] = useState(selectedUsageType ? selectedUsageType?.Value : null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const { customer, isKPSActive, isVkn } = useSelector((state) => state.info);
  const { isCallCenter } = useSelector((state) => state.shell); //! CHECK THIS


  const fuelType = {
    0: "BILINMIYOR",
    1: "BENZINLI",
    2: "DIZEL",
    4: "BENZINLILPG",
    5: "BENZINELEKTRIK",
    6: "CING",
    7: "ELEKTRIK",
    8: "MOTORSUZ",
  };
  useEffect(() => {
    const temp = [];
    Object.keys(subTypeFilter).map((sub) => {
      filterProductSubTypeModels.map((fp) => {
        fp.subTypes.map((type) => {
          if (type.textValue.value === sub) {
            temp.push({ name: type.textValue.text, count: subTypeFilter[sub], value: sub });
            setSubTypeFilters(temp);
          }
        });
      });
    });
  }, [subTypeFilter]);

  const selectUsageType = (type) => {
    const vehicleObject = {
      quoteGuid,
      CustomerUID: jt,
      ProcessStep: carInfo.processStep,
      VehicleTarifeCode: carTypeValue,
      VehicleUsedType: 0,
      VehicleBrandCode: carInfo.brandCode,
      VehicleModelYear: carInfo.year.toString(),
      ModelKodBirlesik: carInfo.modelCode,
      VehicleFuelType: parseInt(carInfo.fuelType),
      IsConfirmation: false,
    };
    dispatch(loadingKlActions.showKlLoadingEmpty());
    vehicleService.vehiclePut(vehicleObject, (response) => {
      const params = new URLSearchParams(window.location.search);
      params.set("qGuid", response.newTeklifGuid);
      const newSearchParams = params.toString();
      const { location } = window;
      const redirectUrl = `${location.origin + location.pathname}?${newSearchParams}`;
      window.location.href = redirectUrl;
    });
  };

  const editData = () => {
    setIsEditModalOpen(true);
		// Rollbar.info(`EditPersonalInfo opened jt:${jt} qGuid:${quoteGuid}`);
  };

  const closeDrawer = () => {
    EventBus.emit("closeDrawer");
  };
  const filterProductType = (e, p) => {
    filterByType({ type: e.target.id, value: p });
  };

  const filterSubType = (e, p) => {
    filterBySubType({ type: e.target.id, value: p });
  };

  const filterInsurer = (e, p) => {
    filterByInsurer({ type: e.target.id, value: p });
  };

  const handleCarType = (e) => {
    if (e.target.value !== selectedUsageType?.Value) {
      handleClearFilter();
    }
    setCarTypeValue(e.target.value);
  };

  const isSigortaTypeDisabled = (p) => {
    const isDisabled =
      subTypeFilterList.length > 1 || brandFilterList.length > 1
        ? filteredOffers.filter((product) => product.Brans === p).length === 0
        : false;
    return isDisabled || (isMobile && carTypeValue !== selectedUsageType?.Value);
  };

  return (
    <>
      <div
        className="offer-filters"
        style={{ marginTop: kaskoCount === undefined && !isMobile && !noOffershown ? "-41px" : "0" }}
      >
        {isMobile && (
          <div className="mobile-filter-header">
            <h2>Filtreler</h2>
            <CloseIcon
              style={{
                width: isMobile ? "22px" : "26px",
                height: isMobile ? "22px" : "26px",
              }}
              onClick={closeDrawer}
            />
          </div>
        )}
        <div className="filter-section">
          <div className="filter-group">
            <span className="filter-type" onClick={() => (isMobile ? setProductCollapse(!productCollapse) : "")}>
              <p className="text" style={{ fontWeight: "600" }}>
                Sigorta Türü
              </p>
              {isMobile && (
                <KeyboardArrowDownIcon
                  style={{ transform: !productCollapse ? "rotate(180deg)" : "", fontSize: "2.3rem" }}
                />
              )}
            </span>
            {isLoading ? (
              <Stack spacing={1}>
                <Skeleton variant="rectangular" width={110} height={26} />
                <Skeleton variant="rectangular" width={110} height={26} />
              </Stack>
            ) : (
              <Collapse in={productCollapse} timeout="auto" unmountOnExit>
                {Object.keys(productFilter)
                  .sort()
                  .reverse()
                  .map((p) => (
                    <FormControlLabel
                      className="filter"
                      disabled={isSigortaTypeDisabled(p)}
                      style={{ opacity: isSigortaTypeDisabled(p) ? ".5" : "1" }}
                      control={
                        <Checkbox
                          onChange={filterProductType}
                          id={p}
                          checked={typeFilterList.some((t) => t === p)}
                          sx={{
                            "& svg": { color: "#0055A5" },
                          }}
                          icon={<CustomCheckbox />}
                          checkedIcon={<CustomCheckboxChecked />}
                        />
                      }
                      label={
                        <p
                          className="filter-text desc"
                          style={{
                            textTransform: "capitalize",
                            opacity: isMobile && carTypeValue !== selectedUsageType?.Value ? ".5" : "1",
                          }}
                        >
                          {p} ({productFilter[p]})
                        </p>
                      }
                    />
                  ))}
              </Collapse>
            )}
          </div>
          <div className="filter-group">
            <span className="filter-type" onClick={() => (isMobile ? setSubTypeCollapse(!subTypeCollapse) : "")}>
              <p className="text" style={{ fontWeight: "600" }}>
                Teminat Kapsamı
              </p>
              {isMobile && (
                <KeyboardArrowDownIcon
                  style={{ transform: !subTypeCollapse ? "rotate(180deg)" : "", fontSize: "2.3rem" }}
                />
              )}
            </span>
            {isLoading ? (
              <Stack spacing={1}>
                <Skeleton variant="rectangular" width={110} height={26} />
                <Skeleton variant="rectangular" width={90} height={26} />
                <Skeleton variant="rectangular" width={160} height={26} />
                <Skeleton variant="rectangular" width={110} height={26} />
              </Stack>
            ) : (
              <Collapse in={subTypeCollapse} timeout="auto" unmountOnExit>
                <span
                  style={{
                    overflowY: !isMobile ? "auto" : "unset",
                    maxHeight: !isMobile ? "240px" : "unset",
                    display: "flex",
                  }}
                >
                  {subTypeFilters.map((t) => (
                    <FormControlLabel
                      className="filter"
                      control={
                        <Checkbox
                          onChange={filterSubType}
                          id={t.value}
                          checked={subTypeFilterList.some((st) => st === t.value)}
                          disabled={isMobile && carTypeValue !== selectedUsageType?.Value}
                          style={{ opacity: isMobile && carTypeValue !== selectedUsageType?.Value ? ".5" : "1" }}
                          sx={{
                            "& svg": { color: "#0055A5" },
                          }}
                          icon={<CustomCheckbox />}
                          checkedIcon={<CustomCheckboxChecked />}
                        />
                      }
                      label={
                        <p
                          className="filter-text desc"
                          style={{
                            textTransform: "capitalize",
                            opacity: isMobile && carTypeValue !== selectedUsageType?.Value ? ".5" : "1",
                          }}
                        >
                          {t.name.toLowerCase()} ({t.count})
                        </p>
                      }
                    />
                  ))}
                </span>
              </Collapse>
            )}
          </div>
          <div className="filter-group">
            <span className="filter-type" onClick={() => (isMobile ? setInsurerCollapse(!insurerCollapse) : "")}>
              <p className="text" style={{ fontWeight: "600" }}>
                Sigorta Şirketi
              </p>
              {isMobile && (
                <KeyboardArrowDownIcon
                  style={{ transform: !insurerCollapse ? "rotate(180deg)" : "", fontSize: "2.3rem" }}
                />
              )}
            </span>
            {isLoading ? (
              <Stack spacing={1}>
                <Skeleton variant="rectangular" width={110} height={26} />
                <Skeleton variant="rectangular" width={90} height={26} />
                <Skeleton variant="rectangular" width={160} height={26} />
                <Skeleton variant="rectangular" width={110} height={26} />
              </Stack>
            ) : (
              <Collapse in={insurerCollapse} timeout="auto" unmountOnExit>
                <span
                  style={{
                    overflowY: !isMobile ? "auto" : "unset",
                    maxHeight: !isMobile ? "240px" : "unset",
                    display: "flex",
                  }}
                >
                  {Object.keys(insurerFilter).map((i) => (
                    <FormControlLabel
                      className="filter"
                      control={
                        <Checkbox
                          id={i}
                          onChange={filterInsurer}
                          checked={brandFilterList.some((b) => b === i)}
                          disabled={isMobile && carTypeValue !== selectedUsageType?.Value}
                          style={{ opacity: isMobile && carTypeValue !== selectedUsageType?.Value ? ".5" : "1" }}
                          sx={{
                            "& svg": { color: "#0055A5" },
                          }}
                          icon={<CustomCheckbox />}
                          checkedIcon={<CustomCheckboxChecked />}
                        />
                      }
                      label={
                        <p
                          className="filter-text desc"
                          style={{
                            textTransform: "capitalize",
                            opacity: isMobile && carTypeValue !== selectedUsageType?.Value ? ".5" : "1",
                          }}
                        >
                          {i} ({insurerFilter[i]})
                        </p>
                      }
                    />
                  ))}
                </span>
              </Collapse>
            )}
          </div>

          {isMobile && (
            <>
              <div className="info-section">
                <p className="text" style={{ fontWeight: "600" }}>
                  Araç Bilgileri
                </p>
                <span className="car-info">
                  {Object.keys(carInfo).length == 0 ? (
                    <Skeleton variant="rectangular" width="100%" />
                  ) : (
                    <p className="disclaimer">
                      {" "}
                      {carInfo.year} / {carInfo.brand?.replaceAll("/", " / ").replaceAll("  ", " ")} /{" "}
                      {carInfo.model?.replaceAll("/", " / ").replaceAll("  ", " ")} / {fuelType[carInfo.fuel]}
                      {!((carInfo.processStep === 10 || carInfo.processStep === 30) && selectedUsageType) && (
                        <> / {usageTypes.filter((t) => t.Value == carInfo.usageType)[0]?.Text.toUpperCase()}</>
                      )}
                    </p>
                  )}
                </span>
              </div>
              <div className="info-section" style={{ marginBottom: "20px" }}>
                <p className="text" style={{ fontWeight: "600" }}>
                  Kişisel Bilgiler
                </p>
                <span className="user-info">
                  {Object.keys(carInfo).length == 0 ? (
                    <Skeleton variant="rectangular" width="100%" />
                  ) : (
                    <>
											{!isVkn && (
											<>
												<p className="disclaimer">
													<b>Ad Soyad: </b>
													{userInfo.name + " " + userInfo.surname}
												</p>
												<p className="disclaimer">
													<b>Doğum Tarihi: </b>
													{userInfo.birthDate}
												</p>
											</>
											)}
											{isVkn && (
											<>
												<p className="disclaimer">
													<b>Firma adı: </b>
													{userInfo.name}
												</p>
											</>
											)}
                      <p className="disclaimer">
                        <b>Kimlik/Vergi No: </b>
                        {userInfo.customerTCNo}
                      </p>
                      {policyEndDates &&
                        (
                          <>
                            <p className="disclaimer">
                              <b>Trafik Poliçe Bitişi: </b>
                              {policyEndDates.trafikEndDate ?? '-'}
                            </p>
                            <p className="disclaimer">
                              <b>Kasko Poliçe Bitişi: </b>
                              {policyEndDates.kaskoEndDate ?? '-'}
                            </p>
                          </>
                        )
                      }
                      {isCallCenter &&
                        <p className="disclaimer">
                          <b>Trafik Hasar Kademesi: </b>
                          {CCinfo.appliedLevel}
                        </p>
                      }
                      {isCallCenter &&
                        <p className="disclaimer">
                          <b>İl: </b>
                          {CCinfo.customerIl}
                        </p>
                      }
                      {isCallCenter &&
                        <p className="disclaimer">
                          <b>İlçe: </b>
                          {CCinfo.customerIlce}
                        </p>
                      }
                    </>
                  )}
                </span>
              </div>
							{!isKPSActive && (
											<p
											className="edit-info-button"
											onClick={() => {
												editData();
											}}
											>
												Bilgileri Düzenle
												<span style={{ marginLeft: '10px'}}>
													<img
														src={edit}
														width="19px"
														height="14px"
													/>
											</span>
											</p>
										)}
            </>
          )}
        </div>
        {!isMobile && (typeFilterList[0] !== "all" || brandFilterList.length > 1 || subTypeFilterList.length > 1) && (
          <div className="clear-filter" onClick={handleClearFilter}>
            <img src={thrash} alt="thrash" />
            <p className="desc" style={{ fontWeight: "600" }}>
              Filtreleri Temizle
            </p>
          </div>
        )}
        {!isMobile && (
          <>
            <div className="info-section">
              <p className="text" style={{ fontWeight: "600" }}>
                Araç Bilgileri
              </p>
              <span className="car-info">
                {Object.keys(carInfo).length == 0 ? (
                  <Skeleton variant="rectangular" width="100%" />
                ) : (
                  <p className="disclaimer">
                    {" "}
                    {carInfo.year} / {carInfo.brand?.replaceAll("/", " / ").replaceAll("  ", " ")} /{" "}
                    {carInfo.model?.replaceAll("/", " / ").replaceAll("  ", " ")} / {fuelType[carInfo.fuel]}
                    {!((carInfo.processStep === 10 || carInfo.processStep === 30) && selectedUsageType) && (
                      <> / {usageTypes.filter((t) => t.Value == carInfo.usageType)[0]?.Text.toUpperCase()}</>
                    )}
                  </p>
                )}
              </span>
            </div>
            {(carInfo.processStep === 10 || carInfo.processStep === 30) && selectedUsageType && (
              <div className="car-type-section">
                <div>
									<b>Araç Tipi:</b> {selectedUsageType.Text}
                  {/* <span onClick={() => setIsCarTypeModalOpended(true)}>
                    <img src={editSVG} width={isMobile ? 20 : 20} height={isMobile ? 20 : 20} />
                  </span> */}
                </div>
              </div>
            )}
            <div className="info-section">
              <p className="text" style={{ fontWeight: "600" }}>
                Kişisel Bilgiler
              </p>
              <span className="user-info">
                {Object.keys(carInfo).length == 0 ? (
                  <Skeleton variant="rectangular" width="100%" />
                ) : (
                  <>
                    {!isVkn && (
										<>
											<p className="disclaimer">
												<b>Ad Soyad: </b>
												{userInfo.name + " " + userInfo.surname}
											</p>
											<p className="disclaimer">
												<b>Doğum Tarihi: </b>
												{userInfo.birthDate}
											</p>
										</>
										)}
										{isVkn && (
											<>
												<p className="disclaimer">
													<b>Firma adı: </b>
													{userInfo.name}
												</p>
											</>
											)}
                    <p className="disclaimer">
                      <b>Kimlik/Vergi No: </b>
                      {userInfo.customerTCNo}
                    </p>
                    {policyEndDates &&
                      (
                        <>
                          <p className="disclaimer">
                            <b>Trafik Poliçe Bitişi: </b>
                            {policyEndDates.trafikEndDate ?? '-'}
                          </p>
                          <p className="disclaimer">
                            <b>Kasko Poliçe Bitişi: </b>
                            {policyEndDates.kaskoEndDate ?? '-'}
                          </p>
                        </>
                      )
                    }
                    {isCallCenter &&
                      <p className="disclaimer">
                        <b>Trafik Hasar Kademesi: </b>
                        {CCinfo.appliedLevel}
                      </p>
                    }
                    {isCallCenter &&
                      <p className="disclaimer">
                        <b>İl: </b>
                        {CCinfo.customerIl}
                      </p>
                    }
                    {isCallCenter &&
                      <p className="disclaimer">
                        <b>İlçe: </b>
                        {CCinfo.customerIlce}
                      </p>
                    }
										{!isKPSActive && (
											<p
											className="edit-info-button"
											onClick={() => {
												editData();
											}}
											>
												Bilgileri Düzenle
												<span style={{ marginLeft: '10px'}}>
													<img
														src={edit}
														width="19px"
														height="14px"
													/>
											</span>
											</p>
										)}
                  </>
                )}
              </span>
            </div>
          </>
        )}
      </div>
      {isMobile &&
        (typeFilterList[0] !== "all" ||
          brandFilterList.length > 1 ||
          subTypeFilterList.length > 1 ||
          carTypeValue !== selectedUsageType?.Value) && (
          <div className="clear-and-apply">
            <div
              className="clear-filter"
              onClick={() => {
                handleClearFilter();
                setCarTypeValue(selectedUsageType?.Value);
              }}
            >
              <img src={thrash} alt="thrash" />
              <p className="desc" style={{ fontWeight: "600" }}>
                Filtreleri Temizle
              </p>
            </div>
            <Button
              variant="contained"
              style={{
                width: "300px",
                height: "45px",
                boxSizing: "border-box",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.4)",
                borderRadius: "5px 5px 0 0 ",
                textTransform: "none",
                display: "block",
                position: "fixed",
                bottom: 0,
                left: 0,
                margin: "0 0 0 -20%",
              }}
              onClick={() => {
                if (carTypeValue !== selectedUsageType.Value) {
                  selectUsageType();
                }
                closeDrawer();
              }}
            >
              {carTypeValue !== selectedUsageType?.Value ? "Araç Tipi Değiştir" : "Filtreleri Uygula"}
            </Button>
          </div>
        )}
      {isEditModalOpen && (
        <Modal open={isEditModalOpen} onClose={() => setIsEditModalOpen(false)}>
          <EditPersonalInfo
            handleClose={() => setIsEditModalOpen(false)}
            userInfo={userInfo}
						history={props.history}
						carInfo={carInfo}
						usageTypes={usageTypes}
						setUserInfo={setUserInfo}
						selectedUsageType={selectedUsageType}
						setShouldOffersRerender={setShouldOffersRerender}
						setSelectedUsageType={setSelectedUsageType}
						processStep={carInfo.processStep}
            isCallCenter={isCallCenter}
          />
        </Modal>
      )}
    </>
  );
};

export default OfferFilters;

