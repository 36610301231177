/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-indent */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-expressions */
import {
  Box, Button, Modal,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { MAIN_URL } from '../../../../shared/_helpers/env';

import '../../../../shared/assets/styles/pages/mypolicies.scss';
import { KLInput } from '../../components/form/KLInput';
import { phoneValidation, tcknValidation } from '../../../../shared/_helpers/validations';

import loadingKlActions from '../../../actions/loadingActions';
import commonService from '../../../_services/common.service';
import informationAction from '../../../actions/informationAction';
import policyService from '../../../_services/policy.service';
import SecureOTP from '../Steps/OptionalSteps/SecureOTP';
import toastrActions from '../../../actions/toastrAction';
import headerActions from '../../../actions/headerAction';

// const STATIC_UID = '1254fe79-27bf-4cdd-88c2-85fe05684c91';
const STATIC_QUOTEID = '646e2fec-d07d-4cc7-902a-da96f0b91e38';

export const MyPolicies = (props) => {
  const dispatch = useDispatch();

  /* #region  State Operations */
  const [isOTPRequired, setIsOTPRequired] = useState(false);
  const [quoteId, setQuoteId] = useState(STATIC_QUOTEID);
  const [jt, setJt] = useState(null);
  /* #endregion */

  useEffect(() => {
		dispatch(headerActions.setUseHeader(true));
    dispatch(headerActions.setMainNav(true));
    localStorage.removeItem('phoneNumber');
    localStorage.removeItem('identityNumber');
  }, []);

  /* #region Form fields */
  const [tckn, setTckn] = useState('');
  const [phone, setPhone] = useState('');

  const {
    handleSubmit,
    reset,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isValid, touchedFields },
    control,
  } = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
  });
  /* #endregion */

  const succcessFunc = () => {
    localStorage.setItem('phoneNumber', btoa(phone.replace(/\D/g, '')));
    localStorage.setItem('identityNumber', btoa(tckn));
    // TODO: jt kaldırmalı mıyız öbür sayfalarda kullanılmıyor ?
    props.history.push({
      pathname: '/quotation/policelerim',
      search: `?jt=${localStorage.getItem('jt')}`,
    });
  };

  const submitForm = () => {
    const customerObject = {
      identityNumber: tckn,
      phoneNumber: phone.replace(/\D/g, ''),
    };
    dispatch(loadingKlActions.showKlLoadingEmpty());
    policyService.hasPolicy(customerObject, (hasPolicyResult) => {
      const { userUId, hasPolicy } = hasPolicyResult;
      localStorage.setItem('jt', userUId);
      setJt(userUId);
      if (hasPolicy) {
        dispatch(informationAction.setQuoteId(quoteId));
        setQuoteId(quoteId);
        commonService.checkOTPUser(
          {
            phoneNumber: phone.replace(/\D/g, ''),
            tcIdentityNumber: tckn,
            isPolicyFlow: true,
          },
          (resp) => {
            if (!resp.otpUserResult && !resp.errorMessage) {
              setIsOTPRequired(true);
              commonService.isInBlackList(
                {
                  PhoneNumber: phone.replace(/\D/g, ''),
                  TCKN: tckn,
                  Email: null,
                  FirstPhoneNumber: phone.replace(/\D/g, ''),
                },
                (blresp) => {
                  if (!blresp) {
                    commonService.sendSMS(
                      {
                        OTP: null,
                        OTPType: 0,
                        PhoneNumber: phone.replace(/\D/g, ''),
                        TCKN: tckn,
                        UserUId: userUId,
                        isPolicyFlow: true,
                      },
                      (sms) => {
                      },
                    );
                  }
                },
              );
            } else if (!resp.otpUserResult && resp.errorMessage) {
              dispatch(toastrActions.setToastrMessage({ variant: 'error', message: resp.errorMessage }));
            } else {
              succcessFunc();
            }
          },
        );
      } else {
        props.history.push({
          pathname: '/quotation/policem-yok',
        });
      }
      dispatch(loadingKlActions.hideKlLoading());
    });
    // setIsOTPRequired(true);
  };

  return (
    <Box className="steps-container cancellation-container success-container mypolicies-container">
      <div className="cancellation-header">
        <h1 className="my-policies-h1">Poliçe İşlemleri</h1>
        <p className="my-policies-paragraph">
          <a className="klLink" href={MAIN_URL}>Koalay.com</a>
          'dan satın aldığınız poliçelerinize TCKN/VKN ve cep telefonu bilginizi girerek ulaşabilirsiniz
        </p>
      </div>
      <form className="myPoliciesForm">
        <KLInput
          name="tckn"
          control={control}
          label="TCKN veya Vergi Kimlik No"
          value={tckn}
          error={errors}
          mask="99999999999"
          rules={tcknValidation()}
          sx={{
            '& .MuiOutlinedInput-root': {
              width: '100%',
              '&.Mui-error': {
                width: '100%',
              },
            },
          }}
          onChanged={(val) => { clearErrors('tckn'); setTckn(val); }}
          touchedFields={touchedFields}
          reset={reset}
        />
        <KLInput
          name="phone"
          control={control}
          label="Cep Telefonu"
          rules={phoneValidation()}
          mask="(599) 999 99 99"
          value={phone}
          error={errors}
          onChanged={(val) => setPhone(val)}
          touchedFields={touchedFields}
          reset={reset}
          sx={{
            '& .MuiOutlinedInput-root': {
              width: '100%',
              '&.Mui-error': {
                width: '100%',
              },
            },
          }}
        />
        <Button
          disabled={!isValid}
          variant="contained"
          className="loginButton"
          onClick={handleSubmit(submitForm)}
          style={{ textTransform: 'none' }}
        >
          Giriş Yap
        </Button>
      </form>
      <Modal
        open={isOTPRequired}
        onClose={() => setIsOTPRequired(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <SecureOTP
          quoteId={quoteId}
          notFunnelPage
          jt={localStorage.getItem('jt')}
          url="/quotation/policelerim"
          phone={phone}
          callback={succcessFunc}
          tckn={tckn}
          succcessFunc={succcessFunc}
        />
      </Modal>
    </Box>
  );
};

export default withRouter(MyPolicies);
