import React from 'react';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import createInfoForm from '../../../../data/createForm/createInfoForm';
import FormBuilder from '../../../../../shared/formBuilder/FormBuilder';
import useWindowSize from '../../../../../shared/_hooks/windowResizeHook';

export const InfoForm = (props) => {
  const size = useWindowSize();
  const isMobile = size.width <= 880;

  const {
    formHookObject,
    submitForm,
    relationObject,
    userConsentChecked,
  } = props;

  const infoFormObject = createInfoForm({
    formHookObject, submitForm, relationObject, isMobile, userConsentChecked,
  });

  return (
    <>
      <form>
        <FormBuilder formBuilderObject={infoFormObject} submitForm={submitForm} />
      </form>
    </>
  );
};

export default withRouter(InfoForm);
