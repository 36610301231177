import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ArrowNarrowLeft from '../../../../shared/assets/img/svg/arrow-narrow-left.svg';
import './back-button.scss';
import stepActions from '../../../../motor/actions/stepAction';
import { tssStepDecider } from '../../../../shared/_helpers/stepDecider';
import informationActions from '../../../../motor/actions/informationAction';
import TSS_SEQUENCE from '../../../constants/sequence';
import getQuotes, { stepsEnum } from '../../../apiOperations/getQuotes';
import { historyPushWithSearch } from '../../../../shared/_helpers/historyPushWithSearch';

export const BackButton = (props) => {
  const { step, fromInsureds, shouldRedirectCityDistrict } = useSelector((state) => state.step);
  const { isFamilyFlow, insureds } = useSelector((state) => state.info);
  const currentInsured = insureds[insureds.length - 1];
  // const { movingForward } = currentInsured;
  const movingForward = currentInsured?.movingForward;
  const history = useHistory();
  const dispatch = useDispatch();

  const { location: { pathname } } = window;
  const isInsureds = pathname.includes('Insureds') || pathname.includes('insureds');  
	const isCityDistrict = pathname.includes('CityDistrict') || pathname.includes('cityDistrict');

  const goBack = () => {
    currentInsured.movingForward = false;
    dispatch(informationActions.updateInsured(currentInsured));
		dispatch(stepActions.setfromInsureds(isInsureds))
		if(isCityDistrict){
			historyPushWithSearch('/tamamlayici-saglik-sigortasi/ContactInfo', history);
		}else if(step === 3 && !isFamilyFlow && shouldRedirectCityDistrict){
			historyPushWithSearch('/tamamlayici-saglik-sigortasi/CityDistrict', history); //TODO: dinamik yap
		dispatch(stepActions.setfromInsureds(false))
		}else if(step === 1 && fromInsureds){
			const stepType = isFamilyFlow ? 'FAMILY' : 'SELF';
			const currentStep = TSS_SEQUENCE[stepType].find((elm) => elm.stepUrl.toLowerCase() === '/tamamlayici-saglik-sigortasi/insureds').index;
			tssStepDecider(currentStep, history, isFamilyFlow); //TODO: dinamik yap
			dispatch(
				stepActions.setStep(currentStep),
				);
		dispatch(stepActions.setfromInsureds(false))
		}else if((isFamilyFlow && step === 1 && insureds.length > 1)){
			tssStepDecider(3, history, isFamilyFlow); //TODO: dinamik yap
			dispatch(
				stepActions.setStep(step - 1),
				);
		}else if(isInsureds){
			const stepType = isFamilyFlow ? 'FAMILY' : 'SELF';
			const currentStep = TSS_SEQUENCE[stepType].find((elm) => elm.stepUrl.toLowerCase() === '/tamamlayici-saglik-sigortasi/insureds').index;
			tssStepDecider(currentStep - 1, history, isFamilyFlow); //TODO: dinamik yap
			dispatch(
				stepActions.setStep(currentStep - 1),
				);
		}else if (!isFamilyFlow) {
			tssStepDecider(step - 1, history, isFamilyFlow);
			dispatch(
				stepActions.decrementStep(),
			);
    } else if (currentInsured) {
			if ((movingForward && insureds.length !== 1) || (isFamilyFlow && step === 2)) {
        if (step === 1) {
          tssStepDecider(2, history, isFamilyFlow);
          // dispatch(
          //   informationActions.removeInsured(),
          // );
          dispatch(
            stepActions.setStep(2),
          );
        } else {
          currentInsured.isFull = false;
          dispatch(informationActions.updateInsured(currentInsured));
          tssStepDecider(step - 1, history, isFamilyFlow);
          dispatch(
            stepActions.decrementStep(),
          );
        }
      } else if (insureds.length <= 2 && step === 1) {
        tssStepDecider(3, history, false);
        dispatch(informationActions.setIsFamilyFlow(false));
        if (!currentInsured.isFull) {
          dispatch(
            informationActions.removeInsured(currentInsured),
          );
          getQuotes(dispatch, { step: stepsEnum.TSSPersonalInformation }, () => {
          });
        }
        dispatch(
          stepActions.setStep(3),
        );
      } else {
					dispatch(
						informationActions.removeInsured(currentInsured),
						);
						getQuotes(dispatch, { step: stepsEnum.TSSPersonalInformation }, () => {
						});
						tssStepDecider(2, history, true);
						dispatch(
							stepActions.setStep(2),
							);
      }
    }
  };

  return (
    insureds && (
    <div>
      <span className="back-button-container" onClick={() => goBack()}>
        <img
          src={ArrowNarrowLeft}
          alt="Back"
        />
        <p className="back-button-text">Geri Dön</p>
      </span>
    </div>
    )
  );
};

export default BackButton;


