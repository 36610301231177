import React, { useEffect } from "react";
import "../../../../shared/assets/styles/components/no-offer.scss";
import useWindowSize from "../../../../shared/_hooks/windowResizeHook";
import WarningSVG from "../../../../shared/assets/img/svg/warning.svg";
import { useSelector } from "react-redux";
import { dealerCasco, dealerTraffic, dealerTrafficAndCasco, plainCasco, plainTraffic, plainTrafficAndCasco } from "../../../localization/NoOfferTexts";
import { useRollbar } from '@rollbar/react';

export const NoOffer = ({ startLoading, isProgressBarActive, offers, isCascoRenewal, isTrafficRenewal, size }) => {
	const Rollbar = useRollbar();
  const screenSize = useWindowSize();
  const isMobile = screenSize.width <= 880;
  const shouldCaskoShown = !isCascoRenewal && offers.filter((offer) => offer.Brans === "Kasko")?.length <= 0;
  const shouldTrafikShown = !isTrafficRenewal && offers.filter((offer) => offer.Brans === "Trafik")?.length <= 0;
  const show = !isProgressBarActive && !startLoading;
  const { isAgency } = useSelector((state) => state.shell);

	useEffect(() => {
		if (show && offers.length == 0) {
			// Rollbar.critical("Socketten hiç teklif gelmedi");
		}
	}, [])


  return (
    <>
      {show && shouldTrafikShown && shouldCaskoShown && (
        <div className={`no-offer-container ${size === "large" ? "no-offer-container-large" : ""}`}>
          <img
            className="zero-offer-img"
            src={isMobile ? WarningSVG : `https://static.koalay.com/Images/KoalayApp/Quotation/zeroOffer.svg`}
            alt="noQuote"
            srcSet=""
          />
          {isAgency ? dealerTrafficAndCasco() : plainTrafficAndCasco()}
        </div>
      )}
      {show && shouldTrafikShown && !shouldCaskoShown && (
        <div className={`no-offer-container ${size === "large" ? "no-offer-container-large" : ""}`}>
          <img
            className="zero-offer-img"
            src={isMobile ? WarningSVG : `https://static.koalay.com/Images/KoalayApp/Quotation/zeroOffer.svg`}
            alt="noQuote"
            srcSet=""
          />
          {isAgency ? dealerTraffic() : plainTraffic()}
        </div>
      )}
      {show && !shouldTrafikShown && shouldCaskoShown && (
        <div className={`no-offer-container ${size === "large" ? "no-offer-container-large" : ""}`}>
          <img
            className="zero-offer-img"
            src={isMobile ? WarningSVG : `https://static.koalay.com/Images/KoalayApp/Quotation/zeroOffer.svg`}
            alt="noQuote"
            srcSet=""
          />
          {isAgency ? dealerCasco() : plainCasco()}
        </div>
      )}
      {(!show || (!shouldTrafikShown && !shouldCaskoShown)) && (<span
      style={{ marginTop: isMobile? '0': '-75px' }}
      />)}
    </>
  );
};
export default NoOffer;
