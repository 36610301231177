import React from 'react';
import crossSVG from '../../../../../../shared/assets/img/svg/cross.svg';
import TickSVG from '../../../../../../shared/assets/img/svg/tick-green.svg';

const TrafficPopularv1 = (props) => {
  const { popularCoverages } = props;
  return (
    <>
      <div className="teminat-container trafik" key="teminat-container">
        <div className="teminat-inner">
          {popularCoverages[0].coverages
            .sort((a, b) => a.displayText.localeCompare(b.displayText))
            .map((popular) => (
              <span className="teminat-detail">
                <img src={popular.displayText === 'Yok' ? crossSVG : TickSVG} alt="Yok" />
                <span className="teminat-desc">
                  <span>{popular.coverageName}</span>
                  <span className="teminat-detail-price">
                    {popular.displayText !== 'Yok' && popular.displayText !== 'Var'
                      ? popular.displayText.indexOf('TL') >= 0
                        ? popular.displayText
                        : `${popular.displayText} TL`
                      : ''}
                  </span>
                </span>
              </span>
            ))}
        </div>
      </div>
      {popularCoverages.length > 1 && <h4>Ek Teminatlar</h4>}
      <div className="teminat-container trafik" key="teminat-container">
        <div className="teminat-inner">
          {popularCoverages.length > 1 && (
            <>
              {popularCoverages[1].coverages
                .sort((a, b) => a.displayText.localeCompare(b.displayText))
                .map((popular) => (
                  <span className="teminat-detail">
                    <img src={popular.displayText === 'Yok' ? crossSVG : TickSVG} alt="Yok" />
                    <span className="teminat-desc">
                      <span>{popular.coverageName}</span>
                      <span className="teminat-detail-price">
                        {popular.displayText !== 'Yok' && popular.displayText !== 'Var'
                          ? popular.displayText.indexOf('TL') >= 0
                            ? popular.displayText
                            : `${popular.displayText} TL`
                          : ''}
                      </span>
                    </span>
                  </span>
                ))}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default TrafficPopularv1;
