import { shellTypes } from '../types/shell.types';

const initialState = {
  funnel: {},
  isRenewal: null,
  isAgency: null,
  isMerchant: null,
  isCallCenter: null,
  isBack: false,
  quotation: {},
  sbn: null,
  isKoalaySS: true,
};

export default function SHELL_REDUCER(state = initialState, { type, payload }) {
  switch (type) {
    case shellTypes.SET_FUNNEL_INFO:
      return {
        ...state,
        funnel: { ...payload.funnel },
      };
    case shellTypes.SET_RENEWAL_INFO:
      return {
        ...state,
        isRenewal: payload.isRenewal,
      };
    case shellTypes.SET_DEALER_INFO:
      return {
        ...state,
        isAgency: payload.isAgency,
      };
    case shellTypes.SET_MERCHANT_INFO:
      return {
        ...state,
        isMerchant: payload.isMerchant,
      };
    case shellTypes.SET_CALL_CENTER_INFO:
      return {
        ...state,
        isCallCenter: payload.isCallCenter,
      };
    case shellTypes.SET_IS_BACK:
      return {
        ...state,
        isBack: payload.isBack,
      };
    case shellTypes.SET_QUOTATION_INFO:
      return {
        ...state,
        quotation: { ...payload.quotation },
      };
		case shellTypes.SET_SBN:
      return {
        ...state,
        sbn: payload.sbn,
      };
		case shellTypes.SET_THEME_BY_PAGE:
      const isKoalaySSforTheme = !!(payload?.value || (state.sbn === null || state.sbn === "KCDUTC"))
      return {
        ...state,
        isKoalaySS: isKoalaySSforTheme,
      };		
		case shellTypes.SET_IS_KOALAY_SS:
			const isKoalaySS = !!(payload?.value || (state.sbn === null || state.sbn === "KCDUTC"))
      return {
        ...state,
        isKoalaySS: isKoalaySS,
      };
    default:
      return state;
  }
}


