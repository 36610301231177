const HIDE_KL_LOADING = 'HIDE_KL_LOADING';
const SHOW_KL_LOADING = 'SHOW_KL_LOADING';
const SET_KL_LOADING = 'SET_KL_LOADING';
const SET_KL_LOADING_EMPTY = 'SET_KL_LOADING_EMPTY';
const SET_PROGRESS_BAR_STATUS = 'SET_PROGRESS_BAR_STATUS';

export const loadingTypes = {
  HIDE_KL_LOADING,
  SHOW_KL_LOADING,
  SET_KL_LOADING,
  SET_KL_LOADING_EMPTY,
  SET_PROGRESS_BAR_STATUS,
};
