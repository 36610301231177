const SET_VEHICLE_INFO = 'SET_VEHICLE_INFO';
const SET_CUSTOMER_INFO = 'SET_CUSTOMER_INFO';
const ADD_INSURED = 'ADD_INSURED';
const UPDATE_INSURED = 'UPDATE_INSURED';
const SET_INSUREDS = 'SET_INSUREDS';
const REMOVE_INSURED = 'REMOVE_INSURED';
const SET_VEHICLE_DETAIL = 'SET_VEHICLE_DETAIL';
const SET_BIRTHDATE_INFO = 'SET_BIRTHDATE_INFO';
const SET_BIRTHDATE_VALUE = 'SET_BIRTHDATE_VALUE';
const SET_OCCUPATION_INFO = 'SET_OCCUPATION_INFO';
const SET_QUOTE_ID = 'SET_QUOTE_ID';
const SET_CUSTOMER_QUOTES = 'SET_CUSTOMER_QUOTES';
const SET_VEHICLE_DROPDOWN = 'SET_VEHICLE_DROPDOWN';
const SET_KPS_IS_ACTIVE = 'SET_KPS_IS_ACTIVE';
const SET_IS_VKN = 'SET_IS_VKN';
const SET_IS_FAMILY_FLOW = 'SET_IS_FAMILY_FLOW';
const SET_BREAKOUT_FIELDS = 'SET_BREAKOUT_FIELDS';
const SET_NOCLAIM_DATA = 'SET_NOCLAIM_DATA';

export const informationTypes = {
  SET_CUSTOMER_INFO,
  ADD_INSURED,
  UPDATE_INSURED,
  SET_INSUREDS,
  REMOVE_INSURED,
  SET_VEHICLE_INFO,
  SET_NOCLAIM_DATA,
  SET_VEHICLE_DETAIL,
  SET_BIRTHDATE_INFO,
  SET_BIRTHDATE_VALUE,
  SET_OCCUPATION_INFO,
  SET_QUOTE_ID,
  SET_CUSTOMER_QUOTES,
  SET_VEHICLE_DROPDOWN,
  SET_KPS_IS_ACTIVE,
  SET_IS_VKN,
  SET_IS_FAMILY_FLOW,
  SET_BREAKOUT_FIELDS,
};
