import React, { useEffect, useState } from 'react';

import {
  Button,
} from '@mui/material';
import '../../../../shared/assets/styles/offer.scss';
import { withRouter } from 'react-router-dom';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import useWindowSize from '../../../../shared/_hooks/windowResizeHook';
import './thankyou.scss';
import ProductCard from './components/ProductCard';
import Information from './components/Information';
import CenteredModal from '../../../../motor/views/components/widget/CenteredModal';
import loadingKlActions from '../../../../motor/actions/loadingActions';
import toastrActions from '../../../../motor/actions/toastrAction';
import quotationService from '../../../_services/quotation.service';
import informationActions from '../../../../motor/actions/informationAction';
import getQuotes, { stepsEnum } from '../../../apiOperations/getQuotes';
import { UserStates } from '../../../../motor/data/userStates';
import useUserState from '../../../../shared/_hooks/userStateHook';
import { removeCustomEventsFromDataLayer } from '../../../../shared/_helpers/helperFunctions';

export const Thankyou = (props) => {
  const { setUserState } = useUserState();
  const size = useWindowSize();
  const isMobile = size.width <= 880;

  const URL_PARAMS = new URLSearchParams(document.location.search);
  const jt = URL_PARAMS.get('jt');
  const qGuid = URL_PARAMS.get('qGuid');
  const teklifTID = Array.from(URL_PARAMS.keys()).find((key) => key.toLowerCase() === 'tekliftid') !== undefined
    ? URL_PARAMS.get(Array.from(URL_PARAMS.keys()).find((key) => key.toLowerCase() === 'tekliftid')).toLowerCase()
    : props.location.state.teklifTID;

  const { customer } = useSelector((state) => state.info);
  const [detailResponse, setDetailResponse] = useState(null);
  const [policies, setPolicies] = useState([]);
  const [hasMailClicked, setHasMailClicked] = useState(0);
  const [mailAddress, setMailAddress] = useState('');
  const [customerInitialMail, setCustomerInitialMail] = useState();
  const { campaignDetail, thankYouPageDescription, thankYouPageDetailTextLink } = useSelector((state) => state.campaign);

  const dispatch = useDispatch();

  const getCheckoutResult = () => {
    dispatch(loadingKlActions.showKlLoadingEmpty());
    const quotationObject = {
      TeklifTId: teklifTID,
      TeklifUid: qGuid,
    };
    quotationService.getQuotationDetail(quotationObject, (response) => {
      const selfInsured = response.insured.find((insured) => insured.relation === 1);
      setDetailResponse(response);
      setPolicies(response.insured);
      setCustomerInitialMail(selfInsured.eMail);
      dispatch(loadingKlActions.hideKlLoading());
    });
  };

  const sendMail = (update) => {
    const selfInsured = detailResponse.insured.find((insured) => insured.relation
		=== 1);
    const policyObject = {
      customerInfo: {
        identityNo: selfInsured.identityNo,
        identityType: 0,
        lastName: selfInsured.lastName,
        name: selfInsured.name,
        mobilePhone: selfInsured.mobilePhone,
        eMail: mailAddress,
        userGuid: jt,
        weight: selfInsured.weight,
        height: selfInsured.height,
      },
      policyInfo: {
        insurerPolicyNo: detailResponse.insurerPolicyNo,
        teklifTId: teklifTID,
      },
    };
    if (update) {
      setCustomerInitialMail(null);
      const tempInsureds = detailResponse.insured;
      tempInsureds.map((insured) => {
        if (insured.relation === 1) {
          insured.eMail = mailAddress;
        }
        return insured;
      });
      dispatch(informationActions.setInsureds(tempInsureds));
    }
    dispatch(loadingKlActions.showKlLoadingEmpty());
    quotationService.sendPolicy(policyObject, (response) => {
      setHasMailClicked(false);
      dispatch(loadingKlActions.hideKlLoading());
      dispatch(
        toastrActions.setToastrMessage({
          variant: 'success',
          message: update
            ? 'E-posta adresiniz güncellenmiştir. Poliçeniz girdiğiniz e-posta adresine gönderildi. Sistemin yoğunluğuna göre azami 10 dakika içerisinde ulaşacaktır.'
            : 'Poliçeniz girdiğiniz e-posta adresine gönderildi. Sistemin yoğunluğuna göre azami 10 dakika içerisinde ulaşacaktır.',
        }),
      );
			if (update) {
				getQuotes(dispatch, { step: stepsEnum.TSSPersonalInformation }, () => {

				});
			}
    });
  };

  const handleMailClicked = (data) => {
    setMailAddress(data.mail);
    if (data.mail !== customer.eMail) {
      setHasMailClicked(true);
    } else {
      sendMail(false);
    }
  };

  useEffect(() => {
    const userSateData = {
      UserUID: jt,
      UserStateDefinition: UserStates.TSS_Tesekkur_Sayfasi_Goren,
    };
    setUserState(userSateData);
    getCheckoutResult();

    if (!Object.values(window.dataLayer).map((element) => element.event).includes('thankyou_page')) {
      removeCustomEventsFromDataLayer(window.dataLayer);
      window.dataLayer.push({
        event: 'thankyou_page',
      });
    }
  }, []);

  return (
    <>
      {detailResponse
        && (!isMobile ? (
          <Box className="steps-container thankyou-container-tss" style={{ marginTop: '48px' }}>
            <Information campaignDescription={thankYouPageDescription} campaignLink={thankYouPageDetailTextLink} campaignDetail={campaignDetail} policies={policies} />
            <ProductCard policy={detailResponse.quotations[0]} customerInitialMail={customerInitialMail} handleMailClicked={handleMailClicked} />
          </Box>
        ) : (
          <>
            <Information campaignDescription={thankYouPageDescription} campaignLink={thankYouPageDetailTextLink} campaignDetail={campaignDetail} policies={policies} />
            <Box className="steps-container thankyou-container-tss" style={{ marginTop: '16px' }}>
              <ProductCard policy={detailResponse.quotations[0]} customerInitialMail={customerInitialMail} handleMailClicked={handleMailClicked} />
            </Box>
          </>
        ))}
      <CenteredModal title="Bilgi" isOpen={hasMailClicked} setOpen={setHasMailClicked}>
        <p className="text">
          Girmiş olduğunuz e-posta adresi, sistemimize kayıtlı olan e-posta adresinden farklıdır.
          <br />
          Yeni E-Posta adresi ile güncellemek ister misiniz?
        </p>
        <div className="info-buttons">
          <Button variant="outlined" onClick={() => sendMail(false)}>
            Hayır, istemiyorum
          </Button>
          <Button variant="contained" onClick={() => sendMail(true)}>
            Evet, istiyorum
          </Button>
        </div>
      </CenteredModal>
    </>
  );
};

export default withRouter(Thankyou);
