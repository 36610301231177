import React from 'react';

import styled from '@emotion/styled';
import useWindowSize from '../../../../../shared/_hooks/windowResizeHook';
import CircleTick from '../../../../../shared/assets/img/svg/CircleTick.svg';
import CloseCircle from '../../../../../shared/assets/img/svg/CloseCircle.svg';

export const CCCrossSellSuccess = (props) => {
  const size = useWindowSize();
  const isMobile = size.width <= 880;
  const { closeCCCrossSell } = props;

  const StyledParagraph = styled('p')(({ theme }) => ({
    color: theme.palette.primary.main,
  }));

  return (
    <div className="cc-success-container">
      <img className="cc-close-icon" src={CloseCircle} width={isMobile ? 24 : 24} height={isMobile ? 24 : 24} onClick={closeCCCrossSell} />
      <div className="cc-icon">
        <img src={CircleTick} width={isMobile ? 46 : 46} height={isMobile ? 46 : 46} />
      </div>
      <StyledParagraph className="success-header">
        Başvurunuz İletildi!
      </StyledParagraph>
      <p className="disc">
        Başvurunuz bankaya iletilmiştir. Banka en kısa sürede sizinle iletişime geçecektir.
      </p>
    </div>
  );
};

export default CCCrossSellSuccess;
