import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import {
  tssCoverageGroupButtonStyleContained,
  tssCoverageGroupButtonStyleOutlined,
} from "../../../../data/tssCoverageGroupButtonStyle";
import useWindowSize from "../../../../../shared/_hooks/windowResizeHook";

export const CoverageGroupButtons = (props) => {
	const screenSize = useWindowSize();
  const isMobile = screenSize.width <= 880;
  const [coverageButtons, setCoverageButtons] = useState(null);

  const {
    coverageGroups,
    handleCoverageGroupFilter,
    selectedCoverageGroup,
  } = props;

  useEffect(() => {
    const tempButtonList = [
      {
        coveregeGroupId: "all",
        coveregeGroupName: "Tümü",
      },
    ];
    coverageGroups.forEach((coverageGroup) => {
      const { coveregeGroupId, coveregeGroupName } = coverageGroup;
      tempButtonList.push({
        coveregeGroupId,
        coveregeGroupName,
      });
    });

    setCoverageButtons(tempButtonList);
  }, []);

	const getButtonStyle =(coveregeGroupId) =>{
		if(selectedCoverageGroup === coveregeGroupId){
			return tssCoverageGroupButtonStyleContained
		}else{
			return tssCoverageGroupButtonStyleOutlined
		}
	}

  return (
    <div className="coverage-group-buttons">
      {coverageButtons &&
        coverageButtons.map((coverageButton) => (
          <Button
            key={coverageButton.coveregeGroupId}
            style={{
              	...getButtonStyle(coverageButton.coveregeGroupId),
								fontSize: isMobile ? '12px' : '14px',
            }}
            variant={selectedCoverageGroup === coverageButton.coveregeGroupId ? "contained" : "outlined"}
            onClick={() => handleCoverageGroupFilter(coverageButton.coveregeGroupId)}
          >
            {coverageButton.coveregeGroupName}
          </Button>
        ))}
    </div>
  );
};

export default CoverageGroupButtons;
