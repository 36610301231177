export function partajToQuotes(partajList, queueName, quoteId, selectedOccupationId = null) {
  const quotes = [];
  partajList.productWebOnlinePartaj.map((onlineProductWebPartaj, index) => {
    const quoteModel = {};
    quoteModel.Quote = onlineProductWebPartaj.webOnlinePartaj;
    quoteModel.Invisible = onlineProductWebPartaj.invisible;
    quoteModel.ProductId = onlineProductWebPartaj.productId;
    quoteModel.QuotationUId = quoteId;
    quoteModel.IsAlternate = quoteModel.Quote.IsAlternate;
    quoteModel.DefaultOccupation = onlineProductWebPartaj.defaultOccupation;
    quoteModel.QueueName = queueName;
    quoteModel.QuotationOrder = index;

    if (selectedOccupationId) {
      quoteModel.MasterOccupationId = parseInt(selectedOccupationId, 10);
    }

    if (quoteModel.DefaultOccupation !== null) {
      quoteModel.OccupationCode = quoteModel.DefaultOccupation.Value;
      quoteModel.ParentOccupationCode = quoteModel.DefaultOccupation.ParentOccupationValue;
    }
    if (quoteModel.Quote.BrnTip === 'Trafik') {
      quoteModel.BranchType = 2;
      quoteModel.AdjustmentFactor = 1;
      quotes.push(quoteModel);
    } else if (quoteModel.Quote.BrnTip === 'Kasko') {
      quoteModel.BranchType = 1;
      quoteModel.AdjustmentFactor = 1;
      quotes.push(quoteModel);
    } else if (quoteModel.Quote.BrnTip === 'IMM') {
      quoteModel.BranchType = 15;
      quoteModel.AdjustmentFactor = 1;
      quotes.push(quoteModel);
    }
  });
  return quotes;
}
