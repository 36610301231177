import React, { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import '../../../../shared/assets/styles/radio-button.scss';
import '../../../../shared/assets/styles/pages/car-info.scss';
import '../../../../shared/assets/styles/pages/info.scss';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import { useSelector, useDispatch } from 'react-redux';
import useWindowSize from '../../../../shared/_hooks/windowResizeHook';
import CengizOnCar from '../../../../shared/assets/img/png/cengiz-on-car.png';
import { KLInput } from '../../components/form/KLInput';
import { plateValidation, tcknValidation } from '../../../../shared/_helpers/validations';
import { maskPlate } from '../../../../shared/_helpers/masks';
import shellService from '../../../_services/shell.service';
import CenteredModal from '../../components/widget/CenteredModal';
import { brokerlikSozlesmesi } from '../../../data/shared';

import stepActions from '../../../actions/stepAction';
import Steps from '../../components/Steps';
import { MAIN_URL } from '../../../../shared/_helpers/env';
import CampaignBox from '../../components/campaign-box';
import { setSearchString } from '../../../../shared/_helpers/redirect';
import UserAggrementModal from '../../components/form/UserAggrementModal';
import shellActions from '../../../actions/shellAction';

export const Info = (props) => {
  const size = useWindowSize();
  const isMobile = size.width <= 880;
  const URL_PARAMS = new URLSearchParams(document.location.search);
  const jt = URL_PARAMS.get('jt');
  const extrefHash = URL_PARAMS.get('extrefhash') || URL_PARAMS.get('extRefHash') || URL_PARAMS.get('extrefHash');
  const campaignSource = URL_PARAMS.get('CampSrc') || null;
  const consumer = useSelector((state) => state.consumer);
  const [plateExists, setPlateExists] = useState(consumer.partnerSbn !== 'ATMDLK');

  /* #region Form values */
  const [tckn, setTckn] = useState('');
  const [plate, setPlate] = useState('');
  const [agreement, setAgreement] = useState(false);
  const [userAgreementModal, setUserAgreementModal] = useState(false);
  const [brokerModal, setBrokerModal] = useState(false);
  const { isAgency, isMerchant } = useSelector((state) => state.shell);
  const { campaignInfo, campaignDetail } = useSelector((state) => state.campaign);
  const { additionalInfo } = useSelector((state) => state.theme);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(stepActions.setStep(0));
    dispatch(shellActions.setThemeByPage(false));
  }, []);

  const {
    handleSubmit,
    reset,
    formState: { errors, isValid, touchedFields },
    control,
    setValue,
  } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    dispatch(stepActions.setStep(0));
    dispatch(shellActions.setThemeByPage(false));
    if (isMerchant) {
      const { referenceParams: { identityNo } } = JSON.parse(consumer.consumer);
      setValue('tckn', identityNo);
      setTckn(identityNo);
    }
  }, []);
  /* #endregion */

  const userAgreementHook = (e) => {
    setUserAgreementModal(true);
    if (e.target.href === '') {
      e.preventDefault();
    }
    return false;
  };

  const brokerHook = (e) => {
    setBrokerModal(true);
    if (e.target.href === '') {
      e.preventDefault();
    }
    return false;
  };
  const decrementStep = () => {
    document.location.href = MAIN_URL;
  };

  const submitForm = () => {
    const postData = { plate: plateExists ? plate.replaceAll(' ', '') : null, identity: tckn };
    if (jt) {
      postData.UserUId = jt;
    }
    if (campaignSource) {
      postData.campaignSource = campaignSource;
    }
    shellService.funnelPost(postData, (response) => {
      if (!isAgency && !isMerchant) {
        if (extrefHash) {
          const finalUrl = response.url.replace('?jt=', `?extrefHash=${extrefHash}&jt=`);
          window.location.href = finalUrl;
        } else {
          window.location.href = response.url;
        }
      } else {
        const UserUID = response.url.split('jt=').pop();
        const searchString = setSearchString(`?jt=${UserUID}`);
        window.location.href = `${window.location.origin}/quotation${searchString}`;
      }
    });
  };

  const isAtmo = document.location.href.includes('atmo');

  return (
    <div className="steps-page">
      {isMobile && <Steps goBack={decrementStep} />}
      <Box className="steps-container">
        <Grid container spacing={2}>
          <Grid xs={12} sm={6} lg={6} xl={6} item>
            <div>
              <h1 className="title">Plaka ve Kimlik Numarası</h1>
            </div>
            {!isMobile ? (
              <Grid container className="plate-selector" rowSpacing={1}>
                <Grid
                  style={{ paddingRight: '24px' }}
                  item
                  xl="auto"
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  className="radio-button-container"
                  onClick={() => setPlateExists(true)}
                >
                  <input
                    style={{ marginRight: '8px' }}
                    className="radio-button primary-radio-button"
                    type="checkbox"
                    checked={plateExists}
                    onClick={() => setPlateExists(true)}
                  />
                  <span>Aracın Plakası Var</span>
                </Grid>
                <Grid
                  item
                  xl="auto"
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  className="radio-button-container"
                  onClick={() => setPlateExists(false)}
                >
                  <input
                    style={{ marginRight: '8px' }}
                    className="radio-button primary-radio-button"
                    type="checkbox"
                    checked={!plateExists}
                    onClick={() => setPlateExists(false)}
                  />
                  <span>Aracın Plakası Yok</span>
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                spacing={2}
                className="plate-selector-mobile"
                sx={{
                  '&.MuiGrid-root': {
                    width: 'calc(100% + 32px)',
                  },
                }}
              >
                <Grid
                  xs={6}
                  item
                  sx={{
                    '&.MuiGrid-item': {
                      padding: '0',
                      marginBottom: '16px',
                      marginTop: '4px',
                    },
                  }}
                  className={`tabGrid ${plateExists && 'activeTabGrid'}`}
                >
                  <p
                    onClick={() => setPlateExists(true)}
                    className={`tabOption ${plateExists && 'isActive'}`}
                  >
                    Aracın Plakası Var
                  </p>
                </Grid>
                <Grid
                  xs={6}
                  item
                  sx={{
                    '&.MuiGrid-item': {
                      padding: '0',
                      marginBottom: '16px',
                      marginTop: '4px',
                    },
                  }}
                  className={`tabGrid ${!plateExists && 'activeTabGrid'}`}
                >
                  <p
                    onClick={() => setPlateExists(false)}
                    className={`tabOption ${!plateExists && 'isActive'}`}
                  >
                    Aracın Plakası Yok
                  </p>
                </Grid>
              </Grid>
            )}
            <>
              <form
                style={{
                  width: isMobile && '313px',
                  margin: isMobile && 'auto',
                }}
              >
                <div>
                  <KLInput
                    name="plate"
                    control={plateExists && control}
                    label="Plaka"
                    value={plateExists && plate}
                    hasCustomMaskFunction
                    mask={maskPlate}
                    error={plateExists && errors}
                    rules={plateExists && plateValidation()}
                    onChanged={(val) => setPlate(val)}
                    touchedFields={touchedFields}
                    reset={reset}
                    disabled={!plateExists}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        width: isMobile ? '313px' : '100%',
                        '&.Mui-error': {
                          width: isMobile ? '313px' : '100%',
                        },
                      },
                    }}
                  />
                </div>
                <div>
                  <KLInput
                    name="tckn"
                    control={control}
                    label="TCKN veya Vergi Kimlik No"
                    value={tckn}
                    error={errors}
                    mask="99999999999"
                    rules={tcknValidation()}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        width: isMobile ? '313px' : '100%',
                        '&.Mui-error': {
                          width: isMobile ? '313px' : '100%',
                        },
                      },
                    }}
                    onChanged={(val) => setTckn(val)}
                    touchedFields={touchedFields}
                    reset={reset}
                    disabled={isMerchant}
                  />
                </div>
                <div>
                  <FormControlLabel
                    required
                    style={{
                      margin: '0',
                      display: 'flex',
                      alignItems: 'flex-start',
                    }}
                    control={(
                      <Checkbox
                        checked={agreement}
                        onChange={() => setAgreement(!agreement)}
                      />
                    )}
                    label={(
                      <p className="desc" style={{ marginTop: '8px' }}>
                        <a onClick={(e) => userAgreementHook(e)}>
                          Kullanıcı sözleşmesini
                        </a>
                        {' '}
                        okudum ve Koalay.com’u
                        {' '}
                        <a onClick={(e) => brokerHook(e)}>
                          sigorta brokerim olarak yetkilendiriyorum.
                        </a>
                      </p>
                    )}
                  />
                </div>
              </form>
            </>
            <div>
              {!isMobile ? (
                <Button
                  onClick={handleSubmit(submitForm)}
                  variant="contained"
                  disabled={
                    plateExists
                      ? !isValid || !agreement
                      : !agreement
                        || !tckn
                        || (Object.keys(errors).length === 1
                          && Object.keys(errors)[0] !== 'plate')
                  }
                  style={{
                    width: !isMobile ? '254px' : '100%',
                    height: '45px',
                    margin: '24px 0px',
                    boxSizing: 'border-box',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
                    borderRadius: '5px',
                    textTransform: 'none',
                  }}
                >
                  Devam Et
                </Button>
              ) : (
                ''
              )}
            </div>
          </Grid>
          <Grid xs={12} sm={6} lg={6} xl={6} item className={isAtmo && 'steps-vehiclePhoto-wrapper'}>
            {campaignDetail && campaignInfo ? (
              <CampaignBox details={campaignDetail}>{campaignInfo}</CampaignBox>
            ) : (
              <div
                style={{
                  justifyContent: 'center',
                  display: isMobile ? 'none' : 'flex',
                }}
              >
                <img
                  style={{
                    height: 'auto',
                    maxWidth: '90%',
                  }}
                  src={
                    additionalInfo.landingImageUrl
                    && additionalInfo.landingImageUrl !== 'undefined'
                    && additionalInfo.landingImageUrl !== ''
                      ? additionalInfo.landingImageUrl
                      : CengizOnCar
                  }
                  alt=""
                />
              </div>
            )}
          </Grid>
        </Grid>
        {!isMobile ? (
          <Button
            style={{
              color: '#53585E',
              textTransform: 'none',
              fontSize: '17px',
              fontWeight: 600,
            }}
            onClick={decrementStep}
          >
            <ArrowBackIosNewIcon />
            Geri
          </Button>
        ) : (
          ''
        )}
        {isMobile ? (
          <Button
            onClick={handleSubmit(submitForm)}
            variant="contained"
            disabled={
              plateExists
                ? !isValid || !agreement
                : !agreement
                  || !tckn
                  || (Object.keys(errors).length === 1
                    && Object.keys(errors)[0] !== 'plate')
            }
            style={{
              width: !isMobile ? '254px' : '100%',
              height: '55px',
              boxSizing: 'border-box',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
              borderRadius: '5px',
              textTransform: 'none',
              display: 'block',
              position: 'fixed',
              bottom: 0,
              left: 0,
              margin: '0 0 0 -20%',
            }}
          >
            Devam Et
          </Button>
        ) : (
          ''
        )}
      </Box>
      <CenteredModal className="user-aggrement-modal" title="Kullanıcı Sözleşmesi" isOpen={userAgreementModal} setOpen={() => setUserAgreementModal(false)}>
        <UserAggrementModal />
      </CenteredModal>
      <CenteredModal
        title="Brokerlik Yetkilendirmesi ve Brokerlik Sözleşmesi"
        isOpen={brokerModal}
        setOpen={() => setBrokerModal(false)}
      >
        {brokerlikSozlesmesi}
      </CenteredModal>
    </div>
  );
};

export default withRouter(Info);
