import React, { useState, useEffect } from 'react';
import {
  Autocomplete, Button, MenuItem, Paper, TextField,
} from '@mui/material';
import { useForm } from 'react-hook-form';

import { withRouter } from 'react-router-dom';

import useWindowSize from '../../../../shared/_hooks/windowResizeHook';
import { KLInput } from '../../components/form/KLInput';
import {
  emailValidation, minLength, phoneValidation, requiredArea,
} from '../../../../shared/_helpers/validations';
import useUserState from '../../../../shared/_hooks/userStateHook';
import { UserStates } from '../../../data/userStates';
import commonService from '../../../_services/common.service';

export const MissingConfirmation = (props) => {
  const size = useWindowSize();
  const URL_PARAMS = new URLSearchParams(document.location.search);
  const uid = URL_PARAMS.get('jt');
  const [updatedFlag, setUpdatedFlag] = useState({});
  const isMobile = size.width <= 880;

  const tempConf = { ...props.confirmationInfo };
  const {
    handleSubmit,
    reset,
    formState: { errors, isValid, touchedFields },
    control,
  } = useForm({
    mode: 'onChange',
  });

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -40%)',
    width: 311,
    height: 'max-content',
    bgcolor: 'background.paper',
    textAlign: 'center',
    background: '#FFFFFF',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    paddingBottom: 20,
  };

  const CustomPaper = (props) => (
    <Paper
      style={{
        fontSize: '13px',
      }}
      elevation={8}
      {...props}
    />
  );

  const [cities, setCityList] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [selectedDistrictRow, setSelectedDistrictRow] = useState(null);
  const [selectedCityRow, setSelectedCityRow] = useState(null);

  useEffect(() => {
    commonService.getCityList((data) => {
      if (data.provinces) {
        const uniqueProvinces = [];
        const map = new Map();
        for (const province of data.provinces) {
          if (!map.has(province.code)) {
            map.set(province.code, true);
            uniqueProvinces.push({
              name: province.name,
              code: province.code,
            });
          }
        }
        setCityList(uniqueProvinces);
      }
    });
  }, []);

  const getDistricts = (val) => {
    setSelectedCityRow(val);
    setSelectedDistrictRow(null);
    if (selectedCityRow) {
      commonService.getDitrictsByCityId(val.code, (data) => {
        setDistricts(data.districts);
      });
    }
  };

  useEffect(() => {
    if (selectedCityRow?.code) {
      getDistricts(selectedCityRow);
    }
  }, [selectedCityRow]);

  const districtSelect = (val) => {
    setSelectedDistrictRow(val);
  };

  const checkDataStatus = (tempInfo, key, data) => {
    if (data[key] !== undefined) {
      tempInfo[key] = data[key];
    } else {
      tempInfo[key] = tempInfo[key];
    }
  };

  const checkIfExist = (data) => {
    const keys = Object.keys(data);
    const { confirmationInfo: { customerInfo: { icKapiNo, disKapiNo } } } = props;
    if (keys.includes('icKapiNo')) {
      keys[keys.indexOf('icKapiNo')] = (icKapiNo && icKapiNo !== '') ? undefined : 'icKapiNo';
    }
    if (keys.includes('disKapiNo')) {
      keys[keys.indexOf('disKapiNo')] = (disKapiNo && disKapiNo !== '') ? undefined : 'disKapiNo';
    }
    return keys.filter((k) => k);
  };

  const onSubmit = (data) => {
    props.handleUpdateableFlag(checkIfExist(data));
    checkDataStatus(tempConf.customerInfo, 'icKapiNo', data);
    checkDataStatus(tempConf.customerInfo, 'disKapiNo', data);
    checkDataStatus(tempConf.vehicleInfo, 'motorNo', data);
    checkDataStatus(tempConf.vehicleInfo, 'sasiNo', data);
    checkDataStatus(tempConf.customerInfo, 'email', data);
    checkDataStatus(tempConf.customerInfo, 'mahalle', data);
    checkDataStatus(tempConf.customerInfo, 'caddeSokak', data);
    checkDataStatus(tempConf.customerInfo, 'telefon', data);
    if (selectedCityRow && selectedDistrictRow) {
      tempConf.customerInfo.adresIlAck = selectedCityRow.name;
      tempConf.customerInfo.adresIlCode = selectedCityRow.code;
      tempConf.customerInfo.adresIlceAck = selectedDistrictRow.name;
      tempConf.customerInfo.adresIlceCode = selectedDistrictRow.code;
    }
    props.updateConfirmation(tempConf);
  };

  return (
    <>
      <Paper style={style} square>
        <div>
          <div
            style={{
              marginTop: '33px',
              width: isMobile ? '100%' : 'unset',
            }}
          >
            <h4>Eksik Bilgilerinizi Tamamlayın</h4>
            <form style={{ padding: '20px 30px 0 30px' }}>
              {(props.confirmationInfo.customerInfo.telefon === ''
                || props.confirmationInfo.customerInfo.telefon === null) && (
                <KLInput
                  name="telefon"
                  mask="(599) 999 99 99"
                  label="Telefon Numarası"
                  control={control}
                  rules={phoneValidation()}
                  error={errors}
                  touchedFields={touchedFields}
                  reset={reset}
                />
              )}
              {(props.confirmationInfo.customerInfo.email === ''
                || props.confirmationInfo.customerInfo.email === null) && (
                <KLInput
                  name="mail"
                  control={control}
                  label="E-posta Adresi"
                  rules={emailValidation()}
                  error={errors}
                  touchedFields={touchedFields}
                />
              )}
              {(props.confirmationInfo.vehicleInfo.sasiNo === ''
                || props.confirmationInfo.vehicleInfo.sasiNo.indexOf('CAHSNM') >= 0) && (
                <KLInput
                  name="sasiNo"
                  control={control}
                  label="Şasi No"
                  rules={minLength(17)}
                  inputProps={{
                    type: 'text',
                    maxLength: '17',
                  }}
                  error={errors}
                  touchedFields={touchedFields}
                />
              )}
              {(props.confirmationInfo.vehicleInfo.motorNo === ''
                || props.confirmationInfo.vehicleInfo.motorNo.indexOf('MTRNM') >= 0) && (
                <KLInput
                  name="motorNo"
                  control={control}
                  label="Motor No"
                  rules={minLength(5)}
                  inputProps={{
                    type: 'text',
                    maxLength: '25',
                  }}
                  error={errors}
                  touchedFields={touchedFields}
                />
              )}
              {(props.confirmationInfo.customerInfo.mahalle
                || props.confirmationInfo.customerInfo.caddeSokak
                || props.confirmationInfo.customerInfo.adresIlceAck
                || props.confirmationInfo.customerInfo.adresIlAck)
                && (!props.confirmationInfo.customerInfo.disKapiNo || !props.confirmationInfo.customerInfo.icKapiNo) && (
                  <p className="text">
                    Adres:
                    {' '}
                    {props.confirmationInfo.customerInfo.mahalle
                      && `${props.confirmationInfo.customerInfo.mahalle} Mahallesi,`}
                    {' '}
                    {props.confirmationInfo.customerInfo.caddeSokak
                      && `${props.confirmationInfo.customerInfo.caddeSokak} Sokak,`}
                    {' '}
                    {props.confirmationInfo.customerInfo.adresIlceAck
                      && `${props.confirmationInfo.customerInfo.adresIlceAck},`}
                    {' '}
                    {props.confirmationInfo.customerInfo.adresIlAck
                      && `${props.confirmationInfo.customerInfo.adresIlAck}`}
                  </p>
              )}
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gap: '10px',
                  marginTop:
                    props.confirmationInfo.customerInfo.mahalle
                    || props.confirmationInfo.customerInfo.caddeSokak
                    || props.confirmationInfo.customerInfo.adresIlceAck
                    || props.confirmationInfo.customerInfo.adresIlAck
                      ? '30px'
                      : '0',
                }}
              >
                {!props.confirmationInfo.customerInfo.adresIlAck && (
                  <Autocomplete
                    openOnFocus
                    id="model-type-select"
                    options={cities}
                    PaperComponent={CustomPaper}
                    getOptionLabel={(option) => option.name}
                    noOptionsText="Sonuç bulunamadı"
                    value={selectedCityRow}
                    filterOptions={(x, e) => {
                      if (e.inputValue) {
                        return x.filter((elem) => elem.name.toLocaleLowerCase().includes(e.inputValue.toLocaleLowerCase()));
                      }
                      return x;
                    }}
                    renderOption={(props, option, { selected }) => (
                      <MenuItem {...props} value={option.code} key={option.name} data-text={option.name}>
                        {option.name}
                      </MenuItem>
                    )}
                    onChange={(event, value) => {
                      getDistricts(value);
                    }}
                    style={{
                      gridColumn: 'span 2'
                    }}
                    renderInput={(params) => <TextField {...params} label="İl" />}
                  />
                )}
                {!props.confirmationInfo.customerInfo.adresIlceAck && (
                  <Autocomplete
                    openOnFocus
                    disabled={!selectedCityRow}
                    id="model-type-select"
                    options={districts}
                    PaperComponent={CustomPaper}
                    getOptionLabel={(option) => option.name}
                    noOptionsText="Sonuç bulunamadı"
                    value={selectedDistrictRow}
                    filterOptions={(x, e) => {
                      if (e.inputValue) {
                        return x.filter((elem) => elem.name.toLocaleLowerCase().includes(e.inputValue.toLocaleLowerCase()));
                      }
                      return x;
                    }}
                    renderOption={(props, option, { selected }) => (
                      <MenuItem {...props} value={option.code} key={option.name} data-text={option.name}>
                        {option.name}
                      </MenuItem>
                    )}
                    onChange={(event, value) => {
                      districtSelect(value);
                    }}
                    style={{
                      gridColumn: 'span 2'
                    }}
                    renderInput={(params) => <TextField {...params} label="İlçe" />}
                  />
                )}
                {!props.confirmationInfo.customerInfo.mahalle && (
                  <KLInput
                    name="mahalle"
                    control={control}
                    label="Mahalle"
                    rules={requiredArea()}
                    error={errors}
                    touchedFields={touchedFields}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        width: '120px',
                        '&.Mui-error': {
                          width: '120px',
                        },
                      },
                    }}
                  />
                )}
                {!props.confirmationInfo.customerInfo.mahalle && (
                  <KLInput
                    name="caddeSokak"
                    control={control}
                    label="Cadde/Sokak"
                    rules={requiredArea()}
                    error={errors}
                    touchedFields={touchedFields}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        width: '120px',
                        '&.Mui-error': {
                          width: '120px',
                        },
                      },
                    }}
                  />
                )}
                {(!props.confirmationInfo.customerInfo.disKapiNo || !props.confirmationInfo.customerInfo.icKapiNo) && (
                  <>
                    <KLInput
                      name="icKapiNo"
                      control={control}
                      label="İç Kapı No"
                      defaultValue={props.confirmationInfo.customerInfo.icKapiNo}
                      disabled={
                        props.confirmationInfo.customerInfo.icKapiNo !== ''
                        && props.confirmationInfo.customerInfo.icKapiNo !== null
                      }
                      rules={requiredArea()}
                      error={errors}
                      touchedFields={touchedFields}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          width: '120px',
                          '&.Mui-error': {
                            width: '120px',
                          },
                        },
                      }}
                    />
                    <KLInput
                      name="disKapiNo"
                      control={control}
                      label="Dış Kapı No"
                      defaultValue={props.confirmationInfo.customerInfo.disKapiNo}
                      disabled={
                        props.confirmationInfo.customerInfo.disKapiNo !== ''
                        && props.confirmationInfo.customerInfo.disKapiNo !== null
                      }
                      rules={requiredArea()}
                      error={errors}
                      touchedFields={touchedFields}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          width: '120px',
                          '&.Mui-error': {
                            width: '120px',
                          },
                        },
                      }}
                    />
                  </>
                )}
              </div>
            </form>
          </div>
          <Button
            variant="contained"
            disabled={
              !isValid
              || (!props.confirmationInfo.customerInfo.adresIlAck && !selectedCityRow)
              || (!props.confirmationInfo.customerInfo.adresIlceAck && !selectedDistrictRow)
            }
            style={{
              width: !isMobile ? '254px' : '254px',
              height: '45px',
              boxSizing: 'border-box',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
              borderRadius: '5px',
              textTransform: 'none',
            }}
            onClick={handleSubmit(onSubmit)}
          >
            Tamamla
          </Button>
        </div>
      </Paper>
    </>
  );
};

export default withRouter(MissingConfirmation);
