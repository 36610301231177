import axiosInstance from '../../shared/_helpers/axiosHelper';
import { API } from '../../shared/_helpers/env';
import { interceptors } from '../../shared/_helpers/interceptors';

async function sendMailKaskoTeminatlari(data, callback) {
  interceptors.setXConsumer();
  await axiosInstance.post(`${API}/api/quotation/send-casco-coverages-mail`, data).then((response) => {
    try {
      callback({ success: true });
    } catch (error) {
      console.log(error);
    }
  });
}

async function sendMailTeminatlar(data, callback) {
  interceptors.setXConsumer();
  await axiosInstance.post(`${API}/api/quotation/send-quotation-mail`, data).then((response) => {
    try {
      callback({ success: true });
    } catch (error) {
      console.log(error);
    }
  });
}

const mailService = {
  sendMailKaskoTeminatlari,
  sendMailTeminatlar,
};
export default mailService;
