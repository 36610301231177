import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Autocomplete,
  Paper,
  Popper,
} from '@mui/material';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import informationActions from '../../../actions/informationAction';
import '../../../../shared/assets/styles/radio-button.scss';
import '../../../../shared/assets/styles/pages/car-info.scss';

import useWindowSize from '../../../../shared/_hooks/windowResizeHook';
import vehicleService from '../../../_services/vehicle.services';


String.prototype.capitalizeFirstLetterOfEveryWord = function () {
  let newSentence = this.valueOf().toLowerCase().split(' ');
  for (let i = 0; i < newSentence.length; i += 1) {
    //* Ternary is needed because otherwise things like (RENAULT) would become (renault)
    newSentence[i] = newSentence[i].charAt(0) !== '(' ? newSentence[i].charAt(0).toUpperCase() + newSentence[i].slice(1) : newSentence[i][0] + newSentence[i].charAt(1).toUpperCase() + newSentence[i].slice(2);
  }
  newSentence = newSentence.join(' ');
  return newSentence;
};

export const EditCarInfo = (props) => {
  const size = useWindowSize();
  const URL_PARAMS = new URLSearchParams(document.location.search);
	const {
    carInfo,
		usageTypes: propsUsageTypes,
		processStep,
		vehicleInfoChange,
		setEditedCarInfo,
		editedCarInfo,
  } = props;

  const uid = URL_PARAMS.get('jt');
  const isMobile = size.width <= 880;
  const [brandList, setBrandList] = useState([]);
  const [brandModelYear, setBrandModelYear] = useState([]);
  const [brandModel, setBrandModel] = useState([]);
  const [usageTypes, setUsageTypes] = useState([]);
  const [vehicleType, setVehicleType] = useState(carInfo.usageType?.Value ? propsUsageTypes.find(type => type.Value === carInfo.usageType).Value :
	null);
  const [selectedVehicleType, setSelectedVehicleType] = useState(null);
  const [carBrand, setCarBrand] = useState('');
  const [carModel, setCarModel] = useState('');
  const [modelYear, setModelYear] = useState('');

  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);

  const [isTouched, setIsTouched] = useState(false);

  const dispatch = useDispatch();
  const {
    vehicle, vehicleDetail, dropDown,
  } = useSelector((state) => state.info);

  const {
    handleSubmit,
    reset,
    formState: { errors, isValid, touchedFields },
    control,
    setValue,
  } = useForm({
    mode: 'onChange',
  });

  const {
    isRenewal,
  } = useSelector((state) => state.shell);

  const resetForm = () => {
    dispatch(informationActions.setVehicleInfo({}));
    setBrandModelYear([]);
    setBrandModel([]);
    setModelYear('');
    setCarModel('');
    setSelectedModel(null);
  };

  const carBrandSelect = (event, value, brandRow) => {
    resetForm();
    setCarBrand(value);
    setSelectedBrand(brandRow);
		if (!!value) {
      setEditedCarInfo((prevState) => ({
        ...prevState,
        year: null,
        modelCode: null,
      }));
      vehicleService.getVehicleModelYear({ BuyStat: 0, Kod: brandRow?.Value }, (result) => {
        setBrandModelYear(result.vehicleModelYearList);
        if(brandRow?.Value == carInfo.brandCode && !isTouched){
          carModelYearSelect(null, null, result.vehicleModelYearList.find((year) => year.Value == carInfo.year).Value, brandRow.Value)
          setEditedCarInfo((prevState) => ({
            ...prevState,
            year: result.vehicleModelYearList.find((year) => year.Value == carInfo.year).Value,
          }));
        }else{
          setEditedCarInfo((prevState) => ({
            ...prevState,
            brandCode: brandRow?.Value,
          }));
          setIsTouched(true)
        }
      });
    }
  };

	useEffect(() => {
		if(isTouched){
			vehicleInfoChange(true)
		}
	}, [isTouched]);


  const vehicleTypeSelect = (event, value, row) => {
    setVehicleType(value);
    setSelectedVehicleType(row);

		setEditedCarInfo((prevState) => ({
			...prevState,
			usageType: value,
		}));
  };

  const carModelYearSelect = (event, child, value = null, brand = null) => {
		const modelYearValue = event?.target?.value || value || null
    setModelYear(modelYearValue);
    setCarModel(null);
    setSelectedModel(null);
		setEditedCarInfo((prevState) => ({
			...prevState,
			modelCode: null,
		}));
    vehicleService.getVehicleModel({ yil: modelYearValue, kod: brand || selectedBrand.Value || editedCarInfo.brandCode }, (result) => {
      setBrandModel(result.vehicleModelList);
			if(carInfo.modelCode && (modelYearValue == carInfo.year) && !isTouched){
				const brandModelRow = result.vehicleModelList.find((model) => model.Value == carInfo.modelCode)
				setCarModel(brandModelRow.Value);
				setSelectedModel(brandModelRow)
				setEditedCarInfo((prevState) => ({
					...prevState,
					modelCode: brandModelRow.Value,
				}));
			}else{
				setIsTouched(true)
				setEditedCarInfo((prevState) => ({
					...prevState,
					year: modelYearValue,
				}));
			}
    });
  };

  useEffect(() => {
    vehicleTypeSelect(
      null,
      carInfo.usageType,
      usageTypes.find((usage) => usage.Value === carInfo.usageType),
    );
  }, [usageTypes]);

  useEffect(() => {
		if (!propsUsageTypes) {
			vehicleService.getVehicleUsageTypes({ vehicleBrandId: '1' }, (data) => {
				setUsageTypes(data.vehicleUsageTypeNameList);
			});
		}else{
			setUsageTypes(propsUsageTypes);
		}
		if(processStep === 10){
			vehicleService.getVehicleBrand((data) => {
				if (carInfo.brandCode) {
          const brandRow = data.vehicleBrandList.find((x) => x.Value == carInfo.brandCode)
					setSelectedBrand(brandRow);
					carBrandSelect(null, brandRow?.Value, brandRow);
				}
				setBrandList(data.vehicleBrandList);
			});
		}else if(processStep === 30){
			vehicleService.getVehicleModel({ yil: carInfo.year, kod: carInfo.brandCode }, (result) => {
				setBrandModel(result.vehicleModelList);
				const modelValue = result.vehicleModelList.find((x) => x.Value === carInfo.modelCode)
				setSelectedModel(modelValue);
				setCarModel(modelValue.Value);
			});
		}
  }, []);

  const CustomPaper = (props) => (
    <Paper
      style={{
        fontSize: '13px',
        // minWidth: isMobile ? '100%' : '368px',
				width: 'auto',
      }}
      elevation={8}
      {...props}
    />
  );
  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12} sm={12} lg={12} xl={12} item>
					<>
            <div>
              <FormControl style={{ margin: '15px 0 0 0' }}>
                <Autocomplete
                  openOnFocus
                  noOptionsText="Sonuç bulunamadı"
                  id="model-type-select"
                  options={usageTypes}
                  value={selectedVehicleType}
                  getOptionLabel={(option) => option.Text}
                  style={{
								  width: 'auto',
                  }}
                  filterOptions={(x, e) => {
							  if (e.inputValue) {
							    return x.filter(
							      (elem) => elem.Value.includes(e.inputValue)
										|| elem.Text.toLocaleLowerCase('tr').includes(e.inputValue.toLocaleLowerCase('tr')),
							    );
							  }
							  return x;
                  }}
                  PopperComponent={(props) => (
                    <Popper
                      {...props}
                      style={{
									  width: isMobile ? '100%' : '254px',
									  height: 0,
									  boxShadow:
											'0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
                      }}
                      placement="bottom-start"
                      disablePortal
                    />
                  )}
                  ListboxProps={{ style: { maxHeight: 250 } }}
                  renderOption={(props, option, { selected }) => (
                    <MenuItem style={{whiteSpace: 'normal'}} {...props} value={option.Value} key={option.Text} data-text={option.Text}>
                      {option.Text}
                    </MenuItem>
                  )}
                  onChange={(event, value) => {
							  if (value) {
							    vehicleTypeSelect(event, value.Value, value);
									setIsTouched(true)
							  } else {
							    vehicleTypeSelect(event, '', null);
									setIsTouched(true)
							  }
                  }}
                  sx={{ width: 'auto' }}
                  renderInput={(params) => (
                    <TextField
                      className="autocomplate-label-container"
                      onChange={() => {
									  setCarModel('');
									  setCascoCodeList([]);
                      }}
                      {...params}
                      label="Aracınızın türünü seçiniz"
                    />
                  )}
                />
              </FormControl>
            </div>
					</>
					{processStep === 10 && (<>
            <div>
              <Autocomplete
                openOnFocus
                id="model-type-select"
                options={brandList}
                PaperComponent={CustomPaper}
                getOptionLabel={(option) => option.Text}
                noOptionsText="Sonuç bulunamadı"
                value={selectedBrand}
                filterOptions={(x, e) => {
                  if (e.inputValue) {
                    return x.filter(
                      (elem) => elem.Value.includes(e.inputValue) || elem.Text.includes(e.inputValue.toUpperCase()),
                    );
                  }
                  return x;
                }}
                renderOption={(props, option, { selected }) => (
                  <MenuItem style={{ whiteSpace: 'normal',textAlign: 'start', wordBreak: 'break-word' }} {...props} value={option.Value} key={option.Text} data-text={option.Text}>
                    {option.Text.replaceAll("/", " / ").replaceAll("  ", " ")}
                  </MenuItem>
                )}
                onChange={(event, value) => {
                  if (value) {
                    carBrandSelect(event, value.Value, value);
                  } else {
                    carBrandSelect(event, '', null);
                  }
                }}
                sx={{ width: 'auto' }}
                renderInput={(params) => <TextField {...params} label="Aracınızın markasını yazınız" />}
              />
            </div>
            <div>
              <FormControl>
                <InputLabel id="label-for-modelyear">Aracınızın model yılını seçiniz</InputLabel>
                <Select
                  disabled={brandModelYear.length === 0 && !vehicle.modelYear}
                  labelId="label-for-modelyear"
                  id="car-brand-year"
                  label="Aracınızın model yılını seçiniz"
                  value={modelYear}
                  onChange={carModelYearSelect}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center',
                    },
                    style: { maxWidth: '150px'},
                  }}
									sx={{
										'&': {
											textAlign: 'start',
										}
									}}
                >
                  {brandModelYear.map((brand) => (
                    <MenuItem key={brand.Text} value={brand.Value} data-text={brand.Text}>
                      {brand.Text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </>)}
          {(processStep === 10 || processStep === 30) && (<div>
            <FormControl value={brandModel !== undefined ? brandModel.text : ''}>
              <Autocomplete
                disabled={isRenewal ? vehicleDetail === undefined : brandModel.length === 0}
                openOnFocus
                noOptionsText="Sonuç bulunamadı"
                id="model-type-select"
                options={brandModel}
                value={selectedModel}
                getOptionLabel={(option) => option.Text}
                filterOptions={(x, e) => {
                  if (e.inputValue) {
                    return x.filter(
                      (elem) => elem.Value.includes(e.inputValue) || elem.Text.includes(e.inputValue.toUpperCase()),
                    );
                  }
                  return x;
                }}
                PopperComponent={(props) => (
                  <Popper
                    {...props}
                    style={{
                      width: 'auto',
                      height: 0,
                      boxShadow:
                          '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
                    }}
                    placement="bottom-start"
                    disablePortal
                  />
                )}
                ListboxProps={{ style: { maxHeight: 250 } }}
                renderOption={(props, option, { selected }) => (
                  <MenuItem style={{ whiteSpace: 'normal',textAlign: 'start' }}
									 {...props} value={option.Value} key={option.Text} data-text={option.Text}>
                    {option.Text.capitalizeFirstLetterOfEveryWord()}
                  </MenuItem>
                )}
                onChange={(event, value) => {
                  if (value) {
                    setCarModel(value.Value);
                    setSelectedModel(value);
										setIsTouched(true)
										setEditedCarInfo((prevState) => ({
											...prevState,
											modelCode: value.Value,
										}));
                  } else {
                    setCarModel('');
                    setSelectedModel(null);
										setIsTouched(true)
										setEditedCarInfo((prevState) => ({
											...prevState,
											modelCode: null,
										}));
                  }
                }}
                sx={{
									width: 'auto',
								}}
                renderInput={(params) => (
                  <TextField
                    className="autocomplate-label-container"
                    onChange={() => {
                      setCarModel('');
                      setCascoCodeList([]);
                    }}
                    {...params}
                    label="Aracınızın modelini seçiniz"
                  />
                )}
              />
            </FormControl>
          </div>)}
        </Grid>
      </Grid>
    </>
  );
};

export default withRouter(EditCarInfo);

