import React, { useState } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import CenteredModal from "../../../../motor/views/components/widget/CenteredModal";
import UserConsentModal from "../../../../motor/views/components/widget/UserConsentModal";

import classes from "./userConsent.module.scss";

export const UserConsent = (props) => {
  const { onChange, checked } = props;
  const [userConsentModal, setUserConsentModal] = useState(false);
  const [userConsent, setUserConsent] = useState(checked || false);

  const userConsentHook = (e) => {
    setUserConsentModal(true);
    if (e.target.href === "") {
      e.preventDefault();
    }
    return false;
  };

  const handleCheck = (e) => {
    setUserConsent(e.target.checked);
    onChange(e.target.checked);
  };

  return (
    <>
      <FormControlLabel
        className={classes.label}
        control={<Checkbox onChange={handleCheck} checked={userConsent} />}
        label={
          <p className="desc" style={{ margin: "0" }}>
            Kampanyalar hakkında Koalay’dan e-posta, sms ve telefonla bilgi almak istiyorum. Yukarıda girdiğim ve{" "}
            <a onClick={(e) => userConsentHook(e)}>açık rıza metnindeki</a> verilerin işlenmesine izin veriyorum.
          </p>
        }
      />
      <CenteredModal
        className="user-consent-modal"
        isOpen={userConsentModal}
        setOpen={() => setUserConsentModal(false)}
      >
        <UserConsentModal />
      </CenteredModal>
    </>
  );
};

export default UserConsent;
