export const renewalModelRadioOptions = [
  {
    Text: 'Araç modelini kendin seç',
    Value: 0,
  },
  {
    Text: 'Kasko kodunu girerek araç modelini seç',
    Value: 1,
  },
];
