import axios from 'axios';
import Rollbar from 'rollbar';
import { API, ROLLBAR_ENV, ROLLBAR_TOKEN } from './env';

const axiosInstance = axios.create({
  baseURL: API,
});

const rollbar = new Rollbar({
  accessToken: ROLLBAR_TOKEN,
  environment: ROLLBAR_ENV,
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => ({
    ...config,
    headers: { ...config.headers },
  }),
  (error) => {},
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
  // rollbar.error(`${error} - ${error.request.__rollbar_event.body.url.split('/api/')[1].split('?')[0]}`);

    Promise.reject(error)
  ,
);

export default axiosInstance;
