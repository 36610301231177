import {
  Box, Button,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import EmptyState from '../../../../shared/assets/img/svg/my-policy/EmptyState.svg';
import '../../../../shared/assets/styles/pages/mypolicies-empty-page.scss';
import '../../../../shared/assets/styles/pages/mypolicies-page.scss';
import useWindowSize from '../../../../shared/_hooks/windowResizeHook';
import policyService from '../../../_services/policy.service';
import MyPoliciesPolicy from './MyPoliciesPolicy';
import loadingKlActions from '../../../actions/loadingActions';
import headerActions from '../../../actions/headerAction';

export const MyPoliciesPage = (props) => {
  const size = useWindowSize();
  const isMobile = size.width <= 880;
  const [activePolicyList, setActivePolicyList] = useState([]);
  const [passivePolicyList, setPassivePolicyList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const phoneNumber = atob(localStorage.getItem('phoneNumber'));
  const identityNumber = atob(localStorage.getItem('identityNumber'));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadingKlActions.hideKlLoading());
    setIsLoading(true);
    dispatch(loadingKlActions.showKlLoadingEmpty());
		dispatch(headerActions.setUseHeader(true));
    dispatch(headerActions.setMainNav(true));
    if (!localStorage.getItem('phoneNumber') || !localStorage.getItem('identityNumber')) {
      props.history.push({
        pathname: '/quotation/police-giris-yap',
      });
    }
    const customerObject = {
      identityNumber,
      phoneNumber,
    };
    policyService.customerPolicies(customerObject, (customerPolicies) => {
      const { activePolicies, passivePolicies } = customerPolicies;
      if (activePolicies.length > 0) {
        setActivePolicyList(activePolicies);
      }
      if (passivePolicies.length > 0) {
        setPassivePolicyList(passivePolicies);
      }
      setIsLoading(false);
      dispatch(loadingKlActions.hideKlLoading());
    });
  }, []);

  const redirectToKoalayLanding = () => {
    window.location.href = 'https://www.koalay.com/';
  };

  return (
    <div className="mp-empty-container">
      {!isLoading && (
      <>
        <div className="mp-active-policies-container">
          <Box className="cancellation-container mp-header-box">
            <p className="mp-policies-header">
              Aktif Poliçelerim
            </p>
          </Box>
          {activePolicyList.length <= 0 && (
          <Box className="cancellation-container mp-empty-main-box hasButton">
            <img src={EmptyState} alt="" width={isMobile ? 150 : 200} />
            {(activePolicyList.length <= 0 && passivePolicyList.length <= 0)
              ? (<h2 className="mp-empty-header">Henüz Poliçen Bulunmuyor</h2>)
              : (<h2 className="mp-empty-header">Aktif Poliçen Bulunmuyor</h2>)}
            <p>
              Koalay.comdan satın alınmış bir poliçeniz bulunmamakta. Dilerseniz aşağıdaki sigorta ürünlerinden teklif alabilir, size özel karşılaştırmalı fiyatlarımızı görebilirsiniz.
            </p>
            <div className="button-container">
              <Button
                variant="contained"
                onClick={() => redirectToKoalayLanding()}
                style={{
                  width: !isMobile ? '230px' : '100%',
                  height: '45px',
                  margin: !isMobile ? '0px 7px' : '16px',
                  boxSizing: 'border-box',
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
                  borderRadius: '5px',
                  textTransform: 'none',
                }}
              >
                Poliçe Teklifleri Al
              </Button>
            </div>
          </Box>
          )}
          {activePolicyList.length > 0 && (
          <div className="mp-active-policies">
            {activePolicyList.map((policy) => (
              <MyPoliciesPolicy key={policy.teklifTID} policy={policy} isActivePolicy />
            ))}
          </div>
          )}
        </div>

        {passivePolicyList.length > 0 && (
        <div className="mp-passive-policies-container">
          <Box className="cancellation-container mp-header-box">
            <p className="mp-policies-header">
              Pasif Poliçelerim
            </p>
          </Box>
          {passivePolicyList.map((policy) => (
            <MyPoliciesPolicy key={policy.teklifTID} policy={policy} isActivePolicy={false} />
          ))}
        </div>
        )}
      </>
      )}
    </div>
  );
};

export default withRouter(MyPoliciesPage);
