import { toastrTypes } from '../types/toastr.types';

const initialState = {
  show: false,
  message: '',
  variant: 'success',
};

export default function TOASTR_REDUCER(state = initialState, { type, payload }) {
  switch (type) {
    case toastrTypes.SHOW_TOASTR:
      return {
        ...state,
        show: true,
        message: payload.message,
        variant: payload.variant,
      };
    case toastrTypes.HIDE_TOASTR:
      return {
        ...state,
        show: false,
      };
    default:
      return state;
  }
}
