import shellService from '../../motor/_services/shell.service';
import consumerActions from '../../motor/actions/consumerAction';
import shellActions from '../../motor/actions/shellAction';
const resolveConsumerFnc = (dispatch, callBack) => {
	
  shellService.resolveUrl((urlResponse) => {
    const consumer = urlResponse.consumer;
    dispatch(
     consumerActions.setConsumer(JSON.stringify(consumer))
    );
		dispatch(
			shellActions.setIsAgency(consumer.isDealer === true && consumer.isAgency === true)
		);
		dispatch(
			shellActions.setIsMerchant(consumer.isDealer === true && !consumer.isAgency)
		);
		dispatch(
			shellActions.setisCallCenter(consumer.isCallCenter === true)
		);
		if(consumer.isCallCenter === true){
			const params = new URLSearchParams(document.location.search);
			const agentCode = params.get('agentCode') !== null ? params.get('agentCode') : null;
			if(agentCode){
				localStorage.setItem('agentCode', agentCode)
			}
		}

		if (consumer.queryParams.all?.token) {
			localStorage.setItem("dealerToken", consumer.queryParams.all.token);
		}

		dispatch( 
			shellActions.setSbn(consumer.partnerSbn)
		);		
		dispatch( 
			shellActions.setIsKoalaySS(consumer.partnerSbn === null || consumer.partnerSbn === "KCDUTC")
		);

		if(callBack){
			callBack(urlResponse);
		}
  });
};

export default resolveConsumerFnc;
