import { consumerTypes } from '../types/consumer.types';

const initialState = {
  consumer: null,
};

export default function CONSUMER_REDUCER(state = initialState, { type, payload }) {
  switch (type) {
    case consumerTypes.SET_CONSUMER:
      return {
        ...state,
        consumer: payload.consumer,
      };
    default:
      return state;
  }
}
