export const relations = [
  {
    name: 'Eşi',
    displayName: 'Eşi',
    enumValue: 2,
    Value: 2,
    id: 2,
  },
  {
    name: 'Çocuğu',
    displayName: 'Çocuğu',
    enumValue: 3,
    Value: 3,
    id: 3,
  },
];

export const realationsEnum = {
  Kendisi: 1,
  Eşi: 2,
  Çocuğu: 3,
};

export const selfRelation = {
  name: 'Kendisi',
  displayName: 'Kendisi',
  enumValue: 1,
  Value: 1,
  id: 1,
};
