const SEQUENCE = {
  SIFIR: [
    { index: 0, name: 'info', displayName: 'Temel Bilgiler' },
    {
      index: 1, name: 'CarInfo', displayName: 'Araç Bilgileri', stepUrl: '/quotation',
    },
    {
      index: 2, name: 'ContactInfo', displayName: 'Kişisel Bilgiler', stepUrl: '/quotation/ContactInfo',
    },
    { index: 3, name: 'Offer', displayName: 'Teklifler' },
    { index: 4, name: 'Payment', displayName: 'Ödeme' },
  ],
  YENILEME: [
    { index: 0, name: 'info', displayName: 'Temel Bilgiler' },
    {
      index: 1, name: 'ContactInfo', displayName: 'Kişisel Bilgiler', stepUrl: '/quotation',
    },
    {
      index: 2, name: 'LicenseInfo', displayName: 'Ruhsat Bilgileri', stepUrl: '/quotation/LicenseInfo',
    },
    { index: 3, name: 'Offer', displayName: 'Teklifler' },
    { index: 4, name: 'Payment', displayName: 'Ödeme' },
  ],
};
export default SEQUENCE;
