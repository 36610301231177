import React, { useEffect, useState } from 'react';
import {
  Button, Checkbox, FormControlLabel, Modal,
} from '@mui/material';
import { withRouter } from 'react-router-dom';
import { Box } from '@mui/system';
import useWindowSize from '../../../../../shared/_hooks/windowResizeHook';
import tickGreen from '../../../../../shared/assets/img/svg/tick-green.svg';
import important from '../../../../../shared/assets/img/svg/Box Important.svg';
import info from '../../../../../shared/assets/img/svg/infoBlue.svg';
import CenteredModal from '../../../../../motor/views/components/widget/CenteredModal';

export const Information = (props) => {
  const [isCampaignModalOpen, setIsCampaignModalOpen] = useState(false);

  const size = useWindowSize();
  const isMobile = size.width <= 880;
  const isKoalaySS = document.location.href.indexOf('koalay') >= 0;

  return (
    <>
      <div className="policy-info success">
        <img src={tickGreen} height={isMobile ? 38 : 74} width={isMobile ? 38 : 74} />
				<h2>Tebrikler, poliçeniz hazır!</h2>
        {props.campaignDescription && <p className="policy-info-description">{props.campaignDescription}</p>}
        {props.campaignLink && <span onClick={() => setIsCampaignModalOpen(true)} dangerouslySetInnerHTML={{ __html: props.campaignLink }} className="policy-info-link" />}
      </div>
      <CenteredModal isOpen={isCampaignModalOpen} setOpen={() => setIsCampaignModalOpen(false)}>
        <div dangerouslySetInnerHTML={{ __html: props.campaignDetail }} />
      </CenteredModal>
    </>
  );
};

export default withRouter(Information);
