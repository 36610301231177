import React, { useEffect, useState } from 'react';
import { Button, Collapse } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ShieldSVG from '../../../../../shared/assets/img/svg/Shield.svg';
import '../../../../../shared/assets/styles/components/secure-payment-warning.scss';
import { UserStates } from '../../../../data/userStates';
import useUserState from '../../../../../shared/_hooks/userStateHook';

export const SecurePaymentWarning = (props) => {
  const { setUserState } = useUserState();
  const URL_PARAMS = new URLSearchParams(document.location.search);
  const [warningDetail, setWarningDetail] = useState(false);
  const jt = URL_PARAMS.get('jt') !== null ? URL_PARAMS.get('jt') : props.location.state.jt;
  const quoteGuid = URL_PARAMS.get('qGuid') !== null ? URL_PARAMS.get('qGuid') : props.location.state.quoteGuid;
  const { handleSecurePayment } = props;

  useEffect(() => {
    const userSateData = {
      UserUID: jt,
      UserStateDefinition: UserStates.Potansiyel_Musteri_Paratika_Uyari_Metni_Goren,
    };
    setUserState(userSateData);
  }, []);

  const onAccept = () => {
    window.dataLayer.push({ funnel_variables: null });
    window.dataLayer.push({
      event: 'paratika_info',
      funnel_variables: {
        jt,
        qGuid: quoteGuid,
      },
    });
    handleSecurePayment();
  };

  return (
    <div>
      <>
        <div className="secure-warning-header" style={{ textAlign: 'center' }}>
          <img src={ShieldSVG} alt="" width="250" />
          <h4>Güvenli Ödeme</h4>
        </div>
        <div className="secure-warning-explanation">
          <p className="desc">
            <span>
              3D güvenli ödeme için SMS ile doğrulama kodu gönderilecek ve kartınızdan
              {' '}
              <strong>1 TL çekilecek.</strong>
            </span>
          </p>
          <p className="desc">
            <span>
              Ardından asıl poliçe tutarı tahsil edilecek ve
              {' '}
              <strong>1 TL iade edilecektir.</strong>
            </span>
          </p>
        </div>
        <div className="secure-warning-detail">
          {warningDetail && (
          <p
            onClick={() => {
              setWarningDetail(false);
            }}
          >
            {' '}
            Daha fazla bilgi al
            {' '}
            <KeyboardArrowDownIcon
              style={{
                transform: 'rotate(180deg)',
              }}
            />
          </p>
          )}
          {!warningDetail && (
          <p
            onClick={() => {
              setWarningDetail(true);
            }}
          >
            {' '}
            Daha fazla bilgi al
            {' '}
            <KeyboardArrowDownIcon />
            {' '}
          </p>
          )}
          <Collapse in={warningDetail} timeout="auto" unmountOnExit className="collapse">
            3D güvenlik doğrulama işlemi kart sahibinin güvenliği için yapılmaktadır. SMS tutarının poliçe tutarı değil de 1 TL olarak gönderilmesinin nedeni, sigorta tahsilatının sigorta şirketleri tarafından yapılması ve anında poliçenin kesilmesidir. SMS doğrulaması sonrası kartınızda yeterli bakiye olması durumunda poliçeniz 1-2 dakika içerisinde aktif olacaktır.
          </Collapse>
        </div>
        <div className="secure-warning-button-container">
          <Button
            variant="contained"
            style={{
							  display: 'flex',
							  margin: 'auto !important',
							  marginTop: '20px',
							  height: '45px',
							  boxSizing: 'border-box',
							  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
							  borderRadius: '5px',
							  textTransform: 'none',
            }}
            onClick={onAccept}
          >
            Ödemeyi Onayla
          </Button>
        </div>
      </>
    </div>
  );
};

export default SecurePaymentWarning;
