import {
  Box, Button,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import '../../../../shared/assets/styles/pages/policy-type-card.scss';
import useWindowSize from '../../../../shared/_hooks/windowResizeHook';

export const PolicyTypeCard = (props) => {
  const size = useWindowSize();
  const isMobile = size.width <= 880;
  const { policy } = props;
  const [icon, setIcon] = useState('');
  // you can send logoUrl or uses policy.header as url without spaces and dot
  const policyLogoUrl = policy.logoUrl || policy.header;
  const imageUrl = require(`../../../../shared/assets/img/svg/my-policy/${policyLogoUrl.replace(/[\s.]+/g, '')}.svg`);

  const redirectToLandingPage = (pageUrl) => {
    window.location.href = pageUrl;
  };

  const checkHeader = () => {
    if (isMobile) {
      const splitedHeader = policy.header.split(' ');
      if (splitedHeader.length === 2) {
        return (
          <>
            {splitedHeader[0]}
            {' '}
            <br />
            {' '}
            {splitedHeader[1]}
          </>
        );
      }
      return (policy.header);
    }
    return (policy.header);
  };

  return (
    <Box className="policy-type-card">
      <img className="policy-type-icon" src={imageUrl} alt="" width={isMobile ? 48 : 64} />
      <h3 className="policy-type-header">
        {
          checkHeader()
        }
      </h3>
      <p className="policy-type-explanation">{policy.explanation}</p>
      <Button
        variant="contained"
        className="buy-policy"
        onClick={() => redirectToLandingPage(policy.landingUrl)}
        style={{ textTransform: 'none' }}
      >
        Poliçe Teklifi Al
      </Button>
    </Box>
  );
};

export default withRouter(PolicyTypeCard);
