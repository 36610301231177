import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import CenteredModal from "../../../../motor/views/components/widget/CenteredModal";
import quotationService from "../../../_services/quotation.service";
import { returnCDNlink } from "../../../helpers/stringHelpers";
import { ConfirmationInsureds } from "./components/ConfirmationInsureds.component";
import "./confirmation.scss";
import { Button, Checkbox, FormControlLabel, Modal } from "@mui/material";
import tssButtonStyle from "../../../data/tssButtonStyle";
import WarningSVG from "../../../../shared/assets/img/svg/warning.svg";
import OTP from "../../components/OTP/OTP";
import commonService from "../../../../motor/_services/common.service";
import { historyPushWithSearch } from "../../../../shared/_helpers/historyPushWithSearch";
import useWindowSize from "../../../../shared/_hooks/windowResizeHook";
import { priceFormatter } from "../../../../shared/_helpers/helperFunctions";
import informationActions from "../../../../motor/actions/informationAction";
import useUserState from "../../../../shared/_hooks/userStateHook";
import { UserStates } from "../../../../motor/data/userStates";
import loadingKlActions from "../../../../motor/actions/loadingActions";

export const Confirmation = (props) => {
  const size = useWindowSize();
  const checkboxRef = useRef(null);
	const { setUserState } = useUserState();
  const isMobile = size.width <= 880;
  const { customer, insureds } = useSelector((state) => state.info);
  const selfInsured = insureds.find((insured) => insured.self === true);

  const history = useHistory();
  const [detailResponse, setDetailResponse] = useState(null);
  const [customerObject, setCustomerObject] = useState(null);
  const [healthDeclarationModal, setHealthDeclarationModal] = useState(false);
  const [healthDeclaration, setHealthDeclaration] = useState(selfInsured?.illnessDeclarationApproval || false);
  const [mobileContainerMargin, setMobileContainerMargin] = useState(0);
  const [isOTPRequired, setIsOTPRequired] = useState(false);
  const URL_PARAMS = new URLSearchParams(document.location.search);
  let { isAgency, isCallCenter, isMerchant } = useSelector((state) => state.shell);
  const jt = URL_PARAMS.get("jt");
  const qGuid = URL_PARAMS.get("qGuid");
	const teklifTId = Array.from(URL_PARAMS.keys()).find((key) => key.toLowerCase() === 'tekliftid') !== undefined
	? URL_PARAMS.get(Array.from(URL_PARAMS.keys()).find((key) => key.toLowerCase() === 'tekliftid')).toLowerCase()
	: props?.location?.state?.teklifTID || null;

  const [mobileBottomContainerHeight, setMobileBottomContainerHeight] = useState(0);

  const measuredRef = useCallback((node) => {
    if (node !== null) {
      const nodeHeight = node.getBoundingClientRect().height;
      setMobileBottomContainerHeight(nodeHeight + 20);
    }
  }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    if (detailResponse) {
      const declaration =
        selfInsured?.illnessDeclarationApproval || detailResponse?.illnessDeclaration?.isApproved || false;
      setHealthDeclaration(declaration);
    }
  }, [detailResponse]);

  useEffect(() => {
		dispatch(loadingKlActions.hideKlLoading());
		const userSateData = {
			UserUID: jt,
			UserStateDefinition: UserStates.TSS_Confirmation_Page_Gelen,
		};
		setUserState(userSateData);
		
    const quoteDetailReq = {
			FromPage: "Confirmation",
      TeklifTId: teklifTId,
      TeklifUid: qGuid,
    };
		dispatch(loadingKlActions.showKlLoadingEmpty());
    quotationService.getQuotationDetail(quoteDetailReq, (response) => {
      response.insured.map((insured) => {
        const selectedPlan = insured.personPlan.find((plan) => plan.selected);
        const tempInsured = insured;
        tempInsured.selectedPlan = selectedPlan;
        return tempInsured;
      });
      setDetailResponse(response);
			dispatch(loadingKlActions.hideKlLoading());
    });
  }, []);

  const healthDeclarationHook = (e) => {
    if (healthDeclaration) {
      setHealthDeclaration(false);
    } else {
      setHealthDeclarationModal(true);
      if (e.target.href === "") {
        e.preventDefault();
      }
      return false;
    }
  };
  const setDeclarationInsureds = (declarationValue = null) => {
    const tempInsureds = insureds.map((insured) => {
      if (insured.self === true) {
        insured.illnessDeclarationApproval = declarationValue || healthDeclaration;
      }
      return insured;
    });
    dispatch(informationActions.setInsureds(tempInsureds));
  };

  const nextPage = () => {
		const userSateData = {
			UserUID: jt,
			UserStateDefinition: UserStates.TSS_Confirmation_Page_Devama_Tiklayan,
		};
		setUserState(userSateData);

    const personPlan = detailResponse.insured.map((insured) => {
      return {
        coverageGroupId: insured.selectedPlan.coverageGroupId,
        networkId: insured.selectedPlan.networkId,
        insuredId: insured.selectedPlan.insuredId,
        selected: true,
      };
    });
    setDeclarationInsureds();
    const requestObject = {
      teklifTId: teklifTId,
      illnessDeclarationApproval: healthDeclaration,
      personPlan: personPlan,
    };
    quotationService.updateQuotationPlan(requestObject, () => {
      historyPushWithSearch("/tamamlayici-saglik-sigortasi/Checkout", history);
    });
  };

  const scrollToCheckbox = () => {
    if (detailResponse.illnessDeclaration?.isEnabled) {
      if (!healthDeclaration) {
        checkboxRef.current.scrollIntoView();
      }
    }
  };

  const handleSubmit = () => {
    const selfInsured = insureds.find((insured) => insured.self === true);

    const customerObj = {
      phoneNumber:
        selfInsured?.mobilePhone ||
        selfInsured?.phoneNumber ||
        detailResponse.customer?.mobilePhone ||
        detailResponse.customer?.phoneNumber ||
        detailResponse.customer?.telefon,
      TCKN: selfInsured?.identityNo || detailResponse.customer?.identityNo || detailResponse.customer?.kimlikNo,
      email: selfInsured?.eMail || customer?.eMail,
    };
    setCustomerObject(customerObj);

    if (isAgency && !healthDeclaration && !isMerchant && !isCallCenter) {
      setIsOTPRequired(true);
      commonService.isInBlackList(
        {
          PhoneNumber: customerObj.phoneNumber.replace(/\D/g, ""),
          TCKN: customerObj.TCKN,
          Email: customerObj.email,
          FirstPhoneNumber: customerObj.phoneNumber.replace(/\D/g, ""),
        },
        (blresp) => {
          if (!blresp) {
            commonService.sendSMS(
              {
                OTP: null,
                OTPType: 0,
                PhoneNumber: customerObj.phoneNumber.replace(/\D/g, ""),
                TCKN: customerObj.TCKN,
                UserUId: jt,
								HasAgencyHealthDeclarationText:true,
              },
              (sms) => {}
            );
          }
        }
      );
    } else {
      setHealthDeclarationModal(false);
      setHealthDeclaration(true);
    }
  };

  return (
    detailResponse && (
      <>
        <div
          className="confirmation-container"
          style={{ marginBottom: isMobile ? `${mobileBottomContainerHeight}px ` : "auto" }}
        >
          <div className="confirmation-insureds-box">
            <p className="confirmation-header">Teklif ve Sigortalı Poliçe Detayları</p>
            {detailResponse.insured.map((insured, index) => (
              <ConfirmationInsureds
                key={insured.identityNo}
                insured={insured}
                quotation={detailResponse.quotations[0]}
                totalGrossPremium={detailResponse.quotations[0].grossPremium}
                isLast={index + 1 === detailResponse.insured.length}
                isMobile={isMobile}
                hasİllnessDeclaration={detailResponse.illnessDeclaration && detailResponse.illnessDeclaration.isEnabled}
              />
            ))}
            {isMobile && detailResponse.illnessDeclaration && detailResponse.illnessDeclaration.isEnabled && (
              <div ref={checkboxRef}>
                <FormControlLabel
                  required
                  style={{
                    margin: "0",
                    display: "flex",
                    alignItems: "center",
                  }}
                  control={<Checkbox checked={healthDeclaration} onClick={(e) => healthDeclarationHook(e)} />}
                  label={
                    <p className="label desc" style={{ marginTop: "8px" }} onClick={(e) => healthDeclarationHook(e)}>
                      Sigorta firmasının talep ettiği <a>sağlık beyanını</a> onaylıyorum.
                    </p>
                  }
                />
              </div>
            )}
          </div>
          {isAgency && isMobile && (
            <div className="confirmation-policy-agency-warning">
              <img src={WarningSVG} width={"24px"} height={"24px"} />
              <p>Sigortalının telefon numarasına sağlık beyanı için onay kodu gönderilecektir.</p>
            </div>
          )}
          {!isMobile && (
            <div className="confirmation-policy-box">
              <div className="confirmation-policy-box-inner">
                <img src={returnCDNlink(detailResponse.quotations[0].insurerCommercialName)} />
                <div className="premium-row">
                  <p className="premium-row-exp">Toplam Tutar:</p>
                  <span className="premium-row-price">
                    <p className="price">
                      {priceFormatter.format(detailResponse.quotations[0].grossPremium).split(",")[0].replace("₺", "")}{" "}
                      TL
                    </p>
                  </span>
                </div>
                {detailResponse.illnessDeclaration && detailResponse.illnessDeclaration.isEnabled && (
                  <div ref={checkboxRef}>
                    <FormControlLabel
                      required
                      style={{
                        margin: "0",
                        display: "flex",
                        alignItems: "center",
                      }}
                      control={<Checkbox checked={healthDeclaration} onClick={(e) => healthDeclarationHook(e)} />}
                      label={
                        <p
                          className="label desc"
                          style={{ marginTop: "8px" }}
                          onClick={(e) => healthDeclarationHook(e)}
                        >
                          Sigorta firmasının talep ettiği <a>sağlık beyanını</a> onaylıyorum.
                        </p>
                      }
                    />
                  </div>
                )}
                <Button
                  style={tssButtonStyle}
                  variant="contained"
                  disabled={detailResponse.illnessDeclaration?.isEnabled ? !healthDeclaration : false}
                  onClick={nextPage}
                >
                  Ödeme Adımına Geç
                </Button>
              </div>
              {isAgency && (
                <div className="confirmation-policy-agency-warning">
                  <img src={WarningSVG} width={"24px"} height={"24px"} />
                  <p>Sigortalının telefon numarasına sağlık beyanı için onay kodu gönderilecektir.</p>
                </div>
              )}
            </div>
          )}
          {isMobile && (
            <div className="confirmation-policy-box-mobile" ref={measuredRef}>
              <div className="premium-row">
                <img src={returnCDNlink(detailResponse.quotations[0].insurerCommercialName)} />
                <span className="premium-row-price">
                  <p className="premium-row-exp">Toplam Tutar:</p>
                  <p className="price">
                    {priceFormatter.format(detailResponse.quotations[0].grossPremium).split(",")[0].replace("₺", "")} TL
                  </p>
                  <p>
                    {
                      detailResponse.quotations[0].paymentOptions[
                        detailResponse.quotations[0].paymentOptions.length - 1
                      ].text
                    }
                  </p>
                </span>
              </div>
              <div style={{ width: "100%" }} onClick={scrollToCheckbox}>
                <Button
                  style={tssButtonStyle}
                  variant="contained"
                  disabled={detailResponse.illnessDeclaration?.isEnabled ? !healthDeclaration : false}
                  onClick={nextPage}
                >
                  Ödeme Adımına Geç
                </Button>
              </div>
            </div>
          )}
        </div>
        <CenteredModal
          className="health-declaration-modal"
          isOpen={healthDeclarationModal}
          setOpen={setHealthDeclarationModal}
          title="Sağlık Beyanı"
        >
          <div>
            <p>
              * Sigortalanmasını talep ettiğim tüm sigortalıların belirtilen hastalıklara sahip olmadığını, geçmişte ve
              şu anda herhangi bir sağlık sorunu olmadığını beyan eder ve verdiğim tüm bilgilerin doğruluğunu kabul
              ederim.
            </p>
            <div dangerouslySetInnerHTML={{ __html: detailResponse.illnessDeclaration?.content }} />
            <Button
              style={{ ...tssButtonStyle, marginTop: "8px" }}
              variant="outlined"
              onClick={() => {
                if (
                  isAgency &&
                  !(selfInsured?.illnessDeclarationApproval || detailResponse?.illnessDeclaration?.isApproved)
                ) {
                  handleSubmit();
                } else {
                  setHealthDeclarationModal(false);
                  setHealthDeclaration(true);
                }
              }}
            >
              Beyanı Onayla
            </Button>
          </div>
        </CenteredModal>
        <Modal
          open={isOTPRequired}
          onClose={() => setIsOTPRequired(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <OTP
            quoteId={qGuid}
            phone={customerObject?.phoneNumber.replace(/\D/g, "")}
            TCKN={customerObject?.TCKN}
						HasAgencyHealthDeclarationText={true}
						setCustomerObject={setCustomerObject}
            nextPage={() => {
              setHealthDeclarationModal(false);
              setHealthDeclaration(true);
              setIsOTPRequired(false);
              setDeclarationInsureds(true);
            }}
          />
        </Modal>
      </>
    )
  );
};

export default withRouter(Confirmation);
