import React, { useRef, useState } from 'react';
import { Grid } from '@mui/material';
import useWindowSize from '../_hooks/windowResizeHook';
import './form-builder.scss';

export const FormBuilder = (prop) => {
  const screenSize = useWindowSize();
  const isMobile = screenSize.width <= 880;
  const buttonsRef = useRef();
  const [buttonsHeight, setButtonsHeight] = useState(0);

  const {
    formBuilderObject: {
      form,
      bottom,
    },
  } = prop;

  return (
    <>
      <div className="builder-form-container" style={{ marginBottom: buttonsHeight }}>
        <Grid
          container
          columnSpacing="12px"
          justifyContent="flex-start"
        >
          {form && Object.keys(form).map((key) => {
            const {
              type,
              component: {
                type: Component,
              },
              props,
              children,
              show = true,
              mobile = false,
              hideMobile = false,
              xs = 12,
              md = 12,
              lg = 12,
            } = form[key];
            return (
              show && ((isMobile && (mobile || !hideMobile))
							|| (!isMobile && (!mobile))) && (
							<Grid item xs={xs} md={md} lg={lg}>
								<Component key={key} {...props}>
									{children || ''}
								</Component>
							</Grid>
              )
            );
          })}
        </Grid>
      </div>
      {bottom && (
      <div ref={buttonsRef} className="bottom-button-container">
        {bottom && Object.keys(bottom).map((key) => {
          const {
            type,
            component: {
              type: Component,
            },
            props,
            children,
            show = true,
            mobile = false,
            hideMobile = false,
          } = bottom[key];
          return (
            show && ((isMobile && (mobile || !hideMobile))
							|| (!isMobile && (!mobile))) && (
							<Component key={key} {...props}>
  							{children || ''}
							</Component>
            )
          );
        })}
      </div>
      )}
    </>
  );
};

export default (FormBuilder);
