import React from 'react';

export const plainTrafficAndCasco = () => (
  <div className="no-offer-description">
    <p className="warning-text" style={{ fontWeight: '700' }}>
      Aracınız için şu anda online platformumuz üzerinden
      {' '}
      <u>trafik ve kasko teklifi</u>
      {' '}
      verilemedi. Daha sonra
      tekrar teklif almayı deneyebilirsiniz.
    </p>
    <p className="explanation-header">Trafik ve kasko teklifi verilememe nedenleri başlıca şunlar olabilir:</p>
    <div className="no-offer-content">
      <ul className="zero-offer-details">
        <li>Ulusal sigorta alt yapısından kaynaklanan genel kesintiler,</li>
        <li>
          Araç fiyatlarında piyasa kaynaklı aşırı dalgalanmalar nedeniyle sigorta şirketlerinin sağlıklı teklif
          çalışamaması.
        </li>
      </ul>
    </div>
  </div>
);

export const plainTraffic = () => (
  <div className="no-offer-description">
    <p className="warning-text" style={{ fontWeight: '700' }}>
      Aracınız için şu anda online platformumuz üzerinden
      {' '}
      <u>trafik teklifi</u>
      {' '}
      verilemedi. Daha sonra tekrar
      teklif almayı deneyebilirsiniz.
    </p>
    <p className="explanation-header">Trafik teklifi verilememe nedenleri başlıca şunlar olabilir:</p>
    <div className="no-offer-content">
      <ul className="zero-offer-details">
        <li>Ulusal sigorta alt yapısından kaynaklanan genel kesintiler,</li>
        <li>
          Araç fiyatlarında piyasa kaynaklı aşırı dalgalanmalar nedeniyle sigorta şirketlerinin sağlıklı teklif
          çalışamaması,
        </li>
      </ul>
    </div>
  </div>
);

export const plainCasco = () => (
  <div className="no-offer-description">
    <p style={{ fontWeight: '700' }}>
      Aracınız için şu anda online platformumuz üzerinden
      {' '}
      <u>kasko teklifi</u>
      {' '}
      verilemedi. Daha sonra tekrar
      teklif almayı deneyebilirsiniz.
    </p>
    <p className="explanation-header">Kasko teklifi verilememe nedenleri başlıca şunlar olabilir:</p>
    <div className="no-offer-content">
      <ul className="zero-offer-details">
        <li>Ulusal sigorta alt yapısından kaynaklanan genel kesintiler,</li>
        <li>
          Araç fiyatlarında piyasa kaynaklı aşırı dalgalanmalar nedeniyle sigorta şirketlerinin sağlıklı teklif
          çalışamaması.
        </li>
      </ul>
    </div>
  </div>
);

export const dealerTrafficAndCasco = () => (
  <div className="no-offer-description">
    <p className="warning-text" style={{ fontWeight: '700' }}>
      Müşterinizin aracına şu anda online platformumuz üzerinden
      {' '}
      <u>trafik ve kasko teklifi</u>
      {' '}
      verilemedi. Daha sonra
      tekrar teklif almayı deneyebilirsiniz.
    </p>
    <p className="explanation-header">Trafik ve kasko teklifi verilememe nedenleri başlıca şunlar olabilir:</p>
    <div className="no-offer-content">
      <ul className="zero-offer-details">
        <li>Ulusal sigorta alt yapısından kaynaklanan genel kesintiler,</li>
        <li>
          Araç fiyatlarında piyasa kaynaklı aşırı dalgalanmalar nedeniyle sigorta şirketlerinin sağlıklı teklif
          çalışamaması.
        </li>
      </ul>
    </div>
  </div>
);

export const dealerTraffic = () => (
  <div className="no-offer-description">
    <p className="warning-text" style={{ fontWeight: '700' }}>
      Müşterinizin aracına şu anda online platformumuz üzerinden
      {' '}
      <u>trafik teklifi</u>
      {' '}
      verilemedi. Daha sonra tekrar
      teklif almayı deneyebilirsiniz.
    </p>
    <p className="explanation-header">Trafik teklifi verilememe nedenleri başlıca şunlar olabilir:</p>
    <div className="no-offer-content">
      <ul className="zero-offer-details">
        <li>Ulusal sigorta alt yapısından kaynaklanan genel kesintiler,</li>
        <li>
          Araç fiyatlarında piyasa kaynaklı aşırı dalgalanmalar nedeniyle sigorta şirketlerinin sağlıklı teklif
          çalışamaması,
        </li>
      </ul>
    </div>
  </div>
);

export const dealerCasco = () => (
  <div className="no-offer-description">
    <p style={{ fontWeight: '700' }}>
      Müşterinizin aracına şu anda online platformumuz üzerinden
      {' '}
      <u>kasko teklifi</u>
      {' '}
      verilemedi. Daha sonra tekrar
      teklif almayı deneyebilirsiniz.
    </p>
    <p className="explanation-header">Kasko teklifi verilememe nedenleri başlıca şunlar olabilir:</p>
    <div className="no-offer-content">
      <ul className="zero-offer-details">
        <li>Ulusal sigorta alt yapısından kaynaklanan genel kesintiler,</li>
        <li>
          Araç fiyatlarında piyasa kaynaklı aşırı dalgalanmalar nedeniyle sigorta şirketlerinin sağlıklı teklif
          çalışamaması.
        </li>
      </ul>
    </div>
  </div>
);
