const INCREMENT_STEP = 'INCREMENT_STEP';
const DECREMENT_STEP = 'DECREMENT_STEP';
const SET_STEP = 'SET_STEP';
const SET_REDIRECT_URL = 'SET_REDIRECT_URL';
const SET_FROM_INSUREDS = 'SET_FROM_INSUREDS';
const SET_SHOULD_REDIRECT_CITY_DISTRICT = 'SET_SHOULD_REDIRECT_CITY_DISTRICT';

export const stepTypes = {
  DECREMENT_STEP,
  INCREMENT_STEP,
  SET_STEP,
  SET_REDIRECT_URL,
  SET_FROM_INSUREDS,
  SET_SHOULD_REDIRECT_CITY_DISTRICT,
};
