import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import StepsBox from '../../components/StepsBox';
import FormBox from '../../components/FormBox';
import InfoForm from './form/CityDistrictForm';
import customerService from '../../../../motor/_services/customer.service';
import { convertBirthDatetoDashed } from '../../../../shared/_helpers/convertBirthDate';
import informationActions from '../../../../motor/actions/informationAction';
import stepActions from '../../../../motor/actions/stepAction';
import { tssStepDecider } from '../../../../shared/_helpers/stepDecider';
import { relations, selfRelation } from '../../../data/relations';
import getQuotes, { stepsEnum } from '../../../apiOperations/getQuotes';
import loadingKlActions from '../../../../motor/actions/loadingActions';
import toastrActions from '../../../../motor/actions/toastrAction';
import commonService from '../../../../motor/_services/common.service';

export const CityDistrict = (props) => {
  const [cityList, setCityList] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [districts, setDistricts] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const { isFamilyFlow, insureds } = useSelector((state) => state.info);
  const { step } = useSelector((state) => state.step);
  const selfInsured = insureds.find((insured) => insured.self === true);
  const dispatch = useDispatch();
  const URL_PARAMS = new URLSearchParams(document.location.search);
  const jt = URL_PARAMS.get('jt');
  const qGuid = URL_PARAMS.get('qGuid');

  const {
    handleSubmit,
    reset,
    formState: { errors, isValid, touchedFields },
    control,
    setValue,
  } = useForm({
    mode: 'onChange',
  });

  const isFormValid = isValid && selectedCity && selectedDistrict;

  const formHookObject = {
    handleSubmit,
    reset,
    control,
    setValue,
    errors,
    isValid: isFormValid,
    touchedFields,
  };

  useEffect(() => {
    dispatch(loadingKlActions.showKlLoadingEmpty());
    commonService.getCityList((data) => {
      if (data.provinces) {
        const uniqueProvinces = [];
        const map = new Map();
        for (const province of data.provinces) {
          if (!map.has(province.code)) {
            map.set(province.code, true);
            uniqueProvinces.push({
              name: province.name,
              code: province.code,
            });
          }
        }
        setCityList(uniqueProvinces);
        if (selfInsured.CityDistrict && selfInsured.CityDistrict.cityCode) {
          const city = uniqueProvinces.find((c) => c.code === selfInsured.CityDistrict.cityCode);
          setSelectedCity(city);
        }
      }
    });
    dispatch(loadingKlActions.hideKlLoading());
  }, []);

  useEffect(() => {
    if (selectedCity) {
      commonService.getDitrictsByCityId(selectedCity.code, (data) => {
        const sortedDistricts = data.districts.sort((a, b) => a.name.localeCompare(b.name, 'tr'));

        setDistricts(sortedDistricts);
        if (selfInsured.CityDistrict && selfInsured.CityDistrict.districtCode) {
          const district = data.districts.find((c) => c.code === selfInsured.CityDistrict.districtCode);
          setSelectedDistrict(district);
        }
      });
    }
  }, [selectedCity]);

  const handleCitySelect = (e) => {
    setSelectedDistrict(null);
    if (e) {
      setSelectedCity(e);
    } else {
      setSelectedCity(null);
      setDistricts([]);
      setSelectedDistrict(null);
    }
  };
  const handleDistrictSelect = (e) => {
    if (e) {
      setSelectedDistrict(e);
    } else {
      setSelectedDistrict(null);
    }
  };

  const cityObject = {
    value: selectedCity,
    onChange: handleCitySelect,
    options: cityList,
  };

  const districtObject = {
    value: selectedDistrict,
    onChange: handleDistrictSelect,
    options: districts,
  };

  const submitForm = () => {
    const apsObject = {
      UserGuid: jt,
      TcKimlikVergiNo: selfInsured.identityNo,
      MernisIlKodu: selectedCity.code,
      MernisIlAdi: selectedCity.name,
      MernisIlceKodu: selectedDistrict.code,
      MernisIlceAdi: selectedDistrict.name,
      Name: selfInsured.name,
      ExtRefId: null,
      PhoneNumber: selfInsured.phoneNumber,
    };

    customerService.sendAps(apsObject, (response) => {
    	console.log(response);
      if (response.code === '0') {
        tssStepDecider(3, props.history, false);
        selfInsured.CityDistrict = {
          cityCode: selectedCity.code,
          districtCode: selectedDistrict.code,
        };
        const otherInsureds = insureds.filter((insured) => insured.self !== true);
        dispatch(informationActions.setInsureds([
          selfInsured,
          ...otherInsureds,
        ]));
      } else {
        dispatch(toastrActions.showToastr('error', '"İl ilçe bilgileri kaydedilirken bir hata oluştu."'));
      }
    });
  };

  return (
    <>
      <StepsBox>
        <FormBox formHeader="Adres Bilgileri">
          <InfoForm formHookObject={formHookObject} submitForm={submitForm} cityObject={cityObject} districtObject={districtObject} />
        </FormBox>
      </StepsBox>
    </>
  );
};

export default withRouter(CityDistrict);

// {
// 	"name": "Onur",
// 	"lastName": "Köktaş",
// 	"identityNo": "19879767150",
// 	"birthDate": "20/01/1994",
// 	"isFull": false,
// 	"self": true,
// 	"movingForward": true,
// 	"relationObject": {
// 			"name": "Kendisi",
// 			"displayName": "Kendisi",
// 			"enumValue": 1,
// 			"Value": 1,
// 			"id": 1
// 	},
// 	"relation": 1,
// 	"relationText": "Kendisi",
// 	"mobilePhone": "5555927873",
// 	"email": "onur.koktas@hesapkurdu.com"
// }
