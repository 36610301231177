import React, { useState } from 'react';
import '../../../../../shared/assets/styles/send-mail-modal.scss';

import { useForm } from 'react-hook-form';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { phoneValidation } from '../../../../../shared/_helpers/validations';
import Support from '../../../../../shared/assets/img/svg/Support.svg';
import customerService from '../../../../../motor/_services/customer.service';
import { KLInput } from '../../../../../motor/views/components/form/KLInput';
import tssButtonStyle from '../../../../data/tssButtonStyle';

import Done from '../../../../../shared/assets/img/svg/Done.svg';
import { DEALER_MAIN_URL } from '../../../../../shared/_helpers/env';

export const AgencyRedirectCC = (props) => {
  const [modalStep, setModalStep] = useState(1);
  const { consumer } = useSelector((state) => state.consumer);
  const { customer, insureds } = useSelector((state) => state.info);
	const selfInsured = insureds.find((insured) => insured.self === true);
	const customerPhone = customer?.PhoneNumber || customer?.telefon || selfInsured?.mobilePhone;

  const {
    handleSubmit,
    formState: { errors, isValid, touchedFields },
    control,
  } = useForm({
    mode: 'onChange',
  });

  const onSubmit = (data) => {
		const URL_PARAMS = new URLSearchParams(document.location.search);
		const qGuid = URL_PARAMS.get('qGuid');
    const contactData = {
      TeklifUid: qGuid,
      Sbn: JSON.parse(consumer).partnerSbn || 'KCDUTC',
      Phone: data?.phone.replace(/\D/g, ''),
    };
    customerService.contactScheduleCallTss(contactData, (resp) => {});
    setModalStep(3);
  };

  return (
    <>
      <div className="redirect-cc-container">
        {modalStep === 1 && (
          <div className="redirect-cc">
            <img src={Support} />
            <h3>
              Üzgünüz!
            </h3>
            <p className="text">
							Seçmiş olduğunuz kriterler için yalnızca çağrı merkezimiz üzerinden poliçe işleminize devam edebiliyoruz. Dilerseniz Koalay beni arasın talebi açarak çağrı merkezimizden destek alabilirsiniz.
            </p>
              <Button
								style={{ ...tssButtonStyle, marginTop: '8px' }}
                variant="contained"
                onClick={() => setModalStep(2)}
              >
                Talep aç
              </Button>
          </div>
        )}
        {modalStep === 2 && (
          <div className="redirect-cc">
            <img src={Support} />
            <h3>
						  Teşekkürler!
            </h3>
            <p className="text">
							Tarafınıza aşağıdaki telefon numarasından ulaşılacaktır. Telefon numarasını değiştirmek isterseniz “Gönder” düğmesine basmadan önce değiştirebilirsiniz.
            </p>
            <div className="redirect-cc-send-mail">
              <form>
                <KLInput
                  name="phone"
                  control={control}
                  label="Telefon Numarası"
                  mask="(599) 999 99 99"
                  error={errors}
                  defaultValue={customerPhone}
                  rules={phoneValidation()}
                  touchedFields={touchedFields}
                  sx={{
							  '& .MuiOutlinedInput-root': {
							    width: '100%',
							    marginBottom: '0',
							    '&.Mui-error': {
							      width: '100%',
							      marginBottm: '0',
							    },
							  },
							  '.MuiInputLabel-formControl': {
							    fontSize: '15px',
							    marginBottom: '0',
							  },
							  '&': {
							    width: 'auto',
							  },
                  }}
                />
                <Button
                  variant="contained"
                  disabled={!isValid}
                  style={{
							  height: '45px',
							  boxSizing: 'border-box',
							  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
							  textTransform: 'none',
                  }}
                  onClick={handleSubmit(onSubmit)}
                >
                  Aranma talebi oluştur
                </Button>
              </form>
            </div>

          </div>
        )}
        {modalStep === 3 && (
          <div className="redirect-cc">
            <img src={Done} />
            <h3 style={{ marginTop: '-16px' }}>
              Teşekkürler
            </h3>
            <p className="text">
              Talebiniz alınmıştır. Satış danışmanlarımız size en kısa sürede ulaşacak.
            </p>
            <Button
              variant="contained"
              onClick={() => {window.location.href = DEALER_MAIN_URL}}
              style={{ ...tssButtonStyle, marginTop: '8px' }}
            >
              Ana sayfaya dön
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default AgencyRedirectCC;
