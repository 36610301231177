import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { useForm } from 'react-hook-form';

import { withRouter } from 'react-router-dom';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';

import { useDispatch, useSelector } from 'react-redux';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import useWindowSize from '../../../../shared/_hooks/windowResizeHook';
import { KLInput } from '../../../../motor/views/components/form/KLInput';
import '../../../../shared/assets/styles/pages/checkout.scss';
import SafetySVG from '../../../../shared/assets/img/svg/safety.svg';
import infoBlue from '../../../../shared/assets/img/svg/infoBlue.svg';
import {
  getUsableLogo, openPDF, priceFormatter, removeCustomEventsFromDataLayer,
} from '../../../../shared/_helpers/helperFunctions';
import commonService from '../../../../motor/_services/common.service';
import CenteredModal from '../../../../motor/views/components/widget/CenteredModal';
import toastrActions from '../../../../motor/actions/toastrAction';
import loadingKlActions from '../../../../motor/actions/loadingActions';
import {
  atLeastTwoWord, betweenLength, exactLength,
} from '../../../../shared/_helpers/validations';
import HasFailAtIssue from '../../../../motor/views/components/widget/HasFailAtIssue';
import CardOwnerIdentity from '../../../../motor/views/pages/Checkout/components/CardOwnerIdentity';
import headerActions from '../../../../motor/actions/headerAction';
import shellActions from '../../../../motor/actions/shellAction';
import quotationService from '../../../_services/quotation.service';
import Installment from '../../../../motor/views/pages/Checkout/components/Installment';
import issueService from '../../../_services/issue.service';
import useUserState from '../../../../shared/_hooks/userStateHook';
import { UserStates } from '../../../../motor/data/userStates';
import { returnCDNlink } from '../../../helpers/stringHelpers';
import PriceDiffModal from '../../../../motor/views/pages/Checkout/components/PriceDiffModal';

export const Checkout = (props) => {
  const { insureds } = useSelector((state) => state.info);
  const {consumer} = useSelector((state) => state.consumer);
  var consumerObj = JSON.parse(consumer);
  
  const selfInsured = insureds.find((insured) => insured.self === true);
  const size = useWindowSize();
  const { setUserState } = useUserState();
  const isMobile = size.width <= 880;
  const URL_PARAMS = new URLSearchParams(document.location.search);
  const jt = URL_PARAMS.get('jt');  
	const quoteGuid = URL_PARAMS.get('qGuid') !== null ? URL_PARAMS.get('qGuid') : props.location.state.quoteGuid;
  const teklifTID = Array.from(URL_PARAMS.keys()).find((key) => key.toLowerCase() === 'tekliftid') !== undefined
    ? URL_PARAMS.get(Array.from(URL_PARAMS.keys()).find((key) => key.toLowerCase() === 'tekliftid')).toLowerCase()
    : props.location.state.teklifTID;
  const [userGuid, setUserGuid] = useState(null);
  const [cvc, setCvc] = useState('');
  const [expiry, setExpiry] = useState('');
  const [focus, setFocused] = useState('');
  const [name, setName] = useState('');
  const [number, setNumber] = useState('');

  const [policyRead, setPolicyRead] = useState(false);
  const [cartData, setCartData] = useState([]);
  const [newPremium, setNewPremium] = useState(null);
  const [mainInstallmentCollapse, setMainInstallmentCollapse] = useState(true);
  const [paymentData, setPaymentData] = useState(null);
  const [selectedInstallment, setSelectedInstallment] = useState(1);

  const [hasFailAtIssue, setHasFailAtIssue] = useState(false);
  const [cardOwnerIdentity, setCardOwnerIdentity] = useState(null);
  const [cardOwnershipVisible, setCardOwnershipVisible] = useState(false);
  const [logoUrl, setLogoUrl] = useState(null);

  const [isResponseCame, setIsResponseCame] = useState(false);
  const [priceDiffModal, setPriceDiffModal] = useState(false);
  const [priceDiffData, setPriceDiffData] = useState([]);

  const dispatch = useDispatch();

  const {
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isValid, touchedFields },
    control,
  } = useForm({
    mode: 'onChange',
  });

  const goToConfirmation = () => {
    props.history.push({
      pathname: '/tamamlayici-saglik-sigortasi/confirmation',
      search: `${props.history.location.search}`,
      state: { quoteGuid, teklifTID },
    });
  };

  useEffect(() => {
		const userSateData = {
			UserUID: jt,
			UserStateDefinition: UserStates.TSS_Odeme_Sayfasina_Gelen,
		};
		setUserState(userSateData);

    setPaymentData({});
    dispatch(shellActions.setThemeByPage(true));
    dispatch(headerActions.setUseHeader(true));
    
    if (!Object.values(window.dataLayer).map((element) => element.event).includes('checkout_page')) {
      removeCustomEventsFromDataLayer(window.dataLayer);
      window.dataLayer.push({
        event: 'checkout_page',
      });
    }
  }, []);

  const handleInputFocus = (e) => {
    setFocused(e.target.name);
  };

  const handleCardNumberChange = (val) => {
    setNumber((prevNumber) => val.replace(/\s/g, ''));
  };

  const getDisclosurePdf = (tekliftid, e) => {
    e.preventDefault();
    openPDF(tekliftid, 'disclosure', quoteGuid);
  };

  const goToSummary = () => {
    const state = { ...props.location.state };
    delete state.teklifTID;
    props.history.push({
      pathname: '/tamamlayici-saglik-sigortasi/summary',
      search: `${props.history.location.search.split('&teklifTId')[0]}`,
      state: { ...state, qGuid: quoteGuid },
    });
  };
  const priceDiffBuyAction = (newTeklifTID) => {
    const params = new URLSearchParams(props.location.search);
    params.set('teklifTId', newTeklifTID);
    window.location.search = params.toString();
  };

  useEffect(() => {
    dispatch(loadingKlActions.showKlLoadingEmpty());
    quotationService.getQuotationDetail({ quoteGuid, teklifTID }, (response) => {
			const logoURLTemp = response?.quotations[0] ? 
			returnCDNlink(response?.quotations[0]?.insurerCommercialName) 
			: getUsableLogo(item, false).src;
			
			setLogoUrl(logoURLTemp);
      setCardOwnershipVisible(response.quotations[0].paymentScreenCheckCardOwner);
      setUserGuid(response.customer.userGuid);
      const blacklistObject = {
        Email: response.customer.eMail,
        PhoneNumber: response.customer.mobilePhone,
        TcKimlikVergiNo: response.customer.identityNo,
        UserUid: response.customer.userGuid,
        IpNumber: response.quotations[0].insured[0].userIp,
        Sbnkod: null,
        BlackListPage: 0,
      };
      commonService.blacklist(blacklistObject, (blResponse) => {
        if (blResponse.code === '0') {
          setCartData([
            {
              productId: response.policy.productId,
              premium: response.quotations[0].grossPremium,
              installmentOptions: { paymentPlan: response.quotations[0].paymentOptions },
              insurerCommercialName: response.quotations[0].insurerCommercialName,
              logoName: response.quotations[0].insurerCommercialName,
              productDisplayName: response.quotations[0].productDisplayName,
              installmentShow: true,
              isCash: true,
              quotationUid: response.policy.quoteID
            },
          ]);
          dispatch(loadingKlActions.hideKlLoading());
        } else {
          dispatch(toastrActions.setToastrMessage({ variant: 'error', message: blResponse.msg }));
          dispatch(loadingKlActions.showKlLoadingEmpty());
        }
      });
    });
    reset();
  }, []);

  useEffect(() => {
    if (cartData && cartData?.length > 0 && cartData[0].installmentOptions.paymentPlan) {
      cartData[0].installmentOptions.paymentPlan.map((option) => {
        option.description = option.text;
        option.paymentPlanId = Number(option.value);
      });
    }
  }, [cartData]);

  const selectInstallment = (paymentPlanId, productId) => {
    dispatch(loadingKlActions.showKlLoadingEmpty());
    const request = {
      paymentPlanId: paymentPlanId,
      productId: productId,
      parentTeklifTId : parseInt(teklifTID, 0),
      TeklifUID: cartData[0].quotationUid,
      Step: 2,
    };
    setSelectedInstallment(Number(paymentPlanId));
    quotationService.getQuotes(request, (response) => {
      setNewPremium(response.quotations[0].grossPremium);
      prepareInstallments(response.quotations.find((quotation) => quotation.productId === productId));
      dispatch(loadingKlActions.hideKlLoading());
    });
  };

  const prepareInstallments = (inst) => {
    const tempCart = [...cartData];
    if (inst.installments && inst.installments.length > 0) { // without this, this one fails after selecting 'Pesin' again
      tempCart[0].installmentOptions.paymentPlan.find((pp) => pp.paymentPlanId === inst.installments.length).installmentPlan = inst.installments;
      tempCart[0].installmentOptions.paymentPlan.find((pp) => pp.paymentPlanId === inst.installments.length).Premium = inst.grossPremium;
    }
    setCartData(tempCart);
  };

  const purchase = () => {
    dispatch(loadingKlActions.showKlLoadingEmpty());
		const userSateData = {
			UserUID: jt,
			UserStateDefinition: UserStates.TSS_Odeme_Sayfasinda_Satin_Al_Tiklayan,
		};
		setUserState(userSateData);
    const PurchaseRequestModel  = {
      BranchType: 5,
      Customer: {
        eMail: selfInsured.eMail,
        extRefId: consumerObj.dealerCode,
        height: selfInsured.height,
        identityNo: selfInsured.identityNo,
        identityType: selfInsured.identityType,
        lastName: selfInsured.lastName.substring(0, 2) + '*'.repeat(selfInsured.lastName.length - 2),
        mobilePhone: selfInsured.mobilePhone,
        name: selfInsured.name.substring(0, 2) + '*'.repeat(selfInsured.name.length - 2),
        sbn: consumerObj.partnerSbn,
        userGuid: userGuid,
        weight: selfInsured.weight
      },
      FunctionalBranchId: 3,
      Payment: {
        CardNo: number,
        CardOwnerName: name,
        Cvc2: cvc,
        ExpMonth: expiry.split('/')[0],
        ExpYear: `20${expiry.split('/')[1]}`,
        PaymentPlanId: parseInt(selectedInstallment),
        IdentityNumber: (cardOwnershipVisible && (typeof cardOwnerIdentity === 'string')) ? cardOwnerIdentity : null
      },
      ProductGroupId: 5,
      IdentityNumber: null,
      Premium: (newPremium && selectedInstallment !== 1) ? parseFloat(newPremium).toFixed(2) : parseFloat(cartData[0].premium).toFixed(2),
      ProductId: parseInt(cartData[0].productId),
      TeklifTId: parseInt(teklifTID),
      QuotationUID: quoteGuid,
      QuotationID: parseInt(teklifTID),
    };
    issueService.issue(PurchaseRequestModel, (response, error) => {
      if (!error) {
        const result = response.Result.issueResponse.policy;
        switch (response.code) {
          case '0':
            setIsResponseCame(true);
            window.dataLayer.push({ funnel_variables: null });
            window.dataLayer.push({
              event: 'payment_action',
              funnel_variables: {
                installment_month: selectedInstallment
              },
            });
            props.history.push({
              pathname: '/tamamlayici-saglik-sigortasi/thankyou',
              search: `${props.history.location.search.split('&teklifTId')[0]}&teklifTID=${result.teklifTId}`,
            });
            break;
          case '101':
            setIsResponseCame(true);
            dispatch(toastrActions.setToastrMessage({ variant: 'success', message: result.Message }));
            window.dataLayer.push({ funnel_variables: null });
            window.dataLayer.push({
              event: 'payment_action',
              funnel_variables: {
                installment_month: selectedInstallment
              },
            });
            props.history.push({
              pathname: '/tamamlayici-saglik-sigortasi/thankyou',
              search: `${props.history.location.search.split('&teklifTId')[0]}&teklifTID=${result.teklifTId}`,
            });
            break;
          case '104':
            setIsResponseCame(true);
            setPriceDiffData(response.Result.issueResponse);
            setPriceDiffModal(true);
            dispatch(loadingKlActions.hideKlLoading());
            break;
          default:
            dispatch(loadingKlActions.hideKlLoading());
            dispatch(toastrActions.setToastrMessage({ variant: 'error', message: result.Message }));
            break;
        }
      } else {
        dispatch(loadingKlActions.hideKlLoading());
        dispatch(toastrActions.setToastrMessage({ variant: 'error', message: response.Result.Message }));
      }
    })
  }

  const blurCard = () => {
    setFocused('name');
  };

  return (
    <>
      <div className="checkout">
        <div className="payment payment-tss">
          <div className="payment-left">
            <Box className="steps-container card-container tss-card-container">
              <div className="payment-header">
                <h2>Kart Bilgileri</h2>
                <div>
                  <div style={{ alignItems: 'center', display: 'flex', margin: '10px 0 8px 0' }}>
                    <img src={SafetySVG} alt="" width={!isMobile ? 32 : 20} />
                    <p className="text">Güvenli Ödeme</p>
                  </div>
                </div>
              </div>
              <div className="card-info">
                <form>
                  <KLInput
                    name="name"
                    control={control}
                    label="Kart Üzerinde Yazan İsim"
                    value={name}
                    onFocused={handleInputFocus}
                    error={errors}
                    onBlured={blurCard}
                    onChanged={setName}
                    touchedFields={touchedFields}
                    rules={atLeastTwoWord()}
                    reset={reset}
                    autoComplete="off"
                  />
                  <KLInput
                    name="number"
                    control={control}
                    label="Kart Numarası"
                    value={number}
                    mask="9999 9999 9999 9999"
                    error={errors}
                    onBlured={blurCard}
                    onChanged={(val) => handleCardNumberChange(val)}
                    onFocused={handleInputFocus}
                    touchedFields={touchedFields}
                    rules={exactLength(19)}
                    reset={reset}
                    autoComplete="off"
                    defaultValue={number}
                  />
                  <span
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '3fr 1.5fr',
                      width: isMobile ? '100%' : '254px',
                      gap: isMobile ? '0' : '0 15px',
                    }}
                  >
                    <KLInput
                      name="expiry"
                      control={control}
                      label="Son Kullanma Tarihi"
                      placeholder="AA / YY"
                      mask="99/99"
                      value={expiry}
                      onFocused={handleInputFocus}
                      error={errors}
                      onBlured={blurCard}
                      onChanged={setExpiry}
                      touchedFields={touchedFields}
                      rules={exactLength(5)}
                      reset={reset}
                      autoComplete="off"
                      defaultValue={expiry}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          width: isMobile ? '95%' : '160px',
                          '&.Mui-error': {
                            width: isMobile ? '95%' : '160px',
                          },
                        },
                        '.MuiInputLabel-formControl': {
                          fontSize: '15px',
                        },
                      }}
                    />
                    <KLInput
                      name="cvc"
                      control={control}
                      label="CVV"
                      error={errors}
                      mask="999"
                      value={cvc}
                      onFocused={handleInputFocus}
                      onChanged={setCvc}
                      onBlured={blurCard}
                      touchedFields={touchedFields}
                      rules={betweenLength(3, 4)}
                      reset={reset}
                      autoComplete="off"
                      defaultValue={cvc}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          width: isMobile ? '100%' : '80px',
                          '&.Mui-error': {
                            width: isMobile ? '100%' : '80px',
                          },
                        },
                      }}
                    />
                  </span>
                </form>
                {!isMobile && (
                <Cards
                  cvc={cvc}
                  expiry={expiry}
                  focused={focus}
                  name={name}
                  number={number}
                  locale={{ valid: '' }}
                  placeholders={{ name: 'Ad Soyad' }}
                />
                )}
              </div>
              {cardOwnershipVisible && (<CardOwnerIdentity setCardOwnerIdentity={setCardOwnerIdentity} />)}
            </Box>
            <Box className="steps-container installment-options">
              <div className="installment-header">
                <h2>Taksit Seçenekleri</h2>
                {number.length > 7 && mainInstallmentCollapse && (!cartData[0].isCash || cartData.length > 1) && (
                <p
                  className="disclaimer"
                  style={{ color: '#90979D' }}
                  onClick={() => {
                    setMainInstallmentCollapse(false);
                  }}
                >
                  {' '}
                  Kapat
                  {' '}
                  <KeyboardArrowDownIcon
                    style={{
                      transform: 'rotate(180deg)',
                    }}
                  />
                </p>
                )}
              </div>
              {cartData && cartData[0]?.installmentOptions ? (
                <Collapse in={mainInstallmentCollapse} timeout={20} unmountOnExit>
                  <Installment
                    installments={cartData[0].installmentOptions}
                    premium={cartData[0].premium}
                    selectedInstallment={selectedInstallment}
                    selectInstallment={selectInstallment}
                    productId={cartData[0].productId}
                    hasCrossSell={false}
                    paymentData={paymentData}
                    cartData={cartData}
                  />
                </Collapse>
              ) : (
                <div className="installment-info">
                  <img src={infoBlue} className="svg-icon" />
                  <p className="disclaimer">Bu ürün için taksit seçeneği bulunmamaktadır. </p>
                </div>
              )}
            </Box>
            {isMobile && (
            <div className="policy-info" style={{ margin: '0 16px' }}>
              <FormControlLabel
                style={{ margin: '0', display: 'flex', alignItems: 'flex-start' }}
                control={<Checkbox onChange={() => setPolicyRead(!policyRead)} />}
                label={(
                  <p className="disclaimer" style={{ marginTop: '8px' }}>
                    <a onClick={(e) => getDisclosurePdf(teklifTID, e)} style={{ color: '#0055A5' }}>
                      Bilgilendirme metnini
                    </a>
                    {' '}
                    okudum ve onaylıyorum.
                  </p>
                  )}
              />
            </div>
            )}
            {!isMobile && (
              <div style={{ margin: '10px 48px' }}>
                <Button
                  style={{
                    color: '#53585E',
                    textTransform: 'none',
                    fontSize: '17px',
                    fontWeight: 600,
                  }}
                  onClick={goToConfirmation}
                >
                  <ArrowBackIosNewIcon />
                  Geri
                </Button>
              </div>
            )}
          </div>

          {!isMobile && (
          <div className="cart-summary cart-summary-tss">
            {cartData.map((item) => (
              <>
                <div
                  className="insurer-logo"
                  style={{ justifyContent: 'center' }}
                >
                  <img
                    src={logoUrl}
                    alt={getUsableLogo(item, false).alt}
                    height="auto"
                    width={120}
                  />
                </div>
                <div className="offer-detail">
                  <div className="offer-name" dangerouslySetInnerHTML={{ __html: item.productDisplayName.split('(')[0] }} />
                  <div className="offer-price">
                    {' '}
                    {(newPremium && selectedInstallment !== 1) ? priceFormatter.format(newPremium).replace('₺', '') : priceFormatter.format(item.premium).replace('₺', '')}
                    {' '}
                    TL
                  </div>
                </div>
                {item.packageName !== undefined && <div className="offer-subtitle">{item.packageName}</div>}
                {cartData.length > 1 && <hr />}
                {' '}
              </>
            ))}
            <div className="policy-info">
              <FormControlLabel
                style={{ margin: '0', display: 'flex', alignItems: 'flex-start' }}
                control={<Checkbox onChange={() => setPolicyRead(!policyRead)} />}
                label={(
                  <p className="disclaimer" style={{ marginTop: '8px' }}>
                    <a onClick={(e) => getDisclosurePdf(teklifTID, e)} style={{ color: '#0055A5' }}>
                      Bilgilendirme metnini
                    </a>
                    {' '}
                    okudum ve onaylıyorum.
                  </p>
                  )}
              />
            </div>
            <div className="buy-button">
              <Button
                variant="contained"
                className="button"
                onClick={handleSubmit(purchase)}
                style={{ textTransform: 'none' }}
                disabled={!policyRead || !isValid || (cardOwnerIdentity === false)}
              >
                Satın Al
              </Button>
            </div>
          </div>
          )}
        </div>
        {isMobile && (
        <div
          className="header-cart-summary"
          style={{
            height: '96px',
          }}
        >
            {cartData.map((item) => (
              <>
                <div className="cart-item-container tss-cart-item-container">
                  <div className="row">
                    <div className="insurer-logo">
                      <img src={logoUrl} alt={getUsableLogo(item, false).alt} width={76} height="auto"/>
                    </div>
                    <div className="offer-price">
                      {' '}
                      {(newPremium && selectedInstallment !== 1) ? priceFormatter.format(newPremium).replace('₺', '') : priceFormatter.format(item.premium).replace('₺', '')}
                      {' '}
                      TL
                    </div>
                  </div>
                  <p className="subtitle">{item.productDisplayName}</p>
                </div>
                {cartData.length > 1 && <hr />}
              </>
            ))}
        </div>
        )}
        {isMobile
          && (
          <Button
            variant="contained"
            onClick={handleSubmit(purchase)}
            disabled={!policyRead || !isValid || (cardOwnerIdentity === false)}
            style={{
              width: !isMobile ? '254px' : '100%',
              height: '55px',
              boxSizing: 'border-box',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
              borderRadius: '5px',
              textTransform: 'none',
              display: 'block',
              position: 'fixed',
              bottom: 0,
              left: 0,
              margin: '0 0 0 -20%',
            }}
          >
            Satın Al
          </Button>
          )}
        <CenteredModal className="fail-at-insurer-modal" isOpen={hasFailAtIssue} setOpen={setHasFailAtIssue}>
          <HasFailAtIssue setHasFailAtInsurer={setHasFailAtIssue} />
        </CenteredModal>
        {priceDiffModal && (
            <CenteredModal
              className="price-diff-modal-container"
              isOpen={priceDiffModal}
              setOpen={setPriceDiffModal}
              hasClose={false}
            >
              <PriceDiffModal
                priceDiffData={priceDiffData}
                premium={(newPremium && selectedInstallment !== 1) ? parseFloat(newPremium).toFixed(2) : parseFloat(cartData[0].premium).toFixed(2)}
                goToSummary={goToSummary}
                buyAction={priceDiffBuyAction}
              />
            </CenteredModal>
          )}
      </div>
    </>
  );
};

export default withRouter(Checkout);
