import React from 'react';
import { Grid, Button, Paper } from '@mui/material';

import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import OTPSVG from '../../../../shared/assets/img/svg/otp.svg';
import useWindowSize from '../../../../shared/_hooks/windowResizeHook';
import commonService from '../../../../motor/_services/common.service';

export const OTPConfirmation = (props) => {
  const size = useWindowSize();
  const isMobile = size.width <= 880;
  const URL_PARAMS = new URLSearchParams(document.location.search);
  const uid = URL_PARAMS.get('jt');
  const { customer } = useSelector((state) => state.info);
  const { quotation } = useSelector((state) => state.shell);

  const sendSms = () => {
    commonService.sendSMS(
      {
        OTP: null,
        OTPType: 0,
        PhoneNumber: customer.phoneNumber,
        TCKN: quotation.customer.tcKimlikVergiNo,
        UserUId: uid,
      },
      (resp) => {
        console.table(resp);
      },
    );
  };
  const onSubmit = () => {
    props.setStepName('otp');
    sendSms();
  };

  return (
    <>
      <Grid xs={12} sm={12} lg={12} xl={12} item>
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            margin: '10px 0 8px 0',
            justifyContent: 'center',
          }}
        >
          <img src={OTPSVG} alt="" width={58} height={64} className="svg-icon" />
        </div>
        <h3 style={{ margin: '24px 0' }}>Mobil Onay</h3>
        <div>
          <p className="otp" style={{ padding: '0 20px' }}>
            Teklifleri görebilmeniz için
            0
            {customer.phoneNumber}
            {' '}
            no’lu telefona mobil onay kodu gönderilecektir. Lütfen telefon numarasının müşterinize ait olduğundan emin olunuz.
          </p>
        </div>
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Button
            variant="contained"
            style={{
              width: !isMobile ? '254px' : '100%',
              height: '45px',
              margin: '8px 7px',
              boxSizing: 'border-box',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
              borderRadius: '5px',
              textTransform: 'none',
            }}
            onClick={onSubmit}
            type="submit"
          >
            Şifre gönder
          </Button>
          <Button onClick={() => props.setStepName('newPhone')} style={{ textTransform: 'none' }}>
            Farklı bir telefon numarası gir
          </Button>
        </div>
      </Grid>
    </>
  );
};

export default withRouter(OTPConfirmation);
