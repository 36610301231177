import React, { useState } from 'react';
import {
  Button, FormControlLabel, Radio,
} from '@mui/material';

import '../../../../../shared/assets/styles/components/crosssell-modal.scss';
import 'react-credit-cards/es/styles-compiled.css';
import useWindowSize from '../../../../../shared/_hooks/windowResizeHook';
import { getUsableLogo, priceFormatter } from '../../../../../shared/_helpers/helperFunctions';

const CrossSellButtons = (props) => {
  const {
    productId,
    hasCrossSellAdded,
    removeCrossSellFromBasket,
    setIsCrossSellModalOpen,
    setHasCrossSellAdded,
    addCrossSellToBasket,
    secondaryIMM,
  } = props;
  return (
    <div className={`cross-quotations-buttons ${!secondaryIMM ? 'cross-quotations-buttons-sm' : ''}`}>
      <div>
        {hasCrossSellAdded && (
        <Button
          variant="outlined"
          onClick={() => {
            removeCrossSellFromBasket();
            setIsCrossSellModalOpen(false);
            setHasCrossSellAdded(false);
          }}
        >
          İMM Sigortasını Sepetimden Çıkar
        </Button>
        )}
      </div>
      <div>
        {!hasCrossSellAdded && (
        <Button
          variant="contained"
          className="button"
          style={{ textTransform: 'none' }}
          onClick={() => {
            props.setSelectedCrossInstallment(1);
            addCrossSellToBasket(productId);
            setIsCrossSellModalOpen(false);
          }}
        >
          Sepete Ekle
        </Button>
        )}
        {hasCrossSellAdded && hasCrossSellAdded !== productId && (
        <Button
          variant="contained"
          className="button"
          style={{ textTransform: 'none' }}
          onClick={() => {
            props.setSelectedCrossInstallment(1);
            addCrossSellToBasket(productId, hasCrossSellAdded);
            setIsCrossSellModalOpen(false);
          }}
        >
          Sepeti Güncelle
        </Button>
        )}
      </div>
    </div>
  );
};

export const CrossSellModal = (props) => {
  const size = useWindowSize();
  const isMobile = size.width <= 880;
  const {
    crossQuotations,
    getDisclosurePdf,
    hasCrossSellAdded,
  } = props;

  const defaultIMM = crossQuotations.length === 1
    ? crossQuotations[0] : crossQuotations.reduce((prev, curr) => (prev.grossPremium < curr.grossPremium ? prev : curr));

  const secondaryIMM = crossQuotations.length > 1
    ? crossQuotations.reduce((prev, curr) => (prev.grossPremium > curr.grossPremium ? prev : curr)) : null;

  const [selectedQuotation, setSelectedQuotation] = useState(hasCrossSellAdded || defaultIMM.productId);

  const productDescriptions = {
    Limitsiz:
  <p className="assistive">
    Olası bir kazada trafik sigortasının karşılayamadığı tazminat taleplerini
    {' '}
    <strong>limitsiz</strong>
    {' '}
    olarak karşılar. Maddi, manevi tazminat talepleri limitsiz teminat kapsamındadır.
  </p>,
    '3MilyonTL':
  <p className="assistive">
    Olası bir kazada trafik sigortasının karşılayamadığı maddi tazminat taleplerini
    {' '}
    <strong>3 Milyon TL</strong>
    ’ye kadar karşılar. Ek olarak 3 Milyon TL manevi tazminat hakkı da bulunmaktadır.
  </p>,
    '10MilyonTL':
  <p className="assistive">
    Olası bir kazada trafik sigortasının karşılayamadığı maddi tazminat taleplerini
    {' '}
    <strong>10 Milyon TL</strong>
    ’ye kadar karşılar. Ek olarak
    10 Milyon Teklif
    manevi tazminat hakkı da bulunmaktadır.
  </p>,
  };

  return (
    <>
      <div className="crosss-sell-container" style={{ marginTop: secondaryIMM && !isMobile ? '66px' : 'unset' }}>
        <span className="crosss-sell-type">
          <div
            className="cross-quotation-popup"
            onClick={() => {
              setSelectedQuotation(defaultIMM.productId);
            }}
          >
            <div className="cross-quotation-summary">
              <FormControlLabel control={<Radio checked={selectedQuotation === defaultIMM.productId || !selectedQuotation} />} label={defaultIMM.planName} />
              <div className="cross-popup-summary">
                <div className="insurer-logo">
                  <img
                    src={getUsableLogo(defaultIMM, false).src}
                    alt={getUsableLogo(defaultIMM, false).alt}
                    width={76}
                  />
                </div>
                <div className="offer-price">
                  {' '}
                  {priceFormatter.format(defaultIMM.grossPremium).replace('₺', '')}
                  {' '}
                  TL
                </div>
              </div>
            </div>
            <div className="description">
              {productDescriptions[defaultIMM.subtitle.replace(/\s/g, '')]}
            </div>
            <a
              className="teklif-link"
              onClick={(e) => getDisclosurePdf(defaultIMM.teklifTId, e)}
            >
              {' '}
              Teklifi İncele
            </a>
          </div>
        </span>
        {secondaryIMM && (
        <span className="crosss-sell-type secondary-quotation">
          <span className="info-block">
            <p>
              Sadece
              {' '}
							{priceFormatter.format(secondaryIMM.grossPremium - defaultIMM.grossPremium).replace('₺', '')}
              {' '}
              TL fark ile limitsiz paketi satın alabilirsiniz
            </p>
          </span>
          <div
            className="cross-quotation-popup"
            onClick={() => {
              setSelectedQuotation(secondaryIMM.productId);
            }}
          >
            <div className="cross-quotation-summary">
              <FormControlLabel control={<Radio checked={selectedQuotation === secondaryIMM.productId} />} label={secondaryIMM.planName} />
              <div className="cross-popup-summary">
                <div className="insurer-logo">
                  <img
                    src={getUsableLogo(secondaryIMM, false).src}
                    alt={getUsableLogo(secondaryIMM, false).alt}
                    width={76}
                  />
                </div>
                <div className="offer-price">
                  {' '}
                  {priceFormatter.format(secondaryIMM.grossPremium).replace('₺', '')}
                  {' '}
                  TL
                </div>
              </div>
            </div>
            <div className="description">
              {productDescriptions[secondaryIMM.subtitle.replace(/\s/g, '')]}
            </div>
            <a
              className="teklif-link"
              onClick={(e) => getDisclosurePdf(secondaryIMM.teklifTId, e)}
            >
              {' '}
              Teklifi İncele
            </a>
          </div>
        </span>
        )}
      </div>
      <CrossSellButtons
        secondaryIMM={secondaryIMM}
        productId={selectedQuotation}
        {...props}
      />
    </>
  );
};

export default CrossSellModal;
