import React from 'react';
import '../../../../shared/assets/styles/send-mail-modal.scss';
import '../../../../shared/assets/styles/has-fail-at-insurer-modal.scss';
import { useSelector } from 'react-redux';
import { displayPhoneNumber } from '../../../../shared/_helpers/helperFunctions';

export const HasFailAtIssue = (props) => {
  const { additionalInfo } = useSelector((state) => state.theme);
  return (
    <>
      <div className="fail-at-insurer-container">
        <div className="fail-at-insurer">
          {/* <img src={onlineSupport} /> */}
          <h3>
            Poliçe işlemi sırasında beklenmedik bir durum oluştu. Kartınızdan tutar çekilmiş olabilir, lütfen kontrol ediniz.
            <br />
            Tutar çekilmişse poliçeniz başarıyla oluşmuştur.
            {' '}
            <a
              href={additionalInfo.phone}
              style={{ textDecoration: 'none', color: 'rgb(0, 74, 153)' }}
            >
              {displayPhoneNumber(additionalInfo.phone)}
            </a>
            {' '}
            üzerinden poliçe pdf'inizi talep edebilirsiniz.
          </h3>

        </div>
      </div>
    </>
  );
};

export default HasFailAtIssue;
