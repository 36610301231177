import { convertBirthDatetoDashed } from '../../shared/_helpers/convertBirthDate';
import store from '../../shared/store/store';
import quotationService from '../_services/quotation.service';
import { realationsEnum } from '../data/relations';

export const stepsEnum = {
  None: 0,
  TSSPersonalInformation: 1,
};

const paramsToRemoved = [
  'relationObject',
  'occupation',
  'approvedPolicy',
  'isFull',
  'movingForward',
  'self',
  'eBulletin',
];

const mapInsureds = (insureds, jt) => {
  const URL_PARAMS = new URLSearchParams(document.location.search);
  const userGuid = URL_PARAMS.get('jt') || jt || null;
  const consumer = JSON.parse(store.getState().consumer.consumer);

  const tempInsureds = insureds.map((insurer) => {
    const tempInsurer = {
      ...insurer,
    };
    if (tempInsurer.relation === realationsEnum.Kendisi) {
      tempInsurer.userGuid = userGuid;
			tempInsurer.Sbn = consumer.partnerSbn || 'KCDUTC';
      if (consumer.dealerCode) {
        tempInsurer.ExtRefId = consumer.dealerCode;
      }
    }
    tempInsurer.birthDate = convertBirthDatetoDashed(tempInsurer.birthDate);
    paramsToRemoved.forEach((param) => {
      delete tempInsurer[param];
    });
    return tempInsurer;
  });

  return tempInsureds;
};

const getQuotes = (dispatch, props, callBack) => {
  const URL_PARAMS = new URLSearchParams(document.location.search);
  const qGuid = URL_PARAMS.get('qGuid') || null;
  const {
    step = stepsEnum.None,
    insureds: outherInsureds = null,
    jt = null,
    QueueName,
  } = props;

  const { insureds } = store.getState().info;
  const { consumer } = store.getState().consumer;

  const tempInsureds = outherInsureds || insureds;
  const mappedInsureds = mapInsureds(tempInsureds, jt);

  const Sbn = JSON.parse(consumer).partnerSbn;
  const quotationObject = {
    step,
    ProductGroupId: 5,
    ApplicationId: 2,
    CreateQuotation: step !== stepsEnum.None,
    AsyncCall: step === stepsEnum.None,
    Insureds: [
      ...mappedInsureds,
    ],
    CampSrc: '',
    FunctionalBranchId: 3,
    Sbn,
  };
  if (qGuid) {
    quotationObject.TeklifUId = qGuid;
  }

  if (QueueName) {
    quotationObject.QueueName = QueueName;
  }
  quotationService.getQuotes(quotationObject, (response, hasError) => {
		 if (callBack) {
      callBack(response, hasError);
    }
  });
};

export default getQuotes;
