import {
  getDifferenceInDays, formatStringToDate, formatDateToString, getDateXDaysAgo,
} from '../dateFormat';

export const checkRenevalPeriod = (policyStringDate, days = 30) => {
  const today = new Date();
  const diffInDays = getDifferenceInDays(today, formatStringToDate(policyStringDate));
  return (diffInDays < 0 || Math.abs(diffInDays) < days);
};

export const getRenevalDateString = (policyStringDate, days = 30) => {
    return formatDateToString(getDateXDaysAgo(days, formatStringToDate(policyStringDate)));
}