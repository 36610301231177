import React, { useState, useEffect } from 'react';
import { Grid, Button, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import useWindowSize from '../../../../shared/_hooks/windowResizeHook';
import CalendarSVG from '../../../../shared/assets/img/svg/calendar.svg';
import EventBus from '../../../../shared/_helpers/EventBus';
import useUserState from '../../../../shared/_hooks/userStateHook';
import { UserStates } from '../../../data/userStates';
import { removeCustomEventsFromDataLayer } from '../../../../shared/_helpers/helperFunctions';
import { setSearchString } from '../../../../shared/_helpers/redirect';
import vehicleService from '../../../_services/vehicle.services';
import headerActions from '../../../actions/headerAction';

export const ContinueProcess = (props) => {
  const size = useWindowSize();
  const { setUserState } = useUserState();
  const isMobile = size.width <= 880;
  const URL_PARAMS = new URLSearchParams(document.location.search);
  const uid = URL_PARAMS.get('jt');
  const extrefhash = URL_PARAMS.get('extrefhash');
  const { quotes } = props;
  const { isRenewal, isAgency } = useSelector((state) => state.shell);

  const style = {
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, -40%)',
    width: isMobile ? 343 : 1140,
    bgcolor: 'background.paper',
    padding: !isMobile ? 30 : 15,
    textAlign: 'center',
    background: '#FFFFFF',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
  };

  useEffect(() => {
    removeCustomEventsFromDataLayer(window.dataLayer);
    window.dataLayer.push({
      event: 'continue_process',
      funnel_variables: {
        jt: uid,
      },
    });
  }, []);

  return (
    <>
      <Paper style={style} square>
        <Grid xs={12} sm={12} lg={12} xl={12} item>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingLeft: !isMobile ? '42px' : '16px',
              marginTop: !isMobile ? '' : '10px',
            }}
          >
            <h1 style={{ textTransform: 'capitalize' }}>
              Merhaba
              {' '}
              {quotes.userName}
            </h1>
            {!isMobile && (
              <Button
                variant="contained"
                style={{
                  width: !isMobile ? '254px' : '100%',
                  height: '45px',
                  margin: '24px 0px',
                  boxSizing: 'border-box',
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
                  borderRadius: '5px',
                  textTransform: 'none',
                }}
                onClick={async () => {
                  const userSateData = {
                    UserUID: uid,
                    UserStateDefinition: UserStates.Continue_Process_New_Offer_Button_Clicked,
                  };
                  await setUserState(userSateData);
                  EventBus.emit('closeContinueProcessModal');
                }}
              >
                <p
                  style={{
                    fontSize: '16px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <AddIcon />
                  Yeni Teklif Al
                </p>
              </Button>
            )}
          </div>
          {!isAgency && (
          <p
            className="text"
            style={{
              padding: !isMobile ? '0 42px' : '0 16px',
              textAlign: 'left',
            }}
          >
            Aşağıdaki araç(lar) için daha önce teklif aldığınızı görüyoruz. Bu araç(lar) için teklif almak isterseniz
            araç bilgilerinin yanındaki butona tıklamanız yeterli!
          </p>
          )}
          {isAgency && (
          <p
            className="text"
            style={{
              padding: !isMobile ? '0 42px' : '0 16px',
              textAlign: 'left',
            }}
          >
            Sitemizden daha önce aşağıdaki araçlar için teklif aldığınızı görüyoruz. Aynı araç için tekrar teklif almak için "Bu araçla devam et" butonuna tıklayabilirsiniz.
            <br />
            Farklı bir araç için teklif almak istiyorsanız lütfen sayfa başındaki "Yeni teklif al!" butonuna tıklayınız.
          </p>
          )}
          {isMobile && (
            <div style={{ padding: '0 16px' }}>
              <Button
                variant="contained"
                style={{
                  width: !isMobile ? '254px' : '100%',
                  height: '45px',
                  boxSizing: 'border-box',
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
                  borderRadius: '5px',
                  textTransform: 'none',
                }}
                onClick={async () => {
                  const userSateData = {
                    UserUID: uid,
                    UserStateDefinition: UserStates.Continue_Process_New_Offer_Button_Clicked,
                  };
                  await setUserState(userSateData);
                  EventBus.emit('closeContinueProcessModal');
                }}
              >
                <p
                  style={{
                    fontSize: '16px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <AddIcon />
                  Yeni Teklif Al
                </p>
              </Button>
            </div>
          )}

          <div
            className="continue-processes-container"
            style={{
              maxHeight: '60vh',
              overflowY: 'scroll',
            }}
          >
            {quotes.customerQuotes.map((quote) => {
              const [year, month, day] = quote.btCreateDate.split('T')[0].split('-');
              const [hour, minute, seconds] = quote.btCreateDate.split('T')[1].split(':');
              const hours = [hour, minute].join(':');
              const date = [day, month, year].join('.');
              return (
                <div style={{ padding: '0 16px' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: !isMobile ? 'row' : 'column',
                      justifyContent: 'space-between',
                      border: '1px solid #D9DDE1',
                      borderRadius: '5px',
                      margin: !isMobile ? '32px 189px 24px 189px' : '16px 0 16px 0',
                      textAlign: 'left',
                      padding: !isMobile ? '0 24px' : '0 12px',
                    }}
                  >
                    <div>
                      <p
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '13px',
                          margin: '17px 0 9px 0',
                        }}
                      >
                        <img src={CalendarSVG} alt="calendar" height={20} width={20} className="svg-icon" />
                        {' '}
                        {date}
                        {'    '}
                        {hours}
                      </p>
                      <p style={{ fontSize: '13px', fontWeight: '600', margin: '0 0 4px 0' }}>{quote.plate}</p>
                      <p style={{ fontSize: '12px', fontWeight: '400', margin: '0 0 16px 0' }}>{quote.vehicle}</p>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Button
                        variant="outlined"
                        style={{
                          width: !isMobile ? '254px' : '100%',
                          height: '45px',
                          margin: !isMobile ? '8px 7px' : ' 0px 0 16px 0px',
                          boxSizing: 'border-box',
                          borderRadius: '5px',
                          textTransform: 'none',
                        }}
                        onClick={async () => {
                          window.dataLayer.push({ funnel_variables: null });
                          let cascoValue = null;
                          await vehicleService.getVehicleCascoValue(
                            quote.vehicleBrandCode,
                            quote.vehicleModelCode,
                            quote.vehicleModelYear,
                            (res) => {
                              cascoValue = res.cascoValue;
                            },
                          );
                          window.dataLayer.push({
                            event: 'previous_car_selected',
                            funnel_variables: {
                              process_type: isRenewal ? 'Renewal' : 'New Sales',
                              car_model: quote.vehicleModel,
                              car_year: quote.vehicleModelYear,
                              car_brand: quote.vehicleBrand,
                              car_type: quote.vehicleType,
                              casco_value: parseFloat(cascoValue),
                              car_damage_level: quote.appliedLevel,
                              jt: uid,
                            },
                          });
                          const userSateData = {
                            UserUID: uid,
                            UserStateDefinition: UserStates.Continue_Process_Continue_With_This_Car_Button_Clicked,
                          };
                          await setUserState(userSateData);
                          const url = '/quotation/summary';
                          const searchString = setSearchString(`?jt=${quote.userUId}&qGuid=${quote.teklifUid}`);
                          props.history.push({
                            pathname: url,
                            search: searchString,
                            state: { qGuid: quote.teklifUid },
                          });
                          dispatch(headerActions.setUseHeader(true));
                        }}
                      >
                        <p
                          style={{
                            fontSize: '16px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          Bu Araçla Devam Et
                        </p>
                      </Button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </Grid>
      </Paper>
    </>
  );
};

export default withRouter(ContinueProcess);
