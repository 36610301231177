import { ccCrossSellTypes } from '../types/ccCrossSell.type';

function setCCCrossSellStep(step) {
  return {
    type: ccCrossSellTypes.SET_CC_CROSS_SELL_STEP,
    payload: {
      step,
    },
  };
}
const ccCrossSellStepActions = {
  setCCCrossSellStep,
};

export default ccCrossSellStepActions;
