import React, { useEffect, useState } from "react";
import { Grid, Button, FormControl, Modal } from "@mui/material";
import { useForm } from "react-hook-form";

import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { requiredArea, minLength } from '../../../../shared/_helpers/validations';
import SafetySVG from '../../../../shared/assets/img/svg/safety.svg';
import InfoSVG from '../../../../shared/assets/img/svg/info.svg';
import useWindowSize from '../../../../shared/_hooks/windowResizeHook';
import { KLInput } from '../../components/form/KLInput';
import informationActions from '../../../actions/informationAction';
import vehicleService from '../../../_services/vehicle.services';
import customerService from '../../../_services/customer.service';
import EventBus from '../../../../shared/_helpers/EventBus';
import commonService from '../../../_services/common.service';
import OTP from './OptionalSteps/OTP';
import { showRequestError } from '../../../../shared/_helpers/errorToastr';
import toastrActions from '../../../actions/toastrAction';
import { checkAPSAndGoSummary } from '../../../../shared/_helpers/redirect';
import loadingKlActions from '../../../actions/loadingActions';
import { UserStates } from '../../../data/userStates';
import useUserState from '../../../../shared/_hooks/userStateHook';
import shellActions from '../../../actions/shellAction';
import stepActions from '../../../actions/stepAction';
import { removeCustomEventsFromDataLayer } from "../../../../shared/_helpers/helperFunctions";
import checkBreakout from "../../../_helpers/checkBreakout";

export const LicenseInfo = (props) => {
  const size = useWindowSize();
  const isMobile = size.width <= 880;
  const [isOTPRequired, setIsOTPRequired] = useState(false);
  const [quoteId, setQuoteId] = useState("");

  const { customer, vehicle, isKPSActive } = useSelector((state) => state.info);
  const { quotation, isAgency, isMerchant, isCallCenter } = useSelector((state) => state.shell);
  const { setUserState } = useUserState();
  const URL_PARAMS = new URLSearchParams(document.location.search);
  const uid = URL_PARAMS.get("jt");
  const { additionalInfo } = useSelector((state) => state.theme);

  const dispatch = useDispatch();

  const {
    handleSubmit,
    formState: { errors, isValid, touchedFields },
    control,
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    if (isOTPRequired) {
      const userSateData = {
        UserUID: uid,
        UserStateDefinition: UserStates.OTP_SMS_Sent,
      };
      setUserState(userSateData);
    }
  }, [isOTPRequired]);

	useEffect(() => {
    if (!Object.values(dataLayer).map((element) => element.event).includes('licence_page')) {
      removeCustomEventsFromDataLayer(window.dataLayer);
      window.dataLayer.push({
        event: 'licence_page',
        'funnel_variables': {
          jt: uid
        }
      });
    }
		dispatch(loadingKlActions.hideKlLoading());
		dispatch(stepActions.setStep(2));
		const userSateData = {
      UserUID: uid,
      UserStateDefinition: UserStates.Potansiyel_Musteri_Ruhsat_Bilgisi_Yazmadan_Ayrılan,
    };
    setUserState(userSateData);
  }, []);

  const getOffers = (data) => {
    dispatch(toastrActions.hideToastr());
    dispatch(loadingKlActions.showKlLoadingEmpty());

    const vehicleObject = {
      VehicleBuyingStatus: 0,
      VehicleProcessStatus: 1,
      PlakaBirlesik: quotation.vehicle.plakaBirlesik,
      TescilSeriBirlesik: `${data.seri.toUpperCase()}-${data.no}`,
      IsAgency: 0,
      ExistsAsbisNumber: 0,
      UserGuid: uid,
      kimlikNo: quotation.customer.tcKimlikVergiNo,
      FunctionalBranchId: 1,
    };

			dispatch(loadingKlActions.showKlLoadingEmpty());
      vehicleService.vehicle(vehicleObject, (vehicle) => {
        if (vehicle.code !== '100') {
          if (!Object.values(dataLayer).map((element) => element.event).includes('renewal_continue')) {
            removeCustomEventsFromDataLayer(window.dataLayer);
						if (vehicle.Result.ModelKod === '999') {
window.dataLayer.push({
								event: 'renewal_continue',
              'funnel_variables': {
                renewal_type: vehicle.Result.ModelKod === '999' ? 'Second Hand' : 'Renewal',
                jt: uid
              }
            });
						}else{
							window.dataLayer.push({
								event: 'renewal_continue',
								'funnel_variables': {
									car_model: vehicle.Result.VehicleModelText,
									car_year: vehicle.Result.ModelYear,
									car_brand: vehicle.Result.MarkaAck,
									renewal_type: vehicle.Result.ModelKod === '999' ? 'Second Hand' : 'Renewal',
									car_type:vehicle.Result.VehicleTypeText,
									casco_value:parseFloat(vehicle.Result.cascoValue),
									car_damage_level: vehicle.Result.trafficAppliedLevel,
                  jt: uid
								}
							});
						}
          }
          dispatch(informationActions.setQuoteId(vehicle.qtGuid));
          setQuoteId(vehicle.qtGuid);
          dispatch(
            informationActions.setVehicleDetail({
              MarkaAck: vehicle.Result.MarkaAck,
              MarkaKod: vehicle.Result.MarkaKod,
              ModelKod: vehicle.Result.ModelKod,
              ModelText: vehicle.Result.VehicleModelText,
              ModelYear: vehicle.Result.ModelYear,
              VehicleType: vehicle.Result.VehicleType,
              ProcessStep: vehicle.Result.ProcessStep,
              ActiveStep: vehicle.activeStep || vehicle.Result.ActiveStep,
            }),
          );
					if(vehicle.Result.ModelKod === '999'){
						vehicle.Result.ModelKod === null
					}
          if (vehicle.Result.ModelKod === '999' || checkBreakout(dispatch, vehicle.Result)) {
            // EventBus.emit('999ModelKod');
						dispatch(shellActions.setIsBack(true));
						props.history.push({
							pathname: "/quotation/CarInfo",
							search: props.history.location.search,
						});
          } else {
            commonService.checkOTPUser(
              {
                phoneNumber: customer.phoneNumber,
                tcIdentityNumber: quotation.customer.tcKimlikVergiNo,
								isPolicyFlow: false,
              },
              (resp) => {
								// if birthdate required this data doesnt set
								var url = "";
								if (vehicle.Result.ResultPageUrl) {
									url = vehicle.Result.ResultPageUrl;
									dispatch(stepActions.setRedirectUrl(url));
								}

                if (!resp.otpUserResult && !isMerchant && !isCallCenter) {
                  setIsOTPRequired(true);
                  commonService.isInBlackList(
                    {
                      PhoneNumber: customer.phoneNumber,
                      TCKN: quotation.customer.tcKimlikVergiNo,
                      Email: customer.eMail,
                      FirstPhoneNumber: customer.phoneNumber,
                    },
                    (blresp) => {
                      if (!blresp) {
												if(!isAgency){
                        commonService.sendSMS(
                          {
                            OTP: null,
                            OTPType: 0,
                            PhoneNumber: customer.phoneNumber,
                            TCKN: quotation.customer.tcKimlikVergiNo,
                            UserUId: uid,
                          },
                          (sms) => { },
                        );
												}
                      }
                    },
                  );
                } else if (!quotation.customer.referenceCustomerUID && quotation.customer.tcKimlikVergiNo.length > 10) {
                  // EventBus.emit('birthDateInfo');
									dispatch(informationActions.setBirthDateInfo(!isKPSActive));
									dispatch(shellActions.setIsBack(true));
									props.history.push({
										pathname: "/quotation/BirthdateInfo",
										search: props.history.location.search,
									});
                } else if ((vehicle.ActiveStep === 'OKRespOccupationQuery' || vehicle.Result.activeStep === 'OKRespOccupationQuery') && quotation.customer.tcKimlikVergiNo.length > 10) {
                  dispatch(informationActions.setBirthDateInfo(true));
                  dispatch(informationActions.setOccupationInfo(true));
                  // EventBus.emit('birthDateInfo');
									dispatch(shellActions.setIsBack(true));
									props.history.push({
										pathname: "/quotation/BirthdateInfo",
										search: props.history.location.search,
									});
                } else {
									dispatch(loadingKlActions.showKlLoadingEmpty());
                  customerService.verify(
                    {
                      QuoteGUID: vehicle.qtGuid,
                      ReferenceCustomerUID: uid,
                      UserGUID: uid,
											BirthDate: customer.birthDate || null,
                    },
                    (vresp, code) => {
											if (quotation.customer.tcKimlikVergiNo.length === 10) {
												code = '102';
											}
                      checkAPSAndGoSummary(uid, vehicle.qtGuid, code, props,null,null,url);
                    },
                  );
                }
              },
            );
						dispatch(loadingKlActions.hideKlLoading());
          }
        } else {
          showRequestError(vehicle.msg, dispatch, additionalInfo.phone);
        }

      }).catch((err) => {
        if (err.response) {
          showRequestError(err.response.data.Result.Message.toString(), dispatch, additionalInfo.phone);
        }
      }).finally(() => {
        dispatch(loadingKlActions.hideKlLoading());
      });

  };
  const onSubmit = (data) => {
    getOffers(data);
    dispatch(informationActions.setVehicleInfo(data));
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12} sm={12} lg={6} xl={6} item>
          <div>
            <h1>Ruhsat Bilgileri</h1>
          </div>
          <div style={{ alignItems: "center", display: "flex", margin: "10px 0 8px 0" }}>
            <img src={SafetySVG} alt="" width={20} className="svg-icon" />
            <p className="text">Kaydettiğiniz bilgiler Koalay ile her zaman güvende.</p>
          </div>
          <form>
            <div style={{ display: "flex" }}>
              <KLInput
                name="seri"
                sx={{
                  width: 110,
                  "& .MuiOutlinedInput-root": {
                    width: "94px",
                    "& .MuiOutlinedInput-input": {
                      textTransform: "uppercase",
                    },
                  },
                }}
                mask="yy"
                id="license-serial"
                control={control}
                label="Ruhsat Seri"
                error={errors}
                rules={minLength(2)}
                placeholder="AA"
                InputLabelProps={{ shrink: true }}
                defaultValue={vehicle?.seri}
                touchedFields={touchedFields}
              />
              <KLInput
                name="no"
                id="license-serial"
                mask="999999"
                sx={{
                  width: 144,
                  "& .MuiOutlinedInput-root": {
                    width: isMobile ? "201px" : "144px",
                  },
                }}
                label="Ruhsat No"
                control={control}
                error={errors}
                rules={minLength(6)}
                placeholder="111111"
                InputLabelProps={{ shrink: true }}
                defaultValue={vehicle?.no}
                touchedFields={touchedFields}
              />
            </div>
          </form>
          <div>
            {!isMobile ? (
              <Button
                variant="contained"
                disabled={!isValid}
                style={{
                  width: !isMobile ? "254px" : "100%",
                  height: "45px",
                  margin: "24px 7px",
                  boxSizing: "border-box",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.4)",
                  borderRadius: "5px",
                  textTransform: "none",
                }}
                onClick={handleSubmit(onSubmit)}
              >
                Devam Et
              </Button>
            ) : (
              ""
            )}
          </div>
        </Grid>
        <Grid xs={12} sm={12} lg={6} xl={6} item>
          <div style={{ marginTop: isMobile ? "" : "55px", justifyContent: "center" }}>
            <div
              className="step-description-box"
              style={{ background: "#F0FFDC", padding: "15px", borderRadius: "5px" }}
            >
              <div
                className="step-description-item"
                style={{ display: "flex", alignItems: "flex-start", marginBottom: "12px" }}
              >
                <img src={InfoSVG} alt="" className="svg-icon" />
                <p className="disclaimer" style={{ fontWeight: 700 }}>
                  Ruhsatınız yanınızda değil mi?
                </p>
              </div>
              <div className="step-description-item" style={{ display: "flex", alignItems: "flex-start" }}>
                <p className="disclaimer" style={{ marginLeft: "25px" }}>
                  <a href="https://www.turkiye.gov.tr/turkiye-noterler-birligi-adima-tescilli-arac-sorgulama?hizmet=Ekrani" target="_blank">
                    Buraya tıklayarak
                  </a>{" "}
                  e-Devlet üzerinden ruhsat bilgilerine erişebilirsiniz.
                </p>
              </div>
              <div className="step-description-item" style={{ display: "flex", alignItems: "flex-start" }}>
                <p className="disclaimer" style={{ marginLeft: "25px" }}>
                  e-Devlet şifrenizi hatırlamıyorsanız{" "}
                  <a href="https://giris.turkiye.gov.tr/Giris/Banka-Giris" target="_blank">
                    {" "}
                    buraya tıklayarak
                  </a>{" "}
                  internet bankacılığı ile e-Devlet’e giriş yapabilirsiniz.
                </p>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      {isMobile ? (
        <Button
          variant="contained"
          disabled={!isValid}
          style={{
            width: !isMobile ? "254px" : "100%",
            height: "55px",
            boxSizing: "border-box",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.4)",
            borderRadius: "5px",
            textTransform: "none",
            display: "block",
            position: "fixed",
            bottom: 0,
            left: 0,
            margin: "0 0 0 -20%",
          }}
          onClick={handleSubmit(onSubmit)}
        >
          Devam Et
        </Button>
      ) : (
        ""
      )}
      <Modal
        open={isOTPRequired}
        onClose={() => setIsOTPRequired(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <OTP quoteId={quoteId} />
      </Modal>
    </>
  );
};

export default withRouter(LicenseInfo);

