import axiosInstance from '../../shared/_helpers/axiosHelper';
import { API } from '../../shared/_helpers/env';

async function getPaymentPlan(installment, callback) {
  await axiosInstance.post(`${API}/api/payment/payment-plans`, { ...installment }).then((response) => {
    try {
      callback(response.data.Result);
    } catch (error) {
      console.log(error);
    }
  });
}
async function getSessionToken(data, callback) {
  await axiosInstance.post(`${API}/api/payment/session-token`, { ...data }).then((response) => {
    try {
      callback(response);
    } catch (error) {
      callback('error');
    }
  });
}

async function getWalletToken(data, callback) {
  await axiosInstance.post(`${API}/api/payment/wallet-token`, { ...data }).then((response) => {
    try {
      callback(response);
    } catch (error) {
      callback('error');
    }
  });
}

const paymentService = {
  getPaymentPlan,
  getSessionToken,
  getWalletToken,
};
export default paymentService;
