import axios from 'axios';
import axiosInstance from '../../shared/_helpers/axiosHelper';
import { API } from '../../shared/_helpers/env';
import { interceptors } from '../../shared/_helpers/interceptors';

async function customer(vehicleModel, callback) {
  await axiosInstance.post(`${API}/api/customer/customer`, vehicleModel).then((response) => {
    try {
      callback(response.data.Result, response.data.msg === 'PhoneIsBulking',
        response.data.partnershipRedirectionUrl);
    } catch (error) {
      console.log(error);
    }
  });
}

async function verify(verifyModel, callback) {
  await axiosInstance.post(`${API}/api/customer/customer/verify`, verifyModel).then((response) => {
    try {
      callback(response.data.Result, response.data.code, response.data.msg);
    } catch (error) {
      console.log(error);
    }
  });
}

async function customerQuotes(tckn, callback) {
  await axiosInstance.get(`${API}/api/customer/customer-quotes?Identity=${tckn}`).then((response) => {
    try {
      callback(response.data.Result);
    } catch (error) {
      console.log(error);
    }
  });
}
async function masterOccupation(occupation, callback) {
  await axiosInstance.put(`${API}/api/customer/masteroccupation`, { ...occupation }).then((response) => {
    try {
      callback(response.data.Result);
    } catch (error) {
      console.log(error);
    }
  });
}

async function updateAPS(customerModel, callback) {
  await axiosInstance.post(`${API}/api/customer/aps`, customerModel).then((response) => {
    try {
      callback(response.data.Result, response.data.code, response.data.msg);
    } catch (error) {
      console.log(error);
    }
  });
}

async function contactScheduleCall(data, callback) {
  await axiosInstance.post(`${API}/api/customer/save-contact-schedule-call`, data).then((response) => {
    try {
      callback(response.data.Result);
    } catch (error) {
      console.log(error);
    }
  });
}

async function contactScheduleCallTss(data, callback) {
  await axiosInstance.post(`${API}/api/customer/call-me/tss`, data).then((response) => {
    try {
      callback(response.data.Result);
    } catch (error) {
      console.log(error);
    }
  });
}

async function sendUserAgreementEmail(email, callback) {
  await axiosInstance.post(`${API}/api/customer/send-user-agreement-email`, email).then((response) => {
    try {
      callback(response.data.Result);
    } catch (error) {
      console.log(error);
    }
  });
}

async function getUserAgreementHtml(callback) {
  await axiosInstance.get(`${API}/api/customer/user-agreement-html?BusinessLine=0`).then((response) => {
    try {
      callback(response.data.Result);
    } catch (error) {
      console.log(error);
    }
  });
}

async function getUserAgreementDocument(callback, xConsumer = null) {
  if (xConsumer) {
    axiosInstance.defaults.headers.common['X-Consumer'] = xConsumer;
  } else {
    interceptors.setXConsumer();
  }
  await axiosInstance.get(`${API}/api/customer/user-agreement-document`, {
    responseType: 'arraybuffer',
    headers: {
      'X-Response-Content-Type': 'application/pdf',
    },
  }).catch((error) => {
    if (error.response) {
      console.log(error.response.data);
    }
  }).then((response) => {
    try {
      callback(response);
    } catch (error) {
      console.log(error);
    }
  });
}

async function sendAps(data, callback) {
  await axiosInstance.post(`${API}/api/customer/aps`, data).then((response) => {
    try {
      callback(response.data.Result);
    } catch (error) {
      console.log(error);
    }
  });
}

async function getPermissonCollectionAllowed(sbn, callback) {
  await axiosInstance.get(`${API}/api/customer/permission-collection-allowed/${sbn}`).then((response) => {
    try {
      callback(response.data.Result);
    } catch (error) {
      console.log(error);
    }
  });
}

const customerService = {
  customer,
  verify,
  customerQuotes,
  masterOccupation,
  updateAPS,
  contactScheduleCall,
  sendUserAgreementEmail,
  getUserAgreementHtml,
  getUserAgreementDocument,
  sendAps,
  contactScheduleCallTss,
	getPermissonCollectionAllowed,
};
export default customerService;
