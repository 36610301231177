// import { birthDateValidation, wordValidation } from '../../../shared/_helpers/validations';
import React from 'react';
import { Button } from '@mui/material';
import { KLInput } from '../../../motor/views/components/form/KLInput';
import { wordValidation, birthDateValidation, onlyTcknValidation } from '../../../shared/_helpers/validations';
import fullWidthInputSx from '../fullWidthInputSx';
import KVKKInfo from '../../views/components/KVKKInfo/KVKKInfo';
import BackButton from '../../views/components/BackButton/BackButton';
import store from '../../../shared/store/store';

import tssButtonStyle from '../tssButtonStyle';
import KLAutoComplete from '../../../shared/formComponents/KLAutoComplete';

const createCityDistrictForm = (props) => {
  const {
    submitForm,
    formHookObject: {
      handleSubmit,
      reset,
      control,
      errors,
      touchedFields,
      isValid,
    },
    cityObject,
    districtObject,
  } = props;

  const { insureds } = store.getState().info;

  const formHookProps = {
    control,
    error: { ...errors },
    touchedFields,
    reset,
  };

  const formObject = {
    form: {
      city: {
        type: 'input',
        component: <KLAutoComplete />,
        props: {
          name: 'city',
          label: 'İl',
          sx: fullWidthInputSx,
          ...cityObject,
          ...formHookProps,
          renderParams: {
            name: 'name',
            code: 'code',
          },
        },
      },
      district: {
        type: 'input',
        component: <KLAutoComplete />,
        props: {
          name: 'district',
          label: 'İlçe',
          sx: fullWidthInputSx,
          ...districtObject,
          ...formHookProps,
          renderParams: {
            name: 'name',
            code: 'code',
          },
        },
      },
      button: {
        type: 'button',
        component: <Button />,
        props: {
          style: {
            ...tssButtonStyle,
          },
          disabled: !isValid,
          onClick: handleSubmit(submitForm),
          variant: 'contained',
        },
        children: 'Devam Et',
        hideMobile: true,
      },
      backbutton: {
        type: 'component',
        component: <BackButton />,
        hideMobile: true,
      },
    },
    bottom: {
      button: {
        type: 'button',
        component: <Button />,
        props: {
          style: {
            ...tssButtonStyle,
          },
          disabled: !isValid,
          onClick: handleSubmit(submitForm),
          variant: 'contained',
        },
        children: 'Devam Et',
        mobile: true,
      },
    },
  };

  return formObject;
};

export default createCityDistrictForm;
