import React from 'react';

import styled from '@emotion/styled';
import {
  FormControlLabel, Radio, RadioGroup,
} from '@mui/material';

const KLRadioGroup = (props) => {
  const {
    value, onChange, label, options,
  } = props;

  const StyledParagraph = styled('p')(({ theme }) => ({
    color: theme.palette.secondary.main,
  }));

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <div>
      <div className="">
        <StyledParagraph style={{
          fontStyle: 'normal', fontWeight: '600', fontSize: '14px', lineHeight: '20.46px', margin: '0',
        }}
        >
          {label}
        </StyledParagraph>
        {' '}
      </div>
      <div className="radio-container">
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          onChange={handleChange}
          value={value}
        >
          {options.map((option) => (
            <FormControlLabel value={option.Value} control={<Radio />} label={option.Text} id={option.Value} key={option.Value} />
          ))}
        </RadioGroup>
      </div>
    </div>
  );
};

export default KLRadioGroup;
