import axiosInstance from '../../shared/_helpers/axiosHelper';
import { API } from '../../shared/_helpers/env';

async function getCheckoutDetail(quoteGuid, teklifTid, callback) {
  await axiosInstance
    .get(`${API}/api/issue/checkout-detail?QuotationId=${quoteGuid}&QuotationTId=${teklifTid}`)
    .then((response) => {
      try {
        callback(response.data.Result);
      } catch (error) {
        console.log(error);
      }
    });
}
async function getThankyouDetail(quoteGuid, teklifTid, callback) {
  await axiosInstance.get(`${API}/api/issue/checkout-result?Qtid=${quoteGuid}&TeklifTID=${teklifTid}`).then((response) => {
    try {
      callback(response.data.Result);
    } catch (error) {
      console.log(error);
    }
  });
}
async function issueAsync(data, callback) {
  axiosInstance.defaults.headers.common['X-Unique-Transaction-Id'] = data.TeklifTId;
  try {
    await axiosInstance.post(`${API}/api/issue/issue-async`, { ...data }).then((response) => {
      try {
        callback(response.data.Result);
      } catch (error) {
        console.log(error);
      }
    });
  } catch (error) {
    callback(error.response.data.Result);
  }
}

async function getIssueStatus(qName, callback) {
  await axiosInstance.get(`${API}/api/issue/issue-status?queueName=${qName}`).then((response) => {
    try {
      callback(response.data.Result, response.data.StatusCode === 200);
    } catch (error) {
      console.log(error);
    }
  });
}

async function creditCardApplication(data, callback) {
  await axiosInstance.post(`${API}/api/issue/credit-card-application`, { ...data }).then((response) => {
    try {
      callback(response.data.Result);
    } catch (error) {
      console.log(error);
    }
  });
}

const issueService = {
  getCheckoutDetail,
  issueAsync,
  getThankyouDetail,
  getIssueStatus,
  creditCardApplication,
};
export default issueService;
