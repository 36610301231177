export const pregnancy = [
  {
    Text: 'Evet',
    Value: true,
  },
  {
    Text: 'Hayır',
    Value: false,
  },
];

export const pregnancyEnum = {
  Evet: true,
  Hayır: false,
};
