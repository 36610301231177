import SEQUENCE from '../../motor/constants/sequence';
import TSS_SEQUENCE from '../../tss/constants/sequence';
import store from '../store/store';
import { historyPushWithSearch } from './historyPushWithSearch';

export const stepDecider = (isRenewal, step, history) => {
  const stepType = isRenewal ? 'YENILEME' : 'SIFIR';
  const redirectUrl = SEQUENCE[stepType][step].stepUrl;

  history.push({
    pathname: redirectUrl,
    search: history.location.search, // TODO: dinamik
    // state: { qGuid: qGuid }, // TODO: dinamik
  });
};

export const tssStepDecider = (step, history, isFamily, newSearchParams) => {
  const stepType = isFamily ? 'FAMILY' : 'SELF';
  const redirectUrl = TSS_SEQUENCE[stepType].find((elm) => Number(elm.index) === Number(step)).stepUrl;
  // history.push({
  //   pathname: redirectUrl,
  //   search: history.location.search,
  // });
  historyPushWithSearch(redirectUrl, history, newSearchParams);
};
