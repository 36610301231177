export const convertBirthDatetoDashed = (birthdate) => {
  if (birthdate.includes('/')) {
    const [day, month, year] = birthdate !== undefined ? birthdate.split('/') : [null, null, null];
    const birthDateWithDash = year !== null ? [year, month, day].join('-') : null;
    return birthDateWithDash;
  }
  return birthdate;
};

export const convertBirthDatetoSlashed = (birthdate) => {
  if (birthdate.includes('-')) {
    const date = new Date(birthdate);

    let day = date.getDate();
    let month = date.getMonth() + 1;
    const year = date.getFullYear();

    day = day < 10 ? `0${day}` : day;
    month = month < 10 ? `0${month}` : month;

    const birthDateWithSlash = `${day}/${month}/${year}`;
    return birthDateWithSlash;
  }
  return birthdate;
};
