import React from 'react';
import {
  Stepper, Step, Button, StepLabel,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CheckIcon from '@mui/icons-material/Check';
import { styled } from '@mui/system';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import SEQUENCE from '../../constants/sequence';
import useWindowSize from '../../../shared/_hooks/windowResizeHook';

export const Steps = ({ isHidden = false, goBack }) => {
  const { step } = useSelector((state) => state.step);
  const { isRenewal } = useSelector((state) => state.shell);
  const size = useWindowSize();
  const flow = isRenewal === true ? SEQUENCE.YENILEME : isRenewal === false ? SEQUENCE.SIFIR : SEQUENCE.SIFIR;

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: '18px',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage: `linear-gradient( 95deg,${theme.palette.secondary.main} 0%,${theme.palette.secondary.main} 50%,${theme.palette.secondary.main} 100%)`,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage: `linear-gradient( 95deg,${theme.palette.secondary.main} 0%,${theme.palette.secondary.main} 50%,${theme.palette.secondary.main} 100%)`,
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 4,
      position: 'relative',
      border: 0,
      width: '100%',
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#90979D',
      borderRadius: 1,
    },
  }));
  const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    border: '5px solid #90979D',
    zIndex: 1,
    color: '#fff',
    width: 30,
    height: 30,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    ...(ownerState.completed && {
      backgroundColor: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
    }),
    ...(ownerState.active && {
      borderColor: theme.palette.secondary.main,
    }),
  }));
  const StyledParagraph = styled('p')(({ theme, ownerState }) => ({
    color: theme.palette.secondary.main,
  }));
  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;
    const icons = {
      0: <CheckIcon style={{ fontSize: '32px' }} />,
      1: <CheckIcon style={{ fontSize: '32px' }} />,
      2: <CheckIcon style={{ fontSize: '32px' }} />,
      3: <CheckIcon style={{ fontSize: '32px' }} />,
      4: <CheckIcon style={{ fontSize: '32px' }} />,
      5: <CheckIcon style={{ fontSize: '32px' }} />,
    };

    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }
  const BasicConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 23,
      left: 'calc(-50% + 10px)',
      right: 'calc(50% + 10px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage: `linear-gradient( 95deg,${theme.palette.secondary.main} 0%,${theme.palette.secondary.main} 50%,${theme.palette.secondary.main} 100%)`,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage: `linear-gradient( 95deg,${theme.palette.secondary.main} 0%,${theme.palette.secondary.main} 50%,${theme.palette.secondary.main} 100%)`,
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 2,
      position: 'relative',
      border: 0,
      width: '100%',
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#90979D',
      top: '-10px',
      borderRadius: 1,
    },
  }));
  const BasicStepsRoot = styled('div')(({ theme, ownerState }) => ({
    border: '2px solid #90979D',
    zIndex: 1,
    color: 'transparent',
    backgroundColor: '#f0f2f3',
    width: 24,
    height: 24,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.completed && {
      color: 'white',
      backgroundColor: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
    }),
    ...(ownerState.active && {
      position: 'relative',
      border: `3px solid ${theme.palette.secondary.main}`,
    }),
  }));
  function BasicSteps(props) {
    const { active, completed, className } = props;

    const icons = {
      0: <CheckIcon style={{ fontSize: '18px' }} />,
      1: <CheckIcon style={{ fontSize: '18px' }} />,
      2: <CheckIcon style={{ fontSize: '18px' }} />,
      3: <CheckIcon style={{ fontSize: '18px' }} />,
      4: <CheckIcon style={{ fontSize: '18px' }} />,
      5: <CheckIcon style={{ fontSize: '18px' }} />,
    };

    return (
      <BasicStepsRoot ownerState={{ completed, active }} className={className}>
        {icons[String(props.icon)]}
      </BasicStepsRoot>
    );
  }

  return (
    <>
      {flow ? (
        <>
          {size.width > 880 ? (
            <Stepper alternativeLabel activeStep={step} connector={<ColorlibConnector />} className="steps">
              {flow.map((f) => (
                <Step key={f.name}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    <StyledParagraph
                      className="stepper-label"
                      style={{ fontStyle: 'normal', fontWeight: '700', fontSize: '14px' }}
                    >
                      {f.displayName}
                    </StyledParagraph>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          ) : (
            <div style={{ position: 'relative', gridTemplateColumns: '1fr 5fr', display: isHidden ? 'none' : 'grid' }}>
              <Button
                style={{
                  color: '#53585E',
                  textTransform: 'none',
                  fontSize: '17px',
                  fontWeight: 600,
                  top: '10px',
                  visibility: goBack === undefined ? 'hidden' : '',
                }}
                onClick={goBack}
              >
                <ArrowBackIosNewIcon />
              </Button>
              <Stepper
                alternativeLabel
                activeStep={step}
                connector={<BasicConnector />}
                style={{ paddingTop: '20px', width: '70%', marginLeft: '3%' }}
              >
                {flow.map((f) => (
                  <Step key={f.name}>
                    <StepLabel StepIconComponent={BasicSteps} />
                  </Step>
                ))}
              </Stepper>
            </div>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Steps;
