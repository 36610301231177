/* eslint-disable react/jsx-indent */
/* eslint-disable no-tabs */

import React from 'react';

import '../../../shared/assets/styles/components/renewal-card.scss';
import '../../../shared/assets/styles/radio-button.scss';
import { useSelector } from 'react-redux';
import WarningSVG from '../../../shared/assets/img/svg/warning.svg';
import useWindowSize from '../../../shared/_hooks/windowResizeHook';
import {
  dealerCasco, dealerTraffic, plainCasco, plainTraffic,
} from '../../localization/RenewalTexts';

export const RenewalCard = ({
  startLoading, isProgressBarActive, isCascoRenewal, isTrafficRenewal, size,
}) => {
  const screenSize = useWindowSize();
  const isMobile = screenSize.width <= 880;
  const show = !isProgressBarActive && !startLoading;/*  && !isMobile */
  const { isAgency } = useSelector((state) => state.shell);
  return (
		<>
			{show && (isCascoRenewal || isTrafficRenewal) && (
				<>
					<div className={`renewal-container ${size === 'large' ? 'renewal-container-large' : ''}`}>
						{isTrafficRenewal && (
							<div className="renewal-card">
								<img className="warningImg" src={WarningSVG} width={isMobile ? 20 : 44} height={isMobile ? 20 : 38} />
								<div>
									<p className="text" style={{ fontWeight: '700' }}>Trafik Sigortası Poliçesi</p>
									{isAgency ? dealerTraffic(isTrafficRenewal) : plainTraffic(isTrafficRenewal)}
								</div>
							</div>
						)}
						{isCascoRenewal && (
							<div className="renewal-card">
								<img className="warningImg" src={WarningSVG} width={isMobile ? 20 : 44} height={isMobile ? 20 : 38} />
								<div>
									<p className="text" style={{ fontWeight: '700' }}>Kasko Sigortası Poliçesi</p>
									{isAgency ? dealerCasco(isCascoRenewal) : plainCasco(isCascoRenewal)}
								</div>
							</div>
						)}
					</div>
					{isCascoRenewal && isTrafficRenewal && !isAgency && (
						<div className="send-mail-card">
							<div className="renewal-send-mail">
								<p className="text" style={{ fontWeight: '700' }}>
									Yenileme döneminde olmadığınız için size teklif üretemedik.
								</p>
								<p className="text" style={{ fontWeight: '700' }}>
									Yenileme döneminiz geldiğinde size özel teklifler çalışıp, haber vereceğiz.
								</p>
							</div>
						</div>
					)}
				</>
			)}
		</>
  );
};

export default RenewalCard;
