const fullWidthInputSx = {
  '&.MuiAutocomplete-root': {
    width: '100%',
  },
  '& .MuiOutlinedInput-root': {
    width: '100%',
    '&.Mui-error': {
      width: '100%',
    },
  },
};

export const fullWidthSelectSx = {
  '&.MuiOutlinedInput-root': {
    width: '100%',
    '&.Mui-error': {
      width: '100%',
    },
  },
};

export default fullWidthInputSx;
