import React, { useState } from 'react';
import '../../../../shared/assets/styles/send-mail-modal.scss';
import '../../../../shared/assets/styles/has-fail-at-insurer-modal.scss';

import { useForm } from 'react-hook-form';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import useWindowSize from '../../../../shared/_hooks/windowResizeHook';
import { KLInput } from '../form/KLInput';
import { phoneValidation } from '../../../../shared/_helpers/validations';
import onlineSupport from '../../../../shared/assets/img/png/Online Support.png';
import emptyCheck from '../../../../shared/assets/img/svg/empty-check.svg';
import customerService from '../../../_services/customer.service';
import { displayPhoneNumber } from '../../../../shared/_helpers/helperFunctions';

export const HasFailAtInsurer = (props) => {
  const [modalStep, setModalStep] = useState(1);
  const { additionalInfo } = useSelector((state) => state.theme);
  const { consumer } = useSelector((state) => state.consumer);

  const size = useWindowSize();
  const { insurerFailType, setHasFailAtInsurer, failedInsurerTId } = props;
  const { isAgency, isMerchant } = useSelector((state) => state.shell);
  const {
    handleSubmit,
    formState: { errors, isValid, touchedFields },
    control,
  } = useForm({
    mode: 'onChange',
  });

  const onSubmit = (data) => {
    const contactData = {
      TeklifTId: failedInsurerTId,
      Sbn: JSON.parse(consumer).partnerSbn || 'KCDUTC',
      Phone: data.phone,
    };
    customerService.contactScheduleCall(contactData, (resp) => {});
    setModalStep(3);
  };
  const isKavakMerchant = JSON.parse(consumer).partnerSbn === 'KVKCMDLK';
  const has3DWarning = insurerFailType !== 'Bereket Sigorta Trafik';
  return (
    <>
      {(isKavakMerchant || !(isAgency || isMerchant)) ? (
        <div className="fail-at-insurer-container">
          <div className="fail-at-insurer">
            <img src={onlineSupport} />
            <h3>
              {insurerFailType}
              {' '}
              ürününü satın almak için lütfen çağrı merkezimizi arayın:
            </h3>
            <h2 style={{ color: '#0055a5' }}>
              <a
                href={additionalInfo.phone}
                style={{ textDecoration: 'none', color: 'rgb(0, 74, 153)' }}
              >
                {displayPhoneNumber(additionalInfo.phone)}
              </a>
            </h2>
            <p className="text">
              {has3DWarning ? (
                <>
                  {insurerFailType}
                  {' '}
                  ürünü ile 3D güvenlik entegrasyon sürecimiz devam ettiği için,
                  {' '}
                </>
              )
                : <></>}
              {insurerFailType}
              {' '}
              ürünü
              şu an için yalnızca çağrı merkezi üzerinden satın alınabiliyor. Dilerseniz çağrı merkezimizi arayarak
              poliçenizi kolaylıkla oluşturabilirsiniz.
            </p>
          </div>
        </div>
      ) : (
        <div className="fail-at-insurer-container">
          {modalStep === 1 && (
          <div className="fail-at-insurer">
            <img src={onlineSupport} />
            <h3>
              {insurerFailType}
              {' '}
              ürünü şu an yalnızca çağrı merkezi üzerinden satın alınabiliyor. Sizi en kısa sürede arayıp yardımcı olmamızı ister misiniz?
            </h3>
            <p className="text">
              {insurerFailType}
              {has3DWarning ? ', devreye aldığı SMS ile 3D güvenlik zorunluluğunun, internet satışlarında nasıl kullanılması gerektiğine dair henüz bir yönlendirme yapamadığı için, bu teklif şimdilik ' : ' teklifi '}
              yalnızca çağrı merkezimiz aracılığıyla poliçelendirilebiliyor. Dilerseniz sizi en kısa sürede arayarak poliçenizi oluşturabiliriz.
            </p>
            <div className="fail-at-insurer-buttons">
              <Button
                variant="outlined"
                onClick={() => setHasFailAtInsurer(false)}
              >
                Hayır, teşekkürler
              </Button>
              <Button
                variant="contained"
                onClick={() => setModalStep(2)}
              >
                Evet, aranmak istiyorum
              </Button>
            </div>
          </div>
          )}
          {modalStep === 2 && (
          <div className="fail-at-insurer">
            <img src={onlineSupport} />
            <h3>
              Lütfen aranmak istediğiniz telefon numarasını girin.
            </h3>

            <div className="fail-at-insurer-send-mail">
              <form>
                <KLInput
                  name="phone"
                  control={control}
                  label="Telefon Numarası"
                  mask="(599) 999 99 99"
                  error={errors}
                  defaultValue={null}
                  rules={phoneValidation()}
                  touchedFields={touchedFields}
                  sx={{
							  '& .MuiOutlinedInput-root': {
							    width: '100%',
							    marginBottom: '0',
							    '&.Mui-error': {
							      width: '100%',
							      marginBottm: '0',
							    },
							  },
							  '.MuiInputLabel-formControl': {
							    fontSize: '15px',
							    marginBottom: '0',
							  },
							  '&': {
							    width: 'auto',
							  },
                  }}
                />
                <Button
                  variant="contained"
                  disabled={!isValid}
                  style={{
							  height: '45px',
							  boxSizing: 'border-box',
							  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
							  textTransform: 'none',
                  }}
                  onClick={handleSubmit(onSubmit)}
                >
                  Aranma talebi oluştur
                </Button>
              </form>
            </div>

          </div>
          )}
          {modalStep === 3 && (
          <div className="fail-at-insurer">
            <img src={emptyCheck} />
            <h3>
              Aranma talebiniz başarılı bir şekilde alındı, en kısa sürede çağrı merkezimiz tarafından aranacaksınız.
            </h3>
          </div>
          )}
        </div>
      )}
    </>
  );
};

export default HasFailAtInsurer;
