import React, { useState, useEffect } from 'react';

import '../../../../../shared/assets/styles/components/installment.scss';
import 'react-credit-cards/es/styles-compiled.css';
import { getUsableLogo, priceFormatter } from '../../../../../shared/_helpers/helperFunctions';
import '../../../../../shared/assets/styles/components/cross-quotations.scss';

export const CrossSell = (props) => {
  const { CrossQuotations } = props;
  const [commercialName, setCommercialName] = useState('');

	const defaultCrossQuotation = CrossQuotations.length === 1
	? CrossQuotations[0] : CrossQuotations.reduce((prev, curr) => (prev.grossPremium < curr.grossPremium ? prev : curr));

  useEffect(() => {
    const tempName = getUsableLogo(defaultCrossQuotation);
    setCommercialName(tempName);
  }, [defaultCrossQuotation]);

  return (
    <div className="cross-quotation">
      <div className="cross-quotation-summary">
        <div className="insurer-logo">
          <img
            src={commercialName.src}
            alt={commercialName.alt}
            width={76}
          />
        </div>
        <p className="disclaimer">İhtiyari Mali Mesuliyet Sigortası</p>
        <div className="offer-price">
          {' '}
          {priceFormatter.format(defaultCrossQuotation.grossPremium).replace('₺', '')}
          {' '}
          TL
        </div>
      </div>
      <div className="description">
        <p className="assistive">İhtiyari Mali Mesuliyet(İMM) Sigortası satın alarak olası bir kazada ortaya çıkabilecek yüksek bedelli maddi ve manevi yükümlülüklerden kurtulun.</p>
      </div>
    </div>
  );
};

export default CrossSell;
