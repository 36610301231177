const TSS_SEQUENCE = {
  SELF: [
    {
      index: 1, name: 'info', displayName: 'Kimlik Bilgileri', stepUrl: '/tamamlayici-saglik-sigortasi/Info',
    },
    {
      index: 2, name: 'ContactInfo', displayName: 'İletişim Bilgileri', stepUrl: '/tamamlayici-saglik-sigortasi/ContactInfo',
    },
    {
      index: 3, name: 'CustomerInfo', displayName: 'Kişisel Bilgileri', stepUrl: '/tamamlayici-saglik-sigortasi/CustomerInfo',
    },
    {
      index: 4, name: 'Insureds', displayName: 'Sigortalanacak Bireyler', stepUrl: '/tamamlayici-saglik-sigortasi/Insureds',
    },
    {
      index: 5, name: 'Summary', displayName: 'Teklif Sayfası', stepUrl: '/tamamlayici-saglik-sigortasi/Summary',
    },
  ],
  FAMILY: [
    {
      index: 1, name: 'info', displayName: 'Kimlik Bilgileri', stepUrl: '/tamamlayici-saglik-sigortasi/Info',
    },
    {
      index: 2, name: 'CustomerInfo', displayName: 'Kişisel Bilgileri', stepUrl: '/tamamlayici-saglik-sigortasi/CustomerInfo',
    },
    {
      index: 3, name: 'Insureds', displayName: 'Sigortalanacak Bireyler', stepUrl: '/tamamlayici-saglik-sigortasi/Insureds',
    },
    {
      index: 4, name: 'Summary', displayName: 'Teklif Sayfası', stepUrl: '/tamamlayici-saglik-sigortasi/Summary',
    },
  ],
};

export default TSS_SEQUENCE;
