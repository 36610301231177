import {
  Box, Button,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PaperPlane from '../../../../shared/assets/img/svg/paper-plane.svg';
import { policyTypes, policyTypesRender } from '../../../data/policyTypes';
import { openPDF } from '../../../../shared/_helpers/helperFunctions';
import { MAIN_URL } from '../../../../shared/_helpers/env';
import useWindowSize from '../../../../shared/_hooks/windowResizeHook';
import PdfIcon from '../../../../shared/assets/img/svg/pdf.svg';
import quotationService from '../../../_services/quotation.service';
import loadingKlActions from '../../../actions/loadingActions';

export const MyPoliciesPolicy = (props) => {
  const size = useWindowSize();
  const dispatch = useDispatch();
  const isMobile = size.width <= 880;
  const { policy, isActivePolicy } = props;
  const { consumer } = useSelector((state) => state.consumer);

  const policyType = policyTypes.find((type) => type.productGroupId === policy.productGroupId);
  const policyLogoUrl = policyType.logoUrl || policyType.header || 'Zorunlu Trafik Sigortası';

  const imageUrl = require(`../../../../shared/assets/img/svg/my-policy/${policyLogoUrl.replace(/[\s.]+/g, '')}.svg`);

  const getFormatedDate = (date) => {
    const day = date.getDate().toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    let month = date.getMonth();
    month = (month + 1).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    const year = date.getFullYear().toLocaleString('en-US', {
      minimumIntegerDigits: 4,
      useGrouping: false,
    });
    return `${day}-${month}-${year}`;
  };

  const getInfoExplanation = (info) => {
    const explanation = policyTypesRender[Object.keys(policyTypesRender).find((e) => e === info)];
    return explanation;
  };

  const startDate = new Date(policy.policyStartDate);
  const endDate = new Date(policy.policyEndDate);
  const info1Explation = getInfoExplanation(policyType.info1);
  const info1 = [info1Explation, policy[policyType.info1]];

  let info2 = [];
  if (Array.isArray(policyType.info2) && [2, 1, 15].includes(policyType.productGroupId)) {
    const info2Explation = getInfoExplanation(policyType.info2[0]) || getInfoExplanation(policyType.info2[1]);
    const plateData = policy[policyType.info2[0]] || policy[policyType.info2[1]];
    info2 = [info2Explation, plateData];
  } else if (Array.isArray(policyType.info2)) {
    const arrayData = [];
    policyType.info2.map((adressInfo, index) => {
      if (policy[adressInfo]) {
        arrayData.push(policy[adressInfo]);
        if (policyType.infoExp[index]) {
          arrayData.push(policyType.infoExp[index]);
        }
      }
    });
    info2 = ['Adres:', arrayData.join(' ')];
  } else {
    const info2Explation = getInfoExplanation(policyType.info2);
    info2 = [info2Explation, policy[policyType.info2]];
  }

  const isRenewalPeriod = () => {
    const oneDay = 1000 * 60 * 60 * 24;
    const today = new Date();
    const start = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
    const end = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());
    const daysRemaining = (start - end) / oneDay;
    return {
      daysRemaining,
      onRenewalPriod: daysRemaining > 0 && daysRemaining <= policyType.remaningDays,
    };
  };

  const redirectToSummaryPage = () => {
    const { userUId, teklifUID } = policy;
    // functionBranchId is traffic and kasko = 1, imm = 9
    const functionBranchId = policyType.productGroupId === 15 ? 9 : 1;
    quotationService.getGenerateRedirectedQuote(userUId, teklifUID, functionBranchId, (response, hasError = false) => {
      dispatch(loadingKlActions.hideKlLoading());
      if (hasError) {
        window.location.href = policyType.landingUrl;
      } else if (policyType.productGroupId === 15) {
        // like: http://testself.koalay.corp.telesure.com.tr/imm-sigortasi?jt=...#/Summary/Quote?qGuid=...
        window.location.href = `${MAIN_URL}imm-sigortasi?jt=${response.userId}#/Summary/Quote?qGuid=${response.redirectedQuoteId}`;
      } else {
        // like: http://testteklif.koalay.com/quotation/summary?jt=...&qGuid=...
        window.location.href = `${document.location.origin}/quotation/summary?jt=${response.userId}&qGuid=${response.redirectedQuoteId}`;
      }
    });
  };

  const redirectToLandingPage = () => {
    dispatch(loadingKlActions.showKlLoadingEmpty());
    if (isRenewalPeriod().onRenewalPriod || !isActivePolicy) {
      if (
        policyType.productGroupId === 2
        || policyType.productGroupId === 1
        || policyType.productGroupId === 15
      ) {
        redirectToSummaryPage();
      } else {
        dispatch(loadingKlActions.hideKlLoading());
        window.location.href = policyType.landingUrl;
      }
    }
  };

  const getRemaningDays = () => {
    const isRenewal = isRenewalPeriod();
    const { daysRemaining, onRenewalPriod } = isRenewal;
    if (isActivePolicy && onRenewalPriod
    ) {
      return (
        <span className="remaining-days">
          {daysRemaining}
          {' '}
          gün sonra bitecektir
        </span>
      );
    } if (!isActivePolicy) {
      return (
        <span onClick={redirectToLandingPage} className="get-policy">
          <span>Yeni Teklif Al</span>
          <span><img src={PaperPlane} alt="" width={isMobile ? 12 : 12} /></span>
        </span>
      );
    }
  };

  const openPdfInTab = () => {
    window.open(`/quotation/downloadPdf?qGuid=${policy.teklifUID}&quotationId=${policy.teklifTID}&pdfType=policy&consumer=${btoa(consumer)}`);
  };

  return (
    <div className="mp-policies">
      <Box className="cancellation-container mp-main-box">
        {!isMobile && (
        <div className="mp-main-icon-column">
          <img className="policy-type-icon" src={imageUrl} alt="" width="48" />
        </div>
        )}
        <div className="mp-main-info-column">

          <h4 className="mp-main-header">
            {isMobile && (
            <span>
              <img className="policy-type-icon" src={imageUrl} alt="" width="32" style={{ marginRight: '16px' }} />
            </span>
            )}
            <span className="insurer-name" style={{ maxWidth: isRenewalPeriod().onRenewalPriod ? '111px' : 'unset' }}>
              {policyType.extraName || policyType.shortName}
            </span>
            {getRemaningDays()}
          </h4>

          <div className="mp-main-info-container">
            {' '}
            <p className="mp-main-info">
              <span className="info-explanation">Sigorta Şirketi:</span>
              {' '}
              <span>{policy.insurer}</span>
            </p>
            {' '}
            <p className="mp-main-info">
              <span className="info-explanation">{info1[0]}</span>
              {' '}
              <span>{info1[1]}</span>
            </p>
            {' '}
            <p className="mp-main-info">
              <span className="info-explanation">{info2[0]}</span>
              {' '}
              <span>{info2[1]}</span>
            </p>
            {' '}
          </div>
          <p className="mp-main-date">
            <span>Başlangıç Tarihi:</span>
            {' '}
            {getFormatedDate(startDate)}
            {' '}
            {'\u00A0'}
            {!isMobile ? '| \u00A0' : <br />}

            <span>Bitiş Tarihi:</span>
            {' '}
            {getFormatedDate(endDate)}
          </p>
        </div>
        <div className="mp-main-download-column">
          <span onClick={openPdfInTab}>
            <img src={PdfIcon} alt="" width={isMobile ? 24 : 24} />
            <p className="mp-header">Poliçeyi İndir</p>
          </span>
        </div>
        <div className="mp-main-button-column">
          {isActivePolicy && (
          <>
            {isRenewalPeriod().onRenewalPriod ? (
              <Button
                variant="contained"
                onClick={() => redirectToLandingPage()}
                style={{
                  width: !isMobile ? '230px' : '100%',
                  height: '45px',
                  margin: !isMobile ? '24px 7px' : '16px',
                  boxSizing: 'border-box',
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
                  borderRadius: '5px',
                  textTransform: 'none',
                }}
              >
                Poliçeni Yenile
              </Button>
            ) : null}
          </>
          )}
        </div>
      </Box>
    </div>
  );
};

export default withRouter(MyPoliciesPolicy);
