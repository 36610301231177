import { useState, useEffect } from 'react';
import userStateService from '../../motor/_services/userState.service';

const useUserState = () => {
  const [state, setState] = useState(null);

  const setUserState = async (value) => {
    await setState(value);
  };

  useEffect(() => {
    if (state) { userStateService.setUserState(state); }
  }, [state]);

  return { setUserState };
};

// const { setUserState } = useUserState();
export default useUserState;
