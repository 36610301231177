import React from 'react';

import { Button } from '@mui/material';
import styled from '@emotion/styled';
// import useWindowSize from '../../../../_hooks/windowResizeHook';
import useWindowSize from '../../../../../shared/_hooks/windowResizeHook';
import NoCreditCard from '../../../../../shared/assets/img/svg/NoCreditCard.svg';

export const CCCrossSellApplyStep = (props) => {
  const size = useWindowSize();
  const isMobile = size.width <= 880;
  const { applytoCC } = props;

  const StyledParagraph = styled('p')(({ theme }) => ({
    color: theme.palette.primary.main,
  }));

  return (
    <>
      <div className="cc-main-container">
        <div className="cc-icon">
          <img src={NoCreditCard} width={isMobile ? 46 : 78} height={isMobile ? 37 : 55} />
        </div>
        <div className="cc-middle">
          <StyledParagraph>
            Kredi kartı ihtiyacın var mı?
          </StyledParagraph>
          <p className="disclaimer">
            Akbank kredi kartına hemen başvur, fırsatlardan yararlan!
          </p>
          {!isMobile && (
          <span className="cc-description">
            Başvurunuz iştirakimiz olan
            {' '}
            <a href="https://www.hesapkurdu.com/" target="_blank" rel="noreferrer">Hesapkurdu.com</a>
            {' '}
            tarafından iletilecektir.
          </span>
          )}
        </div>
        {!isMobile && (
        <div className="cc-apply">
          <Button variant="outlined" onClick={applytoCC}>
            Hemen Başvur
          </Button>
        </div>
        )}
      </div>
      <div className="cc-main-container-mobile">
        {isMobile && (
        <div className="cc-apply">
          <Button variant="outlined" onClick={applytoCC}>
            Hemen Başvur
          </Button>
        </div>
        )}
        {isMobile && (
        <span className="cc-description">
          Başvurunuz iştirakimiz olan
          {' '}
          <a href="https://www.hesapkurdu.com/" target="_blank" rel="noreferrer">Hesapkurdu.com</a>
          {' '}
          tarafından iletilecektir.
        </span>
        )}
      </div>
    </>
  );
};

export default CCCrossSellApplyStep;
