/* eslint-disable no-case-declarations */
import { informationTypes } from '../types/information.types';

const initialState = {
  vehicle: {},
  customer: {},
  vehicleDetail: {},
  noClaimDiscount: null,
  hasBirthDateInfo: false,
  birthDateValue: false,
  isOccupationNeeded: null,
  quoteId: '',
  quotes: {},
  dropDown: {
    modelYear: undefined,
    brandModel: undefined,
  },
  isKPSActive: false,
  isVkn: false,
  isFamilyFlow: null,
  insureds: [],
	breakOutFields:null,
};

export default function INFORMATION_REDUCER(state = initialState, { type, payload }) {
  switch (type) {
    case informationTypes.SET_VEHICLE_DROPDOWN:
      return {
        ...state,
        dropDown: { ...payload.dropDown },
      };
    case informationTypes.SET_VEHICLE_INFO:
      return {
        ...state,
        vehicle: { ...payload.vehicle },
      };
		case informationTypes.SET_NOCLAIM_DATA:
      return {
        ...state,
        noClaimDiscount: { ...payload.data },
      };
    case informationTypes.SET_CUSTOMER_INFO:
      return {
        ...state,
        customer: { ...payload.customer },
      };
    case informationTypes.ADD_INSURED:
      return {
        ...state,
        insureds: [...state.insureds, payload.insured],
      };

    case informationTypes.UPDATE_INSURED:
      const updatedInsureds = [...state.insureds];
      const latestInsured = updatedInsureds.pop(); // Get the latest element
      const updatedLatestInsured = { ...latestInsured, ...payload.insured };
      updatedInsureds.push(updatedLatestInsured);
      return {
        ...state,
        insureds: updatedInsureds,
      };

		case informationTypes.SET_INSUREDS:
      return {
        ...state,
        insureds: [...payload.insureds],
      };

    case informationTypes.REMOVE_INSURED:
      const removedInsureds = [...state.insureds];
      removedInsureds.pop(); // Remove the latest element

      return {
        ...state,
        insureds: removedInsureds,
      };

    case informationTypes.SET_VEHICLE_DETAIL:
      return {
        ...state,
        vehicleDetail: { ...payload.vehicleDetail },
      };
    case informationTypes.SET_BIRTHDATE_INFO:
      return {
        ...state,
        hasBirthDateInfo: payload.birthDateInfo,
      };
    case informationTypes.SET_BIRTHDATE_VALUE:
      return {
        ...state,
        birthDateValue: payload.birthDateValue,
      };
    case informationTypes.SET_OCCUPATION_INFO:
      return {
        ...state,
        isOccupationNeeded: payload.isOccupationNeeded,
      };
    case informationTypes.SET_QUOTE_ID:
      return {
        ...state,
        quoteId: payload.quoteId,
      };
    case informationTypes.SET_CUSTOMER_QUOTES:
      return {
        ...state,
        quotes: payload.quotes,
      };
    case informationTypes.SET_KPS_IS_ACTIVE:
      return {
        ...state,
        isKPSActive: payload.isActive,
      };
    case informationTypes.SET_IS_VKN:
      return {
        ...state,
        isVkn: payload.isVkn,
      };
    case informationTypes.SET_IS_FAMILY_FLOW:
      return {
        ...state,
        isFamilyFlow: payload.isFamilyFlow,
      };    
		case informationTypes.SET_BREAKOUT_FIELDS:
      return {
        ...state,
        breakOutFields: payload.breakOutFields,
      };
    default:
      return state;
  }
}
