import React, { useState, useEffect } from 'react';
import { Grid, Button, Paper } from '@mui/material';
import OtpInput from 'react-otp-input';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import OTPSVG from '../../../../../shared/assets/img/svg/otp.svg';
import tickSVG from '../../../../../shared/assets/img/svg/tick.svg';
import useWindowSize from '../../../../../shared/_hooks/windowResizeHook';
import commonService from '../../../../_services/common.service';
import loadingKlActions from '../../../../actions/loadingActions';

export const SecureOTP = (props) => {
  const size = useWindowSize();
  const isMobile = size.width <= 880;
  const URL_PARAMS = new URLSearchParams(document.location.search);
  const [otp, setOtp] = useState('');
  const [isTimeEnd, setIsTimeEnd] = useState(false);
  const [isOtpFail, setIsOtpFail] = useState(false);
  const [isOtpSuccess, setIsOtpSuccess] = useState(false);
  const [resetTimer, setResetTimer] = useState(0);
  const dispatch = useDispatch();
  const { phone, tckn, jt } = props;

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -40%)',
    width: isMobile ? 343 : 468,
    bgcolor: 'background.paper',
    padding: 30,
    textAlign: 'center',
    background: '#FFFFFF',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
  };

  const checkOTP = () => {
    if (Math.ceil(Math.log10(parseInt(otp) + 1)) === 5) {
      const otpObj = {
        PhoneNumber: phone,
        OTP: otp,
        UserUId: jt,
        TCKN: tckn,
        OTPType: 0,
        isPolicyFlow: true,
      };
      dispatch(loadingKlActions.showKlLoadingEmpty());
      commonService.checkOTP(otpObj, (data) => {
        if (data.otpResult) {
          window.dataLayer.push({ funnel_variables: null });
          window.dataLayer.push({
            event: 'otp_verification',
            funnel_variables: {
              otp_success: true,
              jt,
            },
          });
          setIsOtpSuccess(true);
          props.succcessFunc();
        } else {
          window.dataLayer.push({ funnel_variables: null });
          window.dataLayer.push({
            event: 'otp_verification',
            funnel_variables: {
              otp_success: false,
              jt,
            },
          });
          dispatch(loadingKlActions.hideKlLoading());
          setIsOtpFail(true);
        }
      });
    }
  };

  const sendSms = () => {
    commonService.sendSMS(
      {
        OTP: null,
        OTPType: 0,
        PhoneNumber: phone,
        TCKN: tckn,
        UserUId: jt,
        isPolicyFlow: true,
      },
      (resp) => {
        setResetTimer(resetTimer + 1);
        setOtp('');
        setIsOtpFail(false);
        setIsTimeEnd(false);
      },
    );
  };

  useEffect(() => {
    if (Math.ceil(Math.log10(parseInt(otp) + 1)) === 5) {
      document.querySelectorAll('.MuiPaper-root input')[4].addEventListener('keypress', (e) => {
        if (e.key === 'Enter') {
          checkOTP();
        }
      });
    }
  }, [otp]);

  return (
    <>
      <Paper style={style} square>
        <Grid xs={12} sm={12} lg={12} xl={12} item>
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              margin: '10px 0 8px 0',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ width: '64px', position: 'relative' }} />
            <img src={OTPSVG} alt="" width={58} height={64} className="svg-icon" />
            <div>
              <CountdownCircleTimer
                isPlaying
                size={64}
                strokeWidth={2}
                duration={120}
                onComplete={() => setIsTimeEnd(true)}
                remainingTime={120}
                key={resetTimer}
                trailColor="#AA2323"
                colors={['#8DC63F', '#8DC63F', '#8DC63F', '#8DC63F']}
                colorsTime={[7, 5, 2, 0]}
              >
                {({ remainingTime }) => {
                  const minutes = Math.floor(remainingTime / 60);
                  let seconds = remainingTime % 60;
                  if (seconds === 0) {
                    seconds = '00';
                  } else if (seconds < 10) {
                    seconds = `0${seconds}`;
                  }
                  return `${minutes}:${seconds}`;
                }}
              </CountdownCircleTimer>
            </div>
          </div>
          <h3 style={{ margin: '24px 0' }}>Mobil Onay</h3>
          <div>
            <p className="otp" style={{ padding: '0 20px' }}>
              Lütfen 0
              {phone}
              {' '}
              nolu telefona gönderilen doğrulama kodunu girin
            </p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <OtpInput
              value={otp}
              onChange={(code) => {
                setOtp(code);
              }}
              numInputs={5}
              inputStyle={{
                fontSize: '24px',
                width: '36px',
                height: '36px',
                margin: '4px',
                borderTop: '0px',
                borderLeft: '0px',
                borderRight: '0px',
                outline: 'none',
                borderColor: '#000a46',
              }}
              containerStyle={{
                margin: '10px auto',
                padding: '10px',
              }}
              isInputNum
            />
            {' '}
          </div>
          {isOtpFail && !isOtpSuccess ? (
            <p
              className="disclaimer"
              style={{
                color: '#c73f3f',
                marginBottom: '16px',
                marginTop: '15px',
              }}
            >
              Girdiğiniz kod hatalıdır, lütfen kontrol edin.
            </p>
          ) : (
            ''
          )}
          {isOtpSuccess ? (
            <p
              className="disclaimer"
              style={{
                color: '#8DC63F',
                marginBottom: '16px',
                marginTop: '15px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <img src={tickSVG} />
              Kod onaylandı
            </p>
          ) : (
            ''
          )}
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Button
              variant="contained"
              disabled={isTimeEnd || isOtpSuccess}
              style={{
                width: !isMobile ? '254px' : '100%',
                height: '45px',
                margin: '8px 7px',
                boxSizing: 'border-box',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
                borderRadius: '5px',
                textTransform: 'none',
              }}
              onClick={checkOTP}
              type="submit"
            >
              Doğrula
            </Button>
            {isTimeEnd ? (
              <p
                className="disclaimer"
                style={{
                  color: '#c73f3f',
                  marginBottom: '16px',
                  marginTop: '15px',
                }}
              >
                Süre doldu, lütfen tekrar SMS gönderin.
              </p>
            ) : (
              ''
            )}
            <Button
              variant="outlined"
              disabled={!isTimeEnd}
              type="submit"
              style={{
                width: !isMobile ? '254px' : '100%',
                height: '45px',
                margin: '8px 7px',
                boxSizing: 'border-box',
                color: '#53585E',
                borderRadius: '5px',
                borderColor: '#53585E',
                textTransform: 'none',
              }}
              onClick={sendSms}
            >
              Tekrar SMS Gönder
            </Button>
          </div>
        </Grid>
      </Paper>
    </>
  );
};

export default withRouter(SecureOTP);
