import React from "react";

import infoSVG from "../../../../shared/assets/img/svg/info.svg";

import "../../../../shared/assets/styles/components/info.scss";

export const Info = ({ children }) => (
  <div className="info-container">
    <img src={infoSVG} alt="" className="svg-icon" />
    <p>{children}</p>
  </div>
);

export default Info;
