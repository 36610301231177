import React, { useEffect, useState } from 'react';
import { useLocation, withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '@mui/material';
import StepsBox from '../../components/StepsBox';
import FormBox from '../../components/FormBox';
import ContactInfoForm from './form/ContactInfoForm';
import informationActions from '../../../../motor/actions/informationAction';
import customerService from '../../../../motor/_services/customer.service';
import loadingKlActions from '../../../../motor/actions/loadingActions';
import { historyPushWithSearch } from '../../../../shared/_helpers/historyPushWithSearch';
import store from '../../../../shared/store/store';
import stepActions from '../../../../motor/actions/stepAction';
import { tssStepDecider } from '../../../../shared/_helpers/stepDecider';
import OTP from '../../components/OTP/OTP';
import commonService from '../../../../motor/_services/common.service';
import useUserState from '../../../../shared/_hooks/userStateHook';
import { UserStates } from '../../../../motor/data/userStates';
import { removeCustomEventsFromDataLayer } from '../../../../shared/_helpers/helperFunctions';

export const ContactInfo = (props) => {
  const { setUserState } = useUserState();
  const { insureds } = store.getState().info;
  const currentInsured = insureds[insureds.length - 1];
  const [eBulletin, setEBulletin] = useState(currentInsured.eBulletin || false);
  const [isOTPRequired, setIsOTPRequired] = useState(false);
  const [userConsentChecked, setUserConsentChecked] = useState(currentInsured.eBulletin || false);
  const { isAgency, isMerchant, isCallCenter } = useSelector((state) => state.shell);
	const {consumer} = useSelector((state) => state.consumer);
	const consumerObj = JSON.parse(consumer);
  const { customer } = useSelector((state) => state.info);
  const dispatch = useDispatch();
  const location = useLocation();
  const URL_PARAMS = new URLSearchParams(document.location.search);
  const { step, shouldRedirectCityDistrict } = useSelector((state) => state.step);
  const jt = URL_PARAMS.get('jt') || null;
  const qGuid = URL_PARAMS.get('qGuid') || null;
  const campaignSource = URL_PARAMS.get('CampSrc') || null;

  //* Detecting browserback & datalayer pageview
  useEffect(() => {
    if (currentInsured && currentInsured.isFull === true) { //* Meaning this is a browser-back
      currentInsured.isFull = false;
      dispatch(
        informationActions.updateInsured(currentInsured),
      );
    }
    const userSateData = {
      UserUID: jt,
      UserStateDefinition: UserStates.TSS_Iletisim_Bilgileri_Sayfasina_Gelen,
    };
    setUserState(userSateData);
    if (!Object.values(window.dataLayer).map((element) => element.event).includes('contact_info_page')) {
      removeCustomEventsFromDataLayer(window.dataLayer);
      window.dataLayer.push({
        event: 'contact_info_page',
      });
    }
  }, []);

  const {
    handleSubmit,
    reset,
    formState: { errors, isValid, touchedFields },
    control,
    setValue,
    getValues,
  } = useForm({
    mode: 'onChange',
  });

  const userConsentObject = {
    checked: userConsentChecked,
    onChange: setUserConsentChecked,
  };

  const formHookObject = {
    handleSubmit,
    reset,
    control,
    setValue,
    errors,
    isValid,
    touchedFields,
  };

  const nextPage = () => {
    if (shouldRedirectCityDistrict) {
      historyPushWithSearch('/tamamlayici-saglik-sigortasi/CityDistrict', props.history);
    } else {
      tssStepDecider(step + 1, props.history, false);
    }
    dispatch(stepActions.incrementStep());
  };

  const submitForm = (data) => {
    const customerObject = {
      TcKimlikVergiNo: customer.kimlikNo,
      Email: data.mail,
      PhoneNumber: data.phone.replace(/\D/g, ''),
      UserGuid: jt,
      EBulletin: userConsentChecked,
      Name: customer.ad,
      Surname: customer.soyad,
      Sbn: null,
      GCLID: '',
      I3Identify: '',
      ExtRefId: consumerObj.dealerCode,
      GclientId: '1383055116.1619005077',
      ChannelId: 1,
      CampSrc: campaignSource || '',
      FunctionalBranchId: 3,
      FromStep1: true,
      BirthDate: customer.dogumTarih,
    };

    const userSateData = {
      UserUID: jt,
      UserStateDefinition: UserStates.TSS_Iletisim_Bilgileri_Sayfasinda_Devama_Tiklayan,
    };
    setUserState(userSateData);

    customerService.customer(customerObject,
      (customerResult, isbulking, redirectionUrl) => {
        if (isbulking) {
          dispatch(loadingKlActions.hideKlLoading());
          window.location.href = redirectionUrl;
        } else {
          dispatch(informationActions.setCustomerInfo(customerResult));
          if (currentInsured) {
            currentInsured.mobilePhone = data.phone.replace(/\D/g, '');
            currentInsured.email = data.mail;
            currentInsured.movingForward = true;
            currentInsured.eBulletin = userConsentChecked;
            dispatch(
              informationActions.updateInsured(currentInsured),
            );
          }

          commonService.checkOTPUser(
            {
              phoneNumber: data.phone.replace(/\D/g, ''),
              tcIdentityNumber: customer.kimlikNo,
              isPolicyFlow: false,
            },
            (resp) => {
              if (!resp.otpUserResult && !isAgency && !isMerchant && !isCallCenter) {
                setIsOTPRequired(true);
                commonService.isInBlackList(
                  {
                    PhoneNumber: data.phone.replace(/\D/g, ''),
                    TCKN: customer.kimlikNo,
                    Email: customer.mail,
                    FirstPhoneNumber: customer.phoneNumber || customer.telefon,
                  },
                  (blresp) => {
                    if (!blresp) {
                      commonService.sendSMS(
                        {
                          OTP: null,
                          OTPType: 0,
                          PhoneNumber: data.phone.replace(/\D/g, ''),
                          TCKN: customer.kimlikNo,
                          UserUId: jt,
                        },
                        (sms) => {},
                      );
                    }
                  },
                );
              } else {
                nextPage();
              }
            },
          );
        }
      });

    window.dataLayer.push({ funnel_variables: null });
    window.dataLayer.push({
      event: 'contact_permission',
      funnel_variables: {
        marketing_permission: userConsentChecked,
      },
    });
  };

  return (
    <>
      <StepsBox>
        <FormBox formHeader="İletişim Bilgileri">
          <ContactInfoForm formHookObject={formHookObject} submitForm={submitForm} userConsentObject={userConsentObject} />
        </FormBox>
      </StepsBox>
      <Modal
        open={isOTPRequired}
        onClose={() => setIsOTPRequired(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <OTP
          quoteId={qGuid}
          phone={customer.phoneNumber || customer.telefon}
          nextPage={nextPage}
        />
      </Modal>
    </>
  );
};

export default withRouter(ContactInfo);
