export const mapFunnelDataToCustomer = (funnelCustomer) => {
  const customerObject = {
    kimlikNo: funnelCustomer.tcKimlikVergiNo,
    Email: funnelCustomer.eMail,
    PhoneNumber: funnelCustomer.phoneNumber,
    ad: funnelCustomer.name,
    Surname: funnelCustomer.surname,
    eBulten: funnelCustomer.eBulletin,
    Sbn: funnelCustomer.sbn,
    GCLID: '',
    I3Identify: '',
    ExtRefId: funnelCustomer.extRefId,
    GclientId: '1383055116.1619005077',
    ChannelId: 1,
    CampSrc: funnelCustomer.campSrc || '',
    FunctionalBranchId: 3,
    FromStep1: true,
    dogumTarih: funnelCustomer.birthDate,
  };

  return customerObject;
};
