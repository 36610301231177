import React from 'react';
import '../../../../../shared/assets/styles/send-mail-modal.scss';

import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import Support from '../../../../../shared/assets/img/svg/Support.svg';
import { displayPhoneNumber } from '../../../../../shared/_helpers/helperFunctions';
import tssButtonStyle from '../../../../data/tssButtonStyle';

export const MerchantRedirectCC = (props) => {
  const { additionalInfo } = useSelector((state) => state.theme);

  return (
    <div className="redirect-cc-container">
      <div className="redirect-cc">
        <img src={Support} />
        <h3>
          Üzgünüz!
        </h3>
        <p className="text">
          Seçmiş olduğunuz kriterler için yalnızca çağrı merkezimiz üzerinden poliçe işleminize devam edebiliyoruz. Dilerseniz aşağıdaki telefon numarasından çağrı merkezimize ulaşabilirsiniz.
          {/* {' '}
          <a
            href={additionalInfo.MobilePhone}
            style={{ textDecoration: 'none' }}
          >
            {displayPhoneNumber(additionalInfo.MobilePhone)}
          </a> */}
        </p>
        <a href={`tel:${additionalInfo.phone.replace(/\D/g, '')}`}>
          <Button
            variant="contained"
            style={{ ...tssButtonStyle, marginTop: '8px' }}
          >
            {displayPhoneNumber(additionalInfo.phone)}
            {' '}
            - Ara
          </Button>
        </a>
      </div>
    </div>
  );
};

export default MerchantRedirectCC;
