import store from '../store/store';
import axiosInstance from './axiosHelper';

function setXConsumer() {
  axiosInstance.defaults.headers.common['X-Consumer'] = store.getState().consumer.consumer;
}

export const interceptors = {
  setXConsumer,
};
