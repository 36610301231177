import axiosInstance from '../../shared/_helpers/axiosHelper';
import { STATE_API } from '../../shared/_helpers/env';

async function setUserState(stateData, callback) {
  await axiosInstance.post(`${STATE_API}/state`, stateData).then((response) => {
    try {
      if (callback) {
        callback(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  });
}

const userStateService = {
  setUserState,
};
export default userStateService;
