const pageList = {
  default: {
    pageName: '',
    hasHeader: true,
    hasStepper: false,
    hasMainNav: true,
    hasJt: false,
  },
  'tamamlayici-saglik-sigortasi': {
    pageName: 'tamamlayici-saglik-sigortasi',
    hasHeader: true,
    hasStepper: false,
    hasMainNav: true,
    hasJt: false,
  },
  info: {
    pageName: 'info',
    hasHeader: true,
    hasStepper: false,
    hasMainNav: true,
    hasJt: false,
  },
  ContactInfo: {
    pageName: 'ContactInfo',
    hasHeader: true,
    hasStepper: false,
    hasMainNav: true,
    hasJt: true,
  },
  Insureds: {
    pageName: 'Insureds',
    hasHeader: true,
    hasStepper: false,
    hasMainNav: true,
    hasJt: false,
  },
  openPDF: {
    pageName: 'openPDF',
    hasHeader: false,
    hasStepper: false,
    hasMainNav: false,
    hasJt: false,
    hasQuotationDetail: false,
  },
  Confirmation: {
    pageName: 'Confirmation',
    hasHeader: true,
    hasStepper: false,
    hasMainNav: true,
    hasJt: true,
  },
  Thankyou: {
    pageName: 'Thankyou',
    hasHeader: true,
    hasStepper: false,
    hasMainNav: true,
    hasJt: true,
    hasQuotationDetail: true,
  },
  Checkout: {
    pageName: 'checkout',
    hasHeader: true,
    hasStepper: false,
    hasMainNav: true,
    hasJt: false,
    hasQuotationDetail: true,
  },
};

export default pageList;
