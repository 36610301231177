import React from "react";
import tickSVG from "../../../../../shared/assets/img/svg/tss-green-checkmark.svg";

export const ProductFeatures = (props) => {
  const {
    productFeatures,
    productFeatures: { title, items },
  } = props;

  return (
    productFeatures && (
      <>
        <div className="policy-detail-product-features">
          <p className="product-feature-title">{title}</p>
          <div className="feature-list">
            {items.map((item) => (
              <span className="feature">
                <img src={tickSVG} />
                {item.content}
              </span>
            ))}
          </div>
        </div>
      </>
    )
  );
};

export default ProductFeatures;
