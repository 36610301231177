export const policyTypes = [
  {
    header: 'Zorunlu Trafik Sigortası',
    shortName: 'Trafik Sigortası',
    logoUrl: 'ZorunluTrafikSigortası',
    explanation: 'Trafiğe çıkan her motorlu araç için zorunludur. Hemen teklif al.',
    landingUrl: 'https://www.koalay.com/zorunlu-trafik-sigortasi',
    productGroupId: 2,
    info1: 'policyNo',
    info2: ['plateZeyil', 'plate'],
    remaningDays: 15,
  },
  {
    header: 'Kasko Sigortası',
    shortName: 'Kasko Sigortası',
    logoUrl: 'KaskoSigortası',
    explanation: 'Kasko sigortası ile aracını güvence altına al.',
    landingUrl: 'https://www.koalay.com/kasko-sigortasi',
    productGroupId: 1,
    info1: 'policyNo',
    info2: ['plateZeyil', 'plate'],
    remaningDays: 30,
  },
  {
    header: 'Zorunlu Deprem Sigortası',
    shortName: 'Deprem Sigortası',
    logoUrl: 'ZorunluDepremSigortası',
    explanation: 'Zorunlu deprem sigortası(DASK) yaptırmayı ihmal etme.',
    landingUrl: 'https://www.koalay.com/online-dask-teklifi-al',
    productGroupId: 3,
    info1: 'policyNo',
    info2: ['neighborhood', 'street', 'outerDoorNumber', 'innerDoorNumber', 'districtName', 'cityName'],
    infoExp: ['Mahallesi', 'Sokak', '/'],
    remaningDays: 60,
  },
  {
    header: 'Konut ve Eşya Sigortası',
    shortName: 'Konut ve Eşya Sigortası',
    logoUrl: 'KonutveEşyaSigortası',
    explanation: 'Evini ve eşyalarını olası tüm risklere karşı korumak için hemen teklif al.',
    landingUrl: 'https://www.koalay.com/online-konut-ve-esya-sigortasi',
    productGroupId: 9,
    info1: 'policyNo',
    info2: ['neighborhood', 'street', 'outerDoorNumber', 'innerDoorNumber', 'districtName', 'cityName'],
    infoExp: ['Mahallesi', 'Sokak', '/'],
    remaningDays: 30,
  },
  {
    header: 'Motosiklet Sigortası',
    shortName: 'Motosiklet Sigortası',
    logoUrl: 'MotosikletSigortası',
    explanation: 'En ucuz motosiklet trafik/kasko sigortası tekliflerine ulaş.',
    landingUrl: 'https://www.koalay.com/motosiklet-sigortasi',
    remaningDays: 30,
  },
  {
    header: 'İhtiyari Mali Mesuliyet Sigortası',
    shortName: 'İMM Sigortası',
    logoUrl: 'İMMSigortası',
    explanation: 'Zorunlu trafik sigortasının teminat limitlerini aşan olası durumlar için hemen teklif al',
    landingUrl: 'https://www.koalay.com/ihtiyari-mali-mesuliyet-sigortasi',
    productGroupId: 15,
    info1: 'policyNo',
    info2: ['plateZeyil', 'plate'],
    remaningDays: 30,
  },
  {
    header: 'Tamamlayıcı Sağlık Sigortası',
    shortName: 'Tamamlayıcı Sağlık S.',
    extraName: 'Tamamlayıcı Sağlık Sigortası',
    logoUrl: 'TamamlayıcıSağlıkS',
    explanation: 'Tamamlayıcı sağlık sigortası yaptır, sağlığını güvenceye al.',
    landingUrl: 'https://www.koalay.com/online-tamamlayici-saglik-sigortasi',
    productGroupId: 5,
    info1: 'policyNo',
    info2: 'identityNumber',
    remaningDays: 30,
  }, {
    header: 'Seyahat Sağlık Sigortası',
    shortName: 'Seyahat Sağlık Sigortası',
    logoUrl: 'SeyahatSağlıkSigortası',
    explanation: 'Seyahatlerde karşılaşılması olası sağlık risklerine karşı hemen teklif al.',
    landingUrl: 'https://www.koalay.com/online-seyahat-saglik-sigortasi',
    productGroupId: 6,
    info1: 'policyNo',
    info2: 'identityNumber',
    remaningDays: 30,
  },
];

export const policyTypesRender = {
  policyNo: 'Poliçe Numarası:',
  plate: 'Plaka:',
  plateZeyil: 'Plaka:',
  identityNumber: 'TC kimlik / Vergi no:',
};
