/* eslint-disable no-param-reassign */
//! Really ugly mask function, can be improved.
//! Valid formats: "99 X 9999", "99 X 99999", "99 XX 999", "99 XX 9999", "99 XXX 99", "99 XXX 999"
export const maskPlate = (value) => {
  let val; // last entered character
  let len; // length of total input with whitespaces
  let wsCount; // whitespace count (initially 1)
  if (value) {
    value = value.toUpperCase();
    len = value.replace(/\s/g, '').length;
    wsCount = value.split(' ').length;
    val = value.slice(-1);
    const isNumber = !isNaN(val);
    const isString = !isNumber;
    //* to prevent inputs like .,-*?=
    if (!isNumber && !(/[a-zA-Z]/).test(val)) {
      return value.slice(0, -1);
    }
    if (val === ' ') { return value.slice(0, -1); }
    if (wsCount >= 3) {
      if (len <= 8) {
        if (isString) {
          return value.slice(0, -1);
        } if (isNumber) {
          return value;
        }
      } else {
        return value.slice(0, -1);
      }
    }
    if (len === 1 || len === 2) {
      if (isNumber) {
        return value;
      }
      value = '';
      return value;
    } if (len === 3) {
      if (isString) {
        if (wsCount === 1) { value = `${value.slice(0, -1)} ${val}`; }
        return value;
      } if (isNumber) {
        return value.slice(0, -1);
      }
    } else if (len === 4 || len === 5) {
      if (isString) {
        return value;
      } if (isNumber) {
        value = `${value.slice(0, -1)} ${val}`;
        return value;
      }
    } else if (len === 6) {
      if (isNumber) {
        value = `${value.slice(0, -1)} ${val}`;
        return value;
      } if (isString) {
        return value.slice(0, -1);
      }
    }
  }
};
