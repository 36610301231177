import { stepTypes } from '../types/step.types';

function incrementStep() {
  window.scrollTo(0, 0);
  return {
    type: stepTypes.INCREMENT_STEP,
  };
}
function decrementStep() {
  window.scrollTo(0, 0);
  return {
    type: stepTypes.DECREMENT_STEP,
  };
}

function setStep(step) {
  window.scrollTo(0, 0);
  return {
    type: stepTypes.SET_STEP,
    payload: {
      step,
    },
  };
}
function setRedirectUrl(url) {
  return {
    type: stepTypes.SET_REDIRECT_URL,
    payload: {
      url,
    },
  };
}
function setfromInsureds(fromInsureds) {
  return {
    type: stepTypes.SET_FROM_INSUREDS,
    payload: {
      fromInsureds,
    },
  };
}

function setShouldRedirectCityDistrict(shouldRedirectCityDistrict) {
  return {
    type: stepTypes.SET_SHOULD_REDIRECT_CITY_DISTRICT,
    payload: {
      shouldRedirectCityDistrict,
    },
  };
}
const stepActions = {
  incrementStep,
  decrementStep,
  setStep,
  setRedirectUrl,
  setfromInsureds,
  setShouldRedirectCityDistrict,
};
export default stepActions;
