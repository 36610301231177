const SET_FUNNEL_INFO = 'SET_FUNNEL_INFO';
const SET_RENEWAL_INFO = 'SET_RENEWAL_INFO';
const SET_DEALER_INFO = 'SET_DEALER_INFO';
const SET_MERCHANT_INFO = 'SET_MERCHANT_INFO';
const SET_CALL_CENTER_INFO = 'SET_CALL_CENTER_INFO';
const SET_IS_BACK = 'SET_IS_BACK';
const SET_QUOTATION_INFO = 'SET_QUOTATION_INFO';
const SET_SBN = 'SET_SBN';
const SET_THEME_BY_PAGE = 'SET_THEME_BY_PAGE';
const SET_IS_KOALAY_SS = 'SET_IS_KOALAY_SS';

export const shellTypes = {
  SET_FUNNEL_INFO,
  SET_RENEWAL_INFO,
  SET_DEALER_INFO,
  SET_MERCHANT_INFO,
  SET_IS_BACK,
  SET_QUOTATION_INFO,
  SET_CALL_CENTER_INFO,
  SET_SBN,
  SET_THEME_BY_PAGE,
  SET_IS_KOALAY_SS,
};
