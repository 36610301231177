import { campaignTypes } from '../types/campaign.type';

export function setCampaignInfo(campaign) {
  return {
    type: campaignTypes.CAMPAIGN_SET_INFO,
    payload: {
      ...campaign,
    },
  };
}
