import React, { useEffect, useState } from 'react';
import {
  Button, Checkbox, FormControlLabel, Modal,
} from '@mui/material';
import { withRouter } from 'react-router-dom';
import { Box } from '@mui/system';
import useWindowSize from '../../../../../shared/_hooks/windowResizeHook';
import tickGreen from '../../../../../shared/assets/img/svg/tick-green.svg';
import important from '../../../../../shared/assets/img/svg/Box Important.svg';
import info from '../../../../../shared/assets/img/svg/infoBlue.svg';
import CenteredModal from '../../../components/widget/CenteredModal';

export const Information = (props) => {
  const [isCampaignModalOpen, setIsCampaignModalOpen] = useState(false);

  const size = useWindowSize();
  const isMobile = size.width <= 880;
  const isKoalaySS = document.location.href.indexOf('koalay') >= 0;

  return (
    <>
      {props.failedPolicies.length === 0 ? (
        <div className="policy-info success">
          <img src={tickGreen} height={isMobile ? 38 : 74} width={isMobile ? 38 : 74} />
          {props.policies.length === 1 && (
          <h2>Tebrikler, poliçeniz hazır!</h2>
          )}
          {props.policies.length > 1 && (
          <h2>Tebrikler, poliçeleriniz hazır!</h2>
          )}
          {props.campaignDescription && <p className="policy-info-description">{props.campaignDescription}</p>}
          {props.campaignLink && <span onClick={() => setIsCampaignModalOpen(true)} dangerouslySetInnerHTML={{__html: props.campaignLink}} className="policy-info-link" />}
        </div>
      ) : (
        <div className="policy-info-fail">
          <div className="policy-info">
            <img src={tickGreen} height={isMobile ? 38 : 74} width={isMobile ? 38 : 74} />
            <h2>
              Tebrikler,
              {' '}
              {props.policies[0].branchName.toLowerCase()}
              {' '}
              poliçeniz hazır!
            </h2>
          </div>
          <div className="policy-info">
            <img src={important} height={isMobile ? 38 : 74} width={isMobile ? 38 : 74} />
            <h2>
              {props.failedPolicies[0].productName.split('(')[0]}
              {' '}
              poliçenizin tanziminde bir hata oluştu
            </h2>
          </div>
          <div className="fail-reason">
            <img src={info} height={isMobile ? 18 : 24} width={isMobile ? 18 : 24} className="svg-icon" />
            <p className="disclaimer">
              Teknik bir hata sebebiyle İMM poliçeniz oluşturulamadı. Kartınızdan İMM poliçesi için ücret tahsil edilmedi.
              <br />
              Dilerseniz www.koalay.com linkinden tekrar deneyebilir veya
              {' '}
              <a
                href={isKoalaySS ? 'tel:4448883' : 'tel:02165700800'}
                style={{ textDecoration: 'none', color: 'rgb(0, 74, 153)' }}
              >
                {isKoalaySS ? '444 88 83' : '0216 570 08 00'}
              </a>
              {' '}
              numarasını arayabilirsiniz.
            </p>
          </div>
        </div>
      )}
      <CenteredModal isOpen={isCampaignModalOpen} setOpen={() => setIsCampaignModalOpen(false)}>
        <div dangerouslySetInnerHTML={{__html: props.campaignDetail}} />
      </CenteredModal>
    </>
  );
};

export default withRouter(Information);
