import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  typography: {
    fontFamily: '"Nunito", sans-serif',
  },
  palette: {
    primary: { main: '#0055A5' },
    secondary: { main: '#0055A5' },
  },
  components: {
    // Inputs
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#f0f2f3',
          '& *': {
            boxSizing: 'unset',
          },
          '& a': {
            color: '#0055A5',
          },
          '& *::-webkit-scrollbar': {
            width: '8px',
            borderRadius: '10px',
            color: '#d9dde1',
          },
          '& *::-webkit-scrollbar-track': {
            backgroundColor: '#d9dde1',
            borderRadius: '10px',
          },
          '& *::-webkit-scrollbar-thumb': { background: '#0055a5', borderRadius: '8px' },
          '& *input:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 100px white inset !important',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            width: '254px',
            height: '45px',
          },
          '&.Mui-disabled': {
            backgroundColor: '#D9DDE1',
            opacity: '0.5',
          },
          width: '254px',
          height: '45px',
          maxWidth: '100%',
          '@media (max-width:904px)': {
            width: '100%',
            '&.Mui-error': {
              width: '100%',
              height: '45px',
            },
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.MuiMenuItem-root:hover': {
            backgroundColor: '#1B7EDC !important',
            color: 'white',
            '&:focus': {
              color: 'white !important',
            },
          },
          '&.MuiMenuItem-root': {
            fontSize: '13px',
          },
          '&.MuiMenuItem-root:focus': {
            backgroundColor: 'white',
            color: 'black',
          },
          '&.MuiButton-contained.Mui-selected': {
            color: 'white !important',
          },
          '&.MuiButton-contained.Mui-disabled': {
            backgroundColor: '#90b3d4 !important',
            color: 'white !important',
            opacity: '1',
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginLeft: '0 !important',
          marginBottom: '20px',
          width: '100%',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          marginLeft: '0 !important',
          fontSize: '16px',
          fontWeight: 700,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: '5px',
          marginLeft: '0 !important',
          paddingLeft: '0 !important',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          top: '-4px',
        },
      },
    },
  },
});
