import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CCCrossSellApply from './steps/CCCrossSellApply';
import CCCrossSellSuccess from './steps/CCCrossSellSuccess';
import CCCrossSellSteps from '../../../data/ccCrossSellSteps';
import ccCrossSellStepActions from '../../../actions/ccCrossSellAction';
import issueService from '../../../_services/issue.service';
import { UserStates } from '../../../data/userStates';
import useUserState from '../../../../shared/_hooks/userStateHook';

import '../../../../shared/assets/styles/components/cc-crossell.scss';

export const CCCrossSell = (props) => {
  const dispatch = useDispatch();
  const { setUserState } = useUserState();
  const { step: CCStep } = useSelector((state) => state.ccCrossSell);
  const { showCCCrossSell, jt, qGuid } = props;

  const closeCCCrossSell = () => {
    dispatch(ccCrossSellStepActions.setCCCrossSellStep(CCCrossSellSteps.Hidden));
  };

  const applytoCC = () => {
    window.dataLayer.push({
      event: 'CC_application',
    });
    const reqObject = {
      ProcessId: qGuid,
      UserId: jt,
    };
    const userSateData = {
      UserUID: jt,
      UserStateDefinition: UserStates.Koalay_Teklif_Sayfasinda_Kredi_Kartina_Basvuran,
    };
    setUserState(userSateData);

    issueService.creditCardApplication(reqObject, (resp) => {
      if (resp.isSuccessStatusCode) {
        dispatch(ccCrossSellStepActions.setCCCrossSellStep(CCCrossSellSteps.Success));
      } else {
        dispatch(ccCrossSellStepActions.setCCCrossSellStep(CCCrossSellSteps.Success));
      }
    });
  };

  if (CCStep === CCCrossSellSteps.Hidden || !showCCCrossSell) {
    return (<></>);
  }

  return (
    <div className="cc-container">
      {CCStep === CCCrossSellSteps.Apply && (<CCCrossSellApply applytoCC={applytoCC} />)}
      {CCStep === CCCrossSellSteps.Success && (<CCCrossSellSuccess closeCCCrossSell={closeCCCrossSell} />)}
      {CCStep === CCCrossSellSteps.Reject && (<CCCrossSellSuccess closeCCCrossSell={closeCCCrossSell} />)}
    </div>
  );
};

export default CCCrossSell;
