import coverageService from '../../motor/_services/coverage.service';

const popularCoveragesDecider = (offer, handlePopularCoverages) => {
  if (offer.Version === 1) {
    if (offer.Brans.toLowerCase() === 'kasko') {
      coverageService.GetPopularCoverages(offer.TeklifTID, offer.Brans.toLowerCase() === 'trafik', (response) => {
        handlePopularCoverages(response.mergedCoverages.sort((a, b) => a.displayText.length - b.displayText.length));
      });
    } else {
      coverageService.getQuotationCoverages(offer.TeklifTID, (response) => {
        handlePopularCoverages(response.coverageGroups);
      });
    }
  } else if (offer.Version === 2) {
    if (offer.Brans.toLowerCase() === 'kasko') {
      coverageService.GetPopularCoveragesv2(offer.TeklifTID, (response) => {
        handlePopularCoverages(response.coverages.sort((a, b) => a.orderNo - b.orderNo));
      });
    } else {
      coverageService.GetTrafficCoveragesv2(offer.TeklifTID, (response) => {
        handlePopularCoverages(response.coverages.sort((a, b) => a.orderNo - b.orderNo));
      });
    }
  }
};

export default popularCoveragesDecider;
