import toastrActions from '../../motor/actions/toastrAction';
import { requestErrorList } from '../../motor/data/errorList';
import store from '../store/store';

export const showRequestError = (errorKey, dispatch) => {
  const { phone } = store.getState().theme.additionalInfo;
  const error = requestErrorList.find((item) => item.id === errorKey);
  if (error && error.text.includes('*PHONE_NUM*') && phone) {
    error.text = error.text.replace('*PHONE_NUM*', phone);
  }
  dispatch(toastrActions.setToastrMessage({ variant: 'error', message: error ? error.text : errorKey }));
};
