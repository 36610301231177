import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import '../../../../shared/assets/styles/components/info-modal.scss';
import styled from '@emotion/styled';
import DeleteSVG from '../../../../shared/assets/img/svg/delete.svg';

const Transition = React.forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);
const StyledHeader = styled('a')(({ theme, ownerState }) => ({
  color: theme.palette.primary.main,
  fontSize: '23px !important',
  paddingRight: '46px',
  fontWeight: 700,
  lineHeight: 1.42857143,
}));
export const InfoModal = ({
  children, isOpen, closeModal, title,
}) => (
  <div>
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => closeModal(false)}
      maxWidth="md"
      fullWidth
      aria-describedby="alert-dialog-slide-description"
      className="info-modal-parent"
    >
      <div className="info-modal-title-container">
        <StyledHeader color="primary">{title}</StyledHeader>
        <img src={DeleteSVG} className="info-modal-close-button" onClick={() => closeModal(false)} />
      </div>
      <DialogContent className="info-modal-container">
        <DialogContentText className="info-modal-body">{children}</DialogContentText>
      </DialogContent>
    </Dialog>
  </div>
);

export default InfoModal;
