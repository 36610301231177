import React from "react";
import {
  constructHospitalGroupText,
  constructTreatmentTypeText,
  countStandingTreatment,
} from "../../../../helpers/stringHelpers";
import { priceFormatter } from "../../../../../shared/_helpers/helperFunctions";

export const ConfirmationInsureds = (props) => {
  const { insured, quotation, totalGrossPremium, isLast, isMobile, hasİllnessDeclaration } = props;

  const treatmentCountElement =
    countStandingTreatment(quotation.quotationCoverageItem.coverageGroups) === "-" ? (
      <span>-</span>
    ) : (
      <span>{countStandingTreatment(quotation.quotationCoverageItem.coverageGroups)}</span>
    );

  return (
    <>
      <div className="confirmation-insured">
        <div>
          {insured.name} {insured.lastName}
          {totalGrossPremium !== insured.selectedPlan.premium.grossPremium && (
            <p className="price">
              {priceFormatter.format(insured.selectedPlan.premium.grossPremium).split(",")[0].replace("₺", "")} TL
            </p>
          )}
        </div>
        <p />
        <p className="details">Poliçe Detayları</p>
        <p className="network-plan">
          {quotation.network.networkGroup} - {insured.selectedPlan.networkName}
        </p>
        <p className="network-plan">{insured.selectedPlan.coverageGroupName}</p>
        <p className="network-plan">Ayakta Tedavi {treatmentCountElement}</p>
      </div>
      {((!isMobile && !isLast) || (isMobile && (isLast ? hasİllnessDeclaration : true))) && (
        <span className="separator-line" />
      )}
    </>
  );
};

export default ConfirmationInsureds;
