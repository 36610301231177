import { informationTypes } from '../types/information.types';

function setVehicleDropDown(dropDown) {
  return {
    type: informationTypes.SET_VEHICLE_DROPDOWN,
    payload: {
      dropDown,
    },
  };
}
function setVehicleInfo(vehicle) {
  return {
    type: informationTypes.SET_VEHICLE_INFO,
    payload: {
      vehicle,
    },
  };
}

function setNoClaimDiscount(data) {
  return {
    type: informationTypes.SET_NOCLAIM_DATA,
    payload: {
      data,
    },
  };
}
function setCustomerInfo(customer) {
  return {
    type: informationTypes.SET_CUSTOMER_INFO,
    payload: {
      customer,
    },
  };
}

function addInsured(insured) {
  return {
    type: informationTypes.ADD_INSURED,
    payload: {
      insured,
    },
  };
}

function updateInsured(insured) {
  return {
    type: informationTypes.UPDATE_INSURED,
    payload: {
      insured,
    },
  };
}
function setInsureds(insureds) {
  return {
    type: informationTypes.SET_INSUREDS,
    payload: {
      insureds,
    },
  };
}
function removeInsured(insured) {
  return {
    type: informationTypes.REMOVE_INSURED,
    payload: {
      insured,
    },
  };
}

function setVehicleDetail(vehicleDetail) {
  return {
    type: informationTypes.SET_VEHICLE_DETAIL,
    payload: {
      vehicleDetail,
    },
  };
}
function setBirthDateInfo(birthDateInfo) {
  return {
    type: informationTypes.SET_BIRTHDATE_INFO,
    payload: {
      birthDateInfo,
    },
  };
}
function setBirthDateValue(birthDateValue) {
  return {
    type: informationTypes.SET_BIRTHDATE_VALUE,
    payload: {
      birthDateValue,
    },
  };
}
function setQuoteId(quoteId) {
  return {
    type: informationTypes.SET_QUOTE_ID,
    payload: {
      quoteId,
    },
  };
}
function setOccupationInfo(isOccupationNeeded) {
  return {
    type: informationTypes.SET_OCCUPATION_INFO,
    payload: {
      isOccupationNeeded,
    },
  };
}
function setCustomerQuotes(quotes) {
  return {
    type: informationTypes.SET_OCCUPATION_INFO,
    payload: {
      quotes,
    },
  };
}
function setKPSIsActive(isActive) {
  return {
    type: informationTypes.SET_KPS_IS_ACTIVE,
    payload: {
      isActive,
    },
  };
}
function setIsVkn(isVkn) {
  return {
    type: informationTypes.SET_IS_VKN,
    payload: {
      isVkn,
    },
  };
}
function setIsFamilyFlow(isFamilyFlow) {
  return {
    type: informationTypes.SET_IS_FAMILY_FLOW,
    payload: {
      isFamilyFlow,
    },
  };
}

function setBreakOutFields(breakOutFields) {
  return {
    type: informationTypes.SET_BREAKOUT_FIELDS,
    payload: {
      breakOutFields,
    },
  };
}
const informationActions = {
  setVehicleDropDown,
  setVehicleInfo,
  setCustomerInfo,
  addInsured,
  updateInsured,
  setInsureds,
  removeInsured,
  setVehicleDetail,
  setNoClaimDiscount,
  setBirthDateInfo,
  setBirthDateValue,
  setOccupationInfo,
  setQuoteId,
  setCustomerQuotes,
  setKPSIsActive,
  setIsVkn,
  setIsFamilyFlow,
  setBreakOutFields,
};
export default informationActions;
