import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import loadingKlActions from '../../../actions/loadingActions';
import documentService from '../../../_services/document.service';
import customerService from '../../../_services/customer.service';

export const PdfDownload = (props) => {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
	const URL_PARAMS = new URLSearchParams(document.location.search);
  const teklifTID = URL_PARAMS.get('quotationId');
  const consumer = URL_PARAMS.get('consumer');
  let pdfType = URL_PARAMS.get('pdfType');
	var decodedConsumer = atob(consumer);
  const callback = (response) => {
    dispatch(loadingKlActions.hideKlLoading());
    const file = new Blob([response?.data], { type: 'application/pdf' });
    // Build a URL from the file
    const fileURL = URL.createObjectURL(file);
		const link = document.createElement('a');
    link.href = fileURL;
    link.setAttribute('download','poliçe.pdf',);
    document.body.appendChild(link);
    link.click();

    link.parentNode.removeChild(link);
    setData(fileURL);
  };

  useEffect(() => {
    dispatch(loadingKlActions.showKlLoadingEmpty());
		if(!pdfType){
			pdfType = 'policy'
		}
    switch (pdfType) {
			case 'policy':
				documentService.getPolicyPdf(teklifTID, callback, decodedConsumer);
				break;
			case 'user-aggrement':
        customerService.getUserAgreementDocument(callback, decodedConsumer);
        break;
			//if pdf download page required for other pdf types
			// case 'disclosure':
      //   documentService.getDisclosurePdf(teklifTID, quoteGuid, callback);
      //   break;
      // case 'quotation':
      //   documentService.getQuotationPdf(teklifTID, callback);
      //   break;
      // case 'receipt':
      //   documentService.getMakbuzPdf(teklifTID, callback);
      //   break;
      default:
    }
  }, []);

  return (
    <></>
  );
};

export default withRouter(PdfDownload);
