export const requestErrorList = [
    {
        id: "General_Error",
        text: "Bir hata oluştu."
    },
    {
        id: "Agency_Existing_Policy",
        text: "Aynı şirketten aynı üründe aynı poliçe numarası daha önce sisteme kaydedilmiştir."
    },
    {
        id: "Agency_Existing_Policy_Quote",
        text: "Bu teklife ait poliçe kaydı bulunmaktadır. Başka poliçe kaydedilemez."
    },
    {
        id: "Agency_CreatePolicy_ErrorPremiumDifference",
        text: "Girmiş olduğunuz prim tutarı hatalıdır. Tekrar kontrol ediniz."
    },
    {
        id: "Agency_NoDataFound_CreatePolicyError",
        text: "Girmiş olduğunuz poliçeye ait 111 kaydı yoktur."
    },
    {
        id: "Career_Error",
        text: "Sistemde sorun oluştu. Meslek bilgisi bulunamadı."
    },
    {
        id: "CascoQuote_Error",
        text: "Kasko teklifinde sorun oluştu."
    },
    {
        id: "CascoQuoteInstallment_Error",
        text: "Kasko taksitlendirmesinde sorun oluştu."
    },
    {
        id: "City_Error",
        text: "Sistemde sorun oluştu. İl bilgisi bulunamadı."
    },
    {
        id: "Connection_Error",
        text: "Bağlantı sağlanamıyor. Lütfen internet bağlantınızı kontrol edin. Bağlantı sağlandığında bu mesaj ototmatik olarak kapanacaktır."
    },
    {
        id: "EmptyInputField",
        text: "Kırmızı işaretli alanlara eksik ya da yanlış bilgi girmiş olabilirsiniz."
    },
    {
        id: "EmptyTcField",
        text: "Lütfen TC kimlik numaranızı ya da vergi numarınızı yazınız."
    },
    {
        id: "Existing_Policy_Agency",
        text: "Bu poliçe numarasına ait veri bulunamadı. Lütfen ilgili alanları doldurunuz."
    },
    {
        id: "Guarantees_Error",
        text: "Teminat bulunamadı."
    },
    {
        id: "Invalid_Asbis",
        text: "Hatalı asbis numarası  girdiniz."
    },
    {
        id: "Invalid_Plate",
        text: "Hatalı plaka girdiniz."
    },
    {
        id: "InvalidPhoneNumber",
        text: "Hatalı bir telefon numarası girdiniz."
    },
    {
        id: "Mail_Error",
        text: "Sistemde sorun oluştu. Mail gönderilemedi."
    },
    {
        id: "No_Guarantess",
        text: "Teminat bulunamadı."
    },
    {
        id: "NotSupportedUsageType_Commercial",
        text: "Ticari araç teklifleri için lütfen *PHONE_NUM* numaralı çağrı merkezimizi arayınız."
    },
    {
        id: "Personel_Error",
        text: "Sistemde sorun oluştu. Kişi bilgileri güncellenemedi."
    },
    {
        id: "PolicyPrint_Error",
        text: "Poliçe veya teklif görüntülemede sorun oluştu."
    },
    {
        id: "Register_Error",
        text: "Kişi bulunamadı."
    },
    {
        id: "Saved_Error",
        text: "Kayıt güncellenemedi."
    },
    {
        id: "System_Error",
        text: "Sistemde sorun oluştu."
    },
    {
        id: "TC_Error",
        text: "TC Kimlik numarası kaydı bulunamadı."
    },
    {
        id: "Wrong_TC_Error",
        text: "Lütfen uygun bir TC Kimlik veya Vergi Kimlik Numarası giriniz."
    },
    {
        id: "Death_Person",
        text: "İşlem yapmak istenilen kimlik bilgisinin durumu ÖLÜM'dür. İşleminize devam edemezsiniz."
    },
    {
        id: "Register_UserHasQuote",
        text: "Müşteri'ye ait teklif kaydı olduğu için müşteri bilgileri güncellenemez."
    },
    {
        id: "Town_Error",
        text: "Sistemde sorun oluştu. İlçe bilgisi bulunamadı."
    },
    {
        id: "TrafficQuote_Error",
        text: "Teklif yenilemesinde bir hata oluştu."
    },
    {
        id: "TrafficQuoteInstallment_Error",
        text: "Trafik taksitlendirmesinde bir hata oluştu."
    },
    {
        id: "TruckException",
        text: "Aracınızın kamyon olması sebebiyle, size özel fiyat çalışabilmemiz için lütfen *PHONE_NUM*‘ ten çağrı merkezimizi arayarak, sizin için uygun fiyatları öğrenin."
    },
    {
        id: "UncheckedRadioButton",
        text: "Ekrandaki tercihlerden birini seçmelisiniz."
    },
    {
        id: "UncheckedUserAgreement",
        text: "Devam edebilmek için kullanıcı sözleşmesini okumalı ve kabul etmelisiniz."
    },
    {
        id: "Vehicle_Error",
        text: "Sistemde sorun oluştu. Araç bilgisi bulunamadı."
    },
    {
        id: "VehicleNotFound_Error",
        text: "Seçtiğiniz marka ve yıl için model bulunamadı."
    },
    {
        id: "VehicleRenewal_Error",
        text: "Araç bilgilerinizi kontrol ederek yeniden deneyiniz."
    },
    {
        id: "Vergi_Error",
        text: "Vergi No ile kayıt bulunamadı."
    },
    {
        id: "Policy_Confirm_Fail",
        text: "Poliçe bilgileri doğrulanamadı."
    },
    {
        id: "VehicleNotFoundAnywhere_Error",
        text: "Araç bilgileri bulunamadı."
    },
    {
        id: "EGMResult_Error",
        text: "Aracınıza ait EGM kaydı bulunamadı."
    },
    {
        id: "OLDPolicyNotFound_Error",
        text: "Araca ait eski poliçe kaydı bulunamadı."
    },
    {
        id: "OLDPolicyNotFound_Error_FinancialLiabilityInsurance",
        text: "İMM Sigortası teklifi alabilmek için ilgili kişi ve araca ait yürürlükte trafik sigortası poliçesi olması gerekmektedir. Lütfen aracınız için önce zorunlu trafik sigortası yaptırınız."
    },
    {
        id: "Unavailable_Internet_Connection",
        text: "İnternet bağlantınız kesildi. Lütfen bağlantınızı kontrol ediniz."
    },
    {
        id: "Payment_Timeout",
        text: "Sigorta şirketi belirlenen süre içinde cevap veremedi. Lütfen mükerrer işlem olmaması adına *PHONE_NUM* numaralı çağrı merkezimizi arayıp işlemi teyit ettiriniz."
    },
    {
        id: "Payment_Error",
        text: "Bir hata oluştu. Lütfen *PHONE_NUM* numaralı çağrı merkezimizden bilgi alınız."
    },
    {
        id: "Unsupported_Installment",
        text: "Girmiş olduğunuz kart, seçili taksit seçeneğini desteklememektedir."
    },
    {
        id: "Wrong_Card_Number",
        text: "Kredi kartı numaranız hatalıdır."
    },
    {
        id: "Wrong_Card_Expiry",
        text: "Kredi kartı geçerlilik tarihi hatalıdır."
    },
    {
        id: "Type_Phone_Number",
        text: "Lütfen cep telefonu numaranızı yazınız."
    },
    {
        id: "Invalid_OTP_Verification",
        text: "Mobil onay kodunuz eşleşmiyor. Lütfen kontrol ediniz."
    },
    {
        id: "PhoneNumberCount_Error",
        text: "Girdiğiniz telefon numarasına kayıtlı birden çok poliçe bulunmaktadır. Lütfen farklı bir telefon numarası ile devam ediniz."
    },
    {
        id: "Fraud_Transaction_Error",
        text: "İlgili müşteri profili ile devam edilememektedir. Lütfen çağrı merkezimizi arayınız. *PHONE_NUM*"
    },
    {
        id: "BlackList_Error",
        text: "İlgili müşteri profili için online teklif verilememektedir. Lütfen çağrı merkezimizi arayınız. *PHONE_NUM*"
    },
    {
        id: "BlackListCallCenter_Error",
        text: "Dolandırıcılık vakası ile engellenmiş kişi"
    },
    {
        id: "Fraud_Policy_Notice_CC",
        text: "İlgili müşteri profili bilgileri, fraud poliçe olarak işaretlenmiştir."
    },
    {
        id: "Incorrect_Data_Usage_CC",
        text: "İlgili müşteri profili bilgileri, uygunsuz bilgiler kullanılması sonucu engellenmiştir. IT Birimine başvurunuz."
    },
    {
        id: "Check_Gen_Message_BlackList_CC",
        text: "İlgili müşteri profili bilgileri, sigorta şirketi tarafından engellenmiştir."
    },
    {
        id: "CCAttempt_CC",
        text: "İlgili müşteri profili bilgileri, tekrarlı hatalı kart girişi nedeniyle engellenmiştir."
    },
    {
        id: "Quotation_Already_Issued",
        text: "Aynı araca ait yürürlükte poliçeniz bulunmaktadır. Lütfen mükerrer işlem olmaması için *PHONE_NUM* numaralı çağrı merkezimizi arayın."
    },
    {
        id: "Black_List",
        text: "Poliçeyi tanzim etmek için lütfen çağrı merkezimizi arayınız. *PHONE_NUM*"
    },
    {
        id: "Quotation_Renewal_Problem",
        text: "Teklif yenilenemediği için poliçeleştirme başarısız oldu"
    },
    {
        id: "Price_Difference",
        text: "Teklif yenilenirken fiyat farkı oluştu, lütfen yeniden teklif alınız"
    },
    {
        id: "Debit_Card",
        text: "Debit kart(ATM Kartı) ile ödeme kabul edilmemektedir. Lütfen geçerli bir kredi kartı numarası giriniz."
    },
    {
        id: "Invalid_CreditCard_Username",
        text: "Lütfen kredi kartı sahibinin adının doğru olduğuna emin olunuz."
    },
    {
        id: "Summary_Page_Load_Error",
        text: "Sistemdeki mevcut bilgilerinizle teklif alınamıyor. Lütfen bilgilerinizi tekrar girerek, Koalay.com'un size özel yeni tekliflerini görün."
    },
    {
        id: "InvalidEmail",
        text: "Geçersiz bir email adresi girdiniz."
    },
    {
        id: "InvalidInformation",
        text: "Girdiğiniz bilgiler hatalıdır, lütfen kontrol ediniz."
    },
    {
        id: "PhoneIsBulking",
        text: "Girdiğiniz telefon numarası ile çok sayıda teklif alındığı için bu numaraya yeni teklif üretilemiyor. Lütfen farklı bir telefon numarası ile teklif alın. Telefonla destek almak için: 444 88 83."
    },
    {
        id: "AuxServiceNotAvailable",
        text: "Türkiye genelindeki tüm sigorta şirketlerinde yaşanan sistemsel bir sorundan dolayı şu an teklif verememekteyiz. Lütfen daha sonra yeniden deneyiniz."
    },
    {
        id: "Funnel_Fraud_Error",
        text: "Çok fazla sayıda deneme yaptınız. Lütfen daha sonra tekrar deneyin."
    },
];