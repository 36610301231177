import axiosInstance from '../../shared/_helpers/axiosHelper';
import { API } from '../../shared/_helpers/env';

async function getVehicleBrand(callback) {
  await axiosInstance.get(`${API}/api/vehicle/vehicle-brand`).then((response) => {
    try {
      callback(response.data.Result);
    } catch (error) {
      console.log(error);
    }
  });
}
async function getVehicleModelYear(vehicleBrand, callback) {
  await axiosInstance.post(`${API}/api/vehicle/vehicle-modelyear`, vehicleBrand).then((response) => {
    try {
      callback(response.data.Result);
    } catch (error) {
      console.log(error);
    }
  });
}
async function getVehicleModelByCode(vehicleBrand, callback) {
  await axiosInstance.post(`${API}/api/vehicle/vehicle-model-bycode`, vehicleBrand).then((response) => {
    try {
      callback(response.data.Result);
    } catch (error) {
      console.log(error);
    }
  });
}
async function getVehicleModel(vehicleModel, callback) {
  await axiosInstance.post(`${API}/api/vehicle/vehicle-model`, vehicleModel).then((response) => {
    try {
      callback(response.data.Result);
    } catch (error) {
      console.log(error);
    }
  });
}
async function vehicle(vehicleModel, callback) {
  await axiosInstance.post(`${API}/api/vehicle/vehicle`, vehicleModel).then((response) => {
    try {
      callback(response.data);
    } catch (error) {
      console.log(error);
    }
  });
}
async function vehiclePut(vehicleModel, callback) {
  await axiosInstance.put(`${API}/api/vehicle/vehicle`, vehicleModel).then((response) => {
    try {
      callback(response.data.Result);
    } catch (error) {
      console.log(error);
    }
  });
}
async function vehicleDetail(vehicleModel, callback) {
  await axiosInstance.post(`${API}/api/vehicle/vehicle-detail`, vehicleModel).then((response) => {
    try {
      callback(response.data);
    } catch (error) {
      console.log(error);
    }
  });
}
async function vehicleBreakout(vehicleModel, callback) {
  await axiosInstance.put(`${API}/api/vehicle/vehicle-breakout`, vehicleModel).then((response) => {
    try {
      callback(response.data.Result);
    } catch (error) {
      console.log(error);
    }
  });
}

async function vehicleCrm(crmInfo, callback) {
  await axiosInstance.post(`${API}/api/vehicle/vehicle-crm`, crmInfo).then((response) => {
    try {
    } catch (error) {
      console.log(error);
    }
  });
}
async function getVehicleUsageTypes(vehicleModel, callback) {
  await axiosInstance.post(`${API}/api/vehicle/vehicle-usagetype`, vehicleModel).then((response) => {
    try {
      callback(response.data.Result);
    } catch (error) {
      console.log(error);
    }
  });
}
async function getVehicleType(brandCode, modelCode, modelYear, callback) {
  await axiosInstance.get(`${API}/api/vehicle/vehicle-type?makeCode=${brandCode}&modelCode=${modelCode}&modelYear=${modelYear}`).then((response) => {
    try {
      callback(response.data.Result);
    } catch (error) {
      console.log(error);
    }
  });
}
async function getHasVehicleTypeChanged(brandCode, modelCode, modelYear, vehicleTypeCode, callback) {
  await axiosInstance.get(`${API}/api/vehicle/has-vehicle-type-changed?makeCode=${brandCode}&modelCode=${modelCode}&modelYear=${modelYear}&VehicleTypeCode=${vehicleTypeCode}`).then((response) => {
    try {
      callback(response.data.Result);
    } catch (error) {
      console.log(error);
    }
  });
}
async function getVehicleCascoValue(brandCode, modelCode, modelYear, callback) {
  await axiosInstance.get(`${API}/api/vehicle/vehicle-casco-value?makeCode=${brandCode}&modelCode=${modelCode}&modelYear=${modelYear}`).then((response) => {
    try {
      callback(response.data.Result);
    } catch (error) {
      console.log(error);
    }
  });
}
const vehicleService = {
  getVehicleBrand,
  getVehicleModelYear,
  getVehicleModel,
  vehicle,
  vehiclePut,
  vehicleDetail,
  vehicleCrm,
  vehicleBreakout,
  getVehicleModelByCode,
  getVehicleUsageTypes,
  getHasVehicleTypeChanged,
  getVehicleType,
  getVehicleCascoValue,
};
export default vehicleService;
