import React, { useState } from 'react';
import { Tooltip, styled, tooltipClasses } from '@mui/material';
import crossSVG from '../../../../../../shared/assets/img/svg/cross.svg';
import TickSVG from '../../../../../../shared/assets/img/svg/tick-green.svg';
import fileSVG from '../../../../../../shared/assets/img/svg/file.svg';
import '../../../../../../shared/assets/styles/components/popular-coverages-v2.scss';
import infoSVG from '../../../../../../shared/assets/img/svg/info.svg';
import CenteredModal from '../../../../components/widget/CenteredModal';
import useWindowSize from '../../../../../../shared/_hooks/windowResizeHook';
import { openPDF } from '../../../../../../shared/_helpers/helperFunctions';

const CascoPopularv2 = (props) => {
  const size = useWindowSize();
  const [hintPopup, showHintPopup] = useState(null);
  const [hintText, setHintText] = useState(null);

  const isMobile = size.width <= 880;
  const { TeklifTID, popularCoverages } = props;

  const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 13,
      },
    }),
  );

  const handleGetPdf = (e) => {
    e.preventDefault();
    const URL_PARAMS = new URLSearchParams(document.location.search);
    const qGuid = URL_PARAMS.get('qGuid');
    openPDF(TeklifTID, 'quotation', qGuid);
  };

  const setHintDesc = (coverage) => {
    setHintText(coverage.coverageText);
    showHintPopup(coverage.hintText);
  };
  const closeHintPopup = () => {
    setHintText(null);
    showHintPopup(null);
  };

  return (
    <>
      <div className="teminat-container-v2" key="teminat-container">
        <div className="teminat-inner">
          {popularCoverages.map((popular) => (
            <span className="teminat-detail">
              {popular.hasValue
                ? (<img className="coverage-icon" src={TickSVG} />)
                : (<img className="coverage-icon" src={crossSVG} />)}
              <span className="teminat-desc">
                <span className="coverage-text">
                  {popular.coverageText}
                  {popular.hintText && (
                  <LightTooltip
                    title={(
                      <span dangerouslySetInnerHTML={{ __html: popular.hintText }} />
										)}
                  >
                    <img
                      src={infoSVG}
                      style={{ marginLeft: '8px' }}
                      alt="Var"
                      onClick={() => (isMobile ? setHintDesc(popular) : '')}
                    />
                  </LightTooltip>
                  )}
                </span>
                <span className="coverage-value-text">
                  {popular.coverageId === 88 || popular.coverageId === 143 || popular.coverageId === 97
                    ? (popular.coverageValueText !== 'Var'
											&& popular.coverageValueText !== 'Yok'
                      ? popular.coverageValueText
                      : ''
                    )
                    : ('')}
                </span>
                {/* )} */}
              </span>
            </span>
          ))}
        </div>
        <div className="offer-show-pdf" onClick={(e) => handleGetPdf(e)}>
          <img src={fileSVG} alt="file" className="svg-icon" />
          <span>
            Teklif Dosyasını Görüntüle
          </span>
        </div>
      </div>
      {hintPopup !== null && (
      <CenteredModal title={hintText} isOpen={hintPopup !== null} setOpen={closeHintPopup}>
        <br />
        <>
          <p className="disclaimer">{hintPopup}</p>
        </>
      </CenteredModal>
      )}
    </>
  );
};

export default CascoPopularv2;
