import axiosInstance from '../../shared/_helpers/axiosHelper';
import { API } from '../../shared/_helpers/env';

async function getInstitutionType(data, callback) {
  await axiosInstance
    .get(`${API}/api/product/institution-type?productId=${data}`)
    .then((response) => {
      try {
        callback(response.data.Result);
      } catch (error) {
        console.log(error);
      }
    });
}

async function getContractedProviders(data, callback) {
  await axiosInstance
    .post(`${API}/api/product/tss/contracted-provider`, data)
    .then((response) => {
      try {
        callback(response.data.Result);
      } catch (error) {
        console.log(error);
      }
    });
}

async function getInsuranceCompanies(callback) {
  await axiosInstance
    .get(`${API}/api/product/insurance-companies`)
    .then((response) => {
      try {
        callback(response.data.Result);
      } catch (error) {
        console.log(error);
      }
    });
}

const productService = {
  getInstitutionType,
  getContractedProviders,
  getInsuranceCompanies,
};
export default productService;
