import React, { useEffect, useState } from "react";
import CoverageGroupButtons from "./CoverageGroupButtons";
import CoverageList from "./CoverageList";

export const ProductCoverages = (props) => {
  const [selectedCoverageGroup, setSelectedCoverageGroup] = useState("all");
  const [listedCoverages, setListedCoverages] = useState(null);
  const {
    quotationCoverageItem: { coverageGroups },
  } = props;

  useEffect(() => {
    if (selectedCoverageGroup === "all") {
      setListedCoverages(coverageGroups);
    } else {
      const tempCoverageGroup = coverageGroups.filter((coverageGroup) => {
        if (coverageGroup.coveregeGroupId === selectedCoverageGroup) {
          return true;
        }

        return false;
      });
      setListedCoverages([...tempCoverageGroup]);
    }
  }, [selectedCoverageGroup]);

  const handleCoverageGroupFilter = (coveregeGroupId) => {
    setSelectedCoverageGroup(coveregeGroupId);
  };

  return (
    <>
      <div className="policy-detail-product-coverages">
        <p className="product-coverages-title">Teminatlar</p>
        <CoverageGroupButtons
          coverageGroups={coverageGroups}
          selectedCoverageGroup={selectedCoverageGroup}
          handleCoverageGroupFilter={handleCoverageGroupFilter}
        />
        {listedCoverages && <CoverageList coverageGroups={listedCoverages} />}
      </div>
    </>
  );
};

export default ProductCoverages;
