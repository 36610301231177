/* eslint-disable implicit-arrow-linebreak */
import notShownList from '../../motor/constants/notShownCompanies';
import { setSearchString } from './redirect';

const URL_PARAMS = new URLSearchParams(document.location.search);
export const priceFormatter = new Intl.NumberFormat('tr-TR', {
  style: 'currency',
  currency: 'TRY',

  // These options are needed to round to whole numbers if that's what you want.
  // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const openPDF = (teklifTId, pdfType, qGuid) => {
  const jt = URL_PARAMS.get('jt');
  qGuid = qGuid || URL_PARAMS.get('qGuid');
	
	let pageName = window.location.pathname;
	const baseUrl = pageName.includes('tamamlayici-saglik-sigortasi') ? 'tamamlayici-saglik-sigortasi' : 'quotation'

  const url = `/${baseUrl}/openPDF?qGuid=${qGuid}${jt ? `&jt=${jt}` : ''}&teklifTID=${teklifTId}&pdfType=${pdfType}`;
  window.open(setSearchString(url));
};

export const getUsableLogo = (offer, shouldLogoChange = true) => {
  // TODO: gelen data sabit olmalı
  const { logoName, LogoName } = offer;
  let tempName = logoName || LogoName || 'koalay';
  if ((shouldLogoChange && notShownList.includes(tempName)) || typeof tempName !== 'string') {
    tempName = 'koalay';
  }
  return ({
    src: `https://static.koalay.com/Images/KoalayNew/InsurerLogosV2/${tempName.replace(/\s/g, '').toLowerCase().replaceAll('.', '')}.png`,
    alt: tempName,
  });
};

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = reject;
  });
}

//* Function to be used for clearing dataLayer for custom events.
export function removeCustomEventsFromDataLayer(dl) {
  const allCustomEvents = [
    'hk_kl_redirection_page',
    'result_page',
    'confirmation_page',
    'view_item_list',
    'vehicle_page',
    'vehicle_page_continue',
    'contact_info_page',
    'contact_permission',
    'continue_process',
    'previous_car_selected',
    'licence_page',
    'additional_step',
    'otp_page',
    'otp_verification',
    'select_item',
    'insurant_id',
    'insurant_id_continue',
    'insurant_info_page',
    'insurant_info_page_continue',
    'family_insurant_list',
    'family_insurant_list_continue',
    'insurance_coverage',
    'checkout_page',
    'payment_action',
    'thankyou_page'
  ];
  Object.values(dl).map((event, index) => {
    if (allCustomEvents.includes(event.event)) {
      dl.splice(index, 1);
    }
  });
  window.dataLayer.push({ funnel_variables: null });
}

export function parseOffersAndPushToDatalayer(offers, intervalCounter, dl) {
  const parsedOffers = [];
  let ecommerceObj = null;
  const capPriceRatios = offers.map((offer) => parseFloat(offer?.UnderCapPriceRatio?.replace('%', '')));
  const underCapPrice = Math.max(...capPriceRatios);
  ecommerceObj = {
    item_list_id: `offer_counts_${intervalCounter}`,
    item_list_name: `offer counts ${intervalCounter}`,
    items: parsedOffers,
    under_cap_price_ratio: `${underCapPrice}%`,
  };
  if (offers.length !== 0) {
    offers.map((offer, i) => parsedOffers.push({
      item_id: offer.ProductId,
      item_name: offer.Subtitle,
      discount: 0,
      index: i,
      item_brand: offer.Insurer,
      item_category: offer.Brans,
      item_list_id: `offer_counts_${intervalCounter}`,
      item_list_name: `offer counts ${intervalCounter}`,
      price: offer.Commission,
      premium: offer.Premium,
      under_cap_price_ratio_item: offer.UnderCapPriceRatio,
      quantity: 1,
    }));
    window.dataLayer.push({
      event: 'view_item_list',
      ecommerce: ecommerceObj,
    });
  }
}

export function parseTSSOffersAndPushToDatalayer(offers) {
  const parsedOffers = [];
  for (let offer of offers) {
    let offerObject = {
      product: offer.productDisplayName,
      insurance_brand: offer.insurerCommercialName,
      offer_amount: offer.commission,
      premium: offer.grossPremium
    }
    parsedOffers.push(offerObject)
  }
  window.dataLayer.push({
    event: 'offer_count',
    funnel_variables: {
      offer_details: parsedOffers
    }
  })
}

export function pushSelectItemEvent(offer, latestEvent, dl) {
  const parsedOffers = [];
  if (offer && offer !== undefined && offer !== null) {
    parsedOffers.push({
      item_id: offer.ProductId,
      item_name: offer.Subtitle,
      discount: 0,
      index: 0,
      item_brand: offer.Insurer,
      item_category: offer.Brans,
      item_list_id: `offer_counts_${latestEvent}`,
      item_list_name: `offer counts ${latestEvent}`,
      price: offer.Commission,
      premium: offer.Premium,
      quantity: 1,
    });
    localStorage.setItem('selectedOffer', JSON.stringify(parsedOffers));
		window.dataLayer.push({ items: null });
    window.dataLayer.push({
      event: 'select_item',
      ecommerce: {
        item_list_id: `offer_counts_${latestEvent}`,
        item_list_name: `offer counts ${latestEvent}`,
        items: parsedOffers,
      },
    });
  }
}

//* Converts number in format 5xxxxxxxxx into 05xx xxx xx xx
export function displayPhoneNumber(number) {
  if (number && (number[0] === '5' || number[0] === '2') && number.length === 10) {
    number = number.replace(/^/, '0');
    number = [number.slice(0, 4), ' ', number.slice(4)].join('');
    number = [number.slice(0, 8), ' ', number.slice(8)].join('');
    number = [number.slice(0, 11), ' ', number.slice(11)].join('');
  }
  return number;
}
