import { productTypes } from '../types/product.types';

const initialState = {
  onlineProductWebPartaj: {},
};

export default function PRODUCT_REDUCER(state = initialState, { type, payload }) {
  switch (type) {
    case productTypes.SET_ONLINE_PARTAJ:
      return {
        ...state,
        onlineProductWebPartaj: { ...payload.onlinePartaj },
      };
    default:
      return state;
  }
}
