import React, { useState } from 'react';
import './kvkk-info.scss';
import PersonalDataInfo from '../../../../motor/views/components/shared/PersonalDataProtection';
import InfoModal from '../../../../motor/views/components/widget/InfoModal';

export const KVKKInfo = (prop) => {
  const [isPersonalDataModalOpen, setIsPersonalDataModalOpen] = useState(false);

	
  return (
    <div className="kvkk-info-box">
      <span className="kvkk-info-text">
        Kişisel Verilerin Korunması Hakkında Aydınlatma Metni'ne
        {' '}
        <a style={{ cursor: 'pointer' }} onClick={() => setIsPersonalDataModalOpen(true)}>
          buradan
        </a>
        {' '}
        {' '}
        ulaşabilirsiniz.
      </span>
      <InfoModal
        isOpen={isPersonalDataModalOpen}
        closeModal={setIsPersonalDataModalOpen}
        title="6698 sayılı Kişisel Verilerin Korunması Kanunu Kapsamında Bilgilendirme"
      >
        <PersonalDataInfo />
      </InfoModal>
    </div>
  );
};

export default (KVKKInfo);
