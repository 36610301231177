// import { birthDateValidation, wordValidation } from '../../../shared/_helpers/validations';
import React from 'react';
import { Button } from '@mui/material';
import { KLInput } from '../../../motor/views/components/form/KLInput';
import { emailValidation, phoneValidation } from '../../../shared/_helpers/validations';
import fullWidthInputSx from '../fullWidthInputSx';
import tssButtonStyle from '../tssButtonStyle';
import KLCheckbox from '../../../shared/formComponents/KLCheckbox';
import BackButton from '../../views/components/BackButton/BackButton';
import store from '../../../shared/store/store';
import UserConsent from '../../views/components/UserConsent/userConsent.component';

const createContactInfoForm = (props) => {
  const {
    submitForm,
    userConsentObject,
    formHookObject: {
      handleSubmit,
      reset,
      control,
      errors,
      touchedFields,
      isValid,
			setValue,
    },
  } = props;

  const { insureds } = store.getState().info;
  const { isMerchant, isAgency } = store.getState().shell;
  const consumer = JSON.parse(store.getState().consumer.consumer);
  const currentInsured = insureds[insureds.length - 1];

  const formHookProps = {
    control,
    error: { ...errors },
    touchedFields,
    reset,
  };

  const formObject = {
    form: {
      phone: {
        type: 'input',
        component: <KLInput />,
        props: {
          name: 'phone',
          mask: '(599) 999 99 99',
          label: 'Telefon Numarası',
          sx: fullWidthInputSx,
          rules: phoneValidation(),
          disabled: isMerchant,
					onChanged: (value) => {
						if(value === ''){
							setValue('phone', undefined);
						}
					},
          ...formHookProps,
          defaultValue: isMerchant ? consumer?.referenceParams?.phone : currentInsured ? currentInsured.mobilePhone : null
        },
      },
      mail: {
        type: 'input',
        component: <KLInput />,
        props: {
          name: 'mail',
          label: 'E-posta Adresi',
          sx: fullWidthInputSx,
          rules: emailValidation(),
          ...formHookProps,
          defaultValue: currentInsured && currentInsured.email,
        },
      },
      userConsent: {
        type: 'component',
        component: <UserConsent />,
				show: !isAgency,
        props: {
          ...userConsentObject,
        },
      },
      button: {
        type: 'button',
        component: <Button />,
        props: {
          style: {
            ...tssButtonStyle,
          },
          disabled: !isValid,
          onClick: handleSubmit(submitForm),
          variant: 'contained',
        },
        children: 'Devam Et',
        hideMobile: true,
      },
      backbutton: {
        type: 'component',
        component: <BackButton />,
        hideMobile: true,
      },
    },
    bottom: {
      button: {
        type: 'button',
        component: <Button />,
        props: {
          style: {
            ...tssButtonStyle,
          },
          disabled: !isValid,
          onClick: handleSubmit(submitForm),
          variant: 'contained',
        },
        children: 'Devam Et',
        mobile: true,
      },
    },
  };

  return formObject;
};

export default createContactInfoForm;
