import React from 'react';
import { getRenevalDateString } from "../../shared/_helpers/vehicle/policy"

export const plainCasco = (isCascoRenewal) => (
    <p>
        Kasko poliçenizin süresi <strong>{isCascoRenewal?.replaceAll("-", ".")}</strong> tarihinde bitmektedir. Yenileme döneminiz bu tarihten <strong>30</strong> gün önce başlar. Yenileme dönemi dışında sigorta şirketlerinin kasko sigortası teklifi verip vermeme hakkı saklıdır. Yenileme tekliflerinizi <strong>{getRenevalDateString(isCascoRenewal, 30)}</strong> tarihinden itibaren görüntüleyebilirsiniz.
    </p>
)

export const dealerCasco = (isCascoRenewal) => (
    <p>
        Kasko poliçesinin süresi <strong>{isCascoRenewal?.replaceAll("-", ".")}</strong> tarihinde bitmektedir. Yenileme dönemi bu tarihten <strong>30</strong> gün önce başlar. Yenileme dönemi dışında sigorta şirketlerinin kasko sigortası teklifi verip vermeme hakkı saklıdır. Müşterinizin yenileme tekliflerini <strong>{getRenevalDateString(isCascoRenewal, 30)}</strong> tarihinden itibaren görüntüleyebilirsiniz.
    </p>
)

export const plainTraffic = (isTrafficRenewal) => (
    <p className='text'>
        Trafik poliçenizin süresi <strong>{isTrafficRenewal.replaceAll("-", ".")}</strong> tarihinde bitmektedir. Yenileme döneminiz bu tarihten <strong>15</strong> gün öncesinde başlar. Sigorta şirketleri yenileme dönemi dışında trafik sigortası teklifi vermemektedir. Yenileme tekliflerinizi <strong>{getRenevalDateString(isTrafficRenewal, 15)}</strong> tarihinden itibaren görüntüleyebilirsiniz.
    </p>
)

export const dealerTraffic = (isTrafficRenewal) => (
    <p className='text'>
        Trafik poliçesinin süresi <strong>{isTrafficRenewal.replaceAll("-", ".")}</strong> tarihinde bitmektedir. Yenileme dönemi bu tarihten <strong>15</strong> gün öncesinde başlar. Sigorta şirketleri yenileme dönemi dışında trafik sigortası teklifi vermemektedir. Müşterinizin yenileme tekliflerini <strong>{getRenevalDateString(isTrafficRenewal, 15)}</strong> tarihinden itibaren görüntüleyebilirsiniz.
    </p>
)
