import React, { useEffect } from 'react';
import '../../../../../shared/assets/styles/send-mail-modal.scss';
import './redirect-cc-box.scss';
import { useDispatch, useSelector } from 'react-redux';
import MerchantRedirectCC from '../components/MerchantRedirectCC';
import AgencyRedirectCC from '../components/AgencyRedirectCC';
import SelfServiceRedicrectCC from '../components/SelfServiceRedicrectCC';
import CallCenterRedirect from '../components/CallCenterRedirect';

import loadingKlActions from '../../../../../motor/actions/loadingActions';

export const RedirectCCBox = (props) => {
  const dispatch = useDispatch();
  const { consumer } = useSelector((state) => state.consumer);
  const { isAgency, isMerchant, isCallCenter } = useSelector((state) => state.shell);
  const isSSWL = JSON.parse(consumer).type === 'SelfServiceWL';

  useEffect(() => {
    dispatch(loadingKlActions.hideKlLoading());
  }, []);

  return (
    <>
      {(!isAgency && !isMerchant && !isCallCenter) && <SelfServiceRedicrectCC />}
      {(isMerchant || isSSWL) && <MerchantRedirectCC />}
      {isAgency && <AgencyRedirectCC />}
      {isCallCenter && <CallCenterRedirect />}
    </>
  );
};

export default RedirectCCBox;
