import React, { useState, useEffect, useMemo } from 'react';
import '../../../../shared/assets/styles/components/not-offer-insurer.scss';

import { getUsableLogo } from '../../../../shared/_helpers/helperFunctions';
import useWindowSize from '../../../../shared/_hooks/windowResizeHook';

export const NotOfferInsurer = ({ offer }) => {
  const size = useWindowSize();
  const isMobile = size.width <= 880;

  return (
    <span className="insurer-card">
      <img
        className="insurer-image"
        src={getUsableLogo(offer, false).src}
        alt={getUsableLogo(offer, false).alt}
        height={isMobile ? 39 : 64}
        width={isMobile ? 89 : 120}
      />
    </span>
  );
};

export default NotOfferInsurer;
