import React from 'react';

import classes from './contractedProviderListItem.module.scss';

const ContractedProviderListItem = (item) => (
  <div className={classes.container}>
    <p className={classes.name}>{item.item.name}</p>
    <p className={classes.text}>{item.item.address}</p>
    <p className={classes.text}>{item.item.phone}</p>
  </div>
);

export default ContractedProviderListItem;
