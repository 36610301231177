import React, { useState, useEffect } from 'react';
import {
  Grid, Button, Select, MenuItem, InputLabel, FormControl, Autocomplete, TextField, Paper,
} from '@mui/material';
import { useForm } from 'react-hook-form';

import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import useWindowSize from '../../../../../shared/_hooks/windowResizeHook';
import commonService from '../../../../_services/common.service';

import customerService from '../../../../_services/customer.service';
import toastrActions from '../../../../actions/toastrAction';
import loadingKlActions from '../../../../actions/loadingActions';
import { removeCustomEventsFromDataLayer } from '../../../../../shared/_helpers/helperFunctions';
import { setSearchString } from '../../../../../shared/_helpers/redirect';

export const CityDistrict = (props) => {
  const size = useWindowSize();
  const isMobile = size.width <= 880;
  const URL_PARAMS = new URLSearchParams(document.location.search);
  const uid = URL_PARAMS.get('jt');
  const extrefHash = URL_PARAMS.get('extrefHash');
  const [cities, setCityList] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedDistrictRow, setSelectedDistrictRow] = useState(null);
  const [selectedCityRow, setSelectedCityRow] = useState(null);
  const { quoteId, customer } = useSelector((state) => state.info);
  const { quotation } = useSelector((state) => state.shell);
  const { quotationUrl } = useSelector((state) => state.step);

  const dispatch = useDispatch();

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
  } = useForm({
    mode: 'onBlur',
  });
  const CustomPaper = (props) => (
    <Paper
      style={{
        fontSize: '13px',
        minWidth: isMobile ? '100%' : '368px',
      }}
      elevation={8}
      {...props}
    />
  );
  const districtSelect = (e, val) => {
    setSelectedDistrict(e.target.value);
    setSelectedDistrictRow(val);
  };

  const getDistricts = (e, val) => {
    setSelectedDistrict('');
    setSelectedDistrictRow(null);
    if (e.target.value) {
      const formattedValue = e.target.value.toLocaleString('tr-TR', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
      setSelectedCity(formattedValue);
      setSelectedCityRow(val);
      commonService.getDitrictsByCityId(formattedValue, (data) => {
        setDistricts(data.districts);
      });
    } else {
      setSelectedCity(null);
      setSelectedCityRow(null);
    }
  };

  useEffect(() => {
    removeCustomEventsFromDataLayer(window.dataLayer);
    window.dataLayer.push({
      event: 'additional_step',
      funnel_variables: {
        jt: uid,
      },
    });
    dispatch(loadingKlActions.showKlLoadingEmpty());
    commonService.getCityList((data) => {
      if (data.provinces) {
        const uniqueProvinces = [];
        const map = new Map();
        for (const province of data.provinces) {
          if (!map.has(province.code)) {
            map.set(province.code, true);
            uniqueProvinces.push({
              name: province.name,
              code: province.code,
            });
          }
        }
        setCityList(uniqueProvinces);
      }
    });
    dispatch(loadingKlActions.hideKlLoading());
  }, []);

  const onSubmit = () => {
    customerService.updateAPS(
      {
        UserGuid: uid,
        TcKimlikVergiNo: quotation.customer.tcKimlikVergiNo,
        MernisIlKodu: selectedCity,
        MernisIlAdi: cities.find((city) => city.code === selectedCity.toString()).name,
        MernisIlceKodu: selectedDistrict,
        MernisIlceAdi: districts.find((district) => district.code === selectedDistrict.toString()).name,
        Name: customer.firstname,
        Surname: customer.lastname,
        Sbn: quotation.customer.sbn,
        ExtRefId: quotation.customer.referenceCustomerUID,
        PhoneNumber: customer.phoneNumber,
        QuoteGUID: quoteId,
      },
      (resp, code) => {
        if (code === '0') {
          if (quotationUrl === '') {
            const url = '/quotation/summary';
            const searchString = setSearchString(url.indexOf('qGuid') >= 0 ? '' : `?jt=${uid}&qGuid=${quoteId}`);
            props.history.push({ pathname: url, search: searchString, state: { qGuid: quoteId } });
          } else {
            window.location.href = quotationUrl;
          }
        } else {
          dispatch(
            toastrActions.setToastrMessage({
              variant: 'error',
              message: 'İl ilçe bilgileri kaydedilirken bir hata oluştu.',
            }),
          );
        }
      },
    );
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12} item>
          <div>
            <h1>Lütfen il ve ilçenizi belirtiniz.</h1>
          </div>
          <div style={{ alignItems: 'center', display: 'flex', margin: '10px 0 8px 0' }}>
            <p className="text">
              Adres bilgilerinize ulaşamadık. Sigorta teklifleri adresinize bağlı olarak değişebilmektedir. Size özel en
              uygun teklifleri alabilmek için lütfen adresinizi seçin.
            </p>
          </div>
          <form>
            <div>
              <Autocomplete
                openOnFocus
                id="model-type-select"
                options={cities}
                PaperComponent={CustomPaper}
                getOptionLabel={(option) => option.name}
                noOptionsText="Sonuç bulunamadı"
                value={selectedCityRow}
                filterOptions={(x, e) => {
                  if (e.inputValue) {
                    return x.filter((elem) => elem.name.toLocaleLowerCase().includes(e.inputValue.toLocaleLowerCase()));
                  }
                  return x;
                }}
                renderOption={(props, option, { selected }) => (
                  <MenuItem {...props} value={option.code} key={option.name} data-text={option.name}>
                    {option.name}
                  </MenuItem>
                )}
                onChange={(event, value) => {
                  getDistricts(event, value);
                }}
                sx={{ width: 'auto' }}
                renderInput={(params) => <TextField {...params} label="İl" />}
              />
            </div>
            <div>
              <Autocomplete
                openOnFocus
                disabled={!selectedCity}
                id="model-type-select"
                options={districts}
                PaperComponent={CustomPaper}
                getOptionLabel={(option) => option.name}
                noOptionsText="Sonuç bulunamadı"
                value={selectedDistrictRow}
                filterOptions={(x, e) => {
                  if (e.inputValue) {
                    return x.filter((elem) => elem.name.toLocaleLowerCase().includes(e.inputValue.toLocaleLowerCase()));
                  }
                  return x;
                }}
                renderOption={(props, option, { selected }) => (
                  <MenuItem {...props} value={option.code} key={option.name} data-text={option.name}>
                    {option.name}
                  </MenuItem>
                )}
                onChange={(event, value) => {
                  districtSelect(event, value);
                }}
                sx={{ width: 'auto' }}
                renderInput={(params) => <TextField {...params} label="İlçe" />}
              />
            </div>
          </form>
          <div>
            {!isMobile ? (
              <Button
                variant="contained"
                disabled={!selectedDistrict}
                style={{
                  width: !isMobile ? '254px' : '100%',
                  height: '45px',
                  margin: '24px 7px',
                  boxSizing: 'border-box',
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
                  borderRadius: '5px',
                  textTransform: 'none',
                }}
                onClick={handleSubmit(onSubmit)}
              >
                Tekliflerimi Göster
              </Button>
            ) : (
              ''
            )}
          </div>
        </Grid>
      </Grid>
      {isMobile ? (
        <Button
          variant="contained"
          disabled={!selectedDistrict}
          style={{
            width: !isMobile ? '254px' : '100%',
            height: '55px',
            boxSizing: 'border-box',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
            borderRadius: '5px',
            textTransform: 'none',
            display: 'block',
            position: 'fixed',
            bottom: 0,
            left: 0,
            margin: '0 0 0 -20%',
          }}
          onClick={handleSubmit(onSubmit)}
        >
          Tekliflerimi Göster
        </Button>
      ) : (
        ''
      )}
    </>
  );
};

export default withRouter(CityDistrict);
