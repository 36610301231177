import React from 'react';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import createCityDistrictForm from '../../../../data/createForm/createCityDistrictForm';
import FormBuilder from '../../../../../shared/formBuilder/FormBuilder';
import useWindowSize from '../../../../../shared/_hooks/windowResizeHook';

export const CityDistrictForm = (props) => {
  const size = useWindowSize();
  const isMobile = size.width <= 880;

  const {
    formHookObject,
    submitForm,
		cityObject,
		districtObject,
  } = props;

  const CityDistrictFormObject = createCityDistrictForm({
    formHookObject, submitForm, cityObject, districtObject,
  });

  return (
    <>
      <form>
        <FormBuilder formBuilderObject={CityDistrictFormObject} submitForm={submitForm} />
      </form>
    </>
  );
};

export default withRouter(CityDistrictForm);
