export const processTypes = [
  {
    Text: 'Aracımın sigortasını yenilemek',
    Value: 20,
  },
  {
    Text: 'Yeni aracım için sigorta teklifi almak',
    Value: 30,
  },
];
