/* eslint-disable no-param-reassign */
import React, { useEffect, useState, useRef } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import infoSVG from "../../../../shared/assets/img/svg/info-darkgrey.svg";
import closeSVG from "../../../../shared/assets/img/svg/tss-xmark.svg";

import classes from "./Summary.module.scss";
import ProductCard from "./components/ProductCard/productCard.component";
import { generateQueueGuid } from "../../../../shared/_helpers/qguidGenerator";
import quotationService from "../../../_services/quotation.service";
import getQuotes from "../../../apiOperations/getQuotes";
import CenteredModal from "../../../../motor/views/components/widget/CenteredModal";
import PolicyDetail from "../PolicyDetail/PolicyDetail";
import informationActions from "../../../../motor/actions/informationAction";
import FilterSection from "./components/FilterSection/filterSection.component";
import { historyPushWithSearch } from "../../../../shared/_helpers/historyPushWithSearch";
import PageToTopButton from "../../../../shared/components/PageToTopButton/pageToTopButton.component";
import { checkIfSuggested, constructOfferArray, filterDecider } from "../../../helpers/filterHelpers";

import SkeletonProductCard from "./components/SkeletonProductCard/SkeletonProductCard.component";
import ProgressBar from "../../../../motor/views/components/ProgressBar";
import { tssLoadingLimit } from "../../../../motor/data/shared";
import loadingKlActions from "../../../../motor/actions/loadingActions";
import NoOffer from "./components/NoOffer/NoOffer.component";
import SkeletonFilterSection from "./components/SkeletonFilterSection/SkeletonFilterSection.component";
import { UserStates } from "../../../../motor/data/userStates";
import useUserState from "../../../../shared/_hooks/userStateHook";
import {
  parseTSSOffersAndPushToDatalayer,
  removeCustomEventsFromDataLayer,
} from "../../../../shared/_helpers/helperFunctions";
import userStateService from "../../../../motor/_services/userState.service";
import { WS } from "../../../../shared/_helpers/env";
import useWindowSize from "../../../../shared/_hooks/windowResizeHook";

const StompJs = require("@stomp/stompjs");

export const Summary = (props) => {
  const size = useWindowSize();
  const isMobile = size.width <= 880;
  const { insureds } = useSelector((state) => state.info);
  const { setUserState } = useUserState();
  const { show, isProgressBarActive } = useSelector((state) => state.loading);
  const [offersEnded, setOffersEnded] = useState(false);
  const [loadingCounter, setCounter] = useState(1);

  const [selectedOffer, setSelectedOffer] = useState(null);
  const [detailModal, setDetailModal] = useState(false);
  const filterSectionRef = useRef(null);
  const sessionHideInfo = sessionStorage.getItem("hideInfo");

  const [showInfo, setShowInfo] = useState(true);
  const [popularCoverages, setPopularCoverages] = useState([]);
  const [customerCity, setCustomerCity] = useState(null);

  //* Offer related states
  const [allOffers, setAllOffers] = useState([]);
  const offerRef = useRef(allOffers);
  offerRef.current = allOffers; //! Used in datalayer operations
  const [offersToBeRendered, setOffersToBeRendered] = useState(null);
  const [offers, setOffers] = useState(null);
  const [suggestedOffers, setSuggestedOffers] = useState(null);
  const [seeAllClicked, setSeeAllClicked] = useState(false);
  const [suggestedOffersClicked, setSuggestedOffersClicked] = useState(true);

  //* Filter operations
  const [networkTypes, setNetworkTypes] = useState(null);
  // ? These two are not necessary anymore, since we don't expect them to be really dynamic
  const [outpatientTreatmentOptions, setOutpatientTreatmentOptions] = useState(null);
  const [treatmentTypes, setTreatmentTypes] = useState(null);

  const qUniqueGuid = generateQueueGuid();
  const URL_PARAMS = new URLSearchParams(document.location.search);
  const qGuid = URL_PARAMS.get("qGuid");
  const jt = URL_PARAMS.get("jt");
  const queueName = `Koalay_TSS_${qGuid}_${qUniqueGuid}`;

  //* Product Types
  // Network Types
  const [yatarakTedavi, setYatarakTedavi] = useState([]);
  const [yatarakAyaktaTedavi, setYatarakAyaktaTedavi] = useState([]);
  const [yatarakAyaktaDogum, setYatarakAyaktaDogum] = useState([]);
  // Hospital Network
  const [kapsamli, setKapsamli] = useState([]);
  const [limitli, setLimitli] = useState([]);
  // Treatment Count
  const [oneToFive, setOneToFive] = useState([]);
  const [sixToTen, setSixToTen] = useState([]);

  const dispatch = useDispatch();

  const client = new StompJs.Client({
    brokerURL: WS,
    connectHeaders: {
      login: "koalay",
      passcode: "koalay",
    },
    heartbeatIncoming: 5000,
    heartbeatOutgoing: 5000,
    reconnectDelay: 10,
  });

  const headers = {
    "auto-delete": true,
    "x-expires": 3600000,
  };

  const quoteDetailReq = {
    TeklifUid: qGuid,
  };

  const subscribeToSocket = () => {
    client.activate();
    client.onConnect = function (frame) {
      client.subscribe(
        queueName,
        (message) => {
          if (message && message.body !== "") {
						const body = JSON.parse(message.body);

            try {
              body.map((offer) => {
                //* Filter operations:
                const filterTypes = filterDecider(offer);
                offer.filterTypes = filterTypes;
                switch (filterTypes.treatmentType) {
                  case 0:
                    setYatarakTedavi((prevState) => {
                      const updatedState = [...prevState, offer];
                      return updatedState.sort((a, b) => a.grossPremium - b.grossPremium);
                    });
                    break;
                  case 1:
                    setYatarakAyaktaTedavi((prevState) => {
                      const updatedState = [...prevState, offer];
                      return updatedState.sort((a, b) => a.grossPremium - b.grossPremium);
                    });
                    break;
                  case 2:
                    setYatarakAyaktaDogum((prevState) => {
                      const updatedState = [...prevState, offer];
                      return updatedState.sort((a, b) => a.grossPremium - b.grossPremium);
                    });
                    break;
                  default:
                    break;
                }
                switch (filterTypes.hospitalNetwork) {
                  case 0:
                    setKapsamli((prevState) => {
                      const updatedState = [...prevState, offer];
                      return updatedState.sort((a, b) => a.grossPremium - b.grossPremium);
                    });
                    break;
                  case 1:
                    setLimitli((prevState) => {
                      const updatedState = [...prevState, offer];
                      return updatedState.sort((a, b) => a.grossPremium - b.grossPremium);
                    });
                    break;
                  default:
                    break;
                }
                switch (filterTypes.treatmentCount) {
                  case 0:
                    setOneToFive((prevState) => {
                      const updatedState = [...prevState, offer];
                      return updatedState.sort((a, b) => a.grossPremium - b.grossPremium);
                    });
                    break;
                  case 1:
                    setSixToTen((prevState) => {
                      const updatedState = [...prevState, offer];
                      return updatedState.sort((a, b) => a.grossPremium - b.grossPremium);
                    });
                    break;
                  default:
                    break;
                }

                setAllOffers((prevState) => {
                  const updatedState = [...prevState, offer];
                  return updatedState.sort((a, b) => a.grossPremium - b.grossPremium);
                });
              });
            } catch (e) {
              console.log("error: ", e);
            }
          }
        },
        headers
      );
    };
  };

  useEffect(() => {
    const userSateData = {
      UserUID: jt,
      UserStateDefinition: UserStates.TSS_Teklif_Sayfasina_Gelen,
    };
    setUserState(userSateData);

    if (
      !Object.values(window.dataLayer)
        .map((element) => element.event)
        .includes("result_page")
    ) {
      removeCustomEventsFromDataLayer(window.dataLayer);
      window.dataLayer.push({
        event: "result_page",
      });
    }

    //* Scrolling to top on page open
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    if (detailModal) {
      document.body.style.overflowY = "hidden";
    } else {
      setTimeout(() => {
        document.body.style.overflowY = "auto";
      }, 200);
    }
  }, [detailModal]);

  //* #region useEffects
  useEffect(() => {
    dispatch(loadingKlActions.showKlLoadingDefault("tss"));
    quotationService.getFilterOptions({}, (response) => {
      setNetworkTypes(response.networkTypes);
      setOutpatientTreatmentOptions(response.outpatientTreatmentOptions);
      setTreatmentTypes(response.treatmentTypes);
    });
    quotationService.getQuotationDetail(quoteDetailReq, (response) => {
      if (insureds.length > 0) {
        response.popularMasterCoverages.map((pop) => {
          setPopularCoverages((prevState) => [...prevState, pop]);
        });
        const customerCity = { name: response.address.city.text, code: response.address.city.value };
        setCustomerCity(customerCity);
        getQuotes(dispatch, { QueueName: queueName }, (resp) => {
          if (resp.shouldRedirectCallCenter) {
            const userSateData = {
              UserUID: jt,
              UserStateDefinition: UserStates.TSS_Call_Center_Sayfasina_Yönlendirilen,
            };
            userStateService.setUserState(userSateData);
            historyPushWithSearch("/tamamlayici-saglik-sigortasi/RedirectCC", props.history);
          } else {
            subscribeToSocket();
          }
        });
      }
    });
  }, [insureds]);

  useEffect(() => {
    if (allOffers && allOffers.length > 1 && show) {
      dispatch(loadingKlActions.hideKlLoading());
      // setStartLoading(false);
      dispatch(loadingKlActions.setProgressBar(true));
    } else if (loadingCounter > 2 && allOffers && allOffers.length === 1 && show) {
      dispatch(loadingKlActions.hideKlLoading());
      // setStartLoading(false);
      dispatch(loadingKlActions.setProgressBar(true));
    }
  }, [allOffers]);

  useEffect(() => {
    // setOffersEnded(!show && !isProgressBarActive);
  }, [show, isProgressBarActive]);

  useEffect(() => {
    if (show && offers?.length > 3) {
      dispatch(loadingKlActions.hideKlLoading());
    }
  }, [offers]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (tssLoadingLimit < loadingCounter + 1) {
        dispatch(loadingKlActions.hideKlLoading());
        setOffersEnded(true);
        clearInterval(interval);
      } else {
        setCounter(loadingCounter + 1);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [loadingCounter]);

  useEffect(() => {
    const unblock = props.history.block((location, action) => {
      if (action === "POP" && detailModal && isMobile) {
        setDetailModal(false);
        return false;
      }
      return true;
    });

    return () => {
      unblock();
    };
  }, [props.history, detailModal, isMobile]);

  /* #region dataLayer operations */

  //* Sending for 10 seconds.
  useEffect(() => {
    setTimeout(() => {
      parseTSSOffersAndPushToDatalayer(offerRef.current);
    }, 10000);
  }, []);

  // //* Sending for 30 seconds.
  useEffect(() => {
    setTimeout(() => {
      parseTSSOffersAndPushToDatalayer(offerRef.current);
    }, 30000);
  }, []);

  // //* Sending for 50 seconds.
  useEffect(() => {
    setTimeout(() => {
      parseTSSOffersAndPushToDatalayer(offerRef.current);
    }, 50000);
  }, []);

  /* #endregion */

  const handleOfferDetailModal = (offer, isOpen) => {
    setSelectedOffer(offer);
    setDetailModal(isOpen);
  };

  //* Triggers when selected filters change, sets offers to be rendered
  useEffect(() => {
    if (offersToBeRendered) {
      const allEmpty = Object.values(offersToBeRendered).every((value) => value.length === 0);
      if (allEmpty) {
        setSeeAllClicked(true);
        return;
      }
      if (!seeAllClicked && checkIfSuggested(offersToBeRendered)) {
        setSuggestedOffersClicked(true);
      } else {
        setSuggestedOffersClicked(false);
      }
      let ttArr = [];
      let ntArr = [];
      let ooArr = [];
      if (offersToBeRendered.tt && offersToBeRendered.tt.length > 0) {
        if (offersToBeRendered.tt.includes(0)) {
          ttArr = ttArr.concat(yatarakTedavi);
        }
        if (offersToBeRendered.tt.includes(1)) {
          ttArr = ttArr.concat(yatarakAyaktaTedavi);
        }
        if (offersToBeRendered.tt.includes(2)) {
          ttArr = ttArr.concat(yatarakAyaktaDogum);
        }
      }
      if (offersToBeRendered.nt && offersToBeRendered.nt.length > 0) {
        if (offersToBeRendered.nt.includes(0)) {
          ntArr = ntArr.concat(kapsamli);
        }
        if (offersToBeRendered.nt.includes(1)) {
          ntArr = ntArr.concat(limitli);
        }
      }
      if (offersToBeRendered.oo && offersToBeRendered.oo.length > 0) {
        if (offersToBeRendered.oo.includes(0)) {
          ooArr = ooArr.concat(oneToFive);
        }
        if (offersToBeRendered.oo.includes(1)) {
          ooArr = ooArr.concat(sixToTen);
        }
      }
      if (!seeAllClicked) {
        const constructedOfferArray = constructOfferArray(ttArr, ntArr, ooArr);
        setOffers(constructedOfferArray);
      }
    }
  }, [
    allOffers,
    kapsamli,
    limitli,
    offersToBeRendered,
    oneToFive,
    seeAllClicked,
    sixToTen,
    yatarakAyaktaDogum,
    yatarakAyaktaTedavi,
    yatarakTedavi,
  ]);

  useEffect(() => {
    const constructedOfferArray = constructOfferArray(yatarakAyaktaTedavi, kapsamli, sixToTen);
    setSuggestedOffers(constructedOfferArray);
  }, [kapsamli, sixToTen, yatarakAyaktaTedavi]);

  //* Meaning user chose a filter
  useEffect(() => {
    if (offersToBeRendered) {
      const allEmpty = Object.values(offersToBeRendered).every((value) => value.length === 0);
      if (!allEmpty) {
        setSeeAllClicked(false);
      }
    }
  }, [offersToBeRendered]);
  //* #endregion

  const handleAllOffersClick = () => {
    filterSectionRef.current.setActiveTT([]);
    filterSectionRef.current.setActiveNT([]);
    filterSectionRef.current.setActiveOO([]);
    setSuggestedOffersClicked(false);
    setShowInfo(false);
    setSeeAllClicked(true);
  };

  const handleSuggestedOffersClick = () => {
    setOffers(suggestedOffers);
    filterSectionRef.current.setActiveTT([1]);
    filterSectionRef.current.setActiveNT([0]);
    filterSectionRef.current.setActiveOO([1]);
    setSuggestedOffersClicked(true);
    setShowInfo(true);
    setSeeAllClicked(false);
  };

  const handleCloseInfoClick = () => {
    setShowInfo(false);
    sessionStorage.setItem("hideInfo", "true");
  };

  return (
    <>
      {!show && <ProgressBar initialCounter={loadingCounter} type="tss" />}
      {(allOffers.length > 0 || !offersEnded) && (
        <div className={classes.wrapper}>
          <div className={classes.filterOptions}>
            {allOffers.length <= 0 && !offersEnded ? (
              <SkeletonFilterSection />
            ) : (
              networkTypes && (
                <FilterSection
                  ref={filterSectionRef}
                  yatarakTedavi={yatarakTedavi}
                  yatarakAyaktaTedavi={yatarakAyaktaTedavi}
                  yatarakAyaktaDogum={yatarakAyaktaDogum}
                  kapsamli={kapsamli}
                  limitli={limitli}
                  oneToFive={oneToFive}
                  sixToTen={sixToTen}
                  setOffersToBeRendered={setOffersToBeRendered}
                  setSeeAllClicked={setSeeAllClicked}
                  seeAllClicked={seeAllClicked}
                />
              )
            )}
          </div>
          <div className={classes.productCardsWrapper}>
            <div className={classes.heading}>
              <p>
                {!suggestedOffersClicked ? "Teklifler" : "Önerilen Teklifler"} (
                {seeAllClicked ? allOffers.length : offers ? offers.length : "0"})
              </p>
              <a onClick={!suggestedOffersClicked ? handleSuggestedOffersClick : handleAllOffersClick}>
                {!suggestedOffersClicked
                  ? `Önerilen teklifleri gör (${suggestedOffers ? suggestedOffers.length : "0"})`
                  : `Tüm teklifleri gör (${allOffers ? allOffers.length : "0"})`}
              </a>
            </div>
            {showInfo && !sessionHideInfo && !seeAllClicked && (
              <div className={classes.infoBox}>
                <img src={infoSVG} />
                <p>
                  Beklenmedik bir kaza veya hastalık durumunda sizi koruyacak, SGK anlaşmalı en iyi özel hastanelerde
                  geçerli teklifleri öneriyoruz.
                </p>
                <img src={closeSVG} className={classes.closeButton} onClick={handleCloseInfoClick} />
              </div>
            )}
            {(allOffers?.length > 0 || offersEnded) && seeAllClicked
              ? allOffers &&
                allOffers.map((offer) => (
                  <ProductCard
                    offer={offer}
                    popularCoverages={popularCoverages}
                    customerCity={customerCity}
                    handleOfferDetailModal={handleOfferDetailModal}
                  />
                ))
              : (offers?.length > 0 || offersEnded) &&
                offers &&
                offers.map((offer) => (
                  <ProductCard
                    offer={offer}
                    popularCoverages={popularCoverages}
                    customerCity={customerCity}
                    handleOfferDetailModal={handleOfferDetailModal}
                  />
                ))}
            {allOffers.length <= 0 &&
              !offersEnded &&
              new Array(3).fill().map(() => <SkeletonProductCard key={`no-offer-${Math.random()}`} />)}
          </div>
          {selectedOffer && (
            <CenteredModal className="policy-detail-modal" isOpen={detailModal} setOpen={setDetailModal}>
              <PolicyDetail offer={selectedOffer} />
            </CenteredModal>
          )}
          <PageToTopButton />
        </div>
      )}
      {allOffers.length <= 0 && offersEnded && <NoOffer />}
    </>
  );
};

export default withRouter(Summary);
