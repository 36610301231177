import {
  Box,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import EmptyState from '../../../../shared/assets/img/svg/my-policy/EmptyState.svg';
import '../../../../shared/assets/styles/pages/mypolicies-empty-page.scss';
import { policyTypes } from '../../../data/policyTypes';
import useWindowSize from '../../../../shared/_hooks/windowResizeHook';
import PolicyTypeCard from './PolicyTypeCard';
import headerActions from '../../../actions/headerAction';

export const MyPoliciesEmptyPage = (props) => {
  const dispatch = useDispatch();

  const size = useWindowSize();
  const isMobile = size.width <= 880;

  useEffect(() => {
    dispatch(headerActions.setUseHeader(true));
    dispatch(headerActions.setMainNav(true));
  }, [props]);

  return (
    <div className="mp-empty-container">
      <Box className="cancellation-container mp-empty-main-box">
        <img src={EmptyState} alt="" width={isMobile ? 150 : 200} />
        <h2 className="mp-empty-header">Aktif Poliçen Bulunmuyor</h2>
        <p>
          Koalay.com'dan satın alınmış bir poliçeniz bulunmamakta. Dilerseniz aşağıdaki sigorta ürünlerinden teklif alabilir, size özel karşılaştırmalı fiyatlarımızı görebilirsiniz.
        </p>
      </Box>
      <div className="mp-empty-policy-types">
        {policyTypes.map((policy) => (
          <div>
            <PolicyTypeCard key={policy.header} policy={policy} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default withRouter(MyPoliciesEmptyPage);
