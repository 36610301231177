import CCCrossSellSteps from '../data/ccCrossSellSteps';
import { ccCrossSellTypes } from '../types/ccCrossSell.type';

const initialState = {
  step: CCCrossSellSteps.Apply,
};

export default function CC_CROSS_SELL_REDUCER(state = initialState, { type, payload }) {
  switch (type) {
    case ccCrossSellTypes.SET_CC_CROSS_SELL_STEP:
      return {
        ...state,
        step: payload.step,
      };

    default:
      return state;
  }
}
