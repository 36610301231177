import axiosInstance from '../../shared/_helpers/axiosHelper';
import { API } from '../../shared/_helpers/env';

async function getOnlinePartaj(partaj, callback) {
  await axiosInstance.get(`${API}/api/product/onlinepartaj`, { params: { ...partaj } }).then((response) => {
    try {
      callback(response.data.Result);
    } catch (error) {
      console.log(error);
    }
  });
}

const productService = {
  getOnlinePartaj,
};
export default productService;
