import { Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';

export const KLCheckbox = (props) => {
  const combinedStyle = {
    margin: '0',
    display: 'flex',
    alignItems: 'center',
    ...props.style,
  };

  const {
    onChange,
    checked,
    ...otherProps
  } = props;

  return (
    <FormControlLabel
      style={combinedStyle}
      sx={{
        '& .MuiCheckbox-root': { alignSelf: 'center' },
      }}
      control={(
        <Checkbox
          onChange={() => onChange(!checked)}
          checked={checked}
          {...otherProps}
        />
      )}
      label={(
        <p className="checkbox-text" style={{ margin: '0px' }}>
          {props.label}
        </p>
    )}
    />
  );
};

export default KLCheckbox;
