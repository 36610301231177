import { Box, Grid } from '@mui/material';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';

import '../../../shared/assets/styles/components/main-box.scss';
import styled from '@emotion/styled';
import MainBox from './MainBox';
import useWindowSize from '../../../shared/_hooks/windowResizeHook';

export const StepsBox = ({ gap = 20, ...props }) => {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 880;
  const { hasFunnelName } = props;

  const StyledHeader = styled('h3')(({ theme, ownerState }) => ({
    color: theme.palette.primary.main,
    fontSize: '20px !important',
    fontWeight: '700',
  }));

  return (
    <MainBox gap={gap}>
      {hasFunnelName && (
        <StyledHeader>Tamamlayıcı Sağlık Sigortası</StyledHeader>
      )}
      {props.children}
    </MainBox>
  );
};

export default (StepsBox);
