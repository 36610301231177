import { loadingTypes } from '../types/loading.types';
import {
  agencyLoadingList, loadingList, loadingTitle, tssLoadingList,
} from '../data/shared';
import store from '../../shared/store/store';

function setKlContent(footerList, title) {
  return {
    type: loadingTypes,
    payload: {
      footerList,
      title,
    },
  };
}

function hideKlLoading() {
  return {
    type: loadingTypes.HIDE_KL_LOADING,
  };
}

function showKlLoading() {
  return {
    type: loadingTypes.SHOW_KL_LOADING,
  };
}

function showKlLoadingEmpty() {
  return {
    type: loadingTypes.SET_KL_LOADING_EMPTY,
  };
}

function setProgressBar(status) {
  return {
    type: loadingTypes.SET_PROGRESS_BAR_STATUS,
    payload: {
      status,
    },
  };
}

function showKlLoadingDefault(type = 'motor') {
  const consumer = JSON.parse(store.getState().consumer.consumer);
  const isAgency = consumer.isDealer === true && consumer.isAgency === true;
  const loadingTexts = type === 'tss' ? tssLoadingList : (isAgency ? agencyLoadingList : loadingList);

  return {
    type: loadingTypes.SET_KL_LOADING,
    payload: {
      footerList: loadingTexts,
      stepLoading: false,
      title: loadingTitle,
    },
  };
}

const loadingKlActions = {
  setKlContent,
  hideKlLoading,
  showKlLoading,
  showKlLoadingEmpty,
  showKlLoadingDefault,
  setProgressBar,
};

export default loadingKlActions;
