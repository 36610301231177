import React, { useState } from 'react';
import '../../../../../shared/assets/styles/send-mail-modal.scss';

import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import Support from '../../../../../shared/assets/img/svg/Support.svg';
import Done from '../../../../../shared/assets/img/svg/Done.svg';
import customerService from '../../../../../motor/_services/customer.service';
import tssButtonStyle from '../../../../data/tssButtonStyle';
import { MAIN_URL } from '../../../../../shared/_helpers/env';

export const CallCenterRedirect = (props) => {
  const [modalStep, setModalStep] = useState(1);
  const { consumer } = useSelector((state) => state.consumer);
  const { customer, insureds } = useSelector((state) => state.info);

  const onSubmit = (data) => {
    const selfInsured = insureds.find((insured) => insured.self === true);
    const customerPhone = data?.phone || customer?.PhoneNumber || customer?.telefon || selfInsured?.mobilePhone;
		const URL_PARAMS = new URLSearchParams(document.location.search);
		const qGuid = URL_PARAMS.get('qGuid');
    const contactData = {
      TeklifUid: qGuid,
      Sbn: JSON.parse(consumer).partnerSbn || 'KCDUTC',
      Phone: customerPhone,
    };
    customerService.contactScheduleCallTss(contactData, (resp) => {
      setModalStep(2);
    });
  };

  return (
    <div className="redirect-cc-container">
    {modalStep === 1 && (
        <div className="redirect-cc">
        <img src={Support} />
        <p>
            Aşağıdaki özelliklere sahip olan sigortalılar için web servis üzerinden teklif çalışamıyoruz. Acente ekranı üzerinden poliçe işleminize devam edebilirsiniz.
        </p>
        <ul style={{textAlign: 'left', width: '100%'}}>
            <li>Sadece çocuğunu sigortalatmak isteyenler</li>
            <li>70 yaştan büyükler</li>
            <li>Devam eden hamileliği olanlar</li>
            <li>Devam eden sağlık poliçesi olanlar</li>
            <li>Yenileme sağlık poliçeleri</li>
        </ul>
        </div>
    )}
    </div>
  );
};

export default CallCenterRedirect;
