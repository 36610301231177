import React from 'react';

export const IllnnesDeclarationText = (props) => (
  <div>
    <h3>
      <strong>
        TAMAMLAYICI SAĞLIK SİGORTASI SAĞLIK BEYANI
      </strong>
    </h3>
    <p>
      Sigortalının poliçe baslangıç tarihinden (bu poliçe için kabul edilen ilk
      kayıt tarihi) önce var olan sikayet ve hastalıkları ile ilgili her türlü
      saglık harcaması poliçe kapsamı dısındadır. Herhangi bir şikayet ile tedavi
      görülmediğini, şu anda mevcut rahatsızlık, tıbbi tedavi, ilaç kullanımı,
      doktora başvurulmamış olsa bile sağlık şikayeti olmadığını beyan ederim.
    </p>
    <ul style={{ marginBottom: '0in', marginTop: '0in' }} type="disc">
      <li>
        Her türlü kanser, iyi veya kötü huylu her türlü kitleler, meme
        hastalıkları, Rahim ve yumurtalık hastalığı ve Prostat ve Testis Hastalığı
      </li>
      <li>
        Kalp ve damar hastalıkları (venöz yetmezlik dahil, kalp kapak
        hastalıkları, hipertansiyon, vb.)
      </li>
      <li>
        Metabolik ve endokrin sistem hastalıkları (Diabetes mellitus (şeker),
        İnsülin Direnci, Glukoz ,cushing, obezite, Tiroit Bezi Hastalıkları
        (Guatr, Tiroit nodülleri, Tiroit kanserleri, Tiroit iltihapları), Hipofiz
        Bezi Hastalıkları
      </li>
      <li>
        Kronik karaciğer (örn.kronik hepatitler), safra, pancreas, dalak
        hastalıkları ve akciğer hastalıkları (KOAH , sarkoidoz vb.)
      </li>
      <li>
        Omurga ve vertebral kolon hastalıkları (bel fıtığı, boyun fıtığı, skolyoz
        vb.), sırt,bel,boyun ve diz hastalıkları
      </li>
      <li>
        Romatizmal hastalıklar, sistemik bağ dokusu hastalıkları (romatoid artrit,
        ankolizan spondilit, sistemik lupus eritematozus (SLE), skleroderma,
        sjögren sendromu, behçet, gut, psoriatik artrit vb.)
      </li>
      <li>
        Beyin ve sinir sistemi hastalıkları (inme, serebral palsi, epilepsi,
        kitleler, demiyelinizan hastalıklar, multiple skleroz vb.)
      </li>
      <li>
        Doğumsal ve genetik hastalıklar (ailevi akdeniz ateşi (FMF), kistik
        fibrozis, akdeniz anemisi, polikistik böbrek, kromozom anomalileri)
      </li>
      <li>
        Kan hastalıkları (hemofili, polisitemi, ITP, aplastik anemi vb.)
      </li>
      <li>
        Kas ve kemik hastalıkları (Kırık/Çıkıklar dahil)
      </li>
      <li>
        Organ nakiller, organ yetmezliliği, tek böbreklilik, tam işitme ve görme
        kaybı
      </li>
      <li>
        Kronik ilerleyici kas hastalıkları (myasteria, müsküler distrofileler vb.)
      </li>
      <li>
        Alzheimer, Parkinson, demans veya zeka geriliği ve davranış bozukluğu
      </li>
      <li>
        Ciddi psikolojik rahatsızlıklar (bipolar, şizofreni vb…)
      </li>
      <li>
        Bağırsak Hastalıkları
      </li>
      <li>
        Burun, Kafa Sinüs, Konka Hastalıkları,
      </li>
      <li>
        Deri ve/veya Lenf Bezi Hastalıkları,
      </li>
      <li>
        Göz Hastalıkları
      </li>
      <li>
        Tansiyon Hastalığı, Varis,&nbsp;
      </li>
      <li>
        Anorektal Hastalıklar (Basur, Fistül gibi),&nbsp;
      </li>
      <li>
        Böbrek ve İdrar Yolları Hastalıkları,&nbsp;
      </li>
      <li>
        Büyüme ve Gelişme Geriliği,
      </li>
      <li>
        Doğumsal, Kalıtsal Hastalıklar, Fıtıklar (Kasık, Göbek gibi),&nbsp;
      </li>
      <li>
        Kulak ve Denge Sistemi Hastalıkları,&nbsp;
      </li>
      <li>
        Omurga Hastalıkları (Boyun, Sırt ve Bel),
      </li>
      <li>
        Romatizmal Hastalık,&nbsp;
      </li>
      <li>
        Yemek Borusu, Mide ve Duodenum Hastalıkları,&nbsp;
      </li>
    </ul>
    <p>
      Bu belirtilen hastalıklar dışında her türlü hastalık, kaza durumu ile ilgili
      tedavi görmediğimi, ameliyat olmadığımı, hastanede yatmadığımı ve halen
      devam eden/planlanan bir tedavim olmadığını ya da beklediğim bir tetkik
      sonucum olmadığını onaylıyorum. &nbsp;
    </p>
    <p>
      SGK tarafından saglanan genel saglık sigortasının aktif olmadıgı durumda
      olusan saglık giderleri bu poliçe kapsamı dısındadır.
    </p>
  </div>
);

export default IllnnesDeclarationText;
