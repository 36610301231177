import React from 'react';
import './assets/styles/components/toastr.scss';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import MuiAlert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import Ok from './assets/img/svg/ok.svg';
import Info from './assets/img/svg/info2.svg';
import Delete from './assets/img/svg/delete.svg';

import toastrActions from '../motor/actions/toastrAction';
import useWindowSize from './_hooks/windowResizeHook';

function TransitionRight(props) {
  return <Slide {...props} direction="right" />;
}

export const Toastr = ({ }) => {
  const dispatch = useDispatch();
  const size = useWindowSize();
  const isMobile = size.width <= 880;
  const toastr = useSelector((state) => state.toastr);

  const Alert = React.forwardRef((props, ref) => <MuiAlert icon={<img className="toastr-icon" src={toastr.variant == 'success' ? Ok : Info} />} elevation={6} ref={ref} variant="filled" {...props} />);
  return (
    typeof toastr.message === 'string' ? (
      <Snackbar
        className={`toastr-${toastr.variant}-message toastr-message`}
        anchorOrigin={isMobile ? { vertical: 'top', horizontal: 'left' } : { vertical: 'bottom', horizontal: 'left' }}
        open={toastr.show}
        TransitionComponent={TransitionRight}
        autoHideDuration={15000}
        onClose={(event, reason) => {
          if (reason === 'timeout') {
            dispatch(toastrActions.hideToastr());
          }
        }}
      >
        <Alert sx={{ width: '100%' }}>
          {toastr.message}
          <img onClick={() => dispatch(toastrActions.hideToastr())} className="toastr-close" src={Delete} />
        </Alert>
      </Snackbar>
    ) : (
      !toastr.message ? (
        <Snackbar
          className={`toastr-${toastr.variant}-message toastr-message`}
          anchorOrigin={isMobile ? { vertical: 'top', horizontal: 'left' } : { vertical: 'bottom', horizontal: 'left' }}
          open={toastr.show}
          TransitionComponent={TransitionRight}
          autoHideDuration={15000}
          onClose={(event, reason) => {
            if (reason === 'timeout') {
              dispatch(toastrActions.hideToastr());
            }
          }}
        >
          <Alert sx={{ width: '100%' }}>
            Bir Hata Oluştu
            <img onClick={() => dispatch(toastrActions.hideToastr())} className="toastr-close" src={Delete} />
          </Alert>
        </Snackbar>
      ) : (
        toastr.message.map((msg, index) => (
          <Snackbar
            style={isMobile ? { top: index > 0 && `calc(${index}*100px)` } : { bottom: index > 0 && `calc(${index}*150px)` }}
            className={`toastr-${toastr.variant}-message toastr-message`}
            anchorOrigin={isMobile ? { vertical: 'top', horizontal: 'left' } : { vertical: 'bottom', horizontal: 'left' }}
            open={toastr.show}
            TransitionComponent={TransitionRight}
            autoHideDuration={15000}
            onClose={(event, reason) => {
              if (reason === 'timeout') {
                dispatch(toastrActions.hideToastr());
              }
            }}
          >
            <Alert sx={{ width: '100%' }}>
              {msg}
              <img onClick={() => dispatch(toastrActions.hideToastr())} className="toastr-close" src={Delete} />
            </Alert>
          </Snackbar>
        ))
      )
    )
  );
};

export default Toastr;
