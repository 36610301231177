export const API = process.env.REACT_APP_API;
export const { STATE_API } = process.env;
export const { DOCUMENT_API } = process.env;
export const {
  NODE_ENV, WS, TSS_WS, PAYMENT_URL, GA_ID, ROLLBAR_ENV, REACT_APP_URL,
} = process.env;
const { MAIN_URL: mainUrl } = process.env;
export const MAIN_URL = document.location.href.indexOf('hesapkurdu') >= 0 ? 'https://hesapkurdu.com' : mainUrl;
export const { DEALER_MAIN_URL } = process.env;
export const URL_PARAMS = new URLSearchParams(document.location.search);
export const ROLLBAR_TOKEN = 'b959f4c3490a4f5b9a1c9a43398a39b1';
