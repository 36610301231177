import React, { useEffect, useState } from 'react';

import { withRouter } from 'react-router-dom';
import { Box } from '@mui/system';
import {
  Button, Tab, Tabs, Tooltip, tooltipClasses,
} from '@mui/material';
import styled from '@emotion/styled';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import CloseIcon from '@mui/icons-material/Close';
import useWindowSize from '../../../../shared/_hooks/windowResizeHook';
import '../../../../shared/assets/styles/components/comparission.scss';
import { getUsableLogo, priceFormatter } from '../../../../shared/_helpers/helperFunctions';
import tick from '../../../../shared/assets/img/svg/tick-green.svg';
import cross from '../../../../shared/assets/img/svg/cross.svg';
import coverageService from '../../../_services/coverage.service';

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 13,
    },
  }),
);

export const Comparission = (props) => {
  const size = useWindowSize();
  const isMobile = size.width <= 880;
  const [selectedTab, setSelectedTab] = useState(0);
  const [coverageGroups, setCoverageGroups] = useState(null);

  useEffect(() => {
    const { devicePixelRatio } = window;
  }, []);

  const { comparissionList } = props;

  useEffect(() => {
    if (props.isComparissionModalOpen) {
      const itemArray = comparissionList.map((offer, i) => ({
        item_name: offer.Subtitle,
        item_brand: offer.Insurer,
        item_category: offer.Brans,
      }));
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: 'compare_offers',
        items: itemArray,
      });
    }
  }, [props.isComparissionModalOpen]);

  useEffect(() => {
    const comparission = [];
    comparissionList.map((offer, i) => {
      comparission.push(offer.TeklifTID);
    });
    coverageService.CompareCoverages(comparission, (response) => {
      setCoverageGroups(response.quotationCoverageItem.coverageGroups);
    });
  }, [props.comparissionList]);

  const handleChange = (event, newTab) => {
    event.preventDefault();
    setSelectedTab(newTab);
  };

  return (
    <>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          backgroundColor: 'white',
          height: isMobile ? '100%' : `calc(787px / ${devicePixelRatio} )`,
          width: isMobile ? '100%' : '995px',
          margin: isMobile ? '0' : `calc(100px / (2 * ${devicePixelRatio})) auto`,
        }}
        className="comparission-modal"
      >
        <div className="comparission-header">
          <div className="comparission-title">
            <h4 style={{ display: 'flex', alignItems: 'center' }}>
              <SyncAltIcon sx={{ mr: 1, fontSize: 20 }} />
              Karşılaştır
            </h4>
            <CloseIcon
              onClick={() => props.handleClose()}
              style={{
                width: isMobile ? '22px' : '26px',
                height: isMobile ? '22px' : '26px',
                position: 'relative',
                right: '25px',
              }}
            />
          </div>
          {comparissionList !== null && (
            <div className="offer-info">
              {comparissionList.map((offer) => (
                <div className="offer-container">
                  <div className="insurer-info">
                    <CloseIcon
                      onClick={() => props.addToComparissionList(offer)}
                      style={{
                        width: isMobile ? '22px' : '26px',
                        height: isMobile ? '22px' : '26px',
                        position: 'absolute',
                        top: '5px',
                        right: '5px',
                      }}
                    />
                    <img
                      src={getUsableLogo(offer).src}
                      alt={getUsableLogo(offer).alt}
                      height={isMobile ? 39 : 64}
                      width={isMobile ? 89 : 120}
                    />
                    {' '}
                    <div className="offer-title" dangerouslySetInnerHTML={{ __html: offer.Subtitle }} />
                    <div className="comparission-price">
                      {priceFormatter.format(offer.Premium).replace('₺', '')}
                      {' '}
                      TL
                    </div>
                  </div>
                  <Button
                    variant="outlined"
                    className="button-buy"
                    onClick={() => props.buyButtonClicked(offer, true)}
                    style={{ marginRight: isMobile ? '-7px' : '33px', width: isMobile ? '111px' : '254px' }}
                  >
                    Satın Al
                  </Button>
                </div>
              ))}
            </div>
          )}
        </div>
        {coverageGroups !== null && (
          <>
            <Tabs
              value={selectedTab}
              onChange={(e, index) => {
                handleChange(e, index);
              }}
              className="comparission-tabs-container"
              variant="scrollable"
              scrollButtons="none"
            >
              {coverageGroups.map((coverageGroup) => (
                <Tab label={coverageGroup.coveregeGroupName.toLocaleLowerCase()} className="tab-label" />
              ))}
            </Tabs>
            <div className="coverages-container" style={{ height: devicePixelRatio === 1.25 ? '307px' : '463px' }}>
              <div className="coverages">
                {coverageGroups[selectedTab].coverages.map((coverage) => (
                  <div className="coverage">
                    <div className="coverage-item">
                      <p className="disclaimer">{coverage.coverageName}</p>
                    </div>
                    <div
                      className="coverage-desc-container"
                      style={{
                        display: 'grid',
                        gridTemplateColumns: `repeat(${coverage.comparissonValue.length}, 1fr)`,
                      }}
                    >
                      {coverage.comparissonValue.map((comp) => (
                        <div className="coverage-description">
                          <p className="desc">
                            {comp.displayText === '' ? (
                              <img src={cross} />
                            ) : comp.displayText === 'Var' ? (
                              <img src={tick} />
                            ) : comp.displayText === 'Yok' ? (
                              <img src={cross} />
                            ) : (
                              comp.displayText
                            )}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </Box>
    </>
  );
};

export default withRouter(Comparission);
