import React from 'react';
import TrafficPopularv1 from './v1/TrafficPopularv1';
import CascoPopularv1 from './v1/CascoPopularv1';
import TrafficPopularv2 from './v2/TrafficPopularv2';
import CascoPopularv2 from './v2/CascoPopularv2';

const PopularCoverages = (props) => {
  const { offer, popularCoverages, totalCoverage } = props;
  return (
    <>
      {offer.Version === 1 && (
      <>
        {offer.Brans.toLowerCase() === 'trafik' && (
        <TrafficPopularv1 popularCoverages={popularCoverages} />
        )}
        {offer.Brans.toLowerCase() === 'kasko' && (
        <CascoPopularv1 popularCoverages={popularCoverages} totalCoverage={totalCoverage} offer={offer} TeklifTID={offer.TeklifTID}/>
        )}
      </>
      )}
      {offer.Version === 2 && (
      <>
        {offer.Brans.toLowerCase() === 'trafik' && (
        <TrafficPopularv2 popularCoverages={popularCoverages} />
        )}
        {offer.Brans.toLowerCase() === 'kasko' && (
        <CascoPopularv2 popularCoverages={popularCoverages} totalCoverage={totalCoverage} TeklifTID={offer.TeklifTID} />
        )}
      </>
      )}
    </>
  );
};

export default PopularCoverages;
