import React, { useState } from "react";

import { Skeleton } from "@mui/material";
import hospitalSVG from "../../../../../../shared/assets/img/svg/hospital-building.svg";

import classes from "./skeletonProductCard.module.scss";
import useWindowSize from "../../../../../../shared/_hooks/windowResizeHook";

const SkeletonProductCard = (props) => {
	const size = useWindowSize();
	const isMobile = size.width <= 880;

  return (
    <div className={classes.skeletonContainer}>
      <div className={classes.logoRow}>
        <div className={classes.imageContainer}>
          <Skeleton variant="rectangular" width={isMobile ? 85 : 170} height={isMobile ? 45 : 70} />
        </div>
        <div className={classes.premiumAndInstallment}>
          <Skeleton variant="rectangular" width={isMobile ? 85 : 150} height={isMobile ? 25 : 45} />
          <Skeleton variant="rectangular" width={isMobile ? 85 : 120} height={isMobile ? 15 : 30} />
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.features}>
          <div className={classes.feature}>
            <p className={classes.network}>
              <Skeleton variant="rectangular" width={isMobile ? 300 : 265} height={isMobile ? 30 : 25} />
              <Skeleton variant="rectangular" width={isMobile ? 300 : 265} height={isMobile ? 30 : 25} />
            </p>
          </div>
          <div className={classes.feature} style={{marginTop: '4px'}}>
            <Skeleton variant="rectangular" width={isMobile ? 300 : 265} height={isMobile ? 30 : 25} />
          </div>
        </div>
        <div className={classes.importantFeature}>
          <Skeleton variant="rectangular" width={isMobile ? 300 : 160} height={isMobile ? 20 : 30} />
          <Skeleton variant="rectangular" width={isMobile ? 300 : 160} height={isMobile ? 20 : 30} />
          <Skeleton variant="rectangular" width={isMobile ? 300 : 160} height={isMobile ? 20 : 30} />
        </div>
      </div>
      <div className={classes.bottomRow}>
        <div className={classes.hospitalNetworkButton} style={{marginTop:'20px'}}>
          <Skeleton variant="rectangular" width={isMobile ? 130 : 160} height={isMobile ? 20 : 25}/>
        </div>
        <div className={classes.buttonContainer}>
          <Skeleton variant="rectangular" width={isMobile ? 160 : 140} height={isMobile ? 45 : 45} />
          <Skeleton variant="rectangular" width={isMobile ? 160 : 140} height={isMobile ? 45 : 45}/>
        </div>
      </div>
    </div>
  );
};

export default SkeletonProductCard;
