import axiosInstance from '../../shared/_helpers/axiosHelper';
import { API } from '../../shared/_helpers/env';
import { interceptors } from '../../shared/_helpers/interceptors';

async function getQuotes(quoteModel, callback) {
  await axiosInstance
    .post(`${API}/api/quotation/quotes`, { Quotes: [...quoteModel], UseLastSequence: false })
    .then((response) => {
      try {
        callback(response.data.Result);
      } catch (error) {
        console.log(error);
      }
    });
}
async function preQuote(qGuid, forcePrepare, callback) {
  interceptors.setXConsumer();
  await axiosInstance
    .get(
      `${API}/api/quotation/pre-quotes?QuoteGuid=${qGuid}&IsOffline=false&ForcePrepare=${forcePrepare}&IsTraffic=true&IsCasco=true&IsOverrideBranchControls=false`,
    )
    .then((response) => {
      try {
        callback(response.data.Result);
      } catch (error) {
        console.log(error);
      }
    });
}
async function getQuotationFilters(callback) {
  interceptors.setXConsumer();
  await axiosInstance
    .get(
      `${API}/api/quotation/quotation-filter?functionalBranchId=1`,
    )
    .then((response) => {
      try {
        callback(response.data.Result);
      } catch (error) {
        console.log(error);
      }
    });
}
async function quote(quoteModel, callback) {
  await axiosInstance
    .post(`${API}/api/quotation/quote`, { ...quoteModel })
    .then((response) => {
      try {
        callback(response.data.Result);
      } catch (error) {
        console.log(error);
      }
    });
}
async function getQuotationDetail(quoteGuid, quoteId, callback) {
  await axiosInstance
    .get(`${API}/api/quotation/quotation-detail?quoteGuid=${quoteGuid}&quoteId=${quoteId}`)
    .then((response) => {
      try {
        callback(response.data.Result);
      } catch (error) {
        console.log(error);
      }
    });
}
async function quotationConfirm(quoteConfirmObject, callback) {
  await axiosInstance
    .post(`${API}/api/quotation/quotation-confirm`, { ...quoteConfirmObject })
    .then((response) => {
      try {
        callback(response.data.Result);
      } catch (error) {
        console.log(error);
      }
    });
}

async function sendPolicy(mailObject, callback) {
  mailObject.itemDownloadUrl = `${document.location.origin}/quotation/downloadPdf`;
  await axiosInstance
    .post(`${API}/api/quotation/send-policy-mail`, { ...mailObject })
    .then((response) => {
      try {
        callback(response.data.Result);
      } catch (error) {
        console.log(error);
      }
    });
}

async function getGenerateRedirectedQuote(jt, quoteId, SbnCode, functionBranchId, callback) {
  try {
    await axiosInstance
      .get(`${API}/api/quotation/generate-redirected-quote?UserId=${jt}&QuoteId=${quoteId}&FunctionalBranchId=${functionBranchId}&ChannelId=1${SbnCode ? `&SbnCode=${SbnCode}` : ''}`)
      .then((response) => {
        try {
          callback(response.data.Result, false);
        } catch (error) {
          console.log(error);
        }
      });
  } catch (error) {
    if (error.response.status === 500) {
      callback(null, true);
    }
  }
}

const quotationService = {
  getQuotes,
  preQuote,
  getQuotationFilters,
  quote,
  getQuotationDetail,
  quotationConfirm,
  sendPolicy,
  getGenerateRedirectedQuote,
};
export default quotationService;
