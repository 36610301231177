const sharedParams = {
  fontWeight: '600',
  fontSize: '14px',
  height: '32px',
  boxSizing: 'border-box',
  textTransform: 'none',
  borderRadius: '4px',
	width: 'auto',
	minWidth: 'max-content',
	boxShadow: 'none',
};

export const tssCoverageGroupButtonStyleContained = {
  ...sharedParams,
  backgroundColor: '#424F7B',
};

export const tssCoverageGroupButtonStyleOutlined = {
  ...sharedParams,
  border: '1px #606D93 solid',
  color: '#606D93',
  backgroundColor: '#FFF',
};
