import { Button } from '@mui/material';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { birthDateValidation, onlyTcknValidation } from '../../../../shared/_helpers/validations';
import { KLInput } from '../form/KLInput';
import '../../../../shared/assets/styles/components/discount.scss';
import { getUsableLogo } from '../../../../shared/_helpers/helperFunctions';
import useWindowSize from '../../../../shared/_hooks/windowResizeHook';
import EventBus from '../../../../shared/_helpers/EventBus';

export const KidDiscount = (props) => {
  const {
    handleSubmit,
    reset,
    formState: { errors, isValid, touchedFields },
    control,
  } = useForm({
    mode: 'onChange',
  });
  const size = useWindowSize();
  const isMobile = size.width <= 880;
  const updateOffer = (data) => {
    data.productId = props.offer.ProductId;
    data.queueNameState = props.queueNameState;
    EventBus.emit('updateKidOffer', data);
    props.setIsKidModalOpen(false);
  };

  return (
    <div className="kid-discount-container">
      <div className="discount-insurer-info">
        <img
          src={getUsableLogo(props.offer).src}
          alt={getUsableLogo(props.offer).alt}
          height={isMobile ? 45 : 64}
          width={isMobile ? 84 : 120}
        />
        <p className="text" dangerouslySetInnerHTML={{ __html: props.offer.Subtitle }} />
      </div>
      <div className="discount-description">
        <p className="kid-disclaimer">
          0-6 yaş aralığında çocuğunuz varsa %25’e varan indirimden faydalanmak için çocuğunuzun bilgilerini girin.
        </p>
      </div>
      <div className="discount-form">
        <form>
          <KLInput
            name="tckn"
            mask="99999999999"
            label="T.C. Numarası"
            placeholder="Çocuğunuzun T.C. Numarası"
            rules={onlyTcknValidation()}
            control={control}
            error={errors}
            sx={{
              '& .MuiOutlinedInput-root': {
                width: '100%',
                '&.Mui-error': {
                  width: '100%',
                },
              },
            }}
            touchedFields={touchedFields}
          />
          <KLInput
            name="birthdate"
            mask="99/99/9999"
            label="Doğum Tarihi"
            placeholder="GG/AA/YYYY"
            rules={birthDateValidation()}
            control={control}
            error={errors}
            touchedFields={touchedFields}
            sx={{
						  '& .MuiOutlinedInput-root': {
						    width: '100%',
						    '&.Mui-error': {
						      width: '100%',
						    },
						  },
            }}
          />
        </form>
      </div>
      <div className="discount-buttons">
        <Button variant="outlined" onClick={() => props.setIsKidModalOpen(false)}>
          Vazgeç
        </Button>
        <Button className="get-discount-button" variant="contained" onClick={handleSubmit(updateOffer)} disabled={!isValid}>
          İndirim Al
        </Button>
      </div>
    </div>
  );
};

export default KidDiscount;
