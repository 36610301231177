import { themeTypes } from '../types/theme.types';

const initialState = {
  consumerTheme: null,
};

export default function THEME_REDUCER(state = initialState, { type, payload }) {
  switch (type) {
    case themeTypes.SET_THEME:
      return {
        ...state,
        theme: payload.theme,
        additionalInfo: payload.additionalInfo,
      };
    default:
      return state;
  }
}
