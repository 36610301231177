/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState, useEffect } from 'react';
import { AppBar, Container } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { useHistory } from 'react-router';
import KoalayLogo from '../../../shared/assets/img/png/koalay.png';
import PhoneSVG from '../../../shared/assets/img/svg/phone.svg';
import filterSVG from '../../../shared/assets/img/svg/filter.svg';
import CengizSVG from '../../../shared/assets/img/svg/cengiz.svg';
import useWindowSize from '../../../shared/_hooks/windowResizeHook';
import EventBus from '../../../shared/_helpers/EventBus';
import Steps from '../components/Steps';
import { MAIN_URL, DEALER_MAIN_URL, REACT_APP_URL } from '../../../shared/_helpers/env';
import RedirectPage from '../pages/Steps/OptionalSteps/RedirectPage';
import MainNavDesktop from './Navbar/desktop';
import MainNavMobile from './Navbar/mobile';
import { displayPhoneNumber } from '../../../shared/_helpers/helperFunctions';

export const KLHeader = () => {
  const size = useWindowSize();
  const history = useHistory();
  const { step } = useSelector((state) => state.step);
  const {
    isAgency, isMerchant, isKoalaySS, isCallCenter,
  } = useSelector((state) => state.shell);
  const URL_PARAMS = new URLSearchParams(document.location.search);
  const extrefHash = URL_PARAMS.get('extrefhash') || URL_PARAMS.get('extRefHash') || URL_PARAMS.get('extrefHash');
  const agentCode = URL_PARAMS.get('agentCode') || URL_PARAMS.get('agentCode');
  const isTss = window.location.href.includes('tamamlayici-saglik-sigortasi')
  const noThemeSSWL = extrefHash === '85C591D25793827B' || extrefHash === 'A5D6230163C2C1FC' || extrefHash === '0473E3FBCF8C8947';
  const { useStepper, mainNav } = useSelector((state) => state.header);
  const pageName = window.location.pathname.split('/').pop();
  const hideInfoSection = window.location.pathname.includes('police-iptal-talebi');  
  // const hideInfoSection = true;
  const [showRedirect, setShowRedirect] = useState(!isKoalaySS && !noThemeSSWL && !isTss && document.location.href.indexOf('summary') >= 0 && window.location.search.split('&').pop() !== 'r');

  const mainURL = isAgency || isMerchant ? DEALER_MAIN_URL : (isCallCenter && document.location.search.includes('agentCode')) ? `${MAIN_URL}?agentCode=${agentCode}` : noThemeSSWL ? `${REACT_APP_URL}quotation/info?extrefHash=${extrefHash}` : MAIN_URL;
  const StyledTitle = styled('h1')(({ theme }) => ({
    color: theme.palette.primary.main,
  }));
  const { additionalInfo } = useSelector((state) => state.theme);
  const { consumer } = useSelector((state) => state.consumer);

	const isSSWL = JSON.parse(consumer).isWhiteLabel;

  const StyledAnchor = styled('a')(({ theme }) => ({
    color: isKoalaySS || isAgency || isMerchant || isTss || noThemeSSWL ? theme.palette.primary.main : '#6f6f6f !important',
    fontWeight: size.width > 880 ? 'bold' : 600,
  }));

  const StyledAnchorTSSMobile = styled('a')(({ theme }) => ({
    color: 'white !important',
    fontWeight: size.width > 880 ? 'bold' : 600,
  }));
  const openDrawer = () => {
    EventBus.emit('openDrawer');
  };

  const isRedirected = ['summary', 'confirmation', 'checkout', 'thankyou'].includes(
    document.location.pathname.split('/').pop(),
  );

  useEffect(() => {
    if (showRedirect) {
      document.body.style.overflowY = 'hidden';
    }
    setTimeout(() => {
      setShowRedirect(false);
      document.body.style.overflowY = 'auto';
    }, 5000);
  }, [showRedirect]);

  useEffect(() => {
    if (document.location.href.indexOf('summary') >= 0 && window.location.search.split('&').pop() !== 'r') {
      if (
        JSON.parse(consumer).partnerSbn !== null
        && JSON.parse(consumer).partnerSbn !== 'KCDUTC'
        && JSON.parse(consumer).partnerSbn === 'HSPKDLK'
        && !noThemeSSWL
        && !isTss
      ) {
        setShowRedirect(true);
      }
    } else if (
      JSON.parse(consumer).partnerSbn !== 'KCDUTC'
      && document.location.pathname.split('/').pop() === 'info'
      && JSON.parse(consumer).partnerSbn !== null
    ) {
      setShowRedirect(true);
    }
  }, [step]);

  if (size.width > 880) {
    return (
      <>
        {showRedirect && !isAgency && !isMerchant && <RedirectPage />}
        <AppBar
          position="static"
          style={{
            display: 'flex',
            height: '93px',
            // height: '220px',
            backgroundColor: '#FFF',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            borderRadius: '0px 0px 5px 5px',
            justifyContent: 'center',
            padding: '0 17px',
          }}
        >
          <Container
            style={{
              display: 'inherit',
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}
            maxWidth="false"
          >
            <div className="header-left">
              <a href={mainURL}>
                <img
                  style={{ width: '250px' }}
                  src={
                    mainNav
                      ? KoalayLogo
                      : isRedirected
                        ? KoalayLogo
                        : JSON.parse(consumer).partnerSbn !== 'HSPKDLK' || isTss
                          ? KoalayLogo
                          : additionalInfo.dealerLogo
                  }
                  alt={isKoalaySS ? 'koalay.com' : (isRedirected || isTss) ? 'koalay.com' : 'hesapkurdu.com'}
                />
              </a>
            </div>
            <div className="header-middle">
              {useStepper && <Steps className="steps" isHidden={false} />}
              {mainNav && <MainNavDesktop history={history} /> }
            </div>
            <span className="header-right" style={{ minWidth: '205px'}}>
              {!hideInfoSection && !isAgency && !isMerchant && (
                <>
                  {JSON.parse(consumer).partnerSbn !== 'KCDUTC'
                    && JSON.parse(consumer).partnerSbn !== 'HSPKDLK'
                    && JSON.parse(consumer).partnerSbn !== null 
                    && !noThemeSSWL && (
                      <img 
                        style={{ width: '150px', marginBottom: '5px' }} 
                        src={JSON.parse(consumer).partnerSbn === 'HSPKDLK'
                          ? KoalayLogo
                          : additionalInfo.dealerLogo} />
                  )}
									{isSSWL && (<span className="header-info-section" style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src={PhoneSVG}
                      alt=""
                      className="svg-icon"
                      style={{
                        filter: ''
                      }}
                    />
                    <StyledTitle style={{ paddingLeft: '13px' }}>
                      {' '}
                      <StyledAnchor
                         href={'tel:4448883'}
                        style={{ textDecoration: 'none', color: 'rgb(0, 74, 153)' }}
                      >
                        {'444 88 83'}
                      </StyledAnchor>
                    </StyledTitle>
                  </span>)}
                </>
              )}
            </span>
          </Container>
        </AppBar>
      </>
    );
  }
  return (
    <>
      {showRedirect && !isAgency && !isMerchant && <RedirectPage />}

      <AppBar
        style={{
          background: !isKoalaySS && !isAgency && !isMerchant && !isTss && !noThemeSSWL && 'white',
          display: 'grid',
          gridTemplateColumns: '1.5fr 3fr 1.5fr',
          height: '55px',
          alignItems: 'center',
          padding: '0 10px',
        }}
        position="static"
      >
        {((step !== 3 && !mainNav) || isTss) && <div />}
        {step === 3 && pageName !== 'confirmation' && !mainNav && !isTss && (
          <div>
            <span onClick={() => openDrawer()} role="button" style={{ display: 'flex', alignItems: 'center' }}>
              <img src={filterSVG} />
              {' '}
              <p className="desc" style={{ color: 'white' }}>
                Filtreler
              </p>
            </span>
          </div>
        )}
        {step === 3 && pageName === 'confirmation' && !mainNav && <div />}
        {mainNav && (
          <div>
            <MainNavMobile />
          </div>
        )}
        <div
          style={{
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            top: '3px',
            position: 'relative',
          }}
        >
          <a href={mainURL}>
            <img
              src={
                mainNav
                  ? CengizSVG
                  : isRedirected
                    ? CengizSVG
                    : additionalInfo?.dealerLogo?.split('/').pop() === 'Koalay.png'
                      ? CengizSVG
                      : (JSON.parse(consumer).partnerSbn === 'HSPKDLK' && isTss) || noThemeSSWL || isTss
                        ? CengizSVG
                        : additionalInfo.dealerLogo
              }
              alt={isKoalaySS ? 'koalay.com' : (isRedirected || isTss || noThemeSSWL) ? 'koalay.com' : 'hesapkurdu.com'}
              width={115}
            />
          </a>
        </div>
				{isSSWL && (
          <div style={{
            display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '4px',
          }}
          >
            <p className="desc" style={{ color: 'white', textWrap: 'nowrap' }}>
              {(isTss || noThemeSSWL) ? (
                <StyledAnchorTSSMobile href='tel:4448883' style={{ textDecoration: 'none'}}>
                {'444 88 83'}
              </StyledAnchorTSSMobile>
              ) : (
                <StyledAnchor href={'tel:4448883'} style={{ textDecoration: 'none', color: 'white' }}>
                {'444 88 83'}
              </StyledAnchor>
              )}
            </p>
            {(isTss || noThemeSSWL) ? (
              <StyledAnchorTSSMobile href='tel:4448883' style={{ textDecoration: 'none', display: 'flex' }}>
              <PhoneIcon width="20" height="20" />
            </StyledAnchorTSSMobile>
            ) : (
              <StyledAnchor href={'tel:4448883'} style={{ textDecoration: 'none', color: 'white', display: 'flex' }}>
              <PhoneIcon width="20" height="20" />
            </StyledAnchor>
            )}
          </div>
        )}
      </AppBar>
    </>
  );
};

export default KLHeader;
