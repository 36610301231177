import { realationsEnum } from '../../tss/data/relations';
import { convertBirthDatetoSlashed } from './convertBirthDate';

export const mapDetailInsuredsToRedux = (insureds, pageNameLower, address) => {
  let isFamilyFlow = false;
  const tempInsureds = insureds.map((insured) => {
    const tempInsured = insured;
    tempInsured.movingForward = true;
    tempInsured.birthDate = convertBirthDatetoSlashed(tempInsured.birthDate);
    if (insured.relation === realationsEnum.Kendisi) {
      tempInsured.self = true;
      tempInsured.approvedPolicy = true; // TODO: böyle mi olmalı
      tempInsured.CityDistrict = {
        cityCode: address.city.value,
        districtCode: address.district.value,
      };
    } else {
      isFamilyFlow = true;
    }

    if (!insured.relation) {
      tempInsured.isFull = false;
    } else {
      tempInsured.isFull = true;
    }

    return tempInsured;
  });

  tempInsureds.sort((insured) => {
    if (insured.self && insured.isFull) {
      return 1;
    }
    if (!insured.isFull) {
      return -1;
    }
    return 0;
  });

  if (pageNameLower !== '/tamamlayici-saglik-sigortasi/insureds') {
    tempInsureds[insureds.length - 1].movingForward = false;
  }
  const mappedData = {
    insureds: tempInsureds,
    isFamilyFlow,
  };
  return mappedData;
};
