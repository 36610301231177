import shellService from '../../motor/_services/shell.service';
import informationActions from '../../motor/actions/informationAction';

const getFunnelInfo = (dispatch, funnelId = 1, callBack) => {
  const URL_PARAMS = new URLSearchParams(document.location.search);
  const campaignSource = URL_PARAMS.get('campSrc') || null;
  const JT = URL_PARAMS.get('jt') || document.location.href.split('jt=')[1];

  shellService.getFunnelInfo(JT, campaignSource, funnelId, (data) => {
    dispatch(informationActions.setKPSIsActive(data.kpsBirthdateValidationEnabled));
    dispatch(informationActions.setIsVkn(data.quotation.customer.tcKimlikVergiNo.length < 11));
    if (callBack) {
      callBack(data);
    }
  });
};

export default getFunnelInfo;
