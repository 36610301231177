export const constructTreatmentTypeText = (coverageGroups) => {
  let result = '';
  for (let i = 0; i < coverageGroups.length; i++) {
    if (coverageGroups[i].coveregeGroupName === 'Diğer Teminatlar') {
      result = `${result} + ` + 'Diğer Teminatlar';
    } else if (result && result.includes('Tedavi')) {
      result = result.replace('Tedavi', '');
      result = result ? `${result} + ${coverageGroups[i].coveregeGroupName}` : coverageGroups[i].coveregeGroupName;
    } else {
      result = coverageGroups[i].coveregeGroupName;
    }
  }
  return result;
};

export const countStandingTreatment = (coverageGroups) => {
  let result = '-';
  const standingTreatment = coverageGroups.find((item) => item.coveregeGroupId === 13);
  if (standingTreatment) {
    const doctor = standingTreatment.coverages.find((coverage) => coverage.coverageId === 126);
    if (doctor) {
      result = doctor.displayText;
    }
  }
  return result;
};

export const returnCDNlink = (insurerName) => {
  const baseUrl = 'https://static.koalay.com/Images/KoalayNew/InsurerLogosV2/';
  if (insurerName === 'Türkiye' || insurerName === 'Türkiye Sigorta') {
    return `${baseUrl}Türkiyesigorta.png`;
  }
  return `${baseUrl}${insurerName.split(' ')[0]}.png`;
};
