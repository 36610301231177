import customerService from '../../motor/_services/customer.service';
import informationActions from '../../motor/actions/informationAction';
import loadingKlActions from '../../motor/actions/loadingActions';
import shellActions from '../../motor/actions/shellAction';
import { UserStates } from '../../motor/data/userStates';
import { showRequestError } from '../_helpers/errorToastr';

const getCustomerQuotes = (dispatch, funnelData, setUserState, callBack) => {
  const URL_PARAMS = new URLSearchParams(document.location.search);
  const JT = URL_PARAMS.get('jt') || document.location.href.split('jt=')[1];
  let quotationError = null;
  let QuotesState = null;

  customerService.customerQuotes(funnelData.quotation.customer.tcKimlikVergiNo, (customerQuotes) => {
    if (customerQuotes.errors) {
      quotationError = true;
      showRequestError(customerQuotes.errors, dispatch);
    } else if (customerQuotes.customerQuotes.length > 0) {
      dispatch(informationActions.setCustomerQuotes(customerQuotes));
      QuotesState = customerQuotes;
      quotationError = false;
    } else {
      const userSateData = {
        UserUID: JT,
        UserStateDefinition: UserStates.No_Continue_Process,
      };
      quotationError = false;
      setUserState(userSateData);
      QuotesState = customerQuotes;
    }

    if (quotationError === false) {
      dispatch(shellActions.setFunnelInfo(funnelData));
      dispatch(shellActions.setIsRenewal(funnelData.quotation.isVehicle));
      dispatch(shellActions.setQuotationInfo(funnelData.quotation));
      dispatch(informationActions.setCustomerInfo(funnelData.quotation.customer));
      dispatch(
        informationActions.setBirthDateInfo(
          funnelData.quotation.customer.referenceCustomerUID !== null
          && funnelData.quotation.customer.referenceCustomerUID !== ''
          && funnelData.quotation.customer.referenceCustomerUID !== undefined,
        ),
      );
      dispatch(loadingKlActions.hideKlLoading());
    }

    if (callBack) {
      callBack(customerQuotes, QuotesState);
    }
  });
};

export default getCustomerQuotes;
