import React from 'react';
import { useSelector } from 'react-redux';
import classes from './loading-spinner.module.scss';

const LoadingSpinner = () => (
  <div className={classes.spinnerContainer}>
    <div className={classes.ldsRoller}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
    <div>
      <p className={classes.loadingText}>Yükleniyor...</p>
    </div>
  </div>
);

export default LoadingSpinner;
