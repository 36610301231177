import React, { useState } from 'react';
import '../../../../shared/assets/styles/car-usage-type-selection.scss';

import {
  Button, FormControl, FormControlLabel, Radio, RadioGroup,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import useWindowSize from '../../../../shared/_hooks/windowResizeHook';
import vehicleService from '../../../_services/vehicle.services';
import loadingKlActions from '../../../actions/loadingActions';

export const CarUsageTypesModal = (props) => {
  const {
    carInfo, usageTypes, selectedUsageType, setIsCarTypeModalOpended,
  } = props;
  const size = useWindowSize();
  const dispatch = useDispatch();
  const isMobile = size.width <= 880;
  const URL_PARAMS = new URLSearchParams(document.location.search);
  const quoteGuid = URL_PARAMS.get('qGuid') !== null ? URL_PARAMS.get('qGuid') : props.location.state.quoteGuid;
  const jt = URL_PARAMS.get('jt') !== null ? URL_PARAMS.get('jt') : props.location.state.jt;
  const [value, setValue] = useState(selectedUsageType ? selectedUsageType.Value : null);

  const selectUsageType = (type) => {
    const vehicleObject = {
      quoteGuid,
      customerUID: jt,
      ProcessStep: carInfo.processStep,
      VehicleTarifeCode: value,
      VehicleUsedType: 0,
      VehicleBrandCode: carInfo.brandCode,
      VehicleModelYear: carInfo.year.toString(),
      ModelKodBirlesik: carInfo.modelCode,
      VehicleFuelType: parseInt(carInfo.fuelType),
      IsConfirmation: false,
    };
    dispatch(loadingKlActions.showKlLoadingEmpty());
    setIsCarTypeModalOpended(false);
    vehicleService.vehiclePut(vehicleObject, (response) => {
      const params = new URLSearchParams(window.location.search);
      params.set('qGuid', response.newTeklifGuid);
      const newSearchParams = params.toString();
      const { location } = window;
      const redirectUrl = `${location.origin + location.pathname}?${newSearchParams}`;
      window.location.href = redirectUrl;
    });
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div className="car-usage-type-select" style={{ height: isMobile ? '100%' : 'auto', top: isMobile ? 'unset' : `calc(120px / ${devicePixelRatio})` }}>
      <div className="car-usage-type-header">
        <p>Araç Tipi</p>
        <CloseIcon sx={{ color: '#003B73', fontSize: isMobile ? 22 : 26 }} onClick={() => setIsCarTypeModalOpended(false)} />
      </div>
      <div className="car-usage-types">
        <FormControl
          className="car-usage-type-buttons"
          style={{ height: isMobile ? '100%' : 'auto' }}
        >
          <RadioGroup
            name="radio-buttons-group"
            onChange={handleChange}
            value={value}
            row
          >
            {usageTypes.map((type) => (
              <FormControlLabel value={type.Value} control={<Radio />} label={type.Text} id={type.Value} key={type.Value} />
            ))}
          </RadioGroup>
        </FormControl>
      </div>
      <div className="apply-car-usage-type">
        <Button
          disabled={value === selectedUsageType.Value}
          variant="contained"
          onClick={selectUsageType}
        >
          Değiştir
        </Button>
      </div>
    </div>
  );
};

export default CarUsageTypesModal;
