import { loadingTypes } from "../types/loading.types";
import { loadingList, loadingTitle } from "../data/shared";

const initialState = {
    show: false,
    stepLoading: false,
    footerList: loadingList,
    title: loadingTitle,
    isProgressBarActive: false,
};

export default function KL_LOADING_REDUCER(state = initialState, { type, payload }) {
    switch (type) {
        case loadingTypes.SHOW_KL_LOADING:
            return {
                ...state,
                show: true,
            };
        case loadingTypes.HIDE_KL_LOADING:
            return {
                ...state,
                show: false,
            };
        case loadingTypes.SET_KL_LOADING:
            return {
                ...state,
                show: true,
                stepLoading: payload.stepLoading,
                footerList: payload.footerList,
                title: payload.title
            };
        case loadingTypes.SET_KL_LOADING_EMPTY:
            return {
                ...state,
                show: true,
                stepLoading: true,
                footerList: [],
                title: ''
            };
        case loadingTypes.SET_PROGRESS_BAR_STATUS:
            return {
                ...state,
                isProgressBarActive: payload.status
            };
        default:
            return state;
    }
}
