import React, { useEffect, useState } from "react";
import { Autocomplete, Button, FormControl, MenuItem, Paper, Popper, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { withRouter } from "react-router-dom";

import { useForm } from "react-hook-form";
import useWindowSize from "../../../../shared/_hooks/windowResizeHook";
import { KLInput } from "../../components/form/KLInput";
import { birthDateValidation, companyNameValidation, wordValidation } from "../../../../shared/_helpers/validations";
import { useDispatch, useSelector } from "react-redux";
import customerService from "../../../_services/customer.service";
import loadingKlActions from "../../../actions/loadingActions";
import vehicleService from "../../../_services/vehicle.services";
import { useRollbar } from "@rollbar/react";
import EditCarInfo from "./EditCarInfo";

export const EditPersonalInfo = (props) => {
	const {
    carInfo, usageTypes, selectedUsageType, setIsCarTypeModalOpended, processStep, isCallCenter
  } = props;

  const size = useWindowSize();
	const Rollbar = useRollbar();
  const isMobile = size.width <= 880;
  const { userInfo, setUserInfo } = props;
  const [isOptionChanged, setIsOptionChanged] = useState(false);
  const [editedCarInfo, setEditedCarInfo] = useState(carInfo);
  const [isCarInfoValid, setIsCarInfoValid] = useState(true);
	const { customer, isVkn } = useSelector((state) => state.info);
	const { quotation } = useSelector((state) => state.shell);

 //-------------------
  const dispatch = useDispatch();
	const URL_PARAMS = new URLSearchParams(document.location.search);
  const quoteGuid = URL_PARAMS.get('qGuid') !== null ? URL_PARAMS.get('qGuid') : props.location.state.quoteGuid;
  const jt = URL_PARAMS.get('jt') !== null ? URL_PARAMS.get('jt') : props.location.state.jt;
  const [selectedVehicleType, setSelectedVehicleType] = useState(
		carInfo.usageType ? usageTypes.find(type => type.Value === carInfo.usageType) :
		null
	);
//-------------------
  const {
    handleSubmit,
    reset,
    formState: { errors, isValid, touchedFields },
    control,
  } = useForm({
    mode: "onChange",
  });

	useEffect(() => {
		setIsCarInfoValid(editedCarInfo.modelCode && editedCarInfo.year && editedCarInfo.brandCode && editedCarInfo.usageType)
	}, [editedCarInfo])


	const selectUsageType = () => {

    const vehicleObject = {
      quoteGuid,
      customerUID: jt,
      ProcessStep: carInfo.processStep,
      VehicleTarifeCode: editedCarInfo.usageType,
      VehicleUsedType: 0,
      VehicleBrandCode: editedCarInfo.brandCode,
      VehicleModelYear: editedCarInfo.year.toString(),
      ModelKodBirlesik: editedCarInfo.modelCode,
      VehicleFuelType: parseInt(carInfo.fuelType),
      IsConfirmation: false,
    };
    vehicleService.vehiclePut(vehicleObject, (response) => {
      const params = new URLSearchParams(window.location.search);
      params.set('qGuid', response.newTeklifGuid);
      const newSearchParams = params.toString();
      const { location } = window;
      const redirectUrl = `${location.origin + location.pathname}?${newSearchParams}`;
			props.handleClose();
      window.location.href = redirectUrl;
    });
  };

  const style = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -40%)",
    width: 321,
    maxHeight: '650px',
    height: isMobile && '83vh',
    overflow: 'auto',
    bgcolor: "background.paper",
    textAlign: "center",
    background: "#FFFFFF",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    paddingBottom: "30px",
  };

	const vehicleInfoChange = (event, value, row) => {
		setIsOptionChanged(true);
  };

  const onSubmit = (customerData) => {
		// Rollbar.info(`EditPersonalInfo submitted jt:${jt} qGuid:${quoteGuid}`);
		const newCustomer = {
			...quotation.customer,
			...customer
		}
		const [day, month, year] = customerData.birthDate !== undefined ? customerData.birthDate.split('/') : [null, null, null];
		customerData.birthDate =  year !== null ? [year, month, day].join('-') : null, // TODO: sonunda , var çalışıyor mu ? 

		dispatch(loadingKlActions.showKlLoadingEmpty());
    setUserInfo(customerData);
		delete newCustomer.name
		delete newCustomer.surname
    newCustomer.Name = customerData.name;
    newCustomer.Surname = customerData.surname;
    newCustomer.birthDate = customerData.birthDate;
    newCustomer.fromStep1 = true;
    newCustomer.FunctionalBranchId= 1;
    newCustomer.ChannelId= 1;
    customerService.customer(newCustomer, () => {
			selectUsageType();
		});
  };

	const CustomPaper = (props) => (
		<Paper
			style={{
				fontSize: '13px',
				minWidth: '100%',
			}}
			elevation={8}
			{...props}
		/>
	);

  return (
    <>
      <Paper style={style} square>
        <CloseIcon
          onClick={props.handleClose}
          style={{
            width: isMobile ? "22px" : "26px",
            height: isMobile ? "22px" : "26px",
            position: "absolute",
            right: "10px",
            top: "10px",
            cursor: "pointer",
          }}
        />

        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "33px",
              flexDirection: "column",
							width: isMobile ? "254px" : "unset",
            }}
          >
						<>
							<h3 style={{ marginBottom: "20px" }}>Araç Bilgileri</h3>
							<EditCarInfo
								carInfo={carInfo}
								usageTypes={usageTypes}
								processStep={processStep}
								vehicleInfoChange={vehicleInfoChange}
								setEditedCarInfo={setEditedCarInfo}
								editedCarInfo={editedCarInfo}
								/>
						</>
            {!isVkn && (
							<>
						<h3 style={{ marginBottom: "20px" }}>Kişisel Bilgiler</h3>
            <form
              style={{
                width: isMobile ? "254px" : "unset",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div style={{
							width: '100%',
            	}}>
                <KLInput
                  name="name"
                  label="Ad"
                  control={control}
                  defaultValue={userInfo?.name}
                  error={errors}
                  touchedFields={touchedFields}
                  reset={reset}
									rules={wordValidation()}
                  onChanged={() => {
                    setIsOptionChanged(true);
                  }}
                  value={userInfo?.name}
                />
              </div>
              <div style={{
							width: '100%',
            	}}>
                <KLInput
                  name="surname"
                  label="Soyad"
                  control={control}
                  defaultValue={userInfo?.surname}
                  error={errors}
                  touchedFields={touchedFields}
                  reset={reset}
									rules={wordValidation()}
                  onChanged={() => {
                    setIsOptionChanged(true);
                  }}
                  value={userInfo?.surname}
                />
              </div>
              <div style={{
							width: '100%',
            	}}>
                <KLInput
                  name="birthDate"
                  mask="99/99/9999"
                  label="Doğum Tarihi"
                  placeholder="GG/AA/YYYY"
                  defaultValue={userInfo?.birthDate}
                  rules={birthDateValidation()}
                  control={control}
                  error={errors}
                  touchedFields={touchedFields}
                  onChanged={() => {
                    setIsOptionChanged(true);
                  }}
                  value={userInfo?.birthDate}
                />
              </div>
            </form>
						</>
						)}
						{isVkn && (
							<>
						<h3 style={{ marginBottom: "20px" }}>Kişisel Bilgiler</h3>
            <form
              style={{
                width: isMobile ? "254px" : "unset",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div style={{
							width: '100%',
            	}}>
                <KLInput
                  name="name"
                  label="Firma Adı"
                  control={control}
                  defaultValue={userInfo?.name}
                  error={errors}
                  touchedFields={touchedFields}
                  reset={reset}
									rules={companyNameValidation()}
                  onChanged={() => {
                    setIsOptionChanged(true);
                  }}
                  value={userInfo?.name}
                />
              </div>
            </form>
						</>
						)}
          </div>
          <Button
            variant="contained"
            disabled={!isOptionChanged || !isCarInfoValid}
            style={{
              width: !isMobile ? "254px" : "254px",
              height: "45px",
              boxSizing: "border-box",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.4)",
              borderRadius: "5px",
              textTransform: "none",
            }}
            onClick={handleSubmit(onSubmit)}
          >
            Bilgileri Güncelle
          </Button>
        </div>
      </Paper>
    </>
  );
};

export default withRouter(EditPersonalInfo);
