import styled from '@emotion/styled';
import React, { useEffect } from 'react';

const StyledAnchor = styled('a')(({ theme, ownerState }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'none',
}));
export const PersonalDataInfo = ({ }) => (
  <>
    <div>
      <p>
        Kullanıcılarımızın kişisel verilerini güvence altına almak, Koalay.com’un
        sunduğu hizmetin en önemli bileşenlerinden biridir. Koalay.com’un hizmet
        kalitesi standartlarına ek olarak, özel hayatın gizliliği, temel hak ve
        özgürlükler ile kişisel verilerin güvenliği artık kanunen de güvence
        altında.
      </p>
      <p>
        Koalay.com olarak sizi 6698 sayılı Kişisel Verilerin Korunması Kanunu
        hakkında Aydınlatmak isteriz.
      </p>
			<br />
      <p>
        <strong>
          Kişisel Verilerin Korunması Kanunu Hakkında Aydınlatma Metni
        </strong>
        <br />
      </p>
      <p>
        İşbu Aydınlatma Metni’nin amacı, Koalay Com Sigorta ve Reasürans Brokerliği
        Anonim Şirketi tarafından yönetilmekte olan
        {' '}
        <StyledAnchor href="https://www.koalay.com/" target="_blank" rel="noreferrer">
          https://www.koalay.com/
        </StyledAnchor>
        {' '}
        adresinde yer alan internet sitesinin kullanımı sırasında elde edilen ve
        üçüncü kişilerden alınan kişisel verilerin kullanımına ilişkin olarak
        {' '}
        <StyledAnchor
          href="https://www.mevzuat.gov.tr/mevzuat?MevzuatNo=6698&amp;MevzuatTur=1&amp;MevzuatTertip=5"
          target="_blank"
          rel="noreferrer"
        >
          6698 sayılı Kişisel Verilerin Korunması Kanunu
        </StyledAnchor>
        {' '}
        10. maddesi ile getirilen aydınlatma yükümlülüğünün yerine getirilmesidir.
        Koalay Com Sigorta ve Reasürans Brokerliği Anonim Şirketi, işbu Aydınlatma
        Metni hükümlerini dilediği zaman site üzerinden yayımlamak suretiyle
        güncelleyebilir ve değiştirebilir. Koalay.com Sigorta ve Reasürans
        Brokerliği Anonim Şirketi’nin yaptığı güncelleme ve değişiklikler site
        yayınlandığı tarihten itibaren geçerli olacaktır.
      </p>
      <p>
        <br />
        <strong>Veri Sorumlusu</strong>
        {' '}
        <br />
      </p>
      <p>
        07.04.2016 tarihinde Resmi Gazete’de yayınlanarak yürürlüğe giren
        {' '}
        <StyledAnchor
          href="https://www.mevzuat.gov.tr/mevzuat?MevzuatNo=6698&MevzuatTur=1&MevzuatTertip=5"
          target="_blank"
          rel="noreferrer"
        >
          6698 sayılı Kişisel Verilerin Korunması Kanunu
        </StyledAnchor>
        {' '}
        kapsamında şirketimiz Koalay Com Sigorta ve Reasürans Brokerliği Anonim
        Şirketi, Veri Sorumlusu sıfatına sahiptir. Kişisel Verilerin Korunması
        Kanunu uyarınca, kişisel verileriniz; Veri Sorumlusu olarak Koalay Com
        Sigorta ve Reasürans Brokerliği Anonim Şirketi tarafından aşağıda açıklanan
        kapsamda toplanacak ve işlenebilecektir.
      </p>
      <p>
        <br />
        <strong>Toplanan Kişisel Veriler</strong>
        {' '}
        <br />
      </p>
      <ol start="1" type="1">
        <li>
          <strong>Ad ve İletişim Bilgileri:</strong>
          {' '}
          Ad, soyadı, cep telefonu, ev
          telefonu, iş telefonu, adresi, e-posta adresi, fatura bilgileri,
          TC kimlik numarası, kimlik, ehliyet fotokopisi ve benzer diğer
          belgeler.
        </li>
        <li>
          <strong>Özel Nitelikli Kişisel Veri:</strong>
          {' '}
          Nüfus ve ehliyet gibi
          belgelerde bulunan bilgiler, verilen hizmet kapsamında sağlanan
          sigorta poliçesi bağlamında gerekli olabilecek, daha önceki
          sigortalılık durumuna ilişkin, mesleki durumuna ilişkin, sağlık
          durumuna ilişkin ve/veya sigortalanan değerlere ilişkin bilgiler.
        </li>
        <li>
          <strong>Görsel ve İşitsel Veri:</strong>
          Fotoğraf, ses kayıtları,
          sigortalanan değerlere ilişkin fotoğraflar.
        </li>
        <li>
          <strong>Kullanım Verileri:</strong>
          Çeşitli yazılım ve teknolojik
          araçlar vasıtasıyla cihazlarınızdan toplanan veriler dahil ancak
          bunlarla sınırlı olmamak üzere Koalay Com Sigorta ve Reasürans
          Brokerliği’ni veya Çağrı Merkezlerini arama nedenleriniz, ses kayıtları,
          incelenen sigorta poliçe ve teklifleri, son ziyaret tarihi, ziyaret
          edilen internet siteleri, görüntülenen sayfa sayısı, ziyaret
          süresi, hizmet kullanımı sırasında oluşan hatalar ve benzeri
          sorunlar.
        </li>
      </ol>
      <p>
        Müşteri ve/veya teklif alan olarak talep ettiğiniz işlemlerin
        gerçekleştirilebilmesi ve size uygun hizmetin sunulabilmesi için ihtiyaç
        duyulan kişisel verileriniz; web sitelerimiz üzerinden yapılmış olan
        dijital başvurular, çağrı merkezlerimiz, sosyal medya, müşteri görüşmeleri,
        sigorta şirketleri, Kimlik Paylaşım Sistemi, Adres Paylaşım Sistemi gibi
        kanallar aracılığıyla toplanmakta olup yasal süreler ve şartlar kapsamında
        saklanmaktadır. Elde ettiğimiz kişisel verileriniz yurtiçinde veya
        yurtdışında Koalay Com Sigorta ve Reasürans Brokerliği Anonim Şirketi ya da
        bağlı kuruluşlarının, alt kuruluşlarının veya işbirliği içinde bulunduğu
        hizmet servis sağlayıcılarının tesisi bulunan başka bir ülkede
        depolanabilir ve işbu Aydınlatma Metnindeki amaçlar doğrultusunda ve bu
        amaçlarla orantılı olarak işlenebilir. İşbu Aydınlatma Metni kapsamında
        toplanan kişisel verileriniz burada yer alan hükümlere ve verilerin
        depolandığı ve işlendiği ülkede yürürlükte olan mevzuat kapsamında ve
        öngörülen güvenlik önlemleri dâhilinde işlenecektir.
      </p>
      <p>
        <br />
        <strong>Kişisel verilerinizin işlenme amaçları ve hukuki sebepleri:</strong>
        <br />
      </p>
      <p>
        Kişisel verileriniz,
        {' '}
        <StyledAnchor
          href="https://www.mevzuat.gov.tr/mevzuat?MevzuatNo=5684&MevzuatTur=1&MevzuatTertip=5"
          target="_blank"
          rel="noreferrer"
        >
          Sigortacılık Kanunu
        </StyledAnchor>
        {' '}
        ve ilgili diğer mevzuat kapsamında ürün ve hizmetlerle ilgili olarak
        gerçekleştirilecek her türlü iş ve işlemde işlem sahibini ve muhatabını
        belirlemek üzere kimlik, adres, vergi numarası ve diğer bilgilerinizin
        kaydedilmesi, kağıt üzerinde veya elektronik ortamda gerçekleştirilecek iş
        ve işlemlere dayanak olacak bilgi ve belgelerin düzenlemesi, talep edilen
        sigorta türüne göre sigortalanacak değere ilişkin gerekli bilgi ve
        belgelerin toplanması, sigorta poliçesi yenileme ve zeyil işlemlerinin
        yapılabilmesi, prim ödemelerine ilişkin olası sorunların haber verilmesi,
        ihtiyaç veya hasar halinde gerekli süreçlerin yürütülmesine destek
        olunması, ilgili mevzuat uyarınca adli ve idari tüm yetkili mercilerce
        öngörülen bilgi saklama, raporlama ve bilgilendirme yükümlülüklerine
        uyulması ve aramızdaki sözleşmelerin gereğinin yerine getirilmesi, sizlere
        daha iyi bir hizmet sağlamak, istatistiki bilgi toplamak ve derlemek,
        rızanız olması halinde ürün/hizmet/reklam/kampanyalar hakkında haber
        verilebilmesi ve size özel kampanya ve faydalar hazırlayabilmesi ve
        kullandırılabilmesi, memnuniyetinizi ölçebilmek için anket yapılabilmesi,
        çağrı merkezi hizmetlerinin sunulabilmesi ve ticari faaliyetlerini
        geliştirmek amaçlarıyla tarafımızca kullanılabilecektir.
      </p>
      <p>
        Koalay Com Sigorta ve Reasürans Brokerliği ile iş ilişkisi içerisinde olan
        üçüncü gerçek veya tüzel kişiler ile yapılan sözleşmeler veya yürütülen
        faaliyetler ile yasal düzenlemelerden doğan yükümlülükler çerçevesinde
        hukuki ve ticari yükümlülüklerin gerçekleştirilmesi için Koalay Com Sigorta
        ve Reasürans Brokerliği tarafından iş ortağı/müşteri/tedarikçiler ile
        yapılan sözleşmelerden kaynaklanan yükümlülükleri ifa etme, hak tesis etme,
        hakları koruma, ticari ve hukuki değerlendirme süreçlerini yürütme, hukuki
        ve ticari risk analizleri yapma, hukuki uyum sürecini yürütme, mali işleri
        yürütme, yasal gereklilikleri yerine getirme, yetkili kurum, kuruluş ve
        mercilerin kararlarını yerine getirme, taleplerini cevaplama, amaçlarıyla
        6698 sayılı Kanun’un 5. ve 6. maddelerinde belirtilen kişisel veri işleme
        şartları ve amaçları dahilinde işlenecektir.
      </p>
      <p>
        <br />
        <strong>
          İşlenen Kişisel Verilerin Kimlere ve Hangi Amaçla Aktarılabileceği
        </strong>
        <br />
      </p>
      <p>
        Bu bilgiler grup şirketlerimiz, ana hissedarımız, doğrudan/dolaylı
        yurtiçi/yurtdışı iştiraklerimiz, faaliyetlerimizin gereği anlaşmalı
        olduğumuz kurumlar, iş ortaklarımız, denetim şirketleri, sigorta
        şirketleri, elektronik ileti gönderimi sağlayıcı firmaları, sigorta
        poliçesine ilişkin belgelerin ulaştırılabilmesi için posta ve kargo
        şirketleri, gerekli olduğu durumda ve ölçüde avukatları ile veya yasal bir
        zorunluluk gereği bu verileri talep etmeye yetkili olan kamu kurum veya
        kuruluşları, bunlarla sınırlı olmamak üzere ilgili diğer otoriteler ile
        paylaşabilecektir.
      </p>
      <p>
        Ayrıca, ilgili bilgiler 9 Ocak 2008 tarihli ve 26751 sayılı Resmi Gazete’de
        yayımlanan Suç Gelirlerinin Aklanmasının ve Terörün Finansmanının
        Önlenmesine Dair Tedbirler Hakkında Yönetmelik uyarınca kimlik doğrulaması
        gerçekleştirilmesi amacıyla doğrudan Koalay Com Sigorta ve Reasürans
        Brokerliği A.Ş. veya ilgili sigorta şirketi tarafından ödeme kuruluşlarıyla
        paylaşılabilecektir.
      </p>
      <p>
        Cihazınıza yerleştirilen çerezler aracılığıyla elde edilen kişisel
        verileriniz üçüncü kişiler ile işbu Aydınlatma Metni’de belirtilen kapsam
        ve amaçlarla paylaşılabilecektir.
      </p>
      <p>
        Koalay Com Sigorta ve Reasürans Brokerliği A.Ş. kişisel verileri yukarıda
        belirtilen kategoriler ve amaçlar dahilinde bu amaçlarla sınırlı ve
        orantılı olmak üzere yurt içinde üçüncü kişilere aktarabileceği gibi yurt
        dışına da aktarabilecektir.
      </p>
      <p>
        <br />
        <strong>Kişisel Verileri Saklama Süresi</strong>
        {' '}
        <br />
      </p>
      <p>
        Koalay Com Sigorta ve Reasürans Brokerliği, toplanan kişisel verileri,
        ilgili mevzuatta belirtilen veya işlendikleri amaç için gerekli olan azami
        süre ve kanuni zamanaşımı süresince saklayacaktır.
      </p>
      <p>
        <br />
        <strong>Veri Güvenliğine İlişkin Önlemlerimiz ve Taahhütlerimiz</strong>
        <br />
      </p>
      <p>
        Koalay Com Sigorta ve Reasürans Brokerliği Anonim Şirketi, kişisel verileri
        güvenli bir şekilde korumayı taahhüt eder. Kişisel verilerin hukuka aykırı
        olarak işlenmesini ve erişilmesini engellemek ve kişisel verilerin
        muhafazasını sağlamak amacıyla uygun güvenlik düzeyini temin etmeye yönelik
        teknik ve idari tedbirleri çeşitli yöntemler ve güvenlik teknolojileri
        kullanarak gerçekleştirilmektedir.
      </p>
      <p>
        Koalay Com Sigorta ve Reasürans Brokerliği Anonim Şirketi, elde ettiği
        kişisel verileri bu işbu Aydınlatma Metni ve 6698 Sayılı Kişisel Verilerin
        Korunması Kanunu hükümlerine aykırı olarak başkasına açıklamayacaktır ve
        işleme amacı dışında kullanmayacaktır. Koalay Com Sigorta ve Reasürans
        Brokerliği Anonim Şirketi, işbu Aydınlatma Metni uyarınca dış kaynak hizmet
        sağlayıcılarla Kullanıcılar’a ait kişisel verilerin paylaşılması durumunda,
        söz konusu dış kaynak tedarikçilerinin de işbu madde altında yer alan
        taahhütlere riayet etmeleri için gerekli uyarı ve denetim faaliyetlerini
        icra edeceğini beyan eder.
      </p>
      <p>
        Portal üzerinden başka uygulamalara link verilmesi halinde Koalay Com
        Sigorta ve Reasürans Brokerliği Anonim Şirketi, link verilen uygulamaların
        gizlilik politikaları ve içeriklerine yönelik herhangi bir sorumluluk
        taşımamaktadır.
      </p>
      <p>
        <br />
        <strong>
          6698 sayılı Kanun’un 11. Maddesi Kapsamında Sayılan Haklarınız
        </strong>
        <br />
      </p>
      <p>
        Kişisel veri sahipleri olarak, haklarınıza ilişkin taleplerinizi aşağıda
        düzenlenen yöntemlerle Koalay Com Sigorta ve Reasürans Brokerliği Anonim
        Şirketi’ne iletmeniz durumunda Koalay Com Sigorta ve Reasürans Brokerliği
        Anonim Şirketi talebin niteliğine göre talebi en kısa sürede ve en geç otuz
        gün içinde ücretsiz olarak sonuçlandıracaktır. Ancak, işlemin Şirket için
        ayrıca bir maliyeti gerektirmesi hâlinde, Kişisel Verileri Koruma Kurulu
        tarafından Veri Sorumlusuna Başvuru Usül ve Esasları Hakkında Tebliği’de
        belirlenen tarifedeki ücret alınabilir.
      </p>
      <p>
        Bu kapsamda dilediğiniz zaman Kanunun 11. Maddesi kapsamında Veri Sorumlusu
        olan Şirketimize başvurarak aşağıda belirtilen hakları kullanabilirsiniz.
      </p>
      <p>
        Buna göre kişisel verisi işlenen müşterilerimiz;
      </p>
      <ul type="disc">
        <li>
          Kendisine ait kişisel verilerin işlenip işlenmediğini öğrenme
        </li>
        <li>
          İşlenen kişisel verileri varsa bunlara ilişkin bilgi talep etme,
        </li>
        <li>
          Kişisel verilerin işlenme amacını ve bu verilerin amaca uygun
          kullanılıp kullanılmadığını öğrenme,
        </li>
        <li>
          Yurt içinde veya yurt dışında kişisel verilerinin aktarıldığı
          üçüncü kişileri bilme, kişisel verilerindeki hataların
          düzeltilmesini ve eğer aktarım yapılmışsa ilgili üçüncü kişiden bu
          düzeltmenin istenmesini talep etme,
        </li>
        <li>
          Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde
          bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin
          kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini
          isteme,
        </li>
        <li>
          Kişisel verilerin işlenmesini gerektiren sebeplerin ortadan
          kalkması ve yasal yükümlülük sürelerinin sona ermesi halinde bu
          verilerin silinmesini, yok edilmesini ya da anonim hale
          getirilmesini isteme ve eğer aktarım yapılmışsa bu talebin aktarılan
          üçüncü kişiye iletilmesini isteme,
        </li>
        <li>
          İşlenen verilerin neticesinde kişi ile ilintili olumsuz bir sonuç
          çıkmasına itiraz etme,
        </li>
        <li>
          Kanun’a aykırı veri işleme nedeniyle zararının ortaya çıkması
          halinde zararını yasalar çerçevesinde talep etme,
        </li>
      </ul>
      <p>
        haklarına sahiptirler.
      </p>
      <p>
        Yukarıda belirtilen haklarınızı kullanma ile ilgili talebinizi, Türkçe
        olarak; veri sorumlusu sıfatıyla Koalay Com Sigorta ve Reasürans
        Brokerliği’nin Merdivenköy Mahallesi, Bora Sokak Nidakule Göztepe Blok
        No:1-3 İç Kapı No:58/61 Kadıköy İstanbul adresine kimliğinizi tespit edici
        belgeler ile elden iletebilir, noter kanalıyla,
        {' '}
        <StyledAnchor href="mailto:koalay@hs03.kep.tr" target="_blank" rel="noreferrer">
          koalay@hs03.kep.tr
        </StyledAnchor>
        {' '}
        adresine kayıtlı elektronik posta ya da
        {' '}
        <StyledAnchor href="mailto:kvkk@koalay.com" target="_blank" rel="noreferrer">
          kvkk@koalay.com
        </StyledAnchor>
        {' '}
        adresine sistemlerimizde kayıtlı elektronik postanız aracılığı ile
        elektronik posta olarak veya Kişisel Verileri Koruma Kurumu tarafından
        belirlenen diğer yöntemler ile iletebilirsiniz.
      </p>
      <p>
        Koalay Com Sigorta ve Reasürans Brokerliği’nin cevap vermeden önce
        kimliğinizi doğrulama hakkı saklıdır.
      </p>
      <p>
        Başvurunuzda;
      </p>
      <ul type="disc">
        <li>
          Adınızın, soyadınızın ve başvuru yazılı ise imzanızın,
        </li>
        <li>
          Türkiye Cumhuriyeti vatandaşları için T.C. kimlik numaranızın,
          yabancı iseniz uyruğunuzun, pasaport numaranızın veya varsa kimlik
          numaranızın,
        </li>
        <li>
          Tebligata esas yerleşim yeri veya iş yeri adresinizin,
        </li>
        <li>
          Varsa bildirime esas elektronik posta adresi, telefon ve faks
          numaranızın,
        </li>
        <li>
          Talep konunuzun,
        </li>
      </ul>
      <p>
        bulunması zorunlu olup varsa konuya ilişkin bilgi ve belgelerin de
        başvuruya eklenmesi gerekmektedir.
      </p>
      <p>
        Koalay Com Sigorta ve Reasürans Brokerliği A.Ş.
        {' '}
        <br />
        Merdivenköy Mahallesi, Bora Sokak Nidakule Göztepe Blok No:1-3 İç Kapı
        No:58/61 Kadıköy
        {' '}
        <br />
        T: 0216 570 0876
        {' '}
        <br />
        F: 0216 570 0877
        {' '}
        <br />
        {' '}
        <StyledAnchor href="mailto:koalay@hs03.kep.tr" target="_blank" rel="noreferrer">koalay@hs03.kep.tr</StyledAnchor>
        {' '}
        <u />
      </p>
      <p>
        {' '}
        <StyledAnchor href="mailto:kvkk@koalay.com" target="_blank" rel="noreferrer">kvkk@koalay.com</StyledAnchor>
        {' '}
        {' '}
        <u />
      </p>
      <p>
        Erenköy V.D. 8370537896
        {' '}
        <br />
        Ticaret Sicil: İstanbul 749438
        {' '}
        <br />
        Mersis # 0837053789600017
        {' '}
        <br />
        Broker # 1166-18740
        {' '}
        <br />
        Broker Ruhsat Tarihi 29.11.2013
      </p>
    </div>
    <div className="clear" />
  </>
);

export default PersonalDataInfo;
