// import { birthDateValidation, wordValidation } from '../../../shared/_helpers/validations';
import React from 'react';
import { Button } from '@mui/material';
import { KLInput } from '../../../motor/views/components/form/KLInput';
import { heightValidation, weightValidation } from '../../../shared/_helpers/validations';
import fullWidthInputSx from '../fullWidthInputSx';
import tssButtonStyle from '../tssButtonStyle';
import KLCheckbox from '../../../shared/formComponents/KLCheckbox';
import KLAutoComplete from '../../../shared/formComponents/KLAutoComplete';
import UserAggrement from '../../views/components/UserAggrement/UserAggrement';
import KLRadioGroup from '../../../shared/formComponents/KLRadioGroup';
import { genders } from '../gender';
import { pregnancy } from '../pregnancy';
import { ongoingInsurance } from '../ongoingInsurance';
import BackButton from '../../views/components/BackButton/BackButton';

import PlusSvg from '../../../shared/assets/img/svg/plus.jsx';

const createCustomerInfoForm = (props) => {
  const {
    submitForm,
    occupationProps,
    genderProps,
    pregnancyProps,
    ongoingInsuranceProps,
    userAgreementProps,
    advanceToFamily,
    isFamilyFlow,
    formHookObject: {
      handleSubmit,
      reset,
      control,
      errors,
      touchedFields,
      isValid,
      showPregnancy,
    },
    currentInsured,
  } = props;

	const shouldFillForm = true;

  const formHookProps = {
    control,
    error: { ...errors },
    touchedFields,
    reset,
  };

  const formObject = {
    form: {
      name: {
        type: 'input',
        component: <KLInput />,
        props: {
          name: 'name',
          label: 'Sigortalanan',
          sx: fullWidthInputSx,
          ...formHookProps,
          disabled: true,
          defaultValue: `${currentInsured.name} ${currentInsured.lastName}`.toUpperCase(),
        },
      },
      height: {
        type: 'input',
        component: <KLInput />,
        props: {
          name: 'height',
          label: 'Boy (cm)',
          sx: fullWidthInputSx,
          rules: heightValidation(),
          ...formHookProps,
          defaultValue: shouldFillForm ? currentInsured.height : null,
        },
      },
      weight: {
        type: 'input',
        component: <KLInput />,
        props: {
          name: 'weight',
          label: 'Kilo (kg)',
          sx: fullWidthInputSx,
          rules: weightValidation(),
          ...formHookProps,
          defaultValue: shouldFillForm ? currentInsured.weight : null,
        },
      },
      occupation: {
        type: 'input',
        component: <KLAutoComplete />,
        props: {
          name: 'occupation',
          label: 'Meslek',
          sx: fullWidthInputSx,
          renderParams: {
            name: 'displayName',
            code: 'enumValue',
          },
          ...occupationProps,
        },
      },
      gender: {
        type: 'input',
        component: <KLRadioGroup />,
        props: {
          name: 'gender',
          label: 'Cinsiyet',
          sx: fullWidthInputSx,
          options: genders,
          ...genderProps,
        },
      },
      pregnancy: {
        type: 'input',
        component: <KLRadioGroup />,
        show: showPregnancy,
        props: {
          name: 'pregnancy',
          label: 'Devam eden hamilelik var mı?',
          sx: fullWidthInputSx,
          options: pregnancy,
          ...pregnancyProps,
        },
      },
      ongoingInsurance: {
        type: 'input',
        component: <KLRadioGroup />,
        props: {
          name: 'ongoingInsurance',
          label: 'Devam eden bir sağlık sigortanız var mı?',
          sx: fullWidthInputSx,
          options: ongoingInsurance,
          ...ongoingInsuranceProps,
        },
      },
      ebulletin: {
        type: 'input',
        component: <KLCheckbox />,
        show: !isFamilyFlow,
        props: {
          name: 'eBulletin',
          label: <UserAggrement />,
          ...userAgreementProps,
        },
      },
      addNew: {
        type: 'button',
        component: <Button />,
        props: {
          style: {
            ...tssButtonStyle,
          },
          disabled: !isValid,
          onClick: handleSubmit(advanceToFamily),
          variant: 'outlined',
        },
        show: !isFamilyFlow,
        children: <>
          Kaydet ve Aile Bireyi Ekle
          <PlusSvg color={isValid ? '#0055A5' : '#00000042'} style={{ marginLeft: '4px' }} />
                  </>,
        hideMobile: true,
      },
      button: {
        type: 'button',
        component: <Button />,
        props: {
          style: {
            ...tssButtonStyle,
          },
          disabled: !isValid,
          onClick: handleSubmit(submitForm),
          variant: 'contained',
        },
        children: isFamilyFlow ? 'Kaydet' : 'Teklif Al',
        hideMobile: true,
      },
      backbutton: {
        type: 'component',
        component: <BackButton />,
        hideMobile: true,
      },
    },
    bottom: {
      addNew: {
        type: 'button',
        component: <Button />,
        props: {
          style: {
            ...tssButtonStyle,
          },
          disabled: !isValid,
          onClick: handleSubmit(advanceToFamily),
          variant: 'outlined',
        },
        show: !isFamilyFlow,
        children: <>
          Kaydet ve Aile Bireyi Ekle
          <PlusSvg color={isValid ? '#0055A5' : '#00000042'} style={{ marginLeft: '4px' }} />
                  </>,
        mobile: true,
      },
      button: {
        type: 'button',
        component: <Button />,
        props: {
          style: {
            ...tssButtonStyle,
          },
          disabled: !isValid,
          onClick: handleSubmit(submitForm),
          variant: 'contained',
        },
        children: isFamilyFlow ? 'Kaydet' : 'Teklif Al',
        mobile: true,
      },
    },
  };

  return formObject;
};

export default createCustomerInfoForm;
