import React, { useState } from 'react';
import {
  Button, FormControl, InputLabel, MenuItem, Select,
} from '@mui/material';

import '../../../../../shared/assets/styles/components/price-diff.scss';
import 'react-credit-cards/es/styles-compiled.css';
import EventBus from '../../../../../shared/_helpers/EventBus';
import infoBlue from '../../../../../shared/assets/img/svg/infoBlue.svg';
import { priceFormatter } from '../../../../../shared/_helpers/helperFunctions';

export const PriceDiffModal = (props) => {
  const closePriceDiff = (e) => {
    EventBus.emit('closePriceDiff');
  };

  const { newPrice, newTeklifTID, cheaperInsurer } = props.priceDiffData;
  let parsedPrice;
  if (newPrice.includes(',')) {
    parsedPrice = newPrice.replaceAll(',', '.');
  }
  const { premium, goToSummary } = props;

  const isPriceHigher = newPrice > premium;

  return (
    <div className="price-diff-modal">
      <>
        <div className="price-diff-header">
          <img className="info svg-info" src={infoBlue} />
          <p>Seçtiğiniz Teklifin Fiyatı Değişti!</p>
        </div>
        <div className="price-diff-explanation">
          <p className="disclaimer">
            Poliçe tanzimi öncesi, sigorta şirketinden yaptığımız son sorgulamaya göre seçtiğiniz teklifin fiyatı değişmiştir.
          </p>
        </div>
        <div className="price-container">
          <span className="price-row">
            <p>Güncel Fiyat</p>
            <p className="price">{priceFormatter.format(parsedPrice || newPrice).replace('₺', '')}</p>
          </span>
          <span className="price-row">
            <p>Seçtiğiniz Andaki Fiyat</p>
            <p className="price">{priceFormatter.format(premium).replace('₺', '')}</p>
          </span>
          <span className="price-row">
            <p>Fark</p>
            <p className={`price ${isPriceHigher ? 'price-higher' : 'price-lower'}`}>
              {isPriceHigher ? '+ ' : '- '}
              {priceFormatter.format(Math.abs(parsedPrice ? premium - parsedPrice : premium - newPrice)).replace('₺', '')}
            </p>
          </span>
          {cheaperInsurer && (
          <span className="has-cheper-info">
            <img src={infoBlue} className="svg-icon" />
            <p className="disclaimer">
              Güncel teklifleriniz arasında daha uygun fiyatlı olanlar var
              {' '}
            </p>
          </span>
          )}
        </div>
        <div className="info-buttons">
          <Button variant="outlined" onClick={() => goToSummary()}>
            Güncel Teklifleri Karşılaştır
          </Button>
          <Button variant="contained" onClick={() => props.buyAction(newTeklifTID)}>
            Satın al
          </Button>
        </div>
      </>
    </div>
  );
};

export default PriceDiffModal;
