import { toastrTypes } from '../types/toastr.types';

function setToastrMessage(toastr) {
  return {
    type: toastrTypes.SHOW_TOASTR,
    payload: toastr,
  };
}

function hideToastr() {
  return {
    type: toastrTypes.HIDE_TOASTR,
  };
}

const toastrActions = {
  setToastrMessage,
  hideToastr,
};

export default toastrActions;
