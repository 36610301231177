import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router, Route, Switch,
} from 'react-router-dom';
import ReactGA from 'react-ga';
import CssBaseline from '@mui/material/CssBaseline';
import { ErrorBoundary, Provider } from '@rollbar/react';
import './App.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { GA_ID } from './shared/_helpers/env';

import KoalayLoading from './motor/views/components/KoalayLoading';
import Toastr from './shared/Toastr';
import { clientRollBarConfig } from '../config/rollbar';
import CookieModal from './motor/views/components/shared/Cookie';
import Info from './tss/views/pages/Info/Info';
import pageList from './tss/data/pageList';
import headerActions from './motor/actions/headerAction';
import ContactInfo from './tss/views/pages/ContactInfo/ContactInfo';
import CustomerInfo from './tss/views/pages/CustomerInfo/CustomerInfo';
import loadingKlActions from './motor/actions/loadingActions';
import getFunnelInfo from './shared/apiOperations/getFunnelInfo';
import informationActions from './motor/actions/informationAction';
import { historyPushWithSearch } from './shared/_helpers/historyPushWithSearch';
import { mapFunnelDataToCustomer } from './shared/_helpers/mapFunnelDataToCustomer';
import RedirectCC from './tss/views/pages/RedirectCC/RedirectCC';
import Summary from './tss/views/pages/Summary/Summary';
import Insureds from './tss/views/pages/Insureds/Insureds';
import quotationService from './tss/_services/quotation.service';
import { mapDetailInsuredsToRedux } from './shared/_helpers/mapDetailInsuredsToRedux';
import TSS_SEQUENCE from './tss/constants/sequence';
import stepActions from './motor/actions/stepAction';
import PdfView from './motor/views/pages/PdfPages/PdfView';
import CityDistrict from './tss/views/pages/CityDistrict/CityDistrict';
import { Helmet } from 'react-helmet';
import Checkout from './tss/views/pages/Checkout/Checkout';
import { Confirmation } from './tss/views/pages/Confirmation/Confirmation.component';
import Thankyou from './tss/views/pages/Thankyou/Thankyou';
import TagManager from 'react-gtm-module';
import { IllnnesDeclarationList } from './tss/views/pages/IllnnesDeclarationList/IllnnesDeclarationList';

ReactGA.initialize(GA_ID);
export const TSSApp = (props) => {
	const [completed, setCompleted] = useState(false);
  const { customer } = useSelector((state) => state.info);
  const history = useHistory();
  const URL_PARAMS = new URLSearchParams(document.location.search);
  const jt = URL_PARAMS.get('jt') || null;
  const qGuid = URL_PARAMS.get('qGuid') || null;

  const dispatch = useDispatch();

  const getPageObject = (pageName) => {
    let pageObject = Object.values(pageList).find((item) => {
      if (item.pageName.toLowerCase() === pageName.toLowerCase()) {
        return item;
      }
    });
    if (!pageObject) {
      pageObject = pageList.default;
      pageObject.pageName = pageName;
    }
    return pageObject;
  };

  useEffect(() => {
		// TODO replace this index case with sbn coming from url resolver.
		if (document.location.href.indexOf('hesapkurdu') >= 0) {
			TagManager.initialize({ gtmId: 'GTM-5B9SDM3' });
		} else {
			TagManager.initialize({ gtmId: 'GTM-N34DLK' });
		}

    dispatch(loadingKlActions.showKlLoadingEmpty());
    let pageName = window.location.pathname.split('/').pop();
    if (pageName === '' || pageName.toLowerCase() === 'tamamlayici-saglik-sigortasi') {
      pageName = 'tamamlayici-saglik-sigortasi';
    }
    const pageParameters = getPageObject(pageName);
    dispatch(headerActions.setUseHeader(pageParameters.hasHeader));
    // dispatch(headerActions.setMainNav(pageParameters.hasMainNav));
    dispatch(headerActions.setUseStepper(pageParameters.hasStepper));
    if (
      !pageParameters.hasJt
      || (pageName.toLowerCase() === 'tamamlayici-saglik-sigortasi' && !jt)
    ) {
      if (pageName.toLowerCase() === 'tamamlayici-saglik-sigortasi') {
				console.log(history)
        historyPushWithSearch('/tamamlayici-saglik-sigortasi/info', history);
        dispatch(loadingKlActions.hideKlLoading());
      } else {
        dispatch(loadingKlActions.hideKlLoading());
      }
    } else if(jt) {
      getFunnelInfo(dispatch, 3, (funnelData) => {
        if (Object.keys(customer).length < 1) {
          const mappedCustomerInfo = mapFunnelDataToCustomer(funnelData.quotation.customer);
          dispatch(informationActions.setCustomerInfo(mappedCustomerInfo));
          dispatch(loadingKlActions.hideKlLoading());
        }
      });
    }
    if (qGuid && pageParameters.hasQuotationDetail !== false) {
      const quoteDetailReq = {
        TeklifUid: qGuid,
      };
      dispatch(loadingKlActions.showKlLoadingEmpty());
      quotationService.getQuotationDetail(quoteDetailReq, (quotationDetail) => {
        const mappedInsureds = mapDetailInsuredsToRedux(quotationDetail.insured, pageName.toLowerCase(), quotationDetail.address);
        const { insureds, isFamilyFlow } = mappedInsureds;
        dispatch(informationActions.setInsureds(insureds));
        dispatch(informationActions.setIsFamilyFlow(isFamilyFlow));
        dispatch(loadingKlActions.hideKlLoading());

				// when page refreshes step wil be setted by url pathname
        const { location: { pathname } } = window;
        const stepType = isFamilyFlow ? 'FAMILY' : 'SELF';

        const step = TSS_SEQUENCE[stepType].find((elm) => elm.stepUrl.toLowerCase() === pathname.toLowerCase())?.index;
        dispatch(stepActions.setStep(step || 1));
				setCompleted(true);
      });
    }else{
			dispatch(informationActions.setIsFamilyFlow(false));
			setCompleted(true);
		}
    ReactGA.pageview(window.location.pathname);
    // TODO replace this index case with sbn coming from url resolver.
    if (document.location.href.indexOf('hesapkurdu') >= 0) {
      TagManager.initialize({ gtmId: 'GTM-5B9SDM3' });
    } else {
      TagManager.initialize({ gtmId: 'GTM-N34DLK' });
    }
  }, []);

  return (
    completed && (<Provider config={clientRollBarConfig}>
      <ErrorBoundary>
        <Helmet>
          <title>Online Tamamlayıcı Sağlık Sigortası</title>
        </Helmet>
        <CssBaseline />
        <Router>
          <Toastr />
          <Switch>
            <Route
              path="/tamamlayici-saglik-sigortasi/info"
              exact
              render={() => <Info />}
            />
            <Route
              path="/tamamlayici-saglik-sigortasi/ContactInfo"
              exact
              render={() => <ContactInfo />}
            />            
						<Route
              path="/tamamlayici-saglik-sigortasi/CityDistrict"
              exact
              render={() => <CityDistrict />}
            />
            <Route
              path="/tamamlayici-saglik-sigortasi/CustomerInfo"
              exact
              render={() => <CustomerInfo />}
            />
            <Route
              path="/tamamlayici-saglik-sigortasi/RedirectCC"
              exact
              render={() => <RedirectCC />}
            />
            <Route
              path="/tamamlayici-saglik-sigortasi/Insureds"
              exact
              render={() => <Insureds />}
            />
            <Route
              path="/tamamlayici-saglik-sigortasi/Summary"
              exact
              render={() => <Summary />}
            />            
						<Route
              path="/tamamlayici-saglik-sigortasi/Confirmation"
              exact
              render={() => <Confirmation />}
            />						
						<Route
              path="/tamamlayici-saglik-sigortasi/Thankyou"
              exact
              render={() => <Thankyou />}
            />
            <Route
              path="/tamamlayici-saglik-sigortasi/Checkout"
              exact
              render={() => <Checkout />}
            />            
						<Route
              path="/tamamlayici-saglik-sigortasi/saglik-beyani-hastaliklar"
              exact
              render={() => <IllnnesDeclarationList />}
            />
						<Route path="/tamamlayici-saglik-sigortasi/openPDF" exact render={() => <PdfView />} />
          </Switch>
        </Router>
        <CookieModal />
      </ErrorBoundary>
    </Provider>)
  );
};

export default TSSApp;
