import React, { useState } from 'react';
import styled from '@emotion/styled';

import CenteredModal from '../widget/CenteredModal';

import '../../../../shared/assets/styles/components/campaign.scss';

export const CampaignBox = ({ children, details }) => {
  const [isOpen, setIsOpen] = useState(false);

  const StylesCampaignBox = styled(({ className }) => (
    <div className={`campaign-box-container ${className}`}>
      <div dangerouslySetInnerHTML={{ __html: children }} />
      <span onClick={() => setIsOpen(!isOpen)}>Detaylar</span>
    </div>
  ))(({ theme }) => ({
    borderColor: theme.palette.primary.main,
    '& ul li > p:before': {
      color: theme.palette.primary.main,
    },
  }));

  return (
    <>
      <StylesCampaignBox />
      <CenteredModal isOpen={isOpen} setOpen={() => setIsOpen(false)}>
        <div dangerouslySetInnerHTML={{ __html: details }} />
      </CenteredModal>
    </>
  );
};

export default CampaignBox;
