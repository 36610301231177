import { hospitalNetworksEnum, treatmentCountsEnum, treatmentTypesEnum } from '../constants/filterTypes';
import { countStandingTreatment } from './stringHelpers';

export const filterDecider = (offer) => {
    const filterOptions = {
        treatmentType: null,
        hospitalNetwork: null,
        treatmentCount: null,
    };
    //* Deciding on Treatment Type
    if (offer.subTypeTag.includes('Yatarak_Ayakta_Doğum')) {
        filterOptions.treatmentType = treatmentTypesEnum.YatarakAyaktaDogum;
    } else if (offer.subTypeTag.includes('Yatarak_Ayakta')) {
        filterOptions.treatmentType = treatmentTypesEnum.YatarakAyakta;
    } else {
        filterOptions.treatmentType = treatmentTypesEnum.Yatarak;
    }

    //* Deciding on Hospital Network
    if (offer?.network?.networkGroup?.includes('Kapsamlı')) {
        filterOptions.hospitalNetwork = hospitalNetworksEnum.Kapsamli;
    } else {
        filterOptions.hospitalNetwork = hospitalNetworksEnum.Limitli;
    }

    //* Deciding on Treatment Count
    const treatmentCount = countStandingTreatment(offer.quotationCoverageItem.coverageGroups);
    if (Number(treatmentCount.split(' ')[0]) <= 5 || treatmentCount === '-') {
        filterOptions.treatmentCount = treatmentCountsEnum.OneToFive;
    } else {
        filterOptions.treatmentCount = treatmentCountsEnum.SixToTen;
    }
    return filterOptions;
};

export const constructOfferArray = (...arrays) => {
    const validArrays = arrays.filter(arr => Array.isArray(arr) && arr.length > 0);
    if (validArrays.length === 0) {
        return [];
    }
    const mergedArray = validArrays[0].reduce((acc, curr) => {
        if (curr && curr.productId && !acc.some(obj => obj.productId === curr.productId)) {
            acc.push(curr);
        }
        return acc;
    }, []);
    if (mergedArray.length === 0) {
        return [];
    }
    if (validArrays.length === 1) {
        return mergedArray;
    }
    const sharedObjects = mergedArray.filter(item =>
        validArrays.every(arr => arr.some(obj => obj.productId === item.productId))
    );
    return sharedObjects.sort((a, b) => a.netPremium - b.netPremium);
};

export const checkIfSuggested = (offersToBeRendered) => {
    //! suggestedOffers = [tt: [1], nt: [0], oo: [1]];
    if (offersToBeRendered.tt.length !== 1 || offersToBeRendered.tt[0] !== 1) {
        return false;
    }
    if (offersToBeRendered.nt.length !== 1 || offersToBeRendered.nt[0] !== 0) {
        return false;
    }
    if (offersToBeRendered.oo.length !== 1 || offersToBeRendered.oo[0] !== 1) {
        return false;
    }
    return true;
}