import React, { useState } from 'react';
import './user-aggrement-info.scss';
import CenteredModal from '../../../../motor/views/components/widget/CenteredModal';
import UserAggrementModal from '../../../../motor/views/components/form/UserAggrementModal';
import { brokerlikSozlesmesi } from '../../../../motor/data/shared';

export const UserAggrement = (prop) => {
  const [userAgreementModal, setUserAgreementModal] = useState(false);
  const [brokerModal, setBrokerModal] = useState(false);

  const userAgreementHook = (e) => {
    setUserAgreementModal(true);
    if (e.target.href === '') {
      e.preventDefault();
    }
    return false;
  };

  const brokerHook = (e) => {
    setBrokerModal(true);
    if (e.target.href === '') {
      e.preventDefault();
    }
    return false;
  };

  return (
    <span className="user-aggrement-info-box">
      <span className="user-aggrement-info-text">
        {' '}
        <a style={{ cursor: 'pointer' }} onClick={(e) => userAgreementHook(e)}>
          Kullanıcı sözleşmesini
        </a>
        {' '}
        okudum ve Koalay.com’u
        {' '}
        <a style={{ cursor: 'pointer' }} onClick={(e) => brokerHook(e)}>
          sigorta brokerim
        </a>
        {' '}
        olarak yetkilendiriyorum.
      </span>
      <CenteredModal className="user-aggrement-modal" title="Kullanıcı Sözleşmesi" isOpen={userAgreementModal} setOpen={() => setUserAgreementModal(false)}>
        <UserAggrementModal />
      </CenteredModal>
      <CenteredModal
        title="Brokerlik Yetkilendirmesi ve Brokerlik Sözleşmesi"
        isOpen={brokerModal}
        setOpen={() => setBrokerModal(false)}
      >
        {brokerlikSozlesmesi}
      </CenteredModal>
    </span>
  );
};

export default (UserAggrement);
