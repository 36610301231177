import React from 'react';

const TrashFilterSvg = (props) => {
  const { disabled } = props;
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.1133 5.94629V12.113C12.1133 13.2463 11.1933 14.1663 10.06 14.1663H5.94665C4.81331 14.1663 3.89331 13.2463 3.89331 12.113V5.94629" stroke={disabled ? '#D9D9D9' : '#1E1E1E'} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2.52002 3.88672H13.48" stroke={disabled ? '#D9D9D9' : '#1E1E1E'} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.94666 3.88635C5.94666 2.75302 6.86666 1.83301 7.99999 1.83301C9.13332 1.83301 10.0533 2.75302 10.0533 3.88635" stroke={disabled ? '#D9D9D9' : '#1E1E1E'} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.62671 8L9.37337 10.74" stroke={disabled ? '#D9D9D9' : '#1E1E1E'} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.37337 8L6.62671 10.74" stroke={disabled ? '#D9D9D9' : '#1E1E1E'} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default TrashFilterSvg;
